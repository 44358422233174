import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from '@models/fabrication/config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configUpdates: Subject<Config[]> = new Subject();

  public constructor() {
    console.log(`Config service ctor ${new Date()}`);
  }

  get listenConfigUpdate() {
    return this.configUpdates;
  }

  notifyConfigUpdate(configs: Config[]) {
    this.configUpdates.next(configs);
  }
}
