import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromConnectorInfos from '../reducers/connector-info.reducer';
import { FabricationReference } from '@models/forge-content/references';

// connectorInfo state
export const selectConnectorInfoState = (state: FDMState) => state.configsState.connectorInfos;

// connectorInfo selectors
export const selectConnectorInfoIds = createSelector(
  selectConnectorInfoState,
  fromConnectorInfos.selectConnectorInfoIds
);

export const selectConnectorInfoEntities = createSelector(
  selectConnectorInfoState,
  fromConnectorInfos.selectConnectorInfoEntities
);

export const selectAllConnectorInfos = createSelector(
  selectConnectorInfoState,
  fromConnectorInfos.selectAllConnectorInfos
);

export const selectConnectorInfosTotal = createSelector(
  selectConnectorInfoState,
  fromConnectorInfos.selectConnectorInfosTotal
);

export const selectConnectorInfoById = (
  id: string,
  addMissingReferences?: (fabricationReferences: FabricationReference[]) => FabricationReference[]
) =>
  createSelector(selectConnectorInfoEntities, (connectorInfoEntities) => {
    const entity = connectorInfoEntities[id];
    const references = entity.fabricationReferences;
    const fixedReferences = addMissingReferences ? addMissingReferences(references) : references;

    return {
      ...entity,
      fabricationReferences: fixedReferences,
    };
  });
