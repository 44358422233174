import { Action } from '@ngrx/store';
import { DataElementType } from '@constants/data-element-types';
import { BinaryJobType } from '@models/binary-task/binary-job-type';

export enum RealTimeActivityActionTypes {
  UpsertRealTimeActivityMarker = '[Real Time Activity] Upsert Real Time Activity Marker',
  DeleteRealTimeActivityMarker = '[Real Time Activity] Delete Real Time Activity Marker',
}

export class UpsertRealTimeActivityMarker implements Action {
  readonly type = RealTimeActivityActionTypes.UpsertRealTimeActivityMarker;

  constructor(
    public payload: {
      configUrn: string;
      dataType: DataElementType;
      partNodeId: string;
      jobType: BinaryJobType;
      contentItemIds: string[];
    }
  ) {}
}

export class DeleteRealTimeActivityMarker implements Action {
  readonly type = RealTimeActivityActionTypes.DeleteRealTimeActivityMarker;

  constructor(
    public payload: {
      configUrn: string;
      dataType: DataElementType;
      partNodeId: string;
    }
  ) {}
}

export type RealTimeActivityActions = UpsertRealTimeActivityMarker | DeleteRealTimeActivityMarker;
