import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import {
  DynamicTableOptions,
  ColumnDefinition,
  CreateOptions,
  MenuOption,
} from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import {
  DynamicFormGroupOptions,
  DynamicFormOptions,
  DynamicFormSelectField,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config, ConfigUnitSystem } from '@models/fabrication/config';
import {
  MaterialSpecification,
  MaterialSpecificationType,
  PipeworkMaterialSpecification,
  SheetMetalMaterialSpecification,
  MaterialSpecificationForm,
  DuctboardMaterialSpecification,
  RollMaterialSpecification,
  SleeveMaterialSpecification,
  ElectricalContainmentMaterialSpecification,
} from '@models/fabrication/material-spec';
import { EnvironmentConstants } from '@constants/environment-constants';
import { map, take } from 'rxjs/operators';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfigMaterialSpecs,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import { selectMaterialSpecById } from '@store/selectors/material-spec.selectors';
import {
  LoadMaterialSpecs,
  LoadMaterialSpecsSuccess,
  AddMaterialSpec,
  UpdateMaterialSpec,
  CopyMaterialSpec,
  DeleteMaterialSpecs,
  DeleteMaterialSpecsSuccess,
  AddMaterialSpecSuccess,
  UpdateMaterialSpecSuccess,
} from '@store/actions/material-spec.action';
import { UpdateConfigMaterialSpecIds } from '@store/actions/configs.action';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { UnitSchemaType } from '@models/forge-units/forge-unit-schema-types';
import { isEmpty } from 'lodash';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { JSONSchema7 } from 'json-schema';
import { Observable, of } from 'rxjs';
import { DebugModeService } from '@services/debug-mode.service';

@Injectable()
export class DynamicMaterialSpecSetup extends DynamicDataElementTypeSetup<MaterialSpecification> {
  private createType: MaterialSpecification = new PipeworkMaterialSpecification();

  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<MaterialSpecification>,
    schemaService: SchemaService,
    environmentService: EnvironmentService,
    private debugModeService: DebugModeService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.materialSpecifications;
  }

  private get specsWithThickness(): MaterialSpecificationType[] {
    return [
      MaterialSpecificationType.SheetMetal,
      MaterialSpecificationType.Generic,
      MaterialSpecificationType.Insulation,
    ];
  }

  private get specsWithWireGauge(): MaterialSpecificationType[] {
    return [MaterialSpecificationType.SheetMetal];
  }

  setupOptions() {
    const rectangularSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC,
      version: EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC_VERSION,
    };
    const electricalContainmentSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC,
      version: EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC_VERSION,
    };
    const pipeworkSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC,
      version: EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC_VERSION,
    };
    const sheetMetalSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC,
      version: EnvironmentConstants.FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC_VERSION,
    };
    const roundSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC,
      version: EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC_VERSION,
    };

    this.options = {
      dataType: DataElementType.MaterialSpecification,
      dependentDataTypes: [DataElementType.Material],
      supportsDynamicUpdates: true,
      createNewInstance: () => {
        return this.createType;
      },
      sortFields: ['category', 'name'],
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigMaterialSpecs(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectMaterialSpecById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadMaterialSpecs({ config })),
        deleteDataSuccessAction: () => new DeleteMaterialSpecsSuccess(),
        addDataSuccessAction: () => new AddMaterialSpecSuccess(),
        updateDataSuccessAction: () => new UpdateMaterialSpecSuccess(),
        loadSuccessAction: () => new LoadMaterialSpecsSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigMaterialSpecIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: MaterialSpecification) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddMaterialSpec({
                  config,
                  dataElement: model,
                  schema: this.getCustomSchema(model),
                  skipBinaryTask: !this.debugModeService.enableBinaryUpdateForMaterialSpec,
                })
              )
            );
        },
        editModelAction: (model: MaterialSpecification) =>
          this.store$.dispatch(
            new UpdateMaterialSpec({
              dataElement: model,
              schema: this.getCustomSchema(model),
            })
          ),
        copyModelAction: (model: MaterialSpecification) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyMaterialSpec({
                  config,
                  dataElement: model,
                  schema: this.getCustomSchema(model),
                })
              )
            );
        },
        deleteModelsAction: (models: MaterialSpecification[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new DeleteMaterialSpecs({
                  config,
                  dataElements: models,
                })
              )
            );
        },
        fixModelAction: (model: MaterialSpecification) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.MaterialSpecification,
                  fixSchemaType: this.getSchemaType(model.materialSpecificationType, model.form),
                  fixSchemaVersion: this.getSchemaVersion(
                    model.materialSpecificationType,
                    model.form
                  ),
                  nodeId: EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS,
                  addSuccessAction: new AddMaterialSpecSuccess(),
                  schema: this.getCustomSchema(model),
                })
              )
            );
        },
      },
      fcs: {
        createSchemaOverride: () => {
          switch (this.createType.materialSpecificationType) {
            default:
            case MaterialSpecificationType.Generic:
              return rectangularSchema;

            case MaterialSpecificationType.SheetMetal:
              return sheetMetalSchema;

            case MaterialSpecificationType.ElectricalContainment:
              return electricalContainmentSchema;

            case MaterialSpecificationType.Pipework:
              return pipeworkSchema;

            case MaterialSpecificationType.Insulation: {
              if (this.createType.form === 'Sleeve') return roundSchema;
              else return rectangularSchema;
            }
          }
        },
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS,
        schemas: [
          {
            dataType: DataElementType.MaterialSpecification,
            schema: rectangularSchema,
          },
          {
            dataType: electricalContainmentSchema.type,
            schema: electricalContainmentSchema,
          },
          {
            dataType: pipeworkSchema.type,
            schema: pipeworkSchema,
          },
          {
            dataType: roundSchema.type,
            schema: roundSchema,
          },
          {
            dataType: sheetMetalSchema.type,
            schema: sheetMetalSchema,
          },
        ],
        subSchemas: [
          {
            parentSchema: rectangularSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_MATERIAL_SPEC_SIZE}`,
          },
          {
            parentSchema: electricalContainmentSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC_SIZE}`,
          },
          {
            parentSchema: pipeworkSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_PIPEWORK_MATERIAL_SPEC_SIZE}`,
          },
          {
            parentSchema: roundSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_MATERIAL_SPEC_SIZE}`,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(
    configUnitSystem: ConfigUnitSystem
  ): Observable<DynamicTableOptions<MaterialSpecification>> {
    const lengthUnits = ForgeUnitsService.getStandardLengthUnits(configUnitSystem);

    const materialTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.CATEGORY),
        formatter: (value: string) =>
          value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
        visible: true,
      },
      {
        field: 'materialSpecificationType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.TYPE),
        formatter: (value: string) => this.getTypeTranslation(value as MaterialSpecificationType),
        visible: true,
      },
      {
        field: 'form',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.FORM),
        formatter: (value: string) => this.getFormTranslation(value as MaterialSpecificationForm),
        visible: true,
      },
      {
        field: 'thickness',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.THICKNESS),
        formatter: (value: number) =>
          value || value === 0 ? value : this.translate.instant(LC.DYNAMIC_TABLE.NA),
        visible: false,
        units: lengthUnits,
      },
      {
        field: 'wireGauge',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.WIRE_GAUGE),
        formatter: (value: number) =>
          value || value === 0 ? value : this.translate.instant(LC.DYNAMIC_TABLE.NA),
        visible: false,
      },
    ];

    const createOptions: CreateOptions = {
      setType: (type: MaterialSpecification) => (this.createType = type),
      options: [
        {
          label: this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.PIPEWORK),
          value: () => new PipeworkMaterialSpecification(),
        },
        {
          label: this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.INSULATION),
          value: null,
          subMenus: [
            {
              label: this.translate.instant(
                LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.BOARD_FLAT
              ),
              value: () => new DuctboardMaterialSpecification(),
            },
            {
              label: this.translate.instant(
                LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.ROLL_FLAT
              ),
              value: () => new RollMaterialSpecification(),
            },
            {
              label: this.translate.instant(
                LC.DATATYPES.DEFINITIONS.MATERIAL_SPECIFICATIONS.SLEEVE_ROUND
              ),
              value: () => new SleeveMaterialSpecification(),
            },
          ],
        },
        {
          label: this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.SHEET_METAL),
          value: () => new SheetMetalMaterialSpecification('Coil'),
        },
        {
          label: this.translate.instant(
            LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.ELECTRICAL_CONTAINMENT
          ),
          value: () => new ElectricalContainmentMaterialSpecification(),
        },
        // note - do not add support for adding generic material specs
      ].sort((a: MenuOption, b: MenuOption) => a.label.localeCompare(b.label)),
    };

    return of(this.createDynamicTableOptions(materialTableColumns, createOptions));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<MaterialSpecification>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: MaterialSpecification) => {
        const specType = model.materialSpecificationType;
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          readOnlyFields: ['materialSpecificationType'],
          groups: this.getGroupedData(specType),
          getCustomSchema: (model: MaterialSpecification) => this.getCustomSchema(model),
          formStyle: isEmpty(this.getGroupedData(specType)) //todo - remove isEmpty
            ? DynamicFormStyle.SIMPLE
            : DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  private getGroupedData(specType: MaterialSpecificationType): DynamicFormGroupOptions[] {
    const categories = this.getItemListForTypeaheadControl('category');

    return [
      {
        expanded: true,
        orderByIncludeFields: true,
        label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
        includeFields: this.getBasicIncludeFields(specType),
        options: {
          numberFields: this.specsWithWireGauge.includes(specType) ? ['wireGauge'] : [],
          readOnlyFields: this.getBasicReadOnlyFields(specType),
          unitsFields: this.specsWithThickness.includes(specType)
            ? [
                {
                  key: 'thickness',
                  units: {
                    imperial: UnitSchemaType.Inches,
                    metric: UnitSchemaType.Millimeters,
                  },
                },
              ]
            : [],
          dropdownTypeaheadFields: [
            {
              key: 'category',
              options: categories,
            },
          ],
          selectFields: this.getBasicSelectFields(specType),
          formStyle: DynamicFormStyle.SIMPLE,
        },
      },
      {
        label: this.translate.instant(LC.DYNAMIC_FORM.TABS.SIZES),
        includeFields: ['sizes'],
        options: {
          customComponents: [
            {
              type: DynamicFormCustomComponentType.MaterialSpecificationSizeTable,
              field: 'sizes',
            },
          ],
          formStyle: DynamicFormStyle.SIMPLE,
        },
      },
    ];
  }

  private getBasicReadOnlyFields(specType: MaterialSpecificationType): string[] {
    const fields = ['materialSpecificationType'];

    if (
      specType === MaterialSpecificationType.Insulation ||
      specType === MaterialSpecificationType.ElectricalContainment ||
      specType === MaterialSpecificationType.Generic ||
      specType === MaterialSpecificationType.Pipework
    ) {
      fields.push('form');
    }

    return fields;
  }

  private getBasicSelectFields(specType: MaterialSpecificationType): DynamicFormSelectField[] {
    if (specType === MaterialSpecificationType.SheetMetal) {
      return [
        {
          key: 'form',
          options: [
            {
              value: 'NotSet',
              label: this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.NOT_SET),
            },
            {
              value: 'Coil',
              label: this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.COIL),
            },
            {
              value: 'Sheet',
              label: this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.SHEET),
            },
          ],
        },
      ];
    }

    return [];
  }

  private getBasicIncludeFields(specType: MaterialSpecificationType): string[] {
    const fields = [
      'name',
      'category',
      'materialSpecificationType',
      'form',
      'fabricationReferences',
    ];

    if (this.specsWithThickness.includes(specType)) {
      fields.push('thickness');
    }

    if (this.specsWithWireGauge.includes(specType)) {
      fields.push('wireGauge');
    }

    return fields;
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category', 'form'],
      upstreamReferenceDataTypes: () => [DataElementType.Part, DataElementType.Material],
      isReplaceable: true,
      isRemovable: false,
      isEditable: true,
      isDownstreamRefBlocked: () => true,
      clusterIcon: 'properties16',
    };
  }

  private getSchemaType(
    specType: MaterialSpecificationType,
    form: MaterialSpecificationForm
  ): string {
    switch (specType) {
      default:
      case MaterialSpecificationType.Pipework:
        return EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC;

      case MaterialSpecificationType.SheetMetal:
        return EnvironmentConstants.FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC;

      case MaterialSpecificationType.Generic:
        return EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC;

      case MaterialSpecificationType.ElectricalContainment:
        return EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC;

      case MaterialSpecificationType.Insulation: {
        if (form === 'Sleeve') return EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC;
        else return EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC;
      }
    }
  }

  private getCustomSchema(model: MaterialSpecification): JSONSchema7 {
    let dataElementType = '';
    switch (model.materialSpecificationType) {
      default:
      case MaterialSpecificationType.Generic:
        dataElementType = DataElementType.MaterialSpecification;
        break;

      case MaterialSpecificationType.SheetMetal:
        dataElementType = EnvironmentConstants.FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC;
        break;

      case MaterialSpecificationType.ElectricalContainment:
        dataElementType = EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC;
        break;

      case MaterialSpecificationType.Pipework:
        dataElementType = EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC;
        break;

      case MaterialSpecificationType.Insulation:
        if (model.form === 'Sleeve')
          dataElementType = EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC;
        else dataElementType = DataElementType.MaterialSpecification;
        break;
    }

    const schema = this.schemaService.getSchemaByDataElementType(dataElementType);
    return schema;
  }

  private getSchemaVersion(
    specType: MaterialSpecificationType,
    form: MaterialSpecificationForm
  ): string {
    switch (specType) {
      default:
      case MaterialSpecificationType.Pipework:
        return EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC_VERSION;

      case MaterialSpecificationType.SheetMetal:
        return EnvironmentConstants.FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC_VERSION;

      case MaterialSpecificationType.Generic:
        return EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC_VERSION;

      case MaterialSpecificationType.ElectricalContainment:
        return EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC_VERSION;

      case MaterialSpecificationType.Insulation:
        if (form === 'Sleeve') return EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC_VERSION;
        else return EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC_VERSION;
    }
  }

  private getTypeTranslation(type: MaterialSpecificationType): string {
    switch (type) {
      case MaterialSpecificationType.ElectricalContainment:
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.ELECTRICAL_CONTAINMENT);
      case MaterialSpecificationType.Generic:
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.GENERIC);
      case MaterialSpecificationType.Insulation:
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.INSULATION);
      case MaterialSpecificationType.Pipework:
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.PIPEWORK);
      case MaterialSpecificationType.SheetMetal:
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_TYPE.SHEET_METAL);

      default:
        return 'Unsupported type: ' + type;
    }
  }

  private getFormTranslation(form: MaterialSpecificationForm): string {
    switch (form) {
      case 'NotSet':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.NOT_SET);
      case 'Board':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.BOARD);
      case 'Coil':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.COIL);
      case 'Generic':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.GENERIC);
      case 'Roll':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.ROLL);
      case 'Sheet':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.SHEET);
      case 'Sleeve':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.SLEEVE);
      case 'Tubular':
        return this.translate.instant(LC.ENUMS.MATERIAL_SPECIFICATION_FORM.TUBULAR);

      default:
        return 'Unsupported form';
    }
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return 'properties';
  }

  isFixable(model: InvalidData & MaterialSpecification): boolean {
    return model.materialSpecificationType !== MaterialSpecificationType.Generic;
  }

  getInvalidDataErrors(model: MaterialSpecification & InvalidData): string {
    let schema;
    switch (model.materialSpecificationType) {
      default:
      case MaterialSpecificationType.Generic:
        schema = this.schemaService.getSchemaByDataElementType(
          DataElementType.MaterialSpecification
        );
        break;

      case MaterialSpecificationType.SheetMetal:
        schema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC
        );
        break;

      case MaterialSpecificationType.ElectricalContainment:
        schema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC
        );
        break;

      case MaterialSpecificationType.Pipework:
        schema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC
        );
        break;

      case MaterialSpecificationType.Insulation:
        schema =
          model.form === 'Sleeve'
            ? this.schemaService.getSchemaByDataElementType(
                EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC
              )
            : this.schemaService.getSchemaByDataElementType(DataElementType.MaterialSpecification);
    }

    const errors = this.invalidDataErrorService.parseErrors(model, schema);
    if (!errors.length) {
      return this.translate.instant(LC.ERROR_HANDLING.MATERIAL_SPECS.UNSUPPORTED_TYPE);
    }

    return this.getStandardInvalidDataError(DataElementType.MaterialSpecification, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: MaterialSpecification*/): boolean {
    return false;
  }
}
