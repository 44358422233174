import { NotificationButton, NotificationLink } from '@models/notification/notification';
import { Alert, AlertProps } from '@weave-mui/alert';
import { Button, buttonVariants } from '@weave-mui/button';
import Slide from '@weave-mui/slide';
import styled from '@weave-mui/styled';
import React from 'react';
import Link from '@weave-mui/link';

export type FABAlertProps = AlertProps & {
  message: string;
  onBannerDismiss: (event: any) => void;
  isVisible: boolean;
  buttons?: NotificationButton[];
  link?: NotificationLink;
};

const StyledAlert = styled(Alert)(() => ({
  position: 'fixed',
  zIndex: 99999,
  width: '100%',
}));

export const FABAlert: React.FC<FABAlertProps> = (props) => {
  const { buttons, link, isVisible, message, onBannerDismiss, ...alertProps } = props;

  let action = null;
  if (buttons?.length) {
    const buttonActions = buttons.map((button, index) => (
      <Button variant={buttonVariants.OUTLINED} onClick={button.onClick} key={index}>
        {button.title}
      </Button>
    ));
    action = <>{buttonActions}</>;
  }

  const linkComponent = (link && (
    <Link href={link.url} target={(link.openInNewWindow && '_blank') || null} onClick={link.action}>
      {link.text}
    </Link>
  )) || <></>;

  return (
    <Slide direction="down" in={isVisible}>
      <StyledAlert {...alertProps} onClose={onBannerDismiss} action={action}>
        {message}
        <br />
        {linkComponent}
      </StyledAlert>
    </Slide>
  );
};
