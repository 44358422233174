import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HigTopNavComponent } from './top-nav/top-nav.component';
import { HigSideNavComponent } from './side-nav/side-nav.component';
import { HigLearnNavComponent } from './learn-nav/learn-nav.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HigTopNavComponent, HigSideNavComponent, HigLearnNavComponent],
  exports: [HigTopNavComponent, HigSideNavComponent, HigLearnNavComponent],
  schemas: [],
})
export class HigNavModule {}
