import { FabricationReference } from '@models/forge-content/references';
import { FDMState } from '../reducers';
import * as fromInvalidData from '../reducers/invalid-data.reducer';
import { createSelector } from '@ngrx/store';

// invalid data list state
export const selectInvalidDataState = (state: FDMState) => state.configsState.invalidData;

// invalid data selectors
export const selectInvalidDataIds = createSelector(
  selectInvalidDataState,
  fromInvalidData.selectInvalidDataIds
);

export const selectInvalidDataEntities = createSelector(
  selectInvalidDataState,
  fromInvalidData.selectInvalidDataEntities
);

export const selectAllInvalidData = createSelector(
  selectInvalidDataState,
  fromInvalidData.selectAllInvalidData
);

export const selectInvalidDataTotal = createSelector(
  selectInvalidDataState,
  fromInvalidData.selectInvalidDataTotal
);

export const selectInvalidDataById = (id: string) =>
  createSelector(selectInvalidDataEntities, (invalidDataEntities) => invalidDataEntities[id]);

export const selectInternalInvalidData = (
  id: string,
  addMissingReferences: (fabricationReferences: FabricationReference[]) => FabricationReference[]
): any =>
  createSelector(selectInvalidDataEntities, (invalidDataEntities) => {
    const entity = invalidDataEntities[id];

    const references = entity.fabricationReferences;

    const internal = {
      ...entity,
      fabricationReferences: addMissingReferences(references),
    };

    return internal;
  });
