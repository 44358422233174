import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { ServiceTemplatePalettesComponent } from 'app/feature-modules/data/service-templates/detail/palettes/service-template-palettes.component';
import { ServiceTemplatePartsComponent } from 'app/feature-modules/data/service-templates/detail/parts/parts.component';
import { ServiceTemplateSizeRestrictionsComponent } from 'app/feature-modules/data/service-templates/detail/size-restrictions/service-template-size-restrictions.component';
import { ConnectorBreakPointTableComponent } from 'app/feature-modules/data/database/connectors/connector-break-point-tables/connector-break-point-table.component';
import { ToggleValueComponent } from '../toggle-value/toggle-value.component';
import { MaterialSpecificationSizeTableComponent } from 'app/feature-modules/data/database/material-specs/material-spec-size-tables/material-spec-size-table.component';
import { MultiReferenceSelectComponent } from '../multi-reference-select/multi-reference-select.component';
import { PartSpecificationTablesComponent } from 'app/feature-modules/data/database/part-specs/part-spec-tables.component';
import { PartSpecificationTableComponent } from 'app/feature-modules/data/database/part-specs/part-spec-table.component';
import { InsulationSpecificationTablesComponent } from 'app/feature-modules/data/database/ins-specs/ins-spec-tables.component';
import { InsulationSpecificationTableComponent } from 'app/feature-modules/data/database/ins-specs/ins-spec-table.component';
import { DropdownTypeaheadVariantTypeComponent } from '@shared/components/dropdown-typeahead-variant/dropdown-typeahead-variant.component';
import { PartCollectionTableComponent } from 'app/feature-modules/data/service-templates/detail/part-collections/part-collection-table.component';
import { FormlyTextFieldComponent } from '../formly-text-field/formly-text-field.component';
import { PartDimensionsTableComponent } from 'app/feature-modules/data/parts/part-dimensions-table/part-dimensions-table.component';
import { PartConnectorsFormComponent } from 'app/feature-modules/data/parts/part-connectors-form/part-connectors-form.component';

export enum CustomComponentDisplayType {
  InlineTable,
  FormField,
}

export interface CustomComponentDefinition {
  name: DynamicFormCustomComponentType;
  component: any;
  displayType: CustomComponentDisplayType;
}

export const customComponentDefinitions: CustomComponentDefinition[] = [
  {
    name: DynamicFormCustomComponentType.ServiceTemplateInfoPalettes,
    component: ServiceTemplatePalettesComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.ServiceTemplateInfoParts,
    component: ServiceTemplatePartsComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.ServiceTemplateInfoSizeRestrictions,
    component: ServiceTemplateSizeRestrictionsComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.ConnectorBreakPointTable,
    component: ConnectorBreakPointTableComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.ToggleValue,
    component: ToggleValueComponent,
    displayType: CustomComponentDisplayType.FormField,
  },
  {
    name: DynamicFormCustomComponentType.MaterialSpecificationSizeTable,
    component: MaterialSpecificationSizeTableComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.MultiReferenceSelect,
    component: MultiReferenceSelectComponent,
    displayType: CustomComponentDisplayType.FormField,
  },
  {
    name: DynamicFormCustomComponentType.PartSpecTables,
    component: PartSpecificationTablesComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.PartSpecTable,
    component: PartSpecificationTableComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.InsSpecTables,
    component: InsulationSpecificationTablesComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.InsSpecTable,
    component: InsulationSpecificationTableComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.DropdownTypeaheadVariant,
    component: DropdownTypeaheadVariantTypeComponent,
    displayType: CustomComponentDisplayType.FormField,
  },
  {
    name: DynamicFormCustomComponentType.PartCollectionTable,
    component: PartCollectionTableComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.PartDimensionsTable,
    component: PartDimensionsTableComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
  {
    name: DynamicFormCustomComponentType.FormlyTextField,
    component: FormlyTextFieldComponent,
    displayType: CustomComponentDisplayType.FormField,
  },
  {
    name: DynamicFormCustomComponentType.PartConnectorsForm,
    component: PartConnectorsFormComponent,
    displayType: CustomComponentDisplayType.InlineTable,
  },
];
