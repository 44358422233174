import { Action } from '@ngrx/store';

export enum FullScreenActionTypes {
  EnableFullScreenMode = '[FullScreenMode] Enable Full Screen Mode',
  DisableFullScreenMode = '[FullScreenMode] Disable Full Screen Mode',
}

export class EnableFullScreenMode implements Action {
  readonly type = FullScreenActionTypes.EnableFullScreenMode;
}

export class DisableFullScreenMode implements Action {
  readonly type = FullScreenActionTypes.DisableFullScreenMode;
}

export type FullScreenModeActions = EnableFullScreenMode | DisableFullScreenMode;
