import { createSelector } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import * as fromApplication from '../reducers/fullscreen-mode.reducer';

export const selectFullScreenModeState = (state: FDMState) => state.uiState.fullScreenMode;

export const isFullScreenEnabled = createSelector(
  selectFullScreenModeState,
  fromApplication.isFullScreenEnabled
);
