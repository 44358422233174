import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmationOptions, ConfirmationResult } from '@models/confirmation/confirmation';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private confirmationDisplaySubject = new Subject<ConfirmationOptions>();
  private confirmationCompleteSubject = new Subject<ConfirmationResult>();

  /**
   * Request to display the common confirmation dialog
   * with the provided options
   * called by a consumer i.e. a component that needs to display confirmation prior to an operation being run
   * @param {ConfirmationOptions} options
   * @return {*}  {Observable<ConfirmationResult>}
   * @memberof ConfirmationService
   */
  public requestDisplayConfirmationDialog(
    options: ConfirmationOptions
  ): Observable<ConfirmationResult> {
    this.confirmationDisplaySubject.next(options);

    return this.confirmationCompleteSubject.asObservable();
  }

  /**
   * Indicate that the confirmation operation has been run and we have a result (Ok or Cancel)
   * Run by the confirmation dialog component, the result will be output either click of either the ok or cancel button
   * @param {ConfirmationResult} result
   * @memberof ConfirmationService
   */
  public confirmationComplete(result: ConfirmationResult) {
    this.confirmationCompleteSubject.next(result);
  }

  /**
   * Method to run listener to notify of when a confirmation request has been made
   * Run by the confirmation dialog component to indicate when to display the confirmation modal dialog and the options
   * that it needs to display the title, message etc
   * @return {*}  {Observable<ConfirmationOptions>}
   * @memberof ConfirmationService
   */
  public listenForConfirmationRequests(): Observable<ConfirmationOptions> {
    return this.confirmationDisplaySubject.asObservable();
  }
}
