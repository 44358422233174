import { Action } from '@ngrx/store';
import { ActivitySubmission } from '@models/activities-events/activities';
import { DataElementType } from '@constants/data-element-types';

export enum ActivityActionTypes {
  SubmitActivity = '[Activity Api] Submit Activity',
  SubmitActivitySuccess = '[Activity Api] Submit Activity Success',
  SubmitActivityFailed = '[Activity Api] Submit Activity Failed',
  LoadDataActivities = '[Activity Api] Load Data Activities',
  LoadDataActivitiesSuccess = '[Activity Api] Load Data Activities Success',
  LoadDataActivitiesFailed = '[Activity Api] Load Data Activities Failed',
  LoadDataActivityStartingId = '[Activity Api] Load Data Activity Starting Id',
  LoadDataActivityStartingIdSuccess = '[Activity Api] Load Data Activity Starting Id Success',
  LoadDataActivityStartingIdFailed = '[Activity Api] Load Data Activity Starting Id Failed',
}

export class SubmitActivity implements Action {
  readonly type = ActivityActionTypes.SubmitActivity;

  constructor(
    public payload: {
      submission: ActivitySubmission;
    }
  ) {}
}

export class SubmitActivitySuccess implements Action {
  readonly type = ActivityActionTypes.SubmitActivitySuccess;
}

export class SubmitActivityFailed implements Action {
  readonly type = ActivityActionTypes.SubmitActivityFailed;

  constructor(
    public payload: {
      submission: ActivitySubmission;
    }
  ) {}
}

export class LoadDataActivities implements Action {
  readonly type = ActivityActionTypes.LoadDataActivities;

  constructor(
    public payload: {
      configUrn: string;
      dataType: DataElementType;
      nodeId: string;
      isExternalNodeId: boolean;
      lastActivityProcessedId: string;
      triggeredByRealTimeNotification?: boolean;
    }
  ) {}
}

export class LoadDataActivitiesSuccess implements Action {
  readonly type = ActivityActionTypes.LoadDataActivitiesSuccess;
}

export class LoadDataActivitiesFailed implements Action {
  readonly type = ActivityActionTypes.LoadDataActivitiesFailed;
}

export class LoadDataActivityStartingId implements Action {
  readonly type = ActivityActionTypes.LoadDataActivityStartingId;

  constructor(
    public payload: {
      configUrn: string;
      nodeId: string;
      isExternalNodeId: boolean;
      dataType: DataElementType;
    }
  ) {}
}

export class LoadDataActivityStartingIdSuccess implements Action {
  readonly type = ActivityActionTypes.LoadDataActivityStartingIdSuccess;
}

export class LoadDataActivityStartingIdFailed implements Action {
  readonly type = ActivityActionTypes.LoadDataActivityStartingIdFailed;
}

export type ActivityActions =
  | SubmitActivity
  | SubmitActivitySuccess
  | SubmitActivityFailed
  | LoadDataActivities
  | LoadDataActivitiesSuccess
  | LoadDataActivitiesFailed
  | LoadDataActivityStartingId
  | LoadDataActivityStartingIdSuccess
  | LoadDataActivityStartingIdFailed;
