import { DynamicFormOptions } from '@models/dynamic-form/dynamic-form-properties';
import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { StorageFile } from './files';
import { PartData, PartDimensionParameterType } from './geometry';

export class Part extends ForgeContentDataElement {
  cid = 0;
  path = '';
  sKey = '';
  imagePath = '';
  patternNumber: number;
  partType = PartType.Unknown;
  productListData?: ProductListData;
  alias = '';
  brand = '';
  productNumber = '';
  range = '';
  databaseId = '';
  isCentrelineInput?: boolean;
}

/**
 * Related properties to facilitate part operations
 * e.g. part preview, part thumbnal display etc
 * @export
 * @interface PartMap
 */
export interface PartMap {
  part: Part;
  isUpgrading: boolean;
  isInvalid: boolean;
  requiresUpgrade: boolean;
  contentNodeId: string;
  thumbnailStorageFile: null | StorageFile;
  partStorageFile: null | StorageFile;
  partData: null | PartData;
  formOptions?: DynamicFormOptions<Part>;
  detailsUrl?: string;
}

export enum PartType {
  'Unknown' = 'Unknown',
  'Catagloue' = 'Catalogue',
  'Template' = 'Template',
  'ProductList' = 'ProductList',
}

export interface ProductListData {
  entries: ProductListEntry[];
  dimensionInfos: PartDimensionInfo[];
  optionInfos?: PartOptionInfo[];
}

export interface ProductListEntry {
  id: string;
  name: string;
  databaseId: string;
  dimensions: ProductEntryDimension[];
  options?: ProductEntryOption[];
  orderNumber: string;
  weight?: number;
}

export interface ProductEntryDimension {
  id: number;
  value: number;
}

export interface ProductEntryOption {
  id: number;
  value: number;
}

export interface PartDimensionInfo {
  parameterType: PartDimensionParameterType;
  id: number;
  sameValue: boolean;
  calculation?: string;
}

export interface PartOptionInfo {
  parameterType: PartDimensionParameterType;
  id: number;
  sameValue: boolean;
}

export enum PartUpdateReason {
  ProductEntriesUpdate = 'ProductEntriesUpdate',
  ProductEntriesSelection = 'ProductEntriesSelection',
  OtherUpdates = 'OtherUpdates',
}
