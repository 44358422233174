import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from '@services/logging.service';

@Injectable()
export class DataErrorHandler implements ErrorHandler {
  loggingService: LoggingService;

  constructor(private injector: Injector) {}

  handleError(error: Error): void {
    // send un-caught app errors to logging service
    this.loggingService = this.injector.get(LoggingService);
    this.loggingService.logError(error);
  }
}
