import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, combineLatest, forkJoin } from 'rxjs';
import { catchError, filter, switchMap, take, tap, map } from 'rxjs/operators';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { uniq } from 'lodash';
import { DataElementType } from '@constants/data-element-types';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { LoadingState } from '@models/loading-state/loading-state';
import { NotificationService } from '@services/notification.service';
import {
  Notification,
  NotificationType,
  NotificationPlace,
} from '@models/notification/notification';
import {
  selectCurrentConfigLoadingData,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import {
  selectRealTimeActivityById,
  selectRealTimeActivityContainsDataType,
} from '@store/selectors/activity-real-time.selectors';
import { RealTimeActivityMarker } from '@models/activities-events/activities';
import { LoadDataActivities } from '@store/actions/activity.action';
import { CacheDataTypeRecord } from '@models/cache/cache';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { DynamicDataService } from '../dynamic-data.service';
import { CacheService } from '../cache.service';

/**
 * Handles bulk loading of a data type and it's dependent data.
 * Ensures that data is only loaded once with checks made to the Redux Store to validate
 * data types that have already been loaded.
 * @export
 * @class BulkDataLoadingService
 */
@Injectable({ providedIn: 'root' })
export class BulkDataLoadingService {
  requestedDataTypes: DataElementType[] = [];
  dynamicDataTypeSetup: DynamicDataElementTypeSetup<any>[] = [];

  constructor(
    private store$: Store<FDMState>,
    private dynamicDataService: DynamicDataService,
    private notificationService: NotificationService,
    private cacheService: CacheService,
    private translate: TranslateService
  ) {}

  bulkLoadDataAndDependents(
    dataTypes: DataElementType[],
    withActivityCheck: boolean
  ): Observable<boolean> {
    if (dataTypes?.length) {
      const dataTypesToLoad: DataElementType[] = [];
      dataTypes.forEach((dataType) => {
        const options = this.dynamicDataService.getDynamicDataSetupForType(dataType).options;
        // concat the main dataType to the array of dependents, exclude if defined
        dataTypesToLoad.push(
          ...options.dependentDataTypes.concat(options.excludeDataTypeFromBulkLoad ? [] : dataType)
        );
      });

      dataTypesToLoad.push(DataElementType.InvalidData);

      this.requestedDataTypes = uniq(dataTypesToLoad);

      return this.load().pipe(
        switchMap((loadStatus: boolean) => {
          if (loadStatus) {
            if (withActivityCheck) {
              return this.checkRealTimeActivityMarkers();
            } else {
              return of(true);
            }
          } else {
            return of(false);
          }
        }),
        switchMap((loadStatus: boolean) => of(loadStatus)),
        catchError(() => of(false))
      );
    } else {
      throw new Error('Missing or incorrectly formatted dataTypes parameter');
    }
  }

  // get current config
  // check if requested data types are already loaded for the config
  // load if required
  private load(): Observable<boolean> {
    let loading = false;
    let loaded = false;
    let failed = false;

    return combineLatest([
      this.store$.select(selectCurrentConfigLoadingData),
      this.store$.select(selectCurrentConfig),
    ]).pipe(
      take(1),
      tap((setupData: [LoadingState, Config]) => {
        return this.getDynamicDataToLoad(setupData[0]);
      }),
      map((setupData: [LoadingState, Config]) => setupData[1]),
      switchMap((config: Config) => {
        if (this.dynamicDataTypeSetup.length) {
          return this.store$.select(selectCurrentConfigLoadingData).pipe(
            tap((loadingState: LoadingState) => {
              // check loading state exists, if first usage for the config it will be undefined
              // until it recieves some data
              if (loadingState) {
                loading = this.requestedDataTypesAreLoading(loadingState);
                loaded = this.requestedDataTypesHaveLoaded(loadingState);
                failed = this.requestedDataTypesHaveFailed(loadingState);
              }

              if (!loading && !loaded) {
                // load data element types
                this.dynamicDataTypeSetup.forEach((dynamicSetup) => {
                  dynamicSetup.options.actions.loadAllAction(config);
                });
              }
            })
          );
        } else {
          loaded = true;
          return of(null);
        }
      }),
      filter(() => (!loading && loaded) || failed),
      tap((loadingState: LoadingState) => {
        failed && this.postFailureNotification(loadingState);
      }),
      map(() => !failed),
      take(1)
    );
  }

  private postFailureNotification(loadingState: LoadingState) {
    const failedDataTypes = loadingState.failed
      .map((x) => `${x.dataType.toLocaleLowerCase()}s`)
      .join(',');
    const notification = new Notification();
    notification.type = NotificationType.Error;
    notification.place = NotificationPlace.Banner;
    notification.messages = [
      this.translate.instant(LC.NOTIFICATIONS.MSG_DATA_ELEMENT_FAILURE, {
        usePlural: loadingState.failed.length > 1 ? 's' : '',
        failedDataTypes,
      }),
    ];
    this.notificationService.postNotification(notification);
  }

  private requestedDataTypesAreLoading(loadingState: LoadingState): boolean {
    // check if the store loading data includes any of the requested data types
    return !!this.dynamicDataTypeSetup
      .map((x) => x.options.dataType)
      .filter((dataType: DataElementType) => loadingState.loading.includes(dataType)).length;
  }

  private requestedDataTypesHaveLoaded(loadingState: LoadingState): boolean {
    // check if the requested data types now all exist
    return (
      this.dynamicDataTypeSetup
        .map((x) => x.options.dataType)
        .filter((dataType: DataElementType) => loadingState.loaded.includes(dataType)).length ===
      this.dynamicDataTypeSetup.length
    );
  }

  private requestedDataTypesHaveFailed(loadingState: LoadingState): boolean {
    // check if the requested data types now all exist
    return !!this.dynamicDataTypeSetup
      .map((x) => x.options.dataType)
      .filter((dataType: DataElementType) =>
        loadingState.failed.map((x) => x.dataType).includes(dataType)
      ).length;
  }

  private getDynamicDataToLoad(loadingState: LoadingState): void {
    // ensure no duplicate entries in dataTypes
    // get dataType setup information and filter for only types that need loading
    this.dynamicDataTypeSetup =
      (this.requestedDataTypes.length &&
        uniq(this.requestedDataTypes).map((dataType) =>
          this.dynamicDataService.getDynamicDataSetupForType(dataType)
        )) ||
      [];

    // check if loading state exists and if any requested types are already loaded
    if (loadingState) {
      this.dynamicDataTypeSetup = this.dynamicDataTypeSetup.filter(
        (x) => !loadingState.loaded.includes(x.options.dataType)
      );
    }
  }

  private checkRealTimeActivityMarkers(): Observable<boolean> {
    if (!this.cacheService.isCacheSupported()) {
      this.cacheService.printCacheNotSupportedMessage();
      return of(true);
    }

    let currentConfig: Config = null;
    return this.store$.select(selectCurrentConfig).pipe(
      switchMap((config: Config) => {
        currentConfig = config;
        return this.store$.select(selectRealTimeActivityById(config.id));
      }),
      take(1),
      switchMap((marker: RealTimeActivityMarker) => {
        if (!marker) {
          return of(true);
        }

        // get the data types that have gone through the full loading life cycle
        const loadedDataTypes = this.dynamicDataTypeSetup.map((x) => x.options.dataType);
        // get the difference between what has been requested to load and what has been loaded
        // any data types that were requetsed but not loaded it is assumed they have already gone through
        // the full loading cycle and can be considered as part of the real time activity check
        // i.e. the app has received a notification stating a data type for a config has changed,
        // triggering an activity check outside of the full loading cycle
        const potentialDataTypesForRealTimeActivityLoading = this.requestedDataTypes
          .filter((x) => !loadedDataTypes.includes(x))
          .filter((x) => marker.dataTypes.includes(x));

        if (!potentialDataTypesForRealTimeActivityLoading.length) {
          return of(true);
        } else {
          const checkRealTimeDataTypeIsProcessed$ =
            potentialDataTypesForRealTimeActivityLoading.map((x) =>
              this.store$.select(selectRealTimeActivityContainsDataType(currentConfig.id, x))
            );

          const getDataTypeCacheRecords$ = forkJoin(
            potentialDataTypesForRealTimeActivityLoading.map((x) => {
              const dynamicDataService = this.dynamicDataService.getDynamicDataSetupForType(x);
              return this.cacheService.getCacheDataTypeRecord(
                currentConfig.id,
                dynamicDataService.options.fcs.dataTypeExternalNodeId
              );
            })
          );

          return getDataTypeCacheRecords$.pipe(
            tap((records: CacheDataTypeRecord[]) => {
              records.forEach((x) => {
                this.store$.dispatch(
                  new LoadDataActivities({
                    configUrn: currentConfig.id,
                    dataType: x.dataType,
                    lastActivityProcessedId: x.lastActivityIdProcessed,
                    triggeredByRealTimeNotification: true,
                    nodeId: x.nodeId,
                    isExternalNodeId: true,
                  })
                );
              });
            }),
            switchMap(() => {
              return combineLatest(checkRealTimeDataTypeIsProcessed$).pipe(
                filter((dataTypeExists: boolean[]) => {
                  return dataTypeExists.every((x) => !x);
                }),
                map(() => true)
              );
            }),
            take(1)
          );
        }
      })
    );
  }
}
