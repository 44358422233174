export enum CalculationFunctions {
  abs = 'abs',
  arccos = 'arccos',
  arcsin = 'arcsin',
  arctan = 'arctan',
  cos = 'cos',
  degrees = 'degrees',
  hypotenuse = 'hypotenuse',
  max = 'max',
  min = 'min',
  pi = 'pi',
  radians = 'radians',
  sin = 'sin',
  sqrt = 'sqrt',
  tan = 'tan',
}
