import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromDBFiles from '../reducers/db-file.reducer';

// spec list state
export const selectDBFilesState = (state: FDMState) => state.configsState.dbFiles;

// spec list selectors
export const selectDBFileIds = createSelector(selectDBFilesState, fromDBFiles.selectDBFileIds);

export const selectDBFileEntities = createSelector(
  selectDBFilesState,
  fromDBFiles.selectDBFileEntities
);

export const selectAllDBFiles = createSelector(selectDBFilesState, fromDBFiles.selectAllDBFiles);

export const selectDBFilesTotal = createSelector(
  selectDBFilesState,
  fromDBFiles.selectDBFilesTotal
);

export const selectDBFileById = (id: string) =>
  createSelector(selectDBFileEntities, (dbFileEntities) => dbFileEntities[id]);
