import { HttpBackend } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { snakeCase } from 'lodash';
import * as FormValidators from '@utils/formly/validators-utils';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    { prefix: './assets/i18n/config/', suffix: '.json' },
    { prefix: './assets/i18n/common/', suffix: '.json' },
    { prefix: './assets/i18n/feature-modules/', suffix: '.json' },
    { prefix: './assets/i18n/help-content/', suffix: '.json' },
    { prefix: './assets/i18n/notifications/', suffix: '.json' },
    { prefix: './assets/i18n/datatypes/', suffix: '.json' },
    { prefix: './assets/i18n/enums/', suffix: '.json' },
    { prefix: './assets/i18n/components/badge-tooltip/', suffix: '.json' },
    { prefix: './assets/i18n/components/data-grid/', suffix: '.json' },
    { prefix: './assets/i18n/components/dimensions-table/', suffix: '.json' },
    { prefix: './assets/i18n/components/dynamic-form/', suffix: '.json' },
    { prefix: './assets/i18n/components/dynamic-table/', suffix: '.json' },
    { prefix: './assets/i18n/components/getting-started/', suffix: '.json' },
    { prefix: './assets/i18n/components/entitlements/', suffix: '.json' },
    { prefix: './assets/i18n/components/app-unavailable/', suffix: '.json' },
    { prefix: './assets/i18n/components/graph/', suffix: '.json' },
    { prefix: './assets/i18n/components/missing-settings/', suffix: '.json' },
    { prefix: './assets/i18n/components/multi-select/', suffix: '.json' },
    { prefix: './assets/i18n/components/page-not-found/', suffix: '.json' },
    { prefix: './assets/i18n/components/part-browser/', suffix: '.json' },
    { prefix: './assets/i18n/components/part-search/', suffix: '.json' },
    { prefix: './assets/i18n/components/side-nav/', suffix: '.json' },
    { prefix: './assets/i18n/components/toolbar/', suffix: '.json' },
    { prefix: './assets/i18n/components/tooltip/', suffix: '.json' },
    { prefix: './assets/i18n/components/table-header-menu/', suffix: '.json' },
    { prefix: './assets/i18n/components/thumbnail-editor/', suffix: '.json' },
    { prefix: './assets/i18n/error-handling/', suffix: '.json' },
    { prefix: './assets/i18n/access-rights/', suffix: '.json' },
    { prefix: './assets/i18n/confirmation-modal/', suffix: '.json' },
    { prefix: './assets/i18n/loader/', suffix: '.json' },
  ]);
}

const getRangeWithTranslation = (
  rangeSeparator: string,
  page: number,
  pageSize: number,
  length: number
) => {
  if (length === 0 || pageSize === 0) {
    return `0 ${rangeSeparator} ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} ${rangeSeparator} ${length}`;
};

export function getPaginatorTranslations(translate: TranslateService) {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = translate.instant(LC.DYNAMIC_TABLE.PAGINATOR.ITEMS_PER_PAGE);
  paginatorIntl.nextPageLabel = translate.instant(LC.DYNAMIC_TABLE.PAGINATOR.NEXT_PAGE);
  paginatorIntl.previousPageLabel = translate.instant(LC.DYNAMIC_TABLE.PAGINATOR.PREVIOUS_PAGE);
  paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const rangeSeparator = translate.instant(LC.DYNAMIC_TABLE.PAGINATOR.FROM);
    return getRangeWithTranslation(rangeSeparator, page, pageSize, length);
  };

  return paginatorIntl;
}

export function getI18nConstantRef(key: string, pluralize = false) {
  return `${snakeCase(key).toUpperCase()}${pluralize ? 'S' : ''}`;
}

export function registerTranslateFormlyValidationMessages(translate: TranslateService) {
  return {
    validationMessages: [
      {
        name: 'required',
        message: FormValidators.requiredValidationMessage.bind({ translate }),
      },
      {
        name: 'minLength',
        message: FormValidators.minlengthValidationMessage.bind({ translate }),
      },
      {
        name: 'maxLength',
        message: FormValidators.maxlengthValidationMessage.bind({ translate }),
      },
      { name: 'min', message: FormValidators.minValidationMessage.bind({ translate }) },
      { name: 'max', message: FormValidators.maxValidationMessage.bind({ translate }) },
    ],
  };
}
