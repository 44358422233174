import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromLoadingData from '../reducers/loading-data.reducer';

// system button state
export const selectLoadingDataState = (state: FDMState) => state.applicationState.loadingData;

// LoadingData selectors
export const selectLoadingDataIds = createSelector(
  selectLoadingDataState,
  fromLoadingData.selectLoadingDataIds
);

export const selectLoadingDataEntities = createSelector(
  selectLoadingDataState,
  fromLoadingData.selectLoadingDataEntities
);

export const selectAllLoadingData = createSelector(
  selectLoadingDataState,
  fromLoadingData.selectAllLoadingDatas
);

export const selectLoadingDataTotal = createSelector(
  selectLoadingDataState,
  fromLoadingData.selectLoadingDataTotal
);
