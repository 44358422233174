import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  DynamicTableOptions,
  ColumnDefinition,
  InlineDataTableOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter, map } from 'rxjs/operators';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormSelectType,
} from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditError, InlineEditType } from '@models/inline-edit/inline-edit.options';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import {
  selectCurrentConfigMaterials,
  selectCurrentConfigMaterialSpecs,
} from '@store/selectors/configs.selectors';
import { SchemaService } from '@services/schema.service';
import {
  ValidatorService,
  InlineValidatorFunction,
  CustomErrorMessage,
} from '@services/validator.service';
import {
  createInsulationSpecificationTableEntryFactory,
  InsulationSpecificationTable,
  InsulationSpecificationTableEntrySingleDimension,
  InsulationSpecificationTableEntryType,
  InsulationSpecificationTableType,
} from '@models/fabrication/insulation-specification-info';
import { Material, MaterialType } from '@models/fabrication/material';
import { MaterialSpecification } from '@models/fabrication/material-spec';
import { DataElementType } from '@constants/data-element-types';
import { v4 as uuidv4 } from 'uuid';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { JSONSchema7 } from 'json-schema';
import { EnvironmentConstants } from '@constants/environment-constants';

@Component({
  selector: 'fab-ins-spec-table',
  template: ` <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data> `,
})
export class InsulationSpecificationTableComponent
  extends DynamicFormBaseCustomComponent<InsulationSpecificationTableEntryType[]>
  implements OnInit, OnDestroy
{
  tableOptions: DynamicTableOptions<InsulationSpecificationTableEntryType> = null;
  tableDataSource: BehaviorSubject<InsulationSpecificationTableEntryType[]>;
  tableChangeSubject: Subject<InsulationSpecificationTableEntryType[]> = new Subject<
    InsulationSpecificationTableEntryType[]
  >();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  rows: InsulationSpecificationTableEntryType[];
  customModelChangesSubscription: Subscription;
  materials: Material[] = [];
  materialSpecs: MaterialSpecification[] = [];

  constructor(
    private translate: TranslateService,
    store$: Store<FDMState>,
    private schemaService: SchemaService,
    private validatorService: ValidatorService
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.tableErrorReporterSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  filterValidMaterialsAndSpecs = (
    allMaterials: Material[],
    allMaterialSpecs: MaterialSpecification[]
  ): void => {
    const materialSpecIds = allMaterialSpecs.map((x) => x.externalId);

    // filter materials that:
    // are of type insulation or ductboard
    allMaterials.forEach((x) => {
      const materialSpecReferences = x.fabricationReferences.filter(
        (x) =>
          x.dataType === DataElementType.MaterialSpecification &&
          materialSpecIds.includes(x.externalId)
      );

      if (
        materialSpecReferences.length &&
        (x.materialType === MaterialType.Insulation || x.materialType === MaterialType.Ductboard)
      ) {
        this.materials.push(x);
        this.materialSpecs.push(
          ...materialSpecReferences.map((ref) =>
            allMaterialSpecs.find((y) => y.externalId === ref.externalId)
          )
        );
      }
    });
  };

  getModelUpdates() {
    const getModelChanges$: Observable<InsulationSpecificationTable> = this.formModelUpdater.pipe(
      filter(
        (changeEvent: DynamicFormCustomUpdateEvent) =>
          changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
      ),
      map(
        (changeEvent: DynamicFormCustomUpdateEvent) =>
          changeEvent.data as InsulationSpecificationTable
      )
    );

    this.customModelChangesSubscription = combineLatest([
      getModelChanges$,
      this.store$.select(selectCurrentConfigMaterials(true, false)),
      this.store$.select(selectCurrentConfigMaterialSpecs(true)),
    ]).subscribe(
      (setupData: [InsulationSpecificationTable, Material[], MaterialSpecification[]]) => {
        const [model, allMaterials, allMaterialSpecs] = setupData;
        this.filterValidMaterialsAndSpecs(allMaterials, allMaterialSpecs);
        this.rows = model.entries;
        this.createTableDataOptions();
        this.tableDataSource.next(this.rows);
        this.reportErrors();
      }
    );
  }

  createTableDataOptions() {
    if (!this.tableOptions) {
      this.tableDataSource =
        this.tableDataSource ||
        new BehaviorSubject<InsulationSpecificationTableEntryType[]>(this.rows);

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'part-spec-table',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        maintainSelectionOnUpdate: true,
        columns: this.getColumnDefinitions(),
        subscribeToTableChanges: this.tableChangeSubject,
        tableTypeOptions: {
          newRowData: () => {
            return createInsulationSpecificationTableEntryFactory(
              InsulationSpecificationTableType.SingleDimension
            );
          },
          disableDeleteCondition: 'selectedAll',
        } as InlineDataTableOptions<InsulationSpecificationTableEntryType>,
        errorReporter: this.tableErrorReporterSubject,
        forceFullValidationOnEachChange: true,
        toolbarOptions: {},
        enableMultiSelect: true,
      };
    }
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: InsulationSpecificationTableEntryType[]) => {
        this.rows = rows;
        this.updateSource(this.rows);
      }
    );
  }

  getMaterialSelectFields = (): DynamicFormSelectType[] => {
    const settings: DynamicFormSelectType[] = [
      {
        value: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL,
        label: this.translate.instant(LC.GRAPH.UNASSIGNED),
      },
    ];

    return settings.concat(
      this.materials.map((x) => ({
        label: x.name,
        group: x.category,
        value: x.externalId,
      }))
    );
  };

  getMaterialSpecSelectFields = (materialId?: string): DynamicFormSelectType[] => {
    const noneDefinition = [
      {
        value: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC,
        label: this.translate.instant(LC.GRAPH.UNASSIGNED),
      },
    ];

    if (materialId === EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC || materialId === '-1') {
      return noneDefinition;
    }

    const specs = materialId
      ? this.materials
          .find((x) => x.externalId === materialId)
          .fabricationReferences.filter((x) => x.dataType === DataElementType.MaterialSpecification)
          .map((x) => this.materialSpecs.find((y) => y.externalId === x.externalId))
      : this.materialSpecs;

    const useNoneDefinition = materialId ? [] : noneDefinition;

    return useNoneDefinition.concat(
      specs.map((x) => ({
        label: x.name,
        group: x.category,
        value: x.externalId,
      }))
    );
  };

  getValidator(field: string): InlineValidatorFunction {
    const validators: InlineValidatorFunction[] = [];
    const schema = this.getSchema();

    let customErrorMessage: CustomErrorMessage = null;
    if (field === 'material')
      customErrorMessage = {
        value: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL,
        field,
        message: this.translate.instant(LC.ERROR_HANDLING.INSULATION_SPECS.INVALID_MATERIAL),
      };
    else if (field === 'materialSpecification')
      customErrorMessage = {
        value: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC,
        field,
        message: this.translate.instant(
          LC.ERROR_HANDLING.INSULATION_SPECS.INVALID_MATERIAL_SPECIFICATION
        ),
      };

    const schemaValidator = this.validatorService.getInlineEditSchemaValidator(
      schema.$id,
      customErrorMessage
    );
    validators.push(schemaValidator);

    // todo: add ls/ss validator

    // only attach validators to required fields
    if (field === 'size') {
      const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
        this.rows.map((x) => (x as InsulationSpecificationTableEntrySingleDimension).size),
        'size'
      );
      validators.push(uniqueValidator);
    }

    return this.validatorService.mergeInlineEditValidators(...validators);
  }

  private getSchema(): JSONSchema7 {
    // todo: add checks for tableType when other types are supported
    // currently only supports pipework specs with single table entries
    const parentSchema = this.schemaService.getSchemaByDataElementType(
      DataElementType.InsulationSpecification
    );
    const tableSchema = parentSchema.definitions[
      EnvironmentConstants.FSS_SUB_SCHEMA_INSULATION_SPECIFICATION_TABLE
    ] as JSONSchema7;
    const schema = tableSchema.definitions[
      EnvironmentConstants.FSS_SUB_SCHEMA_SINGLE_DIMENSION_TABLE_ENTRY
    ] as JSONSchema7;

    return schema;
  }

  private getColumnDefinitions(): ColumnDefinition[] {
    const columns: ColumnDefinition[] = [
      {
        field: 'size',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.MAX_SIZE),
        units: ForgeUnitsService.getStandardLengthUnits(this.configUnitSystem),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.units,
          validator: () => this.getValidator('size'),
        },
      },
      {
        field: 'material',
        header: this.translate.instant(LC.DATATYPES.TYPES.MATERIAL),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.select,
          selectFieldOptions: () => this.getMaterialSelectFields(),
          validator: () => this.getValidator('material'),
        },
      },
      {
        field: 'materialSpecification',
        header: this.translate.instant(LC.DATATYPES.TYPES.MATERIAL_SPECIFICATION),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.select,
          selectFieldOptions: () => this.getMaterialSpecSelectFields(),
          validator: () => this.getValidator('materialSpecification'),
          dependantField: 'material',
          filterOnDependantFieldChange: (value: string) => this.getMaterialSpecSelectFields(value),
        },
      },
    ];

    return columns;
  }
}
