import * as fromThubnailFile from '../actions/thumbnail-file.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ThumbnailFile } from '@models/fabrication/files';

export interface ThumbnailFileState extends EntityState<ThumbnailFile> {} // eslint-disable-line

export const thumbnailFileAdapter: EntityAdapter<ThumbnailFile> =
  createEntityAdapter<ThumbnailFile>({
    selectId: (thumbnailFile: ThumbnailFile) => thumbnailFile.contentExternalId,
  });

export const initialState: ThumbnailFileState = thumbnailFileAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromThubnailFile.ThumbnailFileActions
): ThumbnailFileState {
  switch (action.type) {
    case fromThubnailFile.ThumbnailFileActionTypes.UpsertThumbnailFiles: {
      return thumbnailFileAdapter.upsertMany(action.payload, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectThumbnailFileIds,
  selectEntities: selectThumbnailFileEntities,
  selectAll: selectAllThumbnailFile,
  selectTotal: selectThumbnailFileTotal,
} = thumbnailFileAdapter.getSelectors();
