import { FormControl } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { requiredValidationMessage } from '@utils/formly/validators-utils';

export function getErrorMessage(
  formControl: FormControl,
  field: FieldTypeConfig,
  translate: TranslateService
): string {
  let errorMessage = '';
  if (!formControl.errors) return errorMessage;
  const errors = formControl.errors;

  if (errors.required) {
    errorMessage = requiredValidationMessage.call({ translate }, undefined, field);
  }

  if (errors.schemaValidator) {
    errorMessage = errors.schemaValidator?.message({}, field);
  }

  if (errors.uniqueValidator) {
    errorMessage = errors.uniqueValidator?.message;
  }

  if (errors.emailValidator) {
    errorMessage = errors.emailValidator?.message({}, field);
  }

  return errorMessage;
}
