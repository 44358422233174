import { Action } from '@ngrx/store';
import { DataElementType } from '@constants/data-element-types';
import { ApiError } from '@models/errors/api-error';

export enum LoadingDataActionTypes {
  SetDataTypeIsLoading = '[DataTypeLoading] Set DataType is Loading',
  SetDataTypeIsLoaded = '[DataTypeLoading] Set DataType is Loaded',
  SetDataTypeLoadingHasFailed = '[DataTypeLoading] Set DataType Loading Has Failed',
  RemoveConfigLoadingState = '[DataTypeLoading] Remove Config Loading State',
  RemoveConfigDataTypeLoadingState = '[DataTypeLoading] Remove Config Data Type Loading State',
}

export class SetDataTypeIsLoading implements Action {
  readonly type = LoadingDataActionTypes.SetDataTypeIsLoading;

  constructor(public payload: { configId: string; dataType: DataElementType | string }) {}
}

export class SetDataTypeIsLoaded implements Action {
  readonly type = LoadingDataActionTypes.SetDataTypeIsLoaded;

  constructor(public payload: { configId: string; dataType: DataElementType | string }) {}
}

export class SetDataTypeLoadingHasFailed implements Action {
  readonly type = LoadingDataActionTypes.SetDataTypeLoadingHasFailed;

  constructor(public payload: { configId: string; dataLoadingError: ApiError }) {}
}

export class RemoveConfigLoadingState implements Action {
  readonly type = LoadingDataActionTypes.RemoveConfigLoadingState;

  constructor(public payload: { configId: string }) {}
}

export class RemoveConfigDataTypeLoadingState implements Action {
  readonly type = LoadingDataActionTypes.RemoveConfigDataTypeLoadingState;

  constructor(public payload: { configId: string; dataType: DataElementType }) {}
}

export type LoadingDataActions =
  | SetDataTypeIsLoading
  | SetDataTypeIsLoaded
  | SetDataTypeLoadingHasFailed
  | RemoveConfigLoadingState
  | RemoveConfigDataTypeLoadingState;
