import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Project,
  ProfileInfo,
  HelpItem,
  NotificationListItem,
} from '@models/ui-state/hig-navigation';
import { HigTopNav, Props } from './top-nav.react';
import { Theme } from '@models/theme/theme';
import { ThemeService } from '@services/theme.service';
import { FeatureFlagService } from '@services/feature-flag.service';
import { FeatureFlagConstants } from '@constants/feature-flag-constants';
import { createRoot } from 'react-dom/client';
import { diff } from 'deep-object-diff';
import { DebugMode } from '@models/debug-mode/debug-mode';
import { DebugModeService } from '@services/debug-mode.service';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { ActivityService } from '@services/activities-events/activity.service';
import { filter, Subscription, switchMap, take } from 'rxjs';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';

@Component({
  selector: 'hig-top-nav',
  template: ` <div [id]="topNavId"></div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HigTopNavComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() projects: Project[];
  @Input() profileInfo: ProfileInfo;
  @Input() defaultProject: string;
  @Input() activeProject: string;
  @Input() profileSettingsLink: string;
  @Input() sideNavOpened: boolean;
  @Input() helpItems: HelpItem[];
  @Input() notifications: NotificationListItem[];

  @Output() readonly onChangeProject = new EventEmitter<string>();
  @Output() readonly onClickSignOut = new EventEmitter<any>();
  @Output() readonly onClickHamburger = new EventEmitter<any>();
  @Output() readonly onClickLogo = new EventEmitter<any>();
  root;

  public topNavId = 'top-nav-container';

  private prevProjects: Project[] = [];
  private currentConfigId: string = null;
  private configChangeSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    private themeService: ThemeService,
    private featureFlagService: FeatureFlagService,
    private debugModeService: DebugModeService,
    private activityService: ActivityService,
    private store$: Store<FDMState>
  ) {}

  handleChangeLanguage(language) {
    this.translate.use(language);
  }

  ngAfterViewInit(): void {
    this.renderTopNav();
    this.fetchConfigErrorActivities();
  }

  ngOnChanges(): void {
    this.renderTopNav();
  }

  ngOnDestroy() {
    this.root.unmount();
    this.configChangeSubscription?.unsubscribe();
  }

  onClickTheme(theme: Theme): void {
    this.themeService.changeTheme(theme);
    this.renderTopNav();
  }

  hasProjectsUpdated(): boolean {
    if (this.prevProjects.length !== this.projects.length) return true;
    if (this.prevProjects === this.projects) return false;

    return this.projects.some((project, index) => !!diff(project, this.prevProjects[index]));
  }

  fetchConfigErrorActivities(): void {
    this.configChangeSubscription = this.store$
      .select(selectCurrentConfig)
      .pipe(
        filter((config) => config?.id && config.id !== this.currentConfigId),
        switchMap((config) => {
          this.currentConfigId = config.id;

          return this.activityService
            .loadConfigErrorActivityStreamItems(this.currentConfigId)
            .pipe(take(1));
        })
      )
      .subscribe((streamItems) => {
        streamItems.forEach((item) => {
          const { action, displayName, dataType } = item.activity.object.data;
          const dataTypeTranslation = this.translate.instant(
            LC.DATATYPES.TYPES[DataElementTypeUtils.getLocalisationConstantRef(dataType, false)]
          );

          this.activityService.showActivityErrorNotification(
            item,
            this.currentConfigId,
            action,
            dataTypeTranslation,
            displayName
          );
        });
      });
  }

  renderTopNav = (): void => {
    const parentElement = document.getElementById(this.topNavId);
    if (parentElement) {
      const props: Props = {
        isLoading: this.isLoading,
        projects: this.projects,
        defaultProject: this.defaultProject,
        profileInfo: this.profileInfo,
        profileSettingsLink: this.profileSettingsLink,
        sideNavOpened: this.sideNavOpened,
        helpItems: this.helpItems,
        notifications: this.notifications,
        onClickSignOut: ($event) => this.onClickSignOut.emit($event),
        onChangeProject: ($event) => this.onChangeProject.emit($event),
        onClickHamburger: ($event) => this.onClickHamburger.emit($event),
        onClickLogo: ($event) => this.onClickLogo.emit($event),
        onClickTheme: (theme) => this.onClickTheme(theme),
        onClickDebugMode: (debugMode: DebugMode) => this.debugModeService.setDebugMode(debugMode),
        themeData: this.themeService.getSelectedThemeData(true),
        logo: this.themeService.selectedLogo,
        showSettingsButton: this.featureFlagService.isFeatureFlagEnabled(
          FeatureFlagConstants.THEME_SETTINGS
        ),
        techPreview: this.featureFlagService.isFeatureFlagEnabled(
          FeatureFlagConstants.RELEASE_GRADUATION
        )
          ? ''
          : this.translate.instant(LC.APP.TITLE_TECH_PREVIEW),
      };

      if (this.hasProjectsUpdated()) {
        this.root.unmount();
        this.root = null;
      }
      this.prevProjects = this.projects;
      if (!this.root) this.root = createRoot(parentElement);
      this.root.render(HigTopNav(props));
    }
  };
}
