import { Injectable } from '@angular/core';
import { EnvironmentService } from '@services/environment.service';
import { Observable, timer } from 'rxjs';
import { ResilientHttpService } from '@services/http/resilient-http.service';
import { Entitlement } from '@models/entitlements/entitlement';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EntitlementConstants } from '@constants/entitlement-constants';
import { NavigationConstants } from '@constants/navigation-constants';
import {
  EntitlementsLoadForCurrentUser,
  EntitlementsLoadForCurrentUserSuccess,
} from '@store/actions/entitlements.action';
import { selectEntitlementCheckComplete } from '@store/selectors/application.selectors';
import { selectEntitlementsById } from '@store/selectors/entitlements.selectors';
import { SetApplicationIsDisabled } from '@store/actions/application.action';

@Injectable({
  providedIn: 'root',
})
export class EntitlementService {
  constructor(
    private envService: EnvironmentService,
    private httpService: ResilientHttpService,
    private store$: Store<FDMState>,
    private router: Router
  ) {}

  public getCurrentUserEntitlement(): Observable<Entitlement> {
    // use the resilient http client so we can add the ngsw-bypass query param. adding the header to the SDK call throws a CORS error.
    const bypassSwQuery = '?ngsw-bypass=true';
    const url = `${this.envService.environment.fabdmCm.baseUrl}v1/entitlements${bypassSwQuery}`;

    return this.httpService
      .get<Entitlement>(url, {
        headers: {
          'x-fabdm-app-id': 'fabdm',
        },
      })
      .pipe(
        tap((entitlement: Entitlement) => {
          const revalidateInSeconds = 3600;
          if (entitlement.auth) {
            console.log(
              `Entitlement check successful, revalidating in ${revalidateInSeconds} seconds`
            );
            // schedule next entitlement check setting interval returned by api call
            this.getNextUserEntitlementCheck(revalidateInSeconds).subscribe();
          } else {
            console.warn(
              `Entitlement check failed, no valid license found for ${EntitlementConstants.FDM_FEATURE_ID}`
            );
          }
        })
      );
  }

  private getNextUserEntitlementCheck = (interval: number): Observable<Entitlement> => {
    return timer(Math.floor(interval * 1000)).pipe(
      switchMap(() => this.getCurrentUserEntitlement()),
      tap((entitlement: Entitlement) => {
        this.store$.dispatch(new EntitlementsLoadForCurrentUserSuccess({ entitlement }));
        this.store$.dispatch(new SetApplicationIsDisabled({ disabled: false }));
        if (!entitlement.auth) {
          this.store$.dispatch(new SetApplicationIsDisabled({ disabled: true }));
          this.router.navigate([NavigationConstants.NO_ENTITLEMENT_LINK]);
        }
      }),
      take(1)
    );
  };

  /**
   * Run check to determine if entitlement check has been made,
   * use function as part of pre routing guard functions.
   * Note - failure will return a UrlTree which when returned as part of a
   * guard to trigger the current navigation to be cancelled and a redirect started
   * @return {*}  {(Observable<boolean | UrlTree>)}
   * @memberof EntitlementService
   */
  public hasEntitlementCheckBeenRun(): Observable<boolean | UrlTree> {
    return this.store$.select(selectEntitlementCheckComplete).pipe(
      tap((checkHasBeenMade: boolean) => {
        if (!checkHasBeenMade) {
          this.store$.dispatch(new EntitlementsLoadForCurrentUser());
        }
      }),
      filter((checkHasBeenMade: boolean) => checkHasBeenMade),
      switchMap(() =>
        this.store$.select(selectEntitlementsById(EntitlementConstants.FDM_FEATURE_ID))
      ),
      map(
        (entitlement: Entitlement) =>
          entitlement?.auth || this.router.createUrlTree([NavigationConstants.NO_ENTITLEMENT_LINK])
      ),
      take(1)
    );
  }
}
