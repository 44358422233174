import * as fromSystemInfo from '../actions/system-info.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SystemInfo } from '@models/fabrication/system-info';

export interface SystemInfoState extends EntityState<SystemInfo> {} // eslint-disable-line

export const systemInfoAdapter: EntityAdapter<SystemInfo> = createEntityAdapter<SystemInfo>({
  selectId: (systemInfo: SystemInfo) => systemInfo.id,
});

export const initialState: SystemInfoState = systemInfoAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromSystemInfo.SystemInfoActions
): SystemInfoState {
  switch (action.type) {
    case fromSystemInfo.SystemInfoActionTypes.LoadSystemInfos: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.LoadSystemInfosSuccess: {
      return systemInfoAdapter.upsertMany(action.data, state);
    }
    case fromSystemInfo.SystemInfoActionTypes.LoadSystemInfosFail: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.UpdateSystemInfo: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.UpdateSystemInfoSuccess: {
      return systemInfoAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromSystemInfo.SystemInfoActionTypes.UpdateSystemInfoFail: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.AddSystemInfo: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.AddSystemInfoSuccess: {
      return systemInfoAdapter.addOne(action.dataElement, state);
    }
    case fromSystemInfo.SystemInfoActionTypes.AddSystemInfoFail: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.CopySystemInfo: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.CopySystemInfoSuccess: {
      return systemInfoAdapter.addOne(action.dataElement, state);
    }
    case fromSystemInfo.SystemInfoActionTypes.CopySystemInfoFail: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.DeleteSystemInfos: {
      return state;
    }
    case fromSystemInfo.SystemInfoActionTypes.DeleteSystemInfosSuccess: {
      return systemInfoAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromSystemInfo.SystemInfoActionTypes.DeleteSystemInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of system info ids
  selectIds: selectSystemInfoIds,

  // select the dictionary of system info entities
  selectEntities: selectSystemInfoEntities,

  // select the array of system info
  selectAll: selectAllSystemInfo,

  // select the total system info count
  selectTotal: selectSystemInfoTotal,
} = systemInfoAdapter.getSelectors();
