import { Injectable } from '@angular/core';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Observable, Subject } from 'rxjs';
import { DataElementType } from '@constants/data-element-types';

export interface UpgradeChange<T extends ForgeContentDataElement> {
  type: 'start' | 'complete';
  data: T;
  dataType: DataElementType;
}

@Injectable({
  providedIn: 'root',
})
export class DataUpgradeService<T extends ForgeContentDataElement> {
  private dataUpgradeSubject: Subject<UpgradeChange<T>> = new Subject<UpgradeChange<T>>();

  public listenToDataUpgradeChanges(): Observable<UpgradeChange<T>> {
    return this.dataUpgradeSubject.pipe();
  }

  public submitDataUpgradeChange(data: UpgradeChange<T>): void {
    this.dataUpgradeSubject.next(data);
  }
}
