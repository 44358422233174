import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromStiffenerSpecs from '../reducers/stiffener-spec.reducer';

// stiffener spec state
export const selectStiffenerSpecState = (state: FDMState) => state.configsState.stiffenerSpecs;

// stiffener spec selectors
export const selectStiffenerSpecIds = createSelector(
  selectStiffenerSpecState,
  fromStiffenerSpecs.selectStiffenerSpecIds
);

export const selectStiffenerSpecEntities = createSelector(
  selectStiffenerSpecState,
  fromStiffenerSpecs.selectStiffenerSpecEntities
);

export const selectAllStiffenerSpecs = createSelector(
  selectStiffenerSpecState,
  fromStiffenerSpecs.selectAllStiffenerSpecs
);

export const selectStiffenerSpecsTotal = createSelector(
  selectStiffenerSpecState,
  fromStiffenerSpecs.selectStiffenerSpecsTotal
);

export const selectStiffenerSpecById = (id: string) =>
  createSelector(selectStiffenerSpecEntities, (stiffenerSpecEntities) => stiffenerSpecEntities[id]);
