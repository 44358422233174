import { DamperInfo } from '@models/fabrication/damper-info';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsSuccessAction,
  DeleteDataElementsFailAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

const dataType = DataElementType.Damper;

export enum DamperInfoActionTypes {
  LoadDamperInfos = '[DamperInfo API] Load DamperInfos',
  LoadDamperInfosFail = '[DamperInfo API] Load DamperInfos Fail',
  LoadDamperInfosSuccess = '[DamperInfo API] Load DamperInfos Success',
  UpdateDamperInfo = '[Damper Info API] Update DamperInfo',
  UpdateDamperInfoFail = '[Damper Info API] Update DamperInfo Fail',
  UpdateDamperInfoSuccess = '[Damper Info API] Update DamperInfo Success',
  AddDamperInfo = '[Damper Info API] Add DamperInfo',
  AddDamperInfoFail = '[Damper Info API] Add DamperInfo Fail',
  AddDamperInfoSuccess = '[Damper Info API] Add DamperInfo Success',
  DeleteDamperInfos = '[Damper Info API] Delete DamperInfos',
  DeleteDamperInfosFail = '[Damper Info API] Delete DamperInfos Fail',
  DeleteDamperInfosSuccess = '[Damper Info API] Delete DamperInfos Success',
  CopyDamperInfo = '[Damper Info API] Copy DamperInfo',
  CopyDamperInfoFail = '[Damper Info API] Copy DamperInfo Fail',
  CopyDamperInfoSuccess = '[Damper Info API] Copy DamperInfo Success',
}

export class LoadDamperInfos implements LoadDataElementsAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadDamperInfosSuccess();
  failAction = new LoadDamperInfosFail();
  readonly type = DamperInfoActionTypes.LoadDamperInfos;
  dataElementType = DataElementType.Damper;
  nodeId = EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadDamperInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DamperInfoActionTypes.LoadDamperInfosFail;
}

export class LoadDamperInfosSuccess implements LoadDataElementsSuccessAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Complete;
  data: DamperInfo[];
  readonly type = DamperInfoActionTypes.LoadDamperInfosSuccess;
}

export class UpdateDamperInfo implements UpdateDataElementAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: DamperInfo;
  successAction = new UpdateDamperInfoSuccess();
  failAction = new UpdateDamperInfoFail();
  readonly type = DamperInfoActionTypes.UpdateDamperInfo;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: DamperInfo }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateDamperInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DamperInfoActionTypes.UpdateDamperInfoFail;
}

export class UpdateDamperInfoSuccess implements UpdateDataElementSuccessAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: DamperInfo;
  readonly type = DamperInfoActionTypes.UpdateDamperInfoSuccess;
}

export class AddDamperInfo implements AddDataElementAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: DamperInfo;
  successAction = new AddDamperInfoSuccess();
  failAction = new AddDamperInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = DamperInfoActionTypes.AddDamperInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: DamperInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class AddDamperInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DamperInfoActionTypes.AddDamperInfoFail;
}

export class AddDamperInfoSuccess implements AddDataElementSuccessAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: DamperInfo;
  readonly type = DamperInfoActionTypes.AddDamperInfoSuccess;
}

export class CopyDamperInfo implements CopyDataElementAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: DamperInfo;
  successAction = new CopyDamperInfoSuccess();
  failAction = new CopyDamperInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = DamperInfoActionTypes.CopyDamperInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: DamperInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopyDamperInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DamperInfoActionTypes.CopyDamperInfoFail;
}

export class CopyDamperInfoSuccess implements CopyDataElementSuccessAction<DamperInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: DamperInfo;
  readonly type = DamperInfoActionTypes.CopyDamperInfoSuccess;
}

export class DeleteDamperInfos implements DeleteDataElementsAction<DamperInfo> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: DamperInfo[];
  successAction = new DeleteDamperInfosSuccess();
  failAction = new DeleteDamperInfosFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS;
  isExternalNodeId = true;
  readonly type = DamperInfoActionTypes.DeleteDamperInfos;

  constructor(public payload: { config: Config; dataElements: DamperInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteDamperInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DamperInfoActionTypes.DeleteDamperInfosFail;
}

export class DeleteDamperInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = DamperInfoActionTypes.DeleteDamperInfosSuccess;
}

export type DamperInfoActions =
  | LoadDamperInfos
  | LoadDamperInfosFail
  | LoadDamperInfosSuccess
  | UpdateDamperInfo
  | UpdateDamperInfoFail
  | UpdateDamperInfoSuccess
  | AddDamperInfo
  | AddDamperInfoFail
  | AddDamperInfoSuccess
  | DeleteDamperInfos
  | DeleteDamperInfosFail
  | DeleteDamperInfosSuccess
  | CopyDamperInfo
  | CopyDamperInfoFail
  | CopyDamperInfoSuccess;
