import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter, take, switchMap, tap } from 'rxjs/operators';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormSelectType,
} from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditError, InlineEditType } from '@models/inline-edit/inline-edit.options';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import {
  PartReference,
  ServiceTemplateSizeRestriction,
} from '@models/fabrication/service-template-info';
import { StorageFile, ThumbnailFile } from '@models/fabrication/files';
import { selectThumbnailFileById } from '@store/selectors/thumbnail-file.selectors';
import { BinaryStorageService } from '@cache/binary-storage.service';
import {
  ToolBarButton,
  ToolBarButtons,
  ToolBarButtonType,
} from '@models/tool-bar/tool-bar-options';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { DynamicTableComponent } from '@shared/components/dynamic-table/dynamic-table.component';
import { selectAllParts } from '@store/selectors/part.selectors';
import { Part } from '@models/fabrication/part';
import { ImageUrlUtils } from '@utils/image-url-utils';

@Component({
  selector: 'fab-part-collection-table',
  template: ` <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data> `,
  styleUrls: ['./part-collection-table.component.scss'],
})
export class PartCollectionTableComponent
  extends DynamicFormBaseCustomComponent<PartReference[]>
  implements OnInit, OnDestroy
{
  tableOptions: DynamicTableOptions<PartReference> = null;
  tableDataSource: BehaviorSubject<PartReference[]>;
  tableChangeSubject: Subject<PartReference[]> = new Subject<PartReference[]>();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  rows: PartReference[];
  partReferences: PartReference[];
  parts: Part[];
  thumbnailFiles: ThumbnailFile[];
  sizeRestrictions: ServiceTemplateSizeRestriction[];
  thumbnailStorageFiles: StorageFile[];

  constructor(
    private translate: TranslateService,
    store$: Store<FDMState>,
    private fileStorageService: BinaryStorageService,
    private changeRef: ChangeDetectorRef,
    private imageUrlUtils: ImageUrlUtils
  ) {
    super(store$);
  }

  ngOnInit() {
    this.sizeRestrictions = this.model.sizeRestrictions ?? [];
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    if (this.tableChangeSubscription) {
      this.tableChangeSubscription.unsubscribe();
    }

    if (this.tableErrorReporterSubscription) {
      this.tableErrorReporterSubscription.unsubscribe();
    }
  }

  getThumbnailContentFiles(): Observable<ThumbnailFile[]> {
    return combineLatest(
      this.partReferences.map((part) =>
        this.store$.select(selectThumbnailFileById(part.contentExternalId))
      )
    );
  }

  getThumbnailStorageFiles(): Observable<StorageFile[]> {
    const thumbnailData: string[] = [];
    this.partReferences.forEach((part) => {
      const thumbnailFile = this.thumbnailFiles.find(
        (thumbnail) => thumbnail.contentExternalId === part.contentExternalId
      );
      if (thumbnailFile?.objectKey) {
        thumbnailData.push(thumbnailFile.objectKey);
      }
    });

    return this.fileStorageService.getImageFiles(thumbnailData);
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe((rows: PartReference[]) => {
      this.rows = rows;
      this.updateSource(this.rows);
    });
  }

  getModelUpdates() {
    this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
        ),
        switchMap((changeEvent: DynamicFormCustomUpdateEvent) => {
          this.partReferences = changeEvent.data.parts;
          return combineLatest([
            this.getThumbnailContentFiles(),
            this.store$.select(selectAllParts),
          ]).pipe(
            tap((fileData: [ThumbnailFile[], Part[]]) => {
              const [thumbnailFiles, parts] = fileData;
              this.thumbnailFiles = thumbnailFiles.filter((x) => !!x);

              this.parts = [];
              this.partReferences.forEach((x) => {
                const part = parts.find((y) => y.externalId === x.contentExternalId);
                if (part) {
                  this.parts.push(part);
                }
              });
            }),
            switchMap(() => this.getThumbnailStorageFiles()), // dependent on thumbnails array so call later
            tap((thumbnailStorageFiles: StorageFile[]) => {
              this.thumbnailStorageFiles = thumbnailStorageFiles;
            })
          );
        }),
        take(1)
      )
      .subscribe(() => {
        this.rows = this.model.parts;
        this.createTableDataOptions();
        this.tableDataSource.next(this.rows);
        this.reportErrors();
        this.changeRef.detectChanges();
      });
  }

  createTableDataOptions() {
    if (!this.tableOptions) {
      this.tableDataSource =
        this.tableDataSource || new BehaviorSubject<PartReference[]>(this.rows);

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'part-collection-table',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        maintainSelectionOnUpdate: true,
        columns: this.getColumnDefinitions(),
        subscribeToTableChanges: this.tableChangeSubject,
        errorReporter: this.tableErrorReporterSubject,
        forceFullValidationOnEachChange: true,
        enableMultiSelect: true,
        toolbarOptions: {
          permanentToolBarButtons: { visible: false, buttons: [] },
          contextToolBarButtons: {
            buttons: [
              {
                type: ToolBarButtonType.DELETE,
                action: (partReference: PartReference) => this.removeParts([partReference]),
                disableCondition: 'selectedAll',
                isDisabled: false,
                label: this.translate.instant(LC.TOOLBAR.LABEL.DELETE),
                tooltipTitle: this.translate.instant(LC.TOOLTIP.REMOVE_PART_REFERENCE),
                tooltipDescription: this.translate.instant(LC.TOOLTIP.DELETE_DISABLED),
                shouldBeInvisible: (table: DynamicTableComponent<unknown>) => {
                  return table.selection.selected.length !== 1;
                },
              },
            ] as ToolBarButton<any>[],
          } as ToolBarButtons<PartReference>,
          multipleContextToolBarButtons: {
            visible: false,
            buttons: [
              {
                type: ToolBarButtonType.DELETE,
                action: (partReferences) => this.removeParts(partReferences),
                disableCondition: 'selectedAll',
                isDisabled: false,
                label: this.translate.instant(LC.TOOLBAR.LABEL.DELETE),
                tooltipTitle: this.translate.instant(LC.TOOLTIP.REMOVE_PART_REFERENCES),
                tooltipDescription: this.translate.instant(LC.TOOLTIP.DELETE_DISABLED),
                shouldBeInvisible: (table: DynamicTableComponent<unknown>) => {
                  return table.selection.selected.length < 2;
                },
              },
            ],
          } as ToolBarButtons<any>,
        },
      };
    }
  }

  removeParts(partReferences: PartReference[]) {
    // part collections can have the same file multiple times, so we don't want to remove all instances
    partReferences.forEach((partReference) => {
      const index = this.model.parts.findIndex(
        (part: PartReference) => part.contentExternalId === partReference.contentExternalId
      );
      if (index >= 0) {
        this.model.parts.splice(index, 1);
      }
    });

    this.updateSource(this.model.parts);
    this.tableDataSource.next(this.model.parts);
  }

  private getColumnDefinitions(): ColumnDefinition[] {
    const columns: ColumnDefinition[] = [
      {
        field: 'name',
        formatter: (value: string, row: PartReference) => {
          const part = this.parts.find((x) => x.externalId === row.contentExternalId);
          return part?.name ?? '';
        },
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICE_TEMPLATES.PART),
        imageUrl: (row: PartReference) => {
          let thumbnailStorageFile: StorageFile = null;
          const thumbnailFile = this.thumbnailFiles.find(
            (x) => x.contentExternalId === row.contentExternalId
          );
          if (thumbnailFile) {
            thumbnailStorageFile = this.thumbnailStorageFiles.find(
              (x) => x.id === thumbnailFile.objectKey
            );
          }

          return this.imageUrlUtils.getImageUrlFromThumbnailStorageFile(thumbnailStorageFile);
        },
      },
      {
        field: 'sizeRestrictionId',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICE_TEMPLATES.SIZE_RESTRICTION),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.select,
          selectFieldOptions: () => this.getSizeRestrictionSelectFields(),
        },
      },
    ];

    return columns;
  }

  private getSizeRestrictionSelectFields(): DynamicFormSelectType[] {
    const settings: DynamicFormSelectType[] = [];

    return settings.concat(
      this.sizeRestrictions.map((sizeRestriction) => ({
        label: sizeRestriction.description,
        value: sizeRestriction.id,
      }))
    );
  }
}
