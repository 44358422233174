import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromRealTimeActivity from '../reducers/activity-real-time.reducer';
import { RealTimeActivityMarker } from '@models/activities-events/activities';
import { DataElementType } from '@constants/data-element-types';

export const selectRealTimeActivityState = (state: FDMState) =>
  state.applicationState.realtimeActivityMarkers;

export const selectRealTimeActivityIds = createSelector(
  selectRealTimeActivityState,
  fromRealTimeActivity.selectRealTimeRealTimeActivityIds
);

export const selectRealTimeActivityEntities = createSelector(
  selectRealTimeActivityState,
  fromRealTimeActivity.selectRealTimeActivityEntities
);

export const selectAllRealTimeActivity = createSelector(
  selectRealTimeActivityState,
  fromRealTimeActivity.selectAllRealTimeActivity
);

export const selectRealTimeActivityTotal = createSelector(
  selectRealTimeActivityState,
  fromRealTimeActivity.selectRealTimeActivityTotal
);

export const selectRealTimeActivityById = (id: string) =>
  createSelector(
    selectRealTimeActivityEntities,
    (realTimeActivityEntities): RealTimeActivityMarker => realTimeActivityEntities[id]
  );

export const selectRealTimeActivityContainsDataType = (id: string, dataType: DataElementType) =>
  createSelector(
    selectRealTimeActivityEntities,
    (realTimeActivityEntities): boolean =>
      realTimeActivityEntities[id] &&
      !!realTimeActivityEntities[id].dataTypes.find((x) => x === dataType)
  );
