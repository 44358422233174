import { Action } from '@ngrx/store';
import {
  AccessRightsData,
  AcmExtraPermissionId,
  AssignUserRole,
  ConfigUsers,
} from '@models/access-rights/access-rights';
import { Config } from '@models/fabrication/config';
import { DataLoadingAction, DataLoadingState } from './base/data-loading.action';

export enum AccessRightsActionTypes {
  AccessRightsLoadRole = '[Access Rights Api] Load Role',
  AccessRightsLoadRoleSuccess = '[Access Rights Api] Load Role Success',
  AccessRightsLoadUsers = '[Access Rights Api] Load Users',
  AccessRightsLoadUsersSuccess = '[Access Rights Api] Load Users Success',
  AccessRightsLoadUsersFail = '[Access Rights Api] Load Users Fail',
  AccessRightsAddUser = '[Access Rights Api] Add User',
  AccessRightsAddUserSuccess = '[Access Rights Api] Add User Success',
  AccessRightsAddUserFail = '[Access Rights Api] Add User Fail',
  AccessRightsRemoveUsers = '[Access Rights Api] Remove Users',
  AccessRightsRemoveUsersSuccess = '[Access Rights Api] Remove Users Success',
  AccessRightsRemoveUsersFail = '[Access Rights Api] Remove Users Fail',
  AccessRightsRemoveData = '[Access Rights Api] Remove Data',
}

export class AccessRightsLoadRole implements Action {
  readonly type = AccessRightsActionTypes.AccessRightsLoadRole;

  constructor(
    public payload: {
      config: Config;
    }
  ) {}
}

export class AccessRightsLoadRoleSuccess implements Action {
  readonly type = AccessRightsActionTypes.AccessRightsLoadRoleSuccess;

  constructor(
    public payload: {
      data: AccessRightsData;
    }
  ) {}
}

export class AccessRightsLoadUsers implements Action {
  readonly type = AccessRightsActionTypes.AccessRightsLoadUsers;

  constructor(
    public payload: {
      config: Config;
    }
  ) {}
}

export class AccessRightsLoadUsersSuccess implements Action {
  readonly type = AccessRightsActionTypes.AccessRightsLoadUsersSuccess;

  constructor(
    public payload: {
      id: string; // config id
      users: ConfigUsers; // config users
    }
  ) {}
}

export class AccessRightsLoadUsersFail implements Action {
  readonly type = AccessRightsActionTypes.AccessRightsLoadUsersFail;

  constructor(
    public payload: {
      id: string; // config id
    }
  ) {}
}

export class AccessRightsAddUser implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsAddUser;
  dataLoadingState = DataLoadingState.Loading;
  constructor(
    public payload: {
      config: Config;
      emailId: string;
      role: AssignUserRole;
      extraPermissions: AcmExtraPermissionId[];
    }
  ) {}
}

export class AccessRightsAddUserSuccess implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsAddUserSuccess;
  dataLoadingState = DataLoadingState.Complete;
  constructor(
    public payload: {
      id: string; // config id
      users: ConfigUsers; // config users
    }
  ) {}
}

export class AccessRightsAddUserFail implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsAddUserFail;
  dataLoadingState = DataLoadingState.Failed;
  constructor(
    public payload: {
      id: string; // config id
      statusCode?: number;
    }
  ) {}
}

export class AccessRightsRemoveUsers implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsRemoveUsers;
  dataLoadingState = DataLoadingState.Loading;
  constructor(
    public payload: {
      config: Config;
      userIds: string[];
    }
  ) {}
}

export class AccessRightsRemoveUsersSuccess implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsRemoveUsersSuccess;
  dataLoadingState = DataLoadingState.Complete;
  constructor(
    public payload: {
      id: string; // config id
      users: ConfigUsers; // config users
    }
  ) {}
}

export class AccessRightsRemoveUsersFail implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsRemoveUsersFail;
  dataLoadingState = DataLoadingState.Failed;
  constructor(
    public payload: {
      id: string; // config id
    }
  ) {}
}

export class AccessRightsRemoveData implements Action, DataLoadingAction {
  readonly type = AccessRightsActionTypes.AccessRightsRemoveData;
  dataLoadingState = DataLoadingState.Ignore;
  constructor(
    public payload: {
      id: string; // config id
    }
  ) {}
}

export type AccessRightsActions =
  | AccessRightsLoadRole
  | AccessRightsLoadRoleSuccess
  | AccessRightsLoadUsers
  | AccessRightsLoadUsersSuccess
  | AccessRightsLoadUsersFail
  | AccessRightsAddUser
  | AccessRightsAddUserSuccess
  | AccessRightsAddUserFail
  | AccessRightsRemoveUsers
  | AccessRightsRemoveUsersSuccess
  | AccessRightsRemoveUsersFail
  | AccessRightsRemoveData;
