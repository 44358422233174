export enum ShapeType {
  Rectangular = 'Rectangular',
  Round = 'Round',
  Oval = 'Oval',
}

export enum DomainType {
  NotSet = 'NotSet',
  HVAC = 'HVAC',
  Pipework = 'Pipework',
  ElectricalContainment = 'ElectricalContainment',
}

export enum DataViewType {
  'Table' = 'Table',
  'Tile' = 'Tile',
}
