import { PartMap } from '@models/fabrication/part';
import { DomSanitizer } from '@angular/platform-browser';
import { URLSanitizerPipe } from '@shared/pipes/dom-sanitizer.pipe';
import { Injectable } from '@angular/core';
import { StorageFile } from '@models/fabrication/files';

@Injectable({
  providedIn: 'root',
})
export class ImageUrlUtils {
  urlSanitizerPipe: URLSanitizerPipe;
  readonly IMAGE_PLACEHOLDER = '/assets/hig/24/placeholder.svg';

  constructor(private sanitizer: DomSanitizer) {
    this.urlSanitizerPipe = new URLSanitizerPipe(this.sanitizer);
  }

  public getImageUrlFromPartMap(partMap: PartMap): string {
    let imageUrl: string;
    if (partMap?.thumbnailStorageFile) {
      imageUrl = this.urlSanitizerPipe.transform(partMap.thumbnailStorageFile.contents);
    } else {
      imageUrl = partMap?.part?.thumbnailUrl || this.IMAGE_PLACEHOLDER;
    }

    return imageUrl;
  }

  public getImageUrlFromThumbnailStorageFile(
    thumbnailStorageFile?: StorageFile,
    thumbnail?: Blob
  ): string {
    let imageUrl: string;
    if (thumbnailStorageFile || thumbnail) {
      imageUrl = this.urlSanitizerPipe.transform(thumbnailStorageFile?.contents || thumbnail);
    } else {
      imageUrl = this.IMAGE_PLACEHOLDER;
    }

    return imageUrl;
  }

  public getTrustResourceImageUrl(url: string): string {
    return url ? this.urlSanitizerPipe.transform(null, url) : this.IMAGE_PLACEHOLDER;
  }
}
