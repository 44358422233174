/// <reference lib="webworker" />

import { DoWork, runWorker } from 'observable-webworker';
import { Observable, catchError, defer, map, switchMap, tap, throwError } from 'rxjs';
import FabdmWebAssembly from '@adsk/fabdm-web-assembly';

export enum WebAssemblyOperations {
  initialiseEngine,
  initialiseDatabase,
  reload,
  getPartData,
}

export interface InitialiseEngineInfo {
  binary: ArrayBuffer;
}

export interface DatabaseInfo {
  configId: string;
  db: {
    fileName: string;
    fileContents: Blob;
  }[];
}

export interface GetPartData {
  configId: string;
  partFile: {
    fileName: string;
    fileContents: Blob;
    id: string;
  };
  params?: string;
  languageId: number;
  enableSleepDebugMode?: boolean;
}

export interface WebAssemblyOperationRequest {
  operationType: WebAssemblyOperations;
  initialiseEngine?: InitialiseEngineInfo;
  initialiseDatabase?: DatabaseInfo;
  reloadData?: DatabaseInfo;
  getPartData?: GetPartData;
}

export interface WebAssemblyOperationResponse {
  operationType: WebAssemblyOperations;
  initialiseEngine?: boolean;
  initialiseDatabase?: boolean;
  reloadData?: boolean;
  getPartData?: string;
}

export class WebAssemblyWorker
  implements DoWork<WebAssemblyOperationRequest, WebAssemblyOperationResponse>
{
  private webAssemblyEngine: any = null;

  public work(
    operation$: Observable<WebAssemblyOperationRequest>
  ): Observable<WebAssemblyOperationResponse> {
    return operation$.pipe(
      switchMap((operationRequest: WebAssemblyOperationRequest) => {
        switch (operationRequest.operationType) {
          case WebAssemblyOperations.initialiseEngine:
            return this.initialiseEngine(operationRequest.initialiseEngine);
          case WebAssemblyOperations.initialiseDatabase:
            return this.initialiseDatabase(operationRequest.initialiseDatabase);
          case WebAssemblyOperations.reload:
            return this.reloadDb(operationRequest.reloadData);
          case WebAssemblyOperations.getPartData:
            return this.getPartData(operationRequest.getPartData);
          default:
            return throwError(() => 'unexpected request');
        }
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  private initialiseEngine(data: InitialiseEngineInfo): Observable<WebAssemblyOperationResponse> {
    return defer(() => FabdmWebAssembly({ wasmBinary: data.binary })).pipe(
      map((engine: unknown) => {
        this.webAssemblyEngine = engine;
        return {
          operationType: WebAssemblyOperations.initialiseEngine,
          initialiseEngine: !!engine,
        };
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  private initialiseDatabase(data: DatabaseInfo): Observable<WebAssemblyOperationResponse> {
    return defer(() =>
      this.webAssemblyEngine.initialiseFabricationDatabase(data.configId, data.db)
    ).pipe(
      map((response: boolean) => {
        return {
          operationType: WebAssemblyOperations.initialiseDatabase,
          initialiseDatabase: response,
        };
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  private reloadDb(data: DatabaseInfo): Observable<WebAssemblyOperationResponse> {
    return defer(() => this.webAssemblyEngine.reloadDbFiles(data.configId, data.db)).pipe(
      map((response: boolean) => {
        return {
          operationType: WebAssemblyOperations.reload,
          reloadData: response,
        };
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  private getPartData(data: GetPartData): Observable<WebAssemblyOperationResponse> {
    return defer(() =>
      this.webAssemblyEngine.getFabricationPartData(
        data.configId,
        data.partFile,
        data.params,
        data.languageId
      )
    ).pipe(
      tap(() => {
        if (data.enableSleepDebugMode) {
          this.webAssemblyEngine.sleep(5000);
        }
      }),
      map((response: string) => {
        return {
          operationType: WebAssemblyOperations.getPartData,
          getPartData: response,
        };
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }
}

runWorker(WebAssemblyWorker);
