import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromConfigs from '../reducers/configs.reducer';
import * as fromSpecs from './specification.selectors';
import * as fromInsulationSpecs from './insulation-specification.selectors';
import * as fromSystemInfos from './system-info.selectors';
import * as fromConnectorInfos from './connector-info.selectors';
import * as fromDamperInfos from './damper-info.selectors';
import * as fromStiffenerInfos from './stiffener-info.selectors';
import * as fromLoadingData from './loading-data.selectors';
import * as fromDBFiles from './db-file.selectors';
import * as fromPartTemplateInfos from './service-template-info.selectors';
import * as fromFabricationRates from './fabrication-rate.selectors';
import * as fromInstallationRates from './installation-rate.selectors';
import * as fromMaterials from './material.selectors';
import * as fromMaterialSpecs from './material-spec.selectors';
import * as fromInvalidData from './invalid-data.selectors';
import * as fromStiffenerSpecs from './stiffener-spec.selectors';
import { DataElementType } from '@constants/data-element-types';
import { InvalidData } from '@models/fabrication/invalid-data';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { Dictionary } from '@ngrx/entity';
import { Material, MaterialType } from '@models/fabrication/material';
import { MaterialFinish } from '@models/fabrication/material-finish';
import { selectAllParts } from './part.selectors';

// configs state
export const selectConfigsState = (state: FDMState) => state.configsState.configs;

export const selectApplicationState = (state: FDMState) => state.applicationState;

// configs selectors
export const selectConfigIds = createSelector(selectConfigsState, fromConfigs.selectConfigIds);

export const selectConfigEntities = createSelector(
  selectConfigsState,
  fromConfigs.selectConfigEntities
);

export const selectAllConfigs = createSelector(selectConfigsState, fromConfigs.selectAllConfigs);

export const selectConfigTotal = createSelector(selectConfigsState, fromConfigs.selectConfigTotal);

export const selectCurrentConfig = createSelector(
  selectConfigEntities,
  selectApplicationState,
  (configEntities, state) => configEntities[state.applicationData.currentConfigExternalId]
);

// use selector with props rather than function with args
// selectors with props can be overriden in mockStore making
// testing simpler
export const selectConfigById = (id: string) =>
  createSelector(selectConfigEntities, (configEntities) => configEntities[id]);

export const selectConfigByUrn = (urn: string) =>
  createSelector(selectAllConfigs, (configs) => configs?.find((x) => x.id === urn));

export const selectCurrentInvalidData = createSelector(
  selectCurrentConfig,
  fromInvalidData.selectInvalidDataEntities,
  (config, allInvalidData) => {
    return config?.invalidData ? config?.invalidData.map((x) => allInvalidData[x]) : null;
  }
);

export const selectCurrentInvalidDataByDataType = (dataType: DataElementType) =>
  createSelector(
    selectCurrentInvalidData,
    (invalidData: InvalidData[]) =>
      invalidData?.filter(
        (x) => DataElementTypeUtils.getDataTypeFromSchema(x.schemaType, true) === dataType
      ) ?? []
  );

export const selectCurrentConfigSpecificationInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromSpecs.selectSpecEntities,
    selectCurrentInvalidData,
    (config, allSpecs, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.specificationInfos,
        allSpecs,
        invalidData,
        DataElementType.Specification,
        includeInvalidData
      )
  );

export const selectCurrentConfigInsulationSpecificationInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromInsulationSpecs.selectInsulationSpecEntities,
    selectCurrentInvalidData,
    (config, allInsulationSpecs, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.insulationSpecificationInfos,
        allInsulationSpecs,
        invalidData,
        DataElementType.InsulationSpecification,
        includeInvalidData
      )
  );

export const selectCurrentConfigSystemInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromSystemInfos.selectSystemInfoEntities,
    selectCurrentInvalidData,
    (config, allSystemInfos, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.systemInfos,
        allSystemInfos,
        invalidData,
        DataElementType.Service,
        includeInvalidData
      )
  );

export const selectCurrentConfigServiceTemplateInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromPartTemplateInfos.selectServiceTemplateInfoEntities,
    selectCurrentInvalidData,
    (config, allPartTemplateInfos, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.partTemplates,
        allPartTemplateInfos,
        invalidData,
        DataElementType.ServiceTemplate,
        includeInvalidData
      )
  );

export const selectCurrentConfigConnectorInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromConnectorInfos.selectConnectorInfoEntities,
    selectCurrentInvalidData,
    (config, allConnectorInfos, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.connectorInfos,
        allConnectorInfos,
        invalidData,
        DataElementType.Connector,
        includeInvalidData
      )
  );

export const selectCurrentConfigDamperInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromDamperInfos.selectDamperInfoEntities,
    selectCurrentInvalidData,
    (config, allDamperInfos, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.damperInfos,
        allDamperInfos,
        invalidData,
        DataElementType.Damper,
        includeInvalidData
      )
  );

export const selectCurrentConfigStiffenerInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromStiffenerInfos.selectStiffenerInfoEntities,
    selectCurrentInvalidData,
    (config, allStiffenerInfos, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.stiffenerInfos,
        allStiffenerInfos,
        invalidData,
        DataElementType.Stiffener,
        includeInvalidData
      )
  );

export const selectCurrentConfigMaterials = (
  includeInvalidData: boolean,
  includeFinishes: boolean
) =>
  createSelector(
    selectCurrentConfig,
    fromMaterials.selectMaterialEntities,
    selectCurrentInvalidData,
    (config, allMaterials, invalidData: InvalidData[]) => {
      const data = selectFilteredData(
        config?.materials,
        allMaterials,
        invalidData,
        DataElementType.Material,
        includeInvalidData
      ) as (Material | MaterialFinish)[];

      if (includeFinishes) return data;

      return data.filter((x) => x.materialType !== MaterialType.Finish) as Material[];
    }
  );

export const selectCurrentConfigMaterialSpecs = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromMaterialSpecs.selectMaterialSpecEntities,
    selectCurrentInvalidData,
    (config, allMaterialSpecs, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.materialSpecs,
        allMaterialSpecs,
        invalidData,
        DataElementType.MaterialSpecification,
        includeInvalidData
      )
  );

export const selectCurrentConfigStiffenerSpecificationInfos = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromStiffenerSpecs.selectStiffenerSpecEntities,
    selectCurrentInvalidData,
    (config, allStiffenerSpecs, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.stiffenerSpecificationInfos,
        allStiffenerSpecs,
        invalidData,
        DataElementType.StiffenerSpecification,
        includeInvalidData
      )
  );

export const selectCurrentConfigLoadingData = createSelector(
  selectCurrentConfig,
  fromLoadingData.selectLoadingDataEntities,
  (config, allLoadingData) => allLoadingData[config?.externalId]
);

export const selectCurrentConfigDBFiles = createSelector(
  selectCurrentConfig,
  fromDBFiles.selectDBFileEntities,
  (config, allDBFiles) =>
    config?.databaseFiles ? config?.databaseFiles.map((x) => allDBFiles[x]) : null
);

export const selectCurrentFabricationRates = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromFabricationRates.selectFabricationRateEntities,
    selectCurrentInvalidData,
    (config, allRates, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.fabricationRates,
        allRates,
        invalidData,
        DataElementType.FabricationRate,
        includeInvalidData
      )
  );

export const selectCurrentInstallationRates = (includeInvalidData: boolean) =>
  createSelector(
    selectCurrentConfig,
    fromInstallationRates.selectInstallationRateEntities,
    selectCurrentInvalidData,
    (config, allRates, invalidData: InvalidData[]) =>
      selectFilteredData(
        config?.installationRates,
        allRates,
        invalidData,
        DataElementType.InstallationRate,
        includeInvalidData
      )
  );

const selectFilteredData = (
  dataIds: string[],
  dictionary: Dictionary<any>,
  invalidData: InvalidData[],
  dataElementType: DataElementType,
  includeInvalidData: boolean
) => {
  if (!dataIds) {
    return null;
  }

  const mainData = dataIds.map((x) => dictionary[x]);
  if (!includeInvalidData || !invalidData?.length) {
    return mainData;
  }

  return mainData.concat(
    invalidData?.filter(
      (x) => DataElementTypeUtils.getDataTypeFromSchema(x.schemaType, true) === dataElementType
    )
  );
};

export const getReferenceSelector = (dataType: DataElementType) => {
  switch (dataType) {
    case DataElementType.Part:
      return selectAllParts;
    case DataElementType.ServiceTemplate:
      return selectCurrentConfigServiceTemplateInfos(true);
    case DataElementType.Specification:
      return selectCurrentConfigSpecificationInfos(true);
    case DataElementType.InsulationSpecification:
      return selectCurrentConfigInsulationSpecificationInfos(true);
    case DataElementType.Connector:
      return selectCurrentConfigConnectorInfos(true);
    case DataElementType.Damper:
      return selectCurrentConfigDamperInfos(true);
    case DataElementType.Stiffener:
      return selectCurrentConfigStiffenerInfos(true);
    case DataElementType.FabricationRate:
      return selectCurrentFabricationRates(true);
    case DataElementType.InstallationRate:
      return selectCurrentInstallationRates(true);
    case DataElementType.Material:
      return selectCurrentConfigMaterials(true, true);
    case DataElementType.MaterialSpecification:
      return selectCurrentConfigMaterialSpecs(true);
    case DataElementType.StiffenerSpecification:
      return selectCurrentConfigStiffenerSpecificationInfos(true);
    default:
      throw new Error('Unknown reference data type');
  }
};
