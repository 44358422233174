import { Action } from '@ngrx/store';
import { ThumbnailFile } from '@models/fabrication/files';

export enum ThumbnailFileActionTypes {
  UpsertThumbnailFiles = '[ThumbnailFile] Upsert ThumbnailFiles',
}

export class UpsertThumbnailFiles implements Action {
  readonly type = ThumbnailFileActionTypes.UpsertThumbnailFiles;

  constructor(public payload: ThumbnailFile[]) {}
}

export type ThumbnailFileActions = UpsertThumbnailFiles;
