import { Material } from '@models/fabrication/material';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsSuccessAction,
  DeleteDataElementsFailAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';
import { MaterialFinish } from '@models/fabrication/material-finish';
import { JSONSchema7 } from 'json-schema';

const dataType = DataElementType.Material;

export enum MaterialActionTypes {
  LoadMaterials = '[Material API] Load Materials',
  LoadMaterialsFail = '[Material API] Load Materials Fail',
  LoadMaterialsSuccess = '[Material API] Load Materials Success',
  UpdateMaterial = '[Material API] Update Material',
  UpdateMaterialFail = '[Material API] Update Material Fail',
  UpdateMaterialSuccess = '[Material API] Update Material Success',
  AddMaterial = '[Material API] Add Material',
  AddMaterialFail = '[Material API] Add Material Fail',
  AddMaterialSuccess = '[Material API] Add Material Success',
  DeleteMaterials = '[Material API] Delete Material',
  DeleteMaterialsFail = '[Material API] Delete Materials Fail',
  DeleteMaterialsSuccess = '[Material API] Delete Materials Success',
  CopyMaterial = '[Material API] Copy Material',
  CopyMaterialFail = '[Material API] Copy Material Fail',
  CopyMaterialSuccess = '[Material API] Copy Material Success',
}

export class LoadMaterials implements LoadDataElementsAction<Material | MaterialFinish> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadMaterialsSuccess();
  failAction = new LoadMaterialsFail();
  readonly type = MaterialActionTypes.LoadMaterials;
  dataElementType = DataElementType.Material;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIALS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadMaterialsFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialActionTypes.LoadMaterialsFail;
}

export class LoadMaterialsSuccess
  implements LoadDataElementsSuccessAction<Material | MaterialFinish>
{
  dataLoadingState = DataLoadingState.Complete;
  data: (Material | MaterialFinish)[];
  readonly type = MaterialActionTypes.LoadMaterialsSuccess;
}

export class UpdateMaterial implements UpdateDataElementAction<Material | MaterialFinish> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: Material | MaterialFinish;
  successAction = new UpdateMaterialSuccess();
  failAction = new UpdateMaterialFail();
  readonly type = MaterialActionTypes.UpdateMaterial;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIALS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(public payload: { dataElement: Material | MaterialFinish; schema: JSONSchema7 }) {
    this.dataElement = payload.dataElement;
    this.schema = payload.schema;
  }
}

export class UpdateMaterialFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialActionTypes.UpdateMaterialFail;
}

export class UpdateMaterialSuccess
  implements UpdateDataElementSuccessAction<Material | MaterialFinish>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: Material | MaterialFinish;
  readonly type = MaterialActionTypes.UpdateMaterialSuccess;
}

export class AddMaterial implements AddDataElementAction<Material | MaterialFinish> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: Material | MaterialFinish;
  successAction = new AddMaterialSuccess();
  failAction = new AddMaterialFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = MaterialActionTypes.AddMaterial;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIALS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(
    public payload: { config: Config; dataElement: Material | MaterialFinish; schema: JSONSchema7 }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.schema = payload.schema;
  }
}

export class AddMaterialFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialActionTypes.AddMaterialFail;
}

export class AddMaterialSuccess implements AddDataElementSuccessAction<Material | MaterialFinish> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: Material | MaterialFinish;
  readonly type = MaterialActionTypes.AddMaterialSuccess;
}

export class CopyMaterial implements CopyDataElementAction<Material | MaterialFinish> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: Material | MaterialFinish;
  successAction = new CopyMaterialSuccess();
  failAction = new CopyMaterialFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = MaterialActionTypes.CopyMaterial;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIALS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(
    public payload: { config: Config; dataElement: Material | MaterialFinish; schema: JSONSchema7 }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.schema = payload.schema;
  }
}

export class CopyMaterialFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialActionTypes.CopyMaterialFail;
}

export class CopyMaterialSuccess
  implements CopyDataElementSuccessAction<Material | MaterialFinish>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: Material | MaterialFinish;
  readonly type = MaterialActionTypes.CopyMaterialSuccess;
}

export class DeleteMaterials implements DeleteDataElementsAction<Material | MaterialFinish> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: (Material | MaterialFinish)[];
  successAction = new DeleteMaterialsSuccess();
  failAction = new DeleteMaterialsFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIALS;
  isExternalNodeId = true;
  readonly type = MaterialActionTypes.DeleteMaterials;

  constructor(public payload: { config: Config; dataElements: (Material | MaterialFinish)[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteMaterialsFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialActionTypes.DeleteMaterialsFail;
}

export class DeleteMaterialsSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = MaterialActionTypes.DeleteMaterialsSuccess;
}

export type MaterialActions =
  | LoadMaterials
  | LoadMaterialsFail
  | LoadMaterialsSuccess
  | UpdateMaterial
  | UpdateMaterialFail
  | UpdateMaterialSuccess
  | AddMaterial
  | AddMaterialFail
  | AddMaterialSuccess
  | DeleteMaterials
  | DeleteMaterialsFail
  | DeleteMaterialsSuccess
  | CopyMaterial
  | CopyMaterialFail
  | CopyMaterialSuccess;
