import { Injectable } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';

// todo: create standard list/table routes
// logic here is too fuzzy and error prone
const TABLE_VIEW_ROUTE_REGEXP =
  /\/data\/config\/[0-9a-f]+(-[0-9a-f]+)*\/(?!parts)\w+(-\w+)*(\?.*)?$/;
const ADMIN_RESOLVE_TABLE_VIEW_ROUTE_REGEXP =
  /\/data\/config\/[0-9a-f]+(-[0-9a-f]+)*\/admin\/resolve$/;
const RELATIONSHIP_VIEW_ROUTE_REGEXP =
  /^\/data\/config\/[0-9a-f]+(-[0-9a-f]+)*\/\w+(-\w+)*\/.+\/graph(\?.*)?$/;
const PARTS_VIEW_ROUTE_REGEXP = /^\/data\/config\/[0-9a-f]+(-[0-9a-f]+)*\/parts$/;

@Injectable({
  providedIn: 'root',
})
export class RouterExtraInfoService {
  private previousUrl = '/';
  private previousMasterRouteUrl = '';

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((e: [Event, Event]) => {
        this.previousUrl = (e[0] as RouterEvent).url;
      });

    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd &&
            (TABLE_VIEW_ROUTE_REGEXP.test(e.url) ||
              RELATIONSHIP_VIEW_ROUTE_REGEXP.test(e.url) ||
              PARTS_VIEW_ROUTE_REGEXP.test(e.url) ||
              ADMIN_RESOLVE_TABLE_VIEW_ROUTE_REGEXP.test(e.url))
        )
      )
      .subscribe((e: Event) => {
        this.previousMasterRouteUrl = (e as RouterEvent).url;
      });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  /**
   * It returns the preivuos master page
   * master page are pages like Relationship Manager
   * or Table View
   */
  public getPreviousMasterRouteUrl() {
    return this.previousMasterRouteUrl;
  }
}
