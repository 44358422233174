import { OnDestroy, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize_url',
})
export class URLSanitizerPipe implements PipeTransform, OnDestroy {
  objectUrl = null;

  constructor(private sanitizer: DomSanitizer) {}

  transform(blob?: Blob, url?: string): string {
    if (!blob && !url) return '';
    this.objectUrl = blob ? URL.createObjectURL(blob) : url;
    return this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(this.objectUrl)
    );
  }

  ngOnDestroy() {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
  }
}
