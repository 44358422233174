import RichText from '@weave-design/rich-text';
import { useEffect, useState, FunctionComponent } from 'react';
import Spacer from '@weave-design/spacer';
import Typography from '@weave-design/typography';
import ThemeContext from '@weave-design/theme-context';

const stepTitle = '';
const stepBody = '';

export interface IMyComponentProps {
  upicode: string;
  sLanguage: string;
  prod: boolean;
  cID: string;
  themeData: unknown;
}

export const Steps: FunctionComponent<IMyComponentProps> = (props: IMyComponentProps) => {
  const [body, setBody] = useState(stepBody);
  const [title, setTitle] = useState(stepTitle);

  useEffect(() => {
    if (props.cID !== 'FDM-undefined' && props.cID !== 'FDM-config-info') {
      const bURL = props.prod
        ? 'https://beehive.autodesk.com/community/service/rest/caas/resource/caasdoc2/?p='
        : 'https://beehive-stage.autodesk.com/community/service/rest/caas/resource/caasdoc2/?p=';

      const getURL = bURL + props.upicode + '&l=' + props.sLanguage + '&contextid=' + props.cID;

      fetch(getURL)
        .then((response) => response.json())
        .then((data) => {
          setTitle(data?.entries?.item[0]?.title);
          setBody(data?.entries?.item[0]?.caasContent);
          const el1 = document.getElementById('CloudHelp');
          if (el1) {
            const q = el1.querySelectorAll('video');
            const h2 = el1.querySelectorAll('h2');
            for (let k = 0; k < q.length; k++) {
              if (q[k].getAttribute('type') === 'video/webm') {
                const urlstring = "videoURL('" + q[k].getAttribute('src') + "')";
                const analysticsclick =
                  "videoAnalytics('" +
                  q[k].getAttribute('src') +
                  "', '" +
                  data.entries.item['0'].title +
                  "')";
                q[k - 1].setAttribute('onclick', urlstring + '; ' + analysticsclick);
              }
              q[k].removeAttribute('controls');
              q[k].setAttribute('style', 'width:250px;height:175px;padding:0px');
            }
            for (let i = 0; i < h2.length; i++) {
              h2[i].setAttribute('style', 'font-size:16px; font-weight:bold;');
            }
          }
        });
    }
  }, [props.cID, props.upicode, props.sLanguage, props.prod, body, props.themeData]);

  return (
    <ThemeContext.Provider value={props.themeData}>
      <div className="StepsContent">
        <Spacer spacing="s" />
        <Typography className="toolbarHeader" fontWeight="bold">
          {title}
        </Typography>
        <Spacer spacing="s" />
        <RichText>
          <div dangerouslySetInnerHTML={{ __html: body }} className="stepsBody" />
        </RichText>
      </div>
    </ThemeContext.Provider>
  );
};
