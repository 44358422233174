import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ReactFactoryComponent, ReactProp } from '../../../react-factory/react-factory.component';
import { FABTextField, FABTextFieldProps } from './text-field.react';
import { Subject } from 'rxjs';
import { TextFieldVariants } from '@weave-mui/text-field';

@Component({
  selector: 'fab-text-field',
  template: ReactFactoryComponent.template,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FABTextFieldComponent extends ReactFactoryComponent<FABTextFieldProps> {
  @Input() @ReactProp('INPUT') value: string;
  @Input() @ReactProp('INPUT') label: string;
  @Input() @ReactProp('INPUT') variant: TextFieldVariants;
  @Input() @ReactProp('INPUT') required: boolean;
  @Input() @ReactProp('INPUT') error: boolean;
  @Input() @ReactProp('INPUT') helperText: string;
  @Input() @ReactProp('INPUT') disabled: boolean;
  @Input() @ReactProp('INPUT') validationFunction: Subject<string>;
  @Input() @ReactProp('INPUT') autoComplete: string;
  @Input() @ReactProp('INPUT') fullWidth: boolean;
  @Input() @ReactProp('INPUT') placeholder: string;
  @Input() @ReactProp('INPUT') startIcon: any;
  @Input() @ReactProp('INPUT') endIcon: any;
  @Input() @ReactProp('INPUT') multiline: boolean;
  @Input() @ReactProp('INPUT') minRows: number | string;
  @Input() @ReactProp('INPUT') maxRows: number | string;
  @Input() @ReactProp('INPUT') tooltip: string;
  @Input() @ReactProp('INPUT') autoFocus: boolean;

  @Output() @ReactProp('OUTPUT') onChange = new EventEmitter<string>();
  @Output() @ReactProp('OUTPUT') onEndIconClick = new EventEmitter<any>();

  constructor(injector: Injector) {
    super(injector);
  }

  getComponent() {
    return FABTextField;
  }
}
