import { Injectable } from '@angular/core';
import { initialize, LDClient, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { EnvironmentService } from './environment.service';
import { defer, Observable, of, Subject } from 'rxjs';
import { map, catchError, tap, switchMap, retry } from 'rxjs/operators';
import { LoggingService } from '@services/logging.service';
import { AuthService } from './auth.service';
import { UserAnalytics } from '@models/auth/user-data';
import { ResiliencyConstants as rc } from '@constants/resiliency-constants';

export type FeatureFlagChangeResult = {
  [key: string]: {
    current: string | number | boolean;
    previous: string | number | boolean;
  };
};
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private isInitialised = false;
  private client: LDClient = null;
  private flagDefaultSate = false;
  private overRideForLocalDev = false;

  private _featureFlagChangeSubject: Subject<FeatureFlagChangeResult> =
    new Subject<FeatureFlagChangeResult>();

  public get featureFlagChanges(): Observable<FeatureFlagChangeResult> {
    return this._featureFlagChangeSubject.asObservable();
  }

  public constructor(
    private environmentService: EnvironmentService,
    private loggingService: LoggingService,
    private authService: AuthService
  ) {}

  public initialise(): Observable<boolean> {
    if (this.isInitialised) {
      return of(true);
    }

    const cosEnv = this.environmentService.environment.cosEnvironment;
    const featureFlagsClientKey = this.authService.currentKeyData.featureFlagsKey;
    if (!featureFlagsClientKey) {
      this.overRideForLocalDev = this.environmentService.environment.isLocal;
      if (this.overRideForLocalDev) {
        console.log('Overriding feature flags for local dev proxy build');
        this.isInitialised = true;
        return of(true);
      } else {
        this.loggingService.logError(new Error('Feature flags client key was not supplied'));
        return of(false);
      }
    }

    return this.authService.getOxygenAnalyticsId().pipe(
      switchMap((userAnalytics: UserAnalytics) => {
        // check if we have ixygen analytics id
        // to create use correct user details
        let featureFlagsUser: LDSingleKindContext = null;
        if (userAnalytics) {
          const currentUser = this.authService.currentUserData;
          featureFlagsUser = {
            kind: 'user',
            key: userAnalytics.analyticsId,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.emailId,
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            avatar: currentUser.profileImages.sizeX40,
          };
        } else {
          featureFlagsUser = { kind: 'user', anonymous: true };
        }

        this.client = initialize(featureFlagsClientKey, featureFlagsUser);
        return defer(() => this.client.waitForInitialization()).pipe(
          tap(() => {
            console.log('Feature Flag Client Initialised');
            this.client.on('change', (changes: FeatureFlagChangeResult) => {
              this._featureFlagChangeSubject.next(changes);
              console.log('Feature Flags changed event:', changes);
            });
            this.isInitialised = true;
            this.flagDefaultSate = cosEnv === 'dev';
          }),
          map(() => true)
        );
      }),
      retry(rc.DEFAULT_RETRY_COUNT),
      catchError((err: any) => {
        this.client = null;
        this.loggingService.logError(err);
        return of(false);
      })
    );
  }

  public isFeatureFlagEnabled(featureFlag: string): boolean {
    return (
      // e2e tests need to return true for dampers and stiffeners, but not for FC2.0
      this.overRideForLocalDev ||
      (this.client && this.client.variation(featureFlag, this.flagDefaultSate)) ||
      false
    );
  }

  public featureFlagNumberValue(featureFlag: string): number {
    return (this.client && this.client.variation(featureFlag, 0)) || 0;
  }
}
