<div>
  <fab-alert
    [severity]="bannerNotification.type"
    [message]="bannerNotification.message"
    (onBannerDismiss)="handleOnBannerDismiss()"
    [isVisible]="showBannerNotification"
    [buttons]="notificationButtons"
    [link]="notificationLink"
  >
  </fab-alert>

  <fab-toast-list
    [toastList]="toastNotifications"
    (onDismissToast)="handleOnDismissToast($event)"
  ></fab-toast-list>
</div>
