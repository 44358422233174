import { escapeRegExp } from 'lodash';

export const CopyUtils = {
  getCopiedString: (
    text: string,
    allTexts: string[],
    notAppendCopy?: boolean
  ): { copiedText: string; matchedIndex: number } => {
    const copySuffix = notAppendCopy ? '' : '- Copy';
    const copyRegEx = new RegExp(`^${escapeRegExp(text)} ${copySuffix}\\s?(\\(\\d+\\))?$`, 'i');
    let matchedIndex = -1;

    const existingCounts = allTexts.flatMap<{ count: number; index: number }>((str, index) => {
      if (str === text) {
        matchedIndex = index;
        return [];
      }
      const matches = str.match(copyRegEx);
      if (!matches?.length) return [];
      if (!matches[1]) return [{ count: 1, index }];
      const count = parseInt(matches[1].substring(1, matches[1].length - 1));
      return [{ count, index }];
    });

    //If there does not exist any such text return it as it is.
    if (matchedIndex === -1) return { copiedText: text, matchedIndex };

    existingCounts.sort((a, b) => a.count - b.count);
    let newCount = notAppendCopy ? 2 : 1;
    for (let i = 0; i < existingCounts.length; i++) {
      if (newCount !== existingCounts[i].count) {
        break;
      }
      matchedIndex = existingCounts[i].index;
      newCount++;
    }

    const copiedTextParts: string[] = [text];

    if (copySuffix) copiedTextParts.push(copySuffix);
    if (newCount > 1) copiedTextParts.push(`(${newCount})`);
    const copiedText = copiedTextParts.join(' ');

    return { copiedText, matchedIndex };
  },
};
