import { InjectionToken } from '@angular/core';
import { ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';
import * as fromItemFolderTreeData from './item-folder-tree.reducer';
import * as fromPagingData from './paging-data.reducer';
import * as fromConfigs from './configs.reducer';
import * as fromServiceTemplateInfo from './service-template-info.reducer';
import * as fromContentNode from './content-node.reducer';
import * as fromApplication from './application.reducer';
import * as fromSpecs from './specification.reducer';
import * as fromInsulationSpecs from './insulation-specs.reducer';
import * as fromSystemInfos from './system-info.reducer';
import * as fromConnectorInfos from './connector-info.reducer';
import * as fromDamperInfos from './damper-info.reducer';
import * as fromStiffenerInfos from './stiffener-info.reducer';
import * as fromMaterials from './material.reducer';
import * as fromMaterialSpecs from './material-spec.reducer';
import * as fromLoadingData from './loading-data.reducer';
import * as fromDBFiles from './db-file.reducer';
import * as fromContentFiles from './content-file.reducer';
import * as fromParts from './part.reducer';
import * as fromFabricationRates from './fabrication-rate.reducer';
import * as fromInstallationRates from './installation-rate.reducer';
import * as fromTempModels from './temp-model.reducer';
import * as fromAppDependencies from './app-dependency.reducer';
import * as fromCacheData from './cache-data.reducer';
import * as fromActivity from './activity.reducer';
import * as fromRealTimeActivity from './activity-real-time.reducer';
import * as fromBinaryActivity from './binary-activity.reducer';
import * as fromInvalidData from './invalid-data.reducer';
import * as fromThumbnailFiles from './thumbnail-file.reducer';
import * as fromAccessRights from './access-rights.reducer';
import * as fromEntitlements from './entitlements.reducer';
import * as fromExtraDataElementInfos from './extra-data-element-info.reducer';
import * as fromStiffenerSpecs from './stiffener-spec.reducer';
import * as fromPartFilter from './part-filter.reducer';
import * as fromFullScreenMode from './fullscreen-mode.reducer';

export interface FDMState {
  uiState: {
    itemFolderTreeData: fromItemFolderTreeData.ItemFolderTreeState;
    pagingData: fromPagingData.PagingDataState;
    fullScreenMode: fromFullScreenMode.FullScreenModeState;
  };
  configsState: {
    configs: fromConfigs.ConfigsState;
    serviceTemplateInfos: fromServiceTemplateInfo.ServiceTemplateInfoState;
    specs: fromSpecs.SpecificationsState;
    insulationSpecs: fromInsulationSpecs.InsulationSpecificationsState;
    systemInfos: fromSystemInfos.SystemInfoState;
    connectorInfos: fromConnectorInfos.ConnectorInfoState;
    damperInfos: fromDamperInfos.DamperInfoState;
    stiffenerInfos: fromStiffenerInfos.StiffenerInfoState;
    dbFiles: fromDBFiles.DBFileState;
    contentFiles: fromContentFiles.ContentFileState;
    thumbnailFiles: fromThumbnailFiles.ThumbnailFileState;
    contentNodes: fromContentNode.ContentNodeState;
    parts: fromParts.PartState;
    fabricationRates: fromFabricationRates.FabricationRateState;
    installationRates: fromInstallationRates.InstallationRateState;
    materials: fromMaterials.MaterialState;
    materialSpecs: fromMaterialSpecs.MaterialSpecState;
    invalidData: fromInvalidData.InvalidDataState;
    extraDataElementInfos: fromExtraDataElementInfos.ExtraDataElementInfoState;
    stiffenerSpecs: fromStiffenerSpecs.StiffenerSpecState;
  };
  applicationState: {
    applicationData: fromApplication.ApplicationState;
    accessRightsData: fromAccessRights.AccessRightsState;
    loadingData: fromLoadingData.LoadingDataState;
    tempModels: fromTempModels.TempModelState;
    appDependencies: fromAppDependencies.AppDependencyState;
    cacheData: fromCacheData.CacheDataState;
    failedActivitySubmissions: fromActivity.ActivityState;
    realtimeActivityMarkers: fromRealTimeActivity.RealTimeActivityState;
    binaryActivityMarkers: fromBinaryActivity.BinaryActivityState;
    entitlements: fromEntitlements.EntitlementsState;
    partFiler: fromPartFilter.PartFilterState;
  };
}

export const reducers: ActionReducerMap<FDMState> = {
  uiState: combineReducers({
    itemFolderTreeData: fromItemFolderTreeData.reducer,
    pagingData: fromPagingData.reducer,
    fullScreenMode: fromFullScreenMode.reducer,
  }),
  configsState: combineReducers({
    configs: fromConfigs.reducer,
    serviceTemplateInfos: fromServiceTemplateInfo.reducer,
    itemFolders: fromContentNode.reducer,
    specs: fromSpecs.reducer,
    insulationSpecs: fromInsulationSpecs.reducer,
    systemInfos: fromSystemInfos.reducer,
    connectorInfos: fromConnectorInfos.reducer,
    damperInfos: fromDamperInfos.reducer,
    stiffenerInfos: fromStiffenerInfos.reducer,
    dbFiles: fromDBFiles.reducer,
    contentFiles: fromContentFiles.reducer,
    thumbnailFiles: fromThumbnailFiles.reducer,
    contentNodes: fromContentNode.reducer,
    parts: fromParts.reducer,
    fabricationRates: fromFabricationRates.reducer,
    installationRates: fromInstallationRates.reducer,
    materials: fromMaterials.reducer,
    materialSpecs: fromMaterialSpecs.reducer,
    invalidData: fromInvalidData.reducer,
    extraDataElementInfos: fromExtraDataElementInfos.reducer,
    stiffenerSpecs: fromStiffenerSpecs.reducer,
  }),
  applicationState: combineReducers({
    applicationData: fromApplication.reducer,
    accessRightsData: fromAccessRights.reducer,
    loadingData: fromLoadingData.reducer,
    tempModels: fromTempModels.reducer,
    appDependencies: fromAppDependencies.reducer,
    cacheData: fromCacheData.reducer,
    failedActivitySubmissions: fromActivity.reducer,
    realtimeActivityMarkers: fromRealTimeActivity.reducer,
    binaryActivityMarkers: fromBinaryActivity.reducer,
    entitlements: fromEntitlements.reducer,
    partFiler: fromPartFilter.reducer,
  }),
};

// reducer token and reducerProvider required so AOT builds do not fail
// https://github.com/ngrx/store/issues/444#issuecomment-316045026
export const reducerToken = new InjectionToken<ActionReducerMap<FDMState>>('Registered Reducers');

export const reducerProvider = [{ provide: reducerToken, useValue: reducers }];

export const metaReducers: MetaReducer<FDMState>[] = [];
