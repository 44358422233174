import { Action } from '@ngrx/store';
import { Config } from '@models/fabrication/config';
import { Update } from '@ngrx/entity';
import { DataLoadingAction, DataLoadingState } from './base/data-loading.action';
import { ThumbnailFile } from '@models/fabrication/files';

export interface UpdateConfigDataReferences extends Action {
  payload: Update<string[]>;
  deleteReferences: boolean;
}

export enum ConfigsActionTypes {
  LoadConfigs = '[Configs API] Load Configs',
  LoadConfigsFail = '[Configs API] Load Configs Fail',
  LoadConfigsSuccess = '[Configs API] Load Configs Success',
  UpdateConfig = '[Configs API] Update Config',
  UpdateConfigFail = '[Configs API] Update Config Fail',
  UpdateConfigSuccess = '[Configs API] Update Config Success',
  DeleteConfig = '[Configs API] Delete Config',
  DeleteConfigFail = '[Configs API] Delete Config Fail',
  DeleteConfigSuccess = '[Configs API] Delete Config Success',
  UpdateConfigSpecificationIds = '[Configs API] Update Config Specification Ids',
  UpdateConfigInsulationSpecificationIds = '[Configs API] Update Config Insulation Specification Ids',
  UpdateConfigServiceTemplateInfoIds = '[Configs API] Update Config Service Template Info Ids',
  UpdateConfigSystemInfoIds = '[Configs API] Update Config SystemInfo Ids',
  UpdateConfigConnectorInfoIds = '[Configs API] Update Config ConnectorInfo Ids',
  UpdateConfigDamperInfoIds = '[Configs API] Update Config DamperInfo Ids',
  UpdateConfigStiffenerInfoIds = '[Configs API] Update Config StiffenerInfo Ids',
  UpdateConfigDatabaseFileIds = '[Configs API] Update Config Database File Ids',
  UpdateConfigItemFolderIds = '[Configs API] Update Config Item Folder Ids',
  UpdateConfigFabricationRateIds = '[Configs API] Update Config Fabrication Rate Ids',
  UpdateConfigInstallationRateIds = '[Configs API] Update Config Installation Rate Ids',
  UpdateConfigMaterialIds = '[Configs API] Update Config Material Ids',
  UpdateConfigMaterialSpecIds = '[Configs API] Update Config Material Spec Ids',
  UpdateConfigInvalidDataIds = '[Configs API] Update Config Invalid Data Ids',
  UpdateConfigStiffenerSpecificationIds = '[Configs API] Update Config Stiffener Specification Ids',
  // config access rights
  // granted/revoked actions
  LoadGrantedConfig = '[Configs API] Load Granted Config',
  LoadGrantedConfigFail = '[Configs API] Load Granted Config Fail',
  LoadGrantedConfigSuccess = '[Configs API] Load Granted Config Success',
  RevokeConfig = '[Configs API] Revoke Config',
  RevokeConfigFail = '[Configs API] Revoke Config Fail',
  RevokeConfigSuccess = '[Configs API] Revoke Config Success',
}

export class LoadConfigs implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Loading;
  readonly type = ConfigsActionTypes.LoadConfigs;
}

export class LoadConfigsFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConfigsActionTypes.LoadConfigsFail;
}

export class LoadConfigsSuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Complete;
  readonly type = ConfigsActionTypes.LoadConfigsSuccess;

  constructor(public payload: { configs: Config[] }) {}
}

export class UpdateConfig implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Loading;
  readonly type = ConfigsActionTypes.UpdateConfig;

  constructor(public payload: { config: Config; thumbnail: ThumbnailFile }) {}
}

export class UpdateConfigFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConfigsActionTypes.UpdateConfigFail;
}

export class UpdateConfigSuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Complete;
  readonly type = ConfigsActionTypes.UpdateConfigSuccess;

  constructor(public payload: { config: Config }) {}
}

export class DeleteConfig implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Loading;
  readonly type = ConfigsActionTypes.DeleteConfig;

  constructor(public payload: Config) {}
}

export class DeleteConfigFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConfigsActionTypes.DeleteConfigFail;
}

export class DeleteConfigSuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Complete;
  readonly type = ConfigsActionTypes.DeleteConfigSuccess;

  constructor(public payload: { id: string }) {}
}

export class UpdateConfigSpecificationIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigSpecificationIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigInsulationSpecificationIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigInsulationSpecificationIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigServiceTemplateInfoIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigServiceTemplateInfoIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigSystemInfoIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigSystemInfoIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigConnectorInfoIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigConnectorInfoIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigDamperInfoIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigDamperInfoIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigStiffenerInfoIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigStiffenerInfoIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigDatabaseFileIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigDatabaseFileIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigItemFolderIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigItemFolderIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigFabricationRateIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigFabricationRateIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigInstallationRateIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigInstallationRateIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigMaterialIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigMaterialIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigMaterialSpecIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigMaterialSpecIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigInvalidDataIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigInvalidDataIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class UpdateConfigStiffenerSpecificationIds implements UpdateConfigDataReferences {
  readonly type = ConfigsActionTypes.UpdateConfigStiffenerSpecificationIds;

  constructor(public payload: Update<string[]>, public deleteReferences = false) {}
}

export class LoadGrantedConfig implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Ignore;
  readonly type = ConfigsActionTypes.LoadGrantedConfig;

  constructor(public payload: { id: string }) {}
}

export class LoadGrantedConfigFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Ignore;
  readonly type = ConfigsActionTypes.LoadGrantedConfigFail;
}

export class LoadGrantedConfigSuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Ignore;
  readonly type = ConfigsActionTypes.LoadGrantedConfigSuccess;

  constructor(public payload: { config: Config }) {}
}

export class RevokeConfig implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Ignore;
  readonly type = ConfigsActionTypes.RevokeConfig;

  constructor(public payload: { id: string; externalId: string }) {}
}

export class RevokeConfigFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Ignore;
  readonly type = ConfigsActionTypes.RevokeConfigFail;
}

export class RevokeConfigSuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Ignore;
  readonly type = ConfigsActionTypes.RevokeConfigSuccess;

  constructor(public payload: { config: Config }) {}
}

export type ConfigsActions =
  | LoadConfigs
  | LoadConfigsFail
  | LoadConfigsSuccess
  | UpdateConfig
  | UpdateConfigFail
  | UpdateConfigSuccess
  | DeleteConfig
  | DeleteConfigFail
  | DeleteConfigSuccess
  | UpdateConfigSpecificationIds
  | UpdateConfigInsulationSpecificationIds
  | UpdateConfigServiceTemplateInfoIds
  | UpdateConfigSystemInfoIds
  | UpdateConfigConnectorInfoIds
  | UpdateConfigDamperInfoIds
  | UpdateConfigStiffenerInfoIds
  | UpdateConfigDatabaseFileIds
  | UpdateConfigItemFolderIds
  | UpdateConfigFabricationRateIds
  | UpdateConfigInstallationRateIds
  | UpdateConfigMaterialIds
  | UpdateConfigMaterialSpecIds
  | UpdateConfigInvalidDataIds
  | UpdateConfigStiffenerSpecificationIds
  | LoadGrantedConfig
  | LoadGrantedConfigFail
  | LoadGrantedConfigSuccess
  | RevokeConfig
  | RevokeConfigFail
  | RevokeConfigSuccess;
