import { Injectable } from '@angular/core';
import { EnvironmentService } from '@services/environment.service';
import { Observable, of } from 'rxjs';
import { ResilientHttpService } from '@services/http/resilient-http.service';
import { LoggingService } from '@services/logging.service';
import { Config } from '@models/fabrication/config';
import {
  AcmExtraPermissionId,
  AddConfigUser,
  AssignUserRole,
  ConfigUsers,
  ContentUserRole,
  FcsRoleResponse,
  RemoveConfigUsers,
} from '@models/access-rights/access-rights';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { AxiosError } from 'axios';
import { ErrorUtils } from '@utils/error-utils';
import { DebugModeService } from '@services/debug-mode.service';

@Injectable({
  providedIn: 'root',
})
export class AccessRightsService {
  baseUrl: string = null;
  _removedDataIds: string[] = [];

  constructor(
    private envService: EnvironmentService,
    private resilientHttpService: ResilientHttpService,
    private loggingService: LoggingService,
    private translate: TranslateService,
    private debugModeService: DebugModeService
  ) {
    this.baseUrl = this.envService.environment.fabdmCm.baseUrl;
  }

  getCurrentRole(currentConfig: Config): Observable<ContentUserRole> {
    if (this.debugModeService.accessRoleIssues) {
      return this.handleGetCurrentRoleError(new AxiosError('debug mode enabled'));
    }

    const queryParams = `?collectionid=${currentConfig.collectionId}&libraryid=${currentConfig.id}`;
    return this.resilientHttpService
      .get<FcsRoleResponse>(`${this.baseUrl}v2/content/role${queryParams}`)
      .pipe(
        map((response: FcsRoleResponse) => response.role),
        catchError((err) => this.handleGetCurrentRoleError(err))
      );
  }

  private handleGetCurrentRoleError(err: AxiosError): Observable<ContentUserRole> {
    const errorRole: ContentUserRole = 'read';
    this.loggingService.logError(
      err,
      true,
      this.translate.instant(LC.NOTIFICATIONS.ACCESS_RIGHTS.MSG_LOAD_ROLE_FAILED)
    );
    return of(errorRole);
  }

  getConfigUsers(config: Config): Observable<ConfigUsers> {
    const queryParams = `?collectionid=${config.collectionId}&libraryid=${config.id}`;
    return this.resilientHttpService
      .get<ConfigUsers>(`${this.baseUrl}v2/content/users${queryParams}`)
      .pipe(
        catchError((err) => {
          const statusCode = err.response.status;
          let errorMessage = '';
          switch (statusCode) {
            case 404:
              errorMessage = this.translate.instant(
                LC.ACCESS_RIGHTS.ERROR.CONTENT_LIBRARY_NOT_FOUND
              );
              break;
            case 400:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.INVALID_PARAMETERS);
              break;
            default:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.SERVER);
              break;
          }
          this.loggingService.logError(err, true, errorMessage);
          return of(null);
        })
      );
  }

  addConfigUser(
    config: Config,
    emailId: string,
    role: AssignUserRole,
    extraPermissions: AcmExtraPermissionId[]
  ): Observable<ConfigUsers> {
    const addUser: AddConfigUser = {
      collectionId: config.collectionId,
      libraryId: config.id,
      emailId,
      role,
      extraPermissions,
    };

    return this.resilientHttpService
      .patch<ConfigUsers>(`${this.baseUrl}v2/content/users`, {
        data: addUser,
      })
      .pipe(
        catchError((err: AxiosError) => {
          const statusCode = err.response.status;
          let errorMessage = '';
          switch (statusCode) {
            case 404:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.NO_USER);
              break;
            case 400:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.INVALID_PARAMETERS);
              break;
            default:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.SERVER);
              break;
          }
          this.loggingService.logError(err, true, errorMessage);
          return of(null);
        })
      );
  }

  removeConfigUsers(config: Config, userIds: string[]): Observable<ConfigUsers> {
    const removeUsers: RemoveConfigUsers = {
      collectionId: config.collectionId,
      libraryId: config.id,
      userIds,
    };
    return this.resilientHttpService
      .delete<ConfigUsers>(`${this.baseUrl}v2/content/users`, {
        data: removeUsers,
      })
      .pipe(
        catchError((err) => {
          const statusCode = err.response.status;
          let errorMessage = '';
          switch (statusCode) {
            case 404:
              errorMessage = this.translate.instant(
                LC.ACCESS_RIGHTS.ERROR.CONTENT_LIBRARY_NOT_FOUND
              );
              break;
            case 400:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.INVALID_PARAMETERS);
              break;
            default:
              errorMessage = this.translate.instant(LC.ACCESS_RIGHTS.ERROR.SERVER);
              break;
          }
          this.loggingService.logError(err, true, errorMessage);
          return of(null);
        })
      );
  }

  public removeConfigData(config: Config): Observable<boolean> {
    this._removedDataIds.push(config.id);
    const queryParams = `?collectionid=${config.collectionId}&libraryid=${config.id}&method=hard`;
    return this.resilientHttpService
      .delete<boolean>(`${this.baseUrl}v2/content/libraries${queryParams}`)
      .pipe(
        map(() => true),
        catchError((err) => {
          this._removedDataIds = this._removedDataIds.filter((x) => x !== config.id);
          this.loggingService.logError(
            err,
            true,
            this.translate.instant(LC.NOTIFICATIONS.ERROR_DELETE_CONFIG),
            ErrorUtils.getAdditionalStack()
          );
          return of(false);
        })
      );
  }

  public getRemovedDataIds = (): string[] => this._removedDataIds;
}

/* eslint-enable */
