import * as fromMaterialSpec from '../actions/material-spec.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialSpecification } from '@models/fabrication/material-spec';

export interface MaterialSpecState extends EntityState<MaterialSpecification> {} // eslint-disable-line

export const materialSpecAdapter: EntityAdapter<MaterialSpecification> =
  createEntityAdapter<MaterialSpecification>({
    selectId: (materialSpec: MaterialSpecification) => materialSpec.id,
  });

export const initialState: MaterialSpecState = materialSpecAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromMaterialSpec.MaterialSpecActions
): MaterialSpecState {
  switch (action.type) {
    case fromMaterialSpec.MaterialSpecActionTypes.LoadMaterialSpecs: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.LoadMaterialSpecsSuccess: {
      return materialSpecAdapter.upsertMany(action.data, state);
    }
    case fromMaterialSpec.MaterialSpecActionTypes.LoadMaterialSpecsFail: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.AddMaterialSpec: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.AddMaterialSpecSuccess: {
      return materialSpecAdapter.addOne(action.dataElement, state);
    }
    case fromMaterialSpec.MaterialSpecActionTypes.AddMaterialSpecFail: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.CopyMaterialSpec: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.CopyMaterialSpecSuccess: {
      return materialSpecAdapter.addOne(action.dataElement, state);
    }
    case fromMaterialSpec.MaterialSpecActionTypes.CopyMaterialSpecFail: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.UpdateMaterialSpec: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.UpdateMaterialSpecSuccess: {
      return materialSpecAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromMaterialSpec.MaterialSpecActionTypes.UpdateMaterialSpecFail: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.DeleteMaterialSpecs: {
      return state;
    }
    case fromMaterialSpec.MaterialSpecActionTypes.DeleteMaterialSpecsSuccess: {
      return materialSpecAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromMaterialSpec.MaterialSpecActionTypes.DeleteMaterialSpecsFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of material spec ids
  selectIds: selectMaterialSpecIds,

  // select the dictionary of material spec entities
  selectEntities: selectMaterialSpecEntities,

  // select the array of material specs
  selectAll: selectAllMaterialSpecs,

  // select the total material spec count
  selectTotal: selectMaterialSpecsTotal,
} = materialSpecAdapter.getSelectors();
