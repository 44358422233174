import { notificationToastSeverities } from '@weave-mui/notification-toast';
import { alertSeverities } from '@weave-mui/alert';
import { NotificationLink } from '@models/notification/notification';

export type NotificationToastSeveritiesType =
  (typeof notificationToastSeverities)[keyof typeof notificationToastSeverities];
export type AlertType = (typeof alertSeverities)[keyof typeof alertSeverities];

export enum BannerType {
  'info',
  'success',
  'warning',
  'error',
}

export interface Banner {
  type?: BannerType | string;
  message?: string;
}

export interface Toast {
  id: string;
  message: string;
  status: NotificationToastSeveritiesType;
  description?: string;
  timestamp?: string;
  link?: NotificationLink;
  onDismiss?: () => void;
}

export enum ToastStatus {
  'info',
  'success',
  'warning',
  'error',
}
