import * as fromLoadingData from '../actions/loading-data.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingState } from '@models/loading-state/loading-state';
import { DataElementType } from '@constants/data-element-types';
import { ApiError } from '@models/errors/api-error';

export interface LoadingDataState extends EntityState<LoadingState> {} // eslint-disable-line

export const loadingDataAdapter: EntityAdapter<LoadingState> = createEntityAdapter<LoadingState>({
  selectId: (loadingData: LoadingState) => loadingData.configId,
});

export const initialState: LoadingDataState = loadingDataAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromLoadingData.LoadingDataActions
): LoadingDataState {
  switch (action.type) {
    case fromLoadingData.LoadingDataActionTypes.SetDataTypeIsLoading: {
      return loadingDataAdapter.upsertOne(
        setDataLoadingState(
          state,
          action.payload.configId,
          action.payload.dataType,
          'loading',
          null
        ),
        state
      );
    }
    case fromLoadingData.LoadingDataActionTypes.SetDataTypeIsLoaded: {
      return loadingDataAdapter.upsertOne(
        setDataLoadingState(
          state,
          action.payload.configId,
          action.payload.dataType,
          'loaded',
          null
        ),
        state
      );
    }
    case fromLoadingData.LoadingDataActionTypes.SetDataTypeLoadingHasFailed: {
      return loadingDataAdapter.upsertOne(
        setDataLoadingState(
          state,
          action.payload.configId,
          action.payload.dataLoadingError.dataType,
          'failed',
          action.payload.dataLoadingError
        ),
        state
      );
    }
    case fromLoadingData.LoadingDataActionTypes.RemoveConfigLoadingState: {
      return loadingDataAdapter.removeOne(action.payload.configId, state);
    }
    case fromLoadingData.LoadingDataActionTypes.RemoveConfigDataTypeLoadingState: {
      if (state.entities[action.payload.configId]) {
        const loadingState = { ...state.entities[action.payload.configId] };
        loadingState.loaded = loadingState.loaded.filter((x) => x !== action.payload.dataType);
        return loadingDataAdapter.upsertOne(loadingState, state);
      }

      return state;
    }
    default:
      return state;
  }
}

type loadingOperation = 'loading' | 'loaded' | 'failed';

const getConfigLoadingData = (configId: string, state: LoadingDataState): LoadingState => {
  let loadingState: LoadingState;

  if (state.entities[configId]) {
    loadingState = { ...state.entities[configId] };
  } else {
    loadingState = {
      configId,
      failed: [],
      loaded: [],
      loading: [],
    };
  }
  return loadingState;
};

const setDataLoadingState = (
  state: LoadingDataState,
  configId: string,
  dataType: DataElementType | string,
  loadingOperation: loadingOperation,
  dataLoadingError: ApiError
): LoadingState => {
  const loadingState = getConfigLoadingData(configId, state);
  let cleanFailedStates: boolean, cleanLoadingStates: boolean, cleanLoadedStates: boolean;
  const removeFromLoadingStates = (dataType: DataElementType | string) =>
    (loadingState.loading = loadingState.loading.filter((x) => x !== dataType));
  const removeFromLoadedStates = (dataType: DataElementType | string) =>
    (loadingState.loaded = loadingState.loaded.filter((x) => x !== dataType));
  const removeFromFailedStates = (dataType: DataElementType | string) =>
    (loadingState.failed = loadingState.failed.filter((x) => x.dataType !== dataType));

  switch (loadingOperation) {
    case 'loading':
      // eslint-disable-next-line no-lone-blocks
      {
        loadingState.loading = [dataType, ...loadingState.loading];
        cleanFailedStates = cleanLoadedStates = true;
      }
      break;
    case 'loaded':
      // eslint-disable-next-line no-lone-blocks
      {
        loadingState.loaded = [dataType, ...loadingState.loaded];
        cleanFailedStates = cleanLoadingStates = true;
      }
      break;
    case 'failed':
      // eslint-disable-next-line no-lone-blocks
      {
        loadingState.failed = [dataLoadingError, ...loadingState.failed];
        cleanLoadingStates = cleanLoadedStates = true;
      }
      break;
    default:
      break;
  }

  if (cleanLoadingStates) {
    removeFromLoadingStates(dataType);
  }

  if (cleanLoadedStates) {
    removeFromLoadedStates(dataType);
  }

  if (cleanFailedStates) {
    removeFromFailedStates(dataType);
  }

  return loadingState;
};

// entity selectors
export const {
  // select the array of LoadingData ids
  selectIds: selectLoadingDataIds,
  // select the dictionary of LoadingData entities
  selectEntities: selectLoadingDataEntities,

  // select the array of LoadingDatas
  selectAll: selectAllLoadingDatas,

  // select the total LoadingData count
  selectTotal: selectLoadingDataTotal,
} = loadingDataAdapter.getSelectors();
