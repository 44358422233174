import { Observable } from 'rxjs';
import Axios from 'axios';

export class HttpUtils {
  static cancellablePromise<T>(method: () => Promise<T>, abort: AbortController): Observable<T> {
    return new Observable((observer) => {
      method().then(
        (result) => {
          observer.next(result);
          observer.complete();
        },
        (err) => {
          if (Axios.isCancel(err) || err.message === 'canceled') {
            observer.complete();
          } else {
            observer.error();
          }
        }
      );

      return () => abort.abort();
    });
  }
}
