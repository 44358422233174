import * as fromConfigs from '../actions/configs.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Config } from '@models/fabrication/config';

export type ConfigsState = EntityState<Config>;

export const configsAdapter: EntityAdapter<Config> = createEntityAdapter<Config>({
  selectId: (config: Config) => config.externalId,
});

export const initialState: ConfigsState = configsAdapter.getInitialState();

export function reducer(state = initialState, action: fromConfigs.ConfigsActions): ConfigsState {
  switch (action.type) {
    case fromConfigs.ConfigsActionTypes.LoadConfigs: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.LoadConfigsSuccess: {
      return configsAdapter.upsertMany(action.payload.configs, state);
    }
    case fromConfigs.ConfigsActionTypes.LoadConfigsFail: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfig: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigSuccess: {
      return configsAdapter.upsertOne(action.payload.config, state);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigFail: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.DeleteConfig: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.DeleteConfigSuccess: {
      return configsAdapter.removeOne(action.payload.id, state);
    }
    case fromConfigs.ConfigsActionTypes.DeleteConfigFail: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigSpecificationIds: {
      return updateConfigDataIdField(state, 'specificationInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigInsulationSpecificationIds: {
      return updateConfigDataIdField(state, 'insulationSpecificationInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigServiceTemplateInfoIds: {
      return updateConfigDataIdField(state, 'partTemplates', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigSystemInfoIds: {
      return updateConfigDataIdField(state, 'systemInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigConnectorInfoIds: {
      return updateConfigDataIdField(state, 'connectorInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigDamperInfoIds: {
      return updateConfigDataIdField(state, 'damperInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigStiffenerInfoIds: {
      return updateConfigDataIdField(state, 'stiffenerInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigDatabaseFileIds: {
      return updateConfigDataIdField(state, 'databaseFiles', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigItemFolderIds: {
      return updateConfigDataIdField(state, 'folders', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigFabricationRateIds: {
      return updateConfigDataIdField(state, 'fabricationRates', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigInstallationRateIds: {
      return updateConfigDataIdField(state, 'installationRates', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigMaterialIds: {
      return updateConfigDataIdField(state, 'materials', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigMaterialSpecIds: {
      return updateConfigDataIdField(state, 'materialSpecs', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigInvalidDataIds: {
      return updateConfigDataIdField(state, 'invalidData', action);
    }
    case fromConfigs.ConfigsActionTypes.UpdateConfigStiffenerSpecificationIds: {
      return updateConfigDataIdField(state, 'stiffenerSpecificationInfos', action);
    }
    case fromConfigs.ConfigsActionTypes.LoadGrantedConfig: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.LoadGrantedConfigFail: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.LoadGrantedConfigSuccess: {
      return configsAdapter.upsertOne(action.payload.config, state);
    }
    case fromConfigs.ConfigsActionTypes.RevokeConfig: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.RevokeConfigFail: {
      return state;
    }
    case fromConfigs.ConfigsActionTypes.RevokeConfigSuccess: {
      return configsAdapter.removeOne(action.payload.config.externalId, state);
    }
    default:
      return state;
  }
}

// todo: refactor to not use strings as field identifiers, too un-reliable
function updateConfigDataIdField(
  state: ConfigsState,
  field: string,
  action: fromConfigs.UpdateConfigDataReferences
) {
  const ids = state.ids;
  const configId = action.payload.id;
  const updateEntity = state.entities[configId];
  let updateValue = action.payload.changes;

  // check field value exists before attempting to modify array
  if (updateEntity[field]) {
    // delete config references
    if (action.deleteReferences) {
      updateValue = [...updateEntity[field]].filter((x) => !updateValue.includes(x));
    } else {
      // update
      // use Set ctor to ensure references are unique
      updateValue = [...new Set([...updateEntity[field], ...updateValue])];
    }
  }

  return {
    ids,
    entities: {
      ...state.entities,
      [configId]: {
        ...updateEntity,
        [field]: updateValue,
      },
    },
  };
}

// entity selectors
export const {
  // select the array of config ids
  selectIds: selectConfigIds,

  // select the dictionary of config entities
  selectEntities: selectConfigEntities,

  // select the array of configs
  selectAll: selectAllConfigs,

  // select the total config count
  selectTotal: selectConfigTotal,
} = configsAdapter.getSelectors();
