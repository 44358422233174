import { Component, Injector, Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ReactFactoryComponent, ReactProp } from '../../../react-factory/react-factory.component';
import { FABLinearProgress, FABLinearProgressProps } from './linear-progress.react';
import { linearProgressVariants } from '@weave-mui/enums';

export type LinearProgressVariantsType =
  (typeof linearProgressVariants)[keyof typeof linearProgressVariants];

@Component({
  selector: 'fab-linear-progress',
  template: ReactFactoryComponent.template,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FABLinearProgressComponent extends ReactFactoryComponent<FABLinearProgressProps> {
  @Input() @ReactProp('INPUT') classes: any;
  @Input() @ReactProp('INPUT') color: string;
  @Input() @ReactProp('INPUT') sx: any;
  @Input() @ReactProp('INPUT') value: number;
  @Input() @ReactProp('INPUT') valueBuffer: number;
  @Input() @ReactProp('INPUT') variant: LinearProgressVariantsType;

  constructor(injector: Injector) {
    super(injector);
  }

  getComponent() {
    return FABLinearProgress;
  }
}
