import { StiffenerSpecificationInfo } from '@models/fabrication/stiffener-specification-info';
import { Config } from '@models/fabrication/config';
import {
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  DeleteDataElementsSuccessAction,
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum StiffenerSpecificationInfoActionTypes {
  LoadStiffenerSpecificationInfos = '[StiffenerSpecifications API] Load StiffenerSpecificationInfos',
  LoadStiffenerSpecificationInfosFail = '[StiffenerSpecifications API] Load StiffenerSpecificationInfos Fail',
  LoadStiffenerSpecificationInfosSuccess = '[StiffenerSpecifications API] Load StiffenerSpecificationInfos Success',
  UpdateStiffenerSpecificationInfo = '[StiffenerSpecifications API] Update StiffenerSpecificationInfo',
  UpdateStiffenerSpecificationInfoFail = '[StiffenerSpecifications API] Update StiffenerSpecificationInfo Fail',
  UpdateStiffenerSpecificationInfoSuccess = '[StiffenerSpecifications API] Update StiffenerSpecificationInfo Success',
  AddStiffenerSpecificationInfo = '[StiffenerSpecifications API] Add StiffenerSpecificationInfo',
  AddStiffenerSpecificationInfoFail = '[StiffenerSpecifications API] Add StiffenerSpecificationInfo Fail',
  AddStiffenerSpecificationInfoSuccess = '[StiffenerSpecifications API] Add StiffenerSpecificationInfo Success',
  DeleteStiffenerSpecificationInfos = '[StiffenerSpecifications API] Delete StiffenerSpecificationInfos',
  DeleteStiffenerSpecificationInfosFail = '[StiffenerSpecifications API] Delete StiffenerSpecificationInfos Fail',
  DeleteStiffenerSpecificationInfosSuccess = '[StiffenerSpecifications API] Delete StiffenerSpecificationInfos Success',
  CopyStiffenerSpecificationInfo = '[StiffenerSpecifications API] Copy StiffenerSpecificationInfo',
  CopyStiffenerSpecificationInfoFail = '[StiffenerSpecifications API] Copy StiffenerSpecificationInfo Fail',
  CopyStiffenerSpecificationInfoSuccess = '[StiffenerSpecifications API] Copy StiffenerSpecificationInfo Success',
}

const dataType = DataElementType.StiffenerSpecification;

export class LoadStiffenerSpecificationInfos
  implements LoadDataElementsAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadStiffenerSpecificationInfosSuccess();
  failAction = new LoadStiffenerSpecificationInfosFail();
  readonly type = StiffenerSpecificationInfoActionTypes.LoadStiffenerSpecificationInfos;
  dataElementType = DataElementType.StiffenerSpecification;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadStiffenerSpecificationInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerSpecificationInfoActionTypes.LoadStiffenerSpecificationInfosFail;
}

export class LoadStiffenerSpecificationInfosSuccess
  implements LoadDataElementsSuccessAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  data: StiffenerSpecificationInfo[];
  readonly type = StiffenerSpecificationInfoActionTypes.LoadStiffenerSpecificationInfosSuccess;
}

export class UpdateStiffenerSpecificationInfo
  implements UpdateDataElementAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  dataElement: StiffenerSpecificationInfo;
  successAction = new UpdateStiffenerSpecificationInfoSuccess();
  failAction = new UpdateStiffenerSpecificationInfoFail();
  readonly type = StiffenerSpecificationInfoActionTypes.UpdateStiffenerSpecificationInfo;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: StiffenerSpecificationInfo }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateStiffenerSpecificationInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerSpecificationInfoActionTypes.UpdateStiffenerSpecificationInfoFail;
}

export class UpdateStiffenerSpecificationInfoSuccess
  implements UpdateDataElementSuccessAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: StiffenerSpecificationInfo;
  readonly type = StiffenerSpecificationInfoActionTypes.UpdateStiffenerSpecificationInfoSuccess;
}

export class AddStiffenerSpecificationInfo
  implements AddDataElementAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: StiffenerSpecificationInfo;
  successAction = new AddStiffenerSpecificationInfoSuccess();
  failAction = new AddStiffenerSpecificationInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = StiffenerSpecificationInfoActionTypes.AddStiffenerSpecificationInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: StiffenerSpecificationInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class AddStiffenerSpecificationInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerSpecificationInfoActionTypes.AddStiffenerSpecificationInfoFail;
}

export class AddStiffenerSpecificationInfoSuccess
  implements AddDataElementSuccessAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: StiffenerSpecificationInfo;
  readonly type = StiffenerSpecificationInfoActionTypes.AddStiffenerSpecificationInfoSuccess;
}

export class CopyStiffenerSpecificationInfo
  implements CopyDataElementAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: StiffenerSpecificationInfo;
  successAction = new CopyStiffenerSpecificationInfoSuccess();
  failAction = new CopyStiffenerSpecificationInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = StiffenerSpecificationInfoActionTypes.CopyStiffenerSpecificationInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS;
  isExternalNodeId = true;
  constructor(public payload: { config: Config; dataElement: StiffenerSpecificationInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopyStiffenerSpecificationInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerSpecificationInfoActionTypes.CopyStiffenerSpecificationInfoFail;
}

export class CopyStiffenerSpecificationInfoSuccess
  implements CopyDataElementSuccessAction<StiffenerSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: StiffenerSpecificationInfo;
  readonly type = StiffenerSpecificationInfoActionTypes.CopyStiffenerSpecificationInfoSuccess;
}

export class DeleteStiffenerSpecificationInfos
  implements DeleteDataElementsAction<StiffenerSpecificationInfo>
{
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: StiffenerSpecificationInfo[];
  successAction = new DeleteStiffenerSpecificationInfosSuccess();
  failAction = new DeleteStiffenerSpecificationInfosFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS;
  isExternalNodeId = true;
  readonly type = StiffenerSpecificationInfoActionTypes.DeleteStiffenerSpecificationInfos;

  constructor(public payload: { config: Config; dataElements: StiffenerSpecificationInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteStiffenerSpecificationInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerSpecificationInfoActionTypes.DeleteStiffenerSpecificationInfosFail;
}

export class DeleteStiffenerSpecificationInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = StiffenerSpecificationInfoActionTypes.DeleteStiffenerSpecificationInfosSuccess;
}

export type StiffenerSpecificationsActions =
  | LoadStiffenerSpecificationInfos
  | LoadStiffenerSpecificationInfosFail
  | LoadStiffenerSpecificationInfosSuccess
  | UpdateStiffenerSpecificationInfo
  | UpdateStiffenerSpecificationInfoFail
  | UpdateStiffenerSpecificationInfoSuccess
  | AddStiffenerSpecificationInfo
  | AddStiffenerSpecificationInfoFail
  | AddStiffenerSpecificationInfoSuccess
  | DeleteStiffenerSpecificationInfos
  | DeleteStiffenerSpecificationInfosFail
  | DeleteStiffenerSpecificationInfosSuccess
  | CopyStiffenerSpecificationInfo
  | CopyStiffenerSpecificationInfoFail
  | CopyStiffenerSpecificationInfoSuccess;
