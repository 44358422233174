import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromDamperInfos from '../reducers/damper-info.reducer';

// damperInfo state
export const selectDamperInfoState = (state: FDMState) => state.configsState.damperInfos;

// damperInfo selectors
export const selectDamperInfoIds = createSelector(
  selectDamperInfoState,
  fromDamperInfos.selectDamperInfoIds
);

export const selectDamperInfoEntities = createSelector(
  selectDamperInfoState,
  fromDamperInfos.selectDamperInfoEntities
);

export const selectAllDamperInfos = createSelector(
  selectDamperInfoState,
  fromDamperInfos.selectAllDamperInfos
);

export const selectDamperInfosTotal = createSelector(
  selectDamperInfoState,
  fromDamperInfos.selectDamperInfosTotal
);

export const selectDamperInfoById = (id: string) =>
  createSelector(selectDamperInfoEntities, (damperInfoEntities) => damperInfoEntities[id]);
