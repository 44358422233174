import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import {
  selectCurrentConfigDamperInfos,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import { selectDamperInfoById } from '@store/selectors/damper-info.selectors';
import {
  LoadDamperInfos,
  LoadDamperInfosSuccess,
  AddDamperInfo,
  UpdateDamperInfo,
  CopyDamperInfo,
  DeleteDamperInfos,
  DeleteDamperInfosSuccess,
  AddDamperInfoSuccess,
  UpdateDamperInfoSuccess,
} from '@store/actions/damper-info.action';
import { UpdateConfigDamperInfoIds } from '@store/actions/configs.action';
import { Config, ConfigUnitSystem } from '@models/fabrication/config';
import { DamperInfo, DamperControlType } from '@models/fabrication/damper-info';
import { EnvironmentConstants } from '@constants/environment-constants';
import { map, take } from 'rxjs/operators';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { UnitSchemaType } from '@models/forge-units/forge-unit-schema-types';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import helpLinks from '@assets/help/help-links.json';
import { Observable, of } from 'rxjs';

@Injectable()
export class DynamicDamperInfoSetup extends DynamicDataElementTypeSetup<DamperInfo> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<DamperInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.dampers;
  }

  setupOptions() {
    this.options = {
      dataType: DataElementType.Damper,
      dependentDataTypes: [],
      createNewInstance: () => new DamperInfo(),
      supportsDynamicUpdates: true,
      sortFields: ['category', 'name'],
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigDamperInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectDamperInfoById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadDamperInfos({ config })),
        loadSuccessAction: () => new LoadDamperInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteDamperInfosSuccess(),
        addDataSuccessAction: () => new AddDamperInfoSuccess(),
        updateDataSuccessAction: () => new UpdateDamperInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigDamperInfoIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: DamperInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddDamperInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        editModelAction: (model: DamperInfo) =>
          this.store$.dispatch(new UpdateDamperInfo({ dataElement: model })),
        copyModelAction: (model: DamperInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyDamperInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        deleteModelsAction: (models: DamperInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteDamperInfos({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: DamperInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.Damper,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_DAMPER,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_DAMPER_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS,
                  addSuccessAction: new AddDamperInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_DAMPERINFOS,
        schemas: [
          {
            dataType: DataElementType.Damper,
            schema: {
              namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
              version: EnvironmentConstants.FSS_SCHEMA_DAMPER_VERSION,
              type: EnvironmentConstants.FSS_SCHEMA_DAMPER,
            },
          },
        ],
      },
    };
  }

  getDynamicTableOptions(
    configUnitSystem: ConfigUnitSystem
  ): Observable<DynamicTableOptions<DamperInfo>> {
    const lengthUnits = ForgeUnitsService.getStandardLengthUnits(configUnitSystem);

    const damperInfoTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.CATEGORY),
        formatter: (value: string) =>
          value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
        visible: true,
      },
      {
        field: 'controlType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.CONTROL_TYPE),
        formatter: (value: DamperControlType) => FormUtils.stringToSpaceCased(value),
        visible: true,
      },
      {
        field: 'adjust',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.ADJUST),
        visible: false,
        units: lengthUnits,
      },
      {
        field: 'gauge',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.GAUGE),
        visible: false,
      },
      {
        field: 'handleExtension',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.HANDLE_EXTENSION),
        visible: false,
        units: lengthUnits,
      },
      {
        field: 'holeAngle',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.HOLE_ANGLE),
        visible: false,
        units: UnitSchemaType.Degrees,
      },
      {
        field: 'inset',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.INSET),
        visible: false,
        units: lengthUnits,
      },
      {
        field: 'isDisplayed',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.IS_DISPLAYED),
        formatter: (value: boolean) =>
          value
            ? this.translate.instant(LC.DYNAMIC_TABLE.YES)
            : this.translate.instant(LC.DYNAMIC_TABLE.NO),
        visible: false,
      },
      {
        field: 'isMachineCut',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.DAMPERS.IS_MACHINE_CUT),
        formatter: (value: boolean) =>
          value
            ? this.translate.instant(LC.DYNAMIC_TABLE.YES)
            : this.translate.instant(LC.DYNAMIC_TABLE.NO),
        visible: false,
      },
    ];
    return of(this.createDynamicTableOptions(damperInfoTableColumns));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<DamperInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: DamperInfo) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
              includeFields: [
                'category',
                'adjust',
                'inset',
                'holeAngle',
                'handleExtension',
                'gauge',
                'controlType',
                'isMachineCut',
                'isDisplayed',
              ],
              expanded: true,
              orderByIncludeFields: true,
              options: {
                unitsFields: [
                  {
                    key: 'adjust',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                  {
                    key: 'inset',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                  {
                    key: 'handleExtension',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                  {
                    key: 'holeAngle',
                    units: {
                      imperial: UnitSchemaType.Degrees,
                      metric: UnitSchemaType.Degrees,
                    },
                  },
                  {
                    key: 'gauge',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                ],
                selectFields: [
                  {
                    key: 'controlType',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      DamperControlType,
                      'DamperControlType'
                    ),
                  },
                ],
                toggleFields: ['isDisplayed', 'isMachineCut'],
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
          ],
          formStyle: DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category'],
      isReplaceable: true,
      isRemovable: true,
      isEditable: true,
      upstreamReferenceDataTypes: () => [DataElementType.Part],
      clusterIcon: 'file-assembly16',
      isDownstreamRefBlocked: () => true,
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return 'file-assembly';
  }

  isFixable(): boolean {
    return true;
  }

  getInvalidDataErrors(model: DamperInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.Damper);
    return this.getStandardInvalidDataError(DataElementType.Damper, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: DamperInfo*/): boolean {
    return false;
  }
}
