import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { DataElementType } from '@constants/data-element-types';
import { EnvironmentConstants } from '@constants/environment-constants';
import { FabricationReferenceType } from '@models/forge-content/references';

export enum ModelBy {
  PartSize = 'PartSize',
  Insulation = 'Insulation',
}

export enum InsulationType {
  Inside = 'Inside',
  Outside = 'Outside',
}

export enum HangerEngagement {
  AroundPart = 'AroundPart',
  AroundInsulation = 'AroundInsulation',
}

export class SystemInfo extends ForgeContentDataElement {
  abbreviation = '';
  category = '';
  modelBy: ModelBy = ModelBy.PartSize;
  insulationType: InsulationType = InsulationType.Outside;
  hangerEngagement: HangerEngagement = HangerEngagement.AroundPart;

  constructor() {
    super();

    this.fabricationReferences = [
      {
        dataType: DataElementType.ServiceTemplate,
        externalId: '',
        referenceType: FabricationReferenceType.Relationship,
      },
      {
        dataType: DataElementType.Specification,
        externalId: EnvironmentConstants.FCS_UNASSIGNED_PART_SPEC,
        referenceType: FabricationReferenceType.Relationship,
      },
      {
        dataType: DataElementType.InsulationSpecification,
        externalId: EnvironmentConstants.FCS_UNASSIGNED_INSULATION_SPEC,
        referenceType: FabricationReferenceType.Relationship,
      },
    ];
  }
}
