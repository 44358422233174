import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ReactFactoryComponent, ReactProp } from '../../../react-factory/react-factory.component';
import { FABToastList, FABToastListProps } from './toast-list.react';
import { Toast } from '@models/ui-state/weave-notification';
@Component({
  selector: 'fab-toast-list',
  template: ReactFactoryComponent.template,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FABNotificationToastComponent extends ReactFactoryComponent<FABToastListProps> {
  @Input() @ReactProp('INPUT') toastList: Toast[] = [];

  @Output() @ReactProp('OUTPUT') onDismissToast = new EventEmitter<string>();

  constructor(injector: Injector) {
    super(injector);
  }

  getComponent() {
    return FABToastList;
  }
}
