import { FieldWrapper } from '@ngx-formly/core';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { DynamicFormCustomUpdateEvent } from '@models/dynamic-form/dynamic-form-properties';
import {
  InlineEditError,
  InlineEditClearErrorState,
} from '@models/inline-edit/inline-edit.options';
import { v4 as uuidv4 } from 'uuid';
import { Component, OnDestroy } from '@angular/core';
import { Config, ConfigUnitSystem } from '@models/fabrication/config';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { take } from 'rxjs/operators';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';

@Component({ template: '' })
export abstract class DynamicFormBaseCustomComponent<T> extends FieldWrapper implements OnDestroy {
  /**
   * Identify where the update has come from, prevent re-binding to updates from self
   * @param  {} .toString(
   */
  protected subscriptionToken = uuidv4();

  protected tableErrorReporterSubject: Subject<InlineEditError | InlineEditClearErrorState> =
    new Subject<InlineEditError | InlineEditClearErrorState>();
  protected tableErrorReporterSubscription: Subscription = null;

  protected configUnitSystem: ConfigUnitSystem;

  constructor(protected store$: Store<FDMState>) {
    super();

    this.store$
      .select(selectCurrentConfig)
      .pipe(take(1))
      .subscribe((config: Config) => (this.configUnitSystem = config.unitSystem));
  }

  ngOnDestroy(): void {
    if (this.tableErrorReporterSubscription) {
      this.tableErrorReporterSubscription.unsubscribe();
    }
  }

  public get isReadOnly(): boolean {
    return this.props.readonly;
  }

  public get formModelUpdater(): BehaviorSubject<DynamicFormCustomUpdateEvent> {
    return this.props?.customComponentModelChangeListener;
  }

  protected extraCustomValidation(): void {
    return null;
  }

  protected reportErrors() {
    if (!this.tableErrorReporterSubscription) {
      this.tableErrorReporterSubscription = this.tableErrorReporterSubject.subscribe(
        (errorObject: InlineEditError | InlineEditClearErrorState) => {
          if (this.isInlineErrorObject(errorObject)) {
            this.postError(errorObject);
          } else {
            this.clearErrors(errorObject);
          }

          this.extraCustomValidation();
        }
      );
    }
  }

  private isInlineErrorObject(
    errorObject: InlineEditError | InlineEditClearErrorState
  ): errorObject is InlineEditError {
    return (errorObject as InlineEditError).reference !== undefined;
  }

  updateSource(data: T) {
    const customComponentUpdateSubject: Subject<DynamicFormCustomUpdateEvent> =
      this.props.customComponentUpdateSubject;
    customComponentUpdateSubject.next({
      modelKey: this.key as string,
      data,
      isFirstUpdate: false,
      subscriptionToken: this.subscriptionToken,
    });
  }

  protected postError(error: InlineEditError) {
    this.formState.errors?.push(error.reference);
    this.form.setErrors({ [error.reference]: true });
    this.formState.tabValid = false;
    this.formState.tabErrorMessage = error ? error.errorMessage : null;
  }

  protected clearErrors(clearErrorState: InlineEditClearErrorState) {
    if (clearErrorState.clearAllErrors) {
      this.formState.errors = [];
      this.form.setErrors(null);
      this.formState.tabValid = true;
    } else if (clearErrorState.clearReference) {
      this.formState.errors = this.formState.errors.filter(
        (x) => x !== clearErrorState.clearReference
      );
      this.form.setErrors({ [clearErrorState.clearReference]: null });
      this.formState.tabValid = this.formState.errors.length ? false : true;
    }

    this.form.updateValueAndValidity();
  }

  /**
   * Allow a temporary navigation away from form
   * @returns void
   */
  enableTempNavigateFromForm(tempModelOptions: any): void {
    const customComponentAllowNavigateOverride: BehaviorSubject<[boolean, any]> =
      this.props.customComponentAllowNavigateOverride;
    customComponentAllowNavigateOverride.next([true, tempModelOptions]);
  }
}
