import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { DynamicDataService } from '@services/dynamic-data.service';
import { FabricationReference } from '@models/forge-content/references';

import {
  ExtraDataElementInfoActionTypes,
  LoadInterdependentReferences,
  LoadInterdependentReferencesFail,
  LoadInterdependentReferencesSuccess,
} from '@store/actions/extra-data-element-info.action';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';
import { ApiError } from '@models/errors/api-error';
import { ForgeContentService } from '@services/forge-content.service';

interface InterdepentReferencesResult {
  referencedIds: string[];
  isSuccess: boolean;
}

@Injectable()
export class ExtraDataElementInfoEffects {
  loadInterdependentReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtraDataElementInfoActionTypes.LoadInterdependentReferences),
      switchMap((action: LoadInterdependentReferences) => {
        const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
          action.payload.dataElementType,
          action.payload.dataElement.id
        );

        const referenceDataTypes =
          dynamicDataSetup.options.extraDataElementInfoOptions?.interdependentReferenceDataTypes;
        if (!referenceDataTypes?.length) {
          return of([
            new LoadInterdependentReferencesSuccess({
              interdependentReferenceIds: [],
              id: action.payload.dataElement.id,
            }),
          ]);
        }

        return dynamicDataSetup.options.selectors
          .selectById(action.payload.dataElement.id, action.payload.isInvalidData)
          .pipe(
            take(1),
            switchMap((dbElement: ForgeContentDataElement) => {
              const searchChecks$ = dbElement.fabricationReferences
                ?.filter((x: FabricationReference) => referenceDataTypes.includes(x.dataType))
                .map((x: FabricationReference) =>
                  this.forgeContentService
                    .getInterdependentReferences(dbElement.externalId, x.externalId, 10, false)
                    .pipe(map((data: ApiError | ForgeContentDataElement[]) => [data, x.externalId]))
                );

              if (!searchChecks$?.length) {
                return of([
                  action,
                  {
                    isSuccess: true,
                    referencedIds: [],
                  },
                ]);
              }

              const results$ = RxjsUtils.concurrentForkJoin(searchChecks$).pipe(
                map((data: [ApiError | ForgeContentDataElement[], string][]) => {
                  const result: InterdepentReferencesResult = {
                    isSuccess: true,
                    referencedIds: [],
                  };
                  const anyErrors = !!data.find((x) => (x[0] as ApiError).errors);
                  if (anyErrors) {
                    result.isSuccess = false;
                    return result;
                  }

                  result.referencedIds = data
                    .filter((x) => (x[0] as ForgeContentDataElement[]).length)
                    .map((x) => x[1]);
                  return result;
                })
              );

              return forkJoin([of(action), results$]);
            }),
            take(1),
            switchMap((data: [LoadInterdependentReferences, InterdepentReferencesResult]) => {
              const [action, result] = data;
              return result.isSuccess
                ? of([
                    new LoadInterdependentReferencesSuccess({
                      interdependentReferenceIds: result.referencedIds,
                      id: action.payload.dataElement.id,
                    }),
                  ])
                : of([
                    new LoadInterdependentReferencesFail({
                      id: action.payload.dataElement.id,
                    }),
                  ]);
            })
          );
      }),
      switchMap((actions) => actions)
    )
  );

  constructor(
    private actions$: Actions,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService
  ) {}
}
