import { Action } from '@ngrx/store';
import { BinaryActivityMarker } from '@models/activities-events/activities';

export enum BinaryActivityActionTypes {
  UpsertBinaryActivityMarker = '[Binary Activity] Upsert Binary Activity Marker',
  DeleteBinaryActivityMarker = '[Binary Activity] Delete Binary Activity Marker',
}

export class UpsertBinaryActivityMarker implements Action {
  readonly type = BinaryActivityActionTypes.UpsertBinaryActivityMarker;

  constructor(public payload: { marker: BinaryActivityMarker }) {}
}

export class DeleteBinaryActivityMarker implements Action {
  readonly type = BinaryActivityActionTypes.DeleteBinaryActivityMarker;

  constructor(
    public payload: {
      objectKey: string;
    }
  ) {}
}

export type BinaryActivityActions = UpsertBinaryActivityMarker | DeleteBinaryActivityMarker;
