// autodesk hig svg icons to register for use in app
export const higSvgIcons = [
  { name: 'add', path: '/assets/hig/24/add.svg' },
  { name: 'search', path: '/assets/hig/24/search.svg' },
  { name: 'close', path: '/assets/hig/24/close.svg' },
  { name: 'copy', path: '/assets/hig/24/copy.svg' },
  { name: 'edit', path: '/assets/hig/24/edit.svg' },
  { name: 'delete', path: '/assets/hig/24/trash.svg' },
  { name: 'up', path: '/assets/hig/24/up.svg' },
  { name: 'down', path: '/assets/hig/24/down.svg' },
  { name: 'issue', path: '/assets/hig/24/issue.svg' },
  { name: 'relationships', path: '/assets/hig/24/hierarchy.svg' },
  { name: 'item', path: '/assets/hig/24/item.svg' },
  { name: 'visible', path: '/assets/hig/24/visible.svg' },
  { name: 'back', path: '/assets/hig/24/back.svg' },
  { name: 'filter', path: '/assets/hig/24/filter.svg' },
  { name: 'undo', path: '/assets/hig/24/undo.svg' },
  { name: 'lock', path: '/assets/hig/24/lock.svg' },
  { name: 'unlock', path: '/assets/hig/24/unlock.svg' },
  { name: 'collapse-panel', path: '/assets/hig/24/collapse-panel.svg' },
  { name: 'forward', path: '/assets/hig/24/forward.svg' },
  { name: 'trash', path: '/assets/hig/24/trash.svg' },
  { name: 'help', path: '/assets/hig/24/help.svg' },
  { name: 'file-assembly16', path: '/assets/hig/16/file-assembly.svg' },
  { name: 'file-assembly', path: '/assets/hig/24/file-assembly.svg' },
  { name: 'properties16', path: '/assets/hig/16/properties.svg' },
  { name: 'properties', path: '/assets/hig/24/properties.svg' },
  { name: 'products-and-services16', path: '/assets/hig/16/products-and-services.svg' },
  { name: 'products-and-services', path: '/assets/hig/24/products-and-services.svg' },
  { name: 'service', path: '/assets/hig/24/service.svg' },
  { name: 'service16', path: '/assets/hig/16/service.svg' },
  { name: 'placeholder', path: '/assets/hig/16/placeholder.svg' },
  { name: 'settings', path: '/assets/hig/24/settings.svg' },
  { name: 'checklist', path: '/assets/hig/24/checklist.svg' },
  { name: 'checklist16', path: '/assets/hig/16/checklist.svg' },
  { name: 'sync', path: '/assets/hig/24/sync.svg' },
  { name: 'alert', path: '/assets/hig/16/alert.svg' },
  { name: 'error', path: '/assets/hig/16/error.svg' },
  { name: 'error-outline', path: '/assets/hig/24/error-outline.svg' },
  { name: 'alert-warning-badge', path: '/assets/hig/20/alert-warning-badge.svg' },
  { name: 'alert-sync-badge', path: '/assets/hig/20/alert-sync-badge.svg' },
  { name: 'error-badge', path: '/assets/hig/20/error-badge.svg' },
  { name: 'complete-badge', path: '/assets/hig/20/complete-badge.svg' },
  { name: 'tile-view', path: '/assets/hig/24/tile-view.svg' },
  { name: 'table-view', path: '/assets/hig/24/table-view.svg' },
];
