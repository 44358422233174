import { Injectable } from '@angular/core';
import { Theme } from '@models/theme/theme';
import { BehaviorSubject, Observable } from 'rxjs';

import FdmDarkBlueTheme from '@shared/hig/FdmDarkBlueTheme';
import FdmDarkGreyTheme from '@shared/hig/FdmDarkGrayTheme';
import FdmLightGrayTheme from '@shared/hig/FdmLightGrayTheme';
import { createTheme, densities, getTheme, themes } from '@weave-mui/styles';
import {
  csCZ,
  deDE,
  enUS,
  esES,
  frFR,
  itIT,
  jaJP,
  koKR,
  plPL,
  ptBR,
  ruRU,
  zhCN,
  zhTW,
} from '@weave-mui/data-grid';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _selectedTheme: Theme = Theme.Default;

  private themeChangeSubject = new BehaviorSubject<Theme>(Theme.Default);
  private muiThemeChangeSubject = new BehaviorSubject(createTheme({}));

  private readonly blackLogo = '/assets/content-editor-logo-black.png';
  private readonly whiteLogo = '/assets/content-editor-logo-white.png';

  private muiTheme = createTheme({});

  constructor() {
    this.generateMuiTheme();
  }

  get selectedTheme(): Theme {
    return this._selectedTheme;
  }

  get selectedMuiTheme() {
    return this.muiThemeChangeSubject.value;
  }

  private async generateMuiTheme() {
    const weaveTheme: any = await getTheme(this.getMuiTheme(), densities.MEDIUM);
    const customSpacing = (factor) => {
      const spacingIndex = Math.floor(factor);
      return weaveTheme.spacing(spacingIndex);
    };
    const languagePack = this.getLanguagePack();
    this.muiThemeChangeSubject.next(
      createTheme(
        this.muiTheme,
        {
          ...weaveTheme,
          spacing: customSpacing,
        },
        languagePack
      )
    );
  }

  private getMuiTheme() {
    switch (this.selectedTheme) {
      default:
      case Theme.Default:
        return themes.LIGHT_GRAY;

      case Theme.LightGrey:
        return themes.LIGHT_GRAY;

      case Theme.DarkBlue:
        return themes.DARK_BLUE;

      case Theme.DarkGrey:
        return themes.DARK_GRAY;
    }
  }

  getSelectedThemeData(isSideOrTopNav?: boolean) {
    switch (this.selectedTheme) {
      default:
      case Theme.Default:
        return isSideOrTopNav ? FdmDarkBlueTheme : FdmLightGrayTheme;

      case Theme.LightGrey:
        return FdmLightGrayTheme;

      case Theme.DarkBlue:
        return FdmDarkBlueTheme;

      case Theme.DarkGrey:
        return FdmDarkGreyTheme;
    }
  }

  get selectedLogo(): string {
    switch (this.selectedTheme) {
      default:
      case Theme.LightGrey:
        return this.blackLogo;

      case Theme.Default:
      case Theme.DarkBlue:
      case Theme.DarkGrey:
        return this.whiteLogo;
    }
  }

  changeTheme(theme: Theme): void {
    if (this.selectedTheme === theme) return;

    this._selectedTheme = theme;
    this.themeChangeSubject.next(theme);
    this.generateMuiTheme();
  }

  listenToThemeChanges(): Observable<Theme> {
    return this.themeChangeSubject.pipe();
  }

  listenToMuiThemeChanges() {
    return this.muiThemeChangeSubject;
  }

  getLanguagePack() {
    switch (navigator.language) {
      case 'cs':
        return csCZ;
      case 'de':
        return deDE;
      case 'es':
        return esES;
      case 'fr':
        return frFR;
      case 'it':
        return itIT;
      case 'ja':
        return jaJP;
      case 'ko':
        return koKR;
      case 'pl':
        return plPL;
      case 'pt-BR':
        return ptBR;
      case 'ru':
        return ruRU;
      case 'zh-CN':
        return zhCN;
      case 'zh-TW':
        return zhTW;
      default:
        return enUS;
    }
  }
}
