import { DataElementType } from '@constants/data-element-types';
import { Part } from '@models/fabrication/part';
import sha256 from 'crypto-js/sha256';

export class PartUtils {
  static getPartHashedFolderPath(part: Part): string {
    let folders = part.path.split('\\');
    folders.pop(); //remove file name
    folders = folders.concat(PartUtils.getIdentityFolders(part));

    return folders.join('\\');
  }

  static getIdentityFolders(part: Part): string[] {
    const folders: string[] = [];
    folders.push(PartUtils.getHash(part.brand));
    folders.push(PartUtils.getHash(part.range));
    folders.push(
      PartUtils.getHash(
        part.fabricationReferences.find(
          (o) => o.dataType === DataElementType.Material && !('isLocked' in o)
        )?.externalId
      )
    );
    //Add Material Finish to the folder structure
    folders.push(
      PartUtils.getHash(
        part.fabricationReferences.find(
          (o) => o.dataType === DataElementType.Material && 'isLocked' in o
        )?.externalId
      )
    );

    return folders;
  }

  static getHash = (s: string) => (sha256(s || '').toString() as string).substring(0, 12);
}
