import { Injector } from '@angular/core';
import { ThemeProvider } from '@weave-mui/styles';
import { useState, useEffect, FC, StrictMode } from 'react';
import { BehaviorSubject } from 'rxjs';
import { AngularInjectorContext } from '../contexts/angular-injector-context';

export interface ReactFactoryProps<T> {
  propsChangeSubject: BehaviorSubject<T>;
  component: FC<T>;
  themeChangeSubject: BehaviorSubject<any>;
  injector?: Injector;
}

export const ReactFactory: FC<ReactFactoryProps<any>> = (passedProps) => {
  const [props, setProps] = useState<any>();
  const [theme, setTheme] = useState<any>();

  const Component = passedProps.component;

  useEffect(() => {
    const propsChangeSubscription = passedProps.propsChangeSubject.subscribe((props) => {
      setProps(props);
    });
    const themeChangeSubscription = passedProps.themeChangeSubject.subscribe((theme) => {
      setTheme(theme);
    });
    return () => {
      propsChangeSubscription.unsubscribe();
      themeChangeSubscription.unsubscribe();
    };
  }, [passedProps.propsChangeSubject, passedProps.themeChangeSubject]);

  return props ? (
    <StrictMode>
      <AngularInjectorContext.Provider value={passedProps.injector}>
        <ThemeProvider theme={theme || {}}>
          <Component {...props} />
        </ThemeProvider>
      </AngularInjectorContext.Provider>
    </StrictMode>
  ) : null;
};
