import { DataElementType } from '@constants/data-element-types';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { StorageFileType } from '@models/fabrication/files';

export enum ActivityActionType {
  Add,
  Update,
  Delete,
}

export interface ActivityStream<T extends ActivityResource | null> {
  streamItems: StreamItem<T>[];
  nextToken?: string;
}

export interface ActivityBatch<T extends ActivityResource | null> {
  activities: Activity<T>[];
}

export interface StreamItem<T> {
  id: string;
  activity: Activity<T>;
  submitted: string;
}

export interface Activity<T> {
  published: string;
  generator: string;
  actor: Actor;
  verb: ConfigProcessingAction | ConfigDataAction | ConfigUserAction | ConfigBinaryAction;
  object: ActivityObject<T>;
}

export interface Actor {
  id: string;
  displayName: string;
  dataUserId?: string;
}

export interface ActivityObject<T> extends ActivityResource {
  data: T;
  submissionId: string;
}

export interface ActivityResource {
  id: string;
  displayName: string;
  dataType?: DataElementType;
  action?: string;
  oldPath?: string;
  externalIds?: string;
  updatedReferenceContentIds?: string;
}

export interface ConfigDataActivityResource extends ActivityResource {
  urn: string;
  nodeId: string;
}

export interface ConfigUserActivityResource extends ActivityResource {
  role: string;
}

export type ConfigProcessingAction =
  | 'config-processing-started'
  | 'config-processing-complete'
  | 'config-processing-failed'
  | 'config-deleted';

export type ConfigDataAction = 'config-data-added' | 'config-data-updated' | 'config-data-deleted';

export type ConfigUserAction = 'config-user-added' | 'config-user-updated' | 'config-user-deleted';

export type ConfigBinaryAction =
  | 'config-binary-added'
  | 'config-binary-updated'
  | 'config-binary-deleted';

export interface ActivitySubmission {
  id: string;
  configUrn: string;
  nodeId: string;
  dataType: DataElementType;
  activitySubmissionId: string;
  entries: ActivitySubmissionEntry[];
  disableNotification?: boolean;
}

export interface ActivitySubmissionEntry {
  activityType: ActivityActionType;
  urn: string;
  name: string;
}

export interface ActivityProcessEntry {
  configUrn: string;
  dataType: DataElementType;
  activityType: ActivityActionType;
  urn: string;
  isBinaryUpdateOperation: boolean; // currently only filtering on update operations, expand when required for add & delete
}

export interface ActivityProcess {
  lastActivityIdProcessed: string;
  entries: ActivityProcessEntry[];
}

export type ActivityEventMessageType = 'data' | 'config-processing' | 'binary' | 'user' | 'error';

export interface ActivityEventMessage<T> {
  dataUserId?: string;
  dataAction?: string;
  dataName?: string;
  messageType: ActivityEventMessageType;
  dataType: DataElementType;
  configUrn: string;
  activitySubmissionId: string;
  messages: T[];
  jobType?: BinaryJobType;
}

export interface ConfigDataActivityEvent {
  activityType: ActivityActionType;
  urn: string;
  name: string;
  binaryFileType?: StorageFileType;
  nodeId?: string;
}

export interface ConfigActivityEventChannels {
  [configUrn: string]: string;
}

export interface NotificationChannelDetails {
  Channel: string;
}

export interface NotificationChannel {
  channelId: string;
}

export interface RealTimeActivityMarker {
  configUrn: string;
  dataTypes: DataElementType[];
  partNodeIds: string[]; // use to indicate an activity on a part node
  jobType: BinaryJobType;
  contentItemIds?: string[];
}

export interface BinaryActivityMarker {
  configUrn: string;
  configExternalId: string;
  dataType: DataElementType;
  filePath: string;
  objectKey: string;
}
