import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromApplication from '../reducers/application.reducer';

// application state
export const selectApplicationState = (state: FDMState) => state.applicationState.applicationData;

// application selectors (current selections)
export const selectConfigsLoaded = createSelector(
  selectApplicationState,
  fromApplication.selectConfigsLoaded
);

export const selectConfigsLoading = createSelector(
  selectApplicationState,
  fromApplication.selectConfigsLoading
);

export const selectConfigsLoadingFailed = createSelector(
  selectApplicationState,
  fromApplication.selectConfigsLoadingFailed
);

export const selectCurrentConfigExternalId = createSelector(
  selectApplicationState,
  fromApplication.selectCurrentConfigExternalId
);

export const selectCurrentItemFileIds = createSelector(
  selectApplicationState,
  fromApplication.selectCurrentItemFileIds
);

export const selectLoadedItemFolders = createSelector(
  selectApplicationState,
  fromApplication.selectLoadedItemFolders
);

export const selectRequestedRefreshPath = createSelector(
  selectApplicationState,
  fromApplication.selectRequestedRefreshpath
);

export const selectInitialCacheValidityCheckComplete = createSelector(
  selectApplicationState,
  fromApplication.selectInitialCacheValidityCheckComplete
);

export const selectEntitlementCheckComplete = createSelector(
  selectApplicationState,
  fromApplication.selectEntitlementheckComplete
);

export const selectApplicationIsDisabled = createSelector(
  selectApplicationState,
  fromApplication.selectApplicationIsDisabled
);
