import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromStiffenerInfos from '../reducers/stiffener-info.reducer';

// stiffenerInfo state
export const selectStiffenerInfoState = (state: FDMState) => state.configsState.stiffenerInfos;

// stiffenerInfo selectors
export const selectStiffenerInfoIds = createSelector(
  selectStiffenerInfoState,
  fromStiffenerInfos.selectStiffenerInfoIds
);

export const selectStiffenerInfoEntities = createSelector(
  selectStiffenerInfoState,
  fromStiffenerInfos.selectStiffenerInfoEntities
);

export const selectAllStiffenerInfos = createSelector(
  selectStiffenerInfoState,
  fromStiffenerInfos.selectAllStiffenerInfos
);

export const selectStiffenerInfosTotal = createSelector(
  selectStiffenerInfoState,
  fromStiffenerInfos.selectStiffenerInfosTotal
);

export const selectStiffenerInfoById = (id: string) =>
  createSelector(selectStiffenerInfoEntities, (stiffenerInfoEntities) => stiffenerInfoEntities[id]);
