import { EnvironmentConstants } from '@constants/environment-constants';
import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { DomainType, ShapeType } from './common';
import { FabricationReference } from '@models/forge-content/references';

export enum InsulationSpecificationTableType {
  SingleDimension = 'SingleDimension',
  LongSideShortSide = 'LongSideShortSide',
}

export type InsulationSpecificationTableEntryType =
  | InsulationSpecificationTableEntrySingleDimension
  | InsulationSpecificationTableEntryLongSideShortSide;

export class InsulationSpecificationTableEntry {
  material = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL;
  materialSpecification = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
}

export class InsulationSpecificationTableEntrySingleDimension extends InsulationSpecificationTableEntry {
  size = 0;
}

export class InsulationSpecificationTableEntryLongSideShortSide extends InsulationSpecificationTableEntry {
  longSide = 0;
  shortSide = 0;
}

export class InsulationSpecificationTable {
  shape: ShapeType;
  domain: DomainType;
  entries: InsulationSpecificationTableEntryType[];

  constructor(
    shape: ShapeType = ShapeType.Round,
    domain: DomainType = DomainType.Pipework,
    entries: InsulationSpecificationTableEntryType[] = [
      createInsulationSpecificationTableEntryFactory(
        InsulationSpecificationTableType.SingleDimension
      ),
    ]
  ) {
    this.shape = shape;
    this.domain = domain;
    this.entries = entries;
  }
}

export class InsulationSpecificationInfo extends ForgeContentDataElement {
  category = '';
  abbreviation = '';
  tableType: InsulationSpecificationTableType;
  tables: InsulationSpecificationTable[] = [];
  fabricationReferences: FabricationReference[] = [];
  constructor(
    tableType: InsulationSpecificationTableType = InsulationSpecificationTableType.SingleDimension
  ) {
    super();
    this.tableType = tableType;
  }
}

export const createInsulationSpecificationTableEntryFactory = (
  tableType: InsulationSpecificationTableType
): InsulationSpecificationTableEntryType => {
  let entry: InsulationSpecificationTableEntryType = null;
  switch (tableType) {
    case InsulationSpecificationTableType.SingleDimension:
      entry = new InsulationSpecificationTableEntrySingleDimension();
      break;
    case InsulationSpecificationTableType.LongSideShortSide:
      entry = new InsulationSpecificationTableEntryLongSideShortSide();
      break;
    default:
      break;
  }

  if (!entry) {
    throw new Error('Insulation Specification Table Entry Type not Currently Supported');
  }

  return entry;
};
