import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataLoadingService } from '@services/data-loading.service';
import { FDMState } from '@store/reducers';
import { isFullScreenEnabled } from '@store/selectors/fullscreen-mode.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'fab-loading',
  styleUrls: ['loading.component.scss'],
  template: `
    <div [ngClass]="fullScreenEnabled ? 'full-screen-enabled' : 'full-screen-disabled'">
      <div *ngIf="loading" class="loader text-center">
        <fab-linear-progress variant="indeterminate"></fab-linear-progress>
      </div>
    </div>
  `,
})
export class LoadingComponent implements OnInit, OnDestroy {
  loading = false;
  fullScreenEnabled = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private dataLoadingService: DataLoadingService, private store$: Store<FDMState>) {}

  ngOnInit() {
    this.dataLoadingService.listenToDataLoadActions();
    this.dataLoadingService.dataLoadingSubject.subscribe((dataLoading) => {
      if (dataLoading !== this.loading) {
        this.loading = dataLoading;
      }
    });

    this.store$
      .select(isFullScreenEnabled)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isFullScreenEnabled: boolean) => (this.fullScreenEnabled = isFullScreenEnabled));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
