import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';

export enum MaterialType {
  Main = 'Main',
  Insulation = 'Insulation',
  Ductboard = 'Ductboard',
  Finish = 'Finish',
}

export class Material extends ForgeContentDataElement {
  category = '';
  materialType = MaterialType.Main;
  abbreviation = '';
  connectivity = '';

  constructor(materialType: MaterialType) {
    super();

    if (materialType === MaterialType.Finish) {
      throw new Error(
        'MaterialType.Finish is not supported on materials. Use MaterialFinish instead.'
      );
    }

    this.materialType = materialType;
    this.fabricationReferences = [];
  }
}
