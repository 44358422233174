import { DynamicConnectorInfoSetup } from './dynamic-connector-info-setup';
import { DynamicDamperInfoSetup } from './dynamic-damper-info-setup';
import { DynamicStiffenerInfoSetup } from './dynamic-stiffener-info-setup';
import { DynamicSystemInfoSetup } from './dynamic-system-info-setup';
import { DynamicSpecificationInfoSetup } from './dynamic-specification-info-setup';
import { DynamicInsulationSpecificationInfoSetup } from './dynamic-ins-specification-info-setup';
import { DynamicDbFileSetup } from './dynamic-db-file-setup';
import { DynamicImageFileSetup } from './dynamic-image-file-setup';
import { DynamicPartSetup } from './dynamic-part-setup';
import { DynamicServiceTemplateInfoSetup } from './dynamic-service-template-info-setup';
import { DynamicFabricationRateSetup } from './dynamic-fabrication-rate-setup';
import { DynamicInstallationRateSetup } from './dynamic-installation-rate-setup';
import { InjectionToken, Provider } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicMaterialSetup } from './dynamic-material-setup';
import { DynamicMaterialSpecSetup } from './dynamic-material-spec-setup';
import { DynamicInvalidDataSetup } from './dynamic-invalid-data-setup';
import { DynamicStiffenerSpecificationInfoSetup } from './dynamic-stiffener-specification-info-setup';

export const dynamicDataSetupToken = new InjectionToken<DynamicDataElementTypeSetup<any>>(
  'dynamicDataSetupProviders'
);

const dynamicDataSetupProviders: any[] = [
  DynamicConnectorInfoSetup,
  DynamicDamperInfoSetup,
  DynamicStiffenerInfoSetup,
  DynamicSystemInfoSetup,
  DynamicSpecificationInfoSetup,
  DynamicInsulationSpecificationInfoSetup,
  DynamicDbFileSetup,
  DynamicImageFileSetup,
  DynamicPartSetup,
  DynamicServiceTemplateInfoSetup,
  DynamicFabricationRateSetup,
  DynamicInstallationRateSetup,
  DynamicMaterialSetup,
  DynamicMaterialSpecSetup,
  DynamicInvalidDataSetup,
  DynamicStiffenerSpecificationInfoSetup,
];

export const dynamicDataSetupMultiProvider: Provider[] = dynamicDataSetupProviders.map((x) => ({
  provide: dynamicDataSetupToken,
  multi: true,
  useClass: x,
}));
