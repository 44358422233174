import { Component, OnInit } from '@angular/core';

import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { DynamicAppDependencyService } from '@services/dynamic-app-dependency.service';
import { ForgeUnitsService } from '@services/data-services';
import { AppDependencyType } from '@constants/app-dependency-types';
import { UnitSchemaType } from '@models/forge-units/forge-unit-schema-types';
import { ConfigUnitSystem } from '@models/fabrication/config';

@Component({
  selector: 'fab-toggle-value',
  templateUrl: './toggle-value.component.html',
})
export class ToggleValueComponent extends DynamicFormBaseCustomComponent<any> implements OnInit {
  currentValue: string;

  private forgeUnitsService: ForgeUnitsService;
  private units: UnitSchemaType;

  constructor(store$: Store<FDMState>, appDependencyService: DynamicAppDependencyService) {
    super(store$);

    this.forgeUnitsService = appDependencyService.getDynamicAppDependencyForType(
      AppDependencyType.ForgeUnits
    ) as ForgeUnitsService;
  }

  ngOnInit() {
    this.units =
      this.configUnitSystem === ConfigUnitSystem.Imperial
        ? this.props.toggleValueOptions.units.imperial
        : this.props.toggleValueOptions.units.metric;
    this.currentValue = this.forgeUnitsService.stringifyValue(
      this.model[this.key as string].value,
      this.units
    );
  }

  onToggleChange(event: MatSlideToggleChange) {
    const key = this.key as string;
    if (event.checked) {
      this.model[key].option = this.props.toggleValueOptions.trueValue;
    } else {
      this.model[key].option = 'Value';
    }

    this.updateSource(this.model[key]);
  }

  onInputChange() {
    const key = this.key as string;
    try {
      const value = this.forgeUnitsService.parse(this.units, this.currentValue);
      this.model[key].value = value;
      this.updateSource(this.model[key]);
    } catch (error) {
      this.currentValue = this.stringifyUnits();
    }
  }

  stringifyUnits(): string {
    return this.forgeUnitsService.stringifyValue(this.model[this.key as string].value, this.units);
  }
}
