import { createSelector } from '@ngrx/store';
import { AccessRightsData } from '@models/access-rights/access-rights';
import { FDMState } from '../reducers';
import * as fromAccessRights from '../reducers/access-rights.reducer';

export const selectAccessRightsState = (state: FDMState) => state.applicationState.accessRightsData;

export const selectAccessRightsIds = createSelector(
  selectAccessRightsState,
  fromAccessRights.selectAccessRightsIds
);

export const selectAccessRightsEntities = createSelector(
  selectAccessRightsState,
  fromAccessRights.selectAccessRightsEntities
);

export const selectAllAccessRights = createSelector(
  selectAccessRightsState,
  fromAccessRights.selectAllAccessRights
);

export const selectAccessRightsTotal = createSelector(
  selectAccessRightsState,
  fromAccessRights.selectAccessRightsTotal
);

export const selectAccessRightsById = (id: string) =>
  createSelector(
    selectAccessRightsEntities,
    (AccessRightsEntities): AccessRightsData => AccessRightsEntities[id]
  );
