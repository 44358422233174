import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppUpdateService } from '@services/app-updates/app-update.service';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { map, switchMap, take } from 'rxjs/operators';
import { FeatureFlagService } from '@services/feature-flag.service';

/**
 * Entry point Guard into application
 * will check for valid auth session and any updates to the application
 * from the Service Worker (in that order)
 * @export
 * @class AppEntryGuard
 */
@Injectable({
  providedIn: 'root',
})
class AppEntryGuardService {
  constructor(
    private appUpdateService: AppUpdateService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService
  ) {}

  canMatch = (): Observable<boolean> =>
    this.authService.isValidAuthSession().pipe(
      switchMap((isValid: boolean) => {
        if (isValid) {
          return this.appUpdateService.checkForAppUpdates();
        }
        return of(false);
      }),
      switchMap((isValid: boolean) => {
        if (!isValid) {
          return of(false);
        }
        return this.featureFlagService.initialise().pipe(
          // don't block the app if the feature flag service returns false
          map(() => isValid)
        );
      }),
      take(1)
    );
}

export const AppEntryGuard: CanMatchFn = (): Observable<boolean> => {
  return inject(AppEntryGuardService).canMatch();
};
