import * as fromConnectorInfo from '../actions/connector-info.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ConnectorInfo } from '@models/fabrication/connector-info';

export interface ConnectorInfoState extends EntityState<ConnectorInfo> {} // eslint-disable-line

export const connectorInfoAdapter: EntityAdapter<ConnectorInfo> =
  createEntityAdapter<ConnectorInfo>({
    selectId: (connectorInfo: ConnectorInfo) => connectorInfo.id,
  });

export const initialState: ConnectorInfoState = connectorInfoAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromConnectorInfo.ConnectorInfoActions
): ConnectorInfoState {
  switch (action.type) {
    // connectorInfo
    case fromConnectorInfo.ConnectorInfoActionTypes.LoadConnectorInfos: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.LoadConnectorInfosSuccess: {
      return connectorInfoAdapter.upsertMany(action.data, state);
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.LoadConnectorInfosFail: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.AddConnectorInfo: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.AddConnectorInfoSuccess: {
      return connectorInfoAdapter.addOne(action.dataElement, state);
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.AddConnectorInfoFail: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.CopyConnectorInfo: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.CopyConnectorInfoSuccess: {
      return connectorInfoAdapter.addOne(action.dataElement, state);
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.CopyConnectorInfoFail: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.UpdateConnectorInfo: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.UpdateConnectorInfoSuccess: {
      return connectorInfoAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.UpdateConnectorInfoFail: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.DeleteConnectorInfos: {
      return state;
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.DeleteConnectorInfosSuccess: {
      return connectorInfoAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromConnectorInfo.ConnectorInfoActionTypes.DeleteConnectorInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of connectorInfo ids
  selectIds: selectConnectorInfoIds,

  // select the dictionary of connectorInfo entities
  selectEntities: selectConnectorInfoEntities,

  // select the array of connectorInfos
  selectAll: selectAllConnectorInfos,

  // select the total connectorInfo count
  selectTotal: selectConnectorInfosTotal,
} = connectorInfoAdapter.getSelectors();
