import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import {
  NotificationPlace,
  DEFAULT_AUTODISMISS_TIME,
  NotificationLink,
  NotificationButton,
} from '@models/notification/notification';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import {
  Banner,
  BannerType,
  Toast,
  ToastStatus,
  NotificationToastSeveritiesType,
} from '@models/ui-state/weave-notification';

@Component({
  selector: 'fab-notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  notifcationSubscription: Subscription;
  hideNotificationSubscription: Subscription;
  showBannerNotification = false;
  showDialogNotification = false;
  bannerNotification: Banner = {};
  notificationLink: NotificationLink = null;
  onDismissNotification: () => void;
  toastNotifications: Toast[] = [];
  idLookup = elementIdDictionary.notificationBar;
  dialogBtnContinueLabel: string;
  dialogBtnCancelLabel: string;
  notificationButtons: NotificationButton[] = [];

  constructor(private notificationService: NotificationService, translate: TranslateService) {
    this.dialogBtnContinueLabel = translate.instant(LC.NOTIFICATIONS.DIALOG.BTN_CONTINUE);
    this.dialogBtnCancelLabel = translate.instant(LC.NOTIFICATIONS.DIALOG.BTN_CANCEL);
  }

  ngOnInit() {
    this.notifcationSubscription = this.notificationService
      .getNotificationChanges()
      .subscribe((notification) => {
        // check if it was a simple banner notification
        if (notification.place === NotificationPlace.Banner) {
          this.bannerNotification = {
            type: BannerType[notification.type],
            message: notification.messages.join('\n'),
          };
          this.notificationLink = notification.link;
          this.onDismissNotification = notification.onDismiss;
          this.showBannerNotification = true;
          this.notificationButtons = notification.buttons;
        } else if (notification.place === NotificationPlace.Toast) {
          // or a toast
          const nextToast: Toast = {
            id: notification.id ?? uuidv4(),
            message: notification.messages.join('\n'),
            status: ToastStatus[notification.type] as NotificationToastSeveritiesType,
            description: notification.description,
            timestamp: notification.timestamp,
            link: notification.link,
            onDismiss: notification.onDismiss,
          };
          this.toastNotifications = [...this.toastNotifications, nextToast];

          // if autodismiss on find index by uuid and them remove that from toast list in a certain time
          if (notification.autoDismiss) {
            setTimeout(() => {
              this.removeToastNotificationById(nextToast.id);
            }, DEFAULT_AUTODISMISS_TIME);
          }
        }
      });

    this.hideNotificationSubscription = this.notificationService
      .getHideNotificationChanges()
      .subscribe(({ id }) => {
        this.showDialogNotification = false;

        if (id) {
          this.removeToastNotificationById(id);
        }
      });
  }

  ngOnDestroy(): void {
    this.notifcationSubscription.unsubscribe();
    this.hideNotificationSubscription.unsubscribe();
  }

  handleOnBannerDismiss() {
    this.showBannerNotification = false;
    if (this.onDismissNotification) {
      this.onDismissNotification();
    }
  }

  handleOnDismissToast(id: string) {
    this.removeToastNotificationById(id);
  }

  private removeToastNotificationById(id: string) {
    const indexToDelete = this.toastNotifications.findIndex((toast) => toast.id === id);
    if (indexToDelete === -1) {
      return;
    }

    this.toastNotifications = [
      ...this.toastNotifications.slice(0, indexToDelete),
      ...this.toastNotifications.slice(indexToDelete + 1),
    ];
  }
}
