import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, map, switchMap, withLatestFrom, take, catchError } from 'rxjs/operators';
import { Observable, combineLatest, of } from 'rxjs';
import { Config, ConfigResponse, ConfigUnitSystem } from '@models/fabrication/config';
import { FDMState } from '@store/reducers/index';
import {
  ConfigsActionTypes,
  LoadConfigsSuccess,
  LoadConfigsFail,
  DeleteConfig,
  DeleteConfigSuccess,
  DeleteConfigFail,
  LoadGrantedConfig,
  LoadGrantedConfigSuccess,
  LoadGrantedConfigFail,
  RevokeConfig,
  RevokeConfigSuccess,
  RevokeConfigFail,
  UpdateConfig,
  UpdateConfigSuccess,
  UpdateConfigFail,
} from '@store/actions/configs.action';
import {
  SetConfigsLoaded,
  SetConfigsLoadingFailed,
  SetCurrentConfigExternalId,
} from '@store/actions/application.action';
import { selectAllConfigs } from '@store/selectors/configs.selectors';
import { selectCurrentConfigExternalId } from '@store/selectors/application.selectors';
import { AccessRightsRemoveData } from '@store/actions/access-rights.action';
import { RemoveConfigLoadingState } from '../actions/loading-data.action';
import { AccessRightsService } from '@services/access-rights/access-rights.service';
import { CleanStaleConfigCacheRecords } from '../actions/cache-data.action';
import { NavigationConstants } from '@constants/navigation-constants';
import { ResilientHttpService } from '@services/http/resilient-http.service';
import { EnvironmentService } from '@services/environment.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { ErrorUtils } from '@utils/error-utils';
import { ContentFileMap, StorageFileType } from '@models/fabrication/files';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { DataElementType } from '@constants/data-element-types';
import { NotificationService } from '@services/notification.service';
import { NotificationType } from '@models/notification/notification';
import { ImageUrlUtils } from '@utils/image-url-utils';
import { ConfigService } from '@services/config.service';

@Injectable()
export class ConfigsEffects {
  // load configs
  private getAllConfigs(configId?: string, collectionId?: string): Observable<Config[]> {
    let url = `${this.envService.environment.fabdmCm.baseUrl}v2/content/libraries`;
    if (configId && collectionId) url += '?libraryid=' + configId + '&collectionid=' + collectionId;
    return this.resilientHttpService.get<ConfigResponse>(url).pipe(
      take(1),
      map((response: ConfigResponse) => {
        return response.libraries
          .map((c) => {
            if (c.status !== 'complete') return null;

            const config = new Config();
            config.externalId = c.externalId;
            config.collectionId = c.collectionId;
            config.id = c.id;
            config.name = c.name;
            config.longDescription = c.longDescription ?? '';
            config.image = c.imageUrl;
            config.imageObjectKey = c.imageObjectKey;
            config.guid = c.configGuid;
            config.imagesFolderPath = c.imagesFolderPath;
            config.itemsFolderPath = c.itemsFolderPath;
            config.unitSystem = {
              imperial: ConfigUnitSystem.Imperial,
              metric: ConfigUnitSystem.Metric,
            }[c.unitType];
            config.notificationChannelId = c.notificationId;

            //So default thumbnail if not present
            if (!config.image) {
              config.image =
                '/assets/' +
                (config.unitSystem === ConfigUnitSystem.Imperial
                  ? 'config-imperial.png'
                  : 'config-metric.png');
              config.imageObjectKey = 'CONFIG_THUMBNAIL_' + config.unitSystem;
            }

            return config;
          })
          .filter((c) => !!c);
      }),
      switchMap((configs: Config[]) => {
        //store all the thumbnail in the indexDb
        const contentFileMap = configs.map(
          (c): ContentFileMap => ({
            contentFile: {
              name: c.name,
              description: c.longDescription ?? '',
              objectKey: c.imageObjectKey,
              contentExternalId: c.id,
              path: 'config.png', //Dummy data
            },
            fileUrl: c.image,
            isImage: true,
            configId: c.id,
          })
        );

        return this.fileStorageService
          .addStorageFiles(contentFileMap, DataElementType.DBFile, Object.values(StorageFileType))
          .pipe(map(() => configs));
      }),
      switchMap((configs) =>
        combineLatest([
          of(configs),
          this.fileStorageService.getImageFiles(configs.map((c) => c.imageObjectKey)),
        ])
      ),
      map(([configs, files]) => {
        const filesMap = files.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
        configs.forEach((c) => {
          c.thumbnailStorageFile = filesMap[c.imageObjectKey];
          c.thumbnailUrl = this.imageUrlUtils.getImageUrlFromThumbnailStorageFile(
            c.thumbnailStorageFile
          );
        });
        return configs;
      }),
      catchError((err) => {
        this.loggingService.logError(
          err,
          false,
          this.translate.instant(LC.NOTIFICATIONS.ERROR_READING_CONFIG_DATA),
          ErrorUtils.getAdditionalStack()
        );
        return of(null);
      })
    );
  }

  loadConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigsActionTypes.LoadConfigs),
      switchMap(() => this.getAllConfigs()),
      map((configs: Config[]) => {
        const dispatchActions = [];

        // successfully loaded configs, check for no available configs
        if (configs) {
          // if no data, assign the selectedConfigId to null
          // this will trigger the 'no data' side bar
          dispatchActions.push(
            new LoadConfigsSuccess({ configs }),
            new SetConfigsLoaded(true),
            // cache check to clear any stale config records i.e. left behind from a revoke permissions op
            new CleanStaleConfigCacheRecords({
              ids: configs?.map((x) => x.id) || [],
              clearMatchingRecords: false,
            })
          );

          if (!configs.length) {
            // prompt creation of empy nav menus
            dispatchActions.push(new SetCurrentConfigExternalId(null));
          }
        } else {
          dispatchActions.push(
            new LoadConfigsFail(),
            new SetConfigsLoadingFailed(true),
            new SetCurrentConfigExternalId(null)
          );
        }

        return dispatchActions;
      }),
      switchMap((actions) => actions)
    )
  );

  loadGrantedConfig$ = createEffect(() => {
    let grantedConfigId = null;
    return this.actions$.pipe(
      ofType(ConfigsActionTypes.LoadGrantedConfig),
      tap((action: LoadGrantedConfig) => (grantedConfigId = action.payload.id)),
      switchMap((action: LoadGrantedConfig) => this.getAllConfigs(action.payload.id)),
      withLatestFrom(this.store$.select(selectCurrentConfigExternalId)),
      map((configData: [Config[], string]) => {
        const result = {
          actions: [],
          success: false,
          routeToConfigId: '',
        };
        const config = configData[0] && configData[0].find((x) => x.id === grantedConfigId);
        const currentId = configData[1];
        if (config) {
          result.success = true;
          // if no current configs then setup routing to the newly granted config
          result.routeToConfigId = currentId ? '' : config.externalId;
          result.actions = [new LoadGrantedConfigSuccess({ config })];
        } else {
          result.actions = [new LoadGrantedConfigFail()];
        }

        return result;
      }),
      tap((result) => {
        if (result.success && result.routeToConfigId) {
          this.router.navigate([`data/config/${result.routeToConfigId}`]);
        }
      }),
      switchMap((result) => result.actions)
    );
  });

  revokeConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigsActionTypes.RevokeConfig),
      withLatestFrom(
        this.store$.select(selectAllConfigs),
        this.store$.select(selectCurrentConfigExternalId)
      ),
      map((revokeData: [RevokeConfig, Config[], string]) => {
        const result = {
          actions: [],
          success: false,
          routeToGettingStarted: false,
          routeToConfigId: '',
        };
        const [action, allConfigs, currentConfigId] = revokeData;
        const revokedConfig = allConfigs.find((x) => x.externalId === action.payload.externalId);

        if (revokedConfig) {
          const remainingConfigs = allConfigs.filter(
            (x) => x.externalId !== action.payload.externalId
          );

          result.actions.push(new RevokeConfigSuccess({ config: revokedConfig }));
          result.actions.push(new AccessRightsRemoveData({ id: revokedConfig.id }));
          result.actions.push(new RemoveConfigLoadingState({ configId: revokedConfig.externalId }));
          result.actions.push(
            new CleanStaleConfigCacheRecords({
              ids: [revokedConfig.id],
              clearMatchingRecords: true,
            })
          );
          result.success = true;

          if (currentConfigId === action.payload.externalId) {
            result.routeToConfigId =
              (remainingConfigs?.length && remainingConfigs[0].externalId) || '';
            result.routeToGettingStarted = !remainingConfigs?.length;
            if (result.routeToGettingStarted) {
              result.actions.push(new SetCurrentConfigExternalId(null));
            }
          }
        } else {
          result.actions.push(new RevokeConfigFail());
        }

        return result;
      }),
      tap((result) => {
        if (result.success) {
          if (result.routeToGettingStarted) {
            this.router.navigate([NavigationConstants.GETTING_STARTED_LINK]);
          }
          if (result.routeToConfigId) {
            this.router.navigate([`data/config/${result.routeToConfigId}`]);
          }
        }
      }),
      switchMap((result) => result.actions)
    )
  );

  updateConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigsActionTypes.UpdateConfig),
      switchMap((action: UpdateConfig) => {
        const url = `${this.envService.environment.fabdmCm.baseUrl}v2/content/libraries`;
        return this.resilientHttpService
          .put(url, {
            data: {
              collectionId: action.payload.config.collectionId,
              libraryId: action.payload.config.id,
              thumbnail: action.payload.thumbnail,
            },
          })
          .pipe(
            switchMap(() =>
              this.getAllConfigs(action.payload.config.id, action.payload.config.collectionId)
            ),
            tap((configs) => {
              const message = this.translate.instant(LC.NOTIFICATIONS.THUMBNAIL_EDITOR.UPLOADED, {
                thumbnailName: action.payload.config.name,
              });
              this.notificationService.showToast({ message, type: NotificationType.Success });
              this.configService.notifyConfigUpdate(configs);
            }),
            switchMap((configs: Config[]) => {
              const config = { ...action.payload.config };
              config.thumbnailStorageFile = configs[0].thumbnailStorageFile;
              config.thumbnailUrl = configs[0].thumbnailUrl;
              return [
                new UpdateConfigSuccess({ config }),
                new SetCurrentConfigExternalId(config.externalId), //To update the thumbnail in top nav,
              ];
            }),
            catchError(() => {
              const message = this.translate.instant(
                LC.NOTIFICATIONS.THUMBNAIL_EDITOR.UPLOAD_FAILED
              );
              this.notificationService.showToast({ message, type: NotificationType.Error });
              return [new UpdateConfigFail()];
            })
          );
      })
    )
  );

  // delete config

  deleteConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigsActionTypes.DeleteConfig),
      withLatestFrom(this.store$.select(selectAllConfigs)),
      switchMap((deleteData: [DeleteConfig, Config[]]) => {
        const [action, allConfigs] = deleteData;
        const configToDelete = allConfigs.find((x) => x.externalId === action.payload.externalId);
        const remainingConfigs = allConfigs.filter(
          (x) => x.externalId !== configToDelete.externalId
        );
        const result = {
          actions: [],
          success: false,
          routeToConfigId: '',
        };

        return this.accessRightsService.removeConfigData(configToDelete).pipe(
          map((deleted) => {
            if (deleted) {
              result.success = true;
              result.routeToConfigId =
                (remainingConfigs?.length && remainingConfigs[0].externalId) || '';
              result.actions = [
                new DeleteConfigSuccess({ id: configToDelete.externalId }),
                new CleanStaleConfigCacheRecords({
                  ids: [configToDelete.id],
                  clearMatchingRecords: true,
                }),
              ];

              // no data left to display
              if (!result.routeToConfigId) {
                // prompt creation of no-data menu
                result.actions = [...result.actions, new SetCurrentConfigExternalId(null)];
              }
            } else {
              result.actions = [new DeleteConfigFail()];
            }
            return result;
          }),
          tap((result) => {
            if (result.success) {
              if (result.routeToConfigId) {
                this.router.navigate([`data/config/${result.routeToConfigId}`]);
              } else {
                this.router.navigate([NavigationConstants.GETTING_STARTED_LINK]);
              }
            }
          }),
          switchMap((result) => result.actions)
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private router: Router,
    private accessRightsService: AccessRightsService,
    private resilientHttpService: ResilientHttpService,
    private envService: EnvironmentService,
    private loggingService: LoggingService,
    private translate: TranslateService,
    private fileStorageService: BinaryStorageService,
    private notificationService: NotificationService,
    private imageUrlUtils: ImageUrlUtils,
    private configService: ConfigService
  ) {}
}
