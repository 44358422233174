import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';

export enum DamperControlType {
  None = 'None',
  Plain = 'Plain',
  Mechanical = 'Mechanical',
  Circle = 'Circle',
  Handle = 'Handle',
  PushPull = 'PushPull',
  Simple = 'Simple',
}

export class DamperInfo extends ForgeContentDataElement {
  category = '';
  adjust = 0;
  inset = 0;
  holeAngle = 0;
  handleExtension = 0;
  gauge = 0;
  controlType: DamperControlType = DamperControlType.None;
  isMachineCut = false;
  isDisplayed = false;
}
