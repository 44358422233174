import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { NotificationService } from '@services/notification.service';
import {
  NotificationType,
  Notification,
  NotificationPlace,
} from '@models/notification/notification';
import { ApiError } from '@models/errors/api-error';
import { DataElementType } from '@constants/data-element-types';
import { Router, NavigationExtras } from '@angular/router';
import { ContentFile } from '@models/fabrication/files';
import { groupBy, unionBy } from 'lodash';
import { selectContentFileById } from '@store/selectors/content-file.selectors';
import { selectThumbnailFileById } from '@store/selectors/thumbnail-file.selectors';
import { SubmitActivity } from '@store/actions/activity.action';
import { ActivitySubmission } from '@models/activities-events/activities';
import { v4 as uuidv4 } from 'uuid';
import { ActivitySubmissionEntry } from '@models/activities-events/activities';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { RouterExtraInfoService } from '@services/router-extra-info.service';
import { NavigationConstants } from '@constants/navigation-constants';
import { CacheService } from '@services/cache.service';
import { ReferencedData } from '@models/forge-content/references';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { Operation } from '@constants/operations-types';
import { simultaneousUpdateErrorStatus } from './forge-content.service';

@Injectable()
export class DataElementEffectService {
  constructor(
    private translate: TranslateService,
    private notificationService: NotificationService,
    private router: Router,
    private routerExtraInfoService: RouterExtraInfoService,
    private cacheService: CacheService,
    private store$: Store<FDMState>
  ) {}

  public postSuccessNotification(
    operation: Operation,
    dataType: DataElementType,
    n = 1 // length elements deleted
  ): void {
    const dataTypeMessageStr = DataElementTypeUtils.getStartCase(this.translate, dataType, n > 1);
    const completeOperation = this.getSuccessTranslationForOperationType(operation);
    const notification = new Notification();
    notification.autoDismiss = true;
    notification.type = NotificationType.Success;
    notification.messages = [
      this.translate.instant(LC.NOTIFICATIONS.MSG_DATA_ELEMENT_SUCCESS, {
        dataTypeMessageStr,
        completeOperation,
      }),
    ];
    this.notificationService.postNotification(notification);
  }

  public postFailureNotification(
    operation: Operation,
    dataType: DataElementType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    apiError?: ApiError // not used currently but could be used to display status code, tracking error id etc
  ): void {
    // 412 is a special case where the data element is edited by multiple users.
    // Should not show notification since the system handles this case separately.
    if (apiError?.status === simultaneousUpdateErrorStatus) {
      return;
    }

    const failedOperation = this.getFailureTranslationForOperationType(operation);
    const notification = new Notification();
    notification.type = NotificationType.Error;
    notification.place = NotificationPlace.Banner;
    notification.messages = [
      this.translate.instant(LC.NOTIFICATIONS.MSG_DATA_ELEMENT_POST_FAILURE, {
        failedOperation,
        dataType: DataElementTypeUtils.getStartCase(this.translate, dataType, false),
      }),
    ];
    this.notificationService.postNotification(notification);
  }

  public postUnableToDeleteNotification(
    dataType: DataElementType,
    references: ReferencedData[],
    n = 1
  ): void {
    const notification = new Notification();
    notification.type = NotificationType.Info;
    notification.place = NotificationPlace.Banner;

    // eslint-disable-next-line no-array-constructor
    const messages: string[] = [];
    const dataTypeMessageStr = DataElementTypeUtils.getStartCase(this.translate, dataType, n > 1);

    messages.push(
      this.translate.instant(LC.NOTIFICATIONS.MSG_DATA_ELEMENT_DELETE_RESTRICTION, {
        text:
          n > 1
            ? n
            : this.translate.instant(LC.NOTIFICATIONS.MSG_DATA_ELEMENT_DELETE_RESTRICTION_SINGULAR),
        dataTypeMessageStr,
      })
    );

    const filteredReferences = unionBy(references, 'name');

    const grouped = groupBy(filteredReferences, (reference: ReferencedData) => reference.dataType);
    Object.keys(grouped).forEach((key) => {
      messages.push('');

      let dataTypeMessage = `• ${key.toString()}: `;

      const values = grouped[key];
      values.forEach((value) => {
        dataTypeMessage += `${value.name}, `;
      });

      dataTypeMessage = dataTypeMessage.substring(0, dataTypeMessage.length - 2);

      messages.push(dataTypeMessage);
    });

    notification.messages = messages;

    this.notificationService.postNotification(notification);
  }

  public navigateBackToTable() {
    const currentUrlFragments: string[] = this.router.url.split('?')[0].split('/');
    let nextUrlFragments = currentUrlFragments;
    const numberOfFragments: number = currentUrlFragments.length;
    let redirectUrl = '';
    const navigationExtras: NavigationExtras = {
      queryParams: {
        refresh: true,
      },
    };

    if (currentUrlFragments[numberOfFragments - 1] === 'create') {
      nextUrlFragments = currentUrlFragments.slice(0, -1);
    }

    if (
      currentUrlFragments[numberOfFragments - 1] === 'copy' ||
      currentUrlFragments[numberOfFragments - 1] === 'edit'
    ) {
      nextUrlFragments = currentUrlFragments.slice(0, -2);
    }

    redirectUrl = `/${nextUrlFragments.join('/')}`;
    console.log(`Redirecting to ${redirectUrl}`);

    this.router.navigate([redirectUrl], navigationExtras);
  }

  public navigateBackToPreviousMasterPage(successData, action) {
    const previousMasterRouteUrl = this.routerExtraInfoService.getPreviousMasterRouteUrl();
    const cleanedPreviousRoute = previousMasterRouteUrl.split('?')[0];

    if (previousMasterRouteUrl?.includes(NavigationConstants.NAV_GRAPH_ENDPOINT)) {
      this.router.navigate([cleanedPreviousRoute], {
        queryParams: { reuse: true },
        state: {
          updatedDataElementId: successData.id,
          updatedDataType: action.dataElementType,
        },
        relativeTo: null,
      });
    } else if (previousMasterRouteUrl) {
      this.router.navigateByUrl(previousMasterRouteUrl);
    } else {
      this.navigateBackToTable();
    }
  }

  public contentFilesRequired(
    dataElement: ForgeContentDataElement
  ): Observable<[ContentFile[], ContentFile[]]> {
    const getFiles: Observable<ContentFile[]> =
      (dataElement.files &&
        dataElement.files.length &&
        combineLatest(
          dataElement.files.map((x) => this.store$.select(selectContentFileById(x)))
        )) ||
      of(null);

    const getThumbnails: Observable<ContentFile[]> =
      (dataElement.thumbnails &&
        dataElement.thumbnails.length &&
        combineLatest(
          dataElement.thumbnails.map((x) => this.store$.select(selectThumbnailFileById(x)))
        )) ||
      of(null);

    return combineLatest([getFiles, getThumbnails]).pipe(take(1));
  }

  public createActivitySubmissionAction(
    configUrn: string,
    dataType: DataElementType,
    entries: ActivitySubmissionEntry[],
    nodeId = '',
    disableNotification = false
  ): SubmitActivity {
    // always submit the activity, if the cache is not supported i.e. running local dev as ng serve
    // then we still need to submit the activity so cached clients can get the updates
    const activitySubmissionId = this.cacheService.currentCacheIdentityRecord
      ? this.cacheService.currentCacheIdentityRecord.activitySubmissionId
      : uuidv4();
    return new SubmitActivity({
      submission: {
        id: uuidv4(),
        configUrn,
        dataType,
        activitySubmissionId,
        entries,
        nodeId,
        disableNotification,
      } as ActivitySubmission,
    });
  }

  private getSuccessTranslationForOperationType(operation: Operation) {
    const operationTranslations = {
      [Operation.ADD]: LC.NOTIFICATIONS.COMPLETE_OPERATIONS.ADDED,
      [Operation.COPY]: LC.NOTIFICATIONS.COMPLETE_OPERATIONS.COPIED,
      [Operation.DELETE]: LC.NOTIFICATIONS.COMPLETE_OPERATIONS.DELETED,
      [Operation.FIX]: LC.NOTIFICATIONS.COMPLETE_OPERATIONS.FIXED,
      [Operation.UPDATE]: LC.NOTIFICATIONS.COMPLETE_OPERATIONS.UPDATED,
      [Operation.UPGRADE]: LC.NOTIFICATIONS.COMPLETE_OPERATIONS.UPGRADED,
    };

    return this.translate.instant(operationTranslations[operation]);
  }

  private getFailureTranslationForOperationType(operation: Operation) {
    const operationTranslations = {
      [Operation.ADD]: LC.NOTIFICATIONS.FAILED_OPERATIONS.ADDING,
      [Operation.COPY]: LC.NOTIFICATIONS.FAILED_OPERATIONS.COPYING,
      [Operation.DELETE]: LC.NOTIFICATIONS.FAILED_OPERATIONS.DELETING,
      [Operation.FIX]: LC.NOTIFICATIONS.FAILED_OPERATIONS.FIXING,
      [Operation.UPDATE]: LC.NOTIFICATIONS.FAILED_OPERATIONS.UPDATING,
      [Operation.UPGRADE]: LC.NOTIFICATIONS.FAILED_OPERATIONS.UPGRADING,
    };

    return this.translate.instant(operationTranslations[operation]);
  }
}
