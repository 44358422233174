import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { StorageFile } from './files';

export class Config extends ForgeContentDataElement {
  image = '';
  imageObjectKey = '';
  name = '';
  collectionId = '';
  folders: string[] = [];
  systems: string[] = [];
  partTemplates: string[] = [];
  specificationInfos: string[] = [];
  insulationSpecificationInfos: string[] = [];
  unitSystem: ConfigUnitSystem;
  guid = '';
  longDescription = '';
  systemInfos: string[] = [];
  connectorInfos: string[] = [];
  damperInfos: string[] = [];
  stiffenerInfos: string[] = [];
  databaseFiles: string[] = [];
  fabricationRates: string[] = [];
  installationRates: string[] = [];
  materials: string[] = [];
  materialSpecs: string[] = [];
  invalidData: string[] = [];
  graphs: string[] = [];
  stiffenerSpecificationInfos: string[] = [];
  thumbnailStorageFile: StorageFile;
  imagesFolderPath: string;
  itemsFolderPath: string;
  notificationChannelId?: string;
}

export enum ConfigUnitSystem {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export interface ConfigResponse {
  libraries: {
    collectionId: string;
    id: string;
    name: string;
    description: string;
    longDescription: string;
    owner: string;
    externalId: string;
    createTime: string;
    lastModifiedTime: string;
    unitType: string;
    imageUrl: string;
    imageObjectKey: string;
    configGuid: string;
    status: string;
    notificationId?: string;
    role: string;
    imagesFolderPath: string;
    itemsFolderPath: string;
    hasErrors: boolean;
  }[];
}
