import { Component, OnInit, OnDestroy } from '@angular/core';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import {
  DynamicTableOptions,
  ColumnDefinition,
  InlineDataTableOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter, map } from 'rxjs/operators';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormSelectType,
} from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditError, InlineEditType } from '@models/inline-edit/inline-edit.options';
import { MaterialSpecificationSize } from '@models/fabrication/material-spec-sizes';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import {
  createSpecificationTableEntryFactory,
  SpecificationTable,
  SpecificationTableEntryType,
  SpecificationTableType,
} from '@models/fabrication/specification-info';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import {
  ConnectorDomainType,
  ConnectorInfo,
  ConnectorShapeType,
} from '@models/fabrication/connector-info';
import { selectCurrentConfigConnectorInfos } from '@store/selectors/configs.selectors';
import { SchemaService } from '@services/schema.service';
import { ValidatorService, InlineValidatorFunction } from '@services/validator.service';
import { v4 as uuidv4 } from 'uuid';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { JSONSchema7 } from 'json-schema';
import { DataElementType } from '@constants/data-element-types';
import { EnvironmentConstants } from '@constants/environment-constants';

@Component({
  selector: 'fab-part-spec-table',
  template: ` <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data> `,
})
export class PartSpecificationTableComponent
  extends DynamicFormBaseCustomComponent<SpecificationTableEntryType[]>
  implements OnInit, OnDestroy
{
  tableOptions: DynamicTableOptions<SpecificationTableEntryType> = null;
  tableDataSource: BehaviorSubject<SpecificationTableEntryType[]>;
  tableChangeSubject: Subject<SpecificationTableEntryType[]> = new Subject<
    SpecificationTableEntryType[]
  >();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  idLookup = elementIdDictionary.connectorEdit;
  rows: SpecificationTableEntryType[];
  customModelChangesSubscription: Subscription;
  currentRow: MaterialSpecificationSize = null;
  connectors: ConnectorInfo[] = null;
  connectorDynamicSelectType: DynamicFormSelectType[] = [];

  constructor(
    private translate: TranslateService,
    store$: Store<FDMState>,
    private schemaService: SchemaService,
    private validatorService: ValidatorService
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.tableErrorReporterSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getModelUpdates() {
    const getModelChanges$: Observable<SpecificationTable> = this.formModelUpdater.pipe(
      filter(
        (changeEvent: DynamicFormCustomUpdateEvent) =>
          changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
      ),
      map((changeEvent: DynamicFormCustomUpdateEvent) => changeEvent.data as SpecificationTable)
    );

    this.customModelChangesSubscription = combineLatest([
      getModelChanges$,
      this.store$.select(selectCurrentConfigConnectorInfos(true)).pipe(
        map((connectors: ConnectorInfo[]) =>
          // filter only supported connector domains
          connectors.filter(
            (x) => x.shape === ConnectorShapeType.Round && x.domain === ConnectorDomainType.Pipework
          )
        )
      ),
    ]).subscribe((setupData: [SpecificationTable, ConnectorInfo[]]) => {
      const [model, connectors] = setupData;

      this.rows = model.entries;
      this.connectors = connectors;
      this.connectorDynamicSelectType = this.getConnectorSelectFields();
      this.createTableDataOptions();
      this.tableDataSource.next(this.rows);
      this.reportErrors();
    });
  }

  createTableDataOptions() {
    if (!this.tableOptions) {
      this.tableDataSource = new BehaviorSubject<SpecificationTableEntryType[]>(this.rows);

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'part-spec-table',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        maintainSelectionOnUpdate: true,
        columns: this.getColumnDefinitions(),
        subscribeToTableChanges: this.tableChangeSubject,
        tableTypeOptions: {
          newRowData: () => {
            const spec = this.model as SpecificationTable;
            return createSpecificationTableEntryFactory(
              SpecificationTableType.SingleDimension,
              spec.shape,
              spec.domain
            );
          },
          disableDeleteCondition: 'selectedAll',
        } as InlineDataTableOptions<SpecificationTableEntryType>,
        errorReporter: this.tableErrorReporterSubject,
        forceFullValidationOnEachChange: true,
        toolbarOptions: {},
        enableMultiSelect: true,
      };
    }
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: SpecificationTableEntryType[]) => {
        this.rows = rows;
        this.updateSource(this.rows);
      }
    );
  }

  getConnectorSelectFields = (): DynamicFormSelectType[] => {
    const settings: DynamicFormSelectType[] = [
      {
        value: EnvironmentConstants.FCS_NONE_CONNECTOR,
        label: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.NONE),
      },
      {
        value: EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR,
        label: this.translate.instant(LC.GRAPH.UNASSIGNED),
      },
    ];

    return settings.concat(
      this.connectors.map((x) => ({
        label: x.name,
        group: x.category,
        value: x.externalId,
      }))
    );
  };

  getValidator(field?: string): InlineValidatorFunction {
    const validators: InlineValidatorFunction[] = [];
    const schema = this.getSchema();
    const schemaValidator = this.validatorService.getInlineEditSchemaValidator(schema.$id);
    validators.push(schemaValidator);

    // only attach validators to required fields
    if (field === 'size') {
      const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
        this.rows.map((x) => x.size),
        'size'
      );
      validators.push(uniqueValidator);
    }

    return this.validatorService.mergeInlineEditValidators(...validators);
  }

  private getSchema(): JSONSchema7 {
    // todo: add checks for tableType when other types are supported
    // currently only supports pipework specs with single table entries
    const parentSchema = this.schemaService.getSchemaByDataElementType(
      DataElementType.Specification
    );
    const tableSchema = parentSchema.definitions[
      EnvironmentConstants.FSS_SUB_SCHEMA_PART_SPEC_TABLE
    ] as JSONSchema7;
    const schema = tableSchema.definitions[
      EnvironmentConstants.FSS_SUB_SCHEMA_PART_SPEC_PIPEWORK_TABLE_ENTRY
    ] as JSONSchema7;

    return schema;
  }

  private getColumnDefinitions(): ColumnDefinition[] {
    const connectorSelectFields = this.getConnectorSelectFields();
    const columns: ColumnDefinition[] = [
      {
        field: 'size',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.MAX_SIZE),
        units: ForgeUnitsService.getStandardLengthUnits(this.configUnitSystem),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.units,
          validator: () => this.getValidator('size'),
        },
      },
      {
        field: 'primaryConnector',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SPECIFICATIONS.PRIMARY_CONNECTOR),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.select,
          selectFieldOptions: () => connectorSelectFields,
          validator: () => this.getValidator(),
        },
      },
      {
        field: 'secondaryConnector',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SPECIFICATIONS.SECONDARY_CONNECTOR),
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: InlineEditType.select,
          selectFieldOptions: () => connectorSelectFields,
          validator: () => this.getValidator(),
        },
      },
    ];

    return columns;
  }
}
