import { Injectable } from '@angular/core';

import {
  UnitSchemaType,
  QuantitySchemaType,
  SymbolSchemaType,
} from '@models/forge-units/forge-unit-schema-types';
import { Observable, of, from as observableFrom } from 'rxjs';
import { AppDependency } from '@models/app-dependencies/app-dependencies';
import ForgeUnits, { Placement, UnitsEngine } from '@adsk/forge-units';
import { LocalisationConstants as LC } from '@constants/localisation-constants';

import {
  // dimensions
  LengthDimensionSchema,
  MassDimensionSchema,
  TimeDimensionSchema,

  // factors
  CentiFactorSchema,
  DeciFactorSchema,
  HectoFactorSchema,
  KiloFactorSchema,
  MicroFactorSchema,
  MilliFactorSchema,
  NanoFactorSchema,
  PiFactorSchema,
  TenFactorSchema,
  ThreeHundredSixtyFactorSchema,
  ThreeThousandNineHundredThirtySevenFactorSchema,

  // quantities
  AngleQuantitySchema,
  LengthQuantitySchema,
  MassQuantitySchema,

  // symbols
  DegreeSymbolSchema,
  FeetAndInchesSymbolSchema,
  InchDoubleQuoteSymbolSchema,
  MillimeterSymbolSchema,
  FootSingleQuoteSymbolSchema,
  CentimetersSymbolSchema,
  MeterSymbolSchema,
  MetersAndCentimetersSymbolSchema,
  KilogramsSymbolSchema,
  PoundsSymbolSchema,

  // units
  CentimetersUnitSchema,
  DecimetersUnitSchema,
  DegreesUnitSchema,
  FeetUnitSchema,
  GradiansUnitSchema,
  HectometersUnitSchema,
  InchesUnitSchema,
  KilometersUnitSchema,
  MetersUnitSchema,
  MicronsUnitSchema,
  MilesUnitSchema,
  MillimetersUnitSchema,
  MilsUnitSchema,
  NauticalMilesUnitSchema,
  RadiansUnitSchema,
  TurnsUnitSchema,
  UsSurveyFeetUnitSchema,
  YardsUnitSchema,
  KilogramsUnitSchema,
  PoundsUnitSchema,
  GrainsUnitSchema,
  GramsUnitSchema,
  MicrogramsUnitSchema,
  MilligramsUnitSchema,
  NanogramsUnitSchema,
  OuncesUnitSchema,
  SlugsUnitSchema,
  TonnesUnitSchema,
  UsTonnesUnitSchema,
  PoundsForceUnitSchema,
  StandardGravityUnitSchema,
  MetersPerSecondSquaredUnitSchema,
  MetersPerSecondUnitSchema,
  SecondsUnitSchema,
} from '@data-management/forge-units-schemas';
import { ConfigUnitSystem } from '@models/fabrication/config';
import { catchError, map } from 'rxjs/operators';
import { ErrorUtils } from '@utils/error-utils';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentConstants } from '@constants/environment-constants';

export interface UnitSymbolData {
  text: string; // the symbol text, e.g. mm or "
  placement: Placement; // indicates if the symbol appears before or after the text, e.g. £3 or 55 mm.
  space: boolean; // indicates whether or not there should be a space between the value and the symbol, e.g. 5 mm or 2"
}

export interface UnitTypeData {
  type: UnitSchemaType;
}

// the forge units api can be seen here:
// https://git.autodesk.com/revit/ForgeUnits/blob/master/lang/cpp/include/ForgeUnits/unitsEngine.h
@Injectable({
  providedIn: 'root',
})
export class ForgeUnitsService implements AppDependency {
  private unitsEngine: UnitsEngine;
  isInitialised = false;
  initialisationInProgress = false;

  constructor(private loggingService: LoggingService, private translate: TranslateService) {}

  public initialise(): Observable<boolean> {
    if (this.isInitialised || this.initialisationInProgress) {
      return of(true);
    }

    this.initialisationInProgress = true;

    return observableFrom(ForgeUnits()).pipe(
      map((forgeUnitsLib) => {
        this.unitsEngine = forgeUnitsLib.CreateUnitsEngine();
        this.loadSchemas();
        this.unitsEngine.resolveSchemas();
        this.isInitialised = true;

        return true;
      }),
      catchError((error) => {
        this.loggingService.logError(
          error,
          false,
          this.translate.instant(LC.NOTIFICATIONS.ERROR_LOADING_FORGE_UNITS),
          ErrorUtils.getAdditionalStack()
        );

        return of(false);
      })
    );
  }

  // Convert a value from one unit to another.
  // E.g. convert 12 centipoises to pounds mass per foot second with:
  // auto converted = convert(
  //    12,
  //    "autodesk.unit.unit:centipoises-1.0.0",
  //    "autodesk.unit.unit:poundsMassPerFootSecond-1.0.0");
  //
  // The original and target units must be derived from either identical or reciprocal powers of primitive units.
  // Otherwise, an IncompatibleUnitsException is thrown.
  //
  // If either the original or target unit is absolute and the other is not, an IncompatibleUnitsException is
  // thrown.
  //
  // Params:
  // - value: Measurement of the value in its original unit.
  // - fromUnit: UnitSchemaType representing the original unit.
  // - toUnit: UnitSchemaType representing the target unit.
  // Returns: Measurement of the value in its target unit.
  // Throws: IncompatibleUnitsException, UnrecognizedIdentifierException
  // public convert(value: number, fromUnit: UnitSchemaType, toUnit: UnitSchemaType): number {
  //   if (!this.isInitialised) {
  //     return value;
  //   }

  //   return this.unitsEngine.convert(
  //     value,
  //     this.getUnitSchemaTypeId(fromUnit),
  //     this.getUnitSchemaTypeId(toUnit)
  //   );
  // }

  // Parses a numerical value measured in a given unit from a string containing an arithmetic expression. The
  // expression may contain numbers in fixed-point decimal format, unit symbols, and the following operators:
  // Addition: +
  // Subtraction: -
  // Multiplication: *
  // Division: /
  // Exponentiation: ^
  // Parentheses: ( )
  // Logarithm: log(x)
  // Natural logarithm: ln(x)
  // Square root: sqrt(x)
  // Sine: sin(x)
  // Cosine: cos(x)
  // Tangent: tan(x)
  // Arcsine: asin(x)
  // Arccosine: acos(x)
  // Arctangent: atan(x)
  // e raised to the given power: exp(x)
  // Absolute value: abs(x)
  // Pi: pi()
  // Round to nearest whole number: round(x)
  // Round up: roundup(x)
  // Round down: rounddown(x)
  //
  // The sin, cos, and tan operators interpret their inputs as radians. The asin, acos, and atan operators produce
  // output in radians. The ^, log, ln, sin, cos, tan, asin, acos, atan, and exp operators only accept unitless
  // expressions as input.
  //
  // This method throws a ParseException if the string cannot be parsed. A parse may fail if the expression
  // contains symbols for units which cannot be simplified to the target unit's dimension. For example, the
  // following call will throw a ParseException because the expression has a dimension of length to the second
  // power, while the target unit cubicFeet has a dimension of length to the third power.
  // double throws = units.parse("cubicFeet", "5ft*3m"); // Throws a ParseException.
  // However, the following call will succeed:
  // double squareFeet = units.parse("squareFeet", "5ft*3m"); // squareFeet is 49.213...
  //
  // Params:
  // - targetUnit: UnitSchemaType representing the target unit.
  // - expression: A string containing the expression to parse.
  // Returns: The parsed value in units indicated by the targetUnit parameter.
  // Throws: ParseException, UnrecognizedIdentifierException
  public parse(targetUnit: UnitSchemaType, expression: string): number {
    const defaultPrecision = EnvironmentConstants.MAX_DECIMAL_PLACES_FOR_EDITING;
    if (!this.isInitialised) {
      console.log('not initialised');
      return 0;
    }

    const value = this.unitsEngine.parse(this.getUnitSchemaTypeId(targetUnit), expression);

    return Number(this.stringifyValue(value, null, defaultPrecision));
  }

  // Create a string representation of a value in fixed-point decimal format, optionally labeled with a unit
  // symbol. The precision specifier indicates the number of digits to render after the decimal point. The maximum
  // precision is 12 decimal places. For example, to round a value of 3.75 to tenths of a unit (i.e. to 3.8),
  // specify a precision of 1.
  //
  // Params:
  // - value: The numerical value to render as a string.
  // - unitType: UnitType with which to label the value, or nullptr to omit the unit label.
  // Returns: String representation of the value in fixed-point decimal format.
  // Throws: PrecisionException, UnrecognizedIdentifierException
  public stringifyValue(value: number, unitType: UnitSchemaType, precision?: number): string {
    if (!this.isInitialised) {
      return value.toString();
    }

    return this.unitsEngine.stringifyFixedPoint(
      value,
      precision ?? 6,
      unitType ? this.getSymbolSchemaTypeId(this.getSymbolTypeFromUnit(unitType)) : null,
      true
    );
  }

  /**
   * Gets the symbol text for the unit, e.g. mm
   * @param unitType {UnitSchemaType} The unit type
   */
  public getUnitSymbolData(unitType: UnitSchemaType): UnitSymbolData {
    if (!this.isInitialised) {
      return null;
    }

    const symbolTypeId = this.getSymbolSchemaTypeId(this.getSymbolTypeFromUnit(unitType));
    const symbol = this.unitsEngine.getSymbol(symbolTypeId);
    const data = symbol.prefixOrSuffix;

    return {
      text: data.text,
      placement: data.placement,
      space: data.space,
    } as UnitSymbolData;
  }

  // public getConvertibleUnits(units: UnitSchemaType): UnitSchemaType[] {
  //   if (!this.isInitialised) {
  //     return null;
  //   }

  //   // getConvertibleUnits returns a dictionary
  //   const convertible = this.unitsEngine.getConvertibleUnits(
  //     this.getUnitSchemaTypeId(units)
  //   ) as any[];
  //   return Object.keys(convertible)
  //     .map((typeId: string) => this.getUnitSchemaTypeFromId(typeId))
  //     .filter((x) => x !== UnitSchemaType.None);
  // }

  public getUnitSchemaTypeId(unitSchema: UnitSchemaType): string {
    switch (unitSchema) {
      // lengths
      case UnitSchemaType.Millimeters:
        return MillimetersUnitSchema.typeid;
      case UnitSchemaType.Inches:
        return InchesUnitSchema.typeid;
      case UnitSchemaType.Feet:
        return FeetUnitSchema.typeid;
      case UnitSchemaType.Centimeters:
        return CentimetersUnitSchema.typeid;
      case UnitSchemaType.Meters:
        return MetersUnitSchema.typeid;

      // angles
      case UnitSchemaType.Degrees:
        return DegreesUnitSchema.typeid;

      default:
      case UnitSchemaType.None:
        // console.log('could not find typeid for: ' + unitSchema);
        return '';
    }
  }

  public getUnitSchemaTypeFromId(typeId: string): UnitSchemaType {
    switch (typeId) {
      // lengths
      case MillimetersUnitSchema.typeid:
        return UnitSchemaType.Millimeters;
      case InchesUnitSchema.typeid:
        return UnitSchemaType.Inches;
      case FeetUnitSchema.typeid:
        return UnitSchemaType.Feet;
      case CentimetersUnitSchema.typeid:
        return UnitSchemaType.Centimeters;
      case MetersUnitSchema.typeid:
        return UnitSchemaType.Meters;

      // angles
      case DegreesUnitSchema.typeid:
        return UnitSchemaType.Degrees;

      default:
        // console.log('could not find type for: ' + typeId);
        return UnitSchemaType.None;
    }
  }

  public getSymbolSchemaTypeId(symbolSchema: SymbolSchemaType): string {
    switch (symbolSchema) {
      // lengths
      case SymbolSchemaType.Millimeters:
        return MillimeterSymbolSchema.typeid;
      case SymbolSchemaType.Centimeters:
        return CentimetersSymbolSchema.typeid;
      case SymbolSchemaType.Meters:
        return MeterSymbolSchema.typeid;
      case SymbolSchemaType.MetersAndCentimeters:
        return MetersAndCentimetersSymbolSchema.typeid;

      case SymbolSchemaType.Inches:
        return InchDoubleQuoteSymbolSchema.typeid;
      //case SymbolSchemaType.FeetAndInches:
      //return FeetAndInchesSymbolSchema.typeid;
      case SymbolSchemaType.FootSingleQuote:
        return FootSingleQuoteSymbolSchema.typeid;

      // angles
      case SymbolSchemaType.Degrees:
        return DegreeSymbolSchema.typeid;

      // mass
      case SymbolSchemaType.Kilograms:
        return KilogramsSymbolSchema.typeid;
      case SymbolSchemaType.Pounds:
        return PoundsSymbolSchema.typeid;

      default:
      case SymbolSchemaType.None:
        console.log('could not symbol type id for: ' + symbolSchema);
        return '';
    }
  }

  public getSymbolTypeFromUnit(unitType: UnitSchemaType): SymbolSchemaType {
    switch (unitType) {
      case UnitSchemaType.Millimeters:
        return SymbolSchemaType.Millimeters;
      case UnitSchemaType.Centimeters:
        return SymbolSchemaType.Centimeters;
      case UnitSchemaType.Inches:
        return SymbolSchemaType.Inches;
      case UnitSchemaType.Feet:
        return SymbolSchemaType.FootSingleQuote;
      case UnitSchemaType.Meters:
        return SymbolSchemaType.Meters;

      case UnitSchemaType.Degrees:
        return SymbolSchemaType.Degrees;

      case UnitSchemaType.Kilograms:
        return SymbolSchemaType.Kilograms;
      case UnitSchemaType.Pounds:
        return SymbolSchemaType.Pounds;

      default:
      case UnitSchemaType.None:
        console.log('could not symbol type for: ' + unitType);
        return SymbolSchemaType.None;
    }
  }

  public getQuantitySchemaTypeId(qtySchema: QuantitySchemaType): string {
    switch (qtySchema) {
      case QuantitySchemaType.Length:
        return LengthQuantitySchema.typeid;

      case QuantitySchemaType.Angle:
        return AngleQuantitySchema.typeid;

      case QuantitySchemaType.Mass:
        return MassQuantitySchema.typeid;

      default:
        return '';
    }
  }

  private loadSchemas(): void {
    const schemas: any[] = [
      // dimensions
      LengthDimensionSchema,
      MassDimensionSchema,
      TimeDimensionSchema,

      // factors
      CentiFactorSchema,
      DeciFactorSchema,
      HectoFactorSchema,
      KiloFactorSchema,
      MicroFactorSchema,
      MilliFactorSchema,
      NanoFactorSchema,
      PiFactorSchema,
      TenFactorSchema,
      ThreeHundredSixtyFactorSchema,
      ThreeThousandNineHundredThirtySevenFactorSchema,

      // quantities
      LengthQuantitySchema,
      AngleQuantitySchema,
      MassQuantitySchema,

      // symbols
      DegreeSymbolSchema,
      FeetAndInchesSymbolSchema,
      InchDoubleQuoteSymbolSchema,
      MillimeterSymbolSchema,
      FootSingleQuoteSymbolSchema,
      CentimetersSymbolSchema,
      MeterSymbolSchema,
      MetersAndCentimetersSymbolSchema,
      KilogramsSymbolSchema,
      PoundsSymbolSchema,

      // units
      CentimetersUnitSchema,
      DecimetersUnitSchema,
      DegreesUnitSchema,
      FeetUnitSchema,
      GradiansUnitSchema,
      HectometersUnitSchema,
      InchesUnitSchema,
      KilometersUnitSchema,
      MetersUnitSchema,
      MicronsUnitSchema,
      MilesUnitSchema,
      MillimetersUnitSchema,
      MilsUnitSchema,
      NauticalMilesUnitSchema,
      RadiansUnitSchema,
      TurnsUnitSchema,
      UsSurveyFeetUnitSchema,
      YardsUnitSchema,
      KilogramsUnitSchema,
      PoundsUnitSchema,
      GrainsUnitSchema,
      GramsUnitSchema,
      MicrogramsUnitSchema,
      MilligramsUnitSchema,
      NanogramsUnitSchema,
      OuncesUnitSchema,
      SlugsUnitSchema,
      TonnesUnitSchema,
      UsTonnesUnitSchema,
      PoundsForceUnitSchema,
      StandardGravityUnitSchema,
      MetersPerSecondSquaredUnitSchema,
      MetersPerSecondUnitSchema,
      SecondsUnitSchema,
    ];

    schemas.forEach((schema) => {
      const json = JSON.stringify(schema);
      this.unitsEngine.registerSchema(json);
    });
  }

  static getStandardLengthUnits(configUnitSystem: ConfigUnitSystem): UnitSchemaType {
    return configUnitSystem === ConfigUnitSystem.Imperial
      ? UnitSchemaType.Inches
      : UnitSchemaType.Millimeters;
  }

  static getStandardMassUnits(configUnitSystem: ConfigUnitSystem): UnitSchemaType {
    return configUnitSystem === ConfigUnitSystem.Imperial
      ? UnitSchemaType.Pounds
      : UnitSchemaType.Kilograms;
  }
}
