import { Injectable } from '@angular/core';
import { Observable, of, from as observableFrom, combineLatest, lastValueFrom } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ResilientHttpService } from './http/resilient-http.service';
import sha1 from 'crypto-js/sha1';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Config } from '@models/fabrication/config';
import {
  ContentFile,
  ContentFileMap,
  StorageFile,
  StorageFileType,
  ThumbnailFile,
} from '@models/fabrication/files';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { NotificationType } from '@models/notification/notification';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { DataElementType } from '@constants/data-element-types';

export interface SignedUploadURLResponse {
  uploadKey: string;
  uploadExpiration: string;
  urlExpiration: string;
  urls: [string];
  bucketKey: string;
  objectKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class UploadThumbnailService {
  constructor(
    private envService: EnvironmentService,
    private resilientHttpService: ResilientHttpService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private fileStorageService: BinaryStorageService
  ) {}

  public getFileUploadSignedURL(
    collectionId: string,
    libraryId: string
  ): Observable<SignedUploadURLResponse> {
    const url = `${this.envService.environment.fabdmTm.baseUrl}v2/fcs/get-file-upload-signed-url`;
    return this.resilientHttpService.post<SignedUploadURLResponse>(url, {
      data: { collectionId, libraryId },
    });
  }

  public finalizeFileUpload(
    bucketKey: string,
    uploadKey: string,
    objectKey: string,
    collectionId: string,
    libraryId: string
  ): Observable<any> {
    const url = `${this.envService.environment.fabdmTm.baseUrl}v2/fcs/finalize-file-upload`;
    return this.resilientHttpService.post(url, {
      data: { bucketKey, uploadKey, objectKey, collectionId, libraryId },
    });
  }

  public updateThumbnail<T extends ForgeContentDataElement>(
    needUpdate: boolean,
    config: Config,
    dataElement: T,
    files: ContentFile[]
  ): Observable<ThumbnailFile> {
    if (!needUpdate || !dataElement?.thumbnailUrl) return of(0).pipe(map(() => null));

    return of(0).pipe(
      switchMap(() => this.getFileUploadSignedURL(config.collectionId, config.id)),
      switchMap((data: any) => {
        const resp = observableFrom(fetch(dataElement.thumbnailUrl)).pipe(
          switchMap((x) => x.blob()),
          switchMap((blob) => {
            const checksum = sha1(blob).toString();
            const size = blob.size;
            const thumbnail: ThumbnailFile = {
              height: 128,
              width: 128,
              description: `${dataElement.name}.png`,
              name: `${dataElement.name}.png`,
              objectKey: data.objectKey,
              size,
              contentExternalId: dataElement.externalId,
              path: files.length ? files[0].path : '',
              checksum,
              mimeType: blob.type,
            };

            return combineLatest([
              of(thumbnail),
              this.resilientHttpService.httpClientNoAuth.put(data.urls[0], blob),
            ]);
          }),
          take(1)
        );
        return combineLatest([of(data), resp]);
      }),
      switchMap(([fileUploadSignedUrl, [thumbnail]]) =>
        this.finalizeFileUpload(
          fileUploadSignedUrl.bucketKey,
          fileUploadSignedUrl.uploadKey,
          fileUploadSignedUrl.objectKey,
          config.collectionId,
          config.id
        ).pipe(map(() => thumbnail))
      ),
      catchError((error) => {
        console.error('Fail when upload part thumbnail. Error details: ', error);
        const message = this.translateService.instant(
          LC.NOTIFICATIONS.THUMBNAIL_EDITOR.UPLOAD_FAILED
        );
        this.notificationService.showToast({ message, type: NotificationType.Error });
        return of(0).pipe(map(() => null));
      })
    );
  }

  //Uploads the given dataUrl and saves it in the binary storage with the data to finalize the upload
  public async uploadWithFinalizePending(config: Config, dataUrl: string): Promise<StorageFile> {
    try {
      const signedUrlResponse: SignedUploadURLResponse = await lastValueFrom(
        this.getFileUploadSignedURL(config.collectionId, config.id)
      );
      const file = await fetch(dataUrl);
      const blob = await file.blob();
      await this.resilientHttpService.httpClientNoAuth.put(signedUrlResponse.urls[0], blob);
      const contentFileMap: ContentFileMap = {
        contentFile: {
          name: '',
          description: '',
          objectKey: signedUrlResponse.objectKey,
          contentExternalId: signedUrlResponse.objectKey,
          path: '',
        },
        fileUrl: dataUrl,
        isImage: true,
        configId: config.id,
        finalizeUploadData: {
          uploadKey: signedUrlResponse.uploadKey,
          bucketKey: signedUrlResponse.bucketKey,
        },
      };
      await lastValueFrom(
        this.fileStorageService.addStorageFiles([contentFileMap], DataElementType.Image, [
          StorageFileType.ImageFile,
        ])
      );

      const imageFiles = await lastValueFrom(
        this.fileStorageService.getImageFiles([signedUrlResponse.objectKey])
      );
      return imageFiles[0];
    } catch (error) {
      console.error('Fail when upload thumbnail. Error details: ', error);
      const message = this.translateService.instant(
        LC.NOTIFICATIONS.THUMBNAIL_EDITOR.UPLOAD_FAILED
      );
      this.notificationService.showToast({ message, type: NotificationType.Error });
      return null;
    }
  }

  public async finalizePending(config: Config, objectKeys: string[]): Promise<boolean> {
    try {
      const imageFiles = await lastValueFrom(this.fileStorageService.getImageFiles(objectKeys));
      if (!imageFiles || !imageFiles.length) {
        return true;
      }
      const promises: Promise<any>[] = [];
      imageFiles.forEach((imageFile) => {
        if (imageFile.finalizeUploadData) {
          promises.push(
            lastValueFrom(
              this.finalizeFileUpload(
                imageFile.finalizeUploadData.bucketKey,
                imageFile.finalizeUploadData.uploadKey,
                imageFile.id,
                config.collectionId,
                config.id
              )
            )
          );
        }
      });
      await Promise.all(promises);
      return true;
    } catch (error) {
      console.error('Fail when finalize thumbnail. Error details: ', error);
      const message = this.translateService.instant(
        LC.NOTIFICATIONS.THUMBNAIL_EDITOR.UPLOAD_FAILED
      );
      this.notificationService.showToast({ message, type: NotificationType.Error });
      return false;
    }
  }

  public getThumbnailFile(
    storageFile: StorageFile,
    name: string,
    externalId: string
  ): ThumbnailFile {
    const checksum = sha1(storageFile.contents).toString();
    const size = storageFile.contents.size;
    const thumbnailFile: ThumbnailFile = {
      height: 256,
      width: 256,
      description: name,
      name,
      objectKey: storageFile.id,
      size,
      contentExternalId: externalId,
      path: '',
      checksum,
      mimeType: storageFile.contents.type || 'image/png',
    };
    return thumbnailFile;
  }
}
