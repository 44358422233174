import * as fromPart from '../actions/part.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Part } from '@models/fabrication/part';

export interface PartState extends EntityState<Part> {} // eslint-disable-line

export const partAdapter: EntityAdapter<Part> = createEntityAdapter<Part>({
  selectId: (part: Part) => part.id,
});

export const initialState: PartState = partAdapter.getInitialState();

export function reducer(state = initialState, action: fromPart.PartActions): PartState {
  switch (action.type) {
    case fromPart.PartActionTypes.LoadParts:
    case fromPart.PartActionTypes.LoadPartsFail:
    case fromPart.PartActionTypes.UpdatePart:
    case fromPart.PartActionTypes.UpdatePartFail:
    case fromPart.PartActionTypes.CopyPart:
    case fromPart.PartActionTypes.CopyPartFail:
    case fromPart.PartActionTypes.StartUpgradePart:
    case fromPart.PartActionTypes.StartUpgradePartFail:
    case fromPart.PartActionTypes.FinaliseUpgradePart:
    case fromPart.PartActionTypes.FinaliseUpgradePartFail:
    case fromPart.PartActionTypes.LoadSinglePart:
    case fromPart.PartActionTypes.DeletePartsFail:
    case fromPart.PartActionTypes.DeleteParts:
    default: {
      return state;
    }

    case fromPart.PartActionTypes.LoadPartsSuccess: {
      return partAdapter.upsertMany(action.data, state);
    }

    case fromPart.PartActionTypes.UpdatePartSuccess: {
      return partAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }

    case fromPart.PartActionTypes.CopyPartSuccess: {
      return partAdapter.addOne(action.dataElement, state);
    }

    case fromPart.PartActionTypes.AddPartSuccess: {
      return partAdapter.addOne(action.dataElement, state);
    }

    case fromPart.PartActionTypes.StartUpgradePartSuccess: {
      return partAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }

    case fromPart.PartActionTypes.FinaliseUpgradePartSuccess: {
      return partAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }

    case fromPart.PartActionTypes.RemoveAllParts: {
      return partAdapter.removeAll(state);
    }

    case fromPart.PartActionTypes.DeletePartsSuccess: {
      return partAdapter.removeMany(action.removedDataElementIds, state);
    }
  }
}

// entity selectors
export const {
  selectIds: selectPartIds,
  selectEntities: selectPartEntities,
  selectAll: selectAllParts,
  selectTotal: selectPartTotal,
} = partAdapter.getSelectors();
