import { Component, OnInit, OnDestroy } from '@angular/core';
import { SvgIconService } from '@services/svg-icon.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationLangs } from '@constants/localisation-constants';
import '@assets/i18n/i18n';
import { NavigationService } from '@services/navigation.service';
import { filter } from 'rxjs/operators';
import { NavigationData } from '@models/ui-state/navigation-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fab-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  navigationSubscription: Subscription;
  title = 'Fabrication Configuration Editor';
  isLoading = true;

  constructor(
    private svgIconService: SvgIconService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {
    translateService.addLangs(ApplicationLangs);
  }

  ngOnInit(): void {
    // is the app loading as part of a refresh on an data existing url
    if (/\/data\/(.)+$/.test(window.location.pathname)) {
      sessionStorage.setItem('refresh-path', window.location.pathname);
      sessionStorage.setItem('refresh-path-query', window.location.search);
    }
    // register hig icons for use in app
    this.svgIconService.registerSvgIcons();
    this.setLanguage();

    this.navigationSubscription = this.navigationService.currentNavigationData
      .pipe(filter((navigationData) => !!navigationData))
      .subscribe((navigationData: NavigationData) => {
        this.isLoading = navigationData.appIsLoading;
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription?.unsubscribe();
  }

  setLanguage() {
    this.translateService.setDefaultLang('en');

    //manage Chinese Simplified and Traditional
    let lng = navigator.language;
    if (lng === 'zh-CN' || lng === 'zh') {
      lng = 'zh-hans';
    } else if (lng === 'zh-TW' || lng === 'zh-HK' || lng === 'zh-SG') {
      lng = 'zh-hant';
    }

    //toLowerCase needs for languages with region like 'pt-BR'
    this.translateService.use(lng.toLowerCase());
  }
}
