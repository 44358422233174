<ng-template #contentRef>
  <ng-container *ngIf="modalData?.className !== 'parts-modal'; else partCollection">
    <div class="modal-title-container">
      <h1 mat-dialog-title>{{ modalData.title }}</h1>
      <mat-icon *ngIf="modalData?.showCloseButton" svgIcon="close" (click)="cancel()"></mat-icon>
    </div>
  </ng-container>
  <ng-template #partCollection>
    <fab-part-collection-modal-title
      [title]="modalData?.title"
      [formOptions]="modalData?.contentSetup.formSetup.options"
    ></fab-part-collection-modal-title>
  </ng-template>

  <div class="separator"></div>
  <div mat-dialog-content>
    <p class="additionalText" *ngIf="modalData.additionalText">
      {{ modalData.additionalText }}
    </p>
    <ng-container *ngIf="modalData?.contentSetup?.contentType; else noContentType">
      <ng-template #htmlContent>
        <ng-content></ng-content>
      </ng-template>
      <fab-dynamic-content-presenter
        [htmlTemplate]="htmlContent"
        [contentSetup]="modalData.contentSetup"
        (onOperationComplete)="dynamicContentOperationComplete()"
      ></fab-dynamic-content-presenter>
    </ng-container>
    <ng-template #noContentType>
      <div>No modal content type specified</div>
    </ng-template>
  </div>
  <div *ngIf="modalData.showModalButtons" mat-dialog-actions>
    <fab-button
      *ngIf="!modalData.hideModalCancelButton"
      variant="outlined"
      (onClick)="cancel()"
      [text]="modalData?.cancelLabel ? modalData?.cancelLabel : 'Cancel'"
    ></fab-button>
    <fab-button
      (onClick)="ok()"
      [text]="modalData?.okLabel ? modalData?.okLabel : 'Ok'"
      autoFocus="true"
      buttonClass="ok-button"
    ></fab-button>
  </div>
</ng-template>
