import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromRates from '../reducers/installation-rate.reducer';

// installation rate list state
export const selectInstallationRateState = (state: FDMState) =>
  state.configsState.installationRates;

// installation rate selectors
export const selectInstallationRateIds = createSelector(
  selectInstallationRateState,
  fromRates.selectInstallationRateIds
);

export const selectInstallationRateEntities = createSelector(
  selectInstallationRateState,
  fromRates.selectInstallationRateEntities
);

export const selectAllInstallationRates = createSelector(
  selectInstallationRateState,
  fromRates.selectAllInstallationRates
);

export const selectInstallationRatesTotal = createSelector(
  selectInstallationRateState,
  fromRates.selectInstallationRatesTotal
);

export const selectInstallationRateById = (id: string) =>
  createSelector(
    selectInstallationRateEntities,
    (installationRateEntities) => installationRateEntities[id]
  );
