import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { Subscription, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DataLoadingAction, DataLoadingState } from '@store/actions/base/data-loading.action';

@Injectable({
  providedIn: 'root',
})
export class DataLoadingService {
  private loadActionCount = 0;
  actionsSubscription: Subscription = null;
  dataLoadingSubject: Subject<boolean> = new Subject();

  public constructor(private actionsSubject: ActionsSubject) {
    console.log(`Data loading service ctor ${new Date()}`);
  }

  public listenToDataLoadActions() {
    this.actionsSubscription = this.actionsSubject
      .pipe(distinctUntilChanged())
      .subscribe((action) => {
        // check if action has the 'dataLoadingState' property
        if ('dataLoadingState' in action) {
          const dataLoadingAction = action as unknown as DataLoadingAction;
          // bypass ignore flag
          if (dataLoadingAction.dataLoadingState !== DataLoadingState.Ignore) {
            if (dataLoadingAction.dataLoadingState === DataLoadingState.Loading) {
              this.loadActionCount = this.loadActionCount + 1;
            } else {
              this.loadActionCount = this.loadActionCount > 0 ? this.loadActionCount - 1 : 0;
            }

            // broadcast current load status
            this.notifySubscribers(this.loadActionCount > 0);
          }
        }
      });
  }

  private notifySubscribers(dataIsLoading: boolean) {
    // broadcast current load status
    this.dataLoadingSubject.next(dataIsLoading);
  }
}
