import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import { map, switchMap, mergeMap, tap, take, catchError } from 'rxjs/operators';
import { ForgeContentService } from '@services/forge-content.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Store, Action } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { ApiError } from '@models/errors/api-error';
import { DataElementType } from '@constants/data-element-types';
import { Router } from '@angular/router';
import { ContentFile, StorageFileType } from '@models/fabrication/files';
import { ContentNode } from '@models/fabrication/content-node';
import { Config } from '@models/fabrication/config';
import { SystemInfoActionTypes } from '@store/actions/system-info.action';
import { ConnectorInfoActionTypes } from '@store/actions/connector-info.action';
import { DamperInfoActionTypes } from '@store/actions/damper-info.action';
import { StiffenerInfoActionTypes } from '@store/actions/stiffener-info.action';
import { MaterialActionTypes } from '@store/actions/material.action';
import { MaterialSpecActionTypes } from '@store/actions/material-spec.action';
import { SpecificationInfoActionTypes } from '@store/actions/specifications.action';
import { InsulationSpecificationInfoActionTypes } from '@store/actions/insulation-specs.action';
import { StiffenerSpecificationInfoActionTypes } from '@store/actions/stiffener-spec.action';
import { DBFileActionTypes } from '@store/actions/db-file.action';
import {
  LoadPartsFail,
  LoadPartsSuccess,
  LoadSinglePart,
  PartActionTypes,
} from '@store/actions/part.action';
import { ServiceTemplateInfoActionTypes } from '@store/actions/service-template-info.action';
import { FabricationRateActionTypes } from '@store/actions/fabrication-rate.action';
import { InstallationRateActionTypes } from '@store/actions/installation-rate.action';
import { InvalidDataActionTypes } from '@store/actions/invalid-data.action';
import { LoadDataElementsAction } from '@store/actions/base/data-element.action';
import {
  SetDataTypeIsLoading,
  SetDataTypeIsLoaded,
  SetDataTypeLoadingHasFailed,
} from '@store/actions/loading-data.action';
import { UpsertContentFiles } from '@store/actions/content-file.action';
import { CacheTableEntry, CacheDataTypeRecord } from '@models/cache/cache';
import { UpsertCacheTableData } from '@store/actions/cache-data.action';
import { LoadDataActivities, LoadDataActivityStartingId } from '@store/actions/activity.action';

import { NavigationConstants } from '@constants/navigation-constants';
import { Part } from '@models/fabrication/part';
import { ContentFileUtils } from '@utils/content-file-utils';
import { UpsertThumbnailFiles } from '@store/actions/thumbnail-file.action';
import { ContentItem } from '@adsk/content-sdk';
import { CacheService } from '@services/cache.service';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DeleteRealTimeActivityMarker } from '../actions/activity-real-time.action';
import { selectPartFilterById } from '../selectors/part-filter.selectors';
import { PartFilterConfig, PartSearchResponse } from '@models/forge-content/part-search';
import { PartSearchService } from '@services/part-search.service';
import { UpsertPartFilter } from '@store/actions/part-filter.action';
import { LoggingService } from '@services/logging.service';
import { LocalisationConstants as lc } from '@constants/localisation-constants';
import { LoadData, LoadDataFail, LoadDataSuccess } from '@store/actions/base/data-loading.action';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoadingDataElementEffects {
  loadDataElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemInfoActionTypes.LoadSystemInfos,
        ConnectorInfoActionTypes.LoadConnectorInfos,
        DamperInfoActionTypes.LoadDamperInfos,
        StiffenerInfoActionTypes.LoadStiffenerInfos,
        SpecificationInfoActionTypes.LoadSpecificationInfos,
        InsulationSpecificationInfoActionTypes.LoadInsulationSpecificationInfos,
        DBFileActionTypes.LoadDBFiles,
        PartActionTypes.LoadParts,
        ServiceTemplateInfoActionTypes.LoadServiceTemplateInfos,
        FabricationRateActionTypes.LoadFabricationRates,
        InstallationRateActionTypes.LoadInstallationRates,
        MaterialActionTypes.LoadMaterials,
        MaterialSpecActionTypes.LoadMaterialSpecs,
        InvalidDataActionTypes.LoadInvalidData,
        StiffenerSpecificationInfoActionTypes.LoadStiffenerSpecificationInfos
      ),
      tap((action: LoadDataElementsAction<ForgeContentDataElement>) =>
        this.store$.dispatch(
          new SetDataTypeIsLoading({
            dataType: action.dataElementType,
            configId: action.config.externalId,
          })
        )
      ),
      mergeMap((action: LoadDataElementsAction<ForgeContentDataElement>) => {
        // check the data type cache status
        // only check the cache data type record if dynamic updates are supported
        // #1 does it have a valid cache record
        // #2 does it have valid cache entries i.e. present and not expired
        // if no valid cache or expired entries the data will be load from FCS
        // if valid cache entries and not expired data is loaded from the sw cache
        // and an action is posted to check activities for the specified data type
        const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
          action.dataElementType
        );

        const validateDataTypeRecord$ = dynamicDataSetup.options.supportsDynamicUpdates
          ? this.cacheService.validateCacheDataTypeRecord(
              action.dataElementType,
              action.config.id,
              action.nodeId,
              action.isExternalNodeId,
              'content'
            )
          : of(null);

        let cacheDataTypeRecord: CacheDataTypeRecord = null;
        return validateDataTypeRecord$.pipe(
          tap((record: CacheDataTypeRecord) => (cacheDataTypeRecord = record)),
          mergeMap(() =>
            this.cacheService.validateDataTypeIsCached(
              action.dataElementType,
              action.config.id,
              action.nodeId,
              'content'
            )
          ),
          map((dataTypeCacheIsValid: boolean) => [
            dataTypeCacheIsValid,
            cacheDataTypeRecord,
            action,
          ])
        );
      }),
      mergeMap(
        (
          setupData: [boolean, CacheDataTypeRecord, LoadDataElementsAction<ForgeContentDataElement>]
        ) => {
          const [dataTypeCacheIsValid, cacheDataTypeRecord, action] = setupData;
          console.log(
            `Cache is valid: ${dataTypeCacheIsValid} for dataType: ${action.dataElementType}`
          );
          const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
            action.dataElementType
          );

          // the object to update references on config/contentNode
          const referenceObject: Config | ContentNode = action.parentNode || action.config;

          return dynamicDataSetup.options.selectors.selectAll(false).pipe(
            take(1),
            switchMap((dataElements: ForgeContentDataElement[]) => {
              return this.forgeContentService.loadAllContentFromNode<ForgeContentDataElement>(
                action.config,
                action.nodeId,
                action.isExternalNodeId,
                action.dataElementType,
                dynamicDataSetup.options.filesAreReferenced,
                action.bypassCache || !dataElements?.length
              );
            }),
            map(
              (data: ApiError | [ForgeContentDataElement[], ContentFile[], CacheTableEntry[]]) => {
                const dispatchActions = [];
                let dataElementIds = [];

                if (!('errors' in data)) {
                  const [successData, contentFileData, cacheTableEntries] = data as [
                    ForgeContentDataElement[],
                    ContentFile[],
                    CacheTableEntry[]
                  ];
                  dynamicDataSetup.dataFixes(successData);
                  dataElementIds = successData.map((x) => x.id);
                  action.successAction.data = successData;

                  // add file content data if returned
                  if (contentFileData.length) {
                    const [fileData, thumbnailData] =
                      ContentFileUtils.separateFiles(contentFileData);
                    if (fileData.length) {
                      dispatchActions.push(new UpsertContentFiles(fileData));
                    }
                    if (thumbnailData.length) {
                      dispatchActions.push(new UpsertThumbnailFiles(thumbnailData));
                    }
                  }

                  dispatchActions.push(
                    new UpsertCacheTableData({ data: cacheTableEntries }),
                    dynamicDataSetup.options.actions.updateDataReferencesAction(
                      referenceObject,
                      dataElementIds,
                      false
                    ),
                    action.successAction
                  );

                  // if valid cache entries for data type and
                  // the data type supports dynamic updates
                  // trigger activity load
                  // note - if loading activities then 'SetDataTypeIsLoaded'
                  // action will be dispatched after the 'LoadDataActivities'
                  // action is complete
                  if (dataTypeCacheIsValid && dynamicDataSetup.options.supportsDynamicUpdates) {
                    dispatchActions.push(
                      new LoadDataActivities({
                        configUrn: action.config.id,
                        dataType: action.dataElementType,
                        lastActivityProcessedId: cacheDataTypeRecord.lastActivityIdProcessed,
                        nodeId: action.nodeId,
                        isExternalNodeId: action.isExternalNodeId,
                      })
                    );
                  } else {
                    // make sure we have the initial activity record set
                    if (dynamicDataSetup.options.supportsDynamicUpdates) {
                      dispatchActions.push(
                        new LoadDataActivityStartingId({
                          configUrn: action.config.id,
                          dataType: action.dataElementType,
                          nodeId: action.nodeId,
                          isExternalNodeId: action.isExternalNodeId,
                        })
                      );

                      // make sure any activity markers possibly set prior to the full load are removed
                      dispatchActions.push(
                        new DeleteRealTimeActivityMarker({
                          configUrn: action.config.id,
                          dataType: action.dataElementType,
                          partNodeId:
                            action.dataElementType === DataElementType.Part ? action.nodeId : null,
                        })
                      );
                    }
                    dispatchActions.push(
                      new SetDataTypeIsLoaded({
                        dataType: action.dataElementType,
                        configId: action.config.externalId,
                      })
                    );
                  }
                } else {
                  const failureData = data as ApiError;
                  dispatchActions.push(
                    action.failAction,
                    new SetDataTypeLoadingHasFailed({
                      configId: action.config.externalId,
                      dataLoadingError: failureData,
                    })
                  );
                }

                return dispatchActions;
              }
            ),
            switchMap((dispatchActions) => dispatchActions)
          );
        }
      )
    )
  );

  /**
   * This effect is used primarily for when a user refreshes their browser when editing a part
   * or when a part is in focus in the relationship manager.
   */

  loadSinglePart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartActionTypes.LoadSinglePart),
      tap((action: LoadSinglePart) => {
        this.store$.dispatch(
          new SetDataTypeIsLoading({
            dataType: DataElementType.Part,
            configId: action.config.externalId,
          })
        );
      }),
      mergeMap((action: LoadSinglePart) => {
        let loadSuccess = false;

        this.store$.dispatch(new LoadData());
        const facets$ = this.store$.select(selectPartFilterById(action.payload.config.id)).pipe(
          take(1),
          switchMap((facets: PartFilterConfig) => {
            if (facets) return of([]);
            return this.partSearchService
              .partSearch(
                action.payload.config,
                '',
                0,
                { brands: [], ranges: [], materials: [], finishes: [], patternNumber: [] },
                true
              )
              .pipe(
                take(1),
                map((response: PartSearchResponse) => {
                  this.store$.dispatch(new LoadDataSuccess());
                  return [
                    new UpsertPartFilter({
                      filter: { facets: response.facets, configId: action.payload.config.id },
                    }),
                  ];
                }),
                catchError((err) => {
                  this.store$.dispatch(new LoadDataFail());
                  this.loggingService.logError(
                    err,
                    true,
                    this.translate.instant(lc.ERROR_HANDLING.PARTS.SEARCH)
                  );
                  return of([]);
                })
              );
          })
        );

        const loadPart$ = this.forgeContentService
          .loadContentById(
            action.config,
            action.dbId,
            DataElementType.Part,
            [StorageFileType.PartFile, StorageFileType.ImageFile],
            []
          )
          .pipe(
            switchMap(
              (data: ApiError | [Part, ContentFile[], ContentItem, ForgeContentDataElement[]]) => {
                const dispatchActions = [];
                const [successData, contentFileData] = data as [
                  Part,
                  ContentFile[],
                  ContentItem,
                  ForgeContentDataElement[]
                ];
                if (!('error' in data)) {
                  // add file content data if returned
                  if (contentFileData.length) {
                    const [fileData, thumbnailData] =
                      ContentFileUtils.separateFiles(contentFileData);
                    if (fileData.length) {
                      dispatchActions.push(new UpsertContentFiles(fileData));
                    }
                    if (thumbnailData.length) {
                      dispatchActions.push(new UpsertThumbnailFiles(thumbnailData));
                    }
                  }

                  const successAction = new LoadPartsSuccess();
                  successAction.data = [successData];
                  dispatchActions.push(successAction);
                  loadSuccess = true;
                } else {
                  const failureData = data as ApiError;
                  dispatchActions.push(
                    new LoadPartsFail(),
                    new SetDataTypeLoadingHasFailed({
                      configId: action.config.externalId,
                      dataLoadingError: failureData,
                    })
                  );

                  return of(dispatchActions);
                }

                return of(dispatchActions);
              }
            ),
            tap(() => {
              // if the content id doesn't exist go to page not found
              if (!loadSuccess) {
                this.router.navigate([NavigationConstants.PAGE_NOT_FOUND_LINK]);
              }
            })
          );

        return forkJoin([loadPart$, facets$]);
      }),
      switchMap((data: [Action[], Action[]]) => {
        return data[0].concat(data[1]);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private router: Router,
    private cacheService: CacheService,
    private partSearchService: PartSearchService,
    private loggingService: LoggingService,
    private translate: TranslateService
  ) {}
}
