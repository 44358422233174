import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { JSONSchema7 } from 'json-schema';
import {
  DataElementSchema,
  ForgeSchemaInfo,
  SubSchema,
} from '@models/forge-content/forge-content-schema';
import { ResilientHttpService } from './http/resilient-http.service';
import { DynamicDataService } from './dynamic-data.service';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';
import { LoggingService } from './logging.service';
import { ValidatorService } from './validator.service';
import { ErrorUtils } from '@utils/error-utils';
import { DataElementType } from '@constants/data-element-types';

@Injectable({
  providedIn: 'root',
})
export class SchemaService {
  private schemaMap: Map<string, { info: ForgeSchemaInfo; schema: JSONSchema7 }> = new Map();

  // set in the DynamicDataService constructor
  dynamicDataService: DynamicDataService = null;

  public constructor(
    private envService: EnvironmentService,
    private httpService: ResilientHttpService,
    private validationService: ValidatorService,
    private loggingService: LoggingService
  ) {}

  public downloadAllSchemas = (): Observable<boolean> => {
    const [schemas, subSchemas] = this.dynamicDataService.getAllSchemas();

    return this.downloadAndregisterSchemas(schemas, subSchemas);
  };

  public downloadSchemasForDataType = (dataType: DataElementType): Observable<boolean> => {
    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(dataType);
    if (!dynamicDataSetup) {
      return of(false);
    }

    const allSchemas: DataElementSchema[] = [];
    const allSubSchemas: SubSchema[] = [];
    const schemas = dynamicDataSetup?.options?.fcs?.schemas;
    if (schemas?.length) {
      schemas.forEach((schema) => allSchemas.push(schema));
    }

    const subSchemas = dynamicDataSetup?.options?.fcs?.subSchemas;
    if (subSchemas?.length) {
      subSchemas.forEach((schema) => allSubSchemas.push(schema));
    }

    return this.downloadAndregisterSchemas(allSchemas, allSubSchemas);
  };

  /**
   * Load JSON schema (v7) for a data element type
   *
   * @param {string} dataElementType
   * @param {ForgeSchemaInfo} fcsSchema
   * @returns {Observable<JSONSchema7>}
   * @memberof SchemaService
   */
  private loadDataElementTypeSchema(
    dataElementType: string,
    fcsSchema: ForgeSchemaInfo
  ): Observable<JSONSchema7> {
    if (!fcsSchema) {
      return of(null);
    }

    if (this.schemaMap.has(dataElementType)) {
      return of(this.schemaMap.get(dataElementType).schema);
    }

    const schemaId = `${fcsSchema.namespace}:${fcsSchema.type}-${fcsSchema.version}`;
    return this.httpService.get(`${this.envService.environment.schema.schemaUrl}${schemaId}`).pipe(
      tap(() => console.log(`Downloaded ${dataElementType} schema version:${fcsSchema.version}`)),
      map((schemaResponse: any) => {
        // get the FCS schema for the extension data (this is where the fabrication specific data is stored)
        const schema = JSON.parse(schemaResponse.schema) as JSONSchema7;
        schema.$id = schemaId;
        if (schema.$schema?.includes('https://')) {
          // ajv doesn't seem to support https in the $schema!
          schema.$schema = schema.$schema.replace('https://', 'http://');
        }

        if (!schema.properties) {
          schema.properties = {};
        }
        if (!schema.required) {
          schema.required = [];
        }

        // push the name into the schema if it does not exist
        // this is the only field that we need to validate against that is not located in the extension data
        if (!schema.properties.name) {
          schema.properties.name = {
            type: 'string',
            minLength: 1,
            maxLength: 256,
          };
          schema.required = ['name', ...schema.required];
        }

        // add to schema map
        this.schemaMap.set(dataElementType, { info: fcsSchema, schema });

        return schema;
      })
    );
  }

  private downloadAndregisterSchemas = (
    schemas: DataElementSchema[],
    subSchemas: SubSchema[]
  ): Observable<boolean> => {
    const loadSchemas$ = schemas
      .map((x) => this.loadDataElementTypeSchema(x.dataType, x.schema))
      .filter(Boolean);

    return RxjsUtils.concurrentForkJoin(loadSchemas$).pipe(
      tap((loadedSchemas: JSONSchema7[]) => {
        loadedSchemas.forEach((x: JSONSchema7) =>
          this.validationService.registerValidationSchema(x)
        );

        subSchemas.forEach((subSchemaInfo: SubSchema) => {
          const parentSchema = subSchemaInfo.parentSchema;
          const schemaId = `${parentSchema.namespace}:${parentSchema.type}-${parentSchema.version}${subSchemaInfo.id}`;
          const subSchema = this.validationService.ajv.getSchema(schemaId)?.schema as JSONSchema7;
          if (!subSchema) {
            console.log(`no sub-schema found: ${schemaId}`);
          } else {
            this.validationService.registerValidationSchema(subSchema);
          }
        });
      }),
      map(() => true),
      catchError((err) => {
        this.loggingService.logError(err, false, null, ErrorUtils.getAdditionalStack());
        return of(false);
      })
    );
  };

  /**
   * get json schema (v7) previously loaded
   *
   * @param {string} dataElementType
   * @returns {JSONSchema7}
   * @memberof SchemaService
   */
  getSchemaByDataElementType(dataElementType: string): JSONSchema7 {
    if (this.schemaMap.has(dataElementType)) {
      return this.schemaMap.get(dataElementType).schema;
    } else {
      return null;
    }
  }
}
