import { OnInit, Component, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Part } from '@models/fabrication/part';
import { DynamicFormCustomUpdateEvent } from '@models/dynamic-form/dynamic-form-properties';
import { filter, takeUntil } from 'rxjs/operators';

export interface ProductListRow {
  id: string;
  databaseId: string;
  name: string;

  [value: number]: number;
}

@Component({
  selector: 'fab-part-dimensions',
  templateUrl: './part-dimensions-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartDimensionsTableComponent
  extends DynamicFormBaseCustomComponent<Part>
  implements OnInit, OnDestroy
{
  part: Part;

  private unsubscribe$ = new Subject<void>();

  constructor(store$: Store<FDMState>) {
    super(store$);
  }

  ngOnInit(): void {
    this.getModelUpdates();
  }

  getModelUpdates() {
    this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((changeEvent: DynamicFormCustomUpdateEvent) => {
        this.part = changeEvent.data;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    super.ngOnDestroy();
  }

  updateWeaveSource(updatedSource: Part) {
    this.updateSource(updatedSource);
  }
}
