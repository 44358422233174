import { Action } from '@ngrx/store';
import { ContentFile } from '@models/fabrication/files';

export enum ContentFileActionTypes {
  UpsertContentFiles = '[ContentFile] Upsert ContentFiles',
}

export class UpsertContentFiles implements Action {
  readonly type = ContentFileActionTypes.UpsertContentFiles;

  constructor(public payload: ContentFile[]) {}
}

export type ContentFileActions = UpsertContentFiles;
