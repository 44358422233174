<fab-text-field
  [value]="currentValue"
  (onChange)="onInputChange($event)"
  variant="standard"
  [helperText]="errorText"
  class="formly-text-field"
  [disabled]="disabled"
  [ariaLabel]="field.props.label"
  [label]="props.label"
  [required]="props.required"
  autoComplete="off"
  [fullWidth]="true"
  [error]="!!errorText"
></fab-text-field>
