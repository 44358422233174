import { MaterialSpecification } from '@models/fabrication/material-spec';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsSuccessAction,
  DeleteDataElementsFailAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';
import { JSONSchema7 } from 'json-schema';

const dataType = DataElementType.MaterialSpecification;

export enum MaterialSpecActionTypes {
  LoadMaterialSpecs = '[MaterialSpec API] Load MaterialSpecs',
  LoadMaterialSpecsFail = '[MaterialSpec API] Load MaterialSpec Fail',
  LoadMaterialSpecsSuccess = '[MaterialSpec API] Load MaterialSpeca Success',
  UpdateMaterialSpec = '[MaterialSpec API] Update MaterialSpec',
  UpdateMaterialSpecFail = '[MaterialSpec API] Update MaterialSpec Fail',
  UpdateMaterialSpecSuccess = '[MaterialSpec API] Update MaterialSpec Success',
  AddMaterialSpec = '[MaterialSpec API] Add MaterialSpec',
  AddMaterialSpecFail = '[MaterialSpec API] Add MaterialSpec Fail',
  AddMaterialSpecSuccess = '[MaterialSpec API] Add MaterialSpec Success',
  DeleteMaterialSpecs = '[MaterialSpec API] Delete MaterialSpecs',
  DeleteMaterialSpecsFail = '[MaterialSpec API] Delete MaterialSpecs Fail',
  DeleteMaterialSpecsSuccess = '[MaterialSpec API] Delete MaterialSpecs Success',
  CopyMaterialSpec = '[MaterialSpec API] Copy MaterialSpec',
  CopyMaterialSpecFail = '[MaterialSpec API] Copy MaterialSpec Fail',
  CopyMaterialSpecSuccess = '[MaterialSpec API] Copy MaterialSpec Success',
}

export class LoadMaterialSpecs implements LoadDataElementsAction<MaterialSpecification> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadMaterialSpecsSuccess();
  failAction = new LoadMaterialSpecsFail();
  readonly type = MaterialSpecActionTypes.LoadMaterialSpecs;
  dataElementType = DataElementType.MaterialSpecification;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadMaterialSpecsFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialSpecActionTypes.LoadMaterialSpecsFail;
}

export class LoadMaterialSpecsSuccess
  implements LoadDataElementsSuccessAction<MaterialSpecification>
{
  dataLoadingState = DataLoadingState.Complete;
  data: MaterialSpecification[];
  readonly type = MaterialSpecActionTypes.LoadMaterialSpecsSuccess;
}

export class UpdateMaterialSpec implements UpdateDataElementAction<MaterialSpecification> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: MaterialSpecification;
  successAction = new UpdateMaterialSpecSuccess();
  failAction = new UpdateMaterialSpecFail();
  readonly type = MaterialSpecActionTypes.UpdateMaterialSpec;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(public payload: { dataElement: MaterialSpecification; schema: JSONSchema7 }) {
    this.dataElement = payload.dataElement;
    this.schema = payload.schema;
  }
}

export class UpdateMaterialSpecFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialSpecActionTypes.UpdateMaterialSpecFail;
}

export class UpdateMaterialSpecSuccess
  implements UpdateDataElementSuccessAction<MaterialSpecification>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: MaterialSpecification;
  readonly type = MaterialSpecActionTypes.UpdateMaterialSpecSuccess;
}

export class AddMaterialSpec implements AddDataElementAction<MaterialSpecification> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: MaterialSpecification;
  successAction = new AddMaterialSpecSuccess();
  failAction = new AddMaterialSpecFail();
  dataElementType = dataType;
  skipBinaryTask = true;
  readonly type = MaterialSpecActionTypes.AddMaterialSpec;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(
    public payload: {
      config: Config;
      dataElement: MaterialSpecification;
      schema: JSONSchema7;
      skipBinaryTask?: boolean;
    }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.schema = payload.schema;
    this.skipBinaryTask = payload.skipBinaryTask ?? this.skipBinaryTask;
  }
}

export class AddMaterialSpecFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialSpecActionTypes.AddMaterialSpecFail;
}

export class AddMaterialSpecSuccess implements AddDataElementSuccessAction<MaterialSpecification> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: MaterialSpecification;
  readonly type = MaterialSpecActionTypes.AddMaterialSpecSuccess;
}

export class CopyMaterialSpec implements CopyDataElementAction<MaterialSpecification> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: MaterialSpecification;
  successAction = new CopyMaterialSpecSuccess();
  failAction = new CopyMaterialSpecFail();
  dataElementType = dataType;
  skipBinaryTask = true;
  readonly type = MaterialSpecActionTypes.CopyMaterialSpec;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(
    public payload: { config: Config; dataElement: MaterialSpecification; schema: JSONSchema7 }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.schema = payload.schema;
  }
}

export class CopyMaterialSpecFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialSpecActionTypes.CopyMaterialSpecFail;
}

export class CopyMaterialSpecSuccess
  implements CopyDataElementSuccessAction<MaterialSpecification>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: MaterialSpecification;
  readonly type = MaterialSpecActionTypes.CopyMaterialSpecSuccess;
}

export class DeleteMaterialSpecs implements DeleteDataElementsAction<MaterialSpecification> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: MaterialSpecification[];
  successAction = new DeleteMaterialSpecsSuccess();
  failAction = new DeleteMaterialSpecsFail();
  dataElementType = dataType;
  skipBinaryTask = true;
  nodeId = EnvironmentConstants.FCS_NODE_ID_MATERIAL_SPECS;
  isExternalNodeId = true;

  readonly type = MaterialSpecActionTypes.DeleteMaterialSpecs;

  constructor(public payload: { config: Config; dataElements: MaterialSpecification[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteMaterialSpecsFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = MaterialSpecActionTypes.DeleteMaterialSpecsFail;
}

export class DeleteMaterialSpecsSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = MaterialSpecActionTypes.DeleteMaterialSpecsSuccess;
}

export type MaterialSpecActions =
  | LoadMaterialSpecs
  | LoadMaterialSpecsFail
  | LoadMaterialSpecsSuccess
  | UpdateMaterialSpec
  | UpdateMaterialSpecFail
  | UpdateMaterialSpecSuccess
  | AddMaterialSpec
  | AddMaterialSpecFail
  | AddMaterialSpecSuccess
  | DeleteMaterialSpecs
  | DeleteMaterialSpecsFail
  | DeleteMaterialSpecsSuccess
  | CopyMaterialSpec
  | CopyMaterialSpecFail
  | CopyMaterialSpecSuccess;
