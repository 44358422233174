import * as fromCacheData from '../actions/cache-data.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CacheTableEntry } from '@models/cache/cache';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CacheDataState extends EntityState<CacheTableEntry> {}

export const CacheDataAdapter: EntityAdapter<CacheTableEntry> =
  createEntityAdapter<CacheTableEntry>({
    selectId: (cacheEntry: CacheTableEntry) => cacheEntry.id,
  });

export const initialState: CacheDataState = CacheDataAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromCacheData.CacheDataActions
): CacheDataState {
  switch (action.type) {
    case fromCacheData.CacheDataActionTypes.UpsertCacheTableData: {
      return CacheDataAdapter.upsertMany(action.payload.data, state);
    }
    case fromCacheData.CacheDataActionTypes.DeleteCacheTableData: {
      return CacheDataAdapter.removeMany(action.payload.ids, state);
    }
    case fromCacheData.CacheDataActionTypes.TriggerCacheDataUpdate: {
      return state;
    }
    case fromCacheData.CacheDataActionTypes.UpdateCacheDataTypeRecord: {
      return state;
    }
    case fromCacheData.CacheDataActionTypes.CleanStaleConfigCacheRecords: {
      return state;
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectCacheDataIds,
  selectEntities: selectCacheDataEntities,
  selectAll: selectAllCacheData,
  selectTotal: selectCacheDataTotal,
} = CacheDataAdapter.getSelectors();
