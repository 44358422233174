import { Injectable } from '@angular/core';
import { Observable, of, combineLatest, defer } from 'rxjs';
import { map, take, switchMap, tap, catchError } from 'rxjs/operators';
import { StorageFile, StorageFileType, ContentFileMap } from '@models/fabrication/files';
import {
  DbOperationRequest,
  DbOperationResponse,
  DbTable,
  DbOperation,
  StorageFileResponse,
} from '@cache/binary-storage-operations';
import { fromWorker } from 'observable-webworker';
import { v4 as uuidv4 } from 'uuid';
import { DataElementType } from '@constants/data-element-types';
import { uniqBy, chunk } from 'lodash';
import { LoggingService } from '@services/logging.service';
import { StorageFileResponseBulkError } from '@models/errors/storage-error';
import { ErrorConstants as ec } from '@constants/error-constants';
import { ResilientHttpClient, IResilientHttpOptions } from '@adsk/resilient-axios-client';
import { StorageFileResponseError } from '@models/errors/storage-error';
import { ResiliencyConstants as rc } from '@constants/resiliency-constants';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';

// provides file storage to indexeddb
@Injectable({
  providedIn: 'root',
})
export class BinaryStorageService {
  private httpClient: ResilientHttpClient;

  constructor(private loggingService: LoggingService) {
    this.setupClient();
  }

  private filterContentFileMapObjectKeys = (
    contentFileMaps: ContentFileMap[],
    filterImages: boolean
  ): string[] => {
    return contentFileMaps
      .filter((x) => x.isImage === filterImages)
      .map((x) => x.contentFile.objectKey);
  };

  private filterContentFileMapsToSupportedTypes = (
    contentFileMaps: ContentFileMap[],
    supportedStorageFileTypes: StorageFileType[]
  ): ContentFileMap[] => {
    // image file types
    const imageFileMaps = contentFileMaps.filter(
      (x) => x.isImage && supportedStorageFileTypes.includes(StorageFileType.ImageFile)
    );

    const dataFileMaps = contentFileMaps.filter(
      (x) =>
        !x.isImage &&
        (supportedStorageFileTypes.includes(StorageFileType.DbFile) ||
          supportedStorageFileTypes.includes(StorageFileType.PartFile))
    );

    return uniqBy(imageFileMaps.concat(dataFileMaps), 'contentFile.objectKey');
  };

  public addStorageFiles(
    contentFileMaps: ContentFileMap[],
    dataType: DataElementType,
    supportedStorageFileTypes: StorageFileType[]
  ): Observable<boolean> {
    // ensure unique could contain duplicates and filter out un-supported types
    contentFileMaps = this.filterContentFileMapsToSupportedTypes(
      contentFileMaps,
      supportedStorageFileTypes
    );
    // validate storage files do not already exist
    const dataFileObjectKeys = this.filterContentFileMapObjectKeys(contentFileMaps, false);
    const imageFileObjectKeys = this.filterContentFileMapObjectKeys(contentFileMaps, true);

    // get the ids (use the objectKey for the Id) of StorageFiles that match the supplied objectKeys
    const observables: Observable<string[]>[] = [];
    supportedStorageFileTypes.forEach((storageFileType) => {
      observables.push(
        this.checkIdsExist(
          storageFileType === StorageFileType.ImageFile ? imageFileObjectKeys : dataFileObjectKeys,
          storageFileType
        )
      );
    });

    return combineLatest(observables).pipe(
      map((existingIds: string[][]) => {
        if (existingIds && existingIds.length) {
          const persistedIds = existingIds.reduce((current, previous) => [...current, ...previous]);

          // filter to remove existing ids
          contentFileMaps = contentFileMaps.filter(
            (x) => !persistedIds.includes(x.contentFile.objectKey)
          );
        }
        return !!contentFileMaps.length;
      }),
      switchMap((requiresProcessing: boolean) => {
        if (requiresProcessing) {
          const chunks = chunk(contentFileMaps, 100);
          return this.getBinaryFiles(chunks).pipe(
            switchMap((responses: StorageFileResponse[]) => {
              if (responses) {
                const dbPutOperations: Observable<DbOperationResponse>[] = [];
                const imageKeys = this.filterContentFileMapObjectKeys(contentFileMaps, true);
                const dataKeys = this.filterContentFileMapObjectKeys(contentFileMaps, false);
                // success responses
                const storageFileResponses = responses.filter((x) => !x.error);
                // error responses
                const storageFileErrorResponses = responses.filter((x) => x.error);

                // log error responses
                if (storageFileErrorResponses.length) {
                  const bulkResponseError: StorageFileResponseBulkError = {
                    errors: storageFileErrorResponses.map((x) => x.error),
                  };

                  this.loggingService.logError(bulkResponseError, false);
                }

                // parse image and data storage files
                if (imageKeys.length) {
                  const imageStorageFiles = storageFileResponses
                    .filter((x) => imageKeys.includes(x.storageFile.id))
                    .map((x) => x.storageFile);
                  dbPutOperations.push(this.putDbRecords(imageStorageFiles, DbTable.imageFiles));
                }

                if (dataKeys.length) {
                  // todo: improve method to determine if we need parts or dbfiles as data storage
                  const dbTable =
                    dataType === DataElementType.DBFile ? DbTable.dbFiles : DbTable.partFiles;
                  const dataStorageFiles = storageFileResponses
                    .filter((x) => dataKeys.includes(x.storageFile.id))
                    .map((x) => x.storageFile);
                  dbPutOperations.push(this.putDbRecords(dataStorageFiles, dbTable));
                }

                // store to indexeddb
                return combineLatest(dbPutOperations).pipe(
                  map((dbResponses: DbOperationResponse[]) => dbResponses.every((x) => x.complete))
                );
              } else {
                return of(false);
              }
            })
          );
        } else {
          return of(true);
        }
      })
    );
  }

  public cleanBinaryStorage(): Observable<boolean> {
    const operationRequest: DbOperationRequest = {
      operationType: DbOperation.clean,
      request: null,
      requestId: '',
    };

    return this.executeDbOperation(operationRequest).pipe(
      map((response: DbOperationResponse) => response.complete)
    );
  }

  private getBinaryFiles(
    allContentFileMaps: ContentFileMap[][]
  ): Observable<StorageFileResponse[]> {
    const output: StorageFileResponse[] = [];

    console.time('binary download');

    const flat: ContentFileMap[] = [];
    allContentFileMaps.forEach((x) => flat.push(...x));
    return RxjsUtils.concurrentForkJoin(flat.map((x) => this.downloadBinaryFile(x))).pipe(
      tap((storageFileResponses: StorageFileResponse[]) => {
        output.push(...storageFileResponses);
      }),
      map(() => output),
      take(1),
      catchError((error: any) => {
        this.loggingService.logError(error, true, ec.APP_ERROR_FETCHING_STORAGE_FILE);
        return of(null);
      })
    );
  }

  public getImageFiles(keys: string[]): Observable<StorageFile[]> {
    return this.getDbRecords(keys, DbTable.imageFiles, false).pipe(
      map((response: DbOperationResponse) => response.data as StorageFile[])
    );
  }

  public getPartFiles(keys: string[]): Observable<StorageFile[]> {
    return this.getDbRecords(keys, DbTable.partFiles, false).pipe(
      map((response: DbOperationResponse) => response.data as StorageFile[])
    );
  }

  public getDBFiles(keys: string[]): Observable<StorageFile[]> {
    return this.getDbRecords(keys, DbTable.dbFiles, false).pipe(
      map((response: DbOperationResponse) => response.data as StorageFile[])
    );
  }

  public getDBFilesForConfig(configId: string): Observable<StorageFile[]> {
    return this.getDbRecords([], DbTable.dbFiles, false, 'configId', configId).pipe(
      map((response: DbOperationResponse) => {
        // additional check to make sure no duplicates for safety
        // this could potentially happen with binary updates as an edge case
        const configDbFiles = response.data as StorageFile[];
        const filteredConfigDbFiles: StorageFile[] = [];
        const processedDuplicateIds: string[] = [];
        configDbFiles.forEach((storageFile: StorageFile) => {
          if (!processedDuplicateIds.includes(storageFile.id)) {
            const filteredFileEntries = configDbFiles.filter(
              (x) => x.fileName === storageFile.fileName
            );

            // action if duplicate filenames found
            if (filteredFileEntries.length > 1) {
              processedDuplicateIds.push(...filteredFileEntries.map((x) => x.id));
              // compare time stamp to get latest
              const latestTimeStamp = Math.max(...filteredFileEntries.map((x) => x.timeStamp));
              const latestEntry = filteredFileEntries.find((x) => x.timeStamp === latestTimeStamp);
              filteredConfigDbFiles.push(latestEntry);
            } else {
              filteredConfigDbFiles.push(storageFile);
            }
          }
        });
        return filteredConfigDbFiles;
      })
    );
  }

  public deleteDBFiles(keys: string[]): Observable<boolean> {
    return this.deleteDbRecords(keys, DbTable.dbFiles).pipe(
      map((response: DbOperationResponse) => response.complete)
    );
  }

  public deletePartFiles(keys: string[]): Observable<boolean> {
    return this.deleteDbRecords(keys, DbTable.partFiles).pipe(
      map((response: DbOperationResponse) => response.complete)
    );
  }

  public deleteImageFiles(keys: string[]): Observable<boolean> {
    return this.deleteDbRecords(keys, DbTable.imageFiles).pipe(
      map((response: DbOperationResponse) => response.complete)
    );
  }

  public checkIdsExist(ids: string[], storageFileType: StorageFileType): Observable<string[]> {
    let dbTable = null;

    switch (storageFileType) {
      case StorageFileType.DbFile:
        dbTable = DbTable.dbFiles;
        break;
      case StorageFileType.ImageFile:
        dbTable = DbTable.imageFiles;
        break;
      case StorageFileType.PartFile:
        dbTable = DbTable.partFiles;
        break;
      default:
        break;
    }

    return this.getDbRecords(ids, dbTable, true).pipe(
      map((response: DbOperationResponse) => response.data as string[])
    );
  }

  private executeDbOperation(request: DbOperationRequest): Observable<DbOperationResponse> {
    const requestId = request.requestId || uuidv4();
    request.requestId = requestId;

    return fromWorker<DbOperationRequest, DbOperationResponse>(
      () => new Worker(new URL('./binary-storage-db.worker', import.meta.url), { type: 'module' }),
      of(request)
    ).pipe(
      // filter((response: DbOperationResponse) => response.requestId === requestId),
      take(1),
      catchError((error: any) => {
        this.loggingService.logError(error, true, ec.APP_ERROR_LOADING_STORAGE_FILE);
        return of({ requestId, complete: false, error, data: [] } as DbOperationResponse);
      })
    );
  }

  private getDbRecords(
    keys: string[],
    dbTable: DbTable,
    returnOnlyDataIds: boolean,
    alternativeKeyField = null,
    alternativeKeyValue = null
  ): Observable<DbOperationResponse> {
    const operationRequest: DbOperationRequest = {
      operationType: DbOperation.get,
      request: { dbTable, keys, alternativeKeyField, alternativeKeyValue, returnOnlyDataIds },
      requestId: '',
    };
    return this.executeDbOperation(operationRequest);
  }

  private putDbRecords(
    storageFiles: StorageFile[],
    dbTable: DbTable
  ): Observable<DbOperationResponse> {
    const operationRequest: DbOperationRequest = {
      operationType: DbOperation.put,
      request: { data: storageFiles, dbTable },
      requestId: '',
    };
    return this.executeDbOperation(operationRequest);
  }

  private deleteDbRecords(keys: string[], dbTable: DbTable): Observable<DbOperationResponse> {
    const operationRequest: DbOperationRequest = {
      operationType: DbOperation.delete,
      request: { dbTable, keys },
      requestId: '',
    };
    return this.executeDbOperation(operationRequest);
  }

  private downloadBinaryFile(contentFileMap: ContentFileMap): Observable<StorageFileResponse> {
    let storageFileResponse: StorageFileResponse;
    return defer(() => {
      const fileName = this.getNameFromPathEntry(contentFileMap.contentFile.name);
      storageFileResponse = {
        storageFile: {
          id: contentFileMap.contentFile.objectKey,
          configId: contentFileMap.configId,
          fileName,
          checksum: contentFileMap.contentFile.checksum,
          contents: null,
          timeStamp: 0,
          finalizeUploadData: contentFileMap.finalizeUploadData,
        },
        error: null,
      };
      return this.httpClient.get(`${contentFileMap.fileUrl}`, {
        responseType: 'blob',
      });
    }).pipe(
      map((response: any) => {
        storageFileResponse.storageFile.contents = new Blob([response.data]);
        storageFileResponse.storageFile.timeStamp = Date.now();
        return storageFileResponse;
      }),
      catchError((error: any) => {
        const failedAt = new Date().toISOString();
        const correlationId = uuidv4();
        const responseError: StorageFileResponseError = {
          correlationId,
          failedAt,
          error,
          storageFile: storageFileResponse.storageFile,
        };
        storageFileResponse.error = responseError;
        return of(storageFileResponse);
      })
    );
  }

  private getNameFromPathEntry(entry: string): string {
    const isPathTypeName = entry?.includes('\\');
    if (!isPathTypeName) {
      return entry;
    } else {
      const splitPath = entry.split('\\').filter((x) => !!x);
      return splitPath[splitPath.length - 1];
    }
  }

  private setupClient(): void {
    const resilientHttpOptions: IResilientHttpOptions = {
      timeout: 30000,
      baseURL: '',
      resilienceOptions: {
        retries: rc.DEFAULT_RETRY_COUNT,
      },
    };

    this.httpClient = new ResilientHttpClient(resilientHttpOptions);
  }
}
