import * as fromRates from '../actions/fabrication-rate.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FabricationRate } from '@models/fabrication/labour-rate';

export interface FabricationRateState extends EntityState<FabricationRate> {} // eslint-disable-line

export const fabricationRateAdapter: EntityAdapter<FabricationRate> =
  createEntityAdapter<FabricationRate>({
    selectId: (rate: FabricationRate) => rate.id,
  });

export const initialState: FabricationRateState = fabricationRateAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromRates.FabricationRateActions
): FabricationRateState {
  switch (action.type) {
    case fromRates.FabricationRateActionTypes.LoadFabricationRates: {
      return state;
    }
    case fromRates.FabricationRateActionTypes.LoadFabricationRatesSuccess: {
      return fabricationRateAdapter.upsertMany(action.data, state);
    }
    case fromRates.FabricationRateActionTypes.LoadFabricationRatesFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of fabrication rate ids
  selectIds: selectFabricationRateIds,

  // select the dictionary of fabrication rate entities
  selectEntities: selectFabricationRateEntities,

  // select the array of fabrication rates
  selectAll: selectAllFabricationRates,

  // select the total fabrication rate count
  selectTotal: selectFabricationRatesTotal,
} = fabricationRateAdapter.getSelectors();
