import * as fromActivity from '../actions/activity.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActivitySubmission } from '@models/activities-events/activities';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActivityState extends EntityState<ActivitySubmission> {}

export const ActivityAdapter: EntityAdapter<ActivitySubmission> =
  createEntityAdapter<ActivitySubmission>({
    selectId: (submission: ActivitySubmission) => submission.id,
  });

export const initialState: ActivityState = ActivityAdapter.getInitialState();

export function reducer(state = initialState, action: fromActivity.ActivityActions): ActivityState {
  switch (action.type) {
    case fromActivity.ActivityActionTypes.SubmitActivity: {
      return state;
    }
    case fromActivity.ActivityActionTypes.SubmitActivitySuccess: {
      return state;
    }
    case fromActivity.ActivityActionTypes.SubmitActivityFailed: {
      return ActivityAdapter.addOne(action.payload.submission, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectActivityIds,
  selectEntities: selectActivityEntities,
  selectAll: selectAllActivity,
  selectTotal: selectActivityTotal,
} = ActivityAdapter.getSelectors();
