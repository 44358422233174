import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject, Observable, of } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter, map } from 'rxjs/operators';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormOnApplyResult,
  DynamicFormOptions,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditError } from '@models/inline-edit/inline-edit.options';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { getI18nConstantRef } from '@utils/translate-utils';
import { DomainType, ShapeType } from '@models/fabrication/common';
import { DynamicModalSetupData } from '@models/dynamic-content/dynamic-content';
import { DynamicModalComponent } from '@shared/components/dynamic-modal/dynamic-modal.component';
import { DataElementType } from '@constants/data-element-types';
import { FabricationReference, FabricationReferenceType } from '@models/forge-content/references';
import { uniq } from 'lodash';
import {
  InsulationSpecificationInfo,
  InsulationSpecificationTable,
  InsulationSpecificationTableType,
} from '@models/fabrication/insulation-specification-info';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { v4 as uuidv4 } from 'uuid';
import { SchemaService } from '@services/schema.service';
import { JSONSchema7 } from 'json-schema';
import { EnvironmentConstants } from '@constants/environment-constants';

@Component({
  selector: 'fab-ins-spec-tables',
  template: `
    <fab-dynamic-modal #modal> </fab-dynamic-modal>
    <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data>
  `,
})
export class InsulationSpecificationTablesComponent
  extends DynamicFormBaseCustomComponent<InsulationSpecificationTable[]>
  implements OnInit, OnDestroy
{
  @ViewChild(DynamicModalComponent) modal: DynamicModalComponent;
  tableOptions: DynamicTableOptions<InsulationSpecificationTable> = null;
  tableDataSource: BehaviorSubject<InsulationSpecificationTable[]>;
  tableChangeSubject: Subject<InsulationSpecificationTable[]> = new Subject<
    InsulationSpecificationTable[]
  >();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  idLookup = elementIdDictionary.connectorEdit;
  rows: InsulationSpecificationTable[];
  customModelChangesSubscription: Subscription;
  currentRow: InsulationSpecificationTable = null;
  modalOptions: DynamicModalSetupData = null;
  selectedTableIndex = 0;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private schemaService: SchemaService,
    store$: Store<FDMState>
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.tableErrorReporterSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getModelUpdates() {
    const getModelChanges$: Observable<InsulationSpecificationInfo> = this.formModelUpdater.pipe(
      filter(
        (changeEvent: DynamicFormCustomUpdateEvent) =>
          changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
      ),
      map(
        (changeEvent: DynamicFormCustomUpdateEvent) =>
          changeEvent.data as InsulationSpecificationInfo
      )
    );

    this.customModelChangesSubscription = getModelChanges$.subscribe(
      (model: InsulationSpecificationInfo) => {
        this.rows = model.tables;
        this.createTableDataOptions(model);
        this.tableDataSource.next(this.rows);
      }
    );
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: InsulationSpecificationTable[]) => {
        this.rows = rows;
        this.applyFabricationReferences();
      }
    );
  }

  createTableDataOptions(model: InsulationSpecificationInfo) {
    if (!this.tableOptions) {
      this.tableDataSource =
        this.tableDataSource || new BehaviorSubject<InsulationSpecificationTable[]>(this.rows);

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'ins-spec-tables',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        subscribeToTableChanges: this.tableChangeSubject,
        maintainSelectionOnUpdate: true,
        columns: this.getColumnDefinitions(),
        tableTypeOptions: {
          disableAddCondition: 'customFunction',
          customAddAction: this.displayTableModalForCreate,
          customEditAction: this.displayTableModalForEdit,
          customDisableAddButton: (tables: InsulationSpecificationTable[]) =>
            model.tableType !== InsulationSpecificationTableType.SingleDimension ||
            tables.some((x) => x.domain === DomainType.Pipework),
          customDisableEditButton: (tables: InsulationSpecificationTable[], index: number) =>
            model.tableType !== InsulationSpecificationTableType.SingleDimension ||
            tables[index]?.domain !== DomainType.Pipework,
        },
        toolbarOptions: {},
        footerText: this.translate.instant(LC.DYNAMIC_TABLE.PIPEWORK_TABLES_ONLY),
        enableMultiSelect: true,
      };
    }
  }

  displayTableModalForCreate = () => {
    // only create currently
    const defaultTable = new InsulationSpecificationTable();
    this.modalOptions = this.createModalFormOptions(defaultTable, 'create');
    this.modal.openModal(this.modalOptions);
  };

  displayTableModalForEdit = (model: InsulationSpecificationTable, index: number) => {
    this.selectedTableIndex = index;
    this.modalOptions = this.createModalFormOptions(model, 'edit');
    this.modal.openModal(this.modalOptions);
  };

  private getColumnDefinitions(): ColumnDefinition[] {
    const columns: ColumnDefinition[] = [
      {
        field: 'domain',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.DOMAIN),
        formatter: (domain: DomainType) =>
          this.translate.instant(LC.ENUMS.DOMAIN_TYPE[getI18nConstantRef(domain)]),
      },
      {
        field: 'shape',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.SHAPE),
        formatter: (shape: ShapeType) =>
          this.translate.instant(LC.ENUMS.SHAPE_TYPE[getI18nConstantRef(shape)]),
      },
    ];

    return columns;
  }

  applyFabricationReferences = () => {
    const materialIds = [];
    const materialSpecIds = [];
    this.rows.forEach((table: InsulationSpecificationTable) => {
      materialIds.push(...table.entries.map((x) => x.material));
      materialSpecIds.push(...table.entries.map((x) => x.materialSpecification));
    });

    let materialRefs: FabricationReference[] = [];
    let materialSpecRefs: FabricationReference[] = [];
    if (materialIds?.length) {
      // ensure unique
      // filter out references to unassigned/any materials
      materialRefs = uniq(
        materialIds.filter(
          (x) =>
            x !== '-1' &&
            x !== EnvironmentConstants.FCS_UNASSIGNED_MATERIAL &&
            x !== EnvironmentConstants.FCS_ANY_MATERIAL
        )
      ).map((x) => ({
        dataType: DataElementType.Material,
        externalId: x,
        referenceType: FabricationReferenceType.Relationship,
      }));
    }

    if (materialSpecIds?.length) {
      // ensure unique
      materialSpecRefs = uniq(
        materialSpecIds.filter(
          (x) => x !== EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC && x !== '-1'
        )
      ).map((x) => ({
        dataType: DataElementType.MaterialSpecification,
        externalId: x,
        referenceType: FabricationReferenceType.Relationship,
      }));
    }

    const spec = this.model as InsulationSpecificationInfo;
    spec.fabricationReferences = [...materialSpecRefs, ...materialRefs];
    this.updateSource(this.rows);
  };

  createModalFormOptions = (
    table: InsulationSpecificationTable,
    formOperation: DynamicFormOperationType
  ): DynamicModalSetupData => {
    const uniqueFieldRestrictions = ['domain', 'shape'];
    const tablesToCompareUniqueSetup =
      formOperation === 'create' ? this.rows : this.rows.filter((x) => x !== table);

    const formOptions: DynamicFormOptions<any> = {
      model: table,
      formOperation,
      applyModelAction: null,
      isReadOnly: formOperation === 'view',
      disableCreateId: true,
      uniqueFields: {
        fields: uniqueFieldRestrictions,
        allElements: () => of(tablesToCompareUniqueSetup),
        validateImediately: true,
      },
      getCustomSchema: () => {
        const parentSchema = this.schemaService.getSchemaByDataElementType(
          DataElementType.InsulationSpecification
        );
        const schema = parentSchema.definitions[
          EnvironmentConstants.FSS_SUB_SCHEMA_INSULATION_SPECIFICATION_TABLE
        ] as JSONSchema7;
        return schema;
      },
      tabs: [
        {
          label: this.translate.instant(LC.DYNAMIC_FORM.TABS.DETAILS),
          includeFields: ['domain', 'shape'],
          options: {
            disabledFields: ['domain', 'shape'],
            selectFields: [
              {
                key: 'domain',
                options: FormUtils.mapSelectOptionsFromEnum(
                  this.translate,
                  DomainType,
                  'DomainType'
                ).filter((x) => x.value !== DomainType.NotSet),
              },
              {
                key: 'shape',
                options: FormUtils.mapSelectOptionsFromEnum(this.translate, ShapeType, 'ShapeType'),
              },
            ],
            formStyle: DynamicFormStyle.SIMPLE,
          },
        },
        {
          label: this.translate.instant(LC.DYNAMIC_FORM.TABS.SIZE_ENTRIES),
          includeFields: ['entries'],
          options: {
            customComponents: [
              {
                type: DynamicFormCustomComponentType.InsSpecTable,
                field: 'entries',
              },
            ],
            formStyle: DynamicFormStyle.SIMPLE,
          },
        },
      ],
      formStyle: DynamicFormStyle.NONE,
    };

    return {
      title: this.translate.instant(LC.FEATURE_MODULES.INSULATION_SPECIFICATIONS.MODAL_TITLE),
      showModalButtons: false,
      modalWidth: 1000,
      contentSetup: {
        contentType: 'form',
        formSetup: {
          fromModal: true,
          options: formOptions,
          dataType: DataElementType.InsulationSpecification,
          onFormApply: (onApply: DynamicFormOnApplyResult) => {
            if (onApply.operation === 'create') {
              this.rows.push(onApply.result);
            } else {
              this.rows[this.selectedTableIndex] = onApply.result;
            }

            this.tableDataSource.next(this.rows);
            this.cdr.detectChanges();
            // update fabrication refs
            this.applyFabricationReferences();
          },
          onFormCancel: () => {
            console.log('Modal from form cancelled');
          },
        },
      },
    };
  };
}
