import * as fromStiffenerSpecs from '../actions/stiffener-spec.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StiffenerSpecificationInfo } from '@models/fabrication/stiffener-specification-info';

export interface StiffenerSpecState extends EntityState<StiffenerSpecificationInfo> {} // eslint-disable-line

export const stiffenerSpecAdapter: EntityAdapter<StiffenerSpecificationInfo> =
  createEntityAdapter<StiffenerSpecificationInfo>({
    selectId: (stiffenerSpec: StiffenerSpecificationInfo) => stiffenerSpec.id,
  });

export const initialState: StiffenerSpecState = stiffenerSpecAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromStiffenerSpecs.StiffenerSpecificationsActions
): StiffenerSpecState {
  switch (action.type) {
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes.LoadStiffenerSpecificationInfos: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .LoadStiffenerSpecificationInfosSuccess: {
      return stiffenerSpecAdapter.upsertMany(action.data, state);
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .LoadStiffenerSpecificationInfosFail: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes.AddStiffenerSpecificationInfo: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .AddStiffenerSpecificationInfoSuccess: {
      return stiffenerSpecAdapter.addOne(action.dataElement, state);
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .AddStiffenerSpecificationInfoFail: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes.CopyStiffenerSpecificationInfo: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .CopyStiffenerSpecificationInfoSuccess: {
      return stiffenerSpecAdapter.addOne(action.dataElement, state);
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .CopyStiffenerSpecificationInfoFail: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .UpdateStiffenerSpecificationInfo: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .UpdateStiffenerSpecificationInfoSuccess: {
      return stiffenerSpecAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .UpdateStiffenerSpecificationInfoFail: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .DeleteStiffenerSpecificationInfos: {
      return state;
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .DeleteStiffenerSpecificationInfosSuccess: {
      return stiffenerSpecAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromStiffenerSpecs.StiffenerSpecificationInfoActionTypes
      .DeleteStiffenerSpecificationInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of stiffener spec ids
  selectIds: selectStiffenerSpecIds,

  // select the dictionary of stiffener spec entities
  selectEntities: selectStiffenerSpecEntities,

  // select the array of stiffener specs
  selectAll: selectAllStiffenerSpecs,

  // select the total stiffener spec count
  selectTotal: selectStiffenerSpecsTotal,
} = stiffenerSpecAdapter.getSelectors();
