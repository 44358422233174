import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import {
  DynamicTableOptions,
  ColumnDefinition,
  CreateOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import {
  DynamicFormMessageType,
  DynamicFormOptions,
  DynamicFormSelectType,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { Material, MaterialType } from '@models/fabrication/material';
import { EnvironmentConstants } from '@constants/environment-constants';
import { map, take } from 'rxjs/operators';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfigMaterials,
  selectCurrentConfigMaterialSpecs,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import { selectMaterialById } from '@store/selectors/material.selectors';
import {
  LoadMaterials,
  LoadMaterialsSuccess,
  AddMaterial,
  UpdateMaterial,
  CopyMaterial,
  DeleteMaterials,
  DeleteMaterialsSuccess,
  AddMaterialSuccess,
  UpdateMaterialSuccess,
} from '@store/actions/material.action';
import { UpdateConfigMaterialIds } from '@store/actions/configs.action';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import {
  MaterialSpecification,
  MaterialSpecificationType,
} from '@models/fabrication/material-spec';
import { flatten } from 'lodash';
import { DataElementInternalType } from '@constants/data-element-internal-types';
import {
  SpecificationInfo,
  SpecificationTableWithLength,
} from '@models/fabrication/specification-info';
import { Observable, of } from 'rxjs';
import { ReferenceMap, ReferenceOutput } from '@services/data-services/dynamic-graph-data.service';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import { MaterialFinish } from '@models/fabrication/material-finish';
import { JSONSchema7 } from 'json-schema';

@Injectable()
export class DynamicMaterialSetup extends DynamicDataElementTypeSetup<Material | MaterialFinish> {
  private createType: MaterialType = MaterialType.Main;

  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<Material | MaterialFinish>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.materials;
  }

  setupOptions() {
    const materialSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_MATERIAL_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_MATERIAL,
    };

    const materialFinishSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_MATERIAL_FINISH_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_MATERIAL_FINISH,
    };

    this.options = {
      dataType: DataElementType.Material,
      dependentDataTypes: [DataElementType.MaterialSpecification],
      extraDataElementInfoOptions: {
        interdependentReferenceDataTypes: [DataElementType.MaterialSpecification],
      },
      createNewInstance: () => {
        if (this.createType === MaterialType.Finish) {
          return new MaterialFinish();
        }
        return new Material(this.createType);
      },
      supportsDynamicUpdates: true,
      sortFields: ['category', 'name'],
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigMaterials(includeInvalidData, true)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectMaterialById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadMaterials({ config })),
        loadSuccessAction: () => new LoadMaterialsSuccess(),
        deleteDataSuccessAction: () => new DeleteMaterialsSuccess(),
        addDataSuccessAction: () => new AddMaterialSuccess(),
        updateDataSuccessAction: () => new UpdateMaterialSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigMaterialIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: Material | MaterialFinish) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddMaterial({
                  config,
                  dataElement: model,
                  schema: this.getSchema(model),
                })
              )
            );
        },
        editModelAction: (model: Material | MaterialFinish) =>
          this.store$.dispatch(
            new UpdateMaterial({ dataElement: model, schema: this.getSchema(model) })
          ),
        copyModelAction: (model: Material | MaterialFinish) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyMaterial({
                  config,
                  dataElement: model,
                  schema: this.getSchema(model),
                })
              )
            );
        },
        deleteModelsAction: (models: (Material | MaterialFinish)[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteMaterials({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: Material | MaterialFinish) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.Material,
                  fixSchemaType:
                    model.materialType === MaterialType.Finish
                      ? EnvironmentConstants.FSS_SCHEMA_MATERIAL_FINISH
                      : EnvironmentConstants.FSS_SCHEMA_MATERIAL,
                  fixSchemaVersion:
                    model.materialType === MaterialType.Finish
                      ? EnvironmentConstants.FSS_SCHEMA_MATERIAL_FINISH_VERSION
                      : EnvironmentConstants.FSS_SCHEMA_MATERIAL_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_MATERIALS,
                  addSuccessAction: new AddMaterialSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        createSchemaOverride: () => {
          if (this.createType === MaterialType.Finish) {
            return materialFinishSchema;
          }

          return materialSchema;
        },
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_MATERIALS,
        schemas: [
          {
            dataType: DataElementType.Material,
            schema: materialSchema,
          },
          {
            dataType: materialFinishSchema.type,
            schema: materialFinishSchema,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<Material | MaterialFinish>> {
    const materialTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIALS.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIALS.CATEGORY),
        formatter: (value: string) =>
          value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
        visible: true,
      },
      {
        field: 'abbreviation',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIALS.ABBREVIATION),
        formatter: (value: string) => value ?? this.translate.instant(LC.DYNAMIC_TABLE.NA),
        visible: true,
      },
      {
        field: 'materialType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.MATERIALS.TYPE),
        visible: true,
      },
      {
        field: 'connectivity',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CONNECTIVITY),
        formatter: (value: string) => value ?? this.translate.instant(LC.DYNAMIC_TABLE.NA),
        visible: true,
      },
    ];

    const createOptions: CreateOptions = {
      setType: (type: MaterialType) => (this.createType = type),
      options: [
        {
          value: () => MaterialType.Main,
          label: this.translate.instant(LC.ENUMS.MATERIAL_TYPE.MAIN),
        },
        {
          value: () => MaterialType.Insulation,
          label: this.translate.instant(LC.ENUMS.MATERIAL_TYPE.INSULATION),
        },
        {
          value: () => MaterialType.Ductboard,
          label: this.translate.instant(LC.ENUMS.MATERIAL_TYPE.DUCTBOARD),
        },
        {
          value: () => MaterialType.Finish,
          label: this.translate.instant(LC.ENUMS.MATERIAL_TYPE.FINISH),
        },
      ],
    };

    return of(this.createDynamicTableOptions(materialTableColumns, createOptions));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<Material | MaterialFinish>> {
    const uniqueFieldRestrictions = ['name'];
    const categoryOptions = this.getItemListForTypeaheadControl('category');
    const connectivityOptions = this.getItemListForTypeaheadControl('connectivity');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: Material | MaterialFinish) => {
        const includeFields =
          model.materialType === MaterialType.Finish
            ? ['category', 'materialType']
            : ['abbreviation', 'category', 'connectivity', 'materialType', 'fabricationReferences'];

        const messageFields =
          model.materialType === MaterialType.Finish
            ? []
            : [
                {
                  key: 'fabricationReferences',
                  message: (value) =>
                    value.length
                      ? null
                      : {
                          message: this.translate.instant(
                            LC.ERROR_HANDLING.MATERIALS.UNASSIGNED_MATERIAL_SPECS_WARNING
                          ),
                          type: DynamicFormMessageType.WARNING,
                        },
                },
              ];

        const labelMapping =
          model.materialType === MaterialType.Finish
            ? []
            : [
                {
                  key: 'fabricationReferences',
                  label: this.translate.instant(
                    LC.DATATYPES.DEFINITIONS.MATERIALS.MATERIAL_SPECIFICATIONS
                  ),
                },
              ];

        const customComponents =
          model.materialType === MaterialType.Finish
            ? []
            : [
                {
                  type: DynamicFormCustomComponentType.MultiReferenceSelect,
                  field: 'fabricationReferences',
                  multiSelectOptions: {
                    dataType: DataElementType.MaterialSpecification,
                    options: this.getFilteredMultiSelectOptions(model as Material),
                  },
                },
              ];

        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
              includeFields,
              orderByIncludeFields: true,
              expanded: true,
              options: {
                readOnlyFields: ['materialType'],
                messageFields,
                labelMapping,
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categoryOptions,
                  },
                  {
                    key: 'connectivity',
                    options: connectivityOptions,
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
          ],
          customComponents,
          formStyle: DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category', 'materialType'],
      upstreamReferenceDataTypes: (material: Material) => {
        return material.materialType === MaterialType.Finish
          ? [DataElementType.Part]
          : [
              DataElementType.Part,
              DataElementType.Specification,
              DataElementType.InsulationSpecification,
            ];
      },
      isReplaceable: true,
      isRemovable: false,
      isEditable: true,
      clusterIcon: 'file-assembly16',
      internalUpstreamReferencesDataTypes: (material: Material) => {
        return material.materialType === MaterialType.Finish ? [] : [DataElementType.Specification];
      },
      createInternalUpstreamGraphNodes: (dataElement: Material, references: ReferenceMap[]) => {
        if (dataElement.materialType === MaterialType.Finish) {
          return of([]);
        }

        const partSpecifications = references
          .filter((ref) => ref.dataElement && ref.dataElementType === DataElementType.Specification)
          .map((ref) => ref.dataElement as SpecificationInfo);

        if (partSpecifications?.length) {
          const graphNodes = flatten(
            partSpecifications.map((spec) => {
              const tables = spec.tables;

              return tables
                .filter((table) => table.material === dataElement.externalId)
                .map((table) => {
                  const { shape, domain, appliesTo } = table;
                  const tableLength = (table as SpecificationTableWithLength).tableLength;

                  return {
                    id: table.id,
                    internalRelationshipId: spec.externalId,
                    internalRelationshipDataType: DataElementInternalType.PartSpecficationTable,
                    dbid: dataElement.id,
                    dataType: DataElementType.Material,
                    isFocusable: false,
                    isExpandable: true,
                    isReplaceable: false,
                    isRemovable: false,
                    isEditable: false,
                    info: {
                      shape,
                      domain,
                      appliesTo,
                      material: dataElement.name,
                      ...(tableLength ? { tableLength } : {}),
                    },
                    referenceMetaData: null,
                    isInvalidInternalData:
                      spec.extensionDataType === EnvironmentConstants.FSS_SCHEMA_INVALID_DATA,
                  };
                });
            })
          );

          return of(graphNodes);
        } else {
          return of([
            {
              id: '-2',
              dataType: DataElementType.Specification,
              isExpandable: false,
              hasNoReference: true,
            },
          ]);
        }
      },
      getInternalUpstreamReferenceFilteredElements: (
        references: ReferenceOutput[],
        internalRelationshipId: string
      ) => {
        const selectedSpecification = references
          .filter((ref) => ref.referenceMap.dataElementType === DataElementType.Specification)
          .find((ref) => ref.referenceMap.dataElement.externalId === internalRelationshipId);

        return [selectedSpecification];
      },
      isDownstreamRefBlocked: (material: Material) => material.materialType === MaterialType.Finish,
    };
  }

  private getFilteredMultiSelectOptions(material: Material): Observable<DynamicFormSelectType[]> {
    return this.store$.select(selectCurrentConfigMaterialSpecs(true)).pipe(
      map((referenceInfoList: MaterialSpecification[]) => {
        let materialSpecs: MaterialSpecification[] = [];
        if (material.materialType === MaterialType.Main) {
          materialSpecs = referenceInfoList.filter(
            (x) =>
              x.materialSpecificationType === MaterialSpecificationType.ElectricalContainment ||
              x.materialSpecificationType === MaterialSpecificationType.Generic ||
              x.materialSpecificationType === MaterialSpecificationType.Pipework ||
              x.materialSpecificationType === MaterialSpecificationType.SheetMetal
          );
        } else if (material.materialType === MaterialType.Insulation) {
          materialSpecs = referenceInfoList.filter(
            (x) =>
              x.materialSpecificationType === MaterialSpecificationType.Insulation &&
              x.form !== 'Board'
          );
        } else if (material.materialType === MaterialType.Ductboard) {
          materialSpecs = referenceInfoList.filter((x) => x.form === 'Board');
        }

        return this.getDataElementTypeAsSummaryList(
          DataElementType.MaterialSpecification,
          materialSpecs
        );
      }),
      take(1)
    );
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return 'file-assembly';
  }

  isFixable(): boolean {
    return true;
  }

  getInvalidDataErrors(model: Material & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.Material);
    return this.getStandardInvalidDataError(DataElementType.Material, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: Material*/): boolean {
    return false;
  }

  private getSchema(model: Material | MaterialFinish): JSONSchema7 {
    return this.schemaService.getSchemaByDataElementType(
      model.materialType === MaterialType.Finish
        ? EnvironmentConstants.FSS_SCHEMA_MATERIAL_FINISH
        : DataElementType.Material
    );
  }
}
