import { SpecificationInfo } from '@models/fabrication/specification-info';
import { Config } from '@models/fabrication/config';
import {
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  DeleteDataElementsSuccessAction,
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum SpecificationInfoActionTypes {
  LoadSpecificationInfos = '[Specifications API] Load SpecificationInfos',
  LoadSpecificationInfosFail = '[Specifications API] Load SpecificationInfos Fail',
  LoadSpecificationInfosSuccess = '[Specifications API] Load SpecificationInfos Success',
  UpdateSpecificationInfo = '[Specification API] Update SpecificationInfo',
  UpdateSpecificationInfoFail = '[Specification API] Update SpecificationInfo Fail',
  UpdateSpecificationInfoSuccess = '[Specification API] Update SpecificationInfo Success',
  AddSpecificationInfo = '[Specification API] Add SpecificationInfo',
  AddSpecificationInfoFail = '[Specification API] Add SpecificationInfo Fail',
  AddSpecificationInfoSuccess = '[Specification API] Add SpecificationInfo Success',
  DeleteSpecificationInfos = '[Specification API] Delete SpecificationInfos',
  DeleteSpecificationInfosFail = '[Specification API] Delete SpecificationInfos Fail',
  DeleteSpecificationInfosSuccess = '[Specification API] Delete SpecificationInfos Success',
  CopySpecificationInfo = '[Specification API] Copy SpecificationInfo',
  CopySpecificationInfoFail = '[Specification API] Copy SpecificationInfo Fail',
  CopySpecificationInfoSuccess = '[Specification API] Copy SpecificationInfo Success',
}

const dataType = DataElementType.Specification;

export class LoadSpecificationInfos implements LoadDataElementsAction<SpecificationInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadSpecificationInfosSuccess();
  failAction = new LoadSpecificationInfosFail();
  readonly type = SpecificationInfoActionTypes.LoadSpecificationInfos;
  dataElementType = DataElementType.Specification;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadSpecificationInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SpecificationInfoActionTypes.LoadSpecificationInfosFail;
}

export class LoadSpecificationInfosSuccess
  implements LoadDataElementsSuccessAction<SpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  data: SpecificationInfo[];
  readonly type = SpecificationInfoActionTypes.LoadSpecificationInfosSuccess;
}

export class UpdateSpecificationInfo implements UpdateDataElementAction<SpecificationInfo> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: SpecificationInfo;
  successAction = new UpdateSpecificationInfoSuccess();
  failAction = new UpdateSpecificationInfoFail();
  readonly type = SpecificationInfoActionTypes.UpdateSpecificationInfo;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: SpecificationInfo }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateSpecificationInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SpecificationInfoActionTypes.UpdateSpecificationInfoFail;
}

export class UpdateSpecificationInfoSuccess
  implements UpdateDataElementSuccessAction<SpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: SpecificationInfo;
  readonly type = SpecificationInfoActionTypes.UpdateSpecificationInfoSuccess;
}

export class AddSpecificationInfo implements AddDataElementAction<SpecificationInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: SpecificationInfo;
  successAction = new AddSpecificationInfoSuccess();
  failAction = new AddSpecificationInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = SpecificationInfoActionTypes.AddSpecificationInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: SpecificationInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class AddSpecificationInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SpecificationInfoActionTypes.AddSpecificationInfoFail;
}

export class AddSpecificationInfoSuccess implements AddDataElementSuccessAction<SpecificationInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: SpecificationInfo;
  readonly type = SpecificationInfoActionTypes.AddSpecificationInfoSuccess;
}

export class CopySpecificationInfo implements CopyDataElementAction<SpecificationInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: SpecificationInfo;
  successAction = new CopySpecificationInfoSuccess();
  failAction = new CopySpecificationInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = SpecificationInfoActionTypes.CopySpecificationInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: SpecificationInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopySpecificationInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SpecificationInfoActionTypes.CopySpecificationInfoFail;
}

export class CopySpecificationInfoSuccess
  implements CopyDataElementSuccessAction<SpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: SpecificationInfo;
  readonly type = SpecificationInfoActionTypes.CopySpecificationInfoSuccess;
}

export class DeleteSpecificationInfos implements DeleteDataElementsAction<SpecificationInfo> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: SpecificationInfo[];
  successAction = new DeleteSpecificationInfosSuccess();
  failAction = new DeleteSpecificationInfosFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS;
  isExternalNodeId = true;
  readonly type = SpecificationInfoActionTypes.DeleteSpecificationInfos;

  constructor(public payload: { config: Config; dataElements: SpecificationInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteSpecificationInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SpecificationInfoActionTypes.DeleteSpecificationInfosFail;
}

export class DeleteSpecificationInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = SpecificationInfoActionTypes.DeleteSpecificationInfosSuccess;
}

export type SpecificationsActions =
  | LoadSpecificationInfos
  | LoadSpecificationInfosFail
  | LoadSpecificationInfosSuccess
  | UpdateSpecificationInfo
  | UpdateSpecificationInfoFail
  | UpdateSpecificationInfoSuccess
  | AddSpecificationInfo
  | AddSpecificationInfoFail
  | AddSpecificationInfoSuccess
  | DeleteSpecificationInfos
  | DeleteSpecificationInfosFail
  | DeleteSpecificationInfosSuccess
  | CopySpecificationInfo
  | CopySpecificationInfoFail
  | CopySpecificationInfoSuccess;
