import { Part } from '@models/fabrication/part';
import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromParts from '../reducers/part.reducer';

// part state
export const selectPartState = (state: FDMState) => state.configsState.parts;

// part selectors
export const selectPartIds = createSelector(selectPartState, fromParts.selectPartIds);

export const selectPartEntities = createSelector(selectPartState, fromParts.selectPartEntities);

export const selectAllParts = createSelector(selectPartState, fromParts.selectAllParts);

export const selectPartsTotal = createSelector(selectPartState, fromParts.selectPartTotal);

// use selector with props rather than function with args
// selectors with props can be overriden in mockStore making
// testing simpler
export const selectPartById = (id: string) =>
  createSelector(selectPartEntities, (partEntities) => partEntities[id]);

export const selectPartsByIds = (ids: string[]) =>
  createSelector(selectPartEntities, (partEntities) => {
    const parts: Part[] = [];
    ids.forEach((id) => {
      parts.push(partEntities[id]);
    });

    return parts;
  });
