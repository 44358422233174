export enum NotificationType {
  Info,
  Success,
  Warning,
  Error,
}

export enum NotificationPlace {
  Flyout,
  Toast,
  Banner,
  Dialog,
}

export class Notification {
  id?: string;
  place: NotificationPlace = NotificationPlace.Toast;
  type: NotificationType;
  messages: string[] = [];
  autoDismiss = false;
  onDismiss?: () => void;
  actionState?: any;
  link?: NotificationLink;
  buttons?: NotificationButton[];
  description?: string;
  timestamp?: string;
}

export interface NotificationLink {
  text: string;
  url?: string;
  openInNewWindow?: boolean;
  action?: () => void;
}
export interface NotificationButton {
  title: string;
  onClick?: () => void;
}

export interface ShowNotificationProps {
  id?: string;
  message: string;
  description?: string;
  type?: NotificationType;
  withLink?: NotificationLink;
  showToast?: boolean;
  autoDismiss?: boolean;
  timestamp?: string;
  onDismiss?: () => void;
}

export class NotificationHide {
  id?: string;
  forceToHide: boolean;
}

export const DEFAULT_AUTODISMISS_TIME = 10000;
