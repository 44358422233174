import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { HigLearnNav, Props } from './learn-nav.react';
import { Subject } from 'rxjs';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ThemeService } from '@services/theme.service';
import { createRoot } from 'react-dom/client';

@Component({
  selector: 'hig-learn-nav',
  template: ` <div [id]="learnNavId"></div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HigLearnNavComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() command: string;
  @Input() upicode: string;

  @Output() readonly onClose = new EventEmitter<void>();
  root;

  // define at the top of the class
  private helpContextId = '';
  private upiC = 'FDM';

  private destroyed$ = new Subject<void>();

  public learnNavId = 'hig-learn-nav-container';

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.command = changes.data.currentValue;
  }

  ngOnInit(): void {
    this.initialise();
    this.listenToRouteChanges();
  }

  ngAfterViewInit(): void {
    this.themeService
      .listenToThemeChanges()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.renderHigLearnNav());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.root.unmount();
  }

  private initialise() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.helpContextId = data.helpContextId;
      this.renderHigLearnNav();
    });
  }

  private listenToRouteChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        takeUntil(this.destroyed$)
      )
      .subscribe((data) => {
        this.helpContextId = data.helpContextId;

        if (this.helpContextId) this.renderHigLearnNav();
      });
  }

  renderHigLearnNav = (): void => {
    const parentElement = document.getElementById(this.learnNavId);
    if (parentElement) {
      const props: Props = {
        command: this.helpContextId,
        upicode: this.upiC,
        onClose: () => this.onClose.emit(),
        themeData: this.themeService.getSelectedThemeData(),
      };
      if (!this.root) this.root = createRoot(parentElement);
      this.root.render(HigLearnNav(props));
    }
  };
}
