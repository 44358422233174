import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// **Important - do not import modules into routes using index.ts barrel method
import { AuthComponent } from '@shared/components/auth/auth.component';
import { LogoutComponent } from '@shared/components/auth/logout.component';
import { AppEntryGuard } from '@shared/guards/app-entry.guard';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'data',
    loadChildren: () => import('app/feature-modules/data/data.module').then((m) => m.DataModule),
    canMatch: [AppEntryGuard],
  },
  { path: 'auth', component: AuthComponent },
  { path: '', redirectTo: '/auth?redirect=login', pathMatch: 'full' },
];

@NgModule({
  // use { enableTracing: true } to debug routing
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
