import * as fromDamperInfo from '../actions/damper-info.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DamperInfo } from '@models/fabrication/damper-info';

export interface DamperInfoState extends EntityState<DamperInfo> {} // eslint-disable-line

export const damperInfoAdapter: EntityAdapter<DamperInfo> = createEntityAdapter<DamperInfo>({
  selectId: (damperInfo: DamperInfo) => damperInfo.id,
});

export const initialState: DamperInfoState = damperInfoAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromDamperInfo.DamperInfoActions
): DamperInfoState {
  switch (action.type) {
    // damperInfo
    case fromDamperInfo.DamperInfoActionTypes.LoadDamperInfos: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.LoadDamperInfosSuccess: {
      return damperInfoAdapter.upsertMany(action.data, state);
    }
    case fromDamperInfo.DamperInfoActionTypes.LoadDamperInfosFail: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.AddDamperInfo: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.AddDamperInfoSuccess: {
      return damperInfoAdapter.addOne(action.dataElement, state);
    }
    case fromDamperInfo.DamperInfoActionTypes.AddDamperInfoFail: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.CopyDamperInfo: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.CopyDamperInfoSuccess: {
      return damperInfoAdapter.addOne(action.dataElement, state);
    }
    case fromDamperInfo.DamperInfoActionTypes.CopyDamperInfoFail: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.UpdateDamperInfo: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.UpdateDamperInfoSuccess: {
      return damperInfoAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromDamperInfo.DamperInfoActionTypes.UpdateDamperInfoFail: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.DeleteDamperInfos: {
      return state;
    }
    case fromDamperInfo.DamperInfoActionTypes.DeleteDamperInfosSuccess: {
      return damperInfoAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromDamperInfo.DamperInfoActionTypes.DeleteDamperInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of damperInfo ids
  selectIds: selectDamperInfoIds,

  // select the dictionary of damperInfo entities
  selectEntities: selectDamperInfoEntities,

  // select the array of damperInfos
  selectAll: selectAllDamperInfos,

  // select the total damperInfo count
  selectTotal: selectDamperInfosTotal,
} = damperInfoAdapter.getSelectors();
