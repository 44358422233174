import * as fromAppDependency from '../actions/app-dependency.action';
import { AppDependencyType } from '@constants/app-dependency-types';

export interface AppDependencyState {
  loadingAppDependencies: AppDependencyType[];
  loadedAppDependencies: AppDependencyType[];
  failedAppDependencies: AppDependencyType[];
}

export const initialState: AppDependencyState = {
  loadingAppDependencies: [],
  loadedAppDependencies: [],
  failedAppDependencies: [],
};

export function reducer(
  state = initialState,
  action: fromAppDependency.AppDependencyActions
): AppDependencyState {
  switch (action.type) {
    case fromAppDependency.AppDependencyActionTypes.LoadAppDependency: {
      return state;
    }
    case fromAppDependency.AppDependencyActionTypes.LoadAppDependencyFail: {
      return state;
    }
    case fromAppDependency.AppDependencyActionTypes.LoadAppDependencySuccess: {
      return state;
    }
    case fromAppDependency.AppDependencyActionTypes.SetLoadingAppDependency: {
      return {
        ...state,
        loadingAppDependencies: setLoadingAppDependencies(
          state,
          action.payload.appDependencyType,
          action.payload.remove
        ),
      };
    }
    case fromAppDependency.AppDependencyActionTypes.SetLoadedAppDependency: {
      return {
        ...state,
        loadedAppDependencies: setLoadedAppDependencies(state, action.payload.appDependencyType),
      };
    }
    case fromAppDependency.AppDependencyActionTypes.SetFailedAppDependency: {
      return {
        ...state,
        failedAppDependencies: setFailedAppDependencies(
          state,
          action.payload.appDependencyType,
          action.payload.remove
        ),
      };
    }
    default:
      return state;
  }
}

const setFailedAppDependencies = (
  state: AppDependencyState,
  appDependencyType: AppDependencyType,
  remove: boolean
): AppDependencyType[] => {
  return remove
    ? [...state.failedAppDependencies].filter((x) => x !== appDependencyType)
    : [...state.failedAppDependencies, appDependencyType];
};

const setLoadingAppDependencies = (
  state: AppDependencyState,
  appDependencyType: AppDependencyType,
  remove: boolean
): AppDependencyType[] => {
  return remove
    ? [...state.loadingAppDependencies].filter((x) => x !== appDependencyType)
    : [...state.loadingAppDependencies, appDependencyType];
};

const setLoadedAppDependencies = (
  state: AppDependencyState,
  appDependencyType: AppDependencyType
): AppDependencyType[] => {
  return [...state.loadedAppDependencies, appDependencyType];
};

// selectors
export const selectLoadedAppDependencies = (state: AppDependencyState) =>
  state.loadedAppDependencies;

export const selectLoadingAppDependencies = (state: AppDependencyState) =>
  state.loadingAppDependencies;

export const selectFailedAppDependencies = (state: AppDependencyState) =>
  state.failedAppDependencies;
