import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { DataElementType } from '@constants/data-element-types';
import {
  ConnectorBreakPointTableRow,
  RoundConnectorBreakPointTableRow,
  RoundHexEndConnectorBreakPointTableRow,
  RectangularConnectorBreakPointTableRow,
  RectangularStepDownConnectorBreakPointTableRow,
  RectangularCouplingPlateConnectorBreakPointTableRow,
  OvalConnectorBreakPointTableRow,
} from './connector-break-point-table';
import { EnvironmentConstants } from '@constants/environment-constants';
import { FabricationReferenceType } from '@models/forge-content/references';

export enum ConnectorShapeType {
  Rectangular = 'Rectangular',
  Round = 'Round',
  Oval = 'Oval',
}

export enum ConnectorDomainType {
  NotSet = 'NotSet',
  HVAC = 'HVAC',
  Pipework = 'Pipework',
  ElectricalContainment = 'ElectricalContainment',
}

export enum ConnectorGenderType {
  Male = 'Male',
  Female = 'Female',
  None = 'None',
}

export enum RoundGeometryType {
  Standard = 'Standard',
  Blank = 'Blank',
  StepDown = 'StepDown',
  HexEnd = 'HexEnd',
  Square = 'Square',
  PushFit = 'PushFit',
}

export enum RectangularGeometryType {
  Standard = 'Standard',
  Blank = 'Blank',
  StepDown = 'StepDown',
  CouplingPlate = 'CouplingPlate',
}

export enum OvalGeometryType {
  Standard = 'Standard',
  Blank = 'Blank',
}

export enum PipeworkEndType {
  NotSet = 'NotSet',
  ButtWeld = 'ButtWeld',
  Compression = 'Compression',
  Flanged = 'Flanged',
  LinerClamped = 'LinerClamped',
  LinerNut = 'LinerNut',
  ReducingLinerNut = 'ReducingLinerNut',
  MalePart = 'MalePart',
  Plain = 'Plain',
  Screwed = 'Screwed',
  SocketWeld = 'SocketWeld',
}

export enum RoundConnectorMeasurementMode {
  OutsideDiameter = 'OutsideDiameter',
  WallThickness = 'WallThickness',
}

export enum RectangularStepDownOption {
  LinerThickness = 'LinerThickness',
  Value = 'Value',
}

export class RectangularStepDown {
  option = RectangularStepDownOption.Value;
  value = 0.0;
}

export abstract class ConnectorInfo extends ForgeContentDataElement {
  category = '';
  domain = ConnectorDomainType.NotSet;
  readonly shape: ConnectorShapeType;
  gender = ConnectorGenderType.None;
  connectivityClassification = '';

  // if this property name is changed or moved to a different tab in the UI,
  // the breakpoint table custom component needs to be updated
  abstract geometryType: RoundGeometryType | RectangularGeometryType | OvalGeometryType;

  abstract breakPointTable: ConnectorBreakPointTableRow[];

  constructor(shape: ConnectorShapeType) {
    super();

    this.shape = shape;

    this.fabricationReferences = [
      {
        dataType: DataElementType.FabricationRate,
        externalId: EnvironmentConstants.FCS_DEFAULT_CONTENT_ID_FABRICATION_RATE,
        referenceType: FabricationReferenceType.Relationship,
      },
      {
        dataType: DataElementType.InstallationRate,
        externalId: EnvironmentConstants.FCS_DEFAULT_CONTENT_ID_INSTALLATION_RATE,
        referenceType: FabricationReferenceType.Relationship,
      },
      {
        dataType: DataElementType.Connector,
        externalId: EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR,
        referenceType: FabricationReferenceType.Relationship,
      },
    ];
  }
}

export class RoundConnectorInfo extends ConnectorInfo {
  geometryType = RoundGeometryType.Standard;
  pipeworkEndType = PipeworkEndType.NotSet;
  pressureClass = '';
  breakPointTable: RoundConnectorBreakPointTableRow[] | RoundHexEndConnectorBreakPointTableRow[];
  measurementMode = RoundConnectorMeasurementMode.OutsideDiameter;

  constructor() {
    super(ConnectorShapeType.Round);

    this.breakPointTable = [new RoundConnectorBreakPointTableRow()];
  }
}

export class RectangularConnectorInfo extends ConnectorInfo {
  geometryType = RectangularGeometryType.Standard;
  connectorLead = 0.0;
  developmentTurnover = 0.0;
  stepDown: RectangularStepDown;
  breakPointTable:
    | RectangularConnectorBreakPointTableRow[]
    | RectangularStepDownConnectorBreakPointTableRow[]
    | RectangularCouplingPlateConnectorBreakPointTableRow[];

  constructor() {
    super(ConnectorShapeType.Rectangular);

    this.breakPointTable = [new RectangularConnectorBreakPointTableRow()];
    this.stepDown = new RectangularStepDown();
  }
}

export class OvalConnectorInfo extends ConnectorInfo {
  geometryType = OvalGeometryType.Standard;
  breakPointTable: OvalConnectorBreakPointTableRow[];

  constructor() {
    super(ConnectorShapeType.Oval);

    this.breakPointTable = [new OvalConnectorBreakPointTableRow()];
  }
}
