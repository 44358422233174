import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromSpecs from '../reducers/specification.reducer';

// spec list state
export const selectSpecsState = (state: FDMState) => state.configsState.specs;

// spec list selectors
export const selectSpecIds = createSelector(selectSpecsState, fromSpecs.selectSpecIds);

export const selectSpecEntities = createSelector(selectSpecsState, fromSpecs.selectSpecEntities);

export const selectAllSpecs = createSelector(selectSpecsState, fromSpecs.selectAllSpecs);

export const selectSpecsTotal = createSelector(selectSpecsState, fromSpecs.selectSpecsTotal);

export const selectSpecById = (id: string) =>
  createSelector(selectSpecEntities, (specInfoEntities) => specInfoEntities[id]);
