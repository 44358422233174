import * as fromPagingData from '../actions/paging-data.action';

export interface PagingDataState {
  pageSize: number;
}

export const initialState: PagingDataState = {
  pageSize: 5,
};

export function reducer(
  state = initialState,
  action: fromPagingData.PagingDataActions
): PagingDataState {
  switch (action.type) {
    case fromPagingData.PagingDataActionTypes.SetPageSize: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectPageSize = (state: PagingDataState) => state.pageSize;
