import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromContentFile from '../reducers/content-file.reducer';

export const selectContentFileState = (state: FDMState) => state.configsState.contentFiles;

export const selectContentFileIds = createSelector(
  selectContentFileState,
  fromContentFile.selectContentFileIds
);

export const selectContentFileEntities = createSelector(
  selectContentFileState,
  fromContentFile.selectContentFileEntities
);

export const selectAllContentFile = createSelector(
  selectContentFileState,
  fromContentFile.selectAllContentFile
);

export const selectContentFileTotal = createSelector(
  selectContentFileState,
  fromContentFile.selectContentFileTotal
);

export const selectContentFileById = (id: string) =>
  createSelector(selectContentFileEntities, (contentFileEntities) => contentFileEntities[id]);

export const selectContentFilesById = (ids: string[]) =>
  createSelector(selectContentFileEntities, (contentFileEntities) => {
    if (ids?.length) {
      return ids.map((x) => contentFileEntities[x]);
    }

    return [];
  });
