import { Action } from '@ngrx/store';

export enum DataLoadingState {
  Loading = 'Loading',
  Complete = 'Complete',
  Failed = 'Failed',
  Ignore = 'Ignore',
}

export interface DataLoadingAction {
  dataLoadingState: DataLoadingState;
}

export enum DataLoadingActionTypes {
  LoadData = '[Data Loading] Load Data',
  LoadDataFail = '[Data Loading] Load Data Fail',
  LoadDataSuccess = '[Data Loading] Load Data Succes',
}

export class LoadData implements DataLoadingAction, Action {
  dataLoadingState = DataLoadingState.Loading;
  readonly type = DataLoadingActionTypes.LoadData;
}

export class LoadDataFail implements DataLoadingAction, Action {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DataLoadingActionTypes.LoadDataFail;
}

export class LoadDataSuccess implements DataLoadingAction, Action {
  dataLoadingState = DataLoadingState.Complete;
  readonly type = DataLoadingActionTypes.LoadDataSuccess;
}

export type DataLoadingActions = LoadData | LoadDataFail | LoadDataSuccess;
