import { useContext, useMemo } from 'react';
import { AngularInjectorContext } from '../contexts/angular-injector-context';
import { ProviderToken } from '@angular/core';

export const useAngularService = <T>(service: ProviderToken<T>) => {
  const injector = useContext(AngularInjectorContext);
  return useMemo(() => {
    return injector.get(service);
  }, []);
};
