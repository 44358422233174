import * as fromApplication from '../actions/application.action';

export interface ApplicationState {
  configsLoaded: boolean;
  configsLoading: boolean;
  configsLoadingFailed: boolean;
  currentConfigExternalId: string | null;
  currentItemFileIds: string[];
  loadedItemFolders: Record<string, string[]>;
  requestedRefreshPath: string;
  initialCacheValidityCheckComplete: boolean;
  entitlementCheckComplete: boolean;
  applicationIsDisabled: boolean;
}

export const initialState: ApplicationState = {
  configsLoaded: false,
  configsLoading: false,
  configsLoadingFailed: false,
  currentConfigExternalId: null,
  currentItemFileIds: [],
  loadedItemFolders: {},
  requestedRefreshPath: null,
  initialCacheValidityCheckComplete: false,
  entitlementCheckComplete: false,
  applicationIsDisabled: false,
};

export function reducer(
  state = initialState,
  action: fromApplication.ApplicationActions
): ApplicationState {
  switch (action.type) {
    case fromApplication.ApplicationActionTypes.SetConfigsLoading: {
      return {
        ...state,
        configsLoading: action.payload,
      };
    }
    case fromApplication.ApplicationActionTypes.SetConfigsLoadingFailed: {
      return {
        ...state,
        configsLoadingFailed: action.payload,
      };
    }
    case fromApplication.ApplicationActionTypes.SetConfigsLoaded: {
      return {
        ...state,
        configsLoaded: action.payload,
      };
    }
    case fromApplication.ApplicationActionTypes.SetCurrentConfigExternalId: {
      // on change of config id we need to reset current selections
      // todo: implement all selection cleanups (need to be careful on binding failures)
      return {
        ...state,
        currentConfigExternalId: action.payload,
        currentItemFileIds: [],
      };
    }
    case fromApplication.ApplicationActionTypes.SetCurrentItemFileIds: {
      let updatedIds = [];
      if (Array.isArray(action.payload.id)) {
        const uniqueIds = new Set([...state.currentItemFileIds, ...action.payload.id]);
        updatedIds = Array.from(uniqueIds);
      } else {
        // if allow multiples add to the current array, if not replace
        // check if exists, if so remove
        const exists = state.currentItemFileIds.findIndex((x) => x === action.payload.id) >= 0;
        if (exists) {
          updatedIds = state.currentItemFileIds.filter((x) => x !== action.payload.id);
        } else {
          updatedIds = action.payload.allowMultiple
            ? [...state.currentItemFileIds, action.payload.id]
            : [action.payload.id];
        }
      }

      return {
        ...state,
        currentItemFileIds: updatedIds,
      };
    }
    case fromApplication.ApplicationActionTypes.SetCurrentItemFileIdsToEmpty: {
      return {
        ...state,
        currentItemFileIds: [],
      };
    }
    case fromApplication.ApplicationActionTypes.SkipBinaryTaskData: {
      return state;
    }

    case fromApplication.ApplicationActionTypes.SetBinaryTaskData: {
      return state;
    }

    case fromApplication.ApplicationActionTypes.SetLoadedItemFolder: {
      // add loaded item folder id
      const currentLoadedFolders = { ...state.loadedItemFolders };
      const currentConfigId = state.currentConfigExternalId;

      // check if key exists
      if (currentLoadedFolders[currentConfigId]) {
        currentLoadedFolders[currentConfigId] = !currentLoadedFolders[currentConfigId].includes(
          action.payload.itemFolderId
        ) && [action.payload.itemFolderId, ...currentLoadedFolders[currentConfigId]];
      } else {
        currentLoadedFolders[currentConfigId] = [action.payload.itemFolderId];
      }

      return {
        ...state,
        loadedItemFolders: currentLoadedFolders,
      };
    }
    case fromApplication.ApplicationActionTypes.SetRequestedRefreshPath: {
      return {
        ...state,
        requestedRefreshPath: action.payload.path,
      };
    }
    case fromApplication.ApplicationActionTypes.SetInitialCacheValidityCheckComplete: {
      return {
        ...state,
        initialCacheValidityCheckComplete: action.payload.complete,
      };
    }
    case fromApplication.ApplicationActionTypes.SetEntitlementCheckComplete: {
      return {
        ...state,
        entitlementCheckComplete: action.payload.complete,
      };
    }
    case fromApplication.ApplicationActionTypes.SetApplicationIsDisabled: {
      return {
        ...state,
        applicationIsDisabled: action.payload.disabled,
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectConfigsLoading = (state: ApplicationState) => state.configsLoading;
export const selectConfigsLoadingFailed = (state: ApplicationState) => state.configsLoadingFailed;
export const selectConfigsLoaded = (state: ApplicationState) => state.configsLoaded;
export const selectCurrentConfigExternalId = (state: ApplicationState) =>
  state.currentConfigExternalId;
export const selectCurrentItemFileIds = (state: ApplicationState) => state.currentItemFileIds;
export const selectLoadedItemFolders = (state: ApplicationState) => state.loadedItemFolders;
export const selectRequestedRefreshpath = (state: ApplicationState) => state.requestedRefreshPath;
export const selectInitialCacheValidityCheckComplete = (state: ApplicationState) =>
  state.initialCacheValidityCheckComplete;
export const selectEntitlementheckComplete = (state: ApplicationState) =>
  state.entitlementCheckComplete;
export const selectApplicationIsDisabled = (state: ApplicationState) => state.applicationIsDisabled;
