import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import {
  DynamicTableOptions,
  ColumnDefinition,
  DataElementListTableOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { InvalidData } from '@models/fabrication/invalid-data';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { selectCurrentConfig, selectCurrentInvalidData } from '@store/selectors/configs.selectors';
import {
  selectInternalInvalidData,
  selectInvalidDataById,
} from '@store/selectors/invalid-data.selectors';
import {
  LoadInvalidData,
  LoadInvalidDataSuccess,
  DeleteInvalidDataSuccess,
  DeleteInvalidData,
  AddInvalidDataSuccess,
} from '@store/actions/invalid-data.action';
import { UpdateConfigInvalidDataIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { map, take } from 'rxjs/operators';
import { FabricationReference } from '@models/forge-content/references';
import { ToolBarButtonType } from '@models/tool-bar/tool-bar-options';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { EnvironmentService } from '@services/environment.service';
import helpLinks from '@assets/help/help-links.json';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { SchemaService } from '@services/schema.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class DynamicInvalidDataSetup extends DynamicDataElementTypeSetup<InvalidData> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<InvalidData>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.admin.invalidData;
  }

  setupOptions() {
    this.options = {
      dataType: DataElementType.InvalidData,
      dependentDataTypes: [],
      createNewInstance: () => null,
      sortFields: ['name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: () => this.store$.select(selectCurrentInvalidData),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectInvalidDataById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadInvalidData({ config })),
        loadSuccessAction: () => new LoadInvalidDataSuccess(),
        deleteDataSuccessAction: () => new DeleteInvalidDataSuccess(),
        addDataSuccessAction: () => new AddInvalidDataSuccess(),
        updateDataSuccessAction: () => null,
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigInvalidDataIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: null,
        editModelAction: null,
        copyModelAction: null,
        deleteModelsAction: (models: InvalidData[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new DeleteInvalidData({ config, dataElements: models, oldPath: models[0].path }) // path only exists on parts
              )
            );
        },
        fixModelAction: null,
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_INVALID_DATA,
        schemas: [
          {
            dataType: DataElementType.InvalidData,
            schema: {
              namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
              version: EnvironmentConstants.FSS_SCHEMA_INVALID_DATA_VERSION,
              type: EnvironmentConstants.FSS_SCHEMA_INVALID_DATA,
            },
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<InvalidData>> {
    const columns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.NAME),
        icon: true,
        visible: true,
        link: {
          field: 'id',
          condition: (row: InvalidData) => row && this.isInvalidDataFixable(row),
          details: ['fix'],
        },
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
        formatter: (errors, row: InvalidData) => {
          const category = (row as any)?.category;
          if (category) {
            return category;
          }

          const dataType = DataElementTypeUtils.getDataTypeFromSchema(row.schemaType);
          if (dataType === DataElementType.Part) {
            return this.translate.instant(LC.DYNAMIC_TABLE.NA);
          }

          return this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED);
        },
        visible: true,
      },
      {
        field: 'schemaType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.DATA_TYPE),
        formatter: (value: string) =>
          DataElementTypeUtils.getStartCase(
            this.translate,
            DataElementTypeUtils.getDataTypeFromSchema(value),
            false
          ),
        visible: true,
      },
      {
        field: 'errors',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ERROR),
        formatter: (args: any, row: InvalidData) => this.getErrorMessage(row),
        visible: true,
      },
      {
        field: 'isEditable', // field doesn't exist
        header: this.translate.instant(LC.ERROR_HANDLING.GENERIC.EDITABLE),
        formatter: (value: any, row: InvalidData) =>
          this.isInvalidDataFixable(row)
            ? this.translate.instant(LC.ERROR_HANDLING.GENERIC.YES)
            : this.translate.instant(LC.ERROR_HANDLING.GENERIC.NOT_YET),
        visible: true,
      },
    ];

    const options = this.createDynamicTableOptions(columns);
    (options.tableTypeOptions as DataElementListTableOptions<InvalidData>).toolbarButtonShouldHide =
      (model, buttonType) => this.toolbarButtonShouldHide(model, buttonType);

    return of(options);
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<InvalidData>> {
    const uniqueFieldRestrictions = ['name'];

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: InvalidData) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          formStyle: DynamicFormStyle.SIMPLE,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'value'],
      isReplaceable: true,
      isRemovable: false,
      isEditable: false,
      upstreamReferenceDataTypes: () => [],
      isDownstreamRefBlocked: () => true,
    };
  }

  private getErrorMessage(row: InvalidData): string {
    const dataType = DataElementTypeUtils.getDataTypeFromSchema(row.schemaType);
    const setup = this.dynamicDataService.getDynamicDataSetupForType(dataType);

    return setup.getInvalidDataErrors(row);
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  toolbarButtonShouldHide(model: InvalidData, toolbarButtonType: ToolBarButtonType): boolean {
    if (!model?.schemaType) {
      return true;
    }

    // only needs to be applied for the fix toolbar
    if (toolbarButtonType !== ToolBarButtonType.FIX) {
      return false;
    }

    const dataType = DataElementTypeUtils.getDataTypeFromSchema(model.schemaType);
    const dataSetup = this.dynamicDataService.getDynamicDataSetupForType(dataType);
    return !dataSetup.isFixable(model);
  }

  getIconName(): string {
    return null;
  }

  isFixable(): boolean {
    return false;
  }

  getInvalidDataErrors(): string {
    return null;
  }

  requiresBinaryUpgrade(/*dataElement: InvalidData*/): boolean {
    return false;
  }

  private isInvalidDataFixable(row: InvalidData): boolean {
    const dataType = DataElementTypeUtils.getDataTypeFromSchema(row.schemaType);
    const dataSetup = this.dynamicDataService.getDynamicDataSetupForType(dataType);

    return dataSetup.isFixable(row);
  }
}
