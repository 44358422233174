import { BinaryActivityMarker } from '@models/activities-events/activities';
import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromBinaryActivity from '../reducers/binary-activity.reducer';

export const selectBinaryActivityState = (state: FDMState) =>
  state.applicationState.binaryActivityMarkers;

export const selectBinaryActivityIds = createSelector(
  selectBinaryActivityState,
  fromBinaryActivity.selectBinaryActivityIds
);

export const selectBinaryActivityEntities = createSelector(
  selectBinaryActivityState,
  fromBinaryActivity.selectBinaryActivityEntities
);

export const selectAllBinaryActivity = createSelector(
  selectBinaryActivityState,
  fromBinaryActivity.selectAllBinaryActivity
);

export const selectBinaryActivityTotal = createSelector(
  selectBinaryActivityState,
  fromBinaryActivity.selectBinaryActivityTotal
);

export const selectBinaryActiviesByConfigExternalId = (id: string) =>
  createSelector(selectAllBinaryActivity, (allBinaryActivities): BinaryActivityMarker[] =>
    allBinaryActivities.filter((x) => x.configExternalId === id)
  );

export const selectBinaryActivitesByConfigUrn = (urn: string) =>
  createSelector(selectAllBinaryActivity, (allBinaryActivities): BinaryActivityMarker[] =>
    allBinaryActivities.filter((x) => x.configUrn === urn)
  );
