export abstract class ConnectorBreakPointTableRow {}

export class RoundConnectorBreakPointTableRow extends ConnectorBreakPointTableRow {
  // shape is round and geometry type IS NOT hex end
  diameter = 0.0;
  extension = 0.0;
  snapOffset = 0.0;
  bodyThickness = 0.0;
  bodyLength = 0.0;
  bodySetBack = 0.0;
  swageSetBack = 0.0;
  swageLength = 0.0;
}

export class RoundHexEndConnectorBreakPointTableRow extends ConnectorBreakPointTableRow {
  // shape is round and geometry type IS hex end
  diameter = 0.0;
  extension = 0.0;
  snapOffset = 0.0;
  hexHeight = 0.0;
  hexLength = 0.0;
  hexOffset = 0.0;
}

export class RectangularConnectorBreakPointTableRow extends ConnectorBreakPointTableRow {
  // shape is rectangular and geometry type IS NOT step down or coupling plate
  longSide = 0.0;
  shortSide = 0.0;
  gasket = 0.0;
  line1 = 0.0;
  line2 = 0.0;
  line3 = 0.0;
  line4 = 0.0;
}

export class RectangularStepDownConnectorBreakPointTableRow extends ConnectorBreakPointTableRow {
  // shape is rectangular and geometry type IS step down
  longSide = 0.0;
  shortSide = 0.0;
  gasket = 0.0;
  line1 = 0.0;
  line2 = 0.0;
  extension = 0.0;
}

export enum ConnectorBreakPointTableSidesType {
  None = 'None',
  Left = 'Left',
  Top = 'Top',
  LeftAndTop = 'LeftAndTop',
  Right = 'Right',
  Horizontal = 'Horizontal',
  TopAndRight = 'TopAndRight',
  LeftAndTopAndRight = 'LeftAndTopAndRight',
  Bottom = 'Bottom',
  LeftAndBottom = 'LeftAndBottom',
  Vertical = 'Vertical',
  LeftAndTopAndBottom = 'LeftAndTopAndBottom',
  RightAndBottom = 'RightAndBottom',
  LeftAndRightAndBottom = 'LeftAndRightAndBottom',
  TopAndRightAndBottom = 'TopAndRightAndBottom',
  All = 'All',
}

export class RectangularCouplingPlateConnectorBreakPointTableRow extends ConnectorBreakPointTableRow {
  // shape is rectangular and geometry type IS coupling plate
  longSide = 0.0;
  shortSide = 0.0;
  gasket = 0.0;
  sides = ConnectorBreakPointTableSidesType.None;
  horizontalInset = 0.0;
  length = 0.0;
  thickness = 0.0;
  verticalWidth = 0.0;
}

export class OvalConnectorBreakPointTableRow extends ConnectorBreakPointTableRow {
  // shape is oval
  width = 0.0;
  depth = 0.0;
  extension = 0.0;
  snapOffset = 0.0;
  bodyThickness = 0.0;
  bodyLength = 0.0;
  bodySetBack = 0.0;
  swageSetBack = 0.0;
  swageLength = 0.0;
}
