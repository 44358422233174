import { Injectable } from '@angular/core';
import {
  ColumnDefinition,
  ColumnHeaderChange,
  SelectionRows,
} from '@models/dynamic-table/dynamic-table-options';
import { PartDimensionInfo } from '@models/fabrication/part';
import { InlineEditDataCellChangeEvent } from '@models/inline-edit/inline-edit.options';
import {
  FABDataGridRow,
  FABDataGridRowSelectEvent,
} from '@shared/react/components/basic/data-grid/data-grid.types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class DynamicTableChangeService<T1> {
  private tableRowSelectSubject: BehaviorSubject<FABDataGridRowSelectEvent> =
    new BehaviorSubject<FABDataGridRowSelectEvent>(null);

  private tableChangeSubject: Subject<InlineEditDataCellChangeEvent> =
    new Subject<InlineEditDataCellChangeEvent>();

  private tableCellChangeSubject: Subject<InlineEditDataCellChangeEvent> =
    new Subject<InlineEditDataCellChangeEvent>();

  private forceFullValidationSubject: Subject<void> = new Subject<void>();

  private sameValueColumnDataChangeSubject: Subject<ColumnDefinition> =
    new Subject<ColumnDefinition>();

  private revertMoreOptionsSubject: Subject<void> = new Subject<void>();

  private updatePartDimColumnHeaderSubject: Subject<PartDimensionInfo> =
    new Subject<PartDimensionInfo>();

  private updateColumnHeaderSubject: Subject<ColumnHeaderChange> =
    new Subject<ColumnHeaderChange>();

  private updateMoreOptionsSubject: Subject<void> = new Subject<void>();

  private tableRowsSelectSubject: Subject<SelectionRows<T1>> = new Subject<SelectionRows<T1>>();

  public selectedRow: T1;

  public submitTableCellValueChange(changeEvent: InlineEditDataCellChangeEvent): void {
    this.tableChangeSubject.next(changeEvent);
  }

  public stopAllListeners(): void {
    this.tableChangeSubject.complete();
    this.tableRowSelectSubject.complete();
    this.tableRowsSelectSubject.complete();
    this.forceFullValidationSubject.complete();
    this.tableCellChangeSubject.complete();
    this.sameValueColumnDataChangeSubject.complete();
    this.revertMoreOptionsSubject.complete();
    this.updatePartDimColumnHeaderSubject.complete();
    this.updateColumnHeaderSubject.complete();
    this.updateMoreOptionsSubject.complete();
  }

  public listenToTableCellValueChanges(
    tableId: string,
    field?: string,
    allChanges?: boolean
  ): Observable<InlineEditDataCellChangeEvent> {
    return this.tableChangeSubject.pipe(
      filter(
        (changeEvent: InlineEditDataCellChangeEvent) =>
          allChanges ||
          (changeEvent.inlineData.tableId === tableId && field === changeEvent.inlineData.field)
      )
    );
  }

  public listenToTableRowSelection(): Observable<FABDataGridRowSelectEvent> {
    return this.tableRowSelectSubject.pipe();
  }

  public getSelectedRow(): FABDataGridRow {
    return this.tableRowSelectSubject.value?.row;
  }

  public submitTableRowSelection(rowSelectEvent: FABDataGridRowSelectEvent): void {
    this.tableRowSelectSubject.next(rowSelectEvent);
  }

  public listenToTableRowsSelection(): Observable<SelectionRows<T1>> {
    return this.tableRowsSelectSubject.pipe();
  }

  public submitTableRowsSelection(selectedRows: SelectionRows<T1>): void {
    this.tableRowsSelectSubject.next(selectedRows);
  }

  public listenToForceFullValidation(): Observable<void> {
    return this.forceFullValidationSubject.pipe();
  }

  public submitForceFullValidation(): void {
    this.forceFullValidationSubject.next();
  }

  public listenToUpdateColumnHeader(): Observable<ColumnHeaderChange> {
    return this.updateColumnHeaderSubject.pipe();
  }

  public submitUpdateColumnHeader(changes: ColumnHeaderChange) {
    this.updateColumnHeaderSubject.next(changes);
  }
}
