import { SystemInfo } from '@models/fabrication/system-info';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementSuccessAction,
  UpdateDataElementFailAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  DeleteDataElementsSuccessAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum SystemInfoActionTypes {
  LoadSystemInfos = '[System Info API] Load SystemInfos',
  LoadSystemInfosFail = '[System Info API] Load SystemInfos Fail',
  LoadSystemInfosSuccess = '[System Info API] Load SystemInfos Success',
  UpdateSystemInfo = '[System Info API] Update SystemInfo',
  UpdateSystemInfoFail = '[System Info API] Update SystemInfo Fail',
  UpdateSystemInfoSuccess = '[Systems Info API] Update SystemInfo Success',
  AddSystemInfo = '[System Info API] Add SystemInfo',
  AddSystemInfoFail = '[System Info API] Add SystemInfo Fail',
  AddSystemInfoSuccess = '[Systems Info API] Add SystemInfo Success',
  DeleteSystemInfos = '[System Info API] Delete SystemInfos',
  DeleteSystemInfosFail = '[System Info API] Delete SystemInfos Fail',
  DeleteSystemInfosSuccess = '[Systems Info API] Delete SystemInfos Success',
  CopySystemInfo = '[System Info API] Copy SystemInfo',
  CopySystemInfoFail = '[System Info API] Copy SystemInfo Fail',
  CopySystemInfoSuccess = '[Systems Info API] Copy SystemInfo Success',
}

export class LoadSystemInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SystemInfoActionTypes.LoadSystemInfosFail;
}

export class LoadSystemInfosSuccess implements LoadDataElementsSuccessAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Complete;
  data: SystemInfo[];
  readonly type = SystemInfoActionTypes.LoadSystemInfosSuccess;
}

export class LoadSystemInfos implements LoadDataElementsAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadSystemInfosSuccess();
  failAction = new LoadSystemInfosFail();
  readonly type = SystemInfoActionTypes.LoadSystemInfos;
  dataElementType = DataElementType.Service;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SYSTEMINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class UpdateSystemInfo implements UpdateDataElementAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: SystemInfo;
  successAction = new UpdateSystemInfoSuccess();
  failAction = new UpdateSystemInfoFail();
  readonly type = SystemInfoActionTypes.UpdateSystemInfo;
  dataElementType = DataElementType.Service;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SYSTEMINFOS;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: SystemInfo }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateSystemInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SystemInfoActionTypes.UpdateSystemInfoFail;
}

export class UpdateSystemInfoSuccess implements UpdateDataElementSuccessAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: SystemInfo;
  readonly type = SystemInfoActionTypes.UpdateSystemInfoSuccess;
}

export class AddSystemInfo implements AddDataElementAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: SystemInfo;
  successAction = new AddSystemInfoSuccess();
  failAction = new AddSystemInfoFail();
  dataElementType = DataElementType.Service;
  skipBinaryTask = false;
  readonly type = SystemInfoActionTypes.AddSystemInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SYSTEMINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: SystemInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopySystemInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SystemInfoActionTypes.CopySystemInfoFail;
}

export class CopySystemInfoSuccess implements CopyDataElementSuccessAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: SystemInfo;
  readonly type = SystemInfoActionTypes.CopySystemInfoSuccess;
}

export class CopySystemInfo implements CopyDataElementAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: SystemInfo;
  successAction = new CopySystemInfoSuccess();
  failAction = new CopySystemInfoFail();
  dataElementType = DataElementType.Service;
  skipBinaryTask = false;
  readonly type = SystemInfoActionTypes.CopySystemInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SYSTEMINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: SystemInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class AddSystemInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SystemInfoActionTypes.AddSystemInfoFail;
}

export class AddSystemInfoSuccess implements AddDataElementSuccessAction<SystemInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: SystemInfo;
  readonly type = SystemInfoActionTypes.AddSystemInfoSuccess;
}

export class DeleteSystemInfos implements DeleteDataElementsAction<SystemInfo> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: SystemInfo[];
  successAction = new DeleteSystemInfoSuccess();
  failAction = new DeleteSystemInfosFail();
  dataElementType = DataElementType.Service;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SYSTEMINFOS;
  isExternalNodeId = true;
  readonly type = SystemInfoActionTypes.DeleteSystemInfos;

  constructor(public payload: { config: Config; dataElements: SystemInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteSystemInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = SystemInfoActionTypes.DeleteSystemInfosFail;
}

export class DeleteSystemInfoSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  dataElementType = DataElementType.Service;
  config: Config;
  readonly type = SystemInfoActionTypes.DeleteSystemInfosSuccess;
}

export type SystemInfoActions =
  | LoadSystemInfos
  | LoadSystemInfosFail
  | LoadSystemInfosSuccess
  | UpdateSystemInfo
  | UpdateSystemInfoFail
  | UpdateSystemInfoSuccess
  | AddSystemInfo
  | AddSystemInfoFail
  | AddSystemInfoSuccess
  | DeleteSystemInfos
  | DeleteSystemInfosFail
  | DeleteSystemInfoSuccess
  | CopySystemInfo
  | CopySystemInfoFail
  | CopySystemInfoSuccess;
