export enum DataElementType {
  Service = 'Service',
  Connector = 'Connector',
  Damper = 'Damper',
  Stiffener = 'Stiffener',
  Specification = 'Specification',
  InsulationSpecification = 'InsulationSpecification',
  ServiceTemplate = 'PartTemplate', // keep as PartTemplate
  Part = 'Part',
  DBFile = 'DBFile',
  FabricationRate = 'FabricationRate',
  InstallationRate = 'InstallationRate',
  Material = 'Material',
  MaterialSpecification = 'MaterialSpecification',
  InvalidData = 'InvalidData',
  Permissions = 'Permissions',
  StiffenerSpecification = 'StiffenerSpecification',
  Image = 'Image',
}
