import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'fab-logout',
  template: '',
})
// explicit logout component to vist prior to logging out of the app
// rationale - instead of forcing the browser to hit the server logout route
// which will clear the refresh token cookie, hitting an app route will enable
// guards to be called such as navigateAway, which will prompt to save data changes if required.
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    setTimeout(() => this.authService.navigateToSignOutWindow(), 0);
  }
}
