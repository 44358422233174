import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DynamicFormChangeService {
  private _forceDisableApplyButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private _invalidComponentsSubject: BehaviorSubject<Set<string>> = new BehaviorSubject<
    Set<string>
  >(new Set());

  public get forceDisableApplyButtonValue() {
    return this._forceDisableApplyButtonSubject.getValue();
  }

  public submitForceDisableApplyButton(disable: boolean): void {
    this._forceDisableApplyButtonSubject.next(disable);
  }

  public listenToForceDisableApplyButtonChanges(): Observable<boolean> {
    return this._forceDisableApplyButtonSubject.pipe();
  }

  public updateInvalidComponent(component: string, invalid: boolean): void {
    const invalidComponents = this._invalidComponentsSubject.value;
    invalid ? invalidComponents.add(component) : invalidComponents.delete(component);
    this._invalidComponentsSubject.next(invalidComponents);
  }

  public clearInvalidComponents(): void {
    const invalidComponents = this._invalidComponentsSubject.value;
    invalidComponents.clear();
    this._invalidComponentsSubject.next(invalidComponents);
  }

  public listenToHasInvalidComponents(): Observable<boolean> {
    return this._invalidComponentsSubject.pipe(map((value) => value.size > 0));
  }
}
