import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

// angular needs special handling for wrappers around components
// that are written in React instead of being Angular-Material components
export const REACT_FIELD_TYPES = new Set(['formly-text-field']);

@Component({
  selector: 'tooltip-wrapper-field',
  template: `
    <fab-tooltip
      type="template"
      [title]="props.tooltip.title"
      [content]="props.tooltip.content"
      [disableTooltip]="!props.tooltip.title && !props.tooltip.content"
    >
      <ng-container #fieldComponent></ng-container>
    </fab-tooltip>
  `,
})
export class TooltipFieldComponent extends FieldWrapper {}
