import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap, retry, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DynamicAppDependencyService } from '@services/dynamic-app-dependency.service';
import { throwError, of, Observable } from 'rxjs';
import { FDMState } from '@store/reducers/index';
import {
  LoadAppDependencyFail,
  AppDependencyActionTypes,
  LoadAppDependency,
  SetLoadingAppDependency,
  SetFailedAppDependency,
  LoadAppDependencySuccess,
  SetLoadedAppDependency,
} from '../actions/app-dependency.action';

@Injectable()
export class AppDependencyEffects {
  loadAppDependency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppDependencyActionTypes.LoadAppDependency),
      tap((action: LoadAppDependency) => {
        // set app dependency to loading state
        this.store$.dispatch(
          new SetLoadingAppDependency({
            appDependencyType: action.payload.appDependencyType,
          })
        );

        // remove from failed state if present
        this.store$.dispatch(
          new SetFailedAppDependency({
            appDependencyType: action.payload.appDependencyType,
            remove: true,
          })
        );
      }),
      mergeMap((action: LoadAppDependency) => {
        const retryCount = 3;
        let retryAttempt = 0;
        const appDependencyType = action.payload.appDependencyType;
        const appDependency =
          this.dynamicAppDependencyService.getDynamicAppDependencyForType(appDependencyType);

        return appDependency.initialise().pipe(
          switchMap((initialised: boolean) => {
            let returnAction: Observable<LoadAppDependencySuccess | LoadAppDependencyFail>;

            if (!initialised) {
              if (retryAttempt < retryCount) {
                retryAttempt++;
                // force retry
                return throwError(() => 'Error initialising app dependency');
              } else {
                returnAction = of(
                  new LoadAppDependencyFail({
                    appDependencyType,
                  })
                );
              }
            } else {
              returnAction = of(
                new LoadAppDependencySuccess({
                  appDependencyType,
                })
              );
            }

            return returnAction;
          }),
          retry(retryCount)
        );
      })
    )
  );

  loadAppDependencySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppDependencyActionTypes.LoadAppDependencySuccess),
      mergeMap((action: LoadAppDependencySuccess) => {
        const appDependencyType = action.payload.appDependencyType;
        return [
          new SetLoadedAppDependency({
            appDependencyType,
          }),
          new SetLoadingAppDependency({
            appDependencyType,
            remove: true,
          }),
          new SetFailedAppDependency({
            appDependencyType,
            remove: true,
          }),
        ];
      })
    )
  );

  loadAppDependencyFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppDependencyActionTypes.LoadAppDependencyFail),
      mergeMap((action: LoadAppDependencyFail) => {
        const appDependencyType = action.payload.appDependencyType;
        return [
          new SetFailedAppDependency({
            appDependencyType,
          }),
          new SetLoadingAppDependency({
            appDependencyType,
            remove: true,
          }),
        ];
      })
    )
  );

  constructor(
    private store$: Store<FDMState>,
    private actions$: Actions,
    private dynamicAppDependencyService: DynamicAppDependencyService
  ) {}
}
