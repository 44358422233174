import { resolvedRoles } from '@weave-design/theme-data/build/esm/lightGrayMediumDensityTheme';

const themeData = {
  metadata: {
    colorSchemeName: 'FDM Light',
    colorSchemeId: 'fdm-light-theme',
    className: 'fdm--light--theme',
    densityId: 'medium-density',
    densityName: 'Medium density',
    name: 'FDM Light, Medium density',
    fileName: 'FdmLightTheme',
    id: 'fdm-light-medium-density',
  },
  resolvedRoles: {
    ...resolvedRoles,
  },
};

export default themeData;
