import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { CookieService } from 'ngx-cookie-service';
import { ResilientHttpService } from '@services/http/resilient-http.service';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { Config } from '@models/fabrication/config';

export enum BinaryTaskAction {
  Add = 'add',
  Update = 'update',
  Delete = 'delete',
  Copy = 'copy',
}

@Injectable({ providedIn: 'root' })
export class BinaryTaskManagerService {
  public constructor(
    private httpService: ResilientHttpService,
    private envService: EnvironmentService,
    private cookieService: CookieService
  ) {}

  public submitBinaryTask(
    config: Config,
    entityId: string,
    entityType: string,
    action: BinaryTaskAction,
    externalIds: string[],
    schemaId: string,
    jobType: BinaryJobType,
    oldPath: string,
    updatedReferenceContentIds: string[]
  ): Observable<boolean> {
    // don't send the binary task if we are doing e2e testing.
    if (this.cookieService.check('fabdm-e2e')) {
      return of(true);
    }

    const body: any = {
      collectionId: config.collectionId,
      libraryId: config.id,
      entityId,
      entityType,
      action,
      externalIds,
      schemaId,
      jobType,
      oldPath,
      updatedReferenceContentIds,
    };

    const url = `${this.envService.environment.binaryTaskManagerEndpointV2}?ngsw-bypass=true`;

    return this.httpService
      .post(url, {
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }
}
