import { InstallationRate } from '@models/fabrication/labour-rate';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum InstallationRateActionTypes {
  LoadInstallationRates = '[Installation Rates API] Load Installation Rates',
  LoadInstallationRatesFail = '[Installation Rates API] Load Installation Rates Fail',
  LoadInstallationRatesSuccess = '[Installation Rates API] Load Installation Rates Success',
  LoadInstallationRateReferences = '[Installation Rates API] Load Installation Rate References',
}

export class LoadInstallationRatesFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InstallationRateActionTypes.LoadInstallationRatesFail;
}

export class LoadInstallationRatesSuccess
  implements LoadDataElementsSuccessAction<InstallationRate>
{
  dataLoadingState = DataLoadingState.Complete;
  data: InstallationRate[];
  readonly type = InstallationRateActionTypes.LoadInstallationRatesSuccess;
}

export class LoadInstallationRates implements LoadDataElementsAction<InstallationRate> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadInstallationRatesSuccess();
  failAction = new LoadInstallationRatesFail();
  readonly type = InstallationRateActionTypes.LoadInstallationRates;
  dataElementType = DataElementType.InstallationRate;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INSTALLATION_RATES;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export type InstallationRateActions =
  | LoadInstallationRates
  | LoadInstallationRatesFail
  | LoadInstallationRatesSuccess;
