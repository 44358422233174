import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { Toast } from '@models/ui-state/weave-notification';
import { TranslateService } from '@ngx-translate/core';
import { useAngularService } from '@shared/react/hooks/useAngularService';
import Box from '@weave-mui/box';
import NotificationToast from '@weave-mui/notification-toast';
import Link from '@weave-mui/link';
import { AlertM, ErrorM, CompleteM, InfoM } from '@weave-mui/icons-weave';
import { useEffect, useState } from 'react';

const iconMap = {
  info: <InfoM />,
  success: <CompleteM />,
  warning: <AlertM />,
  error: <ErrorM />,
};

interface FABNotificationToastProps {
  toast: Toast;
  onDismissToast: (id: string) => void;
}

export const FABNotificationToast: React.FC<FABNotificationToastProps> = (props) => {
  const { toast, onDismissToast } = props;
  const translate = useAngularService(TranslateService);
  const [timestamp, setTimestamp] = useState<string | null>(
    toast.timestamp
      ? DynamicDataElementTypeSetup.convertUtcToRelativeTime(toast.timestamp, translate.currentLang)
      : null
  );

  useEffect(() => {
    if (!toast.timestamp) return undefined;

    const intervalId = setInterval(() => {
      setTimestamp(
        DynamicDataElementTypeSetup.convertUtcToRelativeTime(toast.timestamp, translate.currentLang)
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [toast.timestamp, translate.currentLang]);

  return (
    <NotificationToast
      className="notification-toast"
      onClose={() => onDismissToast(toast.id)}
      key={toast.id}
      severity={toast.status}
      icon={iconMap[toast.status]}
      timestamp={timestamp}
    >
      <Box display="flex" flexDirection="column">
        <strong>{toast.message}</strong>
        <span>{toast.description}</span>

        {toast.link && (
          <Link
            href="#"
            onClick={(event) => {
              event.preventDefault();
              toast.link.action();
            }}
          >
            {toast.link.text}
          </Link>
        )}
      </Box>
    </NotificationToast>
  );
};
