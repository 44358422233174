import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromTempModel from '../reducers/temp-model.reducer';

export const selectTempModelState = (state: FDMState) => state.applicationState.tempModels;

export const selectTempModelIds = createSelector(
  selectTempModelState,
  fromTempModel.selectTempModelIds
);

export const selectTempModelEntities = createSelector(
  selectTempModelState,
  fromTempModel.selectTempModelEntities
);

export const selectAllTempModel = createSelector(
  selectTempModelState,
  fromTempModel.selectAllTempModel
);

export const selectTempModelTotal = createSelector(
  selectTempModelState,
  fromTempModel.selectTempModelTotal
);

export const selectTempModelById = (id: string) =>
  createSelector(selectTempModelEntities, (TempModelEntities) => TempModelEntities[id]);
