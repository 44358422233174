import { Injectable, Inject } from '@angular/core';
import { DataElementType } from '@constants/data-element-types';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { dynamicDataSetupToken } from '@data-management/dynamic-data-setup';
import { InvalidData } from '@models/fabrication/invalid-data';
import { take } from 'rxjs/operators';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { DataElementSchema, SubSchema } from '@models/forge-content/forge-content-schema';
import { SchemaService } from './schema.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicDataService {
  constructor(
    @Inject(dynamicDataSetupToken)
    private dynamicDataSetupProviders: DynamicDataElementTypeSetup<any>[],
    schemaService: SchemaService
  ) {
    dynamicDataSetupProviders.forEach((x: DynamicDataElementTypeSetup<any>) => {
      x.dynamicDataService = this;
    });
    schemaService.dynamicDataService = this;
  }

  getDynamicDataSetupForType(
    dataType: DataElementType,
    invalidDataElementId = null
  ): DynamicDataElementTypeSetup<any> | null {
    if (dataType === DataElementType.InvalidData && invalidDataElementId) {
      return this.getInternalDynamicInvalidDataSetup(invalidDataElementId);
    }

    return this.dynamicDataSetupProviders.find((x) => x.options.dataType === dataType);
  }

  /**
   * Gets all of the schema and sub-schema info from the dynamic data providers
   * @returns [DataElementSchema[], SubSchema[]]
   */
  getAllSchemas(): [DataElementSchema[], SubSchema[]] {
    const allSchemas: DataElementSchema[] = [];
    const allSubSchemas: SubSchema[] = [];
    this.dynamicDataSetupProviders.forEach((dataSetup) => {
      const schemas = dataSetup?.options?.fcs?.schemas;
      if (schemas?.length) {
        schemas.forEach((schema) => allSchemas.push(schema));
      }

      const subSchemas = dataSetup?.options?.fcs?.subSchemas;
      if (subSchemas?.length) {
        subSchemas.forEach((schema) => allSubSchemas.push(schema));
      }
    });

    return [allSchemas, allSubSchemas];
  }

  private getInternalDynamicInvalidDataSetup(
    dataElementId: string
  ): DynamicDataElementTypeSetup<any> | null {
    let fixDataType: DataElementType;
    const invalidDataSetup = this.getDynamicDataSetupForType(DataElementType.InvalidData);
    invalidDataSetup.options.selectors
      .selectById(dataElementId)
      .pipe(take(1))
      .subscribe(
        (model: InvalidData) =>
          (fixDataType = DataElementTypeUtils.getDataTypeFromSchema(model.schemaType))
      );

    return this.getDynamicDataSetupForType(fixDataType);
  }
}
