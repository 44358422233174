import { Injectable } from '@angular/core';
import { SchemaErrorType } from '@models/fabrication/schema-error-type';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { ValidatorService } from './validator.service';
import { ErrorObject, Schema } from 'ajv';
import {
  InvalidData,
  InvalidDataSchemaErrorReason,
  ParseInvalidDataErrorResult,
} from '@models/fabrication/invalid-data';

interface ErrorData {
  message: string;
  attribute: string;
  errorType: SchemaErrorType;
  fullPath?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InvalidDataErrorService<T extends ForgeContentDataElement> {
  constructor(private validatorService: ValidatorService) {}

  parseErrors(model: T & InvalidData, schema: Schema): ParseInvalidDataErrorResult[] {
    this.validatorService.ajv.validate(schema, model);
    const errors = this.validatorService.ajv.errors?.filter(
      (x) => x.keyword !== 'additionalProperties'
    );

    let results: ParseInvalidDataErrorResult[] = [];
    errors.forEach((error: ErrorObject) => {
      results = results.concat(this.parseError(error));
    });

    // remove any duplicates
    return results.reduce(
      (unique: ParseInvalidDataErrorResult[], item: ParseInvalidDataErrorResult) => {
        const index = unique.findIndex(
          (x) =>
            x.attribute === item.attribute &&
            x.reason === item.reason &&
            x.fullPath === item.fullPath
        );
        return index >= 0 ? unique : [...unique, item];
      },
      []
    );
  }

  private parseError(error: ErrorObject): ParseInvalidDataErrorResult {
    const errorData = this.getErrorResult(error);

    const result: ParseInvalidDataErrorResult = {
      reason: InvalidDataSchemaErrorReason.Unknown,
      attribute: errorData.attribute,
      fullPath: errorData.fullPath,
      schemaErrorType: errorData.errorType,
    };

    switch (errorData.errorType) {
      default:
        result.reason = InvalidDataSchemaErrorReason.Unknown;
        break;

      case SchemaErrorType.minimum:
        result.reason = InvalidDataSchemaErrorReason.NumberLessThanMinimum;
        break;

      case SchemaErrorType.maximum:
        result.reason = InvalidDataSchemaErrorReason.NumberMoreThanMaximum;
        break;

      case SchemaErrorType.pattern:
        if (errorData.attribute === 'contentExternalId') {
          result.reason = InvalidDataSchemaErrorReason.InvalidPart;
        } else if (errorData.attribute === 'sizeRestrictionId') {
          result.reason = InvalidDataSchemaErrorReason.InvalidSizeRestriction;
        } else {
          result.reason = InvalidDataSchemaErrorReason.InvalidReference;
        }
        break;

      case SchemaErrorType.minimumItems:
        result.reason = InvalidDataSchemaErrorReason.ArrayTooShort;
        break;

      case SchemaErrorType.required:
        result.reason = InvalidDataSchemaErrorReason.Required;
        break;
    }

    return result;
  }

  private getErrorResult(error: ErrorObject): ErrorData {
    if ((error.keyword as SchemaErrorType) === 'required') {
      return {
        message: error.message,
        attribute: error.params.missingProperty,
        errorType: error.keyword as SchemaErrorType,
        fullPath: error.instancePath,
      };
    }

    const lastIndex = error.instancePath.lastIndexOf('/');
    if (lastIndex < 0) {
      return {
        message: error.message,
        attribute: error.instancePath,
        errorType: (error.keyword as SchemaErrorType) || SchemaErrorType.unknown,
        fullPath: error.instancePath,
      };
    }

    return {
      message: error.message,
      attribute: error.instancePath.substring(lastIndex + 1),
      errorType: (error.keyword as SchemaErrorType) || SchemaErrorType.unknown,
      fullPath: error.instancePath,
    };
  }
}
