import { Component, OnInit, OnDestroy } from '@angular/core';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import {
  DynamicTableOptions,
  ColumnDefinition,
  InlineDataTableOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter } from 'rxjs/operators';
import { DynamicFormCustomUpdateEvent } from '@models/dynamic-form/dynamic-form-properties';
import { FormUtils } from '@utils/formly/formly-utils';
import {
  RectangularMaterialSpecificationSize,
  PipeworkMaterialSpecificationSize,
  ElectricalContainmentMaterialSpecificationSize,
  RoundMaterialSpecificationSize,
} from '@models/fabrication/material-spec-sizes';
import {
  InlineEditData,
  InlineEditError,
  InlineEditType,
} from '@models/inline-edit/inline-edit.options';
import { SchemaService } from '@services/schema.service';
import { InlineValidatorFunction, ValidatorService } from '@services/validator.service';
import { MaterialSpecificationSize } from '@models/fabrication/material-spec-sizes';
import {
  MaterialSpecification,
  MaterialSpecificationForm,
  MaterialSpecificationType,
} from '@models/fabrication/material-spec';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { v4 as uuidv4 } from 'uuid';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { JSONSchema7 } from 'json-schema';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DataElementType } from '@constants/data-element-types';

@Component({
  selector: 'fab-material-spec-size-table',
  template: ` <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data> `,
})
export class MaterialSpecificationSizeTableComponent
  extends DynamicFormBaseCustomComponent<MaterialSpecificationSize[]>
  implements OnInit, OnDestroy
{
  tableOptions: DynamicTableOptions<MaterialSpecificationSize> = null;
  tableDataSource: BehaviorSubject<MaterialSpecificationSize[]>;
  tableChangeSubject: Subject<MaterialSpecificationSize[]> = new Subject<
    MaterialSpecificationSize[]
  >();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  idLookup = elementIdDictionary.connectorEdit;
  rows: MaterialSpecificationSize[];
  customModelChangesSubscription: Subscription;
  currentRow: MaterialSpecificationSize = null;

  constructor(
    private schemaService: SchemaService,
    private validatorService: ValidatorService,
    private translate: TranslateService,
    store$: Store<FDMState>
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.tableErrorReporterSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getModelUpdates() {
    this.customModelChangesSubscription = this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
        )
      )
      .subscribe((changeEvent: DynamicFormCustomUpdateEvent) => {
        const model = changeEvent.data;
        if (model) {
          this.rows = model.sizes;
          this.createTableDataOptions(model);
          this.tableDataSource.next(this.rows);
          this.reportErrors();
        }
      });
  }

  createTableDataOptions(model: MaterialSpecification) {
    if (!this.tableOptions) {
      this.tableDataSource =
        this.tableDataSource || new BehaviorSubject<MaterialSpecificationSize[]>(this.rows);

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'material-spec-size-table',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        maintainSelectionOnUpdate: true,
        columns: this.getColumnDefinitions(model),
        subscribeToTableChanges: this.tableChangeSubject,
        tableTypeOptions: {
          newRowData: () => this.getNewRowType(model),
        } as InlineDataTableOptions<MaterialSpecificationSize>,
        errorReporter: this.tableErrorReporterSubject,
        forceFullValidationOnEachChange: true,
        toolbarOptions: {},
        enableMultiSelect: true,
      };
    }
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: MaterialSpecificationSize[]) => {
        this.rows = rows;
        this.updateSource(this.rows);
      }
    );
  }

  getValidator(model: MaterialSpecification, field?: string): InlineValidatorFunction {
    const validators: InlineValidatorFunction[] = [];
    const schema = this.getSchema(model.materialSpecificationType, model.form);
    const schemaValidator = this.validatorService.getInlineEditSchemaValidator(schema.$id);
    validators.push(schemaValidator);
    const outsideDiameterFieldName = 'outsideDiameter';
    const insideDiameterFieldName = 'insideDiameter';
    const nominalDiameterFieldName = 'nominalDiameter';
    const widthFieldName = 'width';
    const depthFieldName = 'depth';
    const lengthFieldName = 'length';
    const diameterFieldName = 'diameter';

    // inside/outside diameter field comparision validation
    // only attach validators to relevant fields
    if (field === outsideDiameterFieldName || field === insideDiameterFieldName) {
      const insideOutsideDiameterValidator: InlineValidatorFunction = (
        inlineEditData: InlineEditData,
        value: number
      ) => {
        let error = false;
        let message = '';

        const isOutsideDiameterField = inlineEditData.field === outsideDiameterFieldName;
        const isInsideDiameterField = inlineEditData.field === insideDiameterFieldName;
        const outsideDiameterValue = inlineEditData.row[outsideDiameterFieldName] as number;
        const insideDiameterValue = inlineEditData.row[insideDiameterFieldName] as number;
        if (
          (isOutsideDiameterField && value < insideDiameterValue) ||
          (isInsideDiameterField && value > outsideDiameterValue)
        ) {
          error = true;
          message = this.translate.instant(
            LC.FEATURE_MODULES.MATERIAL_SPECIFICATIONS.INSIDE_OUTSIDE_DIAMETER_RANGE_OUT
          );
        }

        return { error, message };
      };

      validators.push(insideOutsideDiameterValidator);
    }

    const hasLengthAndWidthSizes =
      model.materialSpecificationType === MaterialSpecificationType.SheetMetal ||
      (model.materialSpecificationType === MaterialSpecificationType.Insulation &&
        model.form !== 'Sleeve') ||
      model.materialSpecificationType === MaterialSpecificationType.Generic;

    // unique nominal diameter value
    if (field === nominalDiameterFieldName) {
      const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
        this.rows.map((x) => (x as PipeworkMaterialSpecificationSize).nominalDiameter),
        nominalDiameterFieldName
      );
      validators.push(uniqueValidator);
    }

    // unique diameter value
    if (field === diameterFieldName) {
      const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
        this.rows.map((x) => (x as RoundMaterialSpecificationSize).diameter),
        diameterFieldName
      );
      validators.push(uniqueValidator);
    }

    // length/width
    if (hasLengthAndWidthSizes && (field === widthFieldName || field === lengthFieldName)) {
      const message = this.translate.instant(LC.VALIDATIONS.MSG_UNIQUE_COMBINATION_REQUIRED, {
        values: `${this.translate.instant(
          LC.DATATYPES.DEFINITIONS.COMMON.LENGTH
        )} / ${this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.WIDTH)}`,
      });

      const uniqueItemsValidator = this.validatorService.getInlineEditUniqueObjectsValidator(
        this.rows.map((x) => ({
          [lengthFieldName]: x[lengthFieldName],
          [widthFieldName]: x[widthFieldName],
        })),
        [lengthFieldName, widthFieldName],
        message
      );
      validators.push(uniqueItemsValidator);
    }

    // width depth combination validation
    if (!hasLengthAndWidthSizes && (field === widthFieldName || field === depthFieldName)) {
      const message = this.translate.instant(LC.VALIDATIONS.MSG_UNIQUE_COMBINATION_REQUIRED, {
        values: `${this.translate.instant(
          LC.DATATYPES.DEFINITIONS.COMMON.WIDTH
        )} / ${this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.DEPTH)}`,
      });

      const uniqueItemsValidator = this.validatorService.getInlineEditUniqueObjectsValidator(
        this.rows.map((x) => ({
          [widthFieldName]: x[widthFieldName],
          [depthFieldName]: x[depthFieldName],
        })),
        [widthFieldName, depthFieldName],
        message
      );
      validators.push(uniqueItemsValidator);
    }

    return this.validatorService.mergeInlineEditValidators(...validators);
  }

  private getSchema(
    specType: MaterialSpecificationType,
    form: MaterialSpecificationForm
  ): JSONSchema7 {
    switch (specType) {
      case MaterialSpecificationType.Insulation:
        if (form === 'Sleeve') {
          const parentSchema = this.schemaService.getSchemaByDataElementType(
            EnvironmentConstants.FSS_SCHEMA_ROUND_MATERIAL_SPEC
          );
          const schema = parentSchema.definitions[
            EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_MATERIAL_SPEC_SIZE
          ] as JSONSchema7;
          return schema;
        } else {
          const parentSchema = this.schemaService.getSchemaByDataElementType(
            DataElementType.MaterialSpecification
          );
          const schema = parentSchema.definitions[
            EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_MATERIAL_SPEC_SIZE
          ] as JSONSchema7;
          return schema;
        }

      case MaterialSpecificationType.SheetMetal: // rect and sheet metal sizes are the same
      case MaterialSpecificationType.Generic: {
        const parentSchema = this.schemaService.getSchemaByDataElementType(
          DataElementType.MaterialSpecification
        );
        const schema = parentSchema.definitions[
          EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_MATERIAL_SPEC_SIZE
        ] as JSONSchema7;
        return schema;
      }

      case MaterialSpecificationType.ElectricalContainment: {
        const parentSchema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC
        );
        const schema = parentSchema.definitions[
          EnvironmentConstants.FSS_SUB_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC_SIZE
        ] as JSONSchema7;
        return schema;
      }

      default:
      case MaterialSpecificationType.Pipework: {
        const parentSchema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC
        );
        const schema = parentSchema.definitions[
          EnvironmentConstants.FSS_SUB_SCHEMA_PIPEWORK_MATERIAL_SPEC_SIZE
        ] as JSONSchema7;
        return schema;
      }
    }
  }

  private getNewRowType(model: MaterialSpecification): MaterialSpecificationSize {
    let tableType: any;

    switch (model.materialSpecificationType) {
      case MaterialSpecificationType.Generic:
      case MaterialSpecificationType.SheetMetal:
        tableType = RectangularMaterialSpecificationSize;
        break;

      case MaterialSpecificationType.ElectricalContainment:
        tableType = ElectricalContainmentMaterialSpecificationSize;
        break;

      case MaterialSpecificationType.Insulation:
        tableType =
          model.form === 'Sleeve'
            ? RoundMaterialSpecificationSize
            : RectangularMaterialSpecificationSize;
        break;

      default:
      case MaterialSpecificationType.Pipework:
        tableType = PipeworkMaterialSpecificationSize;
        break;
    }

    return new tableType(); // eslint-disable-line new-cap
  }

  private getColumnDefinitions(model: MaterialSpecification): ColumnDefinition[] {
    const instance = this.getNewRowType(model);

    let columns = Object.keys(instance).map<ColumnDefinition>((key) => ({
      field: key,
      header: FormUtils.stringToSpaceCased(key.charAt(0).toUpperCase() + key.substring(1)),
      units: ForgeUnitsService.getStandardLengthUnits(this.configUnitSystem),
      inlineEdit: {
        disabled: this.isReadOnly,
        fieldType: InlineEditType.units,
        validator: () => this.getValidator(model, key),
      },
    }));

    let index: number[] = [];
    switch (model.materialSpecificationType) {
      default:
      case MaterialSpecificationType.Generic:
      case MaterialSpecificationType.SheetMetal:
        break;

      case MaterialSpecificationType.Insulation:
        if (model.form === 'Sleeve') {
          // before: length, diameter
          // after:  diamter, length
          index = [1, 0];
          columns = index.map((x) => columns[x]);
        }
        break;

      case MaterialSpecificationType.ElectricalContainment:
        // before:   length, width, depth
        // required: width,  depth, length
        index = [1, 2, 0];
        columns = index.map((x) => columns[x]);
        break;

      case MaterialSpecificationType.Pipework:
        // before:    length,  nominal, inside,  outside
        // required:  nominal, length,  outside, inside
        index = [1, 0, 3, 2];
        columns = index.map((x) => columns[x]);
        break;
    }

    return columns;
  }
}
