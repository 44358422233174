import * as fromStiffenerInfo from '../actions/stiffener-info.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StiffenerInfo } from '@models/fabrication/stiffener-info';

export interface StiffenerInfoState extends EntityState<StiffenerInfo> {} // eslint-disable-line

export const stiffenerInfoAdapter: EntityAdapter<StiffenerInfo> =
  createEntityAdapter<StiffenerInfo>({
    selectId: (stiffenerInfo: StiffenerInfo) => stiffenerInfo.id,
  });

export const initialState: StiffenerInfoState = stiffenerInfoAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromStiffenerInfo.StiffenerInfoActions
): StiffenerInfoState {
  switch (action.type) {
    case fromStiffenerInfo.StiffenerInfoActionTypes.LoadStiffenerInfos: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.LoadStiffenerInfosSuccess: {
      return stiffenerInfoAdapter.upsertMany(action.data, state);
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.LoadStiffenerInfosFail: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.AddStiffenerInfo: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.AddStiffenerInfoSuccess: {
      return stiffenerInfoAdapter.addOne(action.dataElement, state);
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.AddStiffenerInfoFail: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.CopyStiffenerInfo: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.CopyStiffenerInfoSuccess: {
      return stiffenerInfoAdapter.addOne(action.dataElement, state);
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.CopyStiffenerInfoFail: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.UpdateStiffenerInfo: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.UpdateStiffenerInfoSuccess: {
      return stiffenerInfoAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.UpdateStiffenerInfoFail: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.DeleteStiffenerInfos: {
      return state;
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.DeleteStiffenerInfosSuccess: {
      return stiffenerInfoAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromStiffenerInfo.StiffenerInfoActionTypes.DeleteStiffenerInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of stiffenerInfo ids
  selectIds: selectStiffenerInfoIds,

  // select the dictionary of StiffenerInfo entities
  selectEntities: selectStiffenerInfoEntities,

  // select the array of StiffenerInfos
  selectAll: selectAllStiffenerInfos,

  // select the total StiffenerInfo count
  selectTotal: selectStiffenerInfosTotal,
} = stiffenerInfoAdapter.getSelectors();
