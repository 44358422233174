import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';

export enum StiffenerType {
  Internal = 'Internal',
  External = 'External',
  Both = 'Both',
  None = 'None',
}

export class StiffenerInfo extends ForgeContentDataElement {
  category = '';
  centralType: StiffenerType = StiffenerType.None;
  endType: StiffenerType = StiffenerType.None;
  longAdjust = 0;
  shortAdjust = 0;
  omitShortSideIfLessThan = 0;
}
