import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ForgeContentService } from '@services/forge-content.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Action } from '@ngrx/store';
import { BinaryTaskAction } from '@services/binary-task-manager.service';
import { ApiError } from '@models/errors/api-error';
import { ContentFile, ThumbnailFile } from '@models/fabrication/files';
import { SystemInfoActionTypes } from '@store/actions/system-info.action';
import { ConnectorInfoActionTypes } from '@store/actions/connector-info.action';
import { DamperInfoActionTypes } from '@store/actions/damper-info.action';
import { StiffenerInfoActionTypes } from '@store/actions/stiffener-info.action';
import { MaterialActionTypes } from '@store/actions/material.action';
import { MaterialSpecActionTypes } from '@store/actions/material-spec.action';
import { SpecificationInfoActionTypes } from '@store/actions/specifications.action';
import { InsulationSpecificationInfoActionTypes } from '@store/actions/insulation-specs.action';
import { ServiceTemplateInfoActionTypes } from '@store/actions/service-template-info.action';
import { StiffenerSpecificationInfoActionTypes } from '@store/actions/stiffener-spec.action';
import { AddDataElementAction } from '@store/actions/base/data-element.action';

import { SetBinaryTaskData, SkipBinaryTaskData } from '@store/actions/application.action';
import { CacheUpdateType } from '@models/cache/cache';
import { TriggerCacheDataUpdate } from '@store/actions/cache-data.action';
import { ActivityActionType } from '@models/activities-events/activities';

import { ContentItem } from '@adsk/content-sdk';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { SchemaService } from '@services/schema.service';
import { ValidatorService } from '@services/validator.service';
import { Operation } from '@constants/operations-types';

@Injectable()
export class AddDataElementEffects {
  addDataElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemInfoActionTypes.AddSystemInfo,
        ConnectorInfoActionTypes.AddConnectorInfo,
        DamperInfoActionTypes.AddDamperInfo,
        StiffenerInfoActionTypes.AddStiffenerInfo,
        ServiceTemplateInfoActionTypes.AddServiceTemplateInfo,
        MaterialActionTypes.AddMaterial,
        MaterialSpecActionTypes.AddMaterialSpec,
        SpecificationInfoActionTypes.AddSpecificationInfo,
        InsulationSpecificationInfoActionTypes.AddInsulationSpecificationInfo,
        StiffenerSpecificationInfoActionTypes.AddStiffenerSpecificationInfo
      ),
      switchMap((action: AddDataElementAction<ForgeContentDataElement>) => {
        return this.runAddDataElementActions(action);
      }),
      switchMap((actions: Action[]) => actions)
    )
  );

  private runAddDataElementActions = (
    action: AddDataElementAction<ForgeContentDataElement>
  ): Observable<Action[]> => {
    const actionSchema =
      action.schema || this.schemaService.getSchemaByDataElementType(action.dataElementType);
    if (!this.validatorService.validateForgeContentData(action.dataElement, actionSchema)) {
      this.dataElementEffectService.postFailureNotification(
        Operation.ADD,
        action.dataElementType,
        null
      );
      return of([action.failAction]);
    }

    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
      action.dataElementType
    );
    const filesAreReferenced = dynamicDataSetup.options.filesAreReferenced;

    return this.dataElementEffectService.contentFilesRequired(action.dataElement).pipe(
      switchMap((contentFiles: [ContentFile[], ThumbnailFile[]]) => {
        const [files, thumbnails] = contentFiles;
        return this.forgeContentService.addContent<ForgeContentDataElement>(
          action.dataElement,
          action.config,
          action.dataElementType,
          dynamicDataSetup.options.fcs.dataTypeExternalNodeId,
          dynamicDataSetup.options.fcs.createSchemaOverride
            ? dynamicDataSetup.options.fcs.createSchemaOverride()
            : dynamicDataSetup.options.fcs.schemas[0].schema,
          filesAreReferenced ? null : files,
          filesAreReferenced ? null : thumbnails,
          filesAreReferenced
        );
      }),
      map((data: [ForgeContentDataElement, ContentItem] | ApiError) => {
        const dispatchActions = [];
        if (!(data as ApiError).errors) {
          const [successData, response] = data as [ForgeContentDataElement, ContentItem];
          action.successAction.dataElement = successData;

          dispatchActions.push(
            action.successAction,
            dynamicDataSetup.options.actions.updateDataReferencesAction(
              action.config,
              [successData.id],
              false
            ),
            // web hook
            ...(action.skipBinaryTask
              ? [
                  new SkipBinaryTaskData({
                    dataElementType: action.dataElementType,
                    completeOperation: Operation.ADD,
                  }),
                ]
              : [
                  new SetBinaryTaskData({
                    entityId: successData.id,
                    entityType: 'content',
                    action: BinaryTaskAction.Add,
                    externalIds: [successData.externalId],
                    schemaId: successData.extensionDataType,
                    jobType: BinaryJobType.BinaryUpdate,
                    updatedReferenceContentIds: action.updatedReferenceContentIds,
                  }),
                ]),
            // cache update
            new TriggerCacheDataUpdate({
              dataElementType: action.dataElementType,
              dataUrn: successData.id,
              cacheUpdateType: CacheUpdateType.Add,
              response,
              nodeId: action.nodeId,
              isExternalNodeId: action.isExternalNodeId,
            }),
            this.dataElementEffectService.createActivitySubmissionAction(
              action.config.id,
              action.dataElementType,
              [
                {
                  activityType: ActivityActionType.Add,
                  urn: successData.id,
                  name: successData.name,
                },
              ],
              action.nodeId
            )
          );
          this.dataElementEffectService.navigateBackToPreviousMasterPage(successData, action);
        } else {
          const failureData = data as ApiError;
          dispatchActions.push(action.failAction);
          this.dataElementEffectService.postFailureNotification(
            Operation.ADD,
            action.dataElementType,
            failureData
          );
        }

        return dispatchActions;
      })
    );
  };

  constructor(
    private actions$: Actions,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private dataElementEffectService: DataElementEffectService,
    private schemaService: SchemaService,
    private validatorService: ValidatorService
  ) {}
}
