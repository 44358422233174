import * as fromMaterial from '../actions/material.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Material } from '@models/fabrication/material';
import { MaterialFinish } from '@models/fabrication/material-finish';

export interface MaterialState extends EntityState<Material | MaterialFinish> {} // eslint-disable-line

export const materialAdapter: EntityAdapter<Material | MaterialFinish> = createEntityAdapter<
  Material | MaterialFinish
>({
  selectId: (material: Material | MaterialFinish) => material.id,
});

export const initialState: MaterialState = materialAdapter.getInitialState();

export function reducer(state = initialState, action: fromMaterial.MaterialActions): MaterialState {
  switch (action.type) {
    case fromMaterial.MaterialActionTypes.LoadMaterials: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.LoadMaterialsSuccess: {
      return materialAdapter.upsertMany(action.data, state);
    }
    case fromMaterial.MaterialActionTypes.LoadMaterialsFail: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.AddMaterial: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.AddMaterialSuccess: {
      return materialAdapter.addOne(action.dataElement, state);
    }
    case fromMaterial.MaterialActionTypes.AddMaterialFail: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.CopyMaterial: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.CopyMaterialSuccess: {
      return materialAdapter.addOne(action.dataElement, state);
    }
    case fromMaterial.MaterialActionTypes.CopyMaterialFail: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.UpdateMaterial: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.UpdateMaterialSuccess: {
      return materialAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromMaterial.MaterialActionTypes.UpdateMaterialFail: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.DeleteMaterials: {
      return state;
    }
    case fromMaterial.MaterialActionTypes.DeleteMaterialsSuccess: {
      return materialAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromMaterial.MaterialActionTypes.DeleteMaterialsFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of material ids
  selectIds: selectMaterialIds,

  // select the dictionary of Material entities
  selectEntities: selectMaterialEntities,

  // select the array of Materials
  selectAll: selectAllMaterials,

  // select the total Material count
  selectTotal: selectMaterialsTotal,
} = materialAdapter.getSelectors();
