import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeleteResult, ForgeContentService } from '@services/forge-content.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Action } from '@ngrx/store';
import { BinaryTaskAction } from '@services/binary-task-manager.service';
import { DataElementType } from '@constants/data-element-types';
import { isEmpty } from 'lodash';
import { SystemInfoActionTypes } from '@store/actions/system-info.action';
import { ConnectorInfoActionTypes } from '@store/actions/connector-info.action';
import { DamperInfoActionTypes } from '@store/actions/damper-info.action';
import { StiffenerInfoActionTypes } from '@store/actions/stiffener-info.action';
import { MaterialActionTypes } from '@store/actions/material.action';
import { MaterialSpecActionTypes } from '@store/actions/material-spec.action';
import { SpecificationInfoActionTypes } from '@store/actions/specifications.action';
import { InsulationSpecificationInfoActionTypes } from '@store/actions/insulation-specs.action';
import { ServiceTemplateInfoActionTypes } from '@store/actions/service-template-info.action';
import { InvalidDataActionTypes } from '@store/actions/invalid-data.action';
import { StiffenerSpecificationInfoActionTypes } from '@store/actions/stiffener-spec.action';
import { DeleteDataElementsAction } from '@store/actions/base/data-element.action';

import { SetBinaryTaskData, SkipBinaryTaskData } from '@store/actions/application.action';
import { CacheUpdateType } from '@models/cache/cache';
import { TriggerCacheDataUpdate } from '@store/actions/cache-data.action';
import { ActivityActionType } from '@models/activities-events/activities';

import { InvalidData } from '@models/fabrication/invalid-data';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { PartActionTypes } from '@store/actions/part.action';
import { Operation } from '@constants/operations-types';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { EnvironmentConstants } from '@constants/environment-constants';

@Injectable()
export class DeleteDataElementEffects {
  deleteDataElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ConnectorInfoActionTypes.DeleteConnectorInfos,
        DamperInfoActionTypes.DeleteDamperInfos,
        StiffenerInfoActionTypes.DeleteStiffenerInfos,
        SystemInfoActionTypes.DeleteSystemInfos,
        ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfos,
        MaterialActionTypes.DeleteMaterials,
        MaterialSpecActionTypes.DeleteMaterialSpecs,
        SpecificationInfoActionTypes.DeleteSpecificationInfos,
        InsulationSpecificationInfoActionTypes.DeleteInsulationSpecificationInfos,
        InvalidDataActionTypes.DeleteInvalidData,
        StiffenerSpecificationInfoActionTypes.DeleteStiffenerSpecificationInfos,
        PartActionTypes.DeleteParts
      ),
      switchMap((action: DeleteDataElementsAction<ForgeContentDataElement>) => {
        return this.runDeleteDataElementActions(action);
      }),
      switchMap((actions: Action[]) => actions)
    )
  );

  private runDeleteDataElementActions = (
    action: DeleteDataElementsAction<ForgeContentDataElement>
  ): Observable<Action[]> => {
    return this.forgeContentService
      .deleteContent<any>(action.config, action.dataElements, action.dataElementType)
      .pipe(
        map((data: DeleteResult[]) => {
          const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
            action.dataElementType
          );
          const dispatchActions = [];

          const dataElementsResults = action.dataElements.map((dataElement, i) => {
            return { dataElement, result: data[i] };
          });

          // we only support deleting a single element at any time
          // we will need to revisit this logic when we support multi-deletes
          let schemaId = '';
          const dataElement = action.dataElements[0];
          if (
            action.dataElementType === DataElementType.InvalidData ||
            dataElement.extensionDataType === EnvironmentConstants.FSS_SCHEMA_INVALID_DATA
          ) {
            schemaId = DataElementTypeUtils.getForgeContentSchema(
              (dataElement as InvalidData).schemaType
            ).type;
          } else {
            schemaId = dataElement.extensionDataType;
          }

          const entityId = dataElement.id;
          const successResults = dataElementsResults.filter(
            (res) => res.result && res.result.success
          );
          const referencedResults = dataElementsResults.filter(
            (res) => res.result && !isEmpty(res.result.references)
          );
          const errorResults = dataElementsResults.filter(
            (res) => !!(res.result && res.result.errors)
          );

          if (!isEmpty(successResults)) {
            action.successAction.removedDataElementIds = successResults.map(
              (res) => res.dataElement.id
            );

            const updateDataRefs = dynamicDataSetup.options.actions.updateDataReferencesAction(
              action.config,
              successResults.map((x) => x.dataElement.id),
              true
            );
            if (updateDataRefs) {
              dispatchActions.push(updateDataRefs);
            }

            dispatchActions.push(
              action.successAction,
              // web hook
              ...(action.skipBinaryTask
                ? [
                    new SkipBinaryTaskData({
                      dataElementType: action.dataElementType,
                      completeOperation: Operation.DELETE,
                    }),
                  ]
                : [
                    new SetBinaryTaskData({
                      entityId,
                      entityType: 'content',
                      action: BinaryTaskAction.Delete,
                      externalIds: successResults.map<string>((res) => res.dataElement.externalId),
                      schemaId, // should all be the same type
                      jobType: BinaryJobType.BinaryUpdate,
                      oldPath: action.oldPath,
                    }),
                  ]),
              ...(action.ignoreActivities
                ? []
                : [
                    this.dataElementEffectService.createActivitySubmissionAction(
                      action.config.id,
                      action.dataElementType,
                      successResults.map(
                        (x) => ({
                          activityType: ActivityActionType.Delete,
                          urn: x.dataElement.id,
                          name: x.dataElement.name,
                          nodeId: action.nodeId,
                          isNodeExternalId: action.isExternalNodeId,
                        }),
                        action.nodeId
                      )
                    ),
                  ]),
              // cache update
              ...(action.ignoreCache
                ? []
                : successResults.map(
                    (x) =>
                      new TriggerCacheDataUpdate({
                        dataElementType: action.dataElementType,
                        dataUrn: x.dataElement.id,
                        cacheUpdateType: CacheUpdateType.Delete,
                        response: null,
                        nodeId: action.nodeId,
                        isExternalNodeId: action.isExternalNodeId,
                      })
                  ))
            );
          }

          if (!isEmpty(referencedResults)) {
            const references = referencedResults.reduce((acc, current) => {
              const refs = current.result && current.result.references;
              return refs ? [...acc, ...refs] : acc;
            }, []);
            dispatchActions.push(action.failAction);
            this.dataElementEffectService.postUnableToDeleteNotification(
              action.dataElementType,
              references,
              referencedResults.length
            );
          }

          if (!isEmpty(errorResults)) {
            const firstDataElementError = errorResults[0].result.errors;
            dispatchActions.push(action.failAction);
            this.dataElementEffectService.postFailureNotification(
              Operation.DELETE,
              action.dataElementType,
              firstDataElementError
            );
          }
          return dispatchActions;
        })
      );
  };

  constructor(
    private actions$: Actions,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private dataElementEffectService: DataElementEffectService
  ) {}
}
