import { FabricationRate } from '@models/fabrication/labour-rate';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum FabricationRateActionTypes {
  LoadFabricationRates = '[Fabrication Rates API] Load Fabrication Rates',
  LoadFabricationRatesFail = '[Fabrication Rates API] Load Fabrication Rates Fail',
  LoadFabricationRatesSuccess = '[Fabrication Rates API] Load Fabrication Rates Success',
  LoadFabricationRateReferences = '[Fabrication Rates API] Load Fabrication Rate References',
}

export class LoadFabricationRatesFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = FabricationRateActionTypes.LoadFabricationRatesFail;
}

export class LoadFabricationRatesSuccess implements LoadDataElementsSuccessAction<FabricationRate> {
  dataLoadingState = DataLoadingState.Complete;
  data: FabricationRate[];
  readonly type = FabricationRateActionTypes.LoadFabricationRatesSuccess;
}

export class LoadFabricationRates implements LoadDataElementsAction<FabricationRate> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadFabricationRatesSuccess();
  failAction = new LoadFabricationRatesFail();
  readonly type = FabricationRateActionTypes.LoadFabricationRates;
  dataElementType = DataElementType.FabricationRate;
  nodeId = EnvironmentConstants.FCS_NODE_ID_FABRICATIONRATES;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export type FabricationRateActions =
  | LoadFabricationRates
  | LoadFabricationRatesFail
  | LoadFabricationRatesSuccess;
