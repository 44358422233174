import { Action } from '@ngrx/store';
import { PartFilterConfig } from '@models/forge-content/part-search';

export enum PartFilterActionTypes {
  UpsertPartFilter = '[Part Filter] Upsert Part Filter',
}

export class UpsertPartFilter implements Action {
  readonly type = PartFilterActionTypes.UpsertPartFilter;

  constructor(public payload: { filter: PartFilterConfig }) {}
}

export type PartFilterActions = UpsertPartFilter;
