import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';

import {
  RectangularMaterialSpecificationSize,
  PipeworkMaterialSpecificationSize,
  ElectricalContainmentMaterialSpecificationSize,
  RoundMaterialSpecificationSize,
} from './material-spec-sizes';

export type ElectricalContainmentMaterialSpecificationForm = 'Generic';
export type PipeworkMaterialSpecificationForm = 'Tubular';
export type DuctboardMaterialSpecificationForm = 'Board';
export type InsulationMaterialSpecificationForm = 'Roll' | 'Sleeve';
export type GenericMaterialSpecificationForm = 'Generic';
export type SheetMetalMaterialSpecificationForm = 'NotSet' | 'Coil' | 'Sheet';

export type MaterialSpecificationForm =
  | GenericMaterialSpecificationForm
  | SheetMetalMaterialSpecificationForm
  | InsulationMaterialSpecificationForm
  | PipeworkMaterialSpecificationForm
  | ElectricalContainmentMaterialSpecificationForm
  | DuctboardMaterialSpecificationForm;

export enum MaterialSpecificationType {
  Generic = 'Generic',
  Pipework = 'Pipework',
  ElectricalContainment = 'ElectricalContainment',
  SheetMetal = 'SheetMetal',
  Insulation = 'Insulation',
}

export abstract class MaterialSpecification extends ForgeContentDataElement {
  category = '';
  readonly materialSpecificationType: MaterialSpecificationType;
  form: MaterialSpecificationForm = 'NotSet';

  constructor(specType: MaterialSpecificationType, form: MaterialSpecificationForm) {
    super();
    this.materialSpecificationType = specType;
    this.form = form;
    this.fabricationReferences = [];
  }
}

export abstract class RectangularMaterialSpecification extends MaterialSpecification {
  thickness = 0.0;

  sizes: RectangularMaterialSpecificationSize[] = [];
}

export class SleeveMaterialSpecification extends MaterialSpecification {
  thickness = 0.0;

  sizes: RoundMaterialSpecificationSize[] = [];

  constructor() {
    super(MaterialSpecificationType.Insulation, 'Sleeve');
  }
}

export class RollMaterialSpecification extends RectangularMaterialSpecification {
  constructor() {
    super(MaterialSpecificationType.Insulation, 'Roll');
  }
}

export class GenericMaterialSpecification extends RectangularMaterialSpecification {
  constructor() {
    super(MaterialSpecificationType.Generic, 'Generic');
  }
}

export class SheetMetalMaterialSpecification extends RectangularMaterialSpecification {
  wireGauge = 1;

  constructor(form: SheetMetalMaterialSpecificationForm) {
    super(MaterialSpecificationType.SheetMetal, form);
  }
}

export class DuctboardMaterialSpecification extends RectangularMaterialSpecification {
  constructor() {
    super(MaterialSpecificationType.Insulation, 'Board');
  }
}

export class PipeworkMaterialSpecification extends MaterialSpecification {
  sizes: PipeworkMaterialSpecificationSize[] = [];

  constructor() {
    super(MaterialSpecificationType.Pipework, 'Tubular');
  }
}

export class ElectricalContainmentMaterialSpecification extends MaterialSpecification {
  sizes: ElectricalContainmentMaterialSpecificationSize[] = [];

  constructor() {
    super(MaterialSpecificationType.ElectricalContainment, 'Generic');
  }
}
