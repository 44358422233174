import { InvalidData } from '@models/fabrication/invalid-data';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
  DeleteDataElementsSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  AddDataElementSuccessAction,
  AddDataElementAction,
  AddDataElementFailAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { EnvironmentConstants } from '@constants/environment-constants';
import { JSONSchema7 } from 'json-schema';

const dataType = DataElementType.InvalidData;

export enum InvalidDataActionTypes {
  LoadInvalidData = '[Invalid Data API] Load Invalid Data',
  LoadInvalidDataFail = '[Invalid Data API] Load Invalid Data Fail',
  LoadInvalidDataSuccess = '[Invalid Data API] Load Invalid Data Success',
  AddInvalidData = '[Invalid Data API] Add Invalid Data',
  AddInvalidDataFail = '[Invalid Data API] Add Invalid Data Fail',
  AddInvalidDataSuccess = '[Invalid Data API] Add Invalid Data Success',
  DeleteInvalidData = '[Invalid Data API] Delete Invalid Data',
  DeleteInvalidDataFail = '[Invalid Data API] Delete Invalid Data Fail',
  DeleteInvalidDataSuccess = '[Invalid Data API] Delete Invalid Data Success',
  FixInvalidData = '[Invalid Data API] Fix Invalid Data',
  FixInvalidDataFail = '[Invalid Data API] Fix Invalid Data Fail',
  FixInvalidDataSuccess = '[Invalid Data API] Fix Invalid Data Success',
}

export class LoadInvalidDataFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InvalidDataActionTypes.LoadInvalidDataFail;
}

export class LoadInvalidDataSuccess implements LoadDataElementsSuccessAction<InvalidData> {
  dataLoadingState = DataLoadingState.Complete;
  data: InvalidData[];
  readonly type = InvalidDataActionTypes.LoadInvalidDataSuccess;
}

export class LoadInvalidData implements LoadDataElementsAction<InvalidData> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadInvalidDataSuccess();
  failAction = new LoadInvalidDataFail();
  readonly type = InvalidDataActionTypes.LoadInvalidData;
  dataElementType = DataElementType.InvalidData;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INVALID_DATA;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class AddInvalidData implements AddDataElementAction<ForgeContentDataElement> {
  config: Config;
  successAction = new AddInvalidDataSuccess();
  failAction = new AddInvalidDataFail();

  dataElement: ForgeContentDataElement;
  dataElementType: DataElementType;
  skipBinaryTask = true;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INVALID_DATA;
  isExternalNodeId = true;
  readonly type = InvalidDataActionTypes.AddInvalidData;
  dataLoadingState = DataLoadingState.Loading;

  constructor(payload: {
    config: Config;
    dataElement: ForgeContentDataElement;
    dataElementType: DataElementType;
  }) {
    this.config = payload.config;
    this.dataElement = payload.dataElement;
    this.dataElementType = payload.dataElementType;
  }
}

export class AddInvalidDataSuccess implements AddDataElementSuccessAction<InvalidData> {
  dataElement: InvalidData;
  readonly type = InvalidDataActionTypes.AddInvalidDataSuccess;
  dataLoadingState = DataLoadingState.Complete;
}

export class AddInvalidDataFail implements AddDataElementFailAction {
  type = InvalidDataActionTypes.AddInvalidDataFail;
  dataLoadingState = DataLoadingState.Failed;
}

export class DeleteInvalidData implements DeleteDataElementsAction<InvalidData> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: InvalidData[];
  successAction = new DeleteInvalidDataSuccess();
  failAction = new DeleteInvalidDataFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INVALID_DATA;
  isExternalNodeId = true;
  oldPath?: string;
  readonly type = InvalidDataActionTypes.DeleteInvalidData;

  constructor(public payload: { config: Config; dataElements: InvalidData[]; oldPath?: string }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
    this.oldPath = payload.oldPath;
  }
}

export class DeleteInvalidDataFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InvalidDataActionTypes.DeleteInvalidDataFail;
}

export class DeleteInvalidDataSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = InvalidDataActionTypes.DeleteInvalidDataSuccess;
}

export class FixInvalidDataFail {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InvalidDataActionTypes.FixInvalidDataFail;
}

export class FixInvalidDataSuccess {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ForgeContentDataElement;
  dataElementType: DataElementType;
  readonly type = InvalidDataActionTypes.FixInvalidDataSuccess;
}

export class FixInvalidData {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: ForgeContentDataElement;
  dataElementType: DataElementType;
  fixSchemaType: string;
  fixSchemaVersion: string;
  nodeId: string;
  nodeExternalId = EnvironmentConstants.FCS_NODE_ID_INVALID_DATA;
  schema: JSONSchema7;

  // only needed for compatibility in the effect. not actually used
  isExternalNodeId = true;

  addSuccessAction: AddDataElementSuccessAction<ForgeContentDataElement>;

  successAction = new FixInvalidDataSuccess();
  failAction = new FixInvalidDataFail();
  skipBinaryTask = false;
  readonly type = InvalidDataActionTypes.FixInvalidData;

  constructor(
    public payload: {
      config: Config;
      dataElement: ForgeContentDataElement;
      dataElementType: DataElementType;
      fixSchemaType: string;
      fixSchemaVersion: string;
      nodeId: string;
      addSuccessAction: AddDataElementSuccessAction<ForgeContentDataElement>;
      schema?: JSONSchema7;
    }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.dataElementType = payload.dataElementType;
    this.fixSchemaType = payload.fixSchemaType;
    this.fixSchemaVersion = payload.fixSchemaVersion;
    this.nodeId = payload.nodeId;
    this.addSuccessAction = payload.addSuccessAction;
    this.schema = payload.schema;
  }
}

export type InvalidDataActions =
  | LoadInvalidData
  | LoadInvalidDataFail
  | LoadInvalidDataSuccess
  | AddInvalidData
  | AddInvalidDataFail
  | AddInvalidDataSuccess
  | DeleteInvalidData
  | DeleteInvalidDataFail
  | DeleteInvalidDataSuccess
  | FixInvalidData
  | FixInvalidDataFail
  | FixInvalidDataSuccess;
