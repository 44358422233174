import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromSystemInfo from '../reducers/system-info.reducer';

// system info state
export const selectSystemInfoState = (state: FDMState) => state.configsState.systemInfos;

// system info selectors
export const selectSystemInfoIds = createSelector(
  selectSystemInfoState,
  fromSystemInfo.selectSystemInfoIds
);

export const selectSystemInfoEntities = createSelector(
  selectSystemInfoState,
  fromSystemInfo.selectSystemInfoEntities
);

export const selectAllSystemInfo = createSelector(
  selectSystemInfoState,
  fromSystemInfo.selectAllSystemInfo
);

export const selectSystemInfoTotal = createSelector(
  selectSystemInfoState,
  fromSystemInfo.selectSystemInfoTotal
);

export const selectSystemInfoById = (id: string) =>
  createSelector(selectSystemInfoEntities, (systemInfoEntities) => systemInfoEntities[id]);
