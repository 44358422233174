import ThemeContext from '@weave-design/theme-context';
import SideNav, { Docked } from '@hig/side-nav';
import * as Icons from '@weave-design/icons';
import * as React from 'react';
import styled from 'styled-components';
import { Header, Link, Group } from '@models/ui-state/navigation-data';

export type Props = {
  header: Header;
  links: Link[];
  groups: Group[];
  themeData: unknown;
  onClickHeader: () => void;
};

const SideNavBackground: any = styled('div')`
  background-color: ${(props: any) => props.background};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 280px;
  padding-right: 4px;
`;

const sideNavHeaderStyleSheet = {
  position: 'relative',
  padding: '0px 12px 0px 16px',
  display: 'block',
  fontWeight: '600',
  textDecoration: 'none',
  cursor: 'pointer',
  fontSize: '20px',
  color: 'rgb(245, 245, 245)',
};

const getIconFromName = (name) => {
  const IconComponent = Icons[name];
  return <IconComponent />;
};

const linksToComponents = (links: Link[]) => {
  return links.map((linkProps, i) => <SideNav.Link key={`sidenavlink-${i}`} {...linkProps} />);
};

const groupsToComponents = (groups: Group[]) => {
  return groups.map(({ modules, ...restGroupProps }, i) => (
    <SideNav.Group key={`group-${i}`} {...restGroupProps}>
      {modules &&
        modules.map(({ submodules, icon, active, ...restModuleProps }, j) => (
          <SideNav.Module
            className={active ? 'active-link' : null}
            key={`sidenavmodule-g${i}-${j}`}
            icon={getIconFromName(icon)}
            {...restModuleProps}
          >
            {submodules &&
              submodules.map(
                (submodule, k) =>
                  submodule && (
                    <SideNav.Submodule
                      key={`sidenavsubmodule-g${i}-m${j}-${k}`}
                      className={submodule.active ? 'active-link' : null}
                      {...submodule}
                    />
                  )
              )}
          </SideNav.Module>
        ))}
    </SideNav.Group>
  ));
};

export const HigSideNav: React.FC<Props> = ({
  header,
  links,
  groups,
  themeData,
  onClickHeader,
}) => {
  return (
    <ThemeContext.Provider value={themeData}>
      <ThemeContext.Consumer>
        {({ resolvedRoles }) => (
          <SideNavBackground background={resolvedRoles['colorScheme.surface.level100']}>
            <Docked>
              <SideNav
                stylesheet={(styles) => {
                  return {
                    ...styles,
                    headers: {
                      ...styles.headers,
                      normal: {
                        ...styles.headers.normal,
                        ...sideNavHeaderStyleSheet,
                      },
                    },
                  };
                }}
                superHeaderTitle={header.title}
                headerLabel={header.label}
                onClickHeader={onClickHeader}
                superHeaderLink={header.link}
                groups={groupsToComponents(groups)}
                links={linksToComponents(links)}
              />
            </Docked>
          </SideNavBackground>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};
