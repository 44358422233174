import { DataElementType } from '@constants/data-element-types';
import { SortDirection } from '@angular/material/sort';
import { DataViewType } from '@models/fabrication/common';

export const defaultForgeViewer: ForgeViewerSettings = {
  elementsSizes: { resizableElement: {}, nextElement: {} },
};

export interface TableSortSettings {
  active?: string;
  direction?: SortDirection;
}

export interface TablePaginatorSettings {
  pageSize: number;
}

export interface TableSettings {
  sort: {
    [key in DataElementType]?: TableSortSettings;
  };
  paginator: TablePaginatorSettings;
  columns: {
    [key in DataElementType]?: string[];
  };
}

export interface SizeSettings {
  width?: number;
  height?: number;
}

export interface ElementsSizesSettings {
  resizableElement: SizeSettings;
  nextElement: SizeSettings;
}

export interface ForgeViewerSettings {
  elementsSizes: ElementsSizesSettings;
}

// the main key uses the userId reference as string
export interface UserSettingsModel {
  table: TableSettings;
  forgeViewer: ForgeViewerSettings;
  dataViewType: DataViewType;
  lastAccessedConfigId: string;
  debugMode: Record<string, boolean>;
}

export const DefaultUserSettings: UserSettingsModel = {
  table: {
    sort: {},
    paginator: {
      pageSize: 10,
    },
    columns: {},
  },
  forgeViewer: defaultForgeViewer,
  dataViewType: DataViewType.Tile,
  lastAccessedConfigId: null,
  debugMode: {},
};
