import { StiffenerInfo } from '@models/fabrication/stiffener-info';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsSuccessAction,
  DeleteDataElementsFailAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

const dataType = DataElementType.Stiffener;

export enum StiffenerInfoActionTypes {
  LoadStiffenerInfos = '[StiffenerInfo API] Load StiffenerInfos',
  LoadStiffenerInfosFail = '[StiffenerInfo API] Load StiffenerInfos Fail',
  LoadStiffenerInfosSuccess = '[StiffenerInfo API] Load StiffenerInfos Success',
  UpdateStiffenerInfo = '[Stiffener Info API] Update StiffenerInfo',
  UpdateStiffenerInfoFail = '[Stiffener Info API] Update StiffenerInfo Fail',
  UpdateStiffenerInfoSuccess = '[Stiffener Info API] Update StiffenerInfo Success',
  AddStiffenerInfo = '[Stiffener Info API] Add StiffenerInfo',
  AddStiffenerInfoFail = '[Stiffener Info API] Add StiffenerInfo Fail',
  AddStiffenerInfoSuccess = '[Stiffener Info API] Add StiffenerInfo Success',
  DeleteStiffenerInfos = '[Stiffener Info API] Delete StiffenerInfos',
  DeleteStiffenerInfosFail = '[Stiffener Info API] Delete StiffenerInfos Fail',
  DeleteStiffenerInfosSuccess = '[Stiffener Info API] Delete StiffenerInfos Success',
  CopyStiffenerInfo = '[Stiffener Info API] Copy StiffenerInfo',
  CopyStiffenerInfoFail = '[Stiffener Info API] Copy StiffenerInfo Fail',
  CopyStiffenerInfoSuccess = '[Stiffener Info API] Copy StiffenerInfo Success',
}

export class LoadStiffenerInfos implements LoadDataElementsAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadStiffenerInfosSuccess();
  failAction = new LoadStiffenerInfosFail();
  readonly type = StiffenerInfoActionTypes.LoadStiffenerInfos;
  dataElementType = DataElementType.Stiffener;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadStiffenerInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerInfoActionTypes.LoadStiffenerInfosFail;
}

export class LoadStiffenerInfosSuccess implements LoadDataElementsSuccessAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Complete;
  data: StiffenerInfo[];
  readonly type = StiffenerInfoActionTypes.LoadStiffenerInfosSuccess;
}

export class UpdateStiffenerInfo implements UpdateDataElementAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: StiffenerInfo;
  successAction = new UpdateStiffenerInfoSuccess();
  failAction = new UpdateStiffenerInfoFail();
  readonly type = StiffenerInfoActionTypes.UpdateStiffenerInfo;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: StiffenerInfo }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateStiffenerInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerInfoActionTypes.UpdateStiffenerInfoFail;
}

export class UpdateStiffenerInfoSuccess implements UpdateDataElementSuccessAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: StiffenerInfo;
  readonly type = StiffenerInfoActionTypes.UpdateStiffenerInfoSuccess;
}

export class AddStiffenerInfo implements AddDataElementAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: StiffenerInfo;
  successAction = new AddStiffenerInfoSuccess();
  failAction = new AddStiffenerInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = StiffenerInfoActionTypes.AddStiffenerInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: StiffenerInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class AddStiffenerInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerInfoActionTypes.AddStiffenerInfoFail;
}

export class AddStiffenerInfoSuccess implements AddDataElementSuccessAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: StiffenerInfo;
  readonly type = StiffenerInfoActionTypes.AddStiffenerInfoSuccess;
}

export class CopyStiffenerInfo implements CopyDataElementAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: StiffenerInfo;
  successAction = new CopyStiffenerInfoSuccess();
  failAction = new CopyStiffenerInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = StiffenerInfoActionTypes.CopyStiffenerInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: StiffenerInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopyStiffenerInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerInfoActionTypes.CopyStiffenerInfoFail;
}

export class CopyStiffenerInfoSuccess implements CopyDataElementSuccessAction<StiffenerInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: StiffenerInfo;
  readonly type = StiffenerInfoActionTypes.CopyStiffenerInfoSuccess;
}

export class DeleteStiffenerInfos implements DeleteDataElementsAction<StiffenerInfo> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: StiffenerInfo[];
  successAction = new DeleteStiffenerInfosSuccess();
  failAction = new DeleteStiffenerInfosFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS;
  isExternalNodeId = true;
  readonly type = StiffenerInfoActionTypes.DeleteStiffenerInfos;

  constructor(public payload: { config: Config; dataElements: StiffenerInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteStiffenerInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = StiffenerInfoActionTypes.DeleteStiffenerInfosFail;
}

export class DeleteStiffenerInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = StiffenerInfoActionTypes.DeleteStiffenerInfosSuccess;
}

export type StiffenerInfoActions =
  | LoadStiffenerInfos
  | LoadStiffenerInfosFail
  | LoadStiffenerInfosSuccess
  | UpdateStiffenerInfo
  | UpdateStiffenerInfoFail
  | UpdateStiffenerInfoSuccess
  | AddStiffenerInfo
  | AddStiffenerInfoFail
  | AddStiffenerInfoSuccess
  | DeleteStiffenerInfos
  | DeleteStiffenerInfosFail
  | DeleteStiffenerInfosSuccess
  | CopyStiffenerInfo
  | CopyStiffenerInfoFail
  | CopyStiffenerInfoSuccess;
