import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ForgeContentService } from '@services/forge-content.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Action, Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { BinaryTaskAction } from '@services/binary-task-manager.service';
import { ApiError } from '@models/errors/api-error';
import { ContentFile, ThumbnailFile } from '@models/fabrication/files';
import { Config } from '@models/fabrication/config';
import { SystemInfoActionTypes } from '@store/actions/system-info.action';
import { ConnectorInfoActionTypes } from '@store/actions/connector-info.action';
import { DamperInfoActionTypes } from '@store/actions/damper-info.action';
import { StiffenerInfoActionTypes } from '@store/actions/stiffener-info.action';
import { MaterialActionTypes } from '@store/actions/material.action';
import { MaterialSpecActionTypes } from '@store/actions/material-spec.action';
import { SpecificationInfoActionTypes } from '@store/actions/specifications.action';
import { InsulationSpecificationInfoActionTypes } from '@store/actions/insulation-specs.action';
import { PartActionTypes } from '@store/actions/part.action';
import { ServiceTemplateInfoActionTypes } from '@store/actions/service-template-info.action';
import { StiffenerSpecificationInfoActionTypes } from '@store/actions/stiffener-spec.action';
import { UpdateDataElementAction } from '@store/actions/base/data-element.action';

import { SetBinaryTaskData } from '@store/actions/application.action';
import { CacheUpdateType } from '@models/cache/cache';
import { TriggerCacheDataUpdate } from '@store/actions/cache-data.action';
import { ActivityActionType } from '@models/activities-events/activities';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';

import { ContentItem } from '@adsk/content-sdk';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { SchemaService } from '@services/schema.service';
import { ValidatorService } from '@services/validator.service';
import { Operation } from '@constants/operations-types';

@Injectable()
export class UpdateDataElementEffects {
  updateDataElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemInfoActionTypes.UpdateSystemInfo,
        ConnectorInfoActionTypes.UpdateConnectorInfo,
        DamperInfoActionTypes.UpdateDamperInfo,
        StiffenerInfoActionTypes.UpdateStiffenerInfo,
        SystemInfoActionTypes.UpdateSystemInfo,
        ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfo,
        PartActionTypes.UpdatePart,
        MaterialActionTypes.UpdateMaterial,
        MaterialSpecActionTypes.UpdateMaterialSpec,
        SpecificationInfoActionTypes.UpdateSpecificationInfo,
        InsulationSpecificationInfoActionTypes.UpdateInsulationSpecificationInfo,
        StiffenerSpecificationInfoActionTypes.UpdateStiffenerSpecificationInfo
      ),
      switchMap((action: UpdateDataElementAction<ForgeContentDataElement>) =>
        combineLatest([of(action), this.store$.select(selectCurrentConfig).pipe(take(1))])
      ),
      switchMap((setupData: [UpdateDataElementAction<ForgeContentDataElement>, Config]) => {
        const [action, config] = setupData;
        const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
          action.dataElementType
        );
        return this.runUpdateDataElementActions(
          action,
          config,
          dynamicDataSetup.options.filesAreReferenced
        );
      }),
      switchMap((actions: Action[]) => actions)
    )
  );

  private runUpdateDataElementActions = (
    action: UpdateDataElementAction<ForgeContentDataElement>,
    config: Config,
    filesAreReferenced: boolean
  ): Observable<Action[]> => {
    const dispatchActions: Action[] = [];

    const actionSchema =
      action.schema || this.schemaService.getSchemaByDataElementType(action.dataElementType);
    if (!this.validatorService.validateForgeContentData(action.dataElement, actionSchema)) {
      this.dataElementEffectService.postFailureNotification(
        Operation.UPDATE,
        action.dataElementType,
        null
      );
      dispatchActions.push(action.failAction);
      return of(dispatchActions);
    }

    return this.dataElementEffectService.contentFilesRequired(action.dataElement).pipe(
      switchMap((contentFiles: [ContentFile[], ThumbnailFile[]]) => {
        const [files, thumbnails] = contentFiles;
        return this.forgeContentService.updateContent(
          config,
          action.dataElement,
          action.dataElementType,
          filesAreReferenced ? null : files,
          filesAreReferenced ? null : thumbnails,
          filesAreReferenced,
          null,
          false,
          action.updateThumbnails
        );
      }),
      map((data: [ForgeContentDataElement, ContentItem] | ApiError) => {
        if (!(data as ApiError).errors) {
          const [successData, response] = data as [ForgeContentDataElement, ContentItem];
          action.successAction.dataElement = successData;
          dispatchActions.push(
            action.successAction,
            // web hook
            new SetBinaryTaskData({
              entityId: successData.id,
              entityType: 'content',
              action: BinaryTaskAction.Update,
              externalIds: [successData.externalId],
              schemaId: successData.extensionDataType,
              jobType: BinaryJobType.BinaryUpdate,
              oldPath: action.oldPath,
              updatedReferenceContentIds: action.updatedReferenceContentIds,
            }),
            ...(action.ignoreCache
              ? []
              : [
                  // cache update
                  new TriggerCacheDataUpdate({
                    dataElementType: action.dataElementType,
                    dataUrn: successData.id,
                    cacheUpdateType: CacheUpdateType.Update,
                    response,
                    nodeId: action.nodeId,
                    isExternalNodeId: action.isExternalNodeId,
                  }),
                ]),
            ...(action.ignoreActivities
              ? []
              : [
                  this.dataElementEffectService.createActivitySubmissionAction(
                    config.id,
                    action.dataElementType,
                    [
                      {
                        activityType: ActivityActionType.Update,
                        urn: successData.id,
                        name: successData.name,
                      },
                    ],
                    action.nodeId
                  ),
                ])
          );

          this.dataElementEffectService.navigateBackToPreviousMasterPage(successData, action);
        } else {
          const failureData = data as ApiError;
          dispatchActions.push(action.failAction);
          this.dataElementEffectService.postFailureNotification(
            Operation.UPDATE,
            action.dataElementType,
            failureData
          );
        }

        return dispatchActions;
      })
    );
  };

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private dataElementEffectService: DataElementEffectService,
    private schemaService: SchemaService,
    private validatorService: ValidatorService
  ) {}
}
