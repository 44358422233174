import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromInsulationSpecs from '../reducers/insulation-specs.reducer';

// spec list state
export const selectInsulationSpecsState = (state: FDMState) => state.configsState.insulationSpecs;

// spec list selectors
export const selectInsulationSpecIds = createSelector(
  selectInsulationSpecsState,
  fromInsulationSpecs.selectInsulationSpecIds
);

export const selectInsulationSpecEntities = createSelector(
  selectInsulationSpecsState,
  fromInsulationSpecs.selectInsulationSpecEntities
);

export const selectAllInsulationSpecs = createSelector(
  selectInsulationSpecsState,
  fromInsulationSpecs.selectAllInsulationSpecs
);

export const selectInsulationSpecsTotal = createSelector(
  selectInsulationSpecsState,
  fromInsulationSpecs.selectInsulationSpecsTotal
);

export const selectInsulationSpecById = (id: string) =>
  createSelector(
    selectInsulationSpecEntities,
    (insulationSpecInfoEntities) => insulationSpecInfoEntities[id]
  );
