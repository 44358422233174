import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private environmentService: EnvironmentService) {}

  getFeedbackLink(): string {
    return this.environmentService.environment.navSubModuleHelp.feedbackUrl;
  }

  openFeedbackInNewWindow(): void {
    window.open(this.getFeedbackLink(), '_blank');
  }
}
