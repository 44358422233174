import { Action } from '@ngrx/store';
import { DataLoadingAction, DataLoadingState } from './base/data-loading.action';
import { AppDependencyType } from '@constants/app-dependency-types';

export enum AppDependencyActionTypes {
  LoadAppDependency = '[AppDependency API] Load AppDependency',
  LoadAppDependencyFail = '[AppDependency API] Load AppDependency Fail',
  LoadAppDependencySuccess = '[AppDependency API] Load AppDependency Success',
  SetLoadingAppDependency = '[AppDependency API] Set Loading AppDependency',
  SetLoadedAppDependency = '[AppDependency API] Set Loaded AppDependency',
  SetFailedAppDependency = '[AppDependency API] Set Failed AppDependency',
}

export class LoadAppDependency implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Loading;
  readonly type = AppDependencyActionTypes.LoadAppDependency;
  constructor(public payload: { appDependencyType: AppDependencyType }) {}
}

export class LoadAppDependencyFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = AppDependencyActionTypes.LoadAppDependencyFail;
  constructor(public payload: { appDependencyType: AppDependencyType }) {}
}

export class LoadAppDependencySuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Complete;
  readonly type = AppDependencyActionTypes.LoadAppDependencySuccess;
  constructor(public payload: { appDependencyType: AppDependencyType }) {}
}

export class SetLoadedAppDependency implements Action {
  readonly type = AppDependencyActionTypes.SetLoadedAppDependency;
  constructor(public payload: { appDependencyType: AppDependencyType }) {}
}

export class SetLoadingAppDependency implements Action {
  readonly type = AppDependencyActionTypes.SetLoadingAppDependency;
  constructor(public payload: { appDependencyType: AppDependencyType; remove?: boolean }) {}
}

export class SetFailedAppDependency implements Action {
  readonly type = AppDependencyActionTypes.SetFailedAppDependency;
  constructor(
    public payload: {
      appDependencyType: AppDependencyType;
      remove?: boolean;
    }
  ) {}
}

export type AppDependencyActions =
  | LoadAppDependency
  | LoadAppDependencyFail
  | LoadAppDependencySuccess
  | SetLoadingAppDependency
  | SetLoadedAppDependency
  | SetFailedAppDependency;
