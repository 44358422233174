import { isEmpty, lowerCase } from 'lodash';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';

/**
 * NOTE: The this.translate is injected and binded via DI from provider at
 *    modules/dynamic-components.module.ts
 * thought
 *  FORMLY_CONFIG and registerTranslateFormlyValidationMessages
 */

export function requiredValidationMessage(err, field: FormlyFieldConfig) {
  return this.translate.instant(LC.VALIDATIONS.FORM.REQUIRED, {
    label: field.props.label,
  });
}

export function minlengthValidationMessage(err, field: FormlyFieldConfig) {
  return this.translate.instant(LC.VALIDATIONS.FORM.MIN_LENGTH, {
    minLength: field.props.minLength,
  });
}

export function maxlengthValidationMessage(err, field: FormlyFieldConfig) {
  return this.translate.instant(LC.VALIDATIONS.FORM.MAX_LENGTH, {
    maxLength: field.props.maxLength,
  });
}

export function minValidationMessage(err, field: FormlyFieldConfig) {
  return this.translate.instant(LC.VALIDATIONS.FORM.MIN, {
    min: field.props.min,
  });
}

export function maxValidationMessage(err, field: FormlyFieldConfig) {
  return this.translate.instant(LC.VALIDATIONS.FORM.MAX, {
    max: field.props.max,
  });
}

export function uniqueModelValidationMessage(
  uniqueFieldRestrictions: string[],
  field?: FormlyFieldConfig
) {
  const parentHasFields = field?.parent.fieldGroup.filter((o) =>
    uniqueFieldRestrictions.includes(o.key as string)
  );

  const restrictions = [...uniqueFieldRestrictions];
  const nameIndex = restrictions.indexOf('name');

  if (restrictions.length > 1 && (!isEmpty(parentHasFields) || !field)) {
    return this.translate.instant(LC.VALIDATIONS.FORM.UNIQUE_MODEL_PLURAL, {
      uniqueFieldRestrictions: getFieldRestrictionMessage(restrictions),
    });
  } else {
    return this.translate.instant(LC.VALIDATIONS.FORM.UNIQUE_MODEL_SINGLE, {
      field: restrictions[nameIndex] || field?.key,
    });
  }
}

const getFieldRestrictionMessage = (restriction: string[]): string => {
  const fieldRestrictions = [...restriction].map((x) => lowerCase(x));
  const last = fieldRestrictions.pop();
  return fieldRestrictions.join(', ') + ' and ' + last;
};
