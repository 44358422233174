import { Action } from '@ngrx/store';
import { TempModel } from '@models/temp-data/temp-model';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';

export enum TempModelActionTypes {
  UpsertTempModel = '[TempModel] Upsert Temp Model',
  DeleteTempModel = '[TempModel] Delete Temp Model',
}

export class UpsertTempModel implements Action {
  readonly type = TempModelActionTypes.UpsertTempModel;

  constructor(public payload: { data: TempModel<ForgeContentDataElement> }) {}
}

export class DeleteTempModel implements Action {
  readonly type = TempModelActionTypes.DeleteTempModel;

  constructor(public payload: { id: string }) {}
}

export type TempModelActions = UpsertTempModel | DeleteTempModel;
