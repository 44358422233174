import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { DynamicFormBaseCustomComponent } from '../dynamic-form/dynamic-form-base-custom-component';
import { FormControl, FormControlStatus } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { getErrorMessage } from '@utils/formly-text-field/formly-text-field-util';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-text-field',
  styleUrls: ['formly-text-field.component.scss'],
  templateUrl: 'formly-text-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyTextFieldComponent
  extends DynamicFormBaseCustomComponent<any>
  implements OnInit, OnDestroy
{
  currentValue: string;
  errorText: string;
  currentFormControlStatus: FormControlStatus;
  disabled: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(store$: Store<FDMState>, private translate: TranslateService) {
    super(store$);
  }

  ngOnInit() {
    this.currentValue = this.model[this.key as string];
    this.disabled = this.field.props.disabled;

    this.formControl.statusChanges
      .pipe(
        filter((status: FormControlStatus) => status === 'VALID' || status === 'INVALID'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        const errorMessage = getErrorMessage(
          this.formControl as FormControl,
          this.field as FieldTypeConfig,
          this.translate
        );
        if (errorMessage === this.errorText) return;

        this.errorText = errorMessage;
      });

    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((newValue) => {
      // update current value if model changed
      if (this.currentValue !== newValue) {
        this.currentValue = newValue || '';
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    super.ngOnDestroy();
  }

  onInputChange(newValue: string) {
    if (newValue && newValue === this.currentValue) return;

    this.currentValue = newValue;
    this.formControl.patchValue(newValue);
  }
}
