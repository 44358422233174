import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ForgeContentService } from '@services/forge-content.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Action } from '@ngrx/store';
import { BinaryTaskAction } from '@services/binary-task-manager.service';
import { ApiError } from '@models/errors/api-error';
import { ContentFile, ThumbnailFile } from '@models/fabrication/files';
import { SystemInfoActionTypes } from '@store/actions/system-info.action';
import { ConnectorInfoActionTypes } from '@store/actions/connector-info.action';
import { DamperInfoActionTypes } from '@store/actions/damper-info.action';
import { StiffenerInfoActionTypes } from '@store/actions/stiffener-info.action';
import { MaterialActionTypes } from '@store/actions/material.action';
import { MaterialSpecActionTypes } from '@store/actions/material-spec.action';
import { SpecificationInfoActionTypes } from '@store/actions/specifications.action';
import { InsulationSpecificationInfoActionTypes } from '@store/actions/insulation-specs.action';
import { PartActionTypes } from '@store/actions/part.action';
import { ServiceTemplateInfoActionTypes } from '@store/actions/service-template-info.action';
import { StiffenerSpecificationInfoActionTypes } from '@store/actions/stiffener-spec.action';
import { CopyDataElementAction } from '@store/actions/base/data-element.action';

import { SetBinaryTaskData, SkipBinaryTaskData } from '@store/actions/application.action';
import { CacheUpdateType } from '@models/cache/cache';
import { TriggerCacheDataUpdate } from '@store/actions/cache-data.action';
import { ActivityActionType } from '@models/activities-events/activities';

import { ContentItem } from '@adsk/content-sdk';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { SchemaService } from '@services/schema.service';
import { ValidatorService } from '@services/validator.service';
import { Operation } from '@constants/operations-types';

@Injectable()
export class CopyDataElementEffects {
  copyDataElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemInfoActionTypes.CopySystemInfo,
        ConnectorInfoActionTypes.CopyConnectorInfo,
        DamperInfoActionTypes.CopyDamperInfo,
        StiffenerInfoActionTypes.CopyStiffenerInfo,
        ServiceTemplateInfoActionTypes.CopyServiceTemplateInfo,
        MaterialActionTypes.CopyMaterial,
        MaterialSpecActionTypes.CopyMaterialSpec,
        SpecificationInfoActionTypes.CopySpecificationInfo,
        InsulationSpecificationInfoActionTypes.CopyInsulationSpecificationInfo,
        PartActionTypes.CopyPart,
        StiffenerSpecificationInfoActionTypes.CopyStiffenerSpecificationInfo
      ),
      switchMap((action: CopyDataElementAction<ForgeContentDataElement>) => {
        return this.runCopyDataElementActions(action);
      }),
      switchMap((actions: Action[]) => actions)
    )
  );

  private runCopyDataElementActions = (
    action: CopyDataElementAction<ForgeContentDataElement>
  ): Observable<Action[]> => {
    const actionSchema =
      action.schema || this.schemaService.getSchemaByDataElementType(action.dataElementType);
    if (!this.validatorService.validateForgeContentData(action.dataElement, actionSchema)) {
      this.dataElementEffectService.postFailureNotification(
        Operation.COPY,
        action.dataElementType,
        null
      );
      return of([action.failAction]);
    }

    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
      action.dataElementType
    );
    const filesAreReferenced = dynamicDataSetup.options.filesAreReferenced;
    return this.dataElementEffectService.contentFilesRequired(action.dataElement).pipe(
      switchMap((contentFiles: [ContentFile[], ThumbnailFile[]]) => {
        const [files, thumbnails] = contentFiles;
        return this.forgeContentService.addContent<ForgeContentDataElement>(
          action.dataElement,
          action.config,
          action.dataElementType,
          action.isExternalNodeId
            ? action.nodeId
            : dynamicDataSetup.options.fcs.dataTypeExternalNodeId,
          {
            version: action.dataElement.extensionDataVersion,
            namespace: action.dataElement.extensionDataNamespace,
            type: action.dataElement.extensionDataType,
          },
          filesAreReferenced ? null : files,
          filesAreReferenced ? null : thumbnails,
          filesAreReferenced,
          null,
          action.usesSearch
        );
      }),
      map((data: [ForgeContentDataElement, ContentItem] | ApiError) => {
        const dispatchActions = [];
        if (!(data as ApiError).errors) {
          const [successData, response] = data as [ForgeContentDataElement, ContentItem];
          action.successAction.dataElement = successData;

          dispatchActions.push(
            action.successAction,
            ...(action.ignoreCache
              ? []
              : [
                  dynamicDataSetup.options.actions.updateDataReferencesAction(
                    action.parentNode ? action.parentNode : action.config,
                    [successData.id],
                    false
                  ),
                ]),
            // binary update task
            ...(action.skipBinaryTask
              ? [
                  new SkipBinaryTaskData({
                    dataElementType: action.dataElementType,
                    completeOperation: Operation.COPY,
                  }),
                ]
              : [
                  new SetBinaryTaskData({
                    entityId: successData.id,
                    entityType: 'content',
                    action: BinaryTaskAction.Copy,
                    externalIds: [action.dataElement.externalId], // needs to be the original external id so the BU knows which entity to copy
                    schemaId: successData.extensionDataType,
                    jobType: BinaryJobType.BinaryUpdate,
                    updatedReferenceContentIds: action.updatedReferenceContentIds,
                  }),
                ]),
            ...(action.ignoreCache
              ? []
              : [
                  // cache update
                  new TriggerCacheDataUpdate({
                    dataElementType: action.dataElementType,
                    dataUrn: successData.id,
                    cacheUpdateType: CacheUpdateType.Add,
                    response,
                    nodeId: action.nodeId,
                    isExternalNodeId: action.isExternalNodeId,
                  }),
                ]),
            ...(action.ignoreActivities
              ? []
              : [
                  this.dataElementEffectService.createActivitySubmissionAction(
                    action.config.id,
                    action.dataElementType,
                    [
                      {
                        activityType: ActivityActionType.Add,
                        urn: successData.id,
                        name: successData.name,
                      },
                    ],
                    action.nodeId
                  ),
                ])
          );
          this.dataElementEffectService.navigateBackToPreviousMasterPage(successData, action);
        } else {
          const failureData = data as ApiError;
          dispatchActions.push(action.failAction);
          this.dataElementEffectService.postFailureNotification(
            Operation.COPY,
            action.dataElementType,
            failureData
          );
        }

        return dispatchActions;
      })
    );
  };

  constructor(
    private actions$: Actions,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private dataElementEffectService: DataElementEffectService,
    private schemaService: SchemaService,
    private validatorService: ValidatorService
  ) {}
}
