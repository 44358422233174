export class ErrorConstants {
  // failed operations
  static readonly APP_INTERNAL_OPERATION_ERROR =
    'An internal error occurred and the operation cannot be completed';
  // environment setup
  static readonly APP_ENVIRONMENT_SETTINGS_ERROR =
    'An error occurred creating the environment settings';
  // config error messages
  static readonly APP_ERROR_READING_CONFIG_DATA =
    'An error occurred getting the fabrication configuration data';
  static readonly APP_ERROR_CONFIG_ID_NOT_FOUND = 'The fabrication configuration was not found';
  static readonly APP_ERROR_DELETE_CONFIG =
    'An error occurred deleting the fabrication configuration';
  static readonly APP_ERROR_GETTING_CONFIG_META_DATA =
    'An error ocurred getting the fabrication configuration meta data';
  // system error messages
  static readonly APP_ERROR_LOADING_SYSTEM_LIST =
    'An error occurred loading the fabrication service list';
  static readonly APP_ERROR_LOADING_SYSTEM_TEMPLATE_LIST =
    'An error occurred loading the fabrication service template list';
  static readonly APP_ERROR_UPDATING_SYSTEM_LIST =
    'An error occurred updating the fabrication service lists';
  static readonly APP_ERROR_UPDATING_SYSTEM_TEMPLATE_LIST =
    'An error occurred updating the fabrication service template lists';
  static readonly APP_ERROR_LOADING_SYSTEM_TEMPLATE =
    'An error occurred loading the fabrication template data';
  static readonly APP_ERROR_LOADING_SYSTEM =
    'An error occurred loading the fabrication service data';
  static readonly APP_ERROR_UPDATING_SYSTEM =
    'An error occurred updating the fabrication service data';
  static readonly APP_ERROR_ADDING_SYSTEM = 'An error occurred adding the fabrication service data';
  static readonly APP_ERROR_DELETING_SYSTEM = 'An error occurred deleting fabrication service data';
  static readonly APP_ERROR_UPDATING_SYSTEM_TEMPLATE =
    'An error occurred updating the fabrication service template data';
  static readonly APP_ERROR_DOWNLOADING_SYSTEM_TEMPLATE_PARTCOLLECTION_IMAGE =
    'An error occurred downloading the fabrication template part collection image';
  static readonly APP_ERROR_ADDING_SYSTEM_TEMPLATE =
    'An error occurred adding the fabrication service template data';
  static readonly APP_ERROR_DELETING_SYSTEM_TEMPLATE =
    'An error occurred deleting fabrication service template data';
  static readonly APP_ERROR_COPING_SYSTEM_TEMPLATE =
    'An error occurred coping the fabrication service template data';
  // item folder error messages
  static readonly APP_ERROR_READING_ITEM_FOLDER_DATA =
    'An error occurred reading the fabrication item folder data';
  static readonly APP_ERROR_READING_ITEM_FOLDER_CONTENTS =
    'An error occurred reading the fabrication item folder contents';
  static readonly APP_ERROR_DOWNLOADING_ITEM_FILE_IMAGE =
    'An error occurred downloading the fabrication item file image';
  // thumbnail error messages
  static readonly APP_ERROR_DOWNLOADING_THUMBNAIL_IMAGE =
    'An error occurred downloading the thumbnail image';
  // database error messages
  static readonly APP_ERROR_LOADING_SPECS =
    'An error occurred loading the fabrication specifications';
  static readonly APP_ERROR_LOADING_INSULATION_SPECS =
    'An error occurred loading the fabrication insulation specifications';
  // connectorInfo error messages
  static readonly APP_ERROR_LOADING_CONNECTORINFOS =
    'An error occurred loading the fabrication connector information';
  // damper error messages
  static readonly APP_ERROR_LOADING_DAMPERINFOS =
    'An error occurred loading the fabrication damper information';
  // stiffenerInfo error messages
  static readonly APP_ERROR_LOADING_STIFFENERINFOS =
    'An error occurred loading the fabrication stiffener information';
  // material error message
  static readonly APP_ERROR_LOADING_MATERIALS =
    'An error occurred loading the fabrication material information';
  // material specs error message
  static readonly APP_ERROR_LOADING_MATERIAL_SPECS =
    'An error occurred loading the fabrication material specification information';
  // fabrication rate error messages
  static readonly APP_ERROR_LOADING_FABRICATION_RATES =
    'An error occurred loading the fabrication rate information';
  // installation rate error messages
  static readonly APP_ERROR_LOADING_INSTALLATION_RATES =
    'An error occurred loading the installation rate information';
  // forge units error messages
  static readonly APP_ERROR_LOADING_FORGE_UNITS = 'An error occurred loading Forge Units';
  // stiffener specs error message
  static readonly APP_ERROR_LOADING_STIFFENER_SPECS =
    'An error occurred loading the fabrication stiffener specification information';

  // geometry parsing
  static readonly APP_ERROR_LOADING_FABDM_WEB_ASSEMBLY_ENGINE =
    'An error occurred loading the Fabdm WebAssembly Engine';
  static readonly APP_ERROR_LOADING_FABRICATION_DATABASE =
    'An error occurred loading the current fabrication database';
  static readonly APP_ERROR_LOADING_FABRICATION_DATABASE_BINARY_FILES =
    'Fabrication database binary files not found in storage';
  static readonly APP_ERROR_LOADING_FABRICATION_PART_GEOMETRY =
    'Failed to load part geometry for: ';

  // binary storage
  static readonly APP_ERROR_LOADING_STORAGE_FILE = 'An error occurred loading the storage file';
  static readonly APP_ERROR_FETCHING_STORAGE_FILE =
    'An error occurred downloading the storage files';
}
// interface ErrorCodeDetails {
//   description: string;
//   errorCode: number;
// }

// export const StandardErrorCodes: StandardErrorCode = {
//   ['foo']: { description: 'string', errorCode: 123 },
//   foo1: { description: 'string', errorCode: 123 }
// };
