import * as fromSpecs from '../actions/specifications.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SpecificationInfo } from '@models/fabrication/specification-info';

export interface SpecificationsState extends EntityState<SpecificationInfo> {} // eslint-disable-line

export const specsAdapter: EntityAdapter<SpecificationInfo> =
  createEntityAdapter<SpecificationInfo>({
    selectId: (spec: SpecificationInfo) => spec.id,
  });

export const initialState: SpecificationsState = specsAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromSpecs.SpecificationsActions
): SpecificationsState {
  switch (action.type) {
    case fromSpecs.SpecificationInfoActionTypes.LoadSpecificationInfos: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.LoadSpecificationInfosSuccess: {
      return specsAdapter.upsertMany(action.data, state);
    }
    case fromSpecs.SpecificationInfoActionTypes.LoadSpecificationInfosFail: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.AddSpecificationInfo: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.AddSpecificationInfoSuccess: {
      return specsAdapter.addOne(action.dataElement, state);
    }
    case fromSpecs.SpecificationInfoActionTypes.AddSpecificationInfoFail: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.CopySpecificationInfo: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.CopySpecificationInfoSuccess: {
      return specsAdapter.addOne(action.dataElement, state);
    }
    case fromSpecs.SpecificationInfoActionTypes.CopySpecificationInfoFail: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.UpdateSpecificationInfo: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.UpdateSpecificationInfoSuccess: {
      return specsAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromSpecs.SpecificationInfoActionTypes.UpdateSpecificationInfoFail: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.DeleteSpecificationInfos: {
      return state;
    }
    case fromSpecs.SpecificationInfoActionTypes.DeleteSpecificationInfosSuccess: {
      return specsAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromSpecs.SpecificationInfoActionTypes.DeleteSpecificationInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of spec ids
  selectIds: selectSpecIds,

  // select the dictionary of spec entities
  selectEntities: selectSpecEntities,

  // select the array of specs
  selectAll: selectAllSpecs,

  // select the total spec count
  selectTotal: selectSpecsTotal,
} = specsAdapter.getSelectors();
