import * as fromPartFilters from '../actions/part-filter.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PartFilterConfig } from '@models/forge-content/part-search';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PartFilterState extends EntityState<PartFilterConfig> {}

export const PartFilterAdapter: EntityAdapter<PartFilterConfig> =
  createEntityAdapter<PartFilterConfig>({
    selectId: (data: PartFilterConfig) => data.configId,
  });

export const initialState: PartFilterState = PartFilterAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromPartFilters.PartFilterActions
): PartFilterState {
  switch (action.type) {
    case fromPartFilters.PartFilterActionTypes.UpsertPartFilter: {
      return PartFilterAdapter.upsertOne(action.payload.filter, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectPartFilterIds,
  selectEntities: selectPartFilterEntities,
  selectAll: selectAllPartFilters,
  selectTotal: selectPartFiltersTotal,
} = PartFilterAdapter.getSelectors();
