import { EnvironmentConstants } from '@constants/environment-constants';
import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { FabricationReference } from '../forge-content/references';
import { DomainType, ShapeType } from './common';

export enum SpecificationTableType {
  SingleDimension = 'SingleDimension',
  SingleDimensionPlusLength = 'SingleDimensionPlusLength',
  LongSideShortSide = 'LongSideShortSide',
  LongSideShortSidePlusLength = 'LongSideShortSidePlusLength',
}

export enum SpecificationApplicationType {
  FittingsOnly = 'FittingsOnly',
  StraightsOnly = 'StraightsOnly',
  AllTypes = 'AllTypes',
}

// specification table entries
export type SpecificationTableEntryType =
  | SpecificationPipingTableEntrySingleDimension
  | SpecificationOvalTableEntrySingleDimension
  | SpecificationRectTableEntrySingleDimension;

export class SpecificationTableEntry {
  primaryConnector = EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;
  secondaryConnector = EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;
}

export class SpecificationTableEntrySingleDimension extends SpecificationTableEntry {
  size = 0;
}

export class SpecificationPipingTableEntrySingleDimension extends SpecificationTableEntrySingleDimension {
  stdLength = 0;
  pipeOD = -1;
}

export class SpecificationOvalTableEntrySingleDimension extends SpecificationTableEntrySingleDimension {
  materialSpecification = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
}

export class SpecificationRectTableEntrySingleDimension extends SpecificationTableEntrySingleDimension {
  materialSpecification = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
}

export type SpecificationTable = SpecificationBaseTable | SpecificationTableWithLength;

export class SpecificationBaseTable {
  id?: string; // used by the relationship manager
  material = EnvironmentConstants.FCS_ANY_MATERIAL;
  shape: ShapeType;
  domain: DomainType;
  appliesTo: SpecificationApplicationType = SpecificationApplicationType.AllTypes;
  entries: SpecificationTableEntryType[];

  constructor(
    shape: ShapeType = ShapeType.Round,
    domain: DomainType = DomainType.Pipework,
    entries: SpecificationTableEntryType[]
  ) {
    this.shape = shape;
    this.domain = domain;
    this.entries = entries;
  }
}

export class SpecificationTableWithLength extends SpecificationBaseTable {
  tableLength = 0;

  constructor(
    shape: ShapeType = ShapeType.Round,
    domain: DomainType = DomainType.Pipework,
    entries: SpecificationTableEntryType[],
    length = 0
  ) {
    super(shape, domain, entries);
    this.tableLength = length;
  }
}

export class SpecificationInfo extends ForgeContentDataElement {
  category = '';
  abbreviation = '';
  tableType: SpecificationTableType;
  tables: SpecificationTable[] = [];
  fabricationReferences: FabricationReference[] = [];
  constructor(tableType: SpecificationTableType = SpecificationTableType.SingleDimension) {
    super();
    this.tableType = tableType;
  }
}

export const createSpecificationTableEntryFactory = (
  tableType: SpecificationTableType,
  shape: ShapeType,
  domain: DomainType
): SpecificationTableEntryType => {
  let entry: SpecificationTableEntryType = null;
  switch (tableType) {
    case SpecificationTableType.SingleDimension:
      entry = createSingleDimenionTableEntry(shape, domain);
      break;
    case SpecificationTableType.SingleDimensionPlusLength:
    case SpecificationTableType.LongSideShortSide:
    case SpecificationTableType.LongSideShortSidePlusLength:
      // todo
      break;
    default:
      break;
  }

  if (!entry) {
    throw new Error('Specification Table Entry Type not Currently Supported');
  }

  return entry;
};

const createSingleDimenionTableEntry = (
  shape: ShapeType,
  domain: DomainType
): SpecificationTableEntryType => {
  // note - only suported type currently is pipework/round/single dimension
  // expand logic to support other types
  // shape check not strictly needed here, added to stop TS complaining
  if (domain === DomainType.Pipework && shape === ShapeType.Round) {
    return new SpecificationPipingTableEntrySingleDimension();
  }

  throw new Error('Specification Table Entry Type not Currently Supported');
};

export const specificationTableFactory = (
  spec: SpecificationInfo,
  shape: ShapeType,
  domain: DomainType
): SpecificationTable => {
  let table: SpecificationTable = null;

  switch (spec.tableType) {
    case SpecificationTableType.SingleDimension:
      table = new SpecificationBaseTable(shape, domain, [
        createSingleDimenionTableEntry(shape, domain),
      ]);
      break;
    case SpecificationTableType.SingleDimensionPlusLength:
    case SpecificationTableType.LongSideShortSide:
    case SpecificationTableType.LongSideShortSidePlusLength:
    default:
      break;
  }

  if (!table) {
    throw new Error('Specification Table Type not Currently Supported');
  }

  return table;
};
