import * as fromTempModel from '../actions/temp-model.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TempModel } from '@models/temp-data/temp-model';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';

export interface TempModelState extends EntityState<TempModel<ForgeContentDataElement>> {} // eslint-disable-line

export const tempModelAdapter: EntityAdapter<TempModel<ForgeContentDataElement>> =
  createEntityAdapter<TempModel<ForgeContentDataElement>>({
    selectId: (tempModel: TempModel<ForgeContentDataElement>) => tempModel.id,
  });

export const initialState: TempModelState = tempModelAdapter.getInitialState();
const initialSessionState = sessionStorage.getItem('temp-models');
if (initialSessionState) {
  initialState.entities = JSON.parse(initialSessionState).entities;
  initialState.ids = JSON.parse(initialSessionState).ids;
}

export function reducer(
  state = initialState,
  action: fromTempModel.TempModelActions
): TempModelState {
  switch (action.type) {
    case fromTempModel.TempModelActionTypes.UpsertTempModel: {
      const updatedState = tempModelAdapter.upsertOne(action.payload.data, state);
      sessionStorage.setItem('temp-models', JSON.stringify(updatedState));
      return updatedState;
    }
    case fromTempModel.TempModelActionTypes.DeleteTempModel: {
      const updatedState = tempModelAdapter.removeOne(action.payload.id, state);
      sessionStorage.setItem('temp-models', JSON.stringify(updatedState));
      return updatedState;
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectTempModelIds,
  selectEntities: selectTempModelEntities,
  selectAll: selectAllTempModel,
  selectTotal: selectTempModelTotal,
} = tempModelAdapter.getSelectors();
