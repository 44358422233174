import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromCacheData from '../reducers/cache-data.reducer';
import { CacheTableEntry } from '@models/cache/cache';

export const selectCacheDataState = (state: FDMState) => state.applicationState.cacheData;

export const selectCacheDataIds = createSelector(
  selectCacheDataState,
  fromCacheData.selectCacheDataIds
);

export const selectCacheDataEntities = createSelector(
  selectCacheDataState,
  fromCacheData.selectCacheDataEntities
);

export const selectAllCacheData = createSelector(
  selectCacheDataState,
  fromCacheData.selectAllCacheData
);

export const selectCacheDataTotal = createSelector(
  selectCacheDataState,
  fromCacheData.selectCacheDataTotal
);

export const selectCacheDataById = (id: string) =>
  createSelector(selectCacheDataEntities, (cacheEntities): CacheTableEntry => cacheEntities[id]);
