import { Injectable, isDevMode } from '@angular/core';
import { serializeError } from 'serialize-error';
import * as stackTrace from 'stacktrace-js';
import { NotificationService } from './notification.service';
import { NotificationType } from '@models/notification/notification';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  newrelic: any;

  constructor(
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {
    this.setupNewRelic();
  }

  /**
   *
   * @param error
   * @param {boolean} notify
   * @param {string} notifyMessage
   * @param additionalStackError
   */
  public logError(
    error: any,
    notify = false,
    notifyMessage = '',
    additionalStackError: any = null
  ) {
    if (error instanceof TypeError) {
      console.error('There was a Type error.', error.message);
    } else if (error instanceof Error) {
      console.error('There was a general error.', error.message);
    } else {
      console.error('Nobody threw an error but something happened!', error);
    }

    if (isDevMode) {
      console.warn(error.stack);
    }

    this.log(error, additionalStackError);

    if (notify) {
      const message =
        notifyMessage !== ''
          ? notifyMessage
          : this.translate.instant(LC.NOTIFICATIONS.MSG_LOGGING_SERVICE_GENERIC_ERROR);
      this.notificationService.showBanner(message, NotificationType.Error);
    }
  }

  private setupNewRelic() {
    // setup new relic
    this.newrelic = (window as any).newrelic;
  }

  // remote logging methods
  private async log(error: any, additionalStackError: any) {
    // get real stack trace using source
    const formattedStack = [];
    const serializedError = serializeError(error);

    if (additionalStackError) {
      const additionalStack = await stackTrace.fromError(additionalStackError);
      formattedStack.push(additionalStack[1]);
    }

    const stack = await stackTrace.fromError(error);
    serializedError.stack = formattedStack.concat(stack);
    console.log(stack);

    // log to new relic if browser script available
    // remote fdm builds will include the nr browser agent script
    // this will include a 'newRelic' object that can be used to post remote logs
    if (this.newrelic) {
      this.newrelic.noticeError(serializedError);
    }
  }
}
