import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ReactFactoryComponent, ReactProp } from '../../../react-factory/react-factory.component';
import { FABAlert, FABAlertProps } from './alert.react';
import { NotificationButton, NotificationLink } from '@models/notification/notification';
import { AlertType } from '@models/ui-state/weave-notification';

@Component({
  selector: 'fab-alert',
  template: ReactFactoryComponent.template,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FABAlertComponent extends ReactFactoryComponent<FABAlertProps> {
  @Input() @ReactProp('INPUT') message: string;
  @Input() @ReactProp('INPUT') severity: AlertType;
  @Input() @ReactProp('INPUT') isVisible: boolean;
  @Input() @ReactProp('INPUT') buttons: NotificationButton[];
  @Input() @ReactProp('INPUT') link: NotificationLink;

  @Output() @ReactProp('OUTPUT') onBannerDismiss = new EventEmitter<string>();

  constructor(injector: Injector) {
    super(injector);
  }

  getComponent() {
    return FABAlert;
  }
}
