import {
  SearchContentItemsResponse,
  FacetBucket,
  ContentItemFacetPostResponse,
} from '@adsk/content-sdk';
import {
  FacetResponse,
  ForgeDataFacets,
  ForgeDataSearchOption,
  ForgeDataSearchResponse,
  ForgeFacetsOption,
  ForgeFacetsOptionData,
  ForgeFacetsSelectedOption,
  ForgeSearchConfig,
  ForgeSearchEventType,
} from '@models/forge-content/forge-data-search';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { ForgeContentService } from './forge-content.service';
import { Config } from '@models/fabrication/config';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import {
  WeaveSelectOption,
  WeaveSelectOptionItem,
} from '@shared/hig/component/select/select.component';

export abstract class ForgeSearchService<
  Data,
  FacetType extends string,
  FacetOptionData extends ForgeFacetsOptionData
> {
  dataLoading: Subject<ForgeSearchEventType> = new Subject<ForgeSearchEventType>();
  dataChanged: Subject<ForgeDataSearchResponse<Data, FacetType>> = new Subject<
    ForgeDataSearchResponse<Data, FacetType>
  >();
  facetsChanged: Subject<ForgeDataSearchResponse<Data, FacetType>> = new Subject<
    ForgeDataSearchResponse<Data, FacetType>
  >();
  dataDeleted: Subject<string[]> = new Subject<string[]>();

  errorStatus: 'none' | 'firstPage' | 'nextPage' | 'resolver' = 'none';

  abstract readonly searchConfig: ForgeSearchConfig<FacetType>;

  constructor(protected forgeContentService: ForgeContentService) {}

  abstract getOption(config: Config, offset: number): Observable<ForgeDataSearchOption>;
  protected abstract updateSearchQueryAction(config: Config, query: string): void;
  protected abstract updateSelectedFacetsAction(
    config: Config,
    selectedFacets: ForgeFacetsSelectedOption<FacetType, FacetOptionData>
  ): void;

  protected abstract getFormattedData(
    config: Config,
    response: SearchContentItemsResponse
  ): Observable<Data[]>;
  protected abstract getFormattedFacets(
    config: Config,
    response: SearchContentItemsResponse
  ): Observable<ForgeDataFacets<FacetType>>;
  protected abstract getMergedFacets(
    config: Config,
    response: SearchContentItemsResponse
  ): Observable<ForgeDataFacets<FacetType>>;

  protected getFacetValues(selectedOptions: WeaveSelectOptionItem<FacetOptionData>[]): string[] {
    return (selectedOptions || []).reduce(
      (prev, curr) => [...prev, curr.key, ...(curr.extraKeys || [])],
      []
    );
  }

  protected getFacetLabels(selectedOptions: WeaveSelectOptionItem<FacetOptionData>[]): string[] {
    return selectedOptions?.length ? selectedOptions.map((x) => x.label) : [];
  }

  loadNextPage(config: Config, offset: number): Observable<Data[]> {
    this.errorStatus = 'none';
    return this.getOption(config, offset).pipe(
      tap(() => this.dataLoading.next(ForgeSearchEventType.NEXT_PAGE)),
      switchMap((option) => this.forgeContentService.dataSearch(option)),
      switchMap((response) => this.getFormattedData(config, response)),
      catchError((err) => {
        this.errorStatus = 'nextPage';
        throw err;
      })
    );
  }

  fetchFirstPage(
    config: Config,
    event: ForgeSearchEventType
  ): Observable<ForgeDataSearchResponse<Data, FacetType>> {
    this.errorStatus = 'none';
    return this.getOption(config, 0).pipe(
      tap(() => this.dataLoading.next(event)),
      switchMap((option) => this.forgeContentService.dataSearch(option)),
      switchMap((response: SearchContentItemsResponse) =>
        combineLatest([
          this.getFormattedData(config, response),
          this.getMergedFacets(config, response),
          of(response.pagination?.totalResults || 0),
        ])
      ),
      map(
        (data): ForgeDataSearchResponse<Data, FacetType> => ({
          data: data[0],
          facets: data[1],
          totalResults: data[2],
        })
      ),
      tap((response) => {
        this.dataChanged.next(response);
        this.facetsChanged.next(response);
      }),
      catchError(() => {
        this.errorStatus = 'firstPage';
        // keep the facets, but update to show 0 results
        const response = {
          data: [],
          facets: null,
          totalResults: 0,
        };

        this.dataChanged.next(response);
        return of(response);
      })
    );
  }

  updateSearchQuery(
    config: Config,
    query: string
  ): Observable<ForgeDataSearchResponse<Data, FacetType>> {
    this.updateSearchQueryAction(config, query);
    return this.fetchFirstPage(config, ForgeSearchEventType.QUERY_CHANGED);
  }

  updateSelectedFacets(
    config: Config,
    selectedFacets: ForgeFacetsSelectedOption<FacetType, FacetOptionData>
  ): Observable<ForgeDataSearchResponse<Data, FacetType>> {
    this.updateSelectedFacetsAction(config, selectedFacets);
    return this.fetchFirstPage(config, ForgeSearchEventType.FACET_CHANGED);
  }

  deleteDataById(ids: string[]): void {
    this.dataDeleted.next(ids);
  }

  protected getFacets(config: Config): Observable<ContentItemFacetPostResponse> {
    return this.getOption(config, 0).pipe(
      switchMap((option) => {
        option.filter.excludeData = true;
        option.filter.query = '';
        option.filter.facets.forEach((f) => {
          f.value = [];
          f.excludeResponse = false;
        });
        return this.forgeContentService.dataSearch(option);
      })
    );
  }

  //Update the count of the initial facets (all facets) with the filters facets count
  protected mergeFacets(initialFacet: FacetResponse[], newFacet: FacetResponse[]): FacetResponse[] {
    if (!initialFacet.length) return newFacet;

    const newFacetMap: { [key: string]: FacetResponse } = newFacet.reduce(
      (prev, curr) => ({ ...prev, [curr.key]: curr }),
      {}
    );

    const resultFacet = initialFacet.map((facet) => {
      const newFacet = { ...facet };
      if (newFacetMap[newFacet.key]) {
        newFacet.count = newFacetMap[newFacet.key].count;
        delete newFacetMap[newFacet.key];
      } else {
        newFacet.count = 0;
      }
      return newFacet;
    });

    resultFacet.push(...Object.values(newFacetMap));

    return resultFacet;
  }

  //Provided the PCS response get the facet value/options by the given key
  protected getFacetByKey(result: SearchContentItemsResponse, key: string): FacetBucket[] {
    return result.facets[`${key}`]?.buckets || [];
  }

  convertFacetToOptions(
    facets: ForgeDataFacets<FacetType>
  ): ForgeFacetsOption<FacetType, FacetOptionData> {
    return Object.entries(facets)
      .map(([key, facetValues]: [FacetType, FacetResponse[]]) => {
        return [
          key,
          facetValues.map(
            (facet): WeaveSelectOption<FacetOptionData> => ({
              item: {
                key: facet.key,
                label: facet.label,
                extraKeys: facet.extraKeys,
                data: {
                  count: facet.count,
                  isUnassigned: facet.isUnassigned,
                } as FacetOptionData,
              },
            })
          ),
        ];
      })
      .reduce(
        (prev, curr) => ({ ...prev, [curr[0] as string]: curr[1] }),
        {} as ForgeFacetsOption<FacetType, FacetOptionData>
      );
  }
}
