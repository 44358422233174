import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';

@Component({
  selector: 'fab-auth',
  template: '',
})
// component is used as part of the oauth2 login process
// auth route is called by server on successful login
// cookie is sent from server containing auth details
export class AuthComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private loggingService: LoggingService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const redirectLocation = this.route.snapshot.queryParams.redirect;

    if (redirectLocation) {
      if (redirectLocation === 'login') {
        this.authService.navigateToSignInWindow();
      } else if (redirectLocation === 'app') {
        console.log('redirect to app');
        this.authService
          .processNewLogin(this.route.snapshot.queryParams.code)
          .subscribe((isValidAuth: boolean) => {
            if (isValidAuth) {
              // Redirect to data component if auth processed successfully
              this.router.navigate(['/data']);
            } else {
              const errorMessage = this.translate.instant(LC.NOTIFICATIONS.MSG_AUTH_ERROR_RESPONSE);
              const notifyMessage = this.translate.instant(
                LC.NOTIFICATIONS.MSG_AUTH_ERROR_RESPONSE_TRY_AGAIN
              );
              this.translate.get([errorMessage, notifyMessage]).subscribe((translations) => {
                this.loggingService.logError(
                  new Error(translations[errorMessage]),
                  true,
                  `${translations[errorMessage]}${translations[notifyMessage]}`
                );
              });
            }
          });
      }
    } else {
      this.authService.navigateToSignOutWindow();
    }
  }
}
