import { Action } from '@ngrx/store';
import { ItemFolderTreeData } from '@models/ui-state/item-folder-tree-data';

export enum ItemFolderTreeActionTypes {
  UpsertItemFolderTreeData = '[ItemFolderTree] Upsert Item Folder Tree Data',
}

export class UpsertItemFolderTreeData implements Action {
  readonly type = ItemFolderTreeActionTypes.UpsertItemFolderTreeData;

  constructor(public payload: ItemFolderTreeData) {}
}

export type ItemFolderTreeActions = UpsertItemFolderTreeData;
