import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DBFile } from '@models/fabrication/db-file';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum DBFileActionTypes {
  LoadDBFiles = '[DB File API] Load DB Files',
  LoadDBFilesFail = '[DB File API] Load DB Files Fail',
  LoadDBFilesSuccess = '[DB File API] Load DB Files Success',
  UpdateDBFile = '[DB File API] Update DBFile',
  UpdateDBFileFail = '[DB File API] Update DBFile Fail',
  UpdateDBFileSuccess = '[DB File API] Update DBFile Success',
}

export class LoadDBFiles implements LoadDataElementsAction<DBFile> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadDBFilesSuccess();
  failAction = new LoadDBFilesFail();
  readonly type = DBFileActionTypes.LoadDBFiles;
  dataElementType = DataElementType.DBFile;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CORE_FILES;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadDBFilesFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DBFileActionTypes.LoadDBFilesFail;
}

export class LoadDBFilesSuccess implements LoadDataElementsSuccessAction<DBFile> {
  dataLoadingState = DataLoadingState.Complete;
  data: DBFile[];
  readonly type = DBFileActionTypes.LoadDBFilesSuccess;
}

export class UpdateDBFile implements UpdateDataElementAction<DBFile> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: DBFile;
  successAction = new UpdateDBFileSuccess();
  failAction = new UpdateDBFileFail();
  readonly type = DBFileActionTypes.UpdateDBFile;
  dataElementType = DataElementType.ServiceTemplate;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CORE_FILES;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: DBFile }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateDBFileFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = DBFileActionTypes.UpdateDBFileFail;
}

export class UpdateDBFileSuccess implements UpdateDataElementSuccessAction<DBFile> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: DBFile;
  readonly type = DBFileActionTypes.UpdateDBFileSuccess;
}

export type DBFileActions =
  | LoadDBFiles
  | LoadDBFilesFail
  | LoadDBFilesSuccess
  | UpdateDBFile
  | UpdateDBFileSuccess
  | UpdateDBFileFail;
