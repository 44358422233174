import { Action } from '@ngrx/store';
import { ContentNode } from '@models/fabrication/content-node';
import { Config } from '@models/fabrication/config';
import { Update } from '@ngrx/entity';

export enum ContentNodeActionTypes {
  LoadContentNode = '[ContentNode API] Load Content Node ',
  LoadContentNodeFail = '[ContentNode API] Load Content Node Fail',
  LoadContentNodeSuccess = '[ContentNode API] Load Content Node Success',
  UpdateContentNodeReferences = '[ContentNode API] Update Item Folder References',
}

export class LoadContentNode implements Action {
  parentNodeExternalId: string | null;
  parentNode: ContentNode | null;
  isTopLevel: boolean;
  config: Config;
  successAction = new LoadContentNodeSuccess();
  failAction = new LoadContentNodeFail();
  readonly type = ContentNodeActionTypes.LoadContentNode;

  constructor(
    public payload: {
      parentNodeExternalId: string | null;
      parentNode: ContentNode | null;
      config: Config;
      isTopLevel: boolean;
    }
  ) {
    this.config = payload.config;
    this.isTopLevel = payload.isTopLevel;
    this.parentNodeExternalId = payload.parentNodeExternalId;
    this.parentNode = payload.parentNode;
  }
}

export class LoadContentNodeFail implements Action {
  readonly type = ContentNodeActionTypes.LoadContentNodeFail;
}

export class LoadContentNodeSuccess implements Action {
  data: ContentNode[];
  readonly type = ContentNodeActionTypes.LoadContentNodeSuccess;
}

export class UpdateContentNodeReferences implements Action {
  readonly type = ContentNodeActionTypes.UpdateContentNodeReferences;
  constructor(public payload: Update<ContentNode>) {}
}

export type ContentNodeActions =
  | LoadContentNode
  | LoadContentNodeFail
  | LoadContentNodeSuccess
  | UpdateContentNodeReferences;
