<div class="row no-gutters mb-1 custom-toggle__container">
  <mat-form-field class="custom-toggle__item">
    <mat-label>{{ props.label }}</mat-label>
    <input
      matInput
      [readonly]="isReadOnly"
      type="text"
      [placeholder]="props.placeholder"
      [(ngModel)]="currentValue"
      value="{{ stringifyUnits() }}"
      [attr.data-text]="stringifyUnits()"
      disabled="{{ model[key.toString()].option !== 'Value' }}"
      (blur)="onInputChange()"
      tabindex="-1"
    />
  </mat-form-field>
  <!-- [(ngModel)]="model[key.toString()].value" -->

  <mat-slide-toggle
    [checked]="model[key.toString()].option !== 'Value'"
    (change)="onToggleChange($event)"
    class="custom-toggle__item"
    [disabled]="isReadOnly"
  >
    {{ props.toggleValueOptions.message }}
  </mat-slide-toggle>
</div>
