import * as fromInvalidData from '../actions/invalid-data.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { InvalidData } from '@models/fabrication/invalid-data';
import {
  AddInvalidDataSuccess,
  DeleteInvalidDataSuccess,
  FixInvalidDataSuccess,
  LoadInvalidDataSuccess,
} from '../actions/invalid-data.action';

export interface InvalidDataState extends EntityState<InvalidData> {} // eslint-disable-line

export const invalidDataAdapter: EntityAdapter<InvalidData> = createEntityAdapter<InvalidData>({
  selectId: (data: InvalidData) => data.id,
});

export const initialState: InvalidDataState = invalidDataAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromInvalidData.InvalidDataActions
): InvalidDataState {
  switch (action.type) {
    case fromInvalidData.InvalidDataActionTypes.LoadInvalidData:
    case fromInvalidData.InvalidDataActionTypes.LoadInvalidDataFail:
    case fromInvalidData.InvalidDataActionTypes.DeleteInvalidData:
    case fromInvalidData.InvalidDataActionTypes.DeleteInvalidDataFail:
    case fromInvalidData.InvalidDataActionTypes.FixInvalidData:
    case fromInvalidData.InvalidDataActionTypes.FixInvalidDataFail:
    default: {
      return state;
    }

    case fromInvalidData.InvalidDataActionTypes.LoadInvalidDataSuccess: {
      return invalidDataAdapter.upsertMany((action as LoadInvalidDataSuccess).data, state);
    }

    case fromInvalidData.InvalidDataActionTypes.AddInvalidDataSuccess: {
      return invalidDataAdapter.addOne((action as AddInvalidDataSuccess).dataElement, state);
    }

    case fromInvalidData.InvalidDataActionTypes.DeleteInvalidDataSuccess: {
      return invalidDataAdapter.removeMany(
        (action as DeleteInvalidDataSuccess).removedDataElementIds,
        state
      );
    }

    case fromInvalidData.InvalidDataActionTypes.FixInvalidDataSuccess: {
      return invalidDataAdapter.removeMany(
        [(action as FixInvalidDataSuccess).dataElement.id],
        state
      );
    }
  }
}

// entity selectors
export const {
  // select the array of invalid data ids
  selectIds: selectInvalidDataIds,

  // select the dictionary of invalid data entities
  selectEntities: selectInvalidDataEntities,

  // select the array of invalid data
  selectAll: selectAllInvalidData,

  // select the total invalid data count
  selectTotal: selectInvalidDataTotal,
} = invalidDataAdapter.getSelectors();
