export enum DynamicFormCustomComponentType {
  ServiceTemplateInfoPalettes = 'service-template-info-pallettes',
  ServiceTemplateInfoParts = 'service-template-info-parts',
  ServiceTemplateInfoSizeRestrictions = 'service-template-info-size-restrictions',
  ConnectorBreakPointTable = 'connector-break-point-table',
  ToggleValue = 'toggle-value',
  MaterialSpecificationSizeTable = 'material-spec-size-table',
  MultiReferenceSelect = 'multi-reference-select',
  PartSpecTables = 'part-spec-tables',
  PartSpecTable = 'part-spec-table',
  InsSpecTables = 'ins-spec-tables',
  InsSpecTable = 'ins-spec-table',
  DropdownTypeaheadVariant = 'dropdown-typeahead-variant',
  PartCollectionTable = 'part-table',
  PartDimensionsTable = 'part-dimensions-table',
  FormlyTextField = 'formly-text-field',
  PartConnectorsForm = 'part-connectors-form',
}
