import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromThumbnailFile from '../reducers/thumbnail-file.reducer';

export const selectThumbnailFileState = (state: FDMState) => state.configsState.thumbnailFiles;

export const selectThumbanilFileIds = createSelector(
  selectThumbnailFileState,
  fromThumbnailFile.selectThumbnailFileIds
);

export const selectThumbnailFileEntities = createSelector(
  selectThumbnailFileState,
  fromThumbnailFile.selectThumbnailFileEntities
);

export const selectAllThumbnailFile = createSelector(
  selectThumbnailFileState,
  fromThumbnailFile.selectAllThumbnailFile
);

export const selectThumbnailFileTotal = createSelector(
  selectThumbnailFileState,
  fromThumbnailFile.selectThumbnailFileTotal
);

export const selectThumbnailFileById = (id: string) =>
  createSelector(selectThumbnailFileEntities, (thumbnailFileEntities) => thumbnailFileEntities[id]);

export const selectThumbnailFilesById = (ids: string[]) =>
  createSelector(selectThumbnailFileEntities, (thumbnailFileEntities) => {
    if (ids?.length) {
      return ids.map((x) => thumbnailFileEntities[x]);
    }

    return [];
  });
