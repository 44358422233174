import { Toast } from '@models/ui-state/weave-notification';
import { notificationToastContainerPlacements } from '@weave-mui/enums';
import { NotificationToastProps } from '@weave-mui/notification-toast';
import NotificationToastContainer from '@weave-mui/notification-toast-container';
import { FABNotificationToast } from './toast-notification.react';

export type FABToastListProps = NotificationToastProps & {
  toastList: Toast[];
  onDismissToast: (id: string) => void;
};

export const FABToastList: React.FC<FABToastListProps> = (props) => {
  const { toastList, onDismissToast, ...notificationToastProps } = props;
  const toastListComponents = toastList.map((toast) => (
    <FABNotificationToast toast={toast} onDismissToast={onDismissToast} key={toast.id} />
  ));

  return (
    <NotificationToastContainer
      {...notificationToastProps}
      placement={notificationToastContainerPlacements.BOTTOM_RIGHT}
    >
      {toastListComponents}
    </NotificationToastContainer>
  );
};
