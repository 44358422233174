import { ServiceTemplateInfo } from '@models/fabrication/service-template-info';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementSuccessAction,
  UpdateDataElementFailAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  DeleteDataElementsSuccessAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum ServiceTemplateInfoActionTypes {
  LoadServiceTemplateInfos = '[Service Template Info API] Load ServiceTemplateInfos',
  LoadServiceTemplateInfosFail = '[Service Template Info API] Load ServiceTemplateInfos Fail',
  LoadServiceTemplateInfosSuccess = '[Service Template Info API] Load ServiceTemplateInfos Success',
  UpdateServiceTemplateInfo = '[Service Template Info API] Update ServiceTemplateInfo',
  UpdateServiceTemplateInfoFail = '[Service Template Info API] Update ServiceTemplateInfo Fail',
  UpdateServiceTemplateInfoSuccess = '[Service Template Info API] Update ServiceTemplateInfo Success',
  AddServiceTemplateInfo = '[Service Template Info API] Add ServiceTemplateInfo',
  AddServiceTemplateInfoFail = '[Service Template Info API] Add ServiceTemplateInfo Fail',
  AddServiceTemplateInfoSuccess = '[Service Template Info API] Add ServiceTemplateInfo Success',
  DeleteServiceTemplateInfos = '[Service Template Info API] Delete ServiceTemplateInfos',
  DeleteServiceTemplateInfosFail = '[Service Template Info API] Delete ServiceTemplateInfos Fail',
  DeleteServiceTemplateInfosSuccess = '[Service Template Info API] Delete ServiceTemplateInfos Success',
  CopyServiceTemplateInfo = '[Service Template Info API] Copy ServiceTemplateInfo',
  CopyServiceTemplateInfoFail = '[Service Template Info API] Copy ServiceTemplateInfo Fail',
  CopyServiceTemplateInfoSuccess = '[Service Template Info API] Copy ServiceTemplateInfo Success',
}

export class LoadServiceTemplateInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ServiceTemplateInfoActionTypes.LoadServiceTemplateInfosFail;
}

export class LoadServiceTemplateInfosSuccess
  implements LoadDataElementsSuccessAction<ServiceTemplateInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  data: ServiceTemplateInfo[];
  readonly type = ServiceTemplateInfoActionTypes.LoadServiceTemplateInfosSuccess;
}

export class LoadServiceTemplateInfos implements LoadDataElementsAction<ServiceTemplateInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadServiceTemplateInfosSuccess();
  failAction = new LoadServiceTemplateInfosFail();
  readonly type = ServiceTemplateInfoActionTypes.LoadServiceTemplateInfos;
  dataElementType = DataElementType.ServiceTemplate;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class UpdateServiceTemplateInfo implements UpdateDataElementAction<ServiceTemplateInfo> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: ServiceTemplateInfo;
  successAction = new UpdateServiceTemplateInfoSuccess();
  failAction = new UpdateServiceTemplateInfoFail();
  readonly type = ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfo;
  dataElementType = DataElementType.ServiceTemplate;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE;
  isExternalNodeId = true;
  updatedReferenceContentIds: string[] = [];

  constructor(
    public payload: { dataElement: ServiceTemplateInfo; updatedReferenceContentIds: string[] }
  ) {
    this.dataElement = payload.dataElement;
    this.updatedReferenceContentIds = payload.updatedReferenceContentIds;
  }
}

export class UpdateServiceTemplateInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfoFail;
}

export class UpdateServiceTemplateInfoSuccess
  implements UpdateDataElementSuccessAction<ServiceTemplateInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ServiceTemplateInfo;
  readonly type = ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfoSuccess;
}

export class AddServiceTemplateInfo implements AddDataElementAction<ServiceTemplateInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: ServiceTemplateInfo;
  successAction = new AddServiceTemplateInfoSuccess();
  failAction = new AddServiceTemplateInfoFail();
  dataElementType = DataElementType.ServiceTemplate;
  skipBinaryTask = false;
  readonly type = ServiceTemplateInfoActionTypes.AddServiceTemplateInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE;
  isExternalNodeId = true;
  updatedReferenceContentIds: string[] = [];

  constructor(
    public payload: {
      config: Config;
      dataElement: ServiceTemplateInfo;
      updatedReferenceContentIds: string[];
    }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.updatedReferenceContentIds = payload.updatedReferenceContentIds;
  }
}

export class CopyServiceTemplateInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ServiceTemplateInfoActionTypes.CopyServiceTemplateInfoFail;
}

export class CopyServiceTemplateInfoSuccess
  implements CopyDataElementSuccessAction<ServiceTemplateInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ServiceTemplateInfo;
  readonly type = ServiceTemplateInfoActionTypes.CopyServiceTemplateInfoSuccess;
}

export class CopyServiceTemplateInfo implements CopyDataElementAction<ServiceTemplateInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: ServiceTemplateInfo;
  successAction = new CopyServiceTemplateInfoSuccess();
  failAction = new CopyServiceTemplateInfoFail();
  dataElementType = DataElementType.ServiceTemplate;
  skipBinaryTask = false;
  readonly type = ServiceTemplateInfoActionTypes.CopyServiceTemplateInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE;
  isExternalNodeId = true;
  updatedReferenceContentIds: string[] = [];

  constructor(
    public payload: {
      config: Config;
      dataElement: ServiceTemplateInfo;
      updatedReferenceContentIds: string[];
    }
  ) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.updatedReferenceContentIds = payload.updatedReferenceContentIds;
  }
}

export class AddServiceTemplateInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ServiceTemplateInfoActionTypes.AddServiceTemplateInfoFail;
}

export class AddServiceTemplateInfoSuccess
  implements AddDataElementSuccessAction<ServiceTemplateInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ServiceTemplateInfo;
  readonly type = ServiceTemplateInfoActionTypes.AddServiceTemplateInfoSuccess;
}

export class DeleteServiceTemplateInfos implements DeleteDataElementsAction<ServiceTemplateInfo> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: ServiceTemplateInfo[];
  successAction = new DeleteServiceTemplateInfosSuccess();
  failAction = new DeleteServiceTemplateInfosFail();
  dataElementType = DataElementType.ServiceTemplate;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE;
  isExternalNodeId = true;
  readonly type = ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfos;

  constructor(public payload: { config: Config; dataElements: ServiceTemplateInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteServiceTemplateInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfosFail;
}

export class DeleteServiceTemplateInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfosSuccess;
}

export type ServiceTemplateInfoActions =
  | LoadServiceTemplateInfos
  | LoadServiceTemplateInfosFail
  | LoadServiceTemplateInfosSuccess
  | UpdateServiceTemplateInfo
  | UpdateServiceTemplateInfoFail
  | UpdateServiceTemplateInfoSuccess
  | AddServiceTemplateInfo
  | AddServiceTemplateInfoFail
  | AddServiceTemplateInfoSuccess
  | DeleteServiceTemplateInfos
  | DeleteServiceTemplateInfosFail
  | DeleteServiceTemplateInfosSuccess
  | CopyServiceTemplateInfo
  | CopyServiceTemplateInfoFail
  | CopyServiceTemplateInfoSuccess;
