import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ForgeContentService } from '@services/forge-content.service';
import { Action, Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { BinaryTaskAction } from '@services/binary-task-manager.service';
import { ApiError } from '@models/errors/api-error';
import { DataElementType } from '@constants/data-element-types';
import { ContentFile, ThumbnailFile } from '@models/fabrication/files';
import { Config } from '@models/fabrication/config';
import { cloneDeep } from 'lodash';
import { FixInvalidData, InvalidDataActionTypes } from '@store/actions/invalid-data.action';
import { SetBinaryTaskData } from '@store/actions/application.action';
import { CacheUpdateType } from '@models/cache/cache';
import { TriggerCacheDataUpdate } from '@store/actions/cache-data.action';
import { ActivityActionType } from '@models/activities-events/activities';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { ContentItem } from '@adsk/content-sdk';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { SchemaService } from '@services/schema.service';
import { ValidatorService } from '@services/validator.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Operation } from '@constants/operations-types';

interface FixDataElementResult {
  config: Config;
  error?: ApiError;
  action: FixInvalidData;
  contentInfo: [any, ContentItem];
}

@Injectable()
export class FixDataElementEffects {
  fixDataElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvalidDataActionTypes.FixInvalidData),
      map((action: FixInvalidData) => {
        return action;
      }),
      switchMap((action: FixInvalidData) =>
        combineLatest([of(action), this.store$.select(selectCurrentConfig).pipe(take(1))])
      ),
      switchMap((setupData: [FixInvalidData, Config]) => {
        const [action, config] = setupData;
        const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
          action.dataElementType
        );
        return this.runFixDataElementActions(
          action,
          config,
          dynamicDataSetup.options.filesAreReferenced
        );
      }),
      switchMap((dispatchActions: any[]) => dispatchActions)
    )
  );

  private runFixDataElementActions = (
    action: FixInvalidData,
    config: Config,
    filesAreReferenced: boolean
  ): Observable<Action[]> => {
    const actionSchema =
      action.schema || this.schemaService.getSchemaByDataElementType(action.dataElementType);
    if (!this.validatorService.validateForgeContentData(action.dataElement, actionSchema)) {
      this.dataElementEffectService.postFailureNotification(
        Operation.FIX,
        action.dataElementType,
        null
      );
      return of([action.failAction]);
    }

    action.dataElement = cloneDeep(action.dataElement);
    action.dataElement.extensionDataType = action.fixSchemaType;
    action.dataElement.extensionDataVersion = action.fixSchemaVersion;

    return this.dataElementEffectService.contentFilesRequired(action.dataElement).pipe(
      switchMap((contentFiles: [ContentFile[], ThumbnailFile[]]) => {
        const [files, thumbnails] = contentFiles;
        return this.forgeContentService.updateContent(
          config,
          action.dataElement,
          action.dataElementType,
          filesAreReferenced ? null : files,
          filesAreReferenced ? null : thumbnails,
          filesAreReferenced,
          action.nodeId
        );
      }),
      map((data: [ForgeContentDataElement, ContentItem] | ApiError) => {
        return {
          config,
          action,
          error: data,
          contentInfo: data,
        } as FixDataElementResult;
      }),
      map((result: FixDataElementResult) => {
        const dispatchActions = [];
        if (result.error.errors) {
          dispatchActions.push(result.action.failAction);
          this.dataElementEffectService.postFailureNotification(
            Operation.FIX,
            result.action.dataElementType,
            result.error
          );
          return dispatchActions;
        }

        const [successData, response] = result.contentInfo;
        const action = result.action;
        const config = result.config;

        const successAction = { ...action.successAction };
        successAction.dataElement = successData;

        const addSuccessAction = { ...action.addSuccessAction };
        addSuccessAction.dataElement = successData;

        const invalidDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
          DataElementType.InvalidData
        );

        const dataTypeSetup = this.dynamicDataService.getDynamicDataSetupForType(
          DataElementTypeUtils.getDataTypeFromSchema(action.fixSchemaType, false)
        );

        dispatchActions.push(
          invalidDataSetup.options.actions.updateDataReferencesAction(
            action.config,
            [successAction.dataElement.id],
            true
          ),
          dataTypeSetup.options.actions.updateDataReferencesAction(
            action.config,
            [successAction.dataElement.id],
            false
          ),
          successAction,
          addSuccessAction,
          // web hook
          new SetBinaryTaskData({
            entityId: successData.id,
            entityType: 'content',
            action: BinaryTaskAction.Update,
            externalIds: [successData.externalId],
            schemaId: successData.extensionDataType,
            jobType: BinaryJobType.BinaryUpdate,
          }),
          // cache update to remove from invalid data bucket
          new TriggerCacheDataUpdate({
            dataElementType: DataElementType.InvalidData,
            dataUrn: successData.id,
            cacheUpdateType: CacheUpdateType.Delete,
            response,
            nodeId: action.nodeExternalId,
            isExternalNodeId: action.isExternalNodeId,
          }),
          this.dataElementEffectService.createActivitySubmissionAction(
            config.id,
            DataElementType.InvalidData,
            [
              {
                activityType: ActivityActionType.Delete,
                urn: successData.id,
                name: successData.name,
              },
            ],
            action.nodeId
          ),
          // cache update to add to correct place
          new TriggerCacheDataUpdate({
            dataElementType: action.dataElementType,
            dataUrn: successData.id,
            cacheUpdateType: CacheUpdateType.Add,
            response,
            nodeId: action.nodeExternalId,
            isExternalNodeId: action.isExternalNodeId,
          }),
          this.dataElementEffectService.createActivitySubmissionAction(
            config.id,
            action.dataElementType,
            [
              {
                activityType: ActivityActionType.Add,
                urn: successData.id,
                name: successData.name,
              },
            ]
          )
        );

        this.dataElementEffectService.postSuccessNotification(
          Operation.FIX,
          action.dataElementType
        );
        this.dataElementEffectService.navigateBackToPreviousMasterPage(successData, action);
        return dispatchActions;
      })
    );
  };

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private dataElementEffectService: DataElementEffectService,
    private schemaService: SchemaService,
    private validatorService: ValidatorService
  ) {}
}
