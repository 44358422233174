import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  Notification,
  NotificationType,
  NotificationPlace,
  NotificationHide,
  NotificationLink,
  NotificationButton,
  ShowNotificationProps,
} from '@models/notification/notification';

const WARNING_DEFAULT_MESSAGE = 'Something happened! Try again';
const ERROR_DEFAULT_MESSAGE = 'Error! Contact support';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationStateSubject = new Subject<Notification>();
  private notificationHideStateSubject = new Subject<NotificationHide>();

  /**
   * Standard to notify an error, displaying a error toast and banner .
   *
   * @param {string} message - text message in the toast and banner
   * @memberof NotificationService
   */
  public notifyError(message = ERROR_DEFAULT_MESSAGE) {
    this.showBanner(message);
    this.showToast({ message, type: NotificationType.Error });
  }

  /**
   * Standard to notify a warning, displaying a warning toast no autodismissed.
   *
   * @param {string} message - text message in the toast
   * @memberof NotificationService
   */
  public notifyWarning(message = WARNING_DEFAULT_MESSAGE) {
    this.showToast({ message, type: NotificationType.Warning, autoDismiss: false });
  }

  /**
   * Shows a toast in the app on the right bottom.
   *
   * @param {string} message - text message in the toast
   * @param {NotificationType} [type=NotificationType.Info] - message type/color
   * @param {boolean} [autoDismiss=true] - if true or no value it will dissapear in 5 secs
   * @memberof NotificationService
   */
  public showToast(props: ShowNotificationProps) {
    const { message, description, type, onDismiss, autoDismiss = true } = props;
    const notification = new Notification();
    notification.id = props.id;
    notification.messages = [message];
    notification.type = type ?? NotificationType.Info;
    notification.autoDismiss = autoDismiss;
    notification.place = NotificationPlace.Toast;
    notification.description = description;
    notification.link = props.withLink;
    notification.timestamp = props.timestamp;
    notification.onDismiss = onDismiss;

    this.postNotification(notification);
  }

  /**
   * Shows a fixed banner in the top of app (recommended to use in critical errors)
   *
   * @param {string} message - text message in the banner
   * @param {NotificationType} [type=NotificationType.Error] - type/color of the notification
   * @memberof NotificationService
   */
  public showBanner(
    message: string,
    type = NotificationType.Error,
    link: NotificationLink = null,
    onDismiss: () => void = null,
    buttons: NotificationButton[] = []
  ) {
    const notification = new Notification();
    notification.messages = [message];
    notification.type = type;
    notification.autoDismiss = false; // because banners is just in critical cases
    notification.place = NotificationPlace.Banner;
    notification.link = link;
    notification.onDismiss = onDismiss;
    notification.buttons = buttons;

    this.postNotification(notification);
  }

  /**
   * Hangs a flyout notification in the top-nav bell icon
   *
   * @param {string} message - text message in the flyout card
   * @param {NotificationType} [type=NotificationType.Info] - type/color of flyout card side label
   * @param {boolean} [showToast=true] if true it also shows a toast in the front of application
   * @memberof NotificationService
   */
  public showFlyoutNotification(options: ShowNotificationProps) {
    const { message, withLink, type, description, showToast } = options;
    const notification = new Notification();
    notification.id = options.id;
    notification.messages = [message];
    notification.type = type ?? NotificationType.Info;
    notification.place = NotificationPlace.Flyout;
    notification.link = withLink;
    notification.description = description;
    notification.onDismiss = options.onDismiss;
    notification.timestamp = options.timestamp;

    this.postNotification(notification);

    if (showToast) {
      this.showToast(options);
    }
  }

  public postNotification(notification: Notification) {
    this.sendNotificationChange(notification);
  }

  public getNotificationChanges(): Observable<Notification> {
    return this.notificationStateSubject.asObservable();
  }

  public getHideNotificationChanges(): Observable<NotificationHide> {
    return this.notificationHideStateSubject.asObservable();
  }

  // Data loading change notifcation
  private sendNotificationChange(notification: Notification) {
    this.notificationStateSubject.next(notification);
  }

  public hideNotification(id?: string): void {
    this.notificationHideStateSubject.next({ id, forceToHide: true });
  }
}
