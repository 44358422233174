import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, withLatestFrom, catchError, switchMap, take } from 'rxjs/operators';
import { ActivityService } from '@services/activities-events/activity.service';
import {
  ActivityActionTypes,
  SubmitActivity,
  SubmitActivitySuccess,
  SubmitActivityFailed,
  LoadDataActivities,
  LoadDataActivitiesSuccess,
  LoadDataActivitiesFailed,
  LoadDataActivityStartingId,
  LoadDataActivityStartingIdFailed,
} from '@store/actions/activity.action';
import {
  ActivityProcessEntry,
  ActivityActionType,
  ActivityProcess,
} from '@models/activities-events/activities';
import { of, Observable, throwError, forkJoin } from 'rxjs';
import { FDMState } from '@store/reducers';
import { Store, Action } from '@ngrx/store';
import { selectAllConfigs } from '@store/selectors/configs.selectors';
import { Config } from '@models/fabrication/config';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { ContentFile, StorageFileRemovalRequest, StorageFileType } from '@models/fabrication/files';
import { UpsertContentFiles } from '@store/actions/content-file.action';
import { ApiError } from '@models/errors/api-error';
import {
  TriggerCacheDataUpdate,
  UpdateCacheDataTypeRecord,
} from '@store/actions/cache-data.action';
import { CacheUpdateType, CacheDataTypeRecord } from '@models/cache/cache';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DataElementType } from '@constants/data-element-types';
import { LoggingService } from '@services/logging.service';
import { DataLoadingState } from '@store/actions/base/data-loading.action';
import { SetDataTypeIsLoaded } from '@store/actions/loading-data.action';
import { LoadDataActivityStartingIdSuccess } from '../actions/activity.action';
import { DeleteRealTimeActivityMarker } from '@store/actions/activity-real-time.action';
import { selectContentFileById } from '@store/selectors/content-file.selectors';
import { WebAssemblyService } from '@services/web-assembly/web-assembly.service';
import { UpsertBinaryActivityMarker } from '@store/actions/binary-activity.action';
import { ContentFileUtils } from '@utils/content-file-utils';
import { UpsertThumbnailFiles } from '@store/actions/thumbnail-file.action';
import { ContentItem } from '@adsk/content-sdk';
import { CacheService } from '@services/cache.service';
import { DynamicDataService } from '@services/dynamic-data.service';
import { ForgeContentService } from '@services/forge-content.service';
import { ContentNode } from '@models/fabrication/content-node';
import { selectContentNodeById } from '@store/selectors/content-node.selectors';

@Injectable()
export class ActivityEffects {
  loadDataActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActionTypes.LoadDataActivities),
      mergeMap((action: LoadDataActivities) =>
        this.activityService
          .getDataActivities(
            action.payload.configUrn,
            action.payload.dataType,
            action.payload.lastActivityProcessedId,
            this.cacheService.currentCacheIdentityRecord.activitySubmissionId
          )
          .pipe(
            withLatestFrom(
              this.store$.select(selectAllConfigs).pipe(
                take(1),
                map((configs: Config[]) => configs.find((x) => x.id === action.payload.configUrn))
              )
            ),
            switchMap((setupData: [ActivityProcess, Config]) => {
              const [activityProcess, config] = setupData;
              const setup = this.dynamicDataService.getDynamicDataSetupForType(
                action.payload.dataType
              );
              if (activityProcess.entries.length) {
                const processActivities$ = activityProcess.entries.map((x) =>
                  this.processActivityData(
                    x,
                    setup,
                    action.payload.dataType,
                    config,
                    action.payload.nodeId,
                    action.payload.isExternalNodeId
                  )
                );

                // process all activities and get combined actions
                return forkJoin(processActivities$).pipe(
                  map((processedActions: Action[][]) => {
                    const dispatchActions = processedActions
                      .reduce((current, previous) => [...current, ...previous])
                      .concat(
                        this.getFinalActivityProcessingActions(
                          action.payload.dataType,
                          config,
                          activityProcess.lastActivityIdProcessed,
                          action.payload.triggeredByRealTimeNotification,
                          action.payload.nodeId,
                          action.payload.isExternalNodeId
                        )
                      );
                    return dispatchActions;
                  })
                );
              } else {
                console.log('No valid activities to process');
                return of(
                  this.getFinalActivityProcessingActions(
                    action.payload.dataType,
                    config,
                    activityProcess.lastActivityIdProcessed,
                    action.payload.triggeredByRealTimeNotification,
                    action.payload.nodeId,
                    action.payload.isExternalNodeId
                  )
                );
              }
            }),
            catchError((err: any) => {
              // todo: notifiy user
              this.loggingService.logError(err);
              return of([new LoadDataActivitiesFailed()]);
            })
          )
      ),
      switchMap((dispatchActions) => dispatchActions)
    )
  );

  submitActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActionTypes.SubmitActivity),
      mergeMap((action: SubmitActivity) =>
        this.activityService.submitDataActivities(action.payload.submission).pipe(
          map((submitted: boolean) => {
            if (submitted) {
              return new SubmitActivitySuccess();
            } else {
              return new SubmitActivityFailed({ submission: action.payload.submission });
            }
          }),
          catchError(() => of(new SubmitActivityFailed({ submission: action.payload.submission })))
        )
      )
    )
  );

  loadActivityStartingId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActionTypes.LoadDataActivityStartingId),
      mergeMap((action: LoadDataActivityStartingId) =>
        this.activityService
          .loadDataActivitityStartingId(action.payload.configUrn, action.payload.dataType)
          .pipe(
            switchMap((startingId: string) =>
              this.cacheService.updateCacheDataTypeRecord(
                action.payload.dataType,
                action.payload.configUrn,
                action.payload.nodeId,
                action.payload.isExternalNodeId,
                startingId
              )
            ),
            map((record: CacheDataTypeRecord) => {
              if (record) {
                return new LoadDataActivityStartingIdSuccess();
              } else {
                return new LoadDataActivityStartingIdFailed();
              }
            }),
            catchError(() => of(new LoadDataActivityStartingIdFailed()))
          )
      )
    )
  );

  private getDataElementCurrentStorageFileKeys(
    entry: ActivityProcessEntry,
    dynamicDataSetup: DynamicDataElementTypeSetup<any>
  ): Observable<StorageFileRemovalRequest[]> {
    // currently only supports DBFiles
    // need to ensure that when DBFile binaries are updated the old binaries are cleaned from indexedDB
    // todo: expand conditionals to support further data types that store binaries e.g. parts, service templates
    if (entry.isBinaryUpdateOperation) {
      return dynamicDataSetup.options.selectors.selectById(entry.urn).pipe(
        take(1),
        switchMap((dataElement: ForgeContentDataElement) => {
          return this.store$.select(selectContentFileById(dataElement.files[0])).pipe(
            take(1),
            map((contentFile: ContentFile) => {
              return [{ storageFileType: StorageFileType.DbFile, keys: [contentFile.objectKey] }];
            })
          );
        })
      );
    }

    return of([]);
  }

  private processActivityData(
    entry: ActivityProcessEntry,
    dynamicDataSetup: DynamicDataElementTypeSetup<any>,
    dataType: DataElementType,
    config: Config,
    nodeId: string,
    isExternalNodeId: boolean
  ): Observable<Action[]> {
    const getStorageFilesToRemove$ = this.getDataElementCurrentStorageFileKeys(
      entry,
      dynamicDataSetup
    );

    let dataElement: ForgeContentDataElement;
    let contentFileData: ContentFile[];
    let response: ContentItem;

    const loadData$: Observable<
      [ForgeContentDataElement, ContentFile[], ContentItem, ForgeContentDataElement[]] | ApiError
    > =
      entry.activityType !== ActivityActionType.Delete
        ? getStorageFilesToRemove$.pipe(
            switchMap((removalRequest: StorageFileRemovalRequest[]) =>
              this.forgeContentService.loadContentById<
                ForgeContentDataElement,
                ForgeContentDataElement
              >(
                config,
                entry.urn,
                dataType,
                null,
                removalRequest,
                dynamicDataSetup.options.filesAreReferenced
              )
            )
          )
        : of([null, [], null, []]);

    return loadData$.pipe(
      take(1),
      switchMap(
        (
          loadedData:
            | [ForgeContentDataElement, ContentFile[], ContentItem, ForgeContentDataElement[]]
            | ApiError
        ) => {
          if ((loadedData as ApiError).errors) {
            return throwError(() => loadedData as ApiError);
          }

          [dataElement, contentFileData, response] = loadedData as [
            ForgeContentDataElement,
            ContentFile[],
            ContentItem,
            ForgeContentDataElement[]
          ];

          return dataType === DataElementType.Part
            ? this.store$.select(selectContentNodeById(nodeId)).pipe(take(1))
            : of(null);
        }
      ),
      map((contentNode: ContentNode) => {
        // content node will be null if data type is not a part
        const dispatchActions: Action[] = [];

        if (contentNode && contentNode.id !== dataElement.parentId) {
          return [];
        }

        if (contentFileData?.length) {
          const [fileData, thumbnailData] = ContentFileUtils.separateFiles(contentFileData);
          if (fileData.length) {
            dispatchActions.push(new UpsertContentFiles(fileData));
          }
          if (thumbnailData.length) {
            dispatchActions.push(new UpsertThumbnailFiles(thumbnailData));
          }
        }

        dispatchActions.push(
          this.getStoreUpdateAction(entry, dynamicDataSetup, dataElement),
          // cache update
          new TriggerCacheDataUpdate({
            dataElementType: dataType,
            dataUrn: entry.urn,
            cacheUpdateType: this.getCacheUpdateType(entry.activityType),
            response,
            nodeId,
            isExternalNodeId,
          })
        );

        // handle binary notifications
        // only needed if the config's db has already been loaded by the geometry service
        // if not the updated files will be picked up with a clean config db load
        if (
          entry.isBinaryUpdateOperation &&
          contentFileData?.length &&
          this.webAssemblyService.currentLoadedFabricationConfigurations.includes(config.externalId)
        ) {
          dispatchActions.push(
            new UpsertBinaryActivityMarker({
              marker: {
                configUrn: config.id,
                configExternalId: config.externalId,
                dataType,
                filePath: contentFileData[0].description,
                objectKey: contentFileData[0].objectKey, // currently only supporting dbfiles so only need a single entry
              },
            })
          );
        }

        // only need to change config references on add & delete
        if (entry.activityType !== ActivityActionType.Update) {
          dispatchActions.push(
            dynamicDataSetup.options.actions.updateDataReferencesAction(
              dataType === DataElementType.Part ? contentNode : config,
              [entry.urn],
              entry.activityType === ActivityActionType.Delete
            )
          );
        }

        return dispatchActions;
      })
    );
  }

  private getCacheUpdateType(activityActionType: ActivityActionType): CacheUpdateType {
    let cacheUpdateType = null;
    switch (activityActionType) {
      case ActivityActionType.Add:
        cacheUpdateType = CacheUpdateType.Add;
        break;
      case ActivityActionType.Update:
        cacheUpdateType = CacheUpdateType.Update;
        break;
      case ActivityActionType.Delete:
        cacheUpdateType = CacheUpdateType.Delete;
        break;
      default:
        break;
    }

    return cacheUpdateType;
  }

  private getFinalActivityProcessingActions = (
    dataElementType: DataElementType,
    config: Config,
    lastActivityIdProcessed: string,
    triggeredByRealTimeNotification: boolean,
    nodeId: string,
    isExternalNodeId: boolean
  ): Action[] => [
    new LoadDataActivitiesSuccess(),
    new UpdateCacheDataTypeRecord({
      dataElementType,
      nodeId,
      isExternalNodeId,
      lastActivityIdProcessed,
    }),
    triggeredByRealTimeNotification
      ? new DeleteRealTimeActivityMarker({
          configUrn: config.id,
          dataType: dataElementType,
          partNodeId: dataElementType === DataElementType.Part ? nodeId : null,
        })
      : new SetDataTypeIsLoaded({
          dataType: dataElementType,
          configId: config.externalId,
        }),
  ];

  private getStoreUpdateAction(
    entry: ActivityProcessEntry,
    dynamicDataSetup: DynamicDataElementTypeSetup<any>,
    dataElement: ForgeContentDataElement
  ): Action {
    let action: Action = null;
    switch (entry.activityType) {
      case ActivityActionType.Add:
        const addDataAction = dynamicDataSetup.options.actions.addDataSuccessAction();
        addDataAction.dataLoadingState = DataLoadingState.Ignore;
        addDataAction.dataElement = dataElement;
        action = addDataAction;
        break;
      case ActivityActionType.Update:
        const updateDataAction = dynamicDataSetup.options.actions.updateDataSuccessAction();
        updateDataAction.dataLoadingState = DataLoadingState.Ignore;
        updateDataAction.dataElement = dataElement;
        action = updateDataAction;
        break;
      case ActivityActionType.Delete:
        const deleteDataAction = dynamicDataSetup.options.actions.deleteDataSuccessAction();
        deleteDataAction.dataLoadingState = DataLoadingState.Ignore;
        deleteDataAction.removedDataElementIds = [entry.urn];
        action = deleteDataAction;
        break;
      default:
        break;
    }

    return action;
  }

  constructor(
    private actions$: Actions,
    private activityService: ActivityService,
    private cacheService: CacheService,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private store$: Store<FDMState>,
    private loggingService: LoggingService,
    private webAssemblyService: WebAssemblyService
  ) {}
}
