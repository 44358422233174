import { Injectable } from '@angular/core';
import {
  Observable,
  of,
  throwError,
  iif,
  defer,
  Subject,
  forkJoin,
  from,
  BehaviorSubject,
} from 'rxjs';
import { map, catchError, switchMap, tap, take, retry, concatMap } from 'rxjs/operators';
import { LoggingService } from '@services/logging.service';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { ErrorUtils } from '@utils/error-utils';
import { FDMState } from '@store/reducers/index';
import { Store } from '@ngrx/store';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { StorageFile } from '@models/fabrication/files';
import {
  InternalFabricationGeometry,
  FabricationPartParameters,
  InternalPartData,
  InternalPartGeometry,
  PartData,
  PartGeometry,
  BadGeometryInfo,
  BadDimInfoState,
} from '@models/fabrication/geometry';
import { AppDependency } from '@models/app-dependencies/app-dependencies';
import { selectCurrentConfigExternalId } from '@store/selectors/application.selectors';
import { TranslateService } from '@ngx-translate/core';
import { selectBinaryActiviesByConfigExternalId } from '@store/selectors/binary-activity.selectors';
import { BinaryActivityMarker } from '@models/activities-events/activities';
import { DataElementType } from '@constants/data-element-types';
import { DeleteBinaryActivityMarker } from '@store/actions/binary-activity.action';
import lcid from 'lcid';
import { EnvironmentConstants } from '@constants/environment-constants';
import { fromWorker } from 'observable-webworker';
import {
  WebAssemblyOperationRequest,
  WebAssemblyOperationResponse,
  WebAssemblyOperations,
} from './web-assembly.worker';
import { ResilientHttpService } from '@services/http/resilient-http.service';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';
import { DebugModeService } from '@services/debug-mode.service';

@Injectable({
  providedIn: 'root',
})
export class WebAssemblyService implements AppDependency {
  private chunkBase = 'fabdm-web-assembly.wasm.sf-part';
  private chunkPath = '/assets/wasm/';
  private dictionaryPath = '/assets/dictionary/diction.ary';
  private chunkCount = 20;
  private currentConfigId = '';
  private currentDbFileReloadParams: string[] = [];
  isInitialised = false;
  initialisationInProgress = false;

  private _currentLoadedFabricationConfigurations: string[] = [];

  private dictionary: Blob;

  private badGeometrySubject: BehaviorSubject<BadDimInfoState> =
    new BehaviorSubject<BadDimInfoState>({
      id: null,
      badInfo: {},
      lastUpdateBadInfo: {},
      triggeredBy: null,
    });
  public listenToBadGeometryChanges() {
    return this.badGeometrySubject.pipe();
  }

  public getBadGeometryState() {
    return this.badGeometrySubject.value;
  }

  private receiveDataSubject = new BehaviorSubject<{
    partData?: PartData;
    triggeredBy?: string;
  }>({});

  public listenToDataChanges() {
    return this.receiveDataSubject.asObservable();
  }

  public currentPartData: PartData;

  private webWorker = new Worker(new URL('./web-assembly.worker', import.meta.url), {
    type: 'module',
  });

  private webWorkerTaskQueueSubject = new Subject<{
    request: WebAssemblyOperationRequest;
    resultCallback: (response: WebAssemblyOperationResponse, error: any) => void;
  }>();

  /**
   * Array of currently loaded configs
   * @readonly
   * @type {string[]}
   * @memberof WebAssemblyService
   */
  public get currentLoadedFabricationConfigurations(): string[] {
    return this._currentLoadedFabricationConfigurations;
  }

  public constructor(
    private loggingService: LoggingService,
    private store$: Store<FDMState>,
    private fileStorageService: BinaryStorageService,
    private httpService: ResilientHttpService,
    private translate: TranslateService,
    private debugModeService: DebugModeService
  ) {
    this.listenToWebWorkerTaskQueue();
  }

  private listenToWebWorkerTaskQueue(): void {
    this.webWorkerTaskQueueSubject
      .pipe(
        concatMap((task) =>
          fromWorker<WebAssemblyOperationRequest, WebAssemblyOperationResponse>(
            () => this.webWorker,
            of(task.request),
            null,
            { terminateOnComplete: false }
          ).pipe(
            take(1),
            tap((response) => task.resultCallback(response, null)),
            catchError((err) => {
              task.resultCallback(null, err);
              return of(err);
            })
          )
        )
      )
      .subscribe();
  }

  private submitWebWorkerTask(
    request: WebAssemblyOperationRequest
  ): Promise<WebAssemblyOperationResponse> {
    return new Promise<WebAssemblyOperationResponse>((resolve, reject) => {
      this.webWorkerTaskQueueSubject.next({
        request,
        resultCallback: (response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        },
      });
    });
  }

  public getFabricationPartData(
    partStorageFile: StorageFile,
    partParameters?: FabricationPartParameters,
    triggeredBy?: string
  ): Observable<PartData> {
    if (this.isInitialised) {
      const errorMessage = `${this.translate.instant(
        LC.CONFIG.ERROR_LOADING_FABRICATION_PART_GEOMETRY
      )}${partStorageFile.fileName}`;

      const languageId = lcid.to(this.translate.getBrowserCultureLang().replace('-', '_'));

      console.log('WebAssembly Engine - Requested Parameters', partParameters);
      return this.initialiseCurrentDatabase().pipe(
        switchMap(() => {
          if (this.currentDbFileReloadParams.length) {
            partParameters.reload = this.currentDbFileReloadParams;
          }
          const params = (partParameters && JSON.stringify(partParameters)) || '';
          return from(
            this.submitWebWorkerTask({
              operationType: WebAssemblyOperations.getPartData,
              getPartData: {
                configId: this.currentConfigId,
                partFile: {
                  fileName: partStorageFile.fileName,
                  fileContents: partStorageFile.contents,
                  id: partStorageFile.checksum,
                },
                params,
                languageId,
                enableSleepDebugMode: this.debugModeService.webAssemblyHeavyProcessing,
              },
            } as WebAssemblyOperationRequest)
          ).pipe(
            map((response) => response.getPartData),
            catchError((err) => throwError(() => err))
          );
        }),
        map((rawJson: string) => {
          // reset, only supports a single use
          this.currentDbFileReloadParams = [];
          if (rawJson) {
            const internalPartData: InternalPartData = JSON.parse(rawJson);
            console.log('WebAssembly Engine - Returned Data', internalPartData);
            const partData: PartData = {
              geometry: this.parseRawFabricationGeometry(internalPartData.geometry),
              dimensions: internalPartData.geometry?.dims ?? [],
              additionalPartMetaData: internalPartData.data,
              additionalPartInfo: internalPartData.info,
              productListData: internalPartData.productListData,
              partType: internalPartData.partType,
              badInfoUpdates: null,
            };

            partData.additionalPartMetaData?.connectors?.forEach((x) => {
              if (x.externalId === '-1')
                x.externalId = EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;
            });
            if (partData.additionalPartMetaData?.material === '-1')
              partData.additionalPartMetaData.material =
                EnvironmentConstants.FCS_UNASSIGNED_MATERIAL;
            if (partData.additionalPartMetaData?.materialSpec === '-1')
              partData.additionalPartMetaData.materialSpec =
                EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;

            const badInfoMap = Object.fromEntries(
              internalPartData.badinfo?.map((x) => [x.entryId, x]) ?? []
            );
            let requestedEntryIds =
              internalPartData.productListData?.calculatedValues?.map((x) => x.id) ??
              internalPartData.productListData?.entries?.map((x) => x.id) ??
              [];

            //If web assembly did not return any calculatedValues or entries, rely on requested data.
            if (!requestedEntryIds.length)
              requestedEntryIds = [
                partParameters.productlist.entry,
                ...(partParameters.otherPartDims?.map((x) => x.productlist.entry) ?? []),
              ];
            const badInfoUpdates = requestedEntryIds.reduce<Record<string, BadGeometryInfo>>(
              (prev, curr) => {
                //Only update it if the entry has or had a bad dim
                if (badInfoMap[curr] || this.badGeometrySubject.value?.badInfo[curr]) {
                  return { ...prev, [curr]: badInfoMap[curr] ?? null };
                }
                return prev;
              },
              {}
            );

            if (this.badGeometrySubject.value?.id === partStorageFile.id) {
              this.badGeometrySubject.next({
                id: partStorageFile.id,
                badInfo: { ...this.badGeometrySubject.value.badInfo, ...badInfoUpdates },
                lastUpdateBadInfo: badInfoUpdates,
                triggeredBy,
              });
            } else {
              //Reset the state with new partStorageFile.id
              this.badGeometrySubject.next({
                id: partStorageFile.id,
                badInfo: badInfoUpdates,
                lastUpdateBadInfo: badInfoUpdates,
                triggeredBy,
              });
            }

            partData.badInfoUpdates = badInfoUpdates;

            this.currentPartData = partData;

            this.receiveDataSubject.next({
              partData,
              triggeredBy,
            });

            return partData;
          } else {
            throw new Error(errorMessage);
          }
        }),
        retry(2), // retry loading for intermitent failures, added as a result of https://jira.autodesk.com/browse/RVTFDM-3182
        catchError((err: Error) => {
          this.loggingService.logError(err, true, errorMessage);
          return of(null);
        })
      );
    } else {
      throw new Error('Geometry engine not initialised');
    }
  }

  public initialise(): Observable<boolean> {
    if (this.isInitialised || this.initialisationInProgress) {
      return of(true);
    }

    this.initialisationInProgress = true;

    return forkJoin([this.getGeometryEngineWasmBinary(), this.getDictionary()]).pipe(
      switchMap(([binary, dictionary]: [Uint8Array, Blob]) => {
        this.dictionary = dictionary;
        return fromWorker<WebAssemblyOperationRequest, WebAssemblyOperationResponse>(
          () => this.webWorker,
          of({
            operationType: WebAssemblyOperations.initialiseEngine,
            initialiseEngine: { binary: binary.buffer },
          } as WebAssemblyOperationRequest),
          (input) => [input.initialiseEngine.binary], // transfer the binary to the worker rather than copying it
          { terminateOnComplete: false }
        );
      }),
      map((response) => {
        this.isInitialised = !!response.initialiseEngine;
        return this.isInitialised;
      }),
      catchError((error) => {
        console.log('Fabdm geometry engine failed to initialise');

        this.loggingService.logError(
          error,
          false,
          this.translate.instant(LC.CONFIG.ERROR_LOADING_FABDM_WEB_ASSEMBLY_ENGINE),
          ErrorUtils.getAdditionalStack()
        );
        return of(false);
      })
    );
  }

  private getGeometryEngineWasmBinaryChunk(chunk: string): Observable<ArrayBuffer> {
    return this.httpService.get<ArrayBuffer>(chunk, { responseType: 'arraybuffer' });
  }

  private getDictionary(): Observable<Blob> {
    return this.httpService.get<Blob>(this.dictionaryPath, { responseType: 'blob' });
  }

  // get wasm binary from file chunks
  private getGeometryEngineWasmBinary(): Observable<Uint8Array> {
    const observables: Observable<ArrayBuffer>[] = [];

    for (let i = 1; i <= this.chunkCount; i++) {
      const chunkName = `${this.chunkPath}${this.chunkBase}${i.toString().padStart(2, '0')}`;
      observables.push(this.getGeometryEngineWasmBinaryChunk(chunkName));
    }

    return RxjsUtils.concurrentForkJoin(observables).pipe(
      map((chunks: ArrayBuffer[]) => {
        // create new Uint8Array (this is the format that the wasm binary is needed in)
        // from the returned ArrayBuffers
        const totalLength: number = chunks.reduce((sum, current) => sum + current.byteLength, 0);
        // construct the wasmBinary from the chunks
        const binary = new Uint8Array(totalLength);
        let currentBufferLength = 0;
        chunks.forEach((chunk: ArrayBuffer) => {
          binary.set(new Uint8Array(chunk), currentBufferLength);
          currentBufferLength += chunk.byteLength;
        });

        return binary;
      }),
      catchError((err) => {
        this.loggingService.logError(err);
        return of(null);
      })
    );
  }

  private initialiseCurrentDatabase(): Observable<boolean> {
    const loadDb$ = defer(() =>
      this.fileStorageService.getDBFilesForConfig(this.currentConfigId).pipe(
        switchMap((dbFiles: StorageFile[]) => {
          if (dbFiles && dbFiles.length) {
            const db = dbFiles.map((dbFile: StorageFile) => ({
              fileName: dbFile.fileName,
              fileContents: dbFile.contents,
            }));

            db.push({
              fileName: 'diction.ary',
              fileContents: this.dictionary,
            });
            return from(
              this.submitWebWorkerTask({
                operationType: WebAssemblyOperations.initialiseDatabase,
                initialiseDatabase: {
                  configId: this.currentConfigId,
                  db,
                },
              } as WebAssemblyOperationRequest)
            ).pipe(
              map((response) => {
                console.log(response);
                return true;
              }),
              catchError((err) => {
                return throwError(() => err);
              })
            );
          } else {
            return throwError(
              () =>
                new Error(
                  this.translate.instant(LC.CONFIG.ERROR_LOADING_FABRICATION_DATABASE_BINARY_FILES)
                )
            );
          }
        }),
        tap(() => this._currentLoadedFabricationConfigurations.push(this.currentConfigId)),
        map((response) => {
          console.log(response);
          return true;
        })
      )
    );

    // check if we need to re-load any binary files
    let dbFileMarkers: BinaryActivityMarker[] = [];
    const reloadDb$ = defer(() =>
      this.store$.select(selectBinaryActiviesByConfigExternalId(this.currentConfigId)).pipe(
        take(1),
        switchMap((markers: BinaryActivityMarker[]) => {
          if (markers?.length) {
            dbFileMarkers = markers.filter((x) => x.dataType === DataElementType.DBFile);

            if (dbFileMarkers.length) {
              const objectKeys = dbFileMarkers.map((x) => x.objectKey);
              return this.fileStorageService.getDBFiles(objectKeys);
            }
          }

          return of([]);
        }),
        switchMap((dbFiles: StorageFile[]) => {
          if (dbFiles.length) {
            const db = dbFiles.map((dbFile: StorageFile) => ({
              fileName: dbFile.fileName,
              fileContents: dbFile.contents,
            }));
            // set the current reload param to contain the newly loaded
            // files, this will be wiped after a single use and the fab config has those files
            // re-loaded into the WASM env
            this.currentDbFileReloadParams = db.map((x) => x.fileName.toLowerCase());

            return from(
              this.submitWebWorkerTask({
                operationType: WebAssemblyOperations.reload,
                reloadData: {
                  configId: this.currentConfigId,
                  db,
                },
              } as WebAssemblyOperationRequest)
            );
          } else {
            return of('No binary files to reload for current fabrication db');
          }
        }),
        tap(() => {
          if (dbFileMarkers.length) {
            dbFileMarkers.forEach((x) => {
              this.store$.dispatch(new DeleteBinaryActivityMarker({ objectKey: x.objectKey }));
            });
          }
        }),
        map((response) => {
          console.log(response);
          return true;
        })
      )
    );

    return this.store$.select(selectCurrentConfigExternalId).pipe(
      take(1),
      tap((configId: string) => {
        this.currentConfigId = configId;
      }),
      switchMap(() =>
        // only load db once or check for re-loads
        iif(
          () => this._currentLoadedFabricationConfigurations.includes(this.currentConfigId),
          reloadDb$,
          loadDb$
        )
      )
    );
  }

  public parseRawFabricationGeometry(geometry: InternalPartGeometry): PartGeometry {
    const annotation = geometry?.annotation ?? [];
    const connectors: THREE.Mesh[] = [];
    let insulation: THREE.Mesh = null;

    let body: THREE.Mesh = null;
    const geometryBody = geometry?.body;
    if (geometryBody?.faces?.length && geometryBody?.vertices?.length)
      body = this.createMesh(geometry.body, true);

    // check connectors
    if (geometry?.connectors?.length) {
      geometry.connectors.forEach((connector) => {
        if (connector.faces?.length && connector.vertices?.length) {
          connectors.push(this.createMesh(connector, false));
        }
      });
    }

    // check insulation
    if (geometry?.insulation) {
      if (geometry.insulation?.faces.length && geometry.insulation?.vertices?.length) {
        insulation = this.createMesh(geometry.insulation, false);
      }
    }

    return {
      annotation,
      body,
      connectors,
      insulation,
    };
  }

  private createMesh(geometryPart: InternalFabricationGeometry, isBody: boolean): THREE.Mesh {
    const displayGeometry = new THREE.Geometry();
    const material = new THREE.MeshPhongMaterial({
      color: isBody ? 0xdcdcdc : 0xa81b46,
      side: THREE.DoubleSide,
    });

    if (geometryPart) {
      for (let i = 0; i < geometryPart.vertices?.length; i++) {
        const vertice = geometryPart.vertices[i];
        const v = new THREE.Vector3(vertice[0], vertice[1], vertice[2]);
        displayGeometry.vertices.push(v);
      }

      for (let i = 0; i < geometryPart.faces?.length; i++) {
        const triangle = geometryPart.faces[i];
        const face = new THREE.Face3(triangle[0], triangle[1], triangle[2]);
        displayGeometry.faces.push(face);
      }

      displayGeometry.computeFaceNormals();
      displayGeometry.computeVertexNormals();
    }

    return new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(displayGeometry), material);
  }
}
