import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Header, Link, Group } from '@models/ui-state/navigation-data';
import { ThemeService } from '@services/theme.service';
import { Subscription, take } from 'rxjs';
import { HigSideNav, Props } from './side-nav.react';
import { createRoot } from 'react-dom/client';
import { NavigationService } from '@services/navigation.service';

@Component({
  selector: 'hig-side-nav',
  template: ` <div [id]="sideNavId"></div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HigSideNavComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() header: Header;
  @Input() links: Link[];
  @Input() groups: Group[];
  root;

  public sideNavId = 'hig-side-nav-container';

  private themeChangeSubscription: Subscription;

  constructor(private themeService: ThemeService, private navigationService: NavigationService) {}

  ngAfterViewInit(): void {
    this.themeChangeSubscription = this.themeService
      .listenToThemeChanges()
      .subscribe(() => this.renderHigSideNav());
  }

  ngOnChanges(): void {
    this.renderHigSideNav();
  }

  ngOnDestroy(): void {
    this.themeChangeSubscription?.unsubscribe();
    this.root.unmount();
  }

  renderHigSideNav = (): void => {
    const parentElement = document.getElementById(this.sideNavId);
    if (parentElement) {
      const props: Props = {
        header: this.header,
        links: this.links,
        groups: this.groups,
        themeData: this.themeService.getSelectedThemeData(true),
        onClickHeader: () => {
          this.navigationService.currentNavigationData.pipe(take(1)).subscribe((data) => {
            return data.onClickHeader();
          });
        },
      };
      if (!this.root) this.root = createRoot(parentElement);
      this.root.render(HigSideNav(props));
    }
  };
}
