import { ContentFile, ThumbnailFile } from '@models/fabrication/files';

export class ContentFileUtils {
  /**
   * Splits the files into an array of content files and thumbnail files.
   * @param contentFiles
   * @returns [ContentFile[], ThumbnailFile[]]
   */
  public static separateFiles(contentFiles: ContentFile[]): [ContentFile[], ThumbnailFile[]] {
    const files: ContentFile[] = [];
    const thumbnails: ThumbnailFile[] = [];
    contentFiles.forEach((file: ThumbnailFile) => {
      if ('height' in file) {
        thumbnails.push(file);
      } else {
        files.push(file);
      }
    });

    return [files, thumbnails];
  }
}
