import * as fromServiceTemplateInfo from '../actions/service-template-info.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ServiceTemplateInfo } from '@models/fabrication/service-template-info';

export interface ServiceTemplateInfoState extends EntityState<ServiceTemplateInfo> {} //eslint-disable-line

export const ServiceTemplateInfoAdapter: EntityAdapter<ServiceTemplateInfo> =
  createEntityAdapter<ServiceTemplateInfo>({
    selectId: (ServiceTemplateInfo: ServiceTemplateInfo) => ServiceTemplateInfo.id,
  });

export const initialState: ServiceTemplateInfoState = ServiceTemplateInfoAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromServiceTemplateInfo.ServiceTemplateInfoActions
): ServiceTemplateInfoState {
  switch (action.type) {
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.LoadServiceTemplateInfos: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.LoadServiceTemplateInfosSuccess: {
      return ServiceTemplateInfoAdapter.upsertMany(action.data, state);
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.LoadServiceTemplateInfosFail: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfo: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfoSuccess: {
      return ServiceTemplateInfoAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.UpdateServiceTemplateInfoFail: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.AddServiceTemplateInfo: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.AddServiceTemplateInfoSuccess: {
      return ServiceTemplateInfoAdapter.addOne(action.dataElement, state);
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.AddServiceTemplateInfoFail: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.CopyServiceTemplateInfo: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.CopyServiceTemplateInfoSuccess: {
      return ServiceTemplateInfoAdapter.addOne(action.dataElement, state);
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.CopyServiceTemplateInfoFail: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfos: {
      return state;
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfosSuccess: {
      return ServiceTemplateInfoAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromServiceTemplateInfo.ServiceTemplateInfoActionTypes.DeleteServiceTemplateInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of system info ids
  selectIds: selectServiceTemplateInfoIds,

  // select the dictionary of system info entities
  selectEntities: selectServiceTemplateInfoEntities,

  // select the array of system info
  selectAll: selectAllServiceTemplateInfo,

  // select the total system info count
  selectTotal: selectServiceTemplateInfoTotal,
} = ServiceTemplateInfoAdapter.getSelectors();
