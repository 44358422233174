import i18next, { i18n } from 'i18next';

export const DEFAULT_LANGUAGE = 'en';

const namespaces = [
  'config',
  'common',
  'feature-modules',
  'help-content',
  'notifications',
  'datatypes',
  'enums',
  'components/badge-tooltip',
  'components/data-grid',
  'components/dimensions-table',
  'components/dynamic-form',
  'components/dynamic-table',
  'components/getting-started',
  'components/entitlements',
  'components/app-unavailable',
  'components/graph',
  'components/missing-settings',
  'components/multi-select',
  'components/page-not-found',
  'components/part-browser',
  'components/part-search',
  'components/side-nav',
  'components/toolbar',
  'components/tooltip',
  'components/table-header-menu',
  'components/thumbnail-editor',
  'error-handling',
  'access-rights',
  'confirmation-modal',
  'loader',
];

export function loadAdditionalResources(): Promise<i18n[]> {
  let lng = i18next.language;
  let jsonName = lng.toLowerCase();

  //manage Chinese Simplified and Traditional
  if (lng === 'zh-CN' || lng === 'zh') {
    jsonName = 'zh-hans';
  } else if (lng === 'zh-TW' || lng === 'zh-HK' || lng === 'zh-SG') {
    jsonName = 'zh-hant';
  }

  const promises = namespaces.map((ns) =>
    import(`../../assets/i18n/${ns}/${jsonName}.json`)
      .catch(() => {
        lng = DEFAULT_LANGUAGE;
        jsonName = lng;
        return import(`../../assets/i18n/${ns}/${jsonName}.json`);
      })
      .then((resource) => i18next.addResourceBundle(lng, 'translation', resource.default))
  );

  return Promise.all(promises);
}
