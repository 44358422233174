import { ContentItem, AssetList } from '@adsk/content-sdk';
import { Injectable } from '@angular/core';
import { DataElementType } from '@constants/data-element-types';
import {
  CacheDataTypeRecord,
  CacheIdentityRecord,
  CacheTableEntry,
  CacheUpdateType,
} from '@models/cache/cache';
import { combineLatest, defer, EMPTY, forkJoin, from, iif, Observable, of, throwError } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { SwUpdate } from '@angular/service-worker';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import { selectCacheDataById } from '@store/selectors/cache-data.selectors';
import { DeleteCacheTableData, UpsertCacheTableData } from '@store/actions/cache-data.action';
import { ForgeContentService } from './forge-content.service';
import { EntityType } from '@models/forge-content/entity-type';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  currentCacheIdentityRecord: CacheIdentityRecord = null;

  get forgeContentDataGroupName() {
    return 'forge-content-api-data';
  }
  get forgeContentDataCacheName() {
    return `${this.forgeContentDataGroupName}:cache`;
  }
  get forgeContentAgeCacheName() {
    return `${this.forgeContentDataGroupName}:age`;
  }
  get forgeContentLruCacheName() {
    return `${this.forgeContentDataGroupName}:lru`;
  }
  get fabdmCacheDataControlName() {
    return 'fabdm-cache-control';
  }
  get fabdmCacheControlIdentityUrl() {
    return `${this.fabdmCacheDataControlName}/identity`;
  }
  get ngSwDbControlCacheName() {
    return 'db:control';
  }

  constructor(private swUpdate: SwUpdate, private store: Store<FDMState>) {}

  isCacheSupported(): boolean {
    return this.swUpdate.isEnabled;
  }

  validateCacheControlIdentityRecord(currentUserId: string): Observable<boolean> {
    if (!this.isCacheSupported()) {
      this.printCacheNotSupportedMessage();
      return of(true);
    }

    if (this.currentCacheIdentityRecord) {
      return of(true);
    }
    return this.getFabdmDataControlCache().pipe(
      switchMap((controlCache: Cache) => {
        if (!controlCache) {
          console.log('Control cache does not exist');
          return this.getForgeContentDataCache().pipe(
            switchMap((dataCache: Cache) =>
              iif(() => !!dataCache, this.removeCacheEntries(dataCache), of(EMPTY))
            ),
            switchMap(() => this.createControlCache()),
            switchMap(() => this.createCacheControlIdentityRecord(currentUserId)),
            tap((record: CacheIdentityRecord) => {
              this.currentCacheIdentityRecord = record;
            }),
            map((record: CacheIdentityRecord) => !!record)
          );
        } else {
          console.log('Control cache exists');
          return this.getCacheControlIdentityRecord().pipe(
            switchMap((record: CacheIdentityRecord) => {
              if (!record || record.userId !== currentUserId || !record.activitySubmissionId) {
                console.log('Control cache contains an error, data cache will be removed');
                return this.getForgeContentDataCache().pipe(
                  switchMap((dataCache: Cache) => this.removeCacheEntries(dataCache)),
                  switchMap(() => this.removeCacheEntries(controlCache)),
                  switchMap(() => this.createCacheControlIdentityRecord(currentUserId)),
                  tap((record: CacheIdentityRecord) => {
                    this.currentCacheIdentityRecord = record;
                  }),
                  map((record: CacheIdentityRecord) => !!record)
                );
              } else {
                this.currentCacheIdentityRecord = record;
                return of(true);
              }
            })
          );
        }
      })
    );
  }

  validateCacheDataTypeRecord(
    dataType: DataElementType,
    configId: string,
    nodeId: string,
    isExternalNodeId: boolean,
    entityType: EntityType
  ): Observable<CacheDataTypeRecord> {
    if (!this.isCacheSupported()) {
      this.printCacheNotSupportedMessage();
      return of(null);
    }

    const urlMatch = this.getRegExpForDataTypeUrlSearch(configId, nodeId, '', entityType);
    const hasMatchingDataEntries$ = this.getForgeContentDataCache().pipe(
      mergeMap((dataCache: Cache) => this.filterCacheForMatchingRequests(dataCache, urlMatch)),
      map((matchingData: Map<Request, Response>) => {
        return !!matchingData;
      })
    );
    const getDataTypeCacheRecord$ = this.getCacheDataTypeRecord(configId, nodeId);
    const createCacheDataTypeRcord$ = this.createCacheDataTypeRecord(
      dataType,
      configId,
      nodeId,
      isExternalNodeId,
      'not-found'
    );

    return combineLatest([hasMatchingDataEntries$, getDataTypeCacheRecord$]).pipe(
      concatMap((setupData: [boolean, CacheDataTypeRecord]) => {
        const [hasMatchingDataEntries, record] = setupData;

        // if cached responses found and no data type record found
        // then remove the cached entries and create the record
        // this essentially starts the data type caching from scratch
        // indicates that the cache has been tampered with by the user
        // data type records and their associated cached responses should exist together always (in different cache tables)
        if (hasMatchingDataEntries && !record) {
          return this.getForgeContentDataCache().pipe(
            switchMap((dataCache: Cache) => this.removeCacheEntries(dataCache, urlMatch)),
            switchMap(() => createCacheDataTypeRcord$)
          );
        } else if (!hasMatchingDataEntries && !record) {
          // assume caching first pass on data type for config
          return createCacheDataTypeRcord$;
        } else {
          return of(record);
        }
      })
    );
  }

  validateDataTypeIsCached(
    dataType: DataElementType,
    configId: string,
    nodeId: string,
    entityType: EntityType
  ): Observable<boolean> {
    if (!this.isCacheSupported()) {
      this.printCacheNotSupportedMessage();
      return of(false);
    }
    return this.getForgeContentDataCache().pipe(
      mergeMap((dataCache: Cache) => {
        if (!dataCache) {
          // todo: log error, report to user what to do
          console.log('Forge content data cache does not exist, unable to continue');
          return of(false);
        } else {
          console.log('Forge content data cache exists');
          const urlMatchExp = this.getRegExpForDataTypeUrlSearch(configId, nodeId, '', entityType);
          return this.filterCacheForMatchingRequests(dataCache, urlMatchExp).pipe(
            switchMap((matchedData: Map<Request, Response>) => {
              // data type has cached entries for specified configId
              if (matchedData) {
                console.log(
                  `Cached requests/responses found for data type:${dataType.toLowerCase()} with config id: ${configId}`
                );
                // make sure the entries are not expired
                return this.getForgeContentAgeCache().pipe(
                  switchMap((ageCache: Cache) => {
                    if (!ageCache) {
                      // todo: log error, report to user what to do
                      console.error('Forge content age cache does not exist, unable to continue');
                      return of(false);
                    } else {
                      return forkJoin([
                        this.filterCacheForMatchingRequests(ageCache, urlMatchExp),
                        this.getDataGroupMaxAge(this.forgeContentDataGroupName),
                      ]).pipe(
                        switchMap((matchedAgeData: [Map<Request, Response>, number]) => {
                          if (matchedAgeData) {
                            const [ageMap, maxAge] = matchedAgeData;
                            return forkJoin([...ageMap.values()].map((x) => from(x.json()))).pipe(
                              map((ageRecords: { age: number }[]) => {
                                const earliestTimeStamp = Math.min(...ageRecords.map((x) => x.age));
                                const now = Date.now();
                                const expiryThreshold = earliestTimeStamp + maxAge;
                                return expiryThreshold > now;
                              })
                            );
                          } else {
                            return of(false);
                          }
                        })
                      );
                    }
                  })
                );
              } else {
                return of(false);
              }
            })
          );
        }
      })
    );
  }

  updateCacheDataTypeRecord(
    dataType: DataElementType,
    configId: string,
    nodeId: string,
    isExternalNodeId: boolean,
    lastActivityIdProcessed: string
  ): Observable<CacheDataTypeRecord> {
    if (!this.isCacheSupported()) {
      this.printCacheNotSupportedMessage();
      return of(null);
    }

    return this.createCacheDataTypeRecord(
      dataType,
      configId,
      nodeId,
      isExternalNodeId,
      lastActivityIdProcessed
    );
  }

  updateForgeContentDataCache(
    configId: string,
    dataId: string,
    cacheUpdateType: CacheUpdateType,
    dataResponse: ContentItem,
    nodeId: string,
    entityType: EntityType,
    ignoreCacheEntry: boolean // when parts are moved to the invalid data section after an upgrade, the cache entry is likely to be invalid
  ): Observable<boolean> {
    if (!this.isCacheSupported()) {
      this.printCacheNotSupportedMessage();
      return of(false);
    }

    // check forge content data cache exists
    // todo: add support for handling multiple updates (mainly deletes)
    // using concatMap initially to prevent un-wanted side effects of
    // potentially opening a cached response and overwriting previous changes
    return this.getForgeContentDataCache().pipe(
      concatMap((cache: Cache) => {
        const fcsDataCache: Cache = cache;
        let cacheTableEntry: CacheTableEntry = null;
        let addNewDataAtPageNumber = 0;

        if (cache) {
          // get cache table entry if required
          const cacheTableObservable =
            cacheUpdateType === CacheUpdateType.Add || ignoreCacheEntry
              ? of(null)
              : this.store.select(selectCacheDataById(dataId)).pipe(take(1));

          let matchedRequestsForDataType: Request[] = [];

          return cacheTableObservable.pipe(
            tap((entry: CacheTableEntry) => {
              cacheTableEntry = entry || cacheTableEntry;
            }),
            switchMap(() => fcsDataCache.keys()),
            switchMap((requests: Request[]) => {
              let urlMatchRegExp: RegExp = null;

              // **Note** - when the cache is adjusted for FCS data
              // adding or deleting data entries will not result in the whole cache
              // for the data type being adjusted. For example
              // if the connectors data type contains 20 records in a single
              // request/response in the cache and another is added which exceeds
              // the page size limit, we do not create another request/response in the cache
              // to handle the overspill. The new data will be added to the existing request/response
              // resulting in it containing 21 records. Cache entries might result in data contained in
              // request/responses to exceed the page size.

              // fc2.0 offset starts at 0
              // add and delete cache updates require the whole set of responses to have
              // the totalResults property updated to refelect the count change
              if (
                cacheUpdateType === CacheUpdateType.Add ||
                cacheUpdateType === CacheUpdateType.Delete
              ) {
                urlMatchRegExp = this.getRegExpForDataTypeUrlSearch(
                  configId,
                  nodeId,
                  '',
                  entityType
                );
                // if adding response to cache
                // get last page of responses for config then data type
                matchedRequestsForDataType = requests
                  .filter((x) => urlMatchRegExp.test(x.url))
                  .sort((req1: Request, req2: Request) => {
                    const getOffset = (request: Request): number =>
                      Number(request.url.match(/offset=\d+/)[0].split('=')[1]);
                    const req1Offset = getOffset(req1);
                    const req2Offset = getOffset(req2);
                    return req1Offset < req2Offset ? -1 : 1;
                  });

                // get request to update
                // cache add - the last entry to add to the end
                // cache delete - the entry specified by the stored page number

                if (cacheUpdateType === CacheUpdateType.Add) {
                  addNewDataAtPageNumber = matchedRequestsForDataType.length - 1;
                  return of(matchedRequestsForDataType[addNewDataAtPageNumber]);
                } else if (cacheTableEntry) {
                  return of(matchedRequestsForDataType[cacheTableEntry.pageNumber]);
                }

                const findMatchedRequest$ = matchedRequestsForDataType.map((request: Request) => {
                  return from(fcsDataCache.match(request)).pipe(
                    switchMap((response: Response) => {
                      return response.json();
                    }),
                    map((responseBody: AssetList) => responseBody)
                  );
                });

                return forkJoin(findMatchedRequest$).pipe(
                  map((responses: AssetList[]) => {
                    const index = responses.findIndex(
                      (x) => x.results.findIndex((y) => y.id === dataId) >= 0
                    );
                    if (index >= 0) {
                      return matchedRequestsForDataType[index];
                    }

                    console.log('No matching request found in cache');
                    return null;
                  })
                );
              } else {
                urlMatchRegExp = this.getRegExpForDataTypeUrlSearch(
                  configId,
                  nodeId,
                  `(.)+offset=${cacheTableEntry?.pageNumber * ForgeContentService.pageSize || 0}`,
                  entityType
                );

                return of(requests.find((x) => urlMatchRegExp.test(x.url)));
              }
            }),
            switchMap((matchedRequest: Request) => {
              if (matchedRequest) {
                return this.updateForgeDataCacheResponse(
                  fcsDataCache,
                  cacheUpdateType,
                  dataResponse,
                  matchedRequest,
                  dataId,
                  matchedRequestsForDataType
                ).pipe(
                  tap(() => {
                    if (cacheUpdateType === CacheUpdateType.Add) {
                      this.store.dispatch(
                        new UpsertCacheTableData({
                          data: [{ id: dataId, pageNumber: addNewDataAtPageNumber }],
                        })
                      );
                    }

                    if (cacheUpdateType === CacheUpdateType.Delete) {
                      this.store.dispatch(new DeleteCacheTableData({ ids: [dataId] }));
                    }
                  })
                );
              } else {
                console.log('No matching request found in service worker cache');
                return of(false);
              }
            })
          );
        } else {
          console.log('forge data cache does not exist');
          return of(false);
        }
      })
    );
  }

  getForgeContentDataUrlPattern(configId: string, nodeId: string): string {
    return `${configId}/nodes/${nodeId}/children`; // todo - try with entityType=content?
  }

  printCacheNotSupportedMessage() {
    console.log('Service worker cache not supported in this mode');
  }

  private updateForgeDataCacheResponse(
    dataCache: Cache,
    cacheUpdateType: CacheUpdateType,
    dataResponse: ContentItem,
    request: Request,
    dataId: string,
    allDataTypeRequests: Request[]
  ): Observable<boolean> {
    let matchedResponse: Response = null;

    return from(dataCache.match(request)).pipe(
      tap((response: Response) => {
        matchedResponse = response;
      }),
      switchMap((response: Response) => {
        return response.json();
      }),
      switchMap((responseBody: AssetList) => {
        // get exisitng list response JSON and add, update or delete
        switch (cacheUpdateType) {
          case CacheUpdateType.Add:
            responseBody.results.push(dataResponse);
            break;
          case CacheUpdateType.Update:
            {
              const updateIndex = responseBody.results.findIndex((x) => x.id === dataResponse.id);
              if (updateIndex < 0) {
                // temporarily log errors
                console.error('invalid index whilst updating an entity in the cache');
              } else {
                responseBody.results[updateIndex] = dataResponse;
              }
            }
            break;
          case CacheUpdateType.Delete:
            {
              const updateIndex = responseBody.results.findIndex((x) => x.id === dataId);
              if (updateIndex < 0) {
                // temporarily log errors
                console.error('invalid index whilst deleting an entity from the cache');
              }

              responseBody.results = responseBody.results.filter((x) => x.id !== dataId);
            }
            break;
          default:
            break;
        }

        const body = JSON.stringify(responseBody);
        const newHeaders = new Headers(matchedResponse.headers);
        newHeaders.set('content-length', body.length.toString());

        const init: ResponseInit = {
          headers: newHeaders,
          status: matchedResponse.status,
          statusText: matchedResponse.statusText,
        };

        const newResponse = new Response(body, init);
        return from(dataCache.put(request, newResponse));
      }),
      tap(() =>
        console.log(
          `${dataId} - cache ${CacheUpdateType[cacheUpdateType]
            .toString()
            .toLowerCase()} successful`
        )
      ),
      switchMap(() => {
        if (cacheUpdateType === CacheUpdateType.Update) {
          return of(true);
        } else {
          const adjustTotalResultsCount = allDataTypeRequests.map((req: Request) =>
            this.adjustForgeDataCacheResponseTotalResults(dataCache, cacheUpdateType, req)
          );
          const timeOperationLabel = 'cache total results adjust total time';
          console.time(timeOperationLabel);
          return forkJoin(adjustTotalResultsCount).pipe(
            map((results: boolean[]) => results.every((x) => x)),
            tap(() => console.timeEnd(timeOperationLabel))
          );
        }
      }),
      map((result: boolean) => result)
    );
  }

  // make sure the a cache add or delete results in the response total results being adjusted correctly
  private adjustForgeDataCacheResponseTotalResults(
    dataCache: Cache,
    cacheUpdateType: CacheUpdateType,
    request: Request
  ): Observable<boolean> {
    let matchedResponse: Response = null;
    let currentTotalResultCount = 0;
    let newTotalResultCount = 0;

    return defer(() => dataCache.match(request)).pipe(
      tap((response: Response) => {
        matchedResponse = response;
      }),
      switchMap((response: Response) => {
        return response.json();
      }),
      switchMap((responseBody: AssetList) => {
        currentTotalResultCount = responseBody.pagination.totalResults;
        switch (cacheUpdateType) {
          case CacheUpdateType.Add:
            newTotalResultCount = currentTotalResultCount + 1;
            break;
          case CacheUpdateType.Delete:
            newTotalResultCount = currentTotalResultCount - 1;
            break;
          default:
            break;
        }

        responseBody.pagination.totalResults = newTotalResultCount;

        const body = JSON.stringify(responseBody);
        const newHeaders = new Headers(matchedResponse.headers);
        newHeaders.set('content-length', body.length.toString());

        const init: ResponseInit = {
          headers: newHeaders,
          status: matchedResponse.status,
          statusText: matchedResponse.statusText,
        };

        const newResponse = new Response(body, init);
        return from(dataCache.put(request, newResponse));
      }),
      tap(() =>
        console.log(
          `Cache response url ${request.url} - cache ${CacheUpdateType[cacheUpdateType]
            .toString()
            .toLowerCase()} resulted in totalResults adjusted from ${currentTotalResultCount} to ${newTotalResultCount}`
        )
      ),
      map(() => true)
    );
  }

  // get cacheIdentity records which contains userId and activitySubmissionId
  private getCacheControlIdentityRecord(): Observable<CacheIdentityRecord> {
    return this.getFabdmDataControlCache().pipe(
      switchMap((controlCache: Cache) =>
        this.filterCacheForMatchingRequests(
          controlCache,
          new RegExp(this.fabdmCacheControlIdentityUrl)
        )
      ),
      switchMap((matchedData: Map<Request, Response>) => {
        if (!matchedData) {
          return of(null);
        } else {
          const response = [...matchedData.values()][0];
          return from(response.json());
        }
      })
    );
  }

  getCacheDataTypeRecord(configId: string, nodeId: string): Observable<CacheDataTypeRecord> {
    return this.getFabdmDataControlCache().pipe(
      switchMap((controlCache: Cache) =>
        this.filterCacheForMatchingRequests(
          controlCache,
          new RegExp(
            `${this.fabdmCacheDataControlName}/${this.getForgeContentDataUrlPattern(
              configId,
              nodeId
            )}`
          )
        )
      ),
      switchMap((matchedData: Map<Request, Response>) => {
        if (!matchedData) {
          return of(null);
        } else {
          const response = [...matchedData.values()][0];
          return from(response.json());
        }
      })
    );
  }

  cleanStaleConfigCacheRecords(
    configIds: string[],
    clearMatchingRecords: boolean
  ): Observable<boolean> {
    if (!this.isCacheSupported()) {
      this.printCacheNotSupportedMessage();
      return of(false);
    }

    // exclude cache identity record and schema requests
    const configsRegex = configIds?.join('|');
    const regexString = `^(?!.*(identity|schema${
      !clearMatchingRecords && configIds?.length ? '|' + configsRegex : ''
    })).*${clearMatchingRecords && configIds?.length ? '(' + configsRegex + ').*' : ''}$`;

    const configUrnRegex = new RegExp(regexString);

    return combineLatest([this.getFabdmDataControlCache(), this.getForgeContentDataCache()]).pipe(
      switchMap((cleanCaches: [Cache, Cache]) => {
        return forkJoin(cleanCaches.map((x) => this.removeCacheEntries(x, configUrnRegex))).pipe(
          map(() => true)
        );
      })
    );
  }

  // create cacheIdentity records which contains userId and activitySubmissionId
  private createCacheControlIdentityRecord(userId: string): Observable<CacheIdentityRecord> {
    const identityRecord: CacheIdentityRecord = {
      userId,
      activitySubmissionId: uuidv4(),
    };
    return this.getFabdmDataControlCache().pipe(
      switchMap((cache: Cache) => {
        const identityRecordPayload = JSON.stringify(identityRecord);
        return this.addOrUpdateCacheEntry(
          cache,
          this.fabdmCacheControlIdentityUrl,
          identityRecordPayload
        );
      }),
      map(() => identityRecord)
    );
  }

  // create cacheIdentity records which contains userId and activitySubmissionId
  private createCacheDataTypeRecord(
    dataType: DataElementType,
    configId: string,
    nodeId: string,
    isExternalNodeId: boolean,
    lastActivityIdProcessed: string
  ): Observable<CacheDataTypeRecord> {
    const dataTypeRecord: CacheDataTypeRecord = {
      lastActivityIdProcessed,
      dataType,
      nodeId,
      isExternalNodeId,
    };
    return this.getFabdmDataControlCache().pipe(
      switchMap((cache: Cache) => {
        const dataTypeRecordPayload = JSON.stringify(dataTypeRecord);
        return this.addOrUpdateCacheEntry(
          cache,
          `${this.fabdmCacheDataControlName}/${this.getForgeContentDataUrlPattern(
            configId,
            nodeId
          )}`,
          dataTypeRecordPayload
        );
      }),
      map(() => dataTypeRecord)
    );
  }

  private createControlCache(): Observable<Cache> {
    return from(caches.open(this.fabdmCacheDataControlName));
  }

  private addOrUpdateCacheEntry(cache: Cache, url: string, jsonPayload: string): Observable<void> {
    const request = new Request(url);
    const response = new Response(jsonPayload, {
      status: 200,
      statusText: 'Ok',
      headers: {
        'Content-Length': jsonPayload.length.toString(),
        'Content-Type': 'application/json',
      },
    });
    return from(cache.put(request, response));
  }

  private getCacheByRegExKey(cacheKeyRegex: RegExp): Observable<Cache> {
    return from(caches.keys()).pipe(
      map((cacheNames: string[]) => cacheNames.find((x) => cacheKeyRegex.test(x))),
      switchMap((cacheKey: string) => {
        if (cacheKey) {
          return from(caches.open(cacheKey));
        } else {
          return of(null);
        }
      })
    );
  }

  private getForgeContentDataCache = (): Observable<Cache> =>
    this.getCacheByRegExKey(new RegExp(`${this.forgeContentDataCacheName}`));

  private getForgeContentAgeCache = (): Observable<Cache> =>
    this.getCacheByRegExKey(new RegExp(`${this.forgeContentAgeCacheName}`));

  private getFabdmDataControlCache = (): Observable<Cache> =>
    this.getCacheByRegExKey(new RegExp(`${this.fabdmCacheDataControlName}`));

  private getNgSwDBControlCache = (): Observable<Cache> =>
    this.getCacheByRegExKey(new RegExp(`${this.ngSwDbControlCacheName}`));

  /**
   * Clean the cached data records i.e. cached api request/response
   * Use prior to SW update
   * @return {*}  {Observable<boolean>}
   * @memberof CacheService
   */
  public cleanDataCacheStorageForVersionUpdate(): Observable<boolean> {
    if (caches) {
      return from(caches.keys()).pipe(
        switchMap((cacheKeys: string[]) => {
          if (cacheKeys?.length) {
            // filter only data and cache-control records
            const filteredCacheKeys = cacheKeys.filter((x) => /data|cache-control/.test(x));
            console.log('Cleaning the following SW Caches', filteredCacheKeys);
            return forkJoin(
              filteredCacheKeys.map((x) =>
                from(caches.open(x)).pipe(
                  switchMap((cache: Cache) =>
                    // leave the cache-control identity record intact
                    this.removeCacheEntries(
                      cache,
                      /cache-control/.test(x) ? /^((?!identity).)*$/ : null
                    )
                  )
                )
              )
            ).pipe(map((results: boolean[]) => results.every((x) => x)));
          } else {
            return of(true);
          }
        })
      );
    }

    return of(true);
  }

  // clear all entries from a specified cache
  private removeCacheEntries(cache: Cache, urlFilter: RegExp = null): Observable<boolean> {
    if (cache) {
      return from(cache.keys()).pipe(
        switchMap((requests: Request[]) => {
          if (requests?.length) {
            // filter requests to remove if regex passed
            const filteredRequests = urlFilter
              ? requests.filter((x) => urlFilter.test(x.url))
              : requests;
            if (filteredRequests?.length) {
              return forkJoin(filteredRequests.map((x) => from(cache.delete(x)))).pipe(
                map((results: boolean[]) => {
                  return results.every((x) => !!x);
                })
              );
            } else {
              return of(true);
            }
          } else {
            return of(true);
          }
        })
      );
    }

    return of(false);
  }

  // create regex to search for cache requests by url using config id, node id and entity type
  private getRegExpForDataTypeUrlSearch(
    configId: string,
    nodeId: string,
    additionalSearchPattern: string,
    entityType: EntityType
  ): RegExp {
    return new RegExp(
      `${this.getForgeContentDataUrlPattern(
        configId,
        nodeId
      )}${additionalSearchPattern}(.)+entityType=${entityType}`
    );
  }

  private filterCacheForMatchingRequests(
    cache: Cache,
    urlMatch: RegExp
  ): Observable<Map<Request, Response>> {
    if (!cache) {
      return of(null);
    }

    const filteredMap = new Map<Request, Response>();

    return from(cache.keys()).pipe(
      switchMap((requests: Request[]) => {
        if (requests?.length) {
          const filteredRequests = requests.filter((x) => urlMatch.test(x.url));
          if (filteredRequests.length) {
            return forkJoin(
              filteredRequests.map((x) =>
                from(cache.match(x)).pipe(tap((response: Response) => filteredMap.set(x, response)))
              )
            ).pipe(map(() => filteredMap));
          } else {
            return of(null);
          }
        } else {
          return of(null);
        }
      })
    );
  }

  private getDataGroupMaxAge(dataGroupName: string): Observable<number> {
    return this.getNgSwDBControlCache().pipe(
      switchMap((cache: Cache) =>
        this.filterCacheForMatchingRequests(cache, new RegExp('manifests|latest'))
      ),
      switchMap((matchingData: Map<Request, Response>) => {
        if (matchingData) {
          return forkJoin([...matchingData.values()].map((x) => from(x.json())));
        } else {
          return throwError(() => 'Unable to find db:control records in cache');
        }
      }),
      map((payloads: any[]) => {
        let latestPayload = null;
        let manifestPayload = null;

        payloads.forEach((payload) => {
          if ('latest' in payload) {
            latestPayload = payload;
          } else {
            manifestPayload = payload;
          }
        });

        const latestKey = latestPayload.latest;
        const dataGroups: any[] = manifestPayload[latestKey].dataGroups;
        const dataGroup = dataGroups.find((x) => x.name === dataGroupName);

        if (dataGroup) {
          return dataGroup.maxAge;
        } else {
          return throwError(() => 'Unable to find data group in db:control records');
        }
      })
    );
  }
}
