import * as fromContentFile from '../actions/content-file.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContentFile } from '@models/fabrication/files';

export interface ContentFileState extends EntityState<ContentFile> {} // eslint-disable-line

export const contentFileAdapter: EntityAdapter<ContentFile> = createEntityAdapter<ContentFile>({
  selectId: (contentFile: ContentFile) => contentFile.contentExternalId,
});

export const initialState: ContentFileState = contentFileAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromContentFile.ContentFileActions
): ContentFileState {
  switch (action.type) {
    case fromContentFile.ContentFileActionTypes.UpsertContentFiles: {
      return contentFileAdapter.upsertMany(action.payload, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectContentFileIds,
  selectEntities: selectContentFileEntities,
  selectAll: selectAllContentFile,
  selectTotal: selectContentFileTotal,
} = contentFileAdapter.getSelectors();
