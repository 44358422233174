export class EnvironmentConstants {
  // cos monikers
  static readonly COS_APP_MONIKER_DEV = 'fabdm-c-uw2';
  static readonly COS_APP_MONIKER_STG = 'fabdm-s-ue1';
  static readonly COS_APP_MONIKER_PROD = 'fabdm-p-ue1';
  // URL
  static readonly URL_USER_PROFILE_STG =
    'https://developer-stg.api.autodesk.com/userprofile/v1/users/@me';
  static readonly URL_USER_PROFILE_PROD =
    'https://developer.api.autodesk.com/userprofile/v1/users/@me';
  static readonly URL_LOGOUT_STG = 'https://accounts-staging.autodesk.com/Authentication/LogOut';
  static readonly URL_LOGOUT_PROD = 'https://accounts.autodesk.com/Authentication/LogOut';
  static readonly URL_ACCOUNTS_STG = 'https://accounts-staging.autodesk.com';
  static readonly URL_ACCOUNTS_PROD = 'https://accounts.autodesk.com';
  static readonly URL_OXYGEN_ANALYTICS_ID_STG =
    'https://developer-stg.api.autodesk.com/identity/v1/users/@me/analytics';
  static readonly URL_OXYGEN_ANALYTICS_ID_PROD =
    'https://developer.api.autodesk.com/identity/v1/users/@me/analytics';

  static readonly URL_ANALYTICS_STG = 'https://ase-stg.autodesk.com';
  static readonly URL_ANALYTICS_PROD = 'https://ase.autodesk.com';
  static readonly URL_BINARY_TASK_MANAGER_DEV_V2 =
    'https://fabdm-dev-tm.autodesk.com/v2/task/binary';
  static readonly URL_BINARY_TASK_MANAGER_STG_V2 =
    'https://fabdm-stg-tm.autodesk.com/v2/task/binary';
  static readonly URL_BINARY_TASK_MANAGER_PROD_V2 = 'https://fabdm-tm.autodesk.com/v2/task/binary';
  static readonly URL_FABDM_TM_DEV = 'https://fabdm-dev-tm.autodesk.com/';
  static readonly URL_FABDM_TM_STG = 'https://fabdm-stg-tm.autodesk.com/';
  static readonly URL_FABDM_TM_PROD = 'https://fabdm-tm.autodesk.com/';
  static readonly URL_FCS_STG = 'https://developer-stg.api.autodesk.com/content/v2/';
  static readonly URL_FCS_PROD = 'https://developer.api.autodesk.com/content/v2/';

  // help links
  static readonly URL_BASE_HELP_LINKS_STG =
    'https://help-staging.autodesk.com/view/MEPCE/ENU/?guid=';
  static readonly URL_BASE_HELP_LINKS_PROD = 'https://help.autodesk.com/view/MEPCE/ENU/?guid=';
  // feedback link
  static readonly SIDE_NAV_SUB_MODULE_FEEDBACK_LINK = `https://autodeskfeedback.az1.qualtrics.com/jfe/form/SV_b3jtlfssn8bBNm6`;

  // fcs constants across all environments
  static readonly FCS_NODE_ID_SERVICE_DATA = '3E273180-9EFC-491D-B3CC-3FC1054882D7';
  static readonly FCS_NODE_ID_SYSTEMINFOS = '06B8E2AA-8099-4020-84BD-5253C25BFBB4';
  static readonly FCS_NODE_ID_SERVICE_TEMPLATE = '56A2EEEA-78B0-4B95-99DB-BE856EF8C327';
  static readonly FCS_NODE_ID_CONTENT_DATA = 'CD4EC178-B995-4A66-B7BA-ADE57772BE3D';
  static readonly FCS_NODE_ID_FDM_ITEM_FOLDER = 'b1939476-cd3d-4f6f-80fa-fc7e63c50310';
  static readonly FCS_NODE_ID_ITEM_FOLDERS = '30BAE725-BB0A-40AB-81A5-98E3B92933AC';
  static readonly FCS_NODE_ID_CORE_FILES = 'F501F50A-6777-46F3-A832-C1766BF68A75';
  static readonly FCS_NODE_ID_DATABASE = '2BEEBCFF-BE92-4E34-B0EA-D67849BDA36C';
  static readonly FCS_NODE_ID_SPECIFICATIONINFOS = 'BA541FB6-D249-49BF-A4B8-AB40E0C42845';
  static readonly FCS_NODE_ID_INS_SPECIFICATIONINFOS = 'AE11C3A3-0B7F-409F-A8D2-09DAECD854CB';
  static readonly FCS_NODE_ID_CONFIG_META = '5847FA23-0B52-4D9E-950E-04E3FD231605';
  static readonly FCS_CONTENT_ID_CONFIG_META = '188F379F-F3DA-47E2-9D10-5C1F53C5EF78';
  static readonly FCS_NODE_ID_CONNECTORINFOS = 'C06DFA9B-F353-491A-AEDF-332AC60EBE8E';
  static readonly FCS_NODE_ID_DAMPERINFOS = '54B564E3-4655-4F7D-97E3-C7CD925C7CC9';
  static readonly FCS_NODE_ID_STIFFENERINFOS = '9B704B86-AD4F-49FE-9CBB-CEA5FD150E59';
  static readonly FCS_NODE_ID_FABRICATIONRATES = '1205A8AF-9022-435E-AFF9-AF19E454A67C';
  static readonly FCS_NODE_ID_INSTALLATION_RATES = '5C7EC544-9AD7-4FF4-A15D-52C71CDBEB9A';
  static readonly FCS_NODE_ID_MATERIALS = 'E07858F9-A167-490C-967B-A993C2576942';
  static readonly FCS_NODE_ID_MATERIAL_SPECS = 'C2F40587-4F3A-49BE-9EA4-34481FA2D787';
  static readonly FCS_NODE_ID_INVALID_DATA = 'E54F3D5A-78B8-49E9-A2B0-FCC263E33765';
  static readonly FCS_NODE_ID_STIFFENER_SPECS = '8C2F5CAC-2F81-4048-9FCE-F235A6A87995';
  static readonly FCS_NODE_ID_IMAGE_FILES = 'C6869FA0-F8C8-408B-9B1E-23B080153112';

  // defaults
  static readonly FCS_DEFAULT_CONTENT_ID_FABRICATION_RATE = '5dfbc399-ea10-40cb-ba02-a75ab142a405';
  static readonly FCS_DEFAULT_CONTENT_ID_INSTALLATION_RATE = '6d88b983-3f52-41b7-8b56-c2967c3720e5';

  // unassigned and special data-type specific external IDs (instead of -1) - make sure to add any more to the
  // FCS_UNASSIGNED_COLLECTION below
  static readonly FCS_UNASSIGNED_CONNECTOR = 'connector.unassigned';
  static readonly FCS_NONE_CONNECTOR = 'connector.none';

  static readonly FCS_UNASSIGNED_PART_SPEC = 'specification.unassigned';

  static readonly FCS_UNASSIGNED_INSULATION_SPEC = 'insulationSpecification.unassigned';

  static readonly FCS_UNASSIGNED_MATERIAL = 'material.unassigned';
  static readonly FCS_ANY_MATERIAL = 'material.any';

  static readonly FCS_UNASSIGNED_MATERIAL_FINISH = 'materialFinish.unassigned';

  static readonly FCS_UNASSIGNED_MATERIAL_SPEC = 'materialSpecification.unassigned';

  static readonly FCS_UNASSIGNED_DAMPER = 'damper.unassigned';

  static readonly FCS_UNASSIGNED_STIFFENER = 'stiffener.unassigned';

  static readonly FCS_UNASSIGNED_STIFFENER_SPEC = 'stiffenerSpecification.unassigned';

  static get FCS_UNASSIGNED_COLLECTION(): string[] {
    return [
      '-1',
      '-2',
      EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR,
      EnvironmentConstants.FCS_UNASSIGNED_DAMPER,
      EnvironmentConstants.FCS_UNASSIGNED_INSULATION_SPEC,
      EnvironmentConstants.FCS_UNASSIGNED_MATERIAL,
      EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC,
      EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_FINISH,
      EnvironmentConstants.FCS_UNASSIGNED_PART_SPEC,
      EnvironmentConstants.FCS_UNASSIGNED_STIFFENER,
      EnvironmentConstants.FCS_UNASSIGNED_STIFFENER_SPEC,
      EnvironmentConstants.FCS_NONE_CONNECTOR,
      EnvironmentConstants.FCS_ANY_MATERIAL,
    ];
  }

  // FSSv3 schemas
  static readonly FSS_SCHEMA_NAMESPACE = 'autodesk.fabricationdm';

  static readonly FSS_SCHEMA_ASSET = 'asset.contentItem'; //Generic schema
  static readonly FSS_SCHEMA_ASSET_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_ROUND_CONNECTOR = 'roundConnectorInfo';
  static readonly FSS_SCHEMA_ROUND_CONNECTOR_VERSION = '1.2.0';
  static readonly FSS_SCHEMA_RECTANGULAR_CONNECTOR = 'rectangularConnectorInfo';
  static readonly FSS_SCHEMA_RECTANGULAR_CONNECTOR_VERSION = '1.1.0';
  static readonly FSS_SCHEMA_OVAL_CONNECTOR = 'ovalConnectorInfo';
  static readonly FSS_SCHEMA_OVAL_CONNECTOR_VERSION = '1.1.0';
  static readonly FSS_SCHEMA_DAMPER = 'damperInfo';
  static readonly FSS_SCHEMA_DAMPER_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_FABRICATION_RATE = 'fabricationRate';
  static readonly FSS_SCHEMA_FABRICATION_RATE_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_INSTALLATION_RATE = 'installationRate';
  static readonly FSS_SCHEMA_INSTALLATION_RATE_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_INSULATION_SPEC = 'insulationSpecification';
  static readonly FSS_SCHEMA_INSULATION_SPEC_VERSION = '1.0.3';
  static readonly FSS_SCHEMA_MATERIAL = 'material';
  static readonly FSS_SCHEMA_MATERIAL_VERSION = '1.1.0';
  static readonly FSS_SCHEMA_MATERIAL_FINISH = 'materialFinish';
  static readonly FSS_SCHEMA_MATERIAL_FINISH_VERSION = '1.0.1';
  static readonly FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC =
    'electricalContainmentMaterialSpecification';
  static readonly FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC = 'pipeworkMaterialSpecification';
  static readonly FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC = 'rectangularMaterialSpecification';
  static readonly FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC_VERSION = '2.0.0';
  static readonly FSS_SCHEMA_ROUND_MATERIAL_SPEC = 'roundMaterialSpecification';
  static readonly FSS_SCHEMA_ROUND_MATERIAL_SPEC_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC = 'sheetMetalMaterialSpecification';
  static readonly FSS_SCHEMA_SHEET_METAL_MATERIAL_SPEC_VERSION = '1.0.0';
  static readonly FSS_SCHEMA_PART = 'part';
  static readonly FSS_SCHEMA_PART_VERSION = '3.6.0';
  static readonly FSS_SCHEMA_PART_LAST_COMPATIBLE_VERSION = '3.1.2';
  static readonly FSS_SCHEMA_SERVICE_TEMPLATE = 'partTemplate';
  static readonly FSS_SCHEMA_SERVICE_TEMPLATE_VERSION = '1.1.0';
  static readonly FSS_SCHEMA_SERVICE = 'service';
  static readonly FSS_SCHEMA_SERVICE_VERSION = '1.1.0';
  static readonly FSS_SCHEMA_PART_SPEC = 'specification';
  static readonly FSS_SCHEMA_PART_SPEC_VERSION = '1.0.3';
  static readonly FSS_SCHEMA_STIFFENER = 'stiffenerInfo';
  static readonly FSS_SCHEMA_STIFFENER_VERSION = '1.0.0';

  static readonly FSS_SCHEMA_INVALID_DATA = 'invalidData';
  static readonly FSS_SCHEMA_INVALID_DATA_VERSION = '1.0.0';

  static readonly FSS_SCHEMA_STIFFENER_SPEC = 'stiffenerSpecification';
  static readonly FSS_SCHEMA_STIFFENER_SPEC_VERSION = '1.0.0';

  static readonly FSS_SUB_SCHEMA_ROUND_CONNECTOR_GEOMETRY_TABLE_ROW =
    'roundConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_ROUND_STANDARD_CONNECTOR_GEOMETRY_TABLE_ROW =
    'roundStandardConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_ROUND_HEX_END_CONNECTOR_GEOMETRY_TABLE_ROW =
    'roundHexEndConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_RECTANGULAR_CONNECTOR_GEOMETRY_TABLE_ROW =
    'rectangularConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_RECTANGULAR_STEP_DOWN_CONNECTOR_GEOMETRY_TABLE_ROW =
    'rectangularStepDownConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_RECTANGULAR_COUPLING_PLATE_CONNECTOR_GEOMETRY_TABLE_ROW =
    'rectangularCouplingPlateConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_OVAL_CONNECTOR_GEOMETRY_TABLE_ROW =
    'ovalConnectorGeometryTableRow';
  static readonly FSS_SUB_SCHEMA_INSULATION_SPECIFICATION_TABLE = 'insulationSpecificationTable';
  static readonly FSS_SUB_SCHEMA_SINGLE_DIMENSION_TABLE_ENTRY = 'singleDimensionTableEntry';
  static readonly FSS_SUB_SCHEMA_RECTANGULAR_MATERIAL_SPEC_SIZE =
    'rectangularMaterialSpecificationSize';
  static readonly FSS_SUB_SCHEMA_ROUND_MATERIAL_SPEC_SIZE = 'roundMaterialSpecificationSize';
  static readonly FSS_SUB_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC_SIZE =
    'electricalContainmentMaterialSpecificationSize';
  static readonly FSS_SUB_SCHEMA_PIPEWORK_MATERIAL_SPEC_SIZE = 'pipeworkMaterialSpecificationSize';

  static readonly FSS_SUB_SCHEMA_PALETTE = 'palette';
  static readonly FSS_SUB_SCHEMA_SIZE_RESTRICTION = 'sizeRestriction';
  static readonly FSS_SUB_SCHEMA_SIZE_RESTRICTION_VALUE = 'sizeRestrictionValue';
  static readonly FSS_SUB_SCHEMA_PART_SPEC_TABLE = 'partSpecificationTable';
  static readonly FSS_SUB_SCHEMA_PART_SPEC_PIPEWORK_TABLE_ENTRY = 'pipeworkTableEntry';

  // forge schema service
  static readonly URL_FSS_STG = 'https://developer-stg.api.autodesk.com/schema/v3/schemas/json:';
  static readonly URL_FSS_PROD = 'https://developer.api.autodesk.com/schema/v3/schemas/json:';
  // forge activities
  static readonly URL_ACTIVITY_STG = 'https://developer-stg.api.autodesk.com/activities/v1/';
  static readonly URL_ACTIVITY_PROD = 'https://developer.api.autodesk.com/activities/v1/';
  // forge notification
  static readonly URL_NOTIFICATION_STG = 'https://developer-stg.api.autodesk.com/notifications/v1/';
  static readonly URL_NOTIFICATION_PROD = 'https://developer.api.autodesk.com/notifications/v1/';

  // fabdm-cm (content manager service)
  static readonly URL_FABDM_CM_DEV = 'https://fabdm-dev-cm.autodesk.com/';
  static readonly URL_FABDM_CM_STG = 'https://fabdm-stg-cm.autodesk.com/';
  static readonly URL_FABDM_CM_PROD = 'https://fabdm-cm.autodesk.com/';

  static readonly IMPERIAL_DECIMAL_PLACES = 5;
  static readonly METRIC_DECIMAL_PLACES = 3;
  static readonly MAX_DECIMAL_PLACES_FOR_EDITING = 9;
}
