// https://wiki.autodesk.com/display/ADP/Usage+analytics+facet+-+operation/
export enum AnalyticsOperationStatus {
  Defined = 'D',
  Initialized = 'I',
  Started = 'S',
  Error = 'R',
  Failed = 'F',
  Aborted = 'A',
  Complete = 'C',
  Ended = 'E',
}
