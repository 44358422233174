<fab-dynamic-modal #modal> </fab-dynamic-modal>

<!-- group and size restriction selectors -->
<div
  class="col-12 group-size-restriction-selectors pr-2"
  *ngIf="selectedPalette && selectedSizeRestriction"
>
  <mat-form-field class="mr-auto">
    <mat-label>{{
      lc.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.SELECT_PALETTE | translate
    }}</mat-label>
    <mat-select
      [(value)]="selectedPalette"
      (selectionChange)="clearSelectedPartCollection()"
      aria-label="{{ lc.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.SELECT_PALETTE | translate }}"
    >
      <mat-option *ngFor="let palette of palettes" [value]="palette">
        {{ palette.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{
      lc.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.SELECT_SIZE_RESTRICTION | translate
    }}</mat-label>
    <mat-select
      [(value)]="selectedSizeRestriction"
      aria-label="{{
        lc.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.SELECT_SIZE_RESTRICTION | translate
      }}"
    >
      <mat-option *ngFor="let sizeRestriction of sizeRestrictions" [value]="sizeRestriction">
        {{ sizeRestriction.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="!isReadOnly" class="col-12">
  <fab-tool-bar
    *ngIf="
      toolBarOptions?.permanentToolBarButtons.buttons.length;
      else no_template_groups_sizeRestrictions
    "
    [options]="toolBarOptions"
  ></fab-tool-bar>

  <ng-template #no_template_groups_sizeRestrictions class="no-data mt-3">
    <p>
      <strong>
        {{ lc.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.NO_DATA | translate }}
      </strong>
    </p>
  </ng-template>
</div>

<!-- part collections -->
<div class="col-md-12 mt-2">
  <div
    *ngIf="selectedPalette?.partCollections && thumbnailsMap"
    id="{{ idLookup.partTemplateInfoPartsDiv }}"
  >
    <div
      class="float-left part-button"
      *ngFor="let partCollection of selectedPalette.partCollections; let index = index"
      [ngClass]="{
        'part-button-selected': !isReadOnly && selectedPartCollection === partCollection
      }"
    >
      <fab-tooltip [content]="partCollection.name">
        <fab-service-template-part
          [thumbnail]="getPartCollectionThumbnail(partCollection)"
          [isExcluded]="partCollection.isExcluded"
          [name]="partCollection.name"
          (click)="selectPartCollection(partCollection)"
          [ngClass]="{
            'part-button-size-restriction': hasSizeRestriction(partCollection),
            'part-button-no-size-restriction': !hasSizeRestriction(partCollection)
          }"
          [partReferenceCount]="partCollection.parts.length"
          id="part-collection-{{ index }}"
          [index]="index"
        ></fab-service-template-part>
      </fab-tooltip>
    </div>
  </div>
</div>
