import * as fromAccessRights from '../actions/access-rights.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AccessRightsData } from '@models/access-rights/access-rights';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccessRightsState extends EntityState<AccessRightsData> {}

export const AccessRightsAdapter: EntityAdapter<AccessRightsData> =
  createEntityAdapter<AccessRightsData>({
    selectId: (data: AccessRightsData) => data.id,
  });

export const initialState: AccessRightsState = AccessRightsAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromAccessRights.AccessRightsActions
): AccessRightsState {
  switch (action.type) {
    case fromAccessRights.AccessRightsActionTypes.AccessRightsLoadRole: {
      return state;
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsLoadRoleSuccess: {
      return AccessRightsAdapter.upsertOne(action.payload.data, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsLoadUsers: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.config.id],
        loadingState: {
          isLoading: true,
          hasFailed: false,
        },
      };
      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsLoadUsersSuccess: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.id],
        users: action.payload.users,
        loadingState: {
          isLoading: false,
          hasFailed: false,
        },
      };

      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsLoadUsersFail: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.id],
        loadingState: {
          isLoading: false,
          hasFailed: true,
        },
      };

      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsAddUser: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.config.id],
        loadingState: {
          isLoading: true,
          hasFailed: false,
        },
      };
      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsAddUserSuccess: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.id],
        users: action.payload.users,
        loadingState: {
          isLoading: false,
          hasFailed: false,
        },
      };

      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsAddUserFail: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.id],
        loadingState: {
          isLoading: false,
          hasFailed: true,
        },
      };

      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsRemoveUsers: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.config.id],
        loadingState: {
          isLoading: true,
          hasFailed: false,
        },
      };
      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsRemoveUsersSuccess: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.id],
        users: action.payload.users,
        loadingState: {
          isLoading: false,
          hasFailed: false,
        },
      };

      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsRemoveUsersFail: {
      const accessRightsData: AccessRightsData = {
        ...state.entities[action.payload.id],
        loadingState: {
          isLoading: false,
          hasFailed: true,
        },
      };

      return AccessRightsAdapter.upsertOne(accessRightsData, state);
    }
    case fromAccessRights.AccessRightsActionTypes.AccessRightsRemoveData: {
      return AccessRightsAdapter.removeOne(action.payload.id, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectAccessRightsIds,
  selectEntities: selectAccessRightsEntities,
  selectAll: selectAllAccessRights,
  selectTotal: selectAccessRightsTotal,
} = AccessRightsAdapter.getSelectors();
