import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromPartFilterActivity from '../reducers/part-filter.reducer';
import { PartFilterConfig } from '@models/forge-content/part-search';

export const selectPartFilterState = (state: FDMState) => state.applicationState.partFiler;

export const selectPartFilterEntities = createSelector(
  selectPartFilterState,
  fromPartFilterActivity.selectPartFilterEntities
);

export const selectPartFilterById = (id: string) =>
  createSelector(selectPartFilterEntities, (partFilter): PartFilterConfig => {
    return partFilter[id];
  });
