import * as fromEntitlements from '../actions/entitlements.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Entitlement } from '@models/entitlements/entitlement';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EntitlementsState extends EntityState<Entitlement> {}

export const EntitlementsAdapter: EntityAdapter<Entitlement> = createEntityAdapter<Entitlement>({
  selectId: (data: Entitlement) => data.featureId,
});

export const initialState: EntitlementsState = EntitlementsAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromEntitlements.EntitlementActions
): EntitlementsState {
  switch (action.type) {
    case fromEntitlements.EntitlementActionTypes.EntitlementsLoadForCurrentUser: {
      return state;
    }
    case fromEntitlements.EntitlementActionTypes.EntitlementsLoadForCurrentUserSuccess: {
      return EntitlementsAdapter.upsertOne(action.payload.entitlement, state);
    }
    case fromEntitlements.EntitlementActionTypes.EntitlementsLoadForCurrentUserFail: {
      return state;
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectEntitlementsIds,
  selectEntities: selectEntitlementsEntities,
  selectAll: selectAllEntitlements,
  selectTotal: selectEntitlementsTotal,
} = EntitlementsAdapter.getSelectors();
