import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { higSvgIcons } from '@constants/icon-constants';

@Injectable({
  providedIn: 'root',
})
export class SvgIconService {
  public constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  registerSvgIcons() {
    for (const svgIcon of higSvgIcons) {
      this.matIconRegistry.addSvgIcon(
        svgIcon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(svgIcon.path)
      );
    }
  }
}
