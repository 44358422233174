import { Injectable } from '@angular/core';
import {
  ForgeContentClient,
  Attribute,
  Node,
  SearchContentItemsResponse,
  CreateContentItemRequest,
  Response,
  CommandResponse,
  ContentItem,
  HttpResponse,
  UpdateContentItemRequest,
  AssetList,
  SearchContentItemsPostRequest,
  HttpErrorResponse,
  ContentItemFacetPostResponse,
} from '@adsk/content-sdk';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { FcsEnvironmentSettings } from '@models/environment/environment-settings';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '@services/analytics.service';
import { AuthService } from '@services/auth.service';
import { EnvironmentService } from '@services/environment.service';
import { LoggingService } from '@services/logging.service';
import { FDMState } from '@store/reducers';
import { DynamicDataService } from './dynamic-data.service';
import { ResiliencyConstants as rc } from '@constants/resiliency-constants';
import { DataElementType } from '@constants/data-element-types';
import { CacheTableEntry } from '@models/cache/cache';
import { ApiError } from '@models/errors/api-error';
import { Config } from '@models/fabrication/config';
import { ContentNode } from '@models/fabrication/content-node';
import {
  ContentFile,
  StorageFileType,
  StorageFileRemovalRequest,
  ContentFileMap,
  ThumbnailFile,
} from '@models/fabrication/files';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import {
  combineLatest,
  defer,
  from as observableFrom,
  Observable,
  of,
  timer,
  Subscription,
  lastValueFrom,
  Subject,
} from 'rxjs';
import { catchError, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { ErrorUtils } from '@utils/error-utils';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';
import { AnalyticsOperationStatus } from '@models/analytics/analytics-component';
import { UpsertContentFiles } from '@store/actions/content-file.action';
import { UpsertThumbnailFiles } from '@store/actions/thumbnail-file.action';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';
import {
  FabricationIndexableReference,
  FabricationReference,
  ReferencedData,
} from '@models/forge-content/references';
import { v4 as uuidv4 } from 'uuid';
import { EnvironmentConstants } from '@constants/environment-constants';
import { Part } from '@models/fabrication/part';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { LoadDataElementsSuccessAction } from '@store/actions/base/data-element.action';
import { cloneDeepWith, startCase } from 'lodash';
import { ValidatorService } from './validator.service';
import { ForgeDataSearchOption } from '@models/forge-content/forge-data-search';
import { UserSettingsService } from './user-settings.service';
import { HttpUtils } from '@utils/http-utils';
import { UploadThumbnailService } from './upload-thumbnail.service';
import { NotificationService } from './notification.service';
import { NotificationType } from '@models/notification/notification';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';
import { EventType, TrackInfo } from '@adsk/adp-web-analytics-sdk';
import { DebugModeService } from './debug-mode.service';

export type OperationType = 'load' | 'loadById' | 'add' | 'update' | 'copy' | 'delete' | 'fix';

export class DataOperationInfo {
  correlationId: string;
  startTime: number;
  endTime: number;
  dataType: DataElementType | string;
  operationType: OperationType;
  errors?: string[];
  paralellCalls?: number | null;
  isSuccess: boolean;

  constructor(dataElementType: DataElementType | string, operationType: OperationType) {
    this.correlationId = uuidv4();
    this.startTime = Date.now();
    this.dataType = dataElementType;
    this.operationType = operationType;
  }
}

export interface DeleteResult {
  success: boolean;
  references?: ReferencedData[];
  errors?: ApiError;
}

export const simultaneousUpdateErrorStatus = 412;
const staleContentErrorMessage = 'stale content item';
const getCommandErrorMessage = 'get command failed';

// disable linting errors on this file temporarily
/* eslint-disable */
@Injectable({
  providedIn: 'root',
})
export class ForgeContentService {
  private client: ForgeContentClient = null;
  private fcsEnvironment: FcsEnvironmentSettings = null;
  // set concurrent calls to reasonable value
  // taking into consideration http1.1 browser limitations
  private concurentHttpCalls = 6;

  private configSubscription: Subscription = null;
  private currentConfig: Config = null;

  public apiErrorSubject = new Subject<ApiError>();

  public static get pageSize(): number {
    return 20;
  }

  constructor(
    private authService: AuthService,
    private envService: EnvironmentService,
    private loggingService: LoggingService,
    private analyticsService: AnalyticsService,
    private fileStorageService: BinaryStorageService,
    private dynamicDataService: DynamicDataService,
    private store$: Store<FDMState>,
    validationService: ValidatorService,
    private userSettingsService: UserSettingsService,
    private uploadThumbnailService: UploadThumbnailService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private debugModeService: DebugModeService
  ) {
    // avoids a circular dependency
    validationService.setForgeContentService(this);
    this.fcsEnvironment = this.envService.environment.fcs;
    this.setupClient();
    this.listenToConfigChange();
  }

  private setupClient() {
    this.client = new ForgeContentClient({
      httpOptions: {
        baseURL: this.fcsEnvironment.fcsUrl,
        oAuth2Func: () => lastValueFrom(this.authService.getAccessToken()),
        timeout: 30000,
        addXRequestId: () => uuidv4(),
      },
      resilienceOptions: {
        retries: rc.DEFAULT_RETRY_COUNT,
      },
    });
  }

  private listenToConfigChange() {
    this.configSubscription = this.store$
      .select(selectCurrentConfig)
      .pipe(tap((config: Config) => (this.currentConfig = config)))
      .subscribe();
  }

  ngOnDestroy() {
    this.configSubscription?.unsubscribe();
  }

  public search<T extends ForgeContentDataElement>(query: string, filter: string): Observable<T[]> {
    return observableFrom(
      this.client.searchContentItemsPost({
        collectionId: this.currentConfig.collectionId,
        libraryId: this.currentConfig.id,
        query,
        filter,
      })
    ).pipe(
      map((response) => this.deserializeContentItemListResponse(response.data.results) as T[])
    );
  }

  public static splitSearchQuery(field: string, query: string): string {
    let filtered = query?.split(' ').filter((x) => !!x.trim());

    // allow single char searches, but remove single chars from multiple word searches
    // e.g. "e" is allowed, but "45 e" is not and becomes "45"
    if (filtered?.length > 1) filtered = filtered.filter((x) => x.length > 1);

    return filtered?.map((x) => `${field}:\"${x}\"`).join(' AND ') ?? '';
  }

  public dataSearch(
    option: ForgeDataSearchOption
  ): Observable<SearchContentItemsResponse | ContentItemFacetPostResponse> {
    const abortController = new AbortController();

    let query = ForgeContentService.splitSearchQuery('info.name', option.filter.query);

    if (this.debugModeService.enablePatternNumberSearch) {
      if (option.filter.customQuery?.length) {
        query += ` OR (${option.filter.customQuery})`;
      }
    }

    const typeFilter = `type.name:"${option.schemaType}"`;
    const facetFilter: string[][] = [];
    const includeFacets: string[] = [];
    for (const facet of option.filter.facets) {
      if (!facet.excludeResponse) includeFacets.push(facet.forgeDataKey);
      if (facet.value && facet.value.length)
        facetFilter.push(facet.value.map((v) => `${facet.forgeDataKey}:"${v}"`));
    }

    let filter: string = typeFilter;
    if (facetFilter.length) {
      filter += ` AND ${facetFilter.map((o) => `(${o.join(' OR ')})`).join(' AND ')}`;
    }

    if (option.filter.excludeData) {
      return HttpUtils.cancellablePromise(
        () =>
          this.client.facetContentItemsPost({
            collectionId: this.currentConfig.collectionId,
            libraryId: this.currentConfig.id,
            query,
            filter,
            facets: includeFacets,
            abortSignal: abortController.signal,
            customQueryParams: {
              'ngsw-bypass': 'true',
            },
          }),
        abortController
      ).pipe(map((response: HttpResponse<ContentItemFacetPostResponse>) => response.data));
    }

    //Used only for logging into analytics
    const filterLabels = Object.fromEntries(
      option.filter.facets.map((o) => [`label-${o.key}`, o.label])
    );

    const useFacets = !option.filter.offset && includeFacets.length ? includeFacets : undefined;
    const request: SearchContentItemsPostRequest = {
      collectionId: this.currentConfig.collectionId,
      libraryId: this.currentConfig.id,
      query,
      limit: option.filter.limit,
      offset: option.filter.offset,
      filter,
      orderBy: option.filter.orderBy,
      facets: useFacets,
      abortSignal: abortController.signal,
      customQueryParams: {
        'ngsw-bypass': 'true',
      },
    };

    const dataObserver = HttpUtils.cancellablePromise(() => {
      return this.client.searchContentItemsPost(request);
    }, abortController).pipe(
      take(1),
      map((response: HttpResponse<SearchContentItemsResponse>) => response.data),
      tap((response: SearchContentItemsResponse) => {
        // only collect data if the user has changed something
        if (query?.length || facetFilter.length || option.filter.offset > 0) {
          const metrics: Record<string, string> = {
            query,
            filter,
            ...filterLabels,
            offset: option.filter.offset.toString(),
            facets: useFacets?.toString() ?? '',
            totalResults: response.pagination.totalResults.toString(),
            view: this.userSettingsService.currentUserSettings.dataViewType,
          };
          this.analyticsService.recordComponentMetrics(
            'forge-content-search-content-item-post-success',
            'FCS search content items success',
            `${option.dataType.toLowerCase()}-search`,
            metrics
          );
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.loggingService.logError(err, false);

        this.analyticsService.recordComponentMetrics(
          'forge-content-search-content-item-post-error',
          'FCS search content items error',
          `${option.dataType.toLowerCase()}-search`,
          {
            request: JSON.stringify(request),
            ...filterLabels,
            errorStatusCode: err?.statusCode?.toString() ?? 'unknown',
            errorMessage: err?.message ?? '',
          }
        );

        // propagate the error
        throw err;
      })
    );

    //Do not find the updated count if this is not the first page or there are no more than 1 facets to be included
    if (
      option.filter.offset ||
      !option.filter?.facets?.some((f) => !f.excludeResponse && f.value.length)
    ) {
      return dataObserver;
    }

    const observers = [dataObserver];
    const possibleFacetsList = [null];

    for (const facet of option.filter.facets) {
      if (facet.value && facet.value.length) {
        const facetCountOption = { ...option };
        facetCountOption.filter = { ...option.filter };
        facetCountOption.filter.excludeData = true;
        //Only get facet of the particular filter
        facetCountOption.filter.facets = option.filter.facets.map((f) => ({
          ...f,
          excludeResponse: f.key === facet.key ? false : true,
          value: f.key === facet.key ? [] : f.value,
        }));
        observers.push(this.dataSearch(facetCountOption));
        possibleFacetsList.push(facet.possibleFacets);
      }
    }

    return combineLatest(observers).pipe(
      map((result) => {
        let facets = {};

        //Combine all individual facets with filtered facets
        //Start with individual to make it more optimal
        for (let i = result.length - 1; i >= 0; i--) {
          const possibleFacets = possibleFacetsList[i];
          for (const facetType in result[i].facets) {
            let facetTypeResult = result[i].facets[facetType];
            if (possibleFacets?.size) {
              facetTypeResult.buckets = facetTypeResult.buckets.filter((x) =>
                possibleFacets.has(x.key)
              );
            }

            if (!facets[facetType]) {
              facets[facetType] = facetTypeResult;
            } else {
              const facetCount = facetTypeResult.buckets.reduce(
                (prev, curr) => ({ ...prev, [curr.key]: curr.count }),
                {}
              );
              facets[facetType].buckets.forEach((o) => {
                //If a count for that particular facet exists, set the minimum one
                if (o.key in facetCount && facetCount[o.key] < o.count) o.count = facetCount[o.key];
                delete facetCount[o.key];
              });

              //Add all the additional facets that were not present earlier
              facets[facetType].buckets.push(
                ...Object.entries(facetCount).map(([key, count]) => ({ key, count }))
              );
            }
          }
        }
        result[0].facets = facets;
        return result[0];
      })
    );
  }

  // ******************* nodes

  getNode(
    nodeId: string,
    isExternalId: boolean,
    nodeContentType: DataElementType,
    byPassCache: boolean
  ): Observable<ApiError | [ContentNode, Node]> {
    // operation info for analytics
    const operationInfo = this.getStartAnalyticsOperationInfo('node', 'load');
    let operationSuccess = true;

    return observableFrom(
      this.client.getNode({
        collectionId: this.currentConfig.collectionId,
        libraryId: this.currentConfig.id,
        nodeId,
        isExternalId,
        customQueryParams: byPassCache ? { 'ngsw-bypass': 'true' } : undefined,
      })
    ).pipe(
      map((response: HttpResponse<Node>) => {
        return [this.getNodeElement(response.data, nodeContentType), response.data] as [
          ContentNode,
          Node
        ];
      }),
      catchError((err) => {
        operationSuccess = false;
        return of(this.handleError(err, 'node', operationInfo.correlationId));
      }),
      tap(() => {
        this.recordAnalyticsOperationInfo(operationInfo, operationSuccess);
      })
    );
  }

  // ******************* content items

  loadAllContentFromNode<T extends ForgeContentDataElement>(
    config: Config,
    contentNodeId: string,
    isExternalId: boolean,
    dataType: DataElementType,
    filesAreReferenced?: boolean,
    bypassCache = false
  ): Observable<[T[], ContentFile[], CacheTableEntry[]] | ApiError> {
    const timerLabelPrefix = `${config.name}-load-${dataType}-`;
    const timerMainLabel = `${timerLabelPrefix}totaltime`;
    const timerDataLoadLabel = `${timerLabelPrefix}dataloadtime`;
    console.time(timerMainLabel);

    const setup = this.dynamicDataService.getDynamicDataSetupForType(dataType);
    const supportedStorageFileTypes = setup.options.bulkLoadFileTypesSupported;

    // operation info for analytics
    const operationInfo = this.getStartAnalyticsOperationInfo(dataType, 'load');
    let operationSuccess = true;

    let contentFilesMap: ContentFileMap[] = [];
    const cacheTableEntries: CacheTableEntry[] = [];
    let numPages = 0;
    // start data load timer
    console.time(timerDataLoadLabel);

    const getNodeChildren = (offset: number): Observable<HttpResponse<AssetList>> => {
      return defer(() =>
        this.client.getNodeChildren({
          collectionId: config.collectionId,
          libraryId: config.id,
          nodeId: contentNodeId,
          entityType: 'content',
          isExternalId,
          offset,
          limit: ForgeContentService.pageSize,
          customQueryParams: bypassCache
            ? {
                'ngsw-bypass': 'true',
              }
            : null,
        })
      );
    };

    return getNodeChildren(0).pipe(
      map((response: HttpResponse<AssetList>) => {
        if (!response.data.pagination?.totalResults) {
          return [];
        }

        numPages = this.getNumPages(
          response.data.pagination.totalResults,
          response.data.pagination.limit
        );

        // get content file map
        contentFilesMap = this.getContentElementFileContent(
          response.data.results as ContentItem[],
          config.externalId
        );

        // cache table entries
        // start population of cache table
        cacheTableEntries.push(...response.data.results.map((x) => ({ id: x.id, pageNumber: 0 })));

        return this.deserializeContentItemListResponse<T>(
          response.data.results as ContentItem[],
          filesAreReferenced
        );
      }),
      switchMap((contentList: T[]) => {
        if (numPages <= 1) {
          return of(contentList);
        }

        const observables: Observable<HttpResponse<AssetList>>[] = [];
        for (let i = 1; i < numPages; i++) {
          const offset = ForgeContentService.pageSize * i;
          observables.push(getNodeChildren(offset));
        }

        return RxjsUtils.concurrentForkJoin(observables, this.concurentHttpCalls).pipe(
          tap(() => console.timeEnd(timerDataLoadLabel)),
          map((responses: HttpResponse<AssetList>[]) => {
            responses.forEach((response, index) => {
              // note that FC2.0 does not use page numbers and that the offset starts at 0
              // whereas FC1.0 pageNumber starts at 1.
              if (response.data.pagination?.totalResults) {
                contentList = [
                  ...contentList,
                  ...this.deserializeContentItemListResponse<T>(
                    response.data.results as ContentItem[],
                    filesAreReferenced
                  ),
                ];

                // get content files
                contentFilesMap = [
                  ...contentFilesMap,
                  ...this.getContentElementFileContent(
                    response.data.results as ContentItem[],
                    config.externalId
                  ),
                ];

                // cache table entries
                // start population of cache table
                cacheTableEntries.push(
                  ...response.data.results.map((x: ContentItem) => ({
                    id: x.id,
                    pageNumber: index + 1,
                  }))
                );
              }
            });
            return contentList;
          })
        );
      }),
      // finally persist any raw content files is requested
      switchMap((contentList: T[]) => {
        const contentFiles =
          (contentFilesMap.length && contentFilesMap.map((x) => x.contentFile)) || [];

        const data: [T[], ContentFile[], CacheTableEntry[]] = [
          contentList,
          contentFiles,
          cacheTableEntries,
        ];

        // add binaries to persistent storage
        if (contentFilesMap.length && supportedStorageFileTypes) {
          return this.fileStorageService
            .addStorageFiles(contentFilesMap, dataType, supportedStorageFileTypes)
            .pipe(map(() => data));
        } else {
          return of(data);
        }
      }),
      catchError((err) => {
        operationSuccess = false;
        return of(this.handleError(err, dataType, operationInfo.correlationId));
      }),
      tap(() => {
        console.timeEnd(timerMainLabel);
        this.recordAnalyticsOperationInfo(
          operationInfo,
          operationSuccess,
          operationSuccess && numPages
        );
      })
    );
  }

  loadContentById<T extends ForgeContentDataElement, R extends ForgeContentDataElement>(
    config: Config,
    contentId: string,
    dataType: DataElementType,
    overrideStorageFileTypesSupported: StorageFileType[],
    storageFileRemovalRequest: StorageFileRemovalRequest[],
    filesAreReferenced?: boolean,
    referencedDataTypeSuccessAction?: LoadDataElementsSuccessAction<R>
  ): Observable<ApiError | [T, ContentFile[], ContentItem, R[]]> {
    const setup = this.dynamicDataService.getDynamicDataSetupForType(dataType);
    const supportedStorageFileTypes = overrideStorageFileTypesSupported?.length
      ? overrideStorageFileTypesSupported
      : setup.options.bulkLoadFileTypesSupported;

    // operation info for analytics
    const operationInfo = this.getStartAnalyticsOperationInfo(dataType, 'loadById');
    let operationSuccess = true;
    let contentFilesMap: ContentFileMap[] = [];
    let contentItem: ContentItem = null;
    let referencedData: R[] = [];

    return observableFrom(
      // make sure we bypass the cache when we get the content item
      this.getContentItemAndRetry(config.collectionId, config.id, contentId, null)
    ).pipe(
      // get initial response with paging info
      map((theContentItem: ContentItem) => {
        contentItem = theContentItem;
        // get content file map (not needed if the files are referenced - we create this later on)
        contentFilesMap = filesAreReferenced
          ? []
          : this.getContentElementFileContent([contentItem], config.externalId);
        // convert response
        return this.deserializeSingleContentResponse<T>(contentItem, filesAreReferenced);
      }),
      // check to see if we need to get any referenced data
      switchMap((content: T) => {
        if (!filesAreReferenced) {
          return of(content);
        }

        const externalIds: string[] = [];
        content?.files?.forEach((x) => externalIds.push(x));
        content?.thumbnails?.forEach((x) => externalIds.push(x));

        return this.batchGetContentItems(config.collectionId, config.id, [
          ...new Set(externalIds),
        ]).pipe(
          map((contentItems: ContentItem[]) => {
            referencedData = this.deserializeContentItemListResponse(contentItems);
            const filesMap = this.getContentElementFileContent(contentItems, config.externalId);
            filesMap.forEach((x) => contentFilesMap.push(x));

            if (referencedDataTypeSuccessAction) {
              referencedDataTypeSuccessAction.data = referencedData;
              this.store$.dispatch(referencedDataTypeSuccessAction);
            }

            return content;
          }),
          catchError(() => {
            return of(null);
          })
        );
      }),
      // finally persist any raw content files
      switchMap((content: T) => {
        const contentFiles =
          (contentFilesMap.length &&
            contentFilesMap.filter((x) => !x.isImage).map((x) => x.contentFile)) ||
          [];

        const thumbnailFiles =
          (contentFilesMap.length &&
            contentFilesMap.filter((x) => x.isImage).map((x) => x.contentFile as ThumbnailFile)) ||
          [];

        const data: [T, ContentFile[], ContentItem, R[]] = [
          content,
          contentFilesMap.map((x) => x.contentFile),
          contentItem,
          referencedData,
        ];
        if (contentFilesMap.length && supportedStorageFileTypes) {
          // add any files/thumbnails to the redux store
          if (contentFiles.length) {
            this.store$.dispatch(new UpsertContentFiles(contentFiles));
          }
          if (thumbnailFiles.length) {
            this.store$.dispatch(new UpsertThumbnailFiles(thumbnailFiles));
          }

          // add binaries to persistent storage
          return this.fileStorageService
            .addStorageFiles(contentFilesMap, dataType, supportedStorageFileTypes)
            .pipe(
              switchMap((filesAdded: boolean) => {
                // handle removal of old versions of storage files
                if (filesAdded && storageFileRemovalRequest?.length) {
                  const removeStorageFiles$: Observable<boolean>[] = [];

                  storageFileRemovalRequest.forEach((request: StorageFileRemovalRequest) => {
                    if (request.storageFileType === StorageFileType.DbFile) {
                      removeStorageFiles$.push(this.fileStorageService.deleteDBFiles(request.keys));
                    }

                    if (request.storageFileType === StorageFileType.PartFile) {
                      removeStorageFiles$.push(
                        this.fileStorageService.deletePartFiles(request.keys)
                      );
                    }

                    if (request.storageFileType === StorageFileType.ImageFile) {
                      removeStorageFiles$.push(
                        this.fileStorageService.deleteImageFiles(request.keys)
                      );
                    }
                  });

                  return combineLatest(removeStorageFiles$);
                }
                return of([]);
              }),
              map(() => data)
            );
        } else {
          return of(data);
        }
      }),
      catchError((err) => {
        operationSuccess = false;
        return of(this.handleError(err, dataType, operationInfo.correlationId));
      }),
      tap(() => {
        this.recordAnalyticsOperationInfo(operationInfo, operationSuccess);
      })
    );
  }

  getContentItem(
    config: Config,
    externalId: string,
    filesAreReferenced: boolean,
    isExternalId = true
  ): Observable<ForgeContentDataElement> {
    // need to retry the HTTP call as well - so this needs to be inside the pipe
    return of(0).pipe(
      switchMap(() =>
        defer(() =>
          this.client.getContentItem({
            collectionId: config.collectionId,
            libraryId: config.id,
            contentItemId: externalId,
            isExternalId,
            customQueryParams: {
              'ngsw-bypass': 'true',
            },
          })
        )
      ),
      map((response) => this.deserializeSingleContentResponse(response.data, filesAreReferenced))
    );
  }

  updateContent<T extends ForgeContentDataElement>(
    config: Config,
    dataElement: T,
    dataType: DataElementType,
    files: ContentFile[],
    thumbnails: ThumbnailFile[],
    filesAreReferenced: boolean,
    parentNodeExternalIdOverride?: string,
    addUpgradeAttribute = false,
    updateThumbnails?: boolean
  ): Observable<ApiError | [T, ContentItem]> {
    // operation info for analytics
    const operationInfo = this.getStartAnalyticsOperationInfo(dataType, 'update');
    let operationSuccess = true;
    let parentNodeId = '';
    const getNodeId$ = parentNodeExternalIdOverride
      ? observableFrom(
          this.client.getNode({
            collectionId: config.collectionId,
            libraryId: config.id,
            nodeId: parentNodeExternalIdOverride,
            isExternalId: true,
          })
        ).pipe(
          map((getNodeResponse) => {
            parentNodeId = getNodeResponse.data.id;
            return parentNodeId;
          })
        )
      : of('');

    return getNodeId$.pipe(
      switchMap(() =>
        this.uploadThumbnailService.updateThumbnail(updateThumbnails, config, dataElement, files)
      ),
      switchMap((thumbnail) => {
        if (thumbnail) {
          const message = this.translateService.instant(
            LC.NOTIFICATIONS.THUMBNAIL_EDITOR.UPLOADED,
            {
              thumbnailName: thumbnail.name,
            }
          );
          this.notificationService.showToast({ message, type: NotificationType.Success });
          thumbnails = [thumbnail];
        }
        if (parentNodeId) {
          dataElement.parentId = parentNodeId;
        }

        return this.client.updateContentItem(
          this.createUpdateContentRequest(
            dataElement,
            config.collectionId,
            config.id,
            files,
            thumbnails,
            addUpgradeAttribute
          )
        );
      }),
      switchMap((updateResponse: HttpResponse<Response>) =>
        this.getCommandAndRetry(updateResponse.data.commandId)
      ),
      switchMap(() => {
        return this.getContentItemAndRetry(
          config.collectionId,
          config.id,
          dataElement.id,
          dataElement.lastModifiedTime
        );
      }),
      switchMap((contentItem: ContentItem) => {
        const result: [T, ContentItem] = [
          this.deserializeSingleContentResponse<T>(contentItem, filesAreReferenced),
          contentItem,
        ];

        if (!updateThumbnails) return of(result);

        const contentFilesMap = this.getContentElementFileContent([contentItem], config.externalId);
        const thumbnailFiles =
          (contentFilesMap.length &&
            contentFilesMap.filter((x) => x.isImage).map((x) => x.contentFile as ThumbnailFile)) ||
          [];

        if (thumbnailFiles.length) {
          this.store$.dispatch(new UpsertThumbnailFiles(thumbnailFiles));
          return this.fileStorageService
            .addStorageFiles(contentFilesMap, dataType, [StorageFileType.ImageFile])
            .pipe(map(() => result));
        }
      }),
      catchError((err) => {
        operationSuccess = false;
        return of(this.handleError(err, dataType, operationInfo.correlationId));
      }),
      tap(() => {
        this.recordAnalyticsOperationInfo(operationInfo, operationSuccess);
      })
    );
  }

  addContent<T extends ForgeContentDataElement>(
    dataElement: T,
    config: Config,
    dataType: DataElementType,
    nodeExternalId: string,
    schema: ForgeSchemaInfo,
    files: ContentFile[],
    thumbnails: ThumbnailFile[],
    filesAreReferenced: boolean,
    externalId?: string,
    checkSearch = false
  ): Observable<ApiError | [T, ContentItem]> {
    // operation info for analytics
    const operationInfo = this.getStartAnalyticsOperationInfo(dataType, 'add');
    let operationSuccess = true;
    if (!externalId) externalId = uuidv4();
    if (!schema) {
      schema = {
        namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
        version: EnvironmentConstants.FSS_SCHEMA_ASSET_VERSION,
        type: EnvironmentConstants.FSS_SCHEMA_ASSET,
      };
    }
    let finalThumbnails = [...(thumbnails || [])];

    // use the parent node ID if we already have it
    const getNodeId = dataElement.parentId
      ? of(dataElement.parentId)
      : defer(() =>
          // gets the fcs nodeId from the externalId
          this.client.getNode({
            collectionId: config.collectionId,
            libraryId: config.id,
            nodeId: nodeExternalId,
            isExternalId: true,
          })
        ).pipe(map((nodeResponse: HttpResponse<Node>) => nodeResponse.data.id));

    return getNodeId.pipe(
      switchMap((nodeId) =>
        this.uploadThumbnailService.updateThumbnail(true, config, dataElement, files).pipe(
          tap((thumbnail) => {
            if (thumbnail) finalThumbnails = [thumbnail];
          }),
          map(() => nodeId)
        )
      ),
      switchMap((nodeId: string) => {
        const request: CreateContentItemRequest = {
          collectionId: config.collectionId,
          libraryId: config.id,
          data: {
            type: `${schema.namespace}:${schema.type}-${schema.version}`,
            attributes: [...ForgeContentService.getExternalIdAttribute(externalId)],
            components: {
              info: {
                name: dataElement.name,
              },
              parent: { id: nodeId },
              tag: {
                values: startCase(dataType).toLowerCase().split(' '),
              },
              fabricationData: ForgeContentService.removeTopLevelProperties(dataElement),
            },
          },
        };

        // description cannot be an empty string
        if (!request.data.components.info.description?.length)
          request.data.components.info.description = undefined;

        if (files?.length) {
          request.data.components.files = files?.map((x) => ({
            name: x.name,
            size: x.size,
            description: x.description,
            objectKey: x.objectKey,
            mimeType: x.mimeType,
            checksum: x.checksum,
          }));
        }
        if (finalThumbnails?.length) {
          request.data.components.thumbnails = finalThumbnails?.map((x) => ({
            size: x.size,
            description: x.description,
            objectKey: x.objectKey,
            mimeType: x.mimeType,
            checksum: x.checksum,
            height: x.height,
            width: x.width,
            name: x.name,
          }));
        }
        return observableFrom(this.client.createContentItem(request));
      }),
      switchMap((createResponse: HttpResponse<Response>) =>
        this.getCommandAndRetry(createResponse.data.commandId)
      ),
      switchMap((commandResponse: CommandResponse) =>
        this.getContentItemAndRetry(config.collectionId, config.id, commandResponse.result.id, null)
      ),
      switchMap((contentItem: ContentItem) => {
        if (!checkSearch) return of(contentItem);

        return this.searchByIdAndRetry(contentItem.id, true).pipe(map(() => contentItem));
      }),
      map((contentItem: ContentItem) => {
        const filesMap = this.getContentElementFileContent([contentItem], config.externalId);
        const contentFiles =
          (filesMap.length && filesMap.filter((x) => !x.isImage).map((x) => x.contentFile)) || [];

        const thumbnailFiles =
          (filesMap.length &&
            filesMap.filter((x) => x.isImage).map((x) => x.contentFile as ThumbnailFile)) ||
          [];
        if (contentFiles.length) {
          this.store$.dispatch(new UpsertContentFiles(contentFiles));
        }
        if (thumbnailFiles.length) {
          this.store$.dispatch(new UpsertThumbnailFiles(thumbnailFiles));
        }

        const result: [T, ContentItem] = [
          this.deserializeSingleContentResponse(contentItem, filesAreReferenced),
          contentItem,
        ];

        return result;
      }),
      catchError((err) => {
        if (err?.response?.data?.type === 'CNT-002') {
          return observableFrom(
            this.client.getContentItem({
              collectionId: config.collectionId,
              libraryId: config.id,
              contentItemId: externalId,
              isExternalId: true,
            })
          ).pipe(
            map((getResponse: HttpResponse<ContentItem>) => {
              const result: [T, ContentItem] = [
                this.deserializeSingleContentResponse(getResponse.data, filesAreReferenced),
                getResponse.data,
              ];

              return result;
            })
          );
        }

        operationSuccess = false;
        return of(this.handleError(err, dataType, operationInfo.correlationId));
      }),
      tap(() => {
        this.recordAnalyticsOperationInfo(operationInfo, operationSuccess);
      })
    );
  }

  deleteContent<T extends ForgeContentDataElement>(
    config: Config,
    dataElements: T[],
    dataType: DataElementType
  ): Observable<DeleteResult[]> {
    const operationInfo = this.getStartAnalyticsOperationInfo(dataType, 'delete');
    let operationSuccess = true;
    const deleteRequests = dataElements.map((element) => {
      const referencedData = this.getReferencedData(config, element.externalId, dataType);

      return referencedData.pipe(
        switchMap((references: ReferencedData[]) => {
          if (references.length === 0) {
            return observableFrom(
              this.client.deleteContentItem({
                collectionId: config.collectionId,
                libraryId: config.id,
                contentItemId: element.id,
              })
            ).pipe(
              switchMap((response: HttpResponse<Response>) =>
                this.getCommandAndRetry(response.data.commandId)
              ),
              map((commandResponse: CommandResponse) => {
                return {
                  success: commandResponse.status === 'SUCCESS',
                };
              })
            );
          } else {
            return of({
              success: false,
              references,
            });
          }
        }),
        catchError((err) => {
          operationSuccess = false; // needs re-working when we support multiple delete operations
          return of({
            errors: this.handleError(err, dataType, operationInfo.correlationId),
            success: false,
          });
        }),
        tap(() => {
          this.recordAnalyticsOperationInfo(operationInfo, operationSuccess);
        })
      );
    });

    return RxjsUtils.concurrentForkJoin(deleteRequests);
  }

  getInterdependentReferences(
    externalId: string,
    referenceExternalId: string,
    pageSize: number,
    multiplePages: boolean
  ): Observable<ApiError | ForgeContentDataElement[]> {
    const matchFabricationReference = `fabricationData.fabricationReferences.externalId:"${referenceExternalId}" && fabricationData.fabricationReferences.externalId:"${externalId}"`;

    let numPages = 1;
    const contentItems: ContentItem[] = [];

    const searchCall = (offset: number): Observable<HttpResponse<SearchContentItemsResponse>> => {
      return defer(() =>
        this.client.searchContentItems({
          collectionId: this.currentConfig.collectionId,
          libraryId: this.currentConfig.id,
          query: matchFabricationReference,
          //filter: { 'type': schemaType }, // todo - filter by type when this is supported in FC2.0
          limit: pageSize,
          offset,
        })
      );
    };

    return searchCall(0).pipe(
      map((response: HttpResponse<SearchContentItemsResponse>) => {
        numPages = this.getNumPages(
          response.data.pagination.totalResults,
          response.data.pagination.limit
        );
        if (!response.data.results.length) {
          return [];
        }

        // collect any of the content items that we don't currently have
        response.data.results?.forEach((x) => contentItems.push(x));
      }),
      switchMap(() => {
        if (numPages <= 1 || !multiplePages) {
          return of(contentItems);
        }

        const observables: Observable<HttpResponse<SearchContentItemsResponse>>[] = [];
        for (let i = 1; i < numPages; i++) {
          const offset = pageSize * i;
          observables.push(searchCall(offset));
        }

        return RxjsUtils.concurrentForkJoin(observables, this.concurentHttpCalls).pipe(
          map((responses: HttpResponse<SearchContentItemsResponse>[]) => {
            responses.forEach((response) => {
              response.data.results?.forEach((x) => contentItems.push(x));
            });
          })
        );
      }),
      map(() => this.deserializeContentItemListResponse(contentItems)),
      catchError((err) => {
        return of(this.handleError(err, null, ''));
      })
    );
  }

  getPartContentByReference(
    referenceExternalId: string,
    config: Config,
    excludeExternalIds: string[]
  ): Observable<ApiError | [Part[], ContentFile[]]> {
    const pageSize = 50; // call should only return parts so 50 is a reasonable value
    const setup = this.dynamicDataService.getDynamicDataSetupForType(DataElementType.Part);
    const matchFabricationReference = `fabricationData.fabricationReferences.externalId:"${referenceExternalId}"`;

    // don't specify the version
    const schemaType = `${EnvironmentConstants.FSS_SCHEMA_NAMESPACE}:${EnvironmentConstants.FSS_SCHEMA_PART}`; //-${EnvironmentConstants.FSS_SCHEMA_PART_VERSION}`;

    let contentFilesMap: ContentFileMap[] = [];
    let numPages = 1;
    const contentItems: ContentItem[] = [];

    const searchCall = (offset: number): Observable<HttpResponse<SearchContentItemsResponse>> => {
      return defer(() =>
        this.client.searchContentItems({
          collectionId: config.collectionId,
          libraryId: config.id,
          query: matchFabricationReference,
          //filter: { 'type': schemaType }, // todo - filter by type when this is supported in FC2.0
          limit: pageSize,
          offset,
        })
      );
    };

    return searchCall(0).pipe(
      map((response: HttpResponse<SearchContentItemsResponse>) => {
        numPages = this.getNumPages(
          response.data.pagination.totalResults,
          response.data.pagination.limit
        );
        if (!response.data.results.length) {
          return [];
        }

        // collect any of the content items that we don't currently have
        response.data.results
          .filter(
            (contentItem: ContentItem) =>
              // todo - filter on the search call when it is supported
              contentItem.type.includes(schemaType) &&
              !excludeExternalIds.includes(
                ForgeContentService.getExternalIdValue(contentItem.attributes)
              )
          )
          ?.forEach((x) => contentItems.push(x));
      }),
      switchMap(() => {
        if (numPages <= 1) {
          return of(contentItems);
        }

        const observables: Observable<HttpResponse<SearchContentItemsResponse>>[] = [];
        for (let i = 1; i < numPages; i++) {
          const offset = pageSize * i;
          observables.push(searchCall(offset));
        }

        return RxjsUtils.concurrentForkJoin(observables, this.concurentHttpCalls).pipe(
          map((responses: HttpResponse<SearchContentItemsResponse>[]) => {
            responses.forEach((response) => {
              response.data.results
                .filter(
                  (contentItem: ContentItem) =>
                    // todo - filter on the search call when it is supported
                    contentItem.type.includes(schemaType) &&
                    !excludeExternalIds.includes(
                      ForgeContentService.getExternalIdValue(contentItem.attributes)
                    )
                )
                ?.forEach((x) => contentItems.push(x));
            });
          })
        );
      }),
      switchMap(() => {
        const parts = this.deserializeContentItemListResponse<Part>(contentItems);
        // get content file map
        contentFilesMap = this.getContentElementFileContent(contentItems, config.externalId);

        return this.fileStorageService
          .addStorageFiles(
            contentFilesMap,
            DataElementType.Part,
            setup.options.bulkLoadFileTypesSupported
          )
          .pipe(
            switchMap(() =>
              of([parts, contentFilesMap.map((x) => x.contentFile)] as [Part[], ContentFile[]])
            )
          );
      }),
      catchError((err) => {
        return of(this.handleError(err, null, ''));
      })
    );
  }

  getPartContentByExternalId<T extends ForgeContentDataElement>(
    externalIds: string[],
    config: Config
  ): Observable<ApiError | [T[], ContentFile[]]> {
    const setup = this.dynamicDataService.getDynamicDataSetupForType(DataElementType.Part);
    const contentFilesMap: ContentFileMap[] = [];
    const content: T[] = [];
    const contentFiles: ContentFile[] = [];

    const batches$ = this.batchGetContentItems(config.collectionId, config.id, externalIds).pipe(
      map((contentItems: ContentItem[]) => {
        contentItems.forEach((x) => {
          const files = this.getContentElementFileContent([x], config.externalId);
          contentFilesMap.push(...files);
          contentFiles.push(...files.map((x) => x.contentFile));
          content.push(this.getContentElement(x));
        });
      }),
      catchError((err) => {
        return of(this.handleError(err, null, ''));
      })
    );

    return batches$.pipe(
      switchMap(() => {
        return this.fileStorageService
          .addStorageFiles(
            contentFilesMap,
            DataElementType.Part,
            setup.options.bulkLoadFileTypesSupported
          )
          .pipe(map(() => [content, contentFiles] as [T[], ContentFile[]]));
      })
    );
  }

  private batchGetContentItems(
    collectionId: string,
    libraryId: string,
    externalIds: string[]
  ): Observable<ContentItem[]> {
    const sliceIntoChunks = (arr: string[], chunkSize: number) => {
      const chunks = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        chunks.push(chunk);
      }
      return chunks;
    };

    const batches$ = sliceIntoChunks(externalIds, 20).map((batch: string[]) =>
      defer(() =>
        this.client.batchGetContentItems({
          collectionId,
          libraryId,
          ids: batch,
          isExternalId: true,
        })
      ).pipe(map((response: HttpResponse<AssetList>) => response.data.results as ContentItem[]))
    );

    return RxjsUtils.concurrentForkJoin(batches$).pipe(
      // flatten the array
      map((data: ContentItem[][]) => [].concat(...data))
    );
  }

  /**
   * Poll the command API for the success status.
   * @param {string} commandId The command ID.
   * @returns
   */
  private getCommandAndRetry(commandId: string): Observable<CommandResponse> {
    // need to retry the HTTP call as well - so this needs to be inside the pipe
    return of(0).pipe(
      switchMap(() =>
        this.client.getCommand({
          collectionId: this.currentConfig.collectionId,
          commandId,
        })
      ),
      map((response: HttpResponse<CommandResponse>) => {
        const status = response.data?.status;
        if (status === 'SUCCESS') {
          return response.data;
        }

        // todo - what if we get a failure, rather than a processing status? we don't want to keep polling in that case
        throw new Error(getCommandErrorMessage);
      }),
      retry({
        count: 5,
        delay: (err: Error) => {
          // we only want to retry if we threw the error
          if (err.message === getCommandErrorMessage) return timer(1000);

          // otherwise throw the original error and don't retry
          throw err;
        },
      })
    );
  }

  /**
   * Get the content item, but retry if this fails or returns stale data. Bypasses the cache.
   * @param {string} collectionId
   * @param {string} libraryId
   * @param {string} contentItemId
   * @param {boolean} isExternalId
   * @param {string} lastModifiedTime
   * @returns
   */
  private getContentItemAndRetry(
    collectionId: string,
    libraryId: string,
    contentItemId: string,
    lastModifiedTime: string
  ): Observable<ContentItem> {
    // need to retry the HTTP call as well - so this needs to be inside the pipe
    return of(0).pipe(
      switchMap(() =>
        defer(() =>
          this.client.getContentItem({
            collectionId,
            libraryId,
            contentItemId,
            customQueryParams: {
              'ngsw-bypass': 'true',
            },
          })
        )
      ),
      map((response: HttpResponse<ContentItem>) => {
        // ensure the last modified time has been updated, otherwise this is stale data
        if (response.data.modifiedBy.date !== lastModifiedTime) {
          return response.data;
        }

        throw new Error(staleContentErrorMessage);
      }),
      retry({
        count: 5,
        delay: (err: Error) => {
          // we only want to retry if we threw the error
          if (err.message === staleContentErrorMessage) return timer(1000);

          // otherwise throw the original error and don't retry
          throw err;
        },
      })
    );
  }

  private searchByIdAndRetry(contentItemId: string, shouldExist: boolean): Observable<boolean> {
    const notFound = 'content item not found';
    const found = 'content item found';
    return of(0).pipe(
      switchMap(() =>
        this.client.searchContentItems({
          collectionId: this.currentConfig.collectionId,
          libraryId: this.currentConfig.id,
          filter: `id:"${contentItemId}"`,
          customQueryParams: {
            'ngsw-bypass': 'true',
          },
        })
      ),
      map((response: HttpResponse<SearchContentItemsResponse>) => {
        const contentItem = response.data?.results?.find((x) => x.id === contentItemId);
        if (shouldExist && contentItem) return;
        if (!shouldExist && !contentItem) return;

        throw new Error(shouldExist ? notFound : found);
      }),
      retry({
        count: 10,
        delay: () => timer(1000),
      }),
      map(() => true),
      catchError(() => of(false))
    );
  }

  // serialization
  public deserializeContentItemListResponse<T extends ForgeContentDataElement>(
    data: ContentItem[],
    filesAreReferenced?: boolean
  ): T[] {
    return data.map<T>((x) => this.getContentElement(x, filesAreReferenced));
  }

  private deserializeSingleContentResponse<T extends ForgeContentDataElement>(
    contentItem: ContentItem,
    filesAreReferenced: boolean
  ): T {
    return this.getContentElement(contentItem, filesAreReferenced);
  }

  private getContentElement<T extends ForgeContentDataElement>(
    data: ContentItem,
    filesAreReferenced?: boolean
  ): T {
    // flatten the return object from the nested api response data
    let isInvalidData = !!data.components.invalidData;
    //isInvalidData = true;
    let isUpgrading = this.getIsUpgradingValue(data.attributes);
    const schema = DataElementTypeUtils.getForgeContentSchema(data.type);

    // convert all floating point numbers to fixed decimal places
    data.components.fabricationData = cloneDeepWith(data.components.fabricationData, (value) => {
      if (!Number.isFinite(value) || Number.isInteger(value)) return undefined;
      return parseFloat(Number(value).toFixed(EnvironmentConstants.MAX_DECIMAL_PLACES_FOR_EDITING));
    });

    const dataElement: ForgeContentDataElement = {
      id: data.id,
      parentId: data.components.parent.id,
      name: data.components.info.name,
      description: data.components.info.description,
      tags: data.components.tag?.values || [],
      externalId: ForgeContentService.getExternalIdValue(data.attributes),
      etag: ForgeContentService.getEtagValue(data.attributes),
      extensionDataType: isInvalidData ? EnvironmentConstants.FSS_SCHEMA_INVALID_DATA : schema.type,
      extensionDataVersion: schema.version,
      extensionDataNamespace: schema.namespace,
      fabricationReferences: data.components.fabricationData?.['fabricationReferences'] ?? [],
      schemaType: isInvalidData ? data.type : undefined,
      lastModifiedTime: data.modifiedBy?.date ?? data.createdBy?.date,
      ...data.components.fabricationData,
      ...data.components.invalidData,
      // files, only store ids
      files: filesAreReferenced
        ? this.getReferenceContentFileIds(data)
        : (data.components.files &&
            data.components.files.map(() =>
              ForgeContentService.getExternalIdValue(data.attributes)
            )) ||
          [], // we should only ever have at most 1 file and 1 thumbnail on a content item
      thumbnails: filesAreReferenced
        ? this.getReferenceContentFileIds(data)
        : (data.components.thumbnails &&
            data.components.thumbnails.map(() =>
              ForgeContentService.getExternalIdValue(data.attributes)
            )) ||
          [],
      isTemporaryData: this.getIsTemporaryDataValue(data.attributes),
      thumbnailUrl: data.components.thumbnails?.length
        ? data.components.thumbnails[0].fileUrl
        : null,
      isUpgrading,
      isInvalid: isInvalidData,
    };

    // temporarily (until the data is upgraded) set the unassigned externals appropriately
    // fix any externalIds which are set to "-1"
    dataElement.fabricationReferences?.forEach((x) => {
      if (x.externalId === '-1') {
        switch (x.dataType) {
          default:
            throw new Error('data type not supported');

          case DataElementType.Connector:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;
            break;

          case DataElementType.Damper:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_DAMPER;
            break;

          case DataElementType.InsulationSpecification:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_INSULATION_SPEC;
            break;

          case DataElementType.Material:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL;
            break;

          case DataElementType.MaterialSpecification:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
            break;

          case DataElementType.Specification:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_PART_SPEC;
            break;

          case DataElementType.Stiffener:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_STIFFENER;
            break;

          case DataElementType.StiffenerSpecification:
            x.externalId = EnvironmentConstants.FCS_UNASSIGNED_STIFFENER_SPEC;
            break;
        }
      }
    });

    // make sure the fabricationReferences array is ordered by externalId so that getting the object diffs
    // when editing data works as expected
    if (!dataElement.fabricationReferences) {
      dataElement.fabricationReferences = [];
    }
    dataElement.fabricationReferences.sort((a: FabricationReference, b: FabricationReference) => {
      const dataTypeCompare = a.dataType.localeCompare(b.dataType);
      if (dataTypeCompare != 0) {
        return dataTypeCompare;
      }

      if ('index' in a && 'index' in b) {
        const indexableA = a as unknown as FabricationIndexableReference;
        const indexableB = b as unknown as FabricationIndexableReference;
        return indexableA.index > indexableB.index ? 1 : 0;
      }

      return a.externalId.localeCompare(b.externalId);
    });

    return dataElement as T;
  }

  public getContentElementFileContent(data: ContentItem[], configId: string): ContentFileMap[] {
    let fileMaps: ContentFileMap[] = [];

    data.forEach((contentItem: ContentItem) => {
      // get files
      if (contentItem?.components?.files?.length) {
        const fileMap = contentItem.components.files.map((file) => {
          const { fileUrl, ...contentFile } = file;
          const contentFileMap: ContentFileMap = {
            contentFile: {
              name: contentFile.name,
              description: contentFile.description,
              size: contentFile.size,
              objectKey: contentFile.objectKey,
              mimeType: contentFile.mimeType,
              checksum: contentFile.checksum,
              contentExternalId: ForgeContentService.getExternalIdValue(contentItem.attributes),
              path: contentItem.components.fabricationData?.['path'],
            },
            fileUrl,
            isImage: false,
            configId,
          };

          return contentFileMap;
        });

        fileMaps = [...fileMaps, ...fileMap];
      }

      // get thumbnails
      if (contentItem?.components?.thumbnails?.length) {
        const thumbnailMap = contentItem.components.thumbnails.map((thumbnail) => {
          const { fileUrl, ...thumbnailFile } = thumbnail;
          const contentFileMap: ContentFileMap = {
            contentFile: {
              name: thumbnailFile.name,
              description: thumbnail.description,
              size: thumbnailFile.size,
              objectKey: thumbnailFile.objectKey,
              mimeType: thumbnailFile.mimeType,
              checksum: thumbnailFile.checksum,
              height: thumbnailFile.height,
              width: thumbnail.width,
              contentExternalId: ForgeContentService.getExternalIdValue(contentItem.attributes),
              path: contentItem.components.fabricationData?.['imagePath'],
            },
            fileUrl,
            isImage: true,
            configId,
          };

          return contentFileMap;
        });

        fileMaps = [...fileMaps, ...thumbnailMap];
      }
    });

    return fileMaps;
  }

  /**
   * Searches through all data for data types that reference the specified entity by its external id.
   * It returns raw data from forge content api
   * @param {string} libraryId
   * @param {string} externalId
   * @returns Observable<SearchResponse>
   */
  private getReferencedRawFullData(
    config: Config,
    externalId: string,
    dataType: DataElementType
  ): Observable<SearchContentItemsResponse> {
    let query = `fabricationData.fabricationReferences.externalId:${externalId}`;
    if (dataType === DataElementType.Material) {
      query += ` OR fabricationData.fabricationReferences.connectivityId:${externalId}`;
    }

    return observableFrom(
      this.client.searchContentItems({
        collectionId: config.collectionId,
        libraryId: config.id,
        query,
      })
    ).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  /**
   * Searches through all data for data types that reference the specified entity by its external id.
   */
  private getReferencedData(
    config: Config,
    externalId: string,
    dataType: DataElementType
  ): Observable<ReferencedData[]> {
    return this.getReferencedRawFullData(config, externalId, dataType).pipe(
      map((response: SearchContentItemsResponse) => {
        return this.deserializeSearchResponse(response);
      })
    );
  }

  private deserializeSearchResponse(response: SearchContentItemsResponse): ReferencedData[] {
    if (!response.results?.length) {
      return [];
    }

    return response.results.map((contentItem: ContentItem) => {
      return {
        externalId: ForgeContentService.getExternalIdValue(contentItem.attributes),
        dataType: DataElementTypeUtils.getDataTypeFromSchema(contentItem.type),
        name: contentItem.components.info.name,
      };
    });
  }

  private getReferenceContentFileIds(data: ContentItem): string[] {
    const fabricationReferences = data.components.fabricationData?.['fabricationReferences'];
    if (!fabricationReferences?.length) {
      return [];
    }

    const ids = fabricationReferences
      .map((x) => x.externalId)
      .filter((x) => !EnvironmentConstants.FCS_UNASSIGNED_COLLECTION.includes(x));

    return Array.from(new Set<string>(ids));
  }

  private getNodeElement(node: Node, nodeContentType: DataElementType): ContentNode {
    // flatten the return object from the nested api response data

    const getNodePath = (name: string) => (name.includes('\\') && name) || '';

    const contentsCount = ForgeContentService.getContentsCountValue(node.attributes);
    const nodesCount = ForgeContentService.getNodesCountValue(node.attributes);
    const nodeElement: ContentNode = {
      id: node.id,
      name: this.getNameFromPathEntry(node.components.info.name),
      description: node.components.info.description,
      tags: node.components.tag?.values || [],
      path: getNodePath(node.components.info.name),
      displayName: node.components.fabricationData?.['displayName'],
      externalId: ForgeContentService.getExternalIdValue(node.attributes),
      content: [],
      nodes: [],
      hasContent: !!contentsCount,
      hasNodes: !!nodesCount,
      contentCount: contentsCount,
      contentType: nodeContentType,
      parentId: node.components.parent?.id,
    };

    return nodeElement;
  }

  private getNameFromPathEntry(entry: string): string {
    const isPathTypeName = entry.includes('\\');
    if (!isPathTypeName) {
      return entry;
    } else {
      const splitPath = entry.split('\\').filter((x) => !!x);
      return splitPath[splitPath.length - 1];
    }
  }

  private static getAttributeValue(attributes: Attribute[], name: string): string {
    if (!attributes?.length) {
      return null;
    }

    return attributes.find((x) => x.name === name)?.value;
  }

  private getIsTemporaryDataValue(attributes: Attribute[]): boolean {
    const value = ForgeContentService.getAttributeValue(attributes, 'isTemporaryData');
    if (!value) {
      return false;
    }

    return value.toLowerCase() === 'true';
  }

  private getIsUpgradingValue(attributes: Attribute[]): boolean {
    const value = ForgeContentService.getAttributeValue(attributes, 'isUpgrading');
    if (!value) {
      return false;
    }

    return value.toLowerCase() === 'true';
  }

  private createIsUpgradingAttribute(): Attribute {
    return {
      category: 'application',
      type: 'Bool',
      name: 'isUpgrading',
      value: 'true',
    };
  }

  public static getExternalIdValue(attributes: Attribute[]): string {
    return ForgeContentService.getAttributeValue(attributes, 'externalId');
  }

  public static getEtagValue(attributes: Attribute[]): string {
    return ForgeContentService.getAttributeValue(attributes, 'etag');
  }

  public static getExternalIdAttribute(externalId: string): Attribute[] {
    if (!externalId) return [];
    return [
      {
        name: 'externalId',
        value: externalId,
        category: 'application',
        type: 'String',
      },
    ];
  }

  private static getNodesCountValue(attributes: Attribute[]): number {
    const value = ForgeContentService.getAttributeValue(attributes, 'nodesCount');
    if (!value) {
      return 0;
    }

    return Number(value);
  }

  static setContentsCountValue(attributes: Attribute[], value: number): void {
    if (!attributes?.length) {
      return;
    }

    const attribute = attributes.find((x) => x.name === 'contentsCount');
    if (attribute) {
      attribute.value = value.toString();
    }
  }

  static getContentsCountValue(attributes: Attribute[]): number {
    const value = ForgeContentService.getAttributeValue(attributes, 'contentsCount');
    if (!value) {
      return 0;
    }

    return Number(value);
  }

  private getNumPages(totalResults: number, limit: number) {
    if (totalResults <= 0) {
      return 0;
    }

    return Math.ceil(totalResults / limit);
  }

  // analytics
  private getStartAnalyticsOperationInfo(
    dataElementType: DataElementType | string,
    operationType: OperationType
  ): DataOperationInfo {
    return new DataOperationInfo(dataElementType, operationType);
  }

  // error handling
  private handleError(
    err: any,
    dataType: DataElementType | string,
    correlationId: string
  ): ApiError {
    const dataLoadingError: ApiError = {
      correlationId,
      status: err?.status,
      statusText: err?.statusText,
      errors: err?.errors ?? [],
      failedAt: new Date().toISOString(),
      dataType,
      apiName: 'forge-content-2.0',
    };

    this.apiErrorSubject.next(dataLoadingError);
    this.loggingService.logError(
      dataLoadingError,
      err?.status !== simultaneousUpdateErrorStatus,
      err?.statusText,
      ErrorUtils.getAdditionalStack()
    );

    return dataLoadingError;
  }

  private recordAnalyticsOperationInfo(
    operationInfo: DataOperationInfo,
    isSuccess: boolean,
    paralellCalls?: number
  ) {
    operationInfo.endTime = Date.now();
    operationInfo.isSuccess = isSuccess;
    operationInfo.paralellCalls = paralellCalls || null;

    const type = `${operationInfo.operationType}-${operationInfo.dataType.toLowerCase()}${
      operationInfo.operationType === 'load' ? 's' : ''
    }`;
    const stage = (isSuccess && 'complete') || 'failed';
    const status =
      (operationInfo.isSuccess && AnalyticsOperationStatus.Complete) ||
      AnalyticsOperationStatus.Error;
    const meta = {};

    if (isSuccess && paralellCalls) {
      meta['paralell-calls'] = paralellCalls.toString();
    }

    const trackInfo: TrackInfo<EventType> = {
      id: operationInfo.correlationId,
      status,
      type,
      meta,
      stage,
    };

    this.analyticsService.recordExternalOperationEvent(trackInfo);
  }

  private createUpdateContentRequest<T extends ForgeContentDataElement>(
    data: T,
    collectionId: string,
    libraryId: string,
    files: ContentFile[] = null,
    thumbnails: ThumbnailFile[] = null,
    addUpgradeAttribute = false
  ): UpdateContentItemRequest {
    const ifMatch =
      data.etag && data.extensionDataType !== EnvironmentConstants.FSS_SCHEMA_PART
        ? data.etag
        : null;

    const request: UpdateContentItemRequest = {
      ifMatch,
      collectionId,
      libraryId,
      contentItemId: data.id,
      data: {
        type: `${data.extensionDataNamespace}:${data.extensionDataType}-${data.extensionDataVersion}`,
        components: {
          info: {
            name: data.name,
            description: data.description,
          },
          parent: { id: data.parentId },
          tag: { values: data.tags },
          //status: { value: 'draft' },
          fabricationData: ForgeContentService.removeTopLevelProperties(data),
        },
        attributes: [...ForgeContentService.getExternalIdAttribute(data.externalId)],
      },
    };

    // description cannot be an empty string
    if (!request.data.components.info.description?.length)
      request.data.components.info.description = undefined;

    if (files?.length) {
      // change the name + description of the content file to match the new path - assumes max 1 file per content item
      const path = this.getFileNameFromPath((data as any).path as string);
      request.data.components.files = files?.map((x) => ({
        name: path ? path : x.name,
        size: x.size,
        description: path ? path : x.name,
        objectKey: x.objectKey,
        mimeType: x.mimeType,
        checksum: x.checksum,
      }));
    }
    if (thumbnails?.length) {
      // we only change the path of the ITM file when renaming a part. the image keeps its original path.
      request.data.components.thumbnails = thumbnails?.map((x) => ({
        name: x.name,
        size: x.size,
        description: x.description,
        objectKey: x.objectKey,
        mimeType: x.mimeType,
        checksum: x.checksum,
        height: x.height,
        width: x.width,
      }));
    }

    // keep the temporary data flag assigned if it exists
    if (data.isTemporaryData) {
      request.data.attributes.push({
        type: 'Bool',
        value: 'true',
        category: 'application',
        name: 'isTemporaryData',
      });
    }

    // keep the upgrading flag assigned if it exists
    if (data.isUpgrading || addUpgradeAttribute) {
      request.data.attributes.push(this.createIsUpgradingAttribute());
    }

    return request;
  }

  public static removeTopLevelProperties<T extends ForgeContentDataElement>(
    data: T,
    ignoreKeys?: string[]
  ): T {
    const base = new ForgeContentDataElement();
    const copy = { ...data };
    const ignoreKeysSet = new Set(ignoreKeys || []);
    // remove base properties
    Object.keys(base).forEach((key) => ignoreKeysSet.has(key) || delete copy[key]);
    return copy;
  }

  private getFileNameFromPath(path: string): string {
    if (!path) {
      return null;
    }

    const index1 = path.lastIndexOf('/');
    const index2 = path.lastIndexOf('\\');
    if (index1 === index2) {
      // no forward or backward slashes
      return path;
    }

    const index = Math.max(index1, index2);
    return path.substring(index + 1);
  }
}
/* eslint-enable */
