import { DataElementType } from '@constants/data-element-types';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Action } from '@ngrx/store';
import { DataLoadingAction, DataLoadingState } from './base/data-loading.action';

export enum ExtraDataElementInfoActionTypes {
  LoadInterdependentReferences = '[Extra Data Element Info API] Load Interdependent References',
  LoadInterdependentReferencesFail = '[Extra Data Element Info API] Load Interdependent References Fail',
  LoadInterdependentReferencesSuccess = '[Extra Data Element Info API] Load Interdependent References Success',
  DeleteInterdependentReferences = '[Extra Data Element Info API] Delete Interdependent References',
}

export class LoadInterdependentReferences implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Loading;

  successAction: LoadInterdependentReferencesSuccess;
  failAction: LoadInterdependentReferencesFail;

  readonly type = ExtraDataElementInfoActionTypes.LoadInterdependentReferences;

  constructor(
    public payload: {
      configId: string;
      dataElement: ForgeContentDataElement;
      dataElementType: DataElementType;
      referencedDataTypes: DataElementType[];
      isInvalidData: boolean;
    }
  ) {}
}

export class LoadInterdependentReferencesSuccess implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Complete;
  readonly type = ExtraDataElementInfoActionTypes.LoadInterdependentReferencesSuccess;

  constructor(
    public payload: {
      id: string;
      interdependentReferenceIds: string[];
    }
  ) {}
}

export class LoadInterdependentReferencesFail implements Action, DataLoadingAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ExtraDataElementInfoActionTypes.LoadInterdependentReferencesFail;

  constructor(public payload: { id: string }) {}
}

export class DeleteInterdependentReferences implements Action {
  readonly type = ExtraDataElementInfoActionTypes.DeleteInterdependentReferences;

  constructor(public payload: { id: string }) {}
}

export type ExtraDataElementInfoActions =
  | LoadInterdependentReferences
  | LoadInterdependentReferencesSuccess
  | LoadInterdependentReferencesFail
  | DeleteInterdependentReferences;
