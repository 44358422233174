import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ForgeContentService } from '@services/forge-content.service';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Action, Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { BinaryTaskAction } from '@services/binary-task-manager.service';
import { ApiError } from '@models/errors/api-error';
import { ContentFile, ThumbnailFile } from '@models/fabrication/files';
import { Config } from '@models/fabrication/config';
import {
  FinaliseUpgradeDataElementAction,
  StartUpgradeDataElementAction,
} from '@store/actions/base/data-element.action';

import { SetBinaryTaskData } from '@store/actions/application.action';
import { CacheUpdateType } from '@models/cache/cache';
import { TriggerCacheDataUpdate } from '@store/actions/cache-data.action';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';

import { ContentItem } from '@adsk/content-sdk';
import { DynamicDataService } from '@services/dynamic-data.service';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { PartActionTypes } from '@store/actions/part.action';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { ContentFileUtils } from '@utils/content-file-utils';
import { UpsertContentFiles } from '@store/actions/content-file.action';
import { UpsertThumbnailFiles } from '@store/actions/thumbnail-file.action';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DataElementType } from '@constants/data-element-types';
import { AddInvalidDataSuccess } from '@store/actions/invalid-data.action';
import { InvalidData } from '@models/fabrication/invalid-data';
import { UpdateConfigInvalidDataIds } from '@store/actions/configs.action';
import { ActivityActionType } from '@models/activities-events/activities';
import { DataUpgradeService } from '@services/data-upgrade.service';
import { Operation } from '@constants/operations-types';

@Injectable()
export class UpgradeDataElementEffects {
  startUpgradeDataElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartActionTypes.StartUpgradePart),
      tap((action: StartUpgradeDataElementAction<ForgeContentDataElement>) => {
        this.dataUpgradeService.submitDataUpgradeChange({
          dataType: action.dataElementType,
          data: action.dataElement,
          type: 'start',
        });
      }),
      mergeMap((action: StartUpgradeDataElementAction<ForgeContentDataElement>) => {
        return combineLatest([of(action), this.store$.select(selectCurrentConfig).pipe(take(1))]);
      }),
      mergeMap((setupData: [StartUpgradeDataElementAction<ForgeContentDataElement>, Config]) => {
        const [action, config] = setupData;
        const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
          action.dataElementType
        );
        return this.runStartUpgradeDataElementActions(
          action,
          config,
          dynamicDataSetup.options.filesAreReferenced
        );
      }),
      mergeMap((actions: Action[]) => actions)
    )
  );

  finaliseUpgradeDataElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartActionTypes.FinaliseUpgradePart),
      mergeMap((action: FinaliseUpgradeDataElementAction<ForgeContentDataElement>) =>
        this.runFinaliseUpgradeDataElementActions(action)
      ),
      filter((action) => !!action),
      mergeMap((dispatchActions) => dispatchActions)
    )
  );

  private runStartUpgradeDataElementActions = (
    action: StartUpgradeDataElementAction<ForgeContentDataElement>,
    config: Config,
    filesAreReferenced: boolean
  ): Observable<Action[]> => {
    const dispatchActions: Action[] = [];
    return this.dataElementEffectService.contentFilesRequired(action.dataElement).pipe(
      mergeMap((contentFiles: [ContentFile[], ThumbnailFile[]]) => {
        const [files, thumbnails] = contentFiles;
        return this.forgeContentService.updateContent(
          config,
          action.dataElement,
          action.dataElementType,
          filesAreReferenced ? null : files,
          filesAreReferenced ? null : thumbnails,
          filesAreReferenced,
          null,
          true
        );
      }),
      map((data: [any, ContentItem] | ApiError) => {
        if (!(data as ApiError).errors) {
          const [successData, response] = data as [any, ContentItem];
          action.successAction.dataElement = successData;
          dispatchActions.push(action.successAction);

          if (action.updateCache) {
            dispatchActions.push(
              // cache update
              new TriggerCacheDataUpdate({
                dataElementType: action.dataElementType,
                dataUrn: successData.id,
                cacheUpdateType: CacheUpdateType.Update,
                response,
                nodeId: action.nodeId,
                isExternalNodeId: action.isExternalNodeId,
              })
            );
          }

          dispatchActions.push(
            // web hook
            new SetBinaryTaskData({
              entityId: successData.id,
              entityType: 'content',
              action: BinaryTaskAction.Update,
              externalIds: [successData.externalId],
              schemaId: successData.extensionDataType,
              jobType: BinaryJobType.DataUpgrade,
            })
          );

          // don't send an activity for this part of the upgrade
        } else {
          const failureData = data as ApiError;
          dispatchActions.push(action.failAction);
          this.dataElementEffectService.postFailureNotification(
            Operation.UPGRADE,
            action.dataElementType,
            failureData
          );
        }

        return dispatchActions;
      })
    );
  };

  private runFinaliseUpgradeDataElementActions = (
    action: FinaliseUpgradeDataElementAction<ForgeContentDataElement>
  ): Observable<Action[]> => {
    const dispatchActions: Action[] = [];
    const dynamicDataSetup = this.dynamicDataService.getDynamicDataSetupForType(
      action.dataElementType
    );

    let dataElement: ForgeContentDataElement = null;
    return dynamicDataSetup.options.selectors.selectById(action.dataElementId).pipe(
      take(1),
      mergeMap((originalDataElement: ForgeContentDataElement) => {
        return this.forgeContentService
          .loadContentById(
            action.config,
            action.dataElementId,
            action.dataElementType,
            action.storageFileTypes ?? [],
            []
          )
          .pipe(
            take(1),
            mergeMap(
              (
                data:
                  | ApiError
                  | [ForgeContentDataElement, ContentFile[], ContentItem, ForgeContentDataElement[]]
              ) => {
                const [successData, contentFileData, contentItem] = data as [
                  ForgeContentDataElement,
                  ContentFile[],
                  ContentItem,
                  ForgeContentDataElement[]
                ];

                if (!('error' in data)) {
                  dataElement = successData;
                  action.successAction.dataElement = successData;

                  // add file content data if returned
                  if (contentFileData.length) {
                    const [fileData, thumbnailData] =
                      ContentFileUtils.separateFiles(contentFileData);
                    if (fileData.length) {
                      dispatchActions.push(new UpsertContentFiles(fileData));
                    }
                    if (thumbnailData.length) {
                      dispatchActions.push(new UpsertThumbnailFiles(thumbnailData));
                    }
                  }

                  if (successData.isInvalid) {
                    // make sure the current config has the new id and the data has been added to the invalid data store
                    const invalidAction = new AddInvalidDataSuccess();
                    invalidAction.dataElement = successData as InvalidData;
                    dispatchActions.push(
                      invalidAction,
                      new UpdateConfigInvalidDataIds(
                        {
                          id: action.config.externalId,
                          changes: [action.dataElementId],
                        },
                        false
                      )
                    );

                    // we might not have downloaded the data (if in another browser, so no need to update the cache)
                    if (originalDataElement) {
                      dispatchActions.push(
                        new TriggerCacheDataUpdate({
                          dataElementType: action.dataElementType,
                          dataUrn: successData.id,
                          cacheUpdateType: CacheUpdateType.Delete,
                          response: contentItem,
                          nodeId: originalDataElement.parentId, // will need to look at this for other data types where we use the external id
                          isExternalNodeId: false,
                          ignoreCacheTableEntry: true,
                        })
                      );
                    }

                    // remove from the existig cache and add to the invalid data cache
                    if (action.updateCache) {
                      dispatchActions.push(
                        new TriggerCacheDataUpdate({
                          dataElementType: DataElementType.InvalidData,
                          dataUrn: successData.id,
                          cacheUpdateType: CacheUpdateType.Add,
                          response: contentItem,
                          nodeId: EnvironmentConstants.FCS_NODE_ID_INVALID_DATA,
                          isExternalNodeId: true,
                        })
                      );
                    }
                    dispatchActions.push(
                      this.dataElementEffectService.createActivitySubmissionAction(
                        action.config.id,
                        DataElementType.InvalidData,
                        [
                          {
                            activityType: ActivityActionType.Add,
                            urn: successData.id,
                            name: successData.name,
                          },
                        ],
                        EnvironmentConstants.FCS_NODE_ID_INVALID_DATA,
                        true
                      )
                    );
                  } else {
                    if (action.updateCache) {
                      // cache update
                      dispatchActions.push(
                        new TriggerCacheDataUpdate({
                          dataElementType: action.dataElementType,
                          dataUrn: successData.id,
                          cacheUpdateType: CacheUpdateType.Update,
                          response: contentItem,
                          nodeId: action.nodeId,
                          isExternalNodeId: action.isExternalNodeId,
                        })
                      );
                    }
                  }

                  // finalise the part in the store
                  dispatchActions.push(action.successAction);
                } else {
                  //const failureData = data as ApiError;
                  dispatchActions.push(action.failAction);

                  // todo - if the part fails to upgrade somehow, then remove the isUpgrading flag so that the user can retry it
                }

                return of(dispatchActions);
              }
            ),
            tap(() => {
              if (dataElement)
                this.dataUpgradeService.submitDataUpgradeChange({
                  data: dataElement,
                  dataType: action.dataElementType,
                  type: 'complete',
                });
            })
          );
      })
    );
  };

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private forgeContentService: ForgeContentService,
    private dynamicDataService: DynamicDataService,
    private dataElementEffectService: DataElementEffectService,
    private dataUpgradeService: DataUpgradeService<ForgeContentDataElement>
  ) {}
}
