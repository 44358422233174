export enum SchemaErrorType {
  none = 'none',
  multipleOf = 'multipleOf',
  maximum = 'maximum',
  minimum = 'minimum',
  maxLength = 'maxLength',
  minLength = 'minLength',
  pattern = 'pattern',
  additionalItems = 'additionalItems',
  items = 'items',
  maximumItems = 'maxItems',
  minimumItems = 'minItems',
  uniqueItems = 'uniqueItems',
  maximumProperties = 'maxProperties',
  minimumProperties = 'minProperties',
  required = 'required',
  additionalProperties = 'additionalProperties',
  dependencies = 'dependencies',
  enum = 'enum',
  type = 'type',
  allOf = 'allOf',
  anyOf = 'anyOf',
  oneOf = 'oneOf',
  not = 'not',
  format = 'format',
  id = 'id',
  patternProperties = 'patternProperties',
  validator = 'validator',
  valid = 'valid',
  const = 'const',
  contains = 'contains',
  contentEncoding = 'contentEncoding',
  contentMediaType = 'contentMediaType',
  then = 'then',
  else = 'else',
  unknown = 'unknown', // added just in case
}
