import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'fab-dropdown-typeahead-variant-type',
  templateUrl: './dropdown-typeahead-variant.component.html',
})
export class DropdownTypeaheadVariantTypeComponent
  extends FieldType<FormlyFieldConfig>
  implements OnInit, AfterViewInit
{
  @ViewChild(MatInput) formFieldControl: MatInput;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  filter: Observable<string[]>;
  filterFunc: (term: string) => Observable<string[]>;
  initialValueSubject: BehaviorSubject<string> = null;

  ngOnInit() {
    if (this.props.dropdownTypeaheadValueOptions) {
      this.filterFunc = this.props.dropdownTypeaheadValueOptions;
      // get initial form control value
      // ensures that initial auto-complete shows only
      // entries matching the exisitng value
      this.initialValueSubject = new BehaviorSubject(this.formControl.value);

      // filter the initial value then any field value changes
      this.filter = concat(
        this.initialValueSubject.pipe(take(1)),
        this.formControl.valueChanges
      ).pipe(switchMap((term: string) => this.filterFunc(term)));
    }
  }

  ngAfterViewInit() {
    // temporary fix for https://github.com/angular/material2/issues/6728
    (this.autocomplete as any)._formField = this.formField;
  }
}
