// unit schemas can be found here:
// https://git.autodesk.com/forge/HFDMSchemas/tree/master/schemas/autodesk/unit/unit
export enum UnitSchemaType {
  None = 'None',

  // lengths
  Millimeters = 'Millimeters',
  Inches = 'Inches',
  Feet = 'Feet',
  Centimeters = 'Centimeters',
  Meters = 'Meters',

  // angles
  Degrees = 'Degrees',
  Radians = 'Radians',

  // mass
  Kilograms = 'Kilograms',
  Pounds = 'Pounds',
}

// symbol schemas can be found here:
// https://git.autodesk.com/forge/HFDMSchemas/tree/master/schemas/autodesk/unit/symbol
export enum SymbolSchemaType {
  None = 'None',
  Millimeters = 'Millimeters',
  Centimeters = 'Centimeters',
  Meters = 'Meters',
  MetersAndCentimeters = 'MetersAndCentimeters',
  Inches = 'Inches',
  // FeetAndInches = 'FeetAndInches',
  FootSingleQuote = 'FootSingleQuote',
  Degrees = 'Degrees',
  Kilograms = 'Kilograms',
  Pounds = 'Pounds',
}

// quantity schemas can be found here:
// https://git.autodesk.com/forge/HFDMSchemas/tree/master/schemas/autodesk/unit/quantity
export enum QuantitySchemaType {
  None = 'None',
  Length = 'Length',
  Angle = 'Angle',
  Mass = 'Mass',
}
