import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { ShapeType } from './common';
import { FabricationReference } from '../forge-content/references';

export enum TwoSidesReinforcedType {
  Rods = 'Rods',
  None = 'None',
}

export enum ExternalCornerOptions {
  FlushToWidthSide = 'FlushToWidthSide',
  FlushToDepthSide = 'FlushToDepthSide',
  FlushToBothSides = 'FlushToBothSides',
  BackToBack = 'BackToBack',
}

export class FamilyTypeReference {
  family = '-1';
  familyType = '-1';
}

export class ComponentLengthBreakpoint {
  minLength = 0;
  maxLength = 999;
  cutHoles = true;
  holeDiameter = 0.1;
  componentFamily: FamilyTypeReference;
}

export class BasicSettings {
  onEnds = false;
  onMidpoint = false;
  endOffset = 0.0;
  midpointOffset = 0.0;
}

export class InternalSettings {
  numberOfComponentsOnEnds = 1;
  numberOfComponentsOnMidpoint = 1;
  distanceBetweenComponentsOnWidthHeight = 0.0;
  componentLengthBreakpoints: ComponentLengthBreakpoint[] = [];
  basicSettings: BasicSettings;
}

export class ExternalSettings {
  isDoubleStiffening = false;
  twoSidesEndExtension = 0.0;
  twoSidesReinforcedType: TwoSidesReinforcedType = TwoSidesReinforcedType.None;
  basicSettings: BasicSettings;
  componentFamily: FamilyTypeReference;
  rodFamily: FamilyTypeReference;
}

export type StiffenerSettingType =
  | StiffenerSettingForRound
  | StiffenerSettingForOval
  | StiffenerSettingForRect;

export class stiffenerSettingGeneral {
  materialSpec = '-1';
}

export class StiffenerSettingForRound extends stiffenerSettingGeneral {
  externalSettings: ExternalSettings;
}

export class StiffenerSettingForOval extends stiffenerSettingGeneral {
  internalSettings: InternalSettings;
  externalSettings: ExternalSettings;
}

export class StiffenerSettingForRect extends stiffenerSettingGeneral {
  internalSettings: InternalSettings;
  externalSettings: ExternalSettings;
}

export const createStiffenerSettingType = (profileShape: ShapeType): StiffenerSettingType => {
  let stiffenerSetting: StiffenerSettingType = null;
  switch (profileShape) {
    case ShapeType.Round:
      stiffenerSetting = new StiffenerSettingForRound();
      break;
    case ShapeType.Oval:
      stiffenerSetting = new StiffenerSettingForOval();
      break;
    case ShapeType.Rectangular:
      stiffenerSetting = new StiffenerSettingForRect();
      break;
    default:
      break;
  }

  if (!stiffenerSetting) {
    throw new Error('Duct Profile Shape not Currently Supported');
  }

  return stiffenerSetting;
};

export class StiffenerBreakpoint {
  start: number;
  end: number;
  stiffenerSettings: StiffenerSettingType[] = [];

  constructor(
    start = 0,
    end = 999,
    stiffenerSettings: StiffenerSettingType[] = [createStiffenerSettingType(ShapeType.Rectangular)]
  ) {
    this.start = start;
    this.end = end;
    this.stiffenerSettings = stiffenerSettings;
  }
}

export class DuctSpecBreakpoint {
  ductSpec = '-1';
  ductMaterial = '-1';
  endsReinforcedOnlyRatio = 0.5;
  notReinforcedRatio = 0.5;
  ductProfileShape: ShapeType = ShapeType.Rectangular;
  externalCornerOptions: ExternalCornerOptions;
  stiffenerBreakpoints: StiffenerBreakpoint[] = [];
}

export class StiffenerSpecificationInfo extends ForgeContentDataElement {
  ductSpecBreakpoints: DuctSpecBreakpoint[] = [];
  fabricationReferences: FabricationReference[] = [];
}
