// dimensions
import * as LengthDimensionSchema from './dimension/length-1.0.0.json';
import * as MassDimensionSchema from './dimension/mass-1.0.0.json';
import * as TimeDimensionSchema from './dimension/time-1.0.0.json';

// factors
import * as CentiFactorSchema from './factor/prefix/centi-1.0.1.json';
import * as DeciFactorSchema from './factor/prefix/deci-1.0.1.json';
import * as HectoFactorSchema from './factor/prefix/hecto-1.0.1.json';
import * as KiloFactorSchema from './factor/prefix/kilo-1.0.1.json';
import * as MicroFactorSchema from './factor/prefix/micro-1.0.1.json';
import * as MilliFactorSchema from './factor/prefix/milli-1.0.1.json';
import * as NanoFactorSchema from './factor/prefix/nano-1.0.1.json';
import * as PiFactorSchema from './factor/pi-1.0.0.json';
import * as TenFactorSchema from './factor/ten-1.0.0.json';
import * as ThreeHundredSixtyFactorSchema from './factor/threeHundredSixty-1.0.0.json';
import * as ThreeThousandNineHundredThirtySevenFactorSchema from './factor/threeThousandNineHundredThirtySeven-1.0.0.json';

// quantities
import * as LengthQuantitySchema from './quantity/length-1.0.3.json';
import * as AngleQuantitySchema from './quantity/angle-1.0.1.json';
import * as MassQuantitySchema from './quantity/mass-1.0.3.json';

// symbols
import * as DegreeSymbolSchema from './symbol/degree-1.0.1.json';
import * as FeetAndInchesSymbolSchema from './symbol/feetAndInches-1.0.1.json';
import * as InchDoubleQuoteSymbolSchema from './symbol/inchDoubleQuote-1.0.1.json';
import * as MillimeterSymbolSchema from './symbol/mm-1.0.1.json';
import * as FootSingleQuoteSymbolSchema from './symbol/footSingleQuote-1.0.1.json';
import * as CentimetersSymbolSchema from './symbol/cm-1.0.1.json';
import * as MeterSymbolSchema from './symbol/meter-1.0.0.json';
import * as MetersAndCentimetersSymbolSchema from './symbol/metersAndCentimeters-1.0.0.json';
import * as KilogramsSymbolSchema from './symbol/kg-1.0.0.json';
import * as PoundsSymbolSchema from './symbol/lb-1.0.1.json';
import * as SlugsSymbolSchema from './symbol/slug-1.0.0.json';

// units
import * as CentimetersUnitSchema from './unit/centimeters-1.0.1.json';
import * as DecimetersUnitSchema from './unit/decimeters-1.0.1.json';
import * as DegreesUnitSchema from './unit/degrees-1.0.1.json';
import * as FeetUnitSchema from './unit/feet-1.0.1.json';
import * as GradiansUnitSchema from './unit/gradians-1.0.1.json';
import * as HectometersUnitSchema from './unit/hectometers-1.0.1.json';
import * as KilometersUnitSchema from './unit/kilometers-1.0.1.json';
import * as InchesUnitSchema from './unit/inches-1.0.1.json';
import * as MetersUnitSchema from './unit/meters-1.0.0.json';
import * as MicronsUnitSchema from './unit/microns-1.0.0.json';
import * as MilesUnitSchema from './unit/miles-1.0.1.json';
import * as MillimetersUnitSchema from './unit/millimeters-1.0.1.json';
import * as MilsUnitSchema from './unit/mils-1.0.0.json';
import * as NauticalMilesUnitSchema from './unit/nauticalMiles-1.0.0.json';
import * as RadiansUnitSchema from './unit/radians-1.0.0.json';
import * as TurnsUnitSchema from './unit/turns-1.0.1.json';
import * as UsSurveyFeetUnitSchema from './unit/usSurveyFeet-1.0.0.json';
import * as YardsUnitSchema from './unit/yards-1.0.1.json';
import * as KilogramsUnitSchema from './unit/kilograms-1.0.0.json';
import * as PoundsUnitSchema from './unit/pounds-1.0.1.json';
import * as GrainsUnitSchema from './unit/grains-1.0.1.json';
import * as GramsUnitSchema from './unit/grams-1.0.1.json';
import * as MicrogramsUnitSchema from './unit/micrograms-1.0.0.json';
import * as MilligramsUnitSchema from './unit/milligrams-1.0.1.json';
import * as NanogramsUnitSchema from './unit/nanograms-1.0.1.json';
import * as OuncesUnitSchema from './unit/ounces-1.0.0.json';
import * as SlugsUnitSchema from './unit/slugs-1.0.0.json';
import * as TonnesUnitSchema from './unit/tonnes-1.0.1.json';
import * as UsTonnesUnitSchema from './unit/usTonnesMass-1.0.1.json';
import * as PoundsForceUnitSchema from './unit/poundsForce-1.0.1.json';
import * as StandardGravityUnitSchema from './unit/standardGravity-1.0.1.json';
import * as MetersPerSecondSquaredUnitSchema from './unit/metersPerSecondSquared-1.0.1.json';
import * as MetersPerSecondUnitSchema from './unit/metersPerSecond-1.0.1.json';
import * as SecondsUnitSchema from './unit/seconds-1.0.0.json';

export {
  // dimensions
  LengthDimensionSchema,
  MassDimensionSchema,
  TimeDimensionSchema,
  // factors
  CentiFactorSchema,
  DeciFactorSchema,
  HectoFactorSchema,
  KiloFactorSchema,
  MicroFactorSchema,
  MilliFactorSchema,
  NanoFactorSchema,
  PiFactorSchema,
  TenFactorSchema,
  ThreeHundredSixtyFactorSchema,
  ThreeThousandNineHundredThirtySevenFactorSchema,
  // quantities
  LengthQuantitySchema,
  AngleQuantitySchema,
  MassQuantitySchema,
  // symbols
  DegreeSymbolSchema,
  FeetAndInchesSymbolSchema,
  InchDoubleQuoteSymbolSchema,
  MillimeterSymbolSchema,
  FootSingleQuoteSymbolSchema,
  CentimetersSymbolSchema,
  MeterSymbolSchema,
  MetersAndCentimetersSymbolSchema,
  KilogramsSymbolSchema,
  PoundsSymbolSchema,
  SlugsSymbolSchema,
  // units
  CentimetersUnitSchema,
  DecimetersUnitSchema,
  DegreesUnitSchema,
  FeetUnitSchema,
  GradiansUnitSchema,
  HectometersUnitSchema,
  InchesUnitSchema,
  KilometersUnitSchema,
  MetersUnitSchema,
  MicronsUnitSchema,
  MilesUnitSchema,
  MillimetersUnitSchema,
  MilsUnitSchema,
  NauticalMilesUnitSchema,
  RadiansUnitSchema,
  TurnsUnitSchema,
  UsSurveyFeetUnitSchema,
  YardsUnitSchema,
  KilogramsUnitSchema,
  PoundsUnitSchema,
  GrainsUnitSchema,
  GramsUnitSchema,
  MicrogramsUnitSchema,
  MilligramsUnitSchema,
  NanogramsUnitSchema,
  OuncesUnitSchema,
  SlugsUnitSchema,
  TonnesUnitSchema,
  UsTonnesUnitSchema,
  PoundsForceUnitSchema,
  StandardGravityUnitSchema,
  MetersPerSecondSquaredUnitSchema,
  MetersPerSecondUnitSchema,
  SecondsUnitSchema,
};
