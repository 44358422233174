export class PartsConstants {
  static readonly FORGE_FDM_FOLDER = 'FDM';
  static readonly ATTRIBUTE_LOWER_PATH = 'lowerCasePath';

  static readonly FORGE_KEY_BRAND = 'fabricationData.brand';
  static readonly FORGE_KEY_RANGE = 'fabricationData.range';
  static readonly FORGE_KEY_FABRICATION_REF_EXTERNAL_ID =
    'fabricationData.fabricationReferences.externalId';
  static readonly FORGE_KEY_PATTERN_NUMBER = 'fabricationData.patternNumber';

  static readonly FORGE_KEY_ATTRIBUTE_LOWER_PATH = 'attributes.' + this.ATTRIBUTE_LOWER_PATH;
}
