import ThemeContext from '@weave-design/theme-context';
import * as React from 'react';
import styled from 'styled-components';
import Tabs, { Tab } from '@weave-design/tabs';
import Typography from '@weave-design/typography';
import Spacer from '@weave-design/spacer';
import { Steps } from './components/learn-content.react';
import { HamburgerCloseUI } from '@weave-design/icons';

export type Props = {
  command: string;
  upicode: string;
  onClose: () => void;
  themeData: unknown;
};

const LearnNavBackground: any = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 325px;
  padding: 8px;
  border-left: 1px solid #808080;
  background-color: ${(props: any) => props.background};
`;

const LearnNavHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled('div')`
  flex-shrink: 0;
  cursor: pointer;
`;

export const HigLearnNav: React.FC<Props> = ({ command, upicode, onClose, themeData }) => (
  <ThemeContext.Provider value={themeData}>
    <ThemeContext.Consumer>
      {({ resolvedRoles }) => (
        <LearnNavBackground background={resolvedRoles['colorScheme.surface.level100']}>
          <LearnNavHeader>
            <Typography align="left" fontWeight="normal" variant="h3">
              Learning Panel
            </Typography>
            <CloseButton onClick={onClose} data-testid="close-learning-panel">
              <HamburgerCloseUI />
            </CloseButton>
          </LearnNavHeader>
          <Spacer size="m" />
          <Tabs>
            <Tab label="Guidance">
              <div className="command-header">
                <Typography align="left" fontWeight="bold" variant="body">
                  Command name w/ Icon
                </Typography>
                <Typography align="left" fontWeight="normal" variant="body">
                  This is the description of {command} the command
                </Typography>
                <Spacer size="l" />
              </div>
              <Tabs>
                <Tab label="Step-by-step">
                  <Steps
                    cID={command}
                    upicode={upicode}
                    sLanguage="ENU"
                    prod={false}
                    themeData={themeData}
                  />
                </Tab>
                <Tab label="Learn more"></Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </LearnNavBackground>
      )}
    </ThemeContext.Consumer>
  </ThemeContext.Provider>
);
