import { Injectable } from '@angular/core';
import { UserSettingsModel, DefaultUserSettings } from '@models/user-settings/user-settings';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  namespacePrefix = 'fabdm-user-settings-';
  namespace: string;
  currentUserSettings: UserSettingsModel = null;

  /* istanbul ignore next */
  saveUserSettings(): void {
    localStorage.setItem(this.namespace, JSON.stringify(this.currentUserSettings));
  }

  /* istanbul ignore next */
  getUserSettings(): any {
    this.currentUserSettings = JSON.parse(localStorage.getItem(this.namespace));

    return this.currentUserSettings;
  }

  initializeUserSettings(userId: string) {
    this.namespace = `${this.namespacePrefix}${userId}`;
    // Initialize the session state if not exists
    if (!this.getUserSettings()) {
      this.currentUserSettings = DefaultUserSettings;
      this.saveUserSettings();
    }
  }

  /* istanbul ignore next */
  clearCurrentUserSettings() {
    localStorage.removeItem(this.namespace);
  }
}
