import { Injectable } from '@angular/core';
import {
  DynamicDataElementTypeSetup,
  DynamicFormReference,
} from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import {
  DynamicFormGroupOptions,
  DynamicFormOptions,
  DynamicFormSelectType,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Part, PartMap, PartType } from '@models/fabrication/part';
import { ContentNode } from '@models/fabrication/content-node';
import { Observable, combineLatest, of } from 'rxjs';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { switchMap, map, catchError, take } from 'rxjs/operators';
import { ContentFile, StorageFile, StorageFileType } from '@models/fabrication/files';
import { LoggingService } from '@services/logging.service';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { EnvironmentConstants as ec, EnvironmentConstants } from '@constants/environment-constants';
import { selectAllParts, selectPartById } from '@store/selectors/part.selectors';
import {
  LoadPartsSuccess,
  UpdatePart,
  UpdatePartSuccess,
  CopyPart,
  AddPartSuccess,
  StartUpgradePart,
  DeleteParts,
  DeletePartsSuccess,
} from '@store/actions/part.action';
import { UpdateContentNodeReferences } from '@store/actions/content-node.action';
import { selectContentFileById } from '@store/selectors/content-file.selectors';
import { selectContentNodeById } from '@store/selectors/content-node.selectors';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { GraphConstants as GC } from '@constants/graph-constants';
import { flatten, startCase } from 'lodash';
import { DataElementInternalType } from '@constants/data-element-internal-types';
import { ReferenceOutput } from '@services/data-services/dynamic-graph-data.service';
import { ServiceTemplateInfo } from '@models/fabrication/service-template-info';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import {
  FabricationConnectorReference,
  FabricationInsulationSpecReference,
  FabricationMaterialFinishReference,
  FabricationMaterialSpecReference,
  FabricationReference,
  FabricationReferenceType,
  FabricationSpecReference,
} from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { selectThumbnailFileById } from '@store/selectors/thumbnail-file.selectors';
import { EnvironmentService } from '@services/environment.service';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';
import { Config } from '@models/fabrication/config';
import { SchemaService } from '@services/schema.service';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { SchemaErrorType } from '@models/fabrication/schema-error-type';
import { InvalidData, InvalidDataErrorReason } from '@models/fabrication/invalid-data';
import { PartUpgraderUtils } from '@utils/upgrades/part-upgrader-utils';
import { PartFilterConfig } from '@models/forge-content/part-search';
import { selectPartFilterById } from '@store/selectors/part-filter.selectors';
import { PartUtils } from '@utils/parts/part-utils';
import { CalculationFunctions } from '@models/calculations/calculations';
import { AnalyticsService } from '@services/analytics.service';
import { Material, MaterialType } from '@models/fabrication/material';
import { ConnectorsData } from '@shared/react/components/part-connectors-form/part-connectors-form.component';

@Injectable()
export class DynamicPartSetup extends DynamicDataElementTypeSetup<Part> {
  constructor(
    store$: Store<FDMState>,
    private fileStorageService: BinaryStorageService,
    translate: TranslateService,
    private loggingService: LoggingService,
    invalidDataService: InvalidDataErrorService<Part>,
    schemaService: SchemaService,
    environmentService: EnvironmentService,
    private analyticsService: AnalyticsService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return '';
  }

  setupOptions() {
    this.options = {
      dataType: DataElementType.Part,
      dependentDataTypes: [
        DataElementType.Specification,
        DataElementType.InsulationSpecification,
        DataElementType.Connector,
        DataElementType.Material,
        DataElementType.MaterialSpecification,
      ],
      excludeDataTypeFromBulkLoad: true,
      supportsDynamicUpdates: false,
      createNewInstance: () => new Part(),
      sortFields: ['name'],
      bulkLoadFileTypesSupported: [StorageFileType.ImageFile], // part files pulled on demand so not defined here
      selectors: {
        selectAll: () => this.store$.select(selectAllParts),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectPartById(id)),
      },
      actions: {
        loadAllAction: null,
        loadSuccessAction: () => new LoadPartsSuccess(),
        deleteDataSuccessAction: () => new DeletePartsSuccess(),
        addDataSuccessAction: () => new AddPartSuccess(),
        updateDataSuccessAction: () => new UpdatePartSuccess(),
        updateDataReferencesAction: (
          parentNode: ContentNode,
          dataIds: string[],
          deleteReferences: boolean // eslint-disable-line @typescript-eslint/no-unused-vars
        ) => {
          if (deleteReferences) return null;

          const node = { ...parentNode };
          node.content = [...new Set(node.content.concat(dataIds))];
          node.contentCount = node.content.length;
          node.hasContent = !!node.content.length;
          return new UpdateContentNodeReferences({
            id: node.id,
            changes: node,
          });
        },
        createModelAction: null,
        editModelAction: (model: Part, oldModel: Part) => {
          this.setFileAndImagePath(model);
          const updateThumbnails = model.thumbnailUrl !== oldModel.thumbnailUrl;
          this.store$.dispatch(
            new UpdatePart({
              dataElement: model,
              nodeId: model.parentId,
              isExternalNodeId: false,
              oldPath: oldModel.path,
              updateThumbnails,
            })
          );
        },
        copyModelAction: (model: Part) => {
          this.setFileAndImagePath(model);
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config: Config) => {
              this.store$.dispatch(
                new CopyPart({
                  config,
                  dataElement: model,
                })
              );
            });
        },
        startUpgradeAction: (partId: string) => {
          combineLatest([
            this.store$.select(selectCurrentConfig),
            this.store$.select(selectPartById(partId)),
          ])
            .pipe(take(1))
            .subscribe((data: [Config, Part]) => {
              const [config, part] = data;
              this.store$.dispatch(
                new StartUpgradePart({
                  config,
                  part,
                  nodeId: part.parentId,
                  isExternalNodeId: false,
                })
              );
            });
        },
        deleteModelsAction: (models: Part[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new DeleteParts({ config, dataElements: models, oldPath: models[0].path })
              )
            );
        },
        fixModelAction: null,
      },
      fcs: {
        dataTypeExternalNodeId: null,
        schemas: [
          {
            dataType: DataElementType.Part,
            schema: {
              namespace: ec.FSS_SCHEMA_NAMESPACE,
              version: ec.FSS_SCHEMA_PART_VERSION,
              type: ec.FSS_SCHEMA_PART,
            },
          },
        ],
      },
    };
  }
  /**
   * Get PartMap from provided partId
   * PartMap includes the raw storage files and part geometry
   * @param  {string} partId
   * @returns Observable
   */
  public getPartMap(partId: string): Observable<PartMap> {
    const partMap: PartMap = {
      part: null,
      isUpgrading: false,
      isInvalid: false,
      requiresUpgrade: false,
      contentNodeId: null,
      thumbnailStorageFile: null,
      partStorageFile: null,
      partData: null,
    };

    return this.options.selectors.selectById(partId).pipe(
      switchMap((selectedPart: Part) => {
        // begin constructing partMap
        partMap.part = selectedPart;

        // get part associated files and thumbnails and ContentNodes to find parent
        return combineLatest([
          this.store$.select(selectContentFileById(selectedPart.externalId)),
          this.store$.select(selectThumbnailFileById(selectedPart.externalId)),
          this.store$.select(selectContentNodeById(selectedPart.parentId)),
        ]);
      }),
      switchMap((contentFiles: [ContentFile, ContentFile, ContentNode]) => {
        const [partContentFile, thumbnailContentFile, parentNode] = contentFiles;
        // assign parent node id
        partMap.contentNodeId = (parentNode && parentNode.id) || null;
        // get raw files from storage and current configId

        const partFileData = partContentFile?.objectKey ? [partContentFile.objectKey] : [];
        const thumbnailData = thumbnailContentFile?.objectKey
          ? [thumbnailContentFile.objectKey]
          : [];
        return combineLatest([
          this.fileStorageService.getPartFiles(partFileData),
          this.fileStorageService.getImageFiles(thumbnailData),
        ]);
      }),
      map((setupData: [StorageFile[], StorageFile[]]) => {
        const [partStorageFiles, thumbnailStorageFiles] = setupData;
        partMap.partStorageFile = partStorageFiles.length ? partStorageFiles[0] : null;
        partMap.thumbnailStorageFile = thumbnailStorageFiles.length
          ? thumbnailStorageFiles[0]
          : null;

        if (!partMap.partStorageFile) {
          throw new Error('No binary file found');
        }

        return partMap;
      }),
      catchError((err) => {
        this.loggingService.logError(
          err,
          true,
          this.translate.instant(LC.CONFIG.ERROR_LOADING_FABRICATION_PART)
        );
        return of(null);
      })
    );
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<Part>> {
    return null;
  }

  private getFabricationReferenceOptions(model: Part): DynamicFormReference[] {
    let references: DynamicFormReference[] = [];

    if (!model.fabricationReferences || model.fabricationReferences.length === 0) {
      return references;
    }

    references = model.fabricationReferences
      .map<DynamicFormReference>((ref, i) => {
        const key = `fabricationReferences.${i}.externalId`;
        const supportsLocking =
          ref.dataType !== DataElementType.Specification && ref.hasOwnProperty('isLocked');

        let referenceSelector = this.getReferenceSelector(ref.dataType);
        if (ref.dataType === DataElementType.Material) {
          referenceSelector = referenceSelector.pipe(
            map((allMaterials: Material[]) => {
              if (supportsLocking) {
                return allMaterials.filter((x) => x.materialType === MaterialType.Finish);
              }

              return allMaterials.filter((x) => x.materialType !== MaterialType.Finish);
            })
          );
        }

        return {
          key,
          dataType: ref.dataType,
          referenceType: ref.referenceType,
          lockField: (supportsLocking && key) || '',
          labelField: {
            key,
            label:
              ref.dataType === DataElementType.Material && supportsLocking
                ? this.translate.instant(LC.DATATYPES.DEFINITIONS.PARTS.FINISH)
                : this.createReferenceLabel(ref.dataType, model, i),
          },
          selectFieldReadOnlyOverride: {
            label: startCase(ref.dataType),
            value: this.getReferenceSelector(ref.dataType).pipe(
              map((referenceInfoList: any) => {
                const data = referenceInfoList.find((x) => x.externalId === ref.externalId);
                return (data && data.name) || this.translate.instant(LC.GRAPH.UNASSIGNED);
              })
            ),
            referenceIndex: i,
          },
          selectField: {
            key,
            options: referenceSelector.pipe(
              map((referenceInfoList: any[]) => {
                const list = this.getDataElementTypeAsSummaryList(
                  ref.dataType,
                  referenceInfoList,
                  model,
                  i
                );
                return list;
              })
            ),
          },
        };
      })
      .filter((x) => x.referenceType === FabricationReferenceType.Relationship);

    return references;
  }

  private getConnectorsData(model: Part): ConnectorsData[] {
    const fabricationReferences = model.fabricationReferences ?? [];
    const referenceSelector = this.getReferenceSelector(DataElementType.Connector);
    const materialSelector = this.getReferenceSelector(DataElementType.Material).pipe(
      map((allMaterials: Material[]) => {
        return allMaterials.filter((x) => x.materialType !== MaterialType.Finish);
      })
    );

    return fabricationReferences
      .filter((ref) => ref.dataType === DataElementType.Connector)
      .map(({ index, dataType, ...connector }: FabricationConnectorReference) => {
        return {
          ...connector,
          index,
          connectorOptions: referenceSelector.pipe(
            map((connectors) =>
              this.getDataElementTypeAsSummaryList(dataType, connectors, model, index)
            )
          ),
          materialOptions: materialSelector.pipe(
            map((materials) => [
              {
                value: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL,
                label: this.translate.instant(LC.GRAPH.UNASSIGNED),
              },
              ...this.getDataElementTypeAsSummaryList(
                DataElementType.Material,
                materials,
                model,
                index
              ),
            ])
          ),
        };
      });
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<Part>> {
    const detailsIncludeFields = ['brand', 'range', 'alias', 'productNumber', 'patternNumber'];
    const detailsReadonlyFields = ['patternNumber'];

    let model: Part = null;
    let config: Config = null;

    return combineLatest([
      this.getFormModel(formOperation, modelId),
      this.store$.select(selectCurrentConfig),
    ]).pipe(
      take(1),
      switchMap((data: [Part, Config]) => {
        model = data[0];
        config = data[1];
        return this.store$.select(selectPartFilterById(config.id));
      }),
      map((facets: PartFilterConfig) => {
        const partType = model.partType;
        const referenceData = this.getFabricationReferenceOptions(model);
        const connectorsData = this.getConnectorsData(model);

        return {
          additionalData: { connectorsData },
          model,
          formOperation,
          showRevert: true,
          showCancelInReadOnlyMode: true,
          applyModelAction: (newPart: Part) => {
            this.captureAnalytics(newPart, model, config.id);
            this.getFormApplyAction(formOperation)(newPart, model);
          },
          isReadOnly: formOperation === 'view',
          formStyle: DynamicFormStyle.PART_PREVIEW,
          tabs: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.DETAILS),
              includeFields: detailsIncludeFields,
              options: {
                readOnlyFields: detailsReadonlyFields,
                overrideFieldWidth: 100,
                formStyle: DynamicFormStyle.PART_PREVIEW,
                dropdownTypeaheadFields: [
                  {
                    key: 'brand',
                    options:
                      facets?.facets?.brands
                        .map((x) => x.key)
                        .filter((x) => x.length > 0)
                        .sort((a, b) => a.localeCompare(b)) || [],
                  },
                  {
                    key: 'range',
                    options:
                      facets?.facets?.ranges
                        .map((x) => x.key)
                        .filter((x) => x.length > 0)
                        .sort((a, b) => a.localeCompare(b)) || [],
                  },
                ],
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.GEOMETRY),
              includeFields: ['productListData', 'fabricationReferences'],
              options: {
                overrideFieldWidth: 100,
                hiddenFields: [
                  partType !== PartType.ProductList && 'productListData',
                  'fabricationReferences',
                ],
                groups: this.getGroups(partType),
                formStyle: DynamicFormStyle.PART_PREVIEW,
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.MANUFACTURING),
              includeFields: ['fabricationReferences'],
              options: {
                overrideFieldWidth: 100,
                selectFields: referenceData.map((ref) => {
                  // make sure material and material spec form field
                  // selectors include the option to set to "Unassigned"
                  // needs to be overridden here as not required in other areas of the app
                  if (
                    ref.dataType === DataElementType.Material ||
                    ref.dataType === DataElementType.MaterialSpecification
                  ) {
                    let dataTypeExternalId = '';
                    if (ref.dataType === DataElementType.Material) {
                      dataTypeExternalId =
                        ref.lockField?.length > 0
                          ? EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_FINISH
                          : EnvironmentConstants.FCS_UNASSIGNED_MATERIAL;
                    } else {
                      dataTypeExternalId = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
                    }

                    const selectFieldOptions$: Observable<DynamicFormSelectType[]> = (
                      ref.selectField.options as Observable<DynamicFormSelectType[]>
                    ).pipe(
                      map((options: DynamicFormSelectType[]) => {
                        return [
                          {
                            value: dataTypeExternalId,
                            label: this.translate.instant(LC.GRAPH.UNASSIGNED),
                          },
                          ...options,
                        ];
                      })
                    );

                    ref.selectField.options = selectFieldOptions$;
                  }
                  return ref.selectField;
                }),
                labelMapping: referenceData.map((ref) => ref.labelField),
                lockFields: referenceData.map((ref) => ref.lockField),
                hiddenFields: referenceData
                  .filter(
                    (x) =>
                      x.dataType === DataElementType.Stiffener ||
                      x.dataType === DataElementType.InsulationSpecification ||
                      x.dataType === DataElementType.Damper ||
                      x.dataType === DataElementType.Connector
                  )
                  .map((x) => x.key), // temp filter out data types on part forms
                formStyle: DynamicFormStyle.PART_PREVIEW,
              },
            },
          ],
        };
      }),
      take(1)
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'patternNumber'],
      hasImage: true,
      isReplaceable: false,
      isRemovable: true,
      isEditable: true,
      upstreamReferenceDataTypes: () => [DataElementType.ServiceTemplate],
      clusterIcon: 'products-and-services16',
      createInternalUpstreamGraphNodes: (dataElement, references) => {
        const serviceTemplates = references
          .filter((ref) => ref.dataElementType === DataElementType.ServiceTemplate)
          .map((ref) => ref.dataElement as ServiceTemplateInfo);

        const filteredServiceTemplates = serviceTemplates.filter((template) =>
          template?.fabricationReferences.some(
            (reference) => reference.externalId === dataElement.externalId
          )
        );

        if (filteredServiceTemplates?.length) {
          const graphNodes = flatten(
            filteredServiceTemplates.map((template) =>
              template.palettes
                .filter((palette) =>
                  palette.partCollections.some(
                    (collection) =>
                      collection.parts.findIndex(
                        (x) => x.contentExternalId === dataElement.externalId
                      ) >= 0
                  )
                )
                .map((palette) => {
                  let numParts = 0;
                  palette.partCollections.forEach((x) => (numParts += x.parts.length));
                  return {
                    id: palette.id,
                    internalRelationshipId: palette.id,
                    internalRelationshipDataType: DataElementInternalType.Palette,
                    dbid: dataElement.id,
                    dataType: DataElementType.Part,
                    isFocusable: false,
                    isExpandable: true,
                    isReplaceable: false,
                    isRemovable: false,
                    isEditable: false,
                    info: {
                      name: palette.name,
                      parts: numParts,
                      partTemplate: `${
                        template.category
                          ? template.category
                          : this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED)
                      }: ${template.name}`,
                    },
                    referenceMetaData: null,
                    isInvalidInternalData:
                      template.extensionDataType === EnvironmentConstants.FSS_SCHEMA_INVALID_DATA,
                  };
                })
            )
          );

          return of(graphNodes);
        } else {
          return of([
            {
              id: '-2',
              dataType: GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE,
              isExpandable: false,
              hasNoReference: true,
            },
          ]);
        }
      },
      getInternalUpstreamReferenceFilteredElements: (
        references: ReferenceOutput[],
        internalRelationshipId: string
      ) => {
        const selectedServiceTemplate = references.find((ref) => {
          const service = ref.referenceMap.dataElement as ServiceTemplateInfo;

          return service.palettes.some((palette) => palette.id === internalRelationshipId);
        });

        return [selectedServiceTemplate];
      },
    };
  }

  dataFixes(): void {
    //
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    // ensure the following references all exist:
    // material, material specification, material finish, specification, insulation specification

    let references: FabricationReference[] = [];
    if (fabricationReferences?.length) {
      references = references.concat(fabricationReferences);
    }

    const material = fabricationReferences?.find((x) => x.dataType === DataElementType.Material);
    if (!material) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL,
        dataType: DataElementType.Material,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    const materialSpec = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.MaterialSpecification
    );
    if (!materialSpec) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC,
        dataType: DataElementType.MaterialSpecification,
        referenceType: FabricationReferenceType.Relationship,
        isLocked: false,
      } as FabricationMaterialSpecReference);
    }

    const materialFinish = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.Material && x.hasOwnProperty('isLocked')
    );
    if (!materialFinish) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_FINISH,
        dataType: DataElementType.Material,
        referenceType: FabricationReferenceType.Relationship,
        isLocked: false,
      } as FabricationMaterialFinishReference);
    }

    const spec = fabricationReferences?.find((x) => x.dataType === DataElementType.Specification);
    if (!spec) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_PART_SPEC,
        dataType: DataElementType.Specification,
        referenceType: FabricationReferenceType.Relationship,
        isLocked: false,
      } as FabricationSpecReference);
    }

    const insulationSpec = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.InsulationSpecification
    );
    if (!insulationSpec) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_INSULATION_SPEC,
        dataType: DataElementType.InsulationSpecification,
        referenceType: FabricationReferenceType.Relationship,
        isLocked: false,
      } as FabricationInsulationSpecReference);
    }

    return references;
  }

  getInvalidDataErrors(model: Part & InvalidData): string {
    const reasons = (model?.reason?.split(',') || []) as InvalidDataErrorReason[];
    if (reasons.length > 1) {
      // multiple reasons
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.MULTIPLE_ERRORS);
    }

    // we will also get back some required attributes that are missing, e.g. CID, patternNumber
    // if the part is corrupt or the payload is too big
    if (model.reason === InvalidDataErrorReason.PayloadTooBig) {
      // assume it's the product list entries that are too big
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.TOO_MANY_ENTRIES, {
        number: 1000,
      });
    }

    if (!model.files?.length || model.reason === InvalidDataErrorReason.Corrupt) {
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.CORRUPT_PART);
    }

    if (model.reason === InvalidDataErrorReason.CalculationCircularReference) {
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.CIRCULAR_CALCULATION_REFERENCES);
    }

    if (model.reason === InvalidDataErrorReason.InvalidDims) {
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.INVALID_DIMS);
    }

    if (model.reason === InvalidDataErrorReason.CalculationInvalidReference) {
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.INVALID_CALCULATION_REFERENCES);
    }

    if (model.reason === InvalidDataErrorReason.DependencyCircularReference) {
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.CIRCULAR_DEPENDENCY_REFERENCES);
    }

    if (model.reason === InvalidDataErrorReason.DifferentOptionPresetValues) {
      return this.translate.instant(LC.ERROR_HANDLING.PARTS.UNSUPPORTED_PRESET_SETUP);
    }

    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.Part);

    const parsedErrors = this.invalidDataErrorService.parseErrors(model, schema);
    if (!parsedErrors.length) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.UNKNOWN);
    }

    if (parsedErrors.length > 1) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.MULTIPLE_ERRORS);
    }

    const error = parsedErrors[0];
    const genericAttributes = ['externalId', 'contentExternalId'];
    if (genericAttributes.includes(error.attribute)) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.INVALID_REFERENCE);
    }

    if (error.attribute === 'entries') {
      if (error.schemaErrorType === SchemaErrorType.minimumItems) {
        return this.translate.instant(LC.ERROR_HANDLING.PARTS.NO_ENTRIES);
      }

      if (error.schemaErrorType === SchemaErrorType.maximumItems) {
        return this.translate.instant(LC.ERROR_HANDLING.PARTS.TOO_MANY_ENTRIES, {
          number: 1000,
        });
      }
    }

    return this.translate.instant(LC.ERROR_HANDLING.GENERIC.UNKNOWN);
  }

  getIconName(): string {
    return 'products-and-services';
  }

  isFixable(): boolean {
    return false;
  }

  /**
   * Sets the part's new file path based on the name
   */
  private setFileAndImagePath(part: Part): void {
    const folderPath = PartUtils.getPartHashedFolderPath(part);
    const itmFilename = `${part.name}.ITM`;

    part.path = `${folderPath}\\${itmFilename}`;

    // TODO: uncomment to add path as attribute
    // part.additionalAttributes = [
    //   {
    //     name: PartsConstants.ATTRIBUTE_LOWER_PATH,
    //     value: part.path.toLocaleLowerCase(),
    //     category: 'application',
    //     type: 'String',
    //   },
    // ];
    if (part.thumbnailUrl) {
      part.imagePath = `${folderPath}\\${part.name}.png`;
    }
  }

  private getGroups(partType: string): DynamicFormGroupOptions[] {
    const groups: DynamicFormGroupOptions[] = [];

    if (partType === 'ProductList') {
      const dimensionsGroup: DynamicFormGroupOptions = {
        label: `${this.translate.instant(LC.DYNAMIC_FORM.GROUPS.PRODUCT_ENTRIES)}`,
        options: {
          formStyle: DynamicFormStyle.SIMPLE,
          customComponents: [
            {
              type: DynamicFormCustomComponentType.PartDimensionsTable,
              field: 'productListData',
            },
          ],
        },
        includeFields: ['productListData'],
      };

      groups.push(dimensionsGroup);
    }

    const connectorsGroup: DynamicFormGroupOptions = {
      label: this.translate.instant(LC.DATATYPES.TITLES.CONNECTORS),
      options: {
        formStyle: DynamicFormStyle.SIMPLE,
        customComponents: [
          {
            type: DynamicFormCustomComponentType.PartConnectorsForm,
            field: 'fabricationReferences',
          },
        ],
        hiddenFields: [],
      },
      includeFields: ['fabricationReferences'],
    };

    groups.push(connectorsGroup);

    return groups;
  }

  requiresBinaryUpgrade(part: Part): boolean {
    return PartUpgraderUtils.partRequiresBinaryUpgrade(part);
  }

  private captureAnalytics(newPart: Part, oldPart: Part, configId: string): void {
    // create a dictionary of the new part's calculation functions
    const newCalcsDictionary: { [id: number]: string } = {};
    newPart.productListData?.dimensionInfos?.forEach((info) => {
      if (!info.calculation?.length) return;

      newCalcsDictionary[info.id] = info.calculation;
    });

    if (!Object.keys(newCalcsDictionary).length) return;

    const oldCalcsDictionary: { [id: number]: string } = {};
    oldPart.productListData?.dimensionInfos?.forEach((info) => {
      if (!info.calculation?.length) return;

      oldCalcsDictionary[info.id] = info.calculation;
    });

    let calculationChangesCount = 0;
    const newCalcs = new Set<CalculationFunctions>();
    const functionValues = Object.values(CalculationFunctions);
    Object.keys(newCalcsDictionary).forEach((id) => {
      const newCalc = newCalcsDictionary[id] as string;
      const oldCalc = oldCalcsDictionary[id] as string;

      if (newCalc === oldCalc) return; // no changes

      calculationChangesCount++;

      functionValues.forEach((func) => {
        const containsFunction = new RegExp(`\\b${func}\\b`, 'g').test(newCalc);
        if (containsFunction) newCalcs.add(func);
      });
    });

    if (!newCalcs.size && !calculationChangesCount) return;

    this.analyticsService.recordComponentMetrics(
      'fdm-part-calculation-usage',
      'FDM part calculation usage',
      'part-calcs',
      {
        configId,
        partId: newPart.id,
        partExternalId: newPart.externalId,
        calculationChangesCount: calculationChangesCount.toString(),
        calculationFunctions: newCalcs?.size ? Array.from(newCalcs).join(',') : undefined,
      }
    );
  }
}
