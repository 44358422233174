import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, defer, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AccessTokenResponse } from '@models/auth/auth-data';
import { UserData, UserAnalytics } from '@models/auth/user-data';
import { LoggingService } from './logging.service';
import { EnvironmentService } from './environment.service';
import { Router } from '@angular/router';
import { KeyData } from '@models/auth/key-data';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '@services/user-settings.service';
import { BroadcastChannel } from 'broadcast-channel';
import { ConfirmationService } from './confirmation/confirmation.service';
import { ConfirmationResult } from '@models/confirmation/confirmation';
import { AxiosError, AxiosResponse } from 'axios';
import { ResilientHttpClient, IResilientHttpOptions } from '@adsk/resilient-axios-client';
import { ResiliencyConstants as rc } from '@constants/resiliency-constants';
import { AppConstants } from '@constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _httpClient: ResilientHttpClient = null;
  private _currentAccessTokenExpires = 0;
  private _currentAccessToken: AccessTokenResponse = null;
  private _currentUserData: UserData = null;
  private _currentKeyData: KeyData = null;
  private _currentAnalyticsId: UserAnalytics = null;
  private _logoutSyncChannel = new BroadcastChannel<boolean>('logout-sync-channel');
  private _hasKeyDataSubject = new BehaviorSubject<boolean>(false);
  private _hasUserDataSubject = new BehaviorSubject<boolean>(false);
  private _apploginComplete = new BehaviorSubject<boolean>(false);

  // ctor
  constructor(
    private envService: EnvironmentService,
    private loggingService: LoggingService,
    private router: Router,
    private translate: TranslateService,
    private userSettingsService: UserSettingsService,
    private confirmationService: ConfirmationService
  ) {
    this.setupClient();
    this.setupLogoutSyncChannels();
  }

  private setupLogoutSyncChannels = (): void => {
    this._logoutSyncChannel.onmessage = () => {
      console.log(`Recieved message to logout current fabdm session`);
      this._currentAccessToken = null;
      this._currentAccessTokenExpires = 0;
      this._currentKeyData = null;
      this.warnUserCurrentSessionIsLoggedOut().subscribe(() => this.navigateToSignInWindow());
    };
  };

  /**
   * The user data (oxygenId, email etc) for the currently logged in user
   * @returns UserData
   */
  public get currentUserData(): UserData {
    return this._currentUserData;
  }

  public get hasUserData(): Observable<boolean> {
    return this._hasUserDataSubject.asObservable();
  }

  /**
   * The key data required for the client to run extrenal operations such as feature flags and analytics
   * @returns KeyData
   */
  public get currentKeyData(): KeyData {
    return this._currentKeyData;
  }

  public get hasKeyData(): Observable<boolean> {
    return this._hasKeyDataSubject.asObservable();
  }

  // output once oxygen user login complete
  public get apploginComplete(): Observable<boolean> {
    return this._apploginComplete.asObservable();
  }

  /**
   * Logs out the current user, navigates to the LogoutComponent and remove sessionFDMStore
   * instead of a hard browser refresh so that any guards protecting un-saved data
   * can be run
   */
  public logout() {
    this._currentAnalyticsId = null;
    this.router.navigate(['/logout']);
  }

  /**
   * Runs data parsing methods after a new login
   * e.g. gets auth data from cookie, start refresh token monitor
   * @returns Observable
   */
  public processNewLogin(authCode: string): Observable<boolean> {
    this._apploginComplete.next(true);
    return this.getToken(authCode).pipe(
      switchMap(() =>
        forkJoin([this.getUserProfile(), this.getSetupData(), this.getOxygenAnalyticsId()])
      ),
      map(() => true),
      catchError((err) => {
        this.logAuthError(err, true);
        return of(false);
      })
    );
  }

  public getAccessToken = (): Observable<string> => {
    if (this._currentAccessTokenExpires && this._currentAccessTokenExpires > Date.now()) {
      return of(this._currentAccessToken.access_token);
    } else {
      console.log(
        `Current access token expired at: ${new Date(
          this._currentAccessTokenExpires
        )}, refresh token operation running`
      );
      return this.refreshAuthToken().pipe(
        map(() => this._currentAccessToken.access_token),
        catchError((err) => {
          this.logAuthError(err, false);
          this.navigateToSignInWindow();
          return of('');
        })
      );
    }
  };

  /**
   * Checks for a valid auth session
   * If refresh token cookie exists a new token will be obtained from the server
   * If refresh token cookie not found the browser will be re-directed to login
   * @returns Observable
   */
  public isValidAuthSession(): Observable<boolean> {
    if (this._currentAccessToken) {
      return of(true);
    } else {
      this.navigateToSignInWindow();
      return of(false);
    }
  }

  /**
   * Browser re-direct to server login page
   */
  public navigateToSignInWindow() {
    window.open('/login', '_self');
  }

  public navigateToSignOutWindow() {
    this._logoutSyncChannel.postMessage(true);
    window.open('/logout', '_self');
  }

  /**
   * Refresh oauth2 access token, passes refresh token cookie to server (withCredentials switch)
   * @param  {boolean} ignoreIfExists allows skipping of refresh check if auth data in memory
   * @param  {boolean} notifyOnFailure enables user notification of auth failure
   * @returns Observable
   */
  private refreshAuthToken(): Observable<boolean> {
    return defer(() =>
      this._httpClient.get<AccessTokenResponse>(
        `/login/refresh?token=${this._currentAccessToken.refresh_token}`,
        {
          headers: { 'ngsw-bypass': 'true' },
        }
      )
    ).pipe(
      map((response: AxiosResponse<AccessTokenResponse>) => {
        this._currentAccessToken = response.data;
        this.setCurrentAccessTokenExpiry();
        return true;
      })
    );
  }

  private getToken = (authCode: string): Observable<boolean> => {
    return defer(() =>
      this._httpClient.get<AccessTokenResponse>(`/gettoken?code=${authCode}`, {
        headers: { 'ngsw-bypass': 'true' },
      })
    ).pipe(
      map((response: AxiosResponse<AccessTokenResponse>) => {
        this._currentAccessToken = response.data;
        this.setCurrentAccessTokenExpiry();
        return true;
      })
    );
  };

  /**
   * Get auth and key data from server
   * @returns Observable
   */
  private getSetupData(): Observable<boolean> {
    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        defer(() =>
          this._httpClient.get<KeyData>('/login/setup', {
            headers: {
              'ngsw-bypass': 'true',
              Authorization: `Bearer ${token}`,
            },
          })
        )
      ),
      map((response: AxiosResponse<KeyData>) => {
        this._currentKeyData = response.data;
        this._hasKeyDataSubject.next(true);
        return true;
      })
    );
  }

  /**
   * Gets the currently logged in user's profile
   * e.g. first/last name, email, oxygenId for displaying in UI
   * and for analytic collection
   * @returns Observable
   */
  private getUserProfile(): Observable<boolean> {
    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        defer(() =>
          this._httpClient.get<UserData>(this.envService.environment.accounts.userProfileUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        )
      ),
      tap((response: AxiosResponse<UserData>) => {
        this._currentUserData = response.data;
        this._hasUserDataSubject.next(true);
        // Initialize user settings
        this.userSettingsService.initializeUserSettings(this._currentUserData.userId);
      }),
      map(() => true)
    );
  }

  /**
   * Gets the current user's oxygen Id as a hashed string
   * API provided by oxygen team and is safe to use when a user Id is
   * required for storage e.g. feature flags
   * @returns Observable
   */
  public getOxygenAnalyticsId(): Observable<UserAnalytics> {
    if (this._currentAnalyticsId) return of(this._currentAnalyticsId);

    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        defer(() =>
          this._httpClient.get<UserAnalytics>(
            this.envService.environment.accounts.oxygenAnalyticsIdUrl,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      ),
      map((response: AxiosResponse<UserAnalytics>) => {
        this._currentAnalyticsId = response.data;
        return this._currentAnalyticsId;
      }),
      catchError((err) => {
        this.loggingService.logError(err);
        return of(null);
      })
    );
  }

  private logAuthError(err: any, notify: boolean) {
    this.loggingService.logError(
      err,
      notify,
      this.translate.instant(LC.NOTIFICATIONS.ERROR_AUTH_FAILED)
    );
  }

  private setCurrentAccessTokenExpiry = (): void => {
    const tokenExpiresInMs = this._currentAccessToken.expires_in * 1000 - 120000;
    this._currentAccessTokenExpires = Math.floor(Date.now() + tokenExpiresInMs);
    console.log(`Current access token expires: ${new Date(this._currentAccessTokenExpires)}`);
  };

  private warnUserCurrentSessionIsLoggedOut = (): Observable<boolean> => {
    return this.confirmationService
      .requestDisplayConfirmationDialog({
        title: this.translate.instant(LC.NOTIFICATIONS.SESSION_LOGGED_OUT.TITLE),
        message: this.translate.instant(LC.NOTIFICATIONS.SESSION_LOGGED_OUT.MESSAGE, {
          appName: AppConstants.APP_NAME,
        }),
        hideCancelButton: true,
      })
      .pipe(
        take(1),
        map((result: ConfirmationResult) => result === ConfirmationResult.OK)
      );
  };

  // setup individual http client to avoid
  // circular refs with the main resilient http service
  private setupClient(): void {
    const resilientHttpOptions: IResilientHttpOptions = {
      timeout: 60000,
      baseURL: '',
      logError: (error: AxiosError) => this.loggingService.logError(error),
      resilienceOptions: {
        retries: rc.DEFAULT_RETRY_COUNT,
      },
    };

    this._httpClient = new ResilientHttpClient(resilientHttpOptions);
  }
}
