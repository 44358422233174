import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromEntitlements from '../reducers/entitlements.reducer';
import { Entitlement } from '@models/entitlements/entitlement';

export const selectEntitlementsState = (state: FDMState) => state.applicationState.entitlements;

export const selectEntitlementsIds = createSelector(
  selectEntitlementsState,
  fromEntitlements.selectEntitlementsIds
);

export const selectEntitlementsEntities = createSelector(
  selectEntitlementsState,
  fromEntitlements.selectEntitlementsEntities
);

export const selectAllEntitlements = createSelector(
  selectEntitlementsState,
  fromEntitlements.selectAllEntitlements
);

export const selectEntitlementsTotal = createSelector(
  selectEntitlementsState,
  fromEntitlements.selectEntitlementsTotal
);

export const selectEntitlementsById = (id: string) =>
  createSelector(
    selectEntitlementsEntities,
    (EntitlementsEntities): Entitlement => EntitlementsEntities[id]
  );
