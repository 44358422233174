export abstract class MaterialSpecificationSize {
  length = 0.0;
}

export class RectangularMaterialSpecificationSize extends MaterialSpecificationSize {
  width = 0.0;
}

export class RoundMaterialSpecificationSize extends MaterialSpecificationSize {
  diameter = 0.0;
}

export class PipeworkMaterialSpecificationSize extends MaterialSpecificationSize {
  nominalDiameter = 0.0;
  insideDiameter = 0.0;
  outsideDiameter = 0.0;
}

export class ElectricalContainmentMaterialSpecificationSize extends MaterialSpecificationSize {
  width = 0.0;
  depth = 0.0;
}
