import { DataElementType } from '@constants/data-element-types';
import { ConnectorShapeType, ConnectorDomainType } from '@models/fabrication/connector-info';

export enum FabricationReferenceType {
  Relationship = 'Relationship',
  Reference = 'Reference',
}

export interface FabricationReference {
  dataType: DataElementType;
  externalId: string;
  referenceType: FabricationReferenceType;
}

export interface FabricationLockableReference {
  isLocked: boolean;
}

export interface FabricationIndexableReference {
  index: number;
}

export interface FabricationConnectorReference
  extends FabricationReference,
    FabricationLockableReference,
    FabricationIndexableReference {
  shape: ConnectorShapeType;
  domain: ConnectorDomainType;
  isUsed: boolean;
  connectivityId?: string; // only for couplings
}

export interface FabricationInsulationSpecReference
  extends FabricationReference,
    FabricationLockableReference {}

export interface FabricationSpecReference
  extends FabricationReference,
    FabricationLockableReference {}

export interface FabricationMaterialSpecReference
  extends FabricationReference,
    FabricationLockableReference {}

export interface FabricationMaterialFinishReference
  extends FabricationReference,
    FabricationLockableReference {}

export interface ReferencedData {
  name: string;
  dataType: DataElementType;
  externalId: string;
}
