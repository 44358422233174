import TextField, { TextFieldProps } from '@weave-mui/text-field';
import { FC, useEffect, useState, createElement, useRef } from 'react';
import { Subject } from 'rxjs';
import Tooltip from '@weave-mui/tooltip';

export type FABTextFieldProps = TextFieldProps & {
  value: string;
  onChange: (string) => void;
  validationFunction?: Subject<string>;
  startIcon?: any;
  endIcon?: any;
  onEndIconClick: () => void;
  tooltip?: string;
};

export const FABTextField: FC<FABTextFieldProps> = (props) => {
  const textFieldRef = useRef(null);
  const [value, setValue] = useState<string>(props.value?.toString().trim() ?? '');
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  useEffect(() => {
    if (props.value?.toString().trim() !== value.trim()) {
      setValue(props.value?.toString().trim());
    }
  }, [props.value]);

  const textFieldProps = {
    ...props,
  };

  const startAdornment = props.startIcon ? createElement(props.startIcon) : null;

  const endAdornment = props.endIcon
    ? createElement(props.endIcon, {
        onClick: props.onEndIconClick,
        style: props.onEndIconClick ? { cursor: 'pointer', pointerEvents: 'auto' } : {},
      })
    : null;

  const onChangeEvent = (event) => {
    const newValue = event.currentTarget.value as string;
    props.onChange?.(newValue.trim());
    props.validationFunction?.next(newValue.trim());
    setValue(newValue);
  };

  delete textFieldProps['validationFunction'];
  delete textFieldProps['startIcon'];
  delete textFieldProps['endIcon'];
  delete textFieldProps['onEndIconClick'];

  return (
    <Tooltip title={props.tooltip} size="small" arrow open={tooltipOpen} placement="bottom-start">
      <TextField
        ref={textFieldRef}
        {...textFieldProps}
        value={value}
        onChange={onChangeEvent}
        onBlur={(event) => {
          setValue(event.currentTarget.value.trim());
          if (!value) {
            props.onChange?.(event.currentTarget.value.trim());
          }
        }}
        onClick={() => {
          setTooltipOpen(false);
        }}
        onMouseEnter={() =>
          document.activeElement !== textFieldRef.current.querySelector('input') &&
          props.tooltip &&
          value &&
          setTooltipOpen(true)
        }
        onMouseLeave={() => setTooltipOpen(false)}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
      />
    </Tooltip>
  );
};
