import { ForgeContentDataElement } from '../forge-content/forge-content-data-element';
import { v4 as uuidv4 } from 'uuid';
import { StorageFile } from './files';

export class ServiceTemplatePalette {
  id: string;
  name: string;
  partCollections: PartCollection[] = [];
  isExcluded = false;
}

export class PartCollection {
  id: string;
  name: string;
  isExcluded: boolean;
  parts: PartReference[] = [];
  imageExternalIdOverride?: string; // the external ID to the content item with the overriden thumbnail
  updatedThumbnailStorageFile?: StorageFile; //store the storage file of the updated thumbnail, used for temporary purpose and not saved in FCS
}

export class PartReference {
  contentExternalId = '-1'; // this is the external id of the content this part references
  sizeRestrictionId: string;
  greaterThanOverride?: number;
  lessThanOrEqualOverride?: number;
  fileContent: string | null;
}

export enum ServiceTemplateSizeRestrictionOption {
  Unrestricted = 'Unrestricted',
  Value = 'Value',
}

export class ServiceTemplateSizeRestrictionValue {
  option: ServiceTemplateSizeRestrictionOption = ServiceTemplateSizeRestrictionOption.Unrestricted;
  value = 0.0; // if the option is set to Unrestricted, then the value is ignored. The value MUST be 0 or positive.
}

export class ServiceTemplateSizeRestriction {
  id: string;
  description: string;
  greaterThan: ServiceTemplateSizeRestrictionValue;
  lessThanOrEqual: ServiceTemplateSizeRestrictionValue;

  constructor() {
    this.greaterThan = new ServiceTemplateSizeRestrictionValue();
    this.lessThanOrEqual = new ServiceTemplateSizeRestrictionValue();
    this.description = 'Unrestricted';
    this.id = uuidv4();
  }
}

export class ServiceTemplateInfo extends ForgeContentDataElement {
  category = '';
  sizeRestrictions: ServiceTemplateSizeRestriction[] = [];
  palettes: ServiceTemplatePalette[] = [];

  constructor() {
    super();

    // add a default size restriction
    const sizeRestriction = new ServiceTemplateSizeRestriction();
    this.sizeRestrictions.push(sizeRestriction);

    // add a default palette
    const palette = new ServiceTemplatePalette();
    palette.id = uuidv4();
    palette.name = 'Untitled';
    this.palettes.push(palette);
  }
}
