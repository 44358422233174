import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromContentNode from '../reducers/content-node.reducer';

// folder state
export const selectContentNodeState = (state: FDMState) => state.configsState.contentNodes;

export const selectApplicationState = (state: FDMState) => state.applicationState;

// folder selectors
export const selectContentNodeIds = createSelector(
  selectContentNodeState,
  fromContentNode.selectContentNodeIds
);

export const selectContentNodeEntities = createSelector(
  selectContentNodeState,
  fromContentNode.selectContentNodeEntities
);

export const selectAllContentNodes = createSelector(
  selectContentNodeState,
  fromContentNode.selectAllContentNode
);

export const selectContentNodeTotal = createSelector(
  selectContentNodeState,
  fromContentNode.selectContentNodeTotal
);

export const selectContentNodeById = (id: string) =>
  createSelector(
    selectContentNodeEntities,
    (selectContentNodeEntities) => selectContentNodeEntities[id]
  );
