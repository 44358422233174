import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FDMState } from '@store/reducers/index';
import { Store } from '@ngrx/store';
import { withLatestFrom, mergeMap, switchMap } from 'rxjs/operators';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';
import { CacheService } from '@services/cache.service';
import { Config } from '@models/fabrication/config';
import {
  CacheDataActionTypes,
  CleanStaleConfigCacheRecords,
  TriggerCacheDataUpdate,
  UpdateCacheDataTypeRecord,
} from '@store/actions/cache-data.action';

@Injectable()
export class CacheDataEffects {
  setCacheUpdateData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CacheDataActionTypes.TriggerCacheDataUpdate),
        withLatestFrom(this.store$.select(selectCurrentConfig)),
        mergeMap((setupData: [TriggerCacheDataUpdate, Config]) => {
          const [action, config] = setupData;

          const contentItemCache$ = this.cacheService.updateForgeContentDataCache(
            config.id,
            action.payload.dataUrn,
            action.payload.cacheUpdateType,
            action.payload.response,
            action.payload.nodeId,
            'content',
            action.payload.ignoreCacheTableEntry
          );

          return contentItemCache$;
        })
      ),
    { dispatch: false }
  );

  setUpdateCacheDataTypeRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CacheDataActionTypes.UpdateCacheDataTypeRecord),
        withLatestFrom(this.store$.select(selectCurrentConfig)),
        mergeMap((setupData: [UpdateCacheDataTypeRecord, Config]) => {
          const [action, config] = setupData;
          return this.cacheService.updateCacheDataTypeRecord(
            action.payload.dataElementType,
            config.id,
            action.payload.nodeId,
            action.payload.isExternalNodeId,
            action.payload.lastActivityIdProcessed
          );
        })
      ),
    { dispatch: false }
  );

  cleanStaleConfigCacheRecords$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CacheDataActionTypes.CleanStaleConfigCacheRecords),
        switchMap((action: CleanStaleConfigCacheRecords) => {
          return this.cacheService.cleanStaleConfigCacheRecords(
            action.payload.ids,
            action.payload.clearMatchingRecords
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private cacheService: CacheService
  ) {}
}
