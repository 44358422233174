import { Injectable, OnDestroy } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { Subscription, defer } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Adp, AdpAnalytics, EventType, TrackInfo } from '@adsk/adp-web-analytics-sdk';
import { TranslateService } from '@ngx-translate/core';

// adp is served from a cdn, so is not imported as a module
// the script is referenced in the index.html base page
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
  public adp: AdpAnalytics;

  private routeChangeSubscription: Subscription = null;

  currentAnalyticsSection = '';

  public constructor(
    private envService: EnvironmentService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }

  public configureAnalytics() {
    return this.authService.hasKeyData.pipe(
      filter((hasKeyData: boolean) => hasKeyData),
      take(1),
      switchMap(() => {
        const key = this.authService.currentKeyData.analyticsKey;

        // setup analytics
        this.adp = Adp()
          .setEnv(this.envService.environment.adpEnvironment)
          .load({
            locale: this.translate.getBrowserCultureLang().replace('-', '_') ?? 'en_US',
            id: key,
            id_provider: 'appkey',
            name: this.envService.environment.cosAppMoniker,
          });

        this.listenToRouteChanges();

        return this.loadUserConsentPreferences();
      })
    );
  }

  public async showUserConsentModal(): Promise<void> {
    if (!this.adp) return;

    await this.adp.showUserConsentDialog();
  }

  public loadUserConsentPreferences() {
    return this.authService.getAccessToken().pipe(
      switchMap((token: string) => defer(() => this.adp.loadUserConsentPreferences(token))),
      map((consent) => {
        if ('status' in consent) {
          return consent;
        }

        return null;
      })
    );
  }

  public recordClickEvent(trackInfo: TrackInfo<EventType>) {
    if (this.isAnalyticsEnabled()) {
      this.adp.track('CLICK_OPERATION', trackInfo);
    }
  }

  public recordExternalOperationEvent(operation: TrackInfo<EventType>) {
    if (this.isAnalyticsEnabled()) {
      this.adp.track('BACKGROUND_CALL', operation);
    }
  }

  public recordComponentMetrics(
    id: string,
    name: string,
    type: string,
    metrics: Record<string, string>
  ): void {
    if (!this.isAnalyticsEnabled()) return;
    this.adp.track('METRICS_COMPONENT', { id, name, type, metrics });
  }

  private isAnalyticsEnabled(): boolean {
    // do not capture analytics for autodesk email accounts
    return !this.authService.currentUserData?.emailId?.toLowerCase()?.includes('@autodesk.com');
  }

  // listen to route changes in the app to determine where we are
  private listenToRouteChanges() {
    this.routeChangeSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        this.currentAnalyticsSection = data.analyticsSection;
      });
  }
}
