/* eslint-disable no-alert */
import { Injectable } from '@angular/core';
import { DebugMode } from '@models/debug-mode/debug-mode';
import { Observable, Subject } from 'rxjs';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DebugModeService {
  private debugModeChangeSubject: Subject<void> = new Subject<void>();

  constructor(private userSettingsService: UserSettingsService) {}

  public listenToDebugModeChanges(): Observable<void> {
    return this.debugModeChangeSubject.asObservable();
  }

  public get accessRoleIssues(): boolean {
    return this.userSettingsService.currentUserSettings.debugMode?.accessRoleIssues ?? false;
  }

  public get schemaIssues(): boolean {
    return this.userSettingsService.currentUserSettings.debugMode?.schemaIssues ?? false;
  }

  public get webAssemblyHeavyProcessing(): boolean {
    return (
      this.userSettingsService.currentUserSettings.debugMode?.webAssemblyHeavyProcessing ?? false
    );
  }

  public get enablePatternNumberSearch(): boolean {
    return (
      this.userSettingsService.currentUserSettings.debugMode?.enablePatternNumberSearch ?? false
    );
  }

  public get enableBinaryUpdateForMaterialSpec(): boolean {
    return (
      this.userSettingsService.currentUserSettings.debugMode?.enableBinaryUpdateForMaterialSpec ??
      false
    );
  }

  public setDebugMode(debugMode: DebugMode): void {
    if (!this.userSettingsService.currentUserSettings.debugMode) {
      this.userSettingsService.currentUserSettings.debugMode = {};
    }
    const currentDebugModeSettings = this.userSettingsService.currentUserSettings.debugMode;
    switch (debugMode) {
      case DebugMode.CouldNotGetAccessRole:
        currentDebugModeSettings.accessRoleIssues = !currentDebugModeSettings.accessRoleIssues;
        alert(
          DebugMode.CouldNotGetAccessRole +
            ' Enabled = ' +
            currentDebugModeSettings.accessRoleIssues
        );
        break;

      case DebugMode.CouldNotGetSchema:
        currentDebugModeSettings.schemaIssues = !currentDebugModeSettings.schemaIssues;
        alert(DebugMode.CouldNotGetSchema + ' Enabled = ' + currentDebugModeSettings.schemaIssues);
        break;

      case DebugMode.WebAssemblyHeavyProcessing:
        currentDebugModeSettings.webAssemblyHeavyProcessing =
          !currentDebugModeSettings.webAssemblyHeavyProcessing;
        alert(
          DebugMode.WebAssemblyHeavyProcessing +
            ' Enabled = ' +
            currentDebugModeSettings.webAssemblyHeavyProcessing
        );
        break;

      case DebugMode.EnablePatternNumberSearch:
        currentDebugModeSettings.enablePatternNumberSearch =
          !currentDebugModeSettings.enablePatternNumberSearch;
        alert(
          DebugMode.EnablePatternNumberSearch +
            ' Enabled = ' +
            currentDebugModeSettings.enablePatternNumberSearch
        );
        break;

      case DebugMode.EnableBinaryUpdateForMaterialSpec:
        currentDebugModeSettings.enableBinaryUpdateForMaterialSpec =
          !currentDebugModeSettings.enableBinaryUpdateForMaterialSpec;
        alert(
          DebugMode.EnableBinaryUpdateForMaterialSpec +
            ' Enabled = ' +
            currentDebugModeSettings.enableBinaryUpdateForMaterialSpec
        );
        break;

      default:
        break;
    }

    this.userSettingsService.saveUserSettings();
    this.debugModeChangeSubject.next();
  }
}
