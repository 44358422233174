import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import { DataElementType } from '@constants/data-element-types';
import { EnvironmentConstants } from '@constants/environment-constants';
import { kebabCase, snakeCase } from 'lodash';
import * as pluralize from 'pluralize';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { GraphConstants } from '@constants/graph-constants';

export class DataElementTypeUtils {
  public static getDataTypeFromSchema(schema: string, convertSchema = true): DataElementType {
    const schemaType = convertSchema ? this.getForgeContentSchema(schema).type : schema;

    switch (schemaType) {
      default:
        throw new Error('Unsupported schema');

      case EnvironmentConstants.FSS_SCHEMA_ROUND_CONNECTOR:
      case EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR:
      case EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR:
        return DataElementType.Connector;

      case EnvironmentConstants.FSS_SCHEMA_DAMPER:
        return DataElementType.Damper;

      case EnvironmentConstants.FSS_SCHEMA_PART:
        return DataElementType.Part;

      case EnvironmentConstants.FSS_SCHEMA_SERVICE_TEMPLATE:
        return DataElementType.ServiceTemplate;

      case EnvironmentConstants.FSS_SCHEMA_SERVICE:
        return DataElementType.Service;

      case EnvironmentConstants.FSS_SCHEMA_INSULATION_SPEC:
        return DataElementType.InsulationSpecification;

      case EnvironmentConstants.FSS_SCHEMA_PART_SPEC:
        return DataElementType.Specification;

      case EnvironmentConstants.FSS_SCHEMA_STIFFENER:
        return DataElementType.Stiffener;

      case EnvironmentConstants.FSS_SCHEMA_FABRICATION_RATE:
        return DataElementType.FabricationRate;

      case EnvironmentConstants.FSS_SCHEMA_INSTALLATION_RATE:
        return DataElementType.InstallationRate;

      case EnvironmentConstants.FSS_SCHEMA_MATERIAL:
      case EnvironmentConstants.FSS_SCHEMA_MATERIAL_FINISH:
        return DataElementType.Material;

      case EnvironmentConstants.FSS_SCHEMA_PIPEWORK_MATERIAL_SPEC:
      case EnvironmentConstants.FSS_SCHEMA_ELECTRICAL_CONTAINMENT_MATERIAL_SPEC:
      case EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_MATERIAL_SPEC:
        return DataElementType.MaterialSpecification;

      case EnvironmentConstants.FSS_SCHEMA_INVALID_DATA:
        return DataElementType.InvalidData;

      case EnvironmentConstants.FSS_SCHEMA_STIFFENER_SPEC:
        return DataElementType.StiffenerSpecification;
    }
  }

  public static getForgeContentSchema(schemaType: string): ForgeSchemaInfo {
    const namespaceSeparatorIndex = schemaType.indexOf(':');
    const versionSeparatorIndex = schemaType.indexOf('-');

    return {
      namespace: schemaType.substring(0, namespaceSeparatorIndex),
      type: schemaType.substring(namespaceSeparatorIndex + 1, versionSeparatorIndex),
      version: schemaType.substring(versionSeparatorIndex + 1),
    };
  }

  public static getDataTypeUrl(dataType: DataElementType): string[] {
    switch (dataType) {
      default:
        return [pluralize(kebabCase(dataType))];

      case DataElementType.ServiceTemplate:
        return ['service-templates'];
    }
  }

  public static getLocalisationConstantRef(dataType: DataElementType, plural: boolean): string {
    switch (dataType) {
      default:
        return `${snakeCase(dataType).toUpperCase()}${plural ? 'S' : ''}`;

      case DataElementType.ServiceTemplate:
        return `SERVICE_TEMPLATE${plural ? 'S' : ''}`;
    }
  }

  public static getStartCase(
    translate: TranslateService,
    dataType: DataElementType | GraphConstants,
    plural: boolean
  ): string {
    switch (dataType) {
      default:
        throw new Error('Data Element Type not supported: ' + dataType);

      case GraphConstants.PLACEHOLDER_GRAPH_NODE_NODATATYPE:
        return '';

      case DataElementType.Connector:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.CONNECTORS : LC.DATATYPES.TYPES.CONNECTOR
        );

      case DataElementType.Damper:
        return translate.instant(plural ? LC.DATATYPES.TITLES.DAMPERS : LC.DATATYPES.TYPES.DAMPER);

      case DataElementType.FabricationRate:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.FABRICATION_RATES : LC.DATATYPES.TYPES.FABRICATION_RATE
        );

      case DataElementType.InstallationRate:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.INSTALLATION_RATES : LC.DATATYPES.TYPES.INSTALLATION_RATE
        );

      case DataElementType.InsulationSpecification:
        return translate.instant(
          plural
            ? LC.DATATYPES.TITLES.INSULATION_SPECIFICATIONS
            : LC.DATATYPES.TYPES.INSULATION_SPECIFICATION
        );

      case DataElementType.InvalidData:
        return translate.instant(LC.DATATYPES.TITLES.INVALID_DATAS);

      case DataElementType.Material:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.MATERIALS : LC.DATATYPES.TYPES.MATERIAL
        );

      case DataElementType.MaterialSpecification:
        return translate.instant(
          plural
            ? LC.DATATYPES.TITLES.MATERIAL_SPECIFICATIONS
            : LC.DATATYPES.TYPES.MATERIAL_SPECIFICATION
        );

      case DataElementType.Part:
        return translate.instant(plural ? LC.DATATYPES.TITLES.PARTS : LC.DATATYPES.TYPES.PART);

      case DataElementType.Service:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.SERVICES : LC.DATATYPES.TYPES.SERVICE
        );

      case DataElementType.ServiceTemplate:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.SERVICE_TEMPLATES : LC.DATATYPES.TYPES.SERVICE_TEMPLATE
        );

      case DataElementType.Specification:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.SPECIFICATIONS : LC.DATATYPES.TYPES.SPECIFICATION
        );

      case DataElementType.Stiffener:
        return translate.instant(
          plural ? LC.DATATYPES.TITLES.STIFFENERS : LC.DATATYPES.TYPES.STIFFENER
        );

      case DataElementType.StiffenerSpecification:
        return translate.instant(
          plural
            ? LC.DATATYPES.TITLES.STIFFENER_SPECIFICATIONS
            : LC.DATATYPES.TYPES.STIFFENER_SPECIFICATION
        );
    }
  }
}
