import * as fromItemFolderTree from '../actions/item-folder-tree.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ItemFolderTreeData } from '@models/ui-state/item-folder-tree-data';

export interface ItemFolderTreeState extends EntityState<ItemFolderTreeData> {} // eslint-disable-line

export const itemFolderTreeAdapter: EntityAdapter<ItemFolderTreeData> =
  createEntityAdapter<ItemFolderTreeData>({
    selectId: (itemFolderTreeData: ItemFolderTreeData) => itemFolderTreeData.id,
  });

export const initialState: ItemFolderTreeState = itemFolderTreeAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromItemFolderTree.ItemFolderTreeActions
): ItemFolderTreeState {
  switch (action.type) {
    case fromItemFolderTree.ItemFolderTreeActionTypes.UpsertItemFolderTreeData: {
      return itemFolderTreeAdapter.upsertOne(action.payload, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of ItemFolderTreeData ids
  selectIds: selectItemFolderTreeDataIds,

  // select the dictionary of ItemFolderTreeData entities
  selectEntities: selectItemFolderTreeDataEntities,

  // select the array of ItemFolderTreeData
  selectAll: selectAllItemFolderTreeData,

  // select the total ItemFolderTreeData count
  selectTotal: selectItemFolderTreeDataTotal,
} = itemFolderTreeAdapter.getSelectors();
