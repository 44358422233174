import * as fromBinaryActivity from '../actions/binary-activity.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BinaryActivityMarker } from '@models/activities-events/activities';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BinaryActivityState extends EntityState<BinaryActivityMarker> {}

export const binaryActivityAdapter: EntityAdapter<BinaryActivityMarker> =
  createEntityAdapter<BinaryActivityMarker>({
    selectId: (marker: BinaryActivityMarker) => marker.objectKey,
  });

export const initialState: BinaryActivityState = binaryActivityAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromBinaryActivity.BinaryActivityActions
): BinaryActivityState {
  switch (action.type) {
    case fromBinaryActivity.BinaryActivityActionTypes.UpsertBinaryActivityMarker: {
      return binaryActivityAdapter.upsertOne(action.payload.marker, state);
    }
    case fromBinaryActivity.BinaryActivityActionTypes.DeleteBinaryActivityMarker: {
      return binaryActivityAdapter.removeOne(action.payload.objectKey, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectBinaryActivityIds,
  selectEntities: selectBinaryActivityEntities,
  selectAll: selectAllBinaryActivity,
  selectTotal: selectBinaryActivityTotal,
} = binaryActivityAdapter.getSelectors();
