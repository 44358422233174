import LinearProgress, {
  LinearProgressProps,
  linearProgressVariants,
} from '@weave-mui/linear-progress';
import React from 'react';

export type FABLinearProgressProps = LinearProgressProps;

export const FABLinearProgress: React.FC<FABLinearProgressProps> = (props) => {
  // set determinate progress bar if value is passed
  if (props.value) {
    return <LinearProgress {...props} variant={linearProgressVariants.DETERMINATE} />;
  }

  return <LinearProgress {...props} />;
};
