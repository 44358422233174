import { Action } from '@ngrx/store';
import { CacheTableEntry, CacheUpdateType } from '@models/cache/cache';
import { DataElementType } from '@constants/data-element-types';
import { ContentItem } from '@adsk/content-sdk';

export enum CacheDataActionTypes {
  UpsertCacheTableData = '[Cache Data Api] Upsert Cache Table Data',
  DeleteCacheTableData = '[Cache Data Api] Delete Cache Data',
  TriggerCacheDataUpdate = '[Cache Data Api] Trigger Cache Data Update',
  UpdateCacheDataTypeRecord = '[Cache Data Api] Update Cache Data Type Record',
  CleanStaleConfigCacheRecords = '[Cache Data Api] Clean State Config Cache Records',
}

export class UpsertCacheTableData implements Action {
  readonly type = CacheDataActionTypes.UpsertCacheTableData;

  constructor(public payload: { data: CacheTableEntry[] }) {}
}

export class DeleteCacheTableData implements Action {
  readonly type = CacheDataActionTypes.DeleteCacheTableData;

  constructor(public payload: { ids: string[] }) {}
}

export class TriggerCacheDataUpdate implements Action {
  readonly type = CacheDataActionTypes.TriggerCacheDataUpdate;

  constructor(
    public payload: {
      dataElementType: DataElementType;
      dataUrn: string;
      cacheUpdateType: CacheUpdateType;
      response: ContentItem;
      nodeId: string;
      isExternalNodeId: boolean;
      ignoreCacheTableEntry?: boolean; // choose to ignore the CacheTableEntry - these can be invalid when we are doing upgrades
    }
  ) {}
}

export class UpdateCacheDataTypeRecord implements Action {
  readonly type = CacheDataActionTypes.UpdateCacheDataTypeRecord;

  constructor(
    public payload: {
      dataElementType: DataElementType;
      nodeId: string;
      isExternalNodeId: boolean;
      lastActivityIdProcessed: string;
    }
  ) {}
}

export class CleanStaleConfigCacheRecords implements Action {
  readonly type = CacheDataActionTypes.CleanStaleConfigCacheRecords;

  // array of config urns
  constructor(
    public payload: {
      ids: string[];
      clearMatchingRecords: boolean;
    }
  ) {}
}

export type CacheDataActions =
  | UpsertCacheTableData
  | DeleteCacheTableData
  | TriggerCacheDataUpdate
  | UpdateCacheDataTypeRecord
  | CleanStaleConfigCacheRecords;
