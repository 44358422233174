export class ArrayUtils {
  /**
   * Comparare string arrays and get difference between both
   * Note: This was necessary because lodash.difference does not work as expected
   * @param a1 string[] initial array to compare
   * @param a2 string[] secondary array to compare
   */
  static differenceArrayString(a1: string[], a2: string[]): string[] {
    const a = [];
    const diff = [];

    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    //eslint-disable-next-line no-restricted-syntax
    for (const k in a) {
      diff.push(k);
    }

    return diff;
  }

  static moveElement(arr: unknown[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
}
