export const elementIdDictionary = {
  systemInfo: {
    editServiceInfoButton: 'edit-service-info-button',
    viewSystemNameValue: 'system-name-value',
    viewSystemGroupNameValue: 'system-group-value',
    viewSystemTemplateNameValue: 'system-template-name-value',
    viewSystemSpecNameValue: 'system-spec-name-value',
    viewSystemInsSpecNameValue: 'system-ins-spec-name-value',
  },
  systemEdit: {
    cancelServiceEditButton: 'cancel-service-edit-button',
    updateServiceEditButton: 'update-service-edit-button',
    editServiceNameTextField: 'edit-service-name-text-field',
    editServiceGroupTextField: 'edit-service-group-text-field',
    partTemplateNameDropDown: 'edit-service-part-template-name-dropdown',
    specificationDropDown: 'edit-service-spec-dropdown',
    useSpecCheckBox: 'edit-service-use-spec-checkbox',
    insulationSpecDropDown: 'edit-service-insulation-spec-dropdown',
    useInsulationSpecCheckBox: 'edit-service-use-insulation-spec-checkbox',
  },
  systemList: {
    addSystemButton: 'service-list-btn-add',
    copySystemButton: 'service-list-btn-copy',
    editSystemButton: 'service-list-btn-edit',
    deleteSystemButton: 'service-list-btn-delete',
    filterSystemGroupsDropDown: 'filter-system-groups-dropdown',
    searchField: 'search-systems',
    modalServiceNameTextField: 'modal-service-name-text-field',
    modalServiceGroupNameTextField: 'modal-service-group-name-text-field',
  },
  connectorInfo: {
    editConnectorInfoButton: 'edit-connector-info-button',
    viewConnectorInfoNameValue: 'connector-name-value',
    viewConnectorInfoGroupValue: 'connector-group-value',
    viewConnectorInfoDomainValue: 'connector-domain-value',
    viewConnectorInfoShapeValue: 'connector-shape-value',
    viewConnectorInfoGenderValue: 'connector-gender-value',
    viewConnectorInfoConnectivityValue: 'connector-connectivity-value',
  },
  connectorEdit: {
    cancelConnectorEditButton: 'cancel-connector-edit-button',
    updateConnectorEditButton: 'update-connector-edit-button',
    editEconnectorNameTextField: 'edit-connector-name-text-field',
    editConnectorGroupTextField: 'edit-connector-group-text-field',
    breakPointTable: 'connector-break-point-table',
  },
  connectorList: {
    addConnectorButton: 'add-connector-button',
    editConnectorButton: 'edit-connector-button',
    deleteConnectorButton: 'delete-connector-button',
    filterConnectorGroupsDropDown: 'filter-connector-groups-dropdown',
    searchField: 'search-connectors',
    modalConnectorNameTextField: 'modal-connector-name-text-field',
    modalConnectorGroupNameTextField: 'modal-connector-group-name-text-field',
  },
  damperInfo: {
    editDamperInfoButton: 'edit-damper-info-button',
    viewDamperInfoNameValue: 'damper-name-value',
    viewDamperInfoAdjustValue: 'damper-adjust-value',
    viewDamperInfoInsetValue: 'damper-inset-value',
    viewDamperInfoHoleAngleValue: 'damper-hole-angle-value',
    viewDamperInfoHandleExtensionValue: 'damper-handle-extension-value',
    viewDamperInfoGaugeValue: 'damper-gauge-value',
    viewDamperInfoControlTypeValue: 'damper-control-type-value',
    viewDamperInfoIsMachineCutValue: 'damper-is-machine-cut-value',
    viewDamperInfoIsDisplayedValue: 'damper-is-displayed-value',
  },
  damperEdit: {
    cancelServiceEditButton: 'cancel-damper-edit-button',
    updateServiceEditButton: 'update-damper-edit-button',
    editServiceNameTextField: 'edit-damper-name-text-field',
    editServiceGroupTextField: 'edit-damper-group-text-field',
  },
  damperList: {
    addDamperButton: 'add-damper-button',
    editDamperButton: 'edit-damper-button',
    deleteDamperButton: 'delete-damper-button',
    filterDamperGroupsDropDown: 'filter-damper-groups-dropdown',
    searchField: 'search-dampers',
    modalDamperNameTextField: 'modal-damper-name-text-field',
    modalDamperGroupNameTextField: 'modal-damper-group-name-text-field',
  },
  stiffenerInfo: {
    editStiffenerInfoButton: 'edit-stiffener-info-button',
    viewStiffenerInfoNameValue: 'stiffener-name-value',
    viewStiffenerInfoGroupValue: 'stiffener-group-value',
    viewStiffenerInfoCentralTypeValue: 'stiffener-central-type-value',
    viewStiffenerInfoEndTypeValue: 'stiffener-end-type-value',
    viewStiffenerInfoLongAdjustValue: 'stiffener-long-adjust-value',
    viewStiffenerInfoShortAdjustValue: 'stiffener-short-adjust-value',
  },
  stiffenerEdit: {
    cancelServiceEditButton: 'cancel-stiffener-edit-button',
    updateServiceEditButton: 'update-stiffener-edit-button',
    editServiceNameTextField: 'edit-stiffener-name-text-field',
    editServiceGroupTextField: 'edit-stiffener-group-text-field',
  },
  stiffenerList: {
    addStiffenerButton: 'add-stiffener-button',
    editStiffenerButton: 'edit-stiffener-button',
    deleteStiffenerButton: 'delete-stiffener-button',
    filterStiffenerGroupsDropDown: 'filter-stiffener-groups-dropdown',
    searchField: 'search-stiffeners',
    modalStiffenerNameTextField: 'modal-stiffener-name-text-field',
    modalStiffenerGroupNameTextField: 'modal-stiffener-group-name-text-field',
  },
  partTemplateInfo: {
    editPartCollection: 'edit-part-collection',
    palettesDropDown: 'part-template-detail-palettes-dropdown',
    sizeRestrictionsDropDown: 'part-template-info-size-restrictions-dropdown',
    partTemplateInfoPartsDiv: 'part-template-info-parts-div',
  },
  partTemplateEdit: {
    cancelPartTemplateEditButton: 'cancel-part-template-edit-button',
    updatePartTemplateEditButton: 'update-part-template-edit-button',
    editPartTemplateDetailsNameTextField: 'edit-part-template-details-name-text-field',
    editPartTemplateDetailsGroupTextField: 'edit-part-template-details-group-text-field',
    editPartTemplateTabControl: 'edit-part-template-tab-control',
    palettesTableDataControl: 'palettes-table-data',
    sizeRestrictionsTableDataControl: 'size-restrictions-table-data',
    addPaletteButton: 'part-template-palette-list-btn-add',
    deletePaletteButton: 'part-template-palette-list-btn-delete',
    movePaletteUpButton: 'part-template-palette-list-btn-up',
    movePaletteDownButton: 'part-template-palette-list-btn-down',
    editPaletteButton: 'part-template-palette-list-btn-edit',
    modalPaletteNameTextField: 'modal-palette-name-text-field',
    editRestrictionsButton: 'part-template-size-restriction-list-btn-edit',
    addRestrictionButton: 'part-template-size-restriction-list-btn-add',
    deleteRestrictionButton: 'part-template-size-restriction-list-btn-delete',
    modalRestrictionNameTextField: 'modal-restriction-name-text-field',
    modalRestrictionGreaterThanField: 'modal-restriction-greaterthan-text-field',
    modalRestrictionGreaterThanCheckBox: 'modal-restriction-greaterthan-checkbox',
    modalRestrictionGreaterThanInvalid: 'modal-restriction-greaterthan-err-invalid',
    modalRestrictionLessThanField: 'modal-restriction-lessthan-text-field',
    modalRestrictionLessThanCheckBox: 'modal-restriction-lessthan-checkbox',
    modalRestrictionLessThanInvalid: 'modal-restriction-lessthan-err-invalid',
    modalRestrictionForm: 'modal-restriction-form',
    modalRestrictionOkButton: 'modal-restriction-ok-button',
    modalRestrictionCancelButton: 'modal-restriction-cancel-button',
  },
  partTemplateList: {
    tableDataControl: 'part-templates-list-table-data',
    addPartTemplateButton: 'part-template-list-btn-add',
    copyPartTemplateButton: 'part-template-list-btn-copy',
    editPartTemplateButton: 'part-template-list-btn-edit',
    deletePartTemplateButton: 'part-template-list-btn-delete',
    filterPartTemplateGroupsDropDown: 'part-template-groups-filter-dropdown',
    searchField: 'search-part-templates',
    modalPartTemplateNameTextField: 'modal-part-template-name-text-field',
    modalPartTemplateGroupNameTextField: 'modal-part-template-group-name-text-field',
  },
  higGlobalNav: {
    configSwitcher: 'config-switcher',
  },
  notificationBar: {
    notificationBar: 'notification-bar',
    notificationBarCloseButton: 'close-notification-bar-button',
  },
};
