import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HigFormFieldComponent } from './form-field/form-field.component';
import { WeaveSelectComponent } from './select/select.component';
import { HigIconComponent } from './icon/icon.component';
import { WeaveBadgeComponent } from './badge/badge.component';
import { ReactComponentModule } from '@react/react.module';

@NgModule({
  imports: [CommonModule, ReactComponentModule],
  declarations: [
    HigFormFieldComponent,
    WeaveSelectComponent,
    HigIconComponent,
    WeaveBadgeComponent,
  ],
  exports: [HigFormFieldComponent, WeaveSelectComponent, HigIconComponent, WeaveBadgeComponent],
  schemas: [],
})
export class HigComponentModule {}
