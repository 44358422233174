import * as fromExtraDataElementInfo from '../actions/extra-data-element-info.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExtraDataElementInfo } from '@models/extra-data-element-info/extra-data-element-info';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExtraDataElementInfoState extends EntityState<ExtraDataElementInfo> {}

export const ExtraDataElementInfoAdapter: EntityAdapter<ExtraDataElementInfo> =
  createEntityAdapter<ExtraDataElementInfo>({
    selectId: (data: ExtraDataElementInfo) => data.id,
  });

export const initialState: ExtraDataElementInfoState =
  ExtraDataElementInfoAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromExtraDataElementInfo.ExtraDataElementInfoActions
): ExtraDataElementInfoState {
  switch (action.type) {
    case fromExtraDataElementInfo.ExtraDataElementInfoActionTypes.LoadInterdependentReferences: {
      return state;
    }
    case fromExtraDataElementInfo.ExtraDataElementInfoActionTypes
      .LoadInterdependentReferencesSuccess: {
      const data: ExtraDataElementInfo = {
        id: action.payload.id,
        interdependentReferenceIds: action.payload.interdependentReferenceIds,
        isSuccess: true,
      };

      return ExtraDataElementInfoAdapter.upsertOne(data, state);
    }

    case fromExtraDataElementInfo.ExtraDataElementInfoActionTypes
      .LoadInterdependentReferencesFail: {
      const data: ExtraDataElementInfo = {
        id: action.payload.id,
        interdependentReferenceIds: [],
        isSuccess: false,
      };
      return ExtraDataElementInfoAdapter.upsertOne(data, state);
    }

    case fromExtraDataElementInfo.ExtraDataElementInfoActionTypes.DeleteInterdependentReferences: {
      return ExtraDataElementInfoAdapter.removeOne(action.payload.id, state);
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectExtraDataElementInfoIds,
  selectEntities: selectExtraDataElementInfoEntities,
  selectAll: selectAllExtraDataElementInfos,
  selectTotal: selectExtraDataElementInfoTotal,
} = ExtraDataElementInfoAdapter.getSelectors();
