<div class="global-nav-container" *ngIf="navigationData">
  <div class="fdm__global-nav">
    <hig-side-nav [header]="sideNavHeader" [links]="sideNavLinks" [groups]="sideNavGroups">
    </hig-side-nav>
    <div
      class="fdm__global-nav__container"
      [ngClass]="{
        sidenav_open: sideNavOpened && navigationData,
        'learning-panel_open': navigationData && showLearningPanel && learningPanelOpened
      }"
    >
      <hig-top-nav
        [isLoading]="isLoading"
        [projects]="projects"
        [defaultProject]="defaultProject"
        [activeProject]="activeProject"
        [profileInfo]="profileInfo"
        [profileSettingsLink]="profileSettingsLink"
        [sideNavOpened]="sideNavOpened"
        [helpItems]="helpItems"
        [notifications]="notifications"
        (onClickSignOut)="handleOnClickSignOut($event)"
        (onChangeProject)="handleOnChangeProject($event)"
        (onClickHamburger)="handleOnClickHamburger()"
        (onClickLogo)="handleOnClickLogo()"
      >
      </hig-top-nav>
      <fab-loading></fab-loading>
      <div class="fdm__global-nav__slot">
        <div class="hig__container">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <hig-learn-nav
      *ngIf="showLearningPanel"
      [hidden]="!learningPanelOpened"
      (onClose)="handleToggleLearningPanel()"
    >
    </hig-learn-nav>
    <div
      *ngIf="showLearningPanel"
      [hidden]="learningPanelOpened"
      class="fdm__global-nav__learning-button"
      (click)="handleToggleLearningPanel()"
    >
      ?
    </div>
  </div>
</div>
