import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromMaterials from '../reducers/material.reducer';

// material state
export const selectMaterialState = (state: FDMState) => state.configsState.materials;

// material selectors
export const selectMaterialIds = createSelector(
  selectMaterialState,
  fromMaterials.selectMaterialIds
);

export const selectMaterialEntities = createSelector(
  selectMaterialState,
  fromMaterials.selectMaterialEntities
);

export const selectAllMaterials = createSelector(
  selectMaterialState,
  fromMaterials.selectAllMaterials
);

export const selectMaterialsTotal = createSelector(
  selectMaterialState,
  fromMaterials.selectMaterialsTotal
);

export const selectMaterialById = (id: string) =>
  createSelector(selectMaterialEntities, (materialEntities) => materialEntities[id]);
