import * as fromFullScreenMode from '../actions/fullscreen-mode.action';

export interface FullScreenModeState {
  enabled: boolean;
}

export const initialState: FullScreenModeState = {
  enabled: false,
};

export function reducer(
  state = initialState,
  action: fromFullScreenMode.FullScreenModeActions
): FullScreenModeState {
  switch (action.type) {
    case fromFullScreenMode.FullScreenActionTypes.EnableFullScreenMode: {
      return {
        ...state,
        enabled: true,
      };
    }
    case fromFullScreenMode.FullScreenActionTypes.DisableFullScreenMode: {
      return {
        ...state,
        enabled: false,
      };
    }
    default:
      return state;
  }
}

//selectors
export const isFullScreenEnabled = (state: FullScreenModeState) => state.enabled;
