import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { StiffenerSpecificationInfo } from '@models/fabrication/stiffener-specification-info';
import { EnvironmentConstants } from '@constants/environment-constants';
import { map, take } from 'rxjs/operators';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfigStiffenerSpecificationInfos,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import { selectStiffenerSpecById } from '@store/selectors/stiffener-spec.selectors';
import {
  LoadStiffenerSpecificationInfos,
  LoadStiffenerSpecificationInfosSuccess,
  AddStiffenerSpecificationInfo,
  AddStiffenerSpecificationInfoSuccess,
  UpdateStiffenerSpecificationInfo,
  UpdateStiffenerSpecificationInfoSuccess,
  DeleteStiffenerSpecificationInfos,
  DeleteStiffenerSpecificationInfosSuccess,
  CopyStiffenerSpecificationInfo,
} from '@store/actions/stiffener-spec.action';
import { UpdateConfigStiffenerSpecificationIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import helpLinks from '@assets/help/help-links.json';
import { Observable, of } from 'rxjs';

@Injectable()
export class DynamicStiffenerSpecificationInfoSetup extends DynamicDataElementTypeSetup<StiffenerSpecificationInfo> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<StiffenerSpecificationInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.stiffenerSpecs;
  }

  setupOptions() {
    const stiffenerSpecSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_STIFFENER_SPEC_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_STIFFENER_SPEC,
    };

    this.options = {
      dataType: DataElementType.StiffenerSpecification,
      dependentDataTypes: [],
      createNewInstance: () => {
        return new StiffenerSpecificationInfo();
      },
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigStiffenerSpecificationInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectStiffenerSpecById(id)),
      },
      actions: {
        loadAllAction: (config: Config) =>
          this.store$.dispatch(new LoadStiffenerSpecificationInfos({ config })),
        loadSuccessAction: () => new LoadStiffenerSpecificationInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteStiffenerSpecificationInfosSuccess(),
        addDataSuccessAction: () => new AddStiffenerSpecificationInfoSuccess(),
        updateDataSuccessAction: () => new UpdateStiffenerSpecificationInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigStiffenerSpecificationIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: StiffenerSpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddStiffenerSpecificationInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        editModelAction: (model: StiffenerSpecificationInfo) =>
          this.store$.dispatch(new UpdateStiffenerSpecificationInfo({ dataElement: model })),
        copyModelAction: (model: StiffenerSpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyStiffenerSpecificationInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        deleteModelsAction: (models: StiffenerSpecificationInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new DeleteStiffenerSpecificationInfos({ config, dataElements: models })
              )
            );
        },
        fixModelAction: (model: StiffenerSpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.StiffenerSpecification,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_STIFFENER_SPEC,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_STIFFENER_SPEC_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS,
                  addSuccessAction: new AddStiffenerSpecificationInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_STIFFENER_SPECS,
        schemas: [
          {
            dataType: DataElementType.StiffenerSpecification,
            schema: stiffenerSpecSchema,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<StiffenerSpecificationInfo>> {
    const stiffenerSpecTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'status',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.STIFFENER_SPECIFICATIONS.STATUS),
        visible: true,
      },
    ];
    return of(this.createDynamicTableOptions(stiffenerSpecTableColumns));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<StiffenerSpecificationInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: StiffenerSpecificationInfo) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          dropdownTypeaheadFields: [
            {
              key: 'category',
              options: categories,
            },
          ],
          formStyle: DynamicFormStyle.SIMPLE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category'],
      isReplaceable: true,
      isRemovable: true,
      isEditable: true,
      upstreamReferenceDataTypes: () => [],
      clusterIcon: 'file-assembly16',
      isDownstreamRefBlocked: () => true,
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return 'file-assembly';
  }

  isFixable(): boolean {
    return true;
  }

  getInvalidDataErrors(model: StiffenerSpecificationInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(
      DataElementType.StiffenerSpecification
    );
    return this.getStandardInvalidDataError(DataElementType.StiffenerSpecification, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: StiffenerSpecification*/): boolean {
    return false;
  }
}
