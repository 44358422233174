import { Injectable } from '@angular/core';
import { AppDependencyType } from '@constants/app-dependency-types';
import { AppDependency } from '@models/app-dependencies/app-dependencies';
import { WebAssemblyService } from '@services/web-assembly/web-assembly.service';
import { ForgeUnitsService } from './data-services/forge-units.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicAppDependencyService {
  private dynamicAppDependencyMap: Map<AppDependencyType, AppDependency> = new Map<
    AppDependencyType,
    AppDependency
  >();

  constructor(
    private webAssemblyService: WebAssemblyService,
    private forgeUnitsService: ForgeUnitsService
  ) {
    this.createDynamicAppDependencyMap();
  }

  getDynamicAppDependencyForType(appDependencyType: AppDependencyType): AppDependency | null {
    return (
      (this.dynamicAppDependencyMap.has(appDependencyType) &&
        this.dynamicAppDependencyMap.get(appDependencyType)) ||
      null
    );
  }

  private createDynamicAppDependencyMap(): void {
    this.dynamicAppDependencyMap.set(AppDependencyType.WebAssembly, this.webAssemblyService);
    this.dynamicAppDependencyMap.set(AppDependencyType.ForgeUnits, this.forgeUnitsService);
  }
}
