import { createSelector } from '@ngrx/store';
import { ExtraDataElementInfo } from '@models/extra-data-element-info/extra-data-element-info';
import { FDMState } from '../reducers';
import * as fromExtraDataElementInfo from '../reducers/extra-data-element-info.reducer';

export const selectExtraDataElementInfoState = (state: FDMState) =>
  state.configsState.extraDataElementInfos;

export const selectExtraDataElementInfoIds = createSelector(
  selectExtraDataElementInfoState,
  fromExtraDataElementInfo.selectExtraDataElementInfoIds
);

export const selectExtraDataElementInfoEntities = createSelector(
  selectExtraDataElementInfoState,
  fromExtraDataElementInfo.selectExtraDataElementInfoEntities
);

export const selectAllExtraDataElementInfos = createSelector(
  selectExtraDataElementInfoState,
  fromExtraDataElementInfo.selectAllExtraDataElementInfos
);

export const selectExtraDataElementInfoTotal = createSelector(
  selectExtraDataElementInfoState,
  fromExtraDataElementInfo.selectExtraDataElementInfoTotal
);

export const selectExtraDataElementInfoById = (id: string) =>
  createSelector(
    selectExtraDataElementInfoEntities,
    (ExtraDataElementInfoEntities): ExtraDataElementInfo => ExtraDataElementInfoEntities[id]
  );
