import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromRates from '../reducers/fabrication-rate.reducer';

// fabrication rate list state
export const selectFabricationRateState = (state: FDMState) => state.configsState.fabricationRates;

// fabrication rate selectors
export const selectFabricationRateIds = createSelector(
  selectFabricationRateState,
  fromRates.selectFabricationRateIds
);

export const selectFabricationRateEntities = createSelector(
  selectFabricationRateState,
  fromRates.selectFabricationRateEntities
);

export const selectAllFabricationRates = createSelector(
  selectFabricationRateState,
  fromRates.selectAllFabricationRates
);

export const selectFabricationRatesTotal = createSelector(
  selectFabricationRateState,
  fromRates.selectFabricationRatesTotal
);

export const selectFabricationRateById = (id: string) =>
  createSelector(
    selectFabricationRateEntities,
    (fabricationRateEntities) => fabricationRateEntities[id]
  );
