import { DataElementType } from '@constants/data-element-types';

export enum CacheUpdateType {
  Add,
  Update,
  Delete,
}

/**
 * Store entry of data id mapped to the corresponding page number
 * the response is located in, used to optimise the searching and
 * invalidation of cached data entries
 * @export
 * @interface CacheTableEntry
 */
export interface CacheTableEntry {
  id: string;
  pageNumber: number;
}

export interface CacheIdentityRecord {
  userId: string;
  activitySubmissionId: string;
}

export interface CacheDataTypeRecord {
  lastActivityIdProcessed: string;
  dataType: DataElementType;
  nodeId: string;
  isExternalNodeId: boolean;
}
