import { ConnectorInfo } from '@models/fabrication/connector-info';
import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsSuccessAction,
  LoadDataElementsFailAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsSuccessAction,
  DeleteDataElementsFailAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';
import { JSONSchema7 } from 'json-schema';

const dataType = DataElementType.Connector;

export enum ConnectorInfoActionTypes {
  LoadConnectorInfos = '[ConnectorInfo API] Load ConnectorInfos',
  LoadConnectorInfosFail = '[ConnectorInfo API] Load ConnectorInfos Fail',
  LoadConnectorInfosSuccess = '[ConnectorInfo API] Load ConnectorInfos Success',
  UpdateConnectorInfo = '[Connector Info API] Update ConnectorInfo',
  UpdateConnectorInfoFail = '[Connector Info API] Update ConnectorInfo Fail',
  UpdateConnectorInfoSuccess = '[Connector Info API] Update ConnectorInfo Success',
  AddConnectorInfo = '[Connector Info API] Add ConnectorInfo',
  AddConnectorInfoFail = '[Connector Info API] Add ConnectorInfo Fail',
  AddConnectorInfoSuccess = '[Connector Info API] Add ConnectorInfo Success',
  DeleteConnectorInfos = '[Connector Info API] Delete ConnectorInfos',
  DeleteConnectorInfosFail = '[Connector Info API] Delete ConnectorInfos Fail',
  DeleteConnectorInfosSuccess = '[Connector Info API] Delete ConnectorInfos Success',
  CopyConnectorInfo = '[Connector Info API] Copy ConnectorInfo',
  CopyConnectorInfoFail = '[Connector Info API] Copy ConnectorInfo Fail',
  CopyConnectorInfoSuccess = '[Connector Info API] Copy ConnectorInfo Success',
}

export class LoadConnectorInfos implements LoadDataElementsAction<ConnectorInfo> {
  config: Config;
  successAction = new LoadConnectorInfosSuccess();
  failAction = new LoadConnectorInfosFail();
  dataLoadingState = DataLoadingState.Loading;
  readonly type = ConnectorInfoActionTypes.LoadConnectorInfos;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadConnectorInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConnectorInfoActionTypes.LoadConnectorInfosFail;
}

export class LoadConnectorInfosSuccess implements LoadDataElementsSuccessAction<ConnectorInfo> {
  dataLoadingState = DataLoadingState.Complete;
  data: ConnectorInfo[];
  readonly type = ConnectorInfoActionTypes.LoadConnectorInfosSuccess;
}

export class UpdateConnectorInfo implements UpdateDataElementAction<ConnectorInfo> {
  dataElement: ConnectorInfo;
  successAction = new UpdateConnectorInfoSuccess();
  failAction = new UpdateConnectorInfoFail();
  dataLoadingState = DataLoadingState.Loading;
  readonly type = ConnectorInfoActionTypes.UpdateConnectorInfo;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(public payload: { dataElement: ConnectorInfo; schema: JSONSchema7 }) {
    this.dataElement = payload.dataElement;
    this.schema = payload.schema;
  }
}

export class UpdateConnectorInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConnectorInfoActionTypes.UpdateConnectorInfoFail;
}

export class UpdateConnectorInfoSuccess implements UpdateDataElementSuccessAction<ConnectorInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ConnectorInfo;
  readonly type = ConnectorInfoActionTypes.UpdateConnectorInfoSuccess;
}

export class AddConnectorInfo implements AddDataElementAction<ConnectorInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: ConnectorInfo;
  successAction = new AddConnectorInfoSuccess();
  failAction = new AddConnectorInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = ConnectorInfoActionTypes.AddConnectorInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(public payload: { config: Config; dataElement: ConnectorInfo; schema: JSONSchema7 }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.schema = payload.schema;
  }
}

export class AddConnectorInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConnectorInfoActionTypes.AddConnectorInfoFail;
}

export class AddConnectorInfoSuccess implements AddDataElementSuccessAction<ConnectorInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ConnectorInfo;
  readonly type = ConnectorInfoActionTypes.AddConnectorInfoSuccess;
}

export class CopyConnectorInfo implements CopyDataElementAction<ConnectorInfo> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: ConnectorInfo;
  successAction = new CopyConnectorInfoSuccess();
  failAction = new CopyConnectorInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = ConnectorInfoActionTypes.CopyConnectorInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS;
  isExternalNodeId = true;
  schema: JSONSchema7;

  constructor(public payload: { config: Config; dataElement: ConnectorInfo; schema: JSONSchema7 }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
    this.schema = payload.schema;
  }
}

export class CopyConnectorInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConnectorInfoActionTypes.CopyConnectorInfoFail;
}

export class CopyConnectorInfoSuccess implements CopyDataElementSuccessAction<ConnectorInfo> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: ConnectorInfo;
  readonly type = ConnectorInfoActionTypes.CopyConnectorInfoSuccess;
}

export class DeleteConnectorInfos implements DeleteDataElementsAction<ConnectorInfo> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: ConnectorInfo[];
  successAction = new DeleteConnectorInfosSuccess();
  failAction = new DeleteConnectorInfosFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS;
  isExternalNodeId = true;
  readonly type = ConnectorInfoActionTypes.DeleteConnectorInfos;

  constructor(public payload: { config: Config; dataElements: ConnectorInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteConnectorInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = ConnectorInfoActionTypes.DeleteConnectorInfosFail;
}

export class DeleteConnectorInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = ConnectorInfoActionTypes.DeleteConnectorInfosSuccess;
}

export type ConnectorInfoActions =
  | LoadConnectorInfos
  | LoadConnectorInfosFail
  | LoadConnectorInfosSuccess
  | UpdateConnectorInfo
  | UpdateConnectorInfoFail
  | UpdateConnectorInfoSuccess
  | AddConnectorInfo
  | AddConnectorInfoFail
  | AddConnectorInfoSuccess
  | DeleteConnectorInfos
  | DeleteConnectorInfosFail
  | DeleteConnectorInfosSuccess
  | CopyConnectorInfo
  | CopyConnectorInfoFail
  | CopyConnectorInfoSuccess;
