import { Action } from '@ngrx/store';
import { Entitlement } from '@models/entitlements/entitlement';

export enum EntitlementActionTypes {
  EntitlementsLoadForCurrentUser = '[Entitlements Api] Load Current User Entitlement',
  EntitlementsLoadForCurrentUserSuccess = '[Entitlements Api] Load Current User Entitlement Success',
  EntitlementsLoadForCurrentUserFail = '[Entitlements Api] Load Current User Entitlement Fail',
}

export class EntitlementsLoadForCurrentUser implements Action {
  readonly type = EntitlementActionTypes.EntitlementsLoadForCurrentUser;
}

export class EntitlementsLoadForCurrentUserSuccess implements Action {
  readonly type = EntitlementActionTypes.EntitlementsLoadForCurrentUserSuccess;

  constructor(
    public payload: {
      entitlement: Entitlement;
    }
  ) {}
}

export class EntitlementsLoadForCurrentUserFail implements Action {
  readonly type = EntitlementActionTypes.EntitlementsLoadForCurrentUserFail;
}

export type EntitlementActions =
  | EntitlementsLoadForCurrentUser
  | EntitlementsLoadForCurrentUserSuccess
  | EntitlementsLoadForCurrentUserFail;
