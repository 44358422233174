import { InsulationSpecificationInfo } from '@models/fabrication/insulation-specification-info';
import { Config } from '@models/fabrication/config';
import {
  AddDataElementAction,
  AddDataElementFailAction,
  AddDataElementSuccessAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  DeleteDataElementsSuccessAction,
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { DataLoadingState } from './base/data-loading.action';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum InsulationSpecificationInfoActionTypes {
  LoadInsulationSpecificationInfos = '[InsulationSpecifications API] Load InsulationSpecificationInfos',
  LoadInsulationSpecificationInfosFail = '[InsulationSpecifications API] Load InsulationSpecificationInfos Fail',
  LoadInsulationSpecificationInfosSuccess = '[InsulationSpecifications API] Load InsulationSpecificationInfos Success',
  UpdateInsulationSpecificationInfo = '[InsulationSpecifications API] Update InsulationSpecificationInfo',
  UpdateInsulationSpecificationInfoFail = '[InsulationSpecifications API] Update InsulationSpecificationInfo Fail',
  UpdateInsulationSpecificationInfoSuccess = '[InsulationSpecifications API] Update InsulationSpecificationInfo Success',
  AddInsulationSpecificationInfo = '[InsulationSpecifications API] Add InsulationSpecificationInfo',
  AddInsulationSpecificationInfoFail = '[InsulationSpecifications API] Add InsulationSpecificationInfo Fail',
  AddInsulationSpecificationInfoSuccess = '[InsulationSpecifications API] Add InsulationSpecificationInfo Success',
  DeleteInsulationSpecificationInfos = '[InsulationSpecifications API] Delete InsulationSpecificationInfos',
  DeleteInsulationSpecificationInfosFail = '[InsulationSpecifications API] Delete InsulationSpecificationInfos Fail',
  DeleteInsulationSpecificationInfosSuccess = '[InsulationSpecifications API] Delete InsulationSpecificationInfos Success',
  CopyInsulationSpecificationInfo = '[InsulationSpecifications API] Copy InsulationSpecificationInfo',
  CopyInsulationSpecificationInfoFail = '[InsulationSpecifications API] Copy InsulationSpecificationInfo Fail',
  CopyInsulationSpecificationInfoSuccess = '[InsulationSpecifications API] Copy InsulationSpecificationInfo Success',
}

const dataType = DataElementType.InsulationSpecification;

export class LoadInsulationSpecificationInfos
  implements LoadDataElementsAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadInsulationSpecificationInfosSuccess();
  failAction = new LoadInsulationSpecificationInfosFail();
  readonly type = InsulationSpecificationInfoActionTypes.LoadInsulationSpecificationInfos;
  dataElementType = DataElementType.InsulationSpecification;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config }) {
    this.config = payload.config;
  }
}

export class LoadInsulationSpecificationInfosFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InsulationSpecificationInfoActionTypes.LoadInsulationSpecificationInfosFail;
}

export class LoadInsulationSpecificationInfosSuccess
  implements LoadDataElementsSuccessAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  data: InsulationSpecificationInfo[];
  readonly type = InsulationSpecificationInfoActionTypes.LoadInsulationSpecificationInfosSuccess;
}

export class UpdateInsulationSpecificationInfo
  implements UpdateDataElementAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  dataElement: InsulationSpecificationInfo;
  successAction = new UpdateInsulationSpecificationInfoSuccess();
  failAction = new UpdateInsulationSpecificationInfoFail();
  readonly type = InsulationSpecificationInfoActionTypes.UpdateInsulationSpecificationInfo;
  dataElementType = dataType;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { dataElement: InsulationSpecificationInfo }) {
    this.dataElement = payload.dataElement;
  }
}

export class UpdateInsulationSpecificationInfoFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InsulationSpecificationInfoActionTypes.UpdateInsulationSpecificationInfoFail;
}

export class UpdateInsulationSpecificationInfoSuccess
  implements UpdateDataElementSuccessAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: InsulationSpecificationInfo;
  readonly type = InsulationSpecificationInfoActionTypes.UpdateInsulationSpecificationInfoSuccess;
}

export class AddInsulationSpecificationInfo
  implements AddDataElementAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: InsulationSpecificationInfo;
  successAction = new AddInsulationSpecificationInfoSuccess();
  failAction = new AddInsulationSpecificationInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = InsulationSpecificationInfoActionTypes.AddInsulationSpecificationInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: InsulationSpecificationInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class AddInsulationSpecificationInfoFail implements AddDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InsulationSpecificationInfoActionTypes.AddInsulationSpecificationInfoFail;
}

export class AddInsulationSpecificationInfoSuccess
  implements AddDataElementSuccessAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: InsulationSpecificationInfo;
  readonly type = InsulationSpecificationInfoActionTypes.AddInsulationSpecificationInfoSuccess;
}

export class CopyInsulationSpecificationInfo
  implements CopyDataElementAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: InsulationSpecificationInfo;
  successAction = new CopyInsulationSpecificationInfoSuccess();
  failAction = new CopyInsulationSpecificationInfoFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  readonly type = InsulationSpecificationInfoActionTypes.CopyInsulationSpecificationInfo;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS;
  isExternalNodeId = true;

  constructor(public payload: { config: Config; dataElement: InsulationSpecificationInfo }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopyInsulationSpecificationInfoFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InsulationSpecificationInfoActionTypes.CopyInsulationSpecificationInfoFail;
}

export class CopyInsulationSpecificationInfoSuccess
  implements CopyDataElementSuccessAction<InsulationSpecificationInfo>
{
  dataLoadingState = DataLoadingState.Complete;
  dataElement: InsulationSpecificationInfo;
  readonly type = InsulationSpecificationInfoActionTypes.CopyInsulationSpecificationInfoSuccess;
}

export class DeleteInsulationSpecificationInfos
  implements DeleteDataElementsAction<InsulationSpecificationInfo>
{
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: InsulationSpecificationInfo[];
  successAction = new DeleteInsulationSpecificationInfosSuccess();
  failAction = new DeleteInsulationSpecificationInfosFail();
  dataElementType = dataType;
  skipBinaryTask = false;
  nodeId = EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS;
  isExternalNodeId = true;
  readonly type = InsulationSpecificationInfoActionTypes.DeleteInsulationSpecificationInfos;

  constructor(public payload: { config: Config; dataElements: InsulationSpecificationInfo[] }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
  }
}

export class DeleteInsulationSpecificationInfosFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = InsulationSpecificationInfoActionTypes.DeleteInsulationSpecificationInfosFail;
}

export class DeleteInsulationSpecificationInfosSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = InsulationSpecificationInfoActionTypes.DeleteInsulationSpecificationInfosSuccess;
}

export type InsulationSpecificationsActions =
  | LoadInsulationSpecificationInfos
  | LoadInsulationSpecificationInfosFail
  | LoadInsulationSpecificationInfosSuccess
  | UpdateInsulationSpecificationInfo
  | UpdateInsulationSpecificationInfoFail
  | UpdateInsulationSpecificationInfoSuccess
  | AddInsulationSpecificationInfo
  | AddInsulationSpecificationInfoFail
  | AddInsulationSpecificationInfoSuccess
  | DeleteInsulationSpecificationInfos
  | DeleteInsulationSpecificationInfosFail
  | DeleteInsulationSpecificationInfosSuccess
  | CopyInsulationSpecificationInfo
  | CopyInsulationSpecificationInfoFail
  | CopyInsulationSpecificationInfoSuccess;
