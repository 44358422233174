import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

enum TooltipType {
  TEXT = 'text',
  TEMPLATE = 'template',
}

@Component({
  selector: 'fab-tooltip',
  template: `
    <ng-template #templateContent>
      <strong>{{ title }}</strong>
      <span *ngIf="content" [innerHTML]="content"></span>
      <a *ngIf="linkText" [href]="link" target="_blank">{{ linkText }}</a>
    </ng-template>

    <div
      [ngbTooltip]="shouldUseTemplate() ? templateContent : content"
      [triggers]="triggers"
      [autoClose]="autoclose"
      [openDelay]="openDelay"
      [placement]="position"
      [disableTooltip]="disableTooltip"
      container="body"
      tooltipClass="fab-tooltip"
      #tooltip="ngbTooltip"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnChanges {
  @Input() type: TooltipType = TooltipType.TEXT;
  @Input() content: string;
  @Input() title: string;
  @Input() linkText: string;
  @Input() link: string;
  @Input() position = 'bottom';
  @Input() disableTooltip = false;
  @Input() offset;
  @Input() triggers: 'click' | 'hover' = 'hover';
  @Input() openDelay = 500;
  @Input() autoclose = true;

  @ViewChild('tooltip') tooltip;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.disableTooltip?.currentValue) {
      this.tooltip?.close();
    }
  }

  shouldUseTemplate(): boolean {
    return this.type === TooltipType.TEMPLATE;
  }
}
