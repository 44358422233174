import { createSelector } from '@ngrx/store';

import { FDMState } from '../reducers';
import * as fromServiceTemplateInfo from '../reducers/service-template-info.reducer';

// service template info state
export const selectServiceTemplateInfoState = (state: FDMState) =>
  state.configsState.serviceTemplateInfos;

// service template info selectors
export const selectServiceTemplateInfoIds = createSelector(
  selectServiceTemplateInfoState,
  fromServiceTemplateInfo.selectServiceTemplateInfoIds
);

export const selectServiceTemplateInfoEntities = createSelector(
  selectServiceTemplateInfoState,
  fromServiceTemplateInfo.selectServiceTemplateInfoEntities
);

export const selectAllServiceTemplateInfo = createSelector(
  selectServiceTemplateInfoState,
  fromServiceTemplateInfo.selectAllServiceTemplateInfo
);

export const selectServiceTemplateInfoTotal = createSelector(
  selectServiceTemplateInfoState,
  fromServiceTemplateInfo.selectServiceTemplateInfoTotal
);

export const selectServiceTemplateInfoById = (id: string) =>
  createSelector(selectServiceTemplateInfoEntities, (systemInfoEntities) => systemInfoEntities[id]);
