import { Action } from '@ngrx/store';

export enum PagingDataActionTypes {
  SetPageSize = '[PagingData] Set Page Size',
}

// create navigation data to populate nav top and side bar
// data will be created each time a new config is selected
export class SetPageSize implements Action {
  readonly type = PagingDataActionTypes.SetPageSize;

  constructor(public payload: number) {}
}

export type PagingDataActions = SetPageSize;
