import { createSelector } from '@ngrx/store';
import { FDMState } from '../reducers';
import * as fromMaterialSpecs from '../reducers/material-spec.reducer';

// material spec state
export const selectMaterialSpecState = (state: FDMState) => state.configsState.materialSpecs;

// material spec selectors
export const selectMaterialSpecIds = createSelector(
  selectMaterialSpecState,
  fromMaterialSpecs.selectMaterialSpecIds
);

export const selectMaterialSpecEntities = createSelector(
  selectMaterialSpecState,
  fromMaterialSpecs.selectMaterialSpecEntities
);

export const selectAllMaterialSpecs = createSelector(
  selectMaterialSpecState,
  fromMaterialSpecs.selectAllMaterialSpecs
);

export const selectMaterialSpecsTotal = createSelector(
  selectMaterialSpecState,
  fromMaterialSpecs.selectMaterialSpecsTotal
);

export const selectMaterialSpecById = (id: string) =>
  createSelector(selectMaterialSpecEntities, (materialSpecEntities) => materialSpecEntities[id]);
