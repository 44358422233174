import * as fromRealTimeActivity from '../actions/activity-real-time.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RealTimeActivityMarker } from '@models/activities-events/activities';
import { DataElementType } from '@constants/data-element-types';
import { BinaryJobType } from '@models/binary-task/binary-job-type';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RealTimeActivityState extends EntityState<RealTimeActivityMarker> {}

export const RealTimeActivityAdapter: EntityAdapter<RealTimeActivityMarker> =
  createEntityAdapter<RealTimeActivityMarker>({
    selectId: (marker: RealTimeActivityMarker) => marker.configUrn,
  });

export const initialState: RealTimeActivityState = RealTimeActivityAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromRealTimeActivity.RealTimeActivityActions
): RealTimeActivityState {
  switch (action.type) {
    case fromRealTimeActivity.RealTimeActivityActionTypes.UpsertRealTimeActivityMarker: {
      const configUrn = action.payload.configUrn;
      let dataTypes: DataElementType[] = action.payload.dataType ? [action.payload.dataType] : [];
      let partNodeIds: string[] = action.payload.partNodeId ? [action.payload.partNodeId] : [];
      let contentItemIds: string[] = action.payload.contentItemIds?.length
        ? action.payload.contentItemIds
        : [];
      if ((state.ids as string[]).find((x) => x === action.payload.configUrn)) {
        dataTypes = [...state.entities[action.payload.configUrn].dataTypes, ...dataTypes];
        partNodeIds = [...state.entities[action.payload.configUrn].partNodeIds, ...partNodeIds];
      }
      // make sure we don't have any duplicates
      dataTypes = [...new Set(dataTypes)];
      partNodeIds = [...new Set(partNodeIds)];
      contentItemIds = [...new Set(contentItemIds)];
      return RealTimeActivityAdapter.upsertOne(
        { configUrn, dataTypes, partNodeIds, jobType: action.payload.jobType, contentItemIds },
        state
      );
    }
    case fromRealTimeActivity.RealTimeActivityActionTypes.DeleteRealTimeActivityMarker: {
      const configUrn = action.payload.configUrn;
      const marker = state.entities[configUrn];
      const dataType = action.payload.dataType;
      const partNodeId = action.payload.partNodeId;
      let canRemoveDataType = true;

      if (marker) {
        let partNodeIds = marker.partNodeIds;
        // handle parts, if all partNodeIds removed then remove data type
        if (dataType === DataElementType.Part) {
          partNodeIds = marker.partNodeIds.filter((x) => x !== partNodeId);

          if (partNodeIds.length) {
            canRemoveDataType = false;
          }
        }

        const dataTypes: DataElementType[] = canRemoveDataType
          ? marker.dataTypes.filter((x) => x !== action.payload.dataType)
          : marker.dataTypes;

        return RealTimeActivityAdapter.upsertOne(
          { configUrn, dataTypes, partNodeIds, jobType: BinaryJobType.BinaryUpdate },
          state
        );
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectRealTimeRealTimeActivityIds,
  selectEntities: selectRealTimeActivityEntities,
  selectAll: selectAllRealTimeActivity,
  selectTotal: selectRealTimeActivityTotal,
} = RealTimeActivityAdapter.getSelectors();
