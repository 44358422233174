import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DEFAULT_LANGUAGE, loadAdditionalResources } from '@utils/i18n-utils';

const browserLanguage = navigator.language || navigator.languages[0];

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: browserLanguage,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(loadAdditionalResources);

export default i18next;
