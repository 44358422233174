import * as fromInsulationSpecs from '../actions/insulation-specs.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { InsulationSpecificationInfo } from '@models/fabrication/insulation-specification-info';

export interface InsulationSpecificationsState extends EntityState<InsulationSpecificationInfo> {} // eslint-disable-line

export const insulationSpecsAdapter: EntityAdapter<InsulationSpecificationInfo> =
  createEntityAdapter<InsulationSpecificationInfo>({
    selectId: (insulationSpec: InsulationSpecificationInfo) => insulationSpec.id,
  });

export const initialState: InsulationSpecificationsState = insulationSpecsAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromInsulationSpecs.InsulationSpecificationsActions
): InsulationSpecificationsState {
  switch (action.type) {
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .LoadInsulationSpecificationInfos: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .LoadInsulationSpecificationInfosSuccess: {
      return insulationSpecsAdapter.upsertMany(action.data, state);
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .LoadInsulationSpecificationInfosFail: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .AddInsulationSpecificationInfo: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .AddInsulationSpecificationInfoSuccess: {
      return insulationSpecsAdapter.addOne(action.dataElement, state);
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .AddInsulationSpecificationInfoFail: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .CopyInsulationSpecificationInfo: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .CopyInsulationSpecificationInfoSuccess: {
      return insulationSpecsAdapter.addOne(action.dataElement, state);
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .CopyInsulationSpecificationInfoFail: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .UpdateInsulationSpecificationInfo: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .UpdateInsulationSpecificationInfoSuccess: {
      return insulationSpecsAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .UpdateInsulationSpecificationInfoFail: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .DeleteInsulationSpecificationInfos: {
      return state;
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .DeleteInsulationSpecificationInfosSuccess: {
      return insulationSpecsAdapter.removeMany(action.removedDataElementIds, state);
    }
    case fromInsulationSpecs.InsulationSpecificationInfoActionTypes
      .DeleteInsulationSpecificationInfosFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of insulation spec ids
  selectIds: selectInsulationSpecIds,

  // select the dictionary of insulation spec entities
  selectEntities: selectInsulationSpecEntities,

  // select the array of insulation specs
  selectAll: selectAllInsulationSpecs,

  // select the total insulation spec count
  selectTotal: selectInsulationSpecsTotal,
} = insulationSpecsAdapter.getSelectors();
