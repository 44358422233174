import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FDMState } from '@store/reducers/index';
import { Action, Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom, take, exhaustMap, catchError, tap } from 'rxjs/operators';
import { BinaryTaskManagerService } from '@services/binary-task-manager.service';
import { NavigationService } from '@services/navigation.service';
import { combineLatest, of } from 'rxjs';
import {
  ApplicationActionTypes,
  SetCurrentConfigExternalId,
  SetBinaryTaskData,
  SetEntitlementCheckComplete,
  SetApplicationIsDisabled,
  SkipBinaryTaskData,
} from '@store/actions/application.action';
import { selectAllConfigs } from '@store/selectors/configs.selectors';
import { selectCurrentConfig } from '@store/selectors/configs.selectors';
import { AccessRightsService } from '@services/access-rights/access-rights.service';
import {
  AccessRightsActionTypes,
  AccessRightsAddUser,
  AccessRightsAddUserFail,
  AccessRightsAddUserSuccess,
  AccessRightsLoadRole,
  AccessRightsLoadRoleSuccess,
  AccessRightsLoadUsersFail,
  AccessRightsLoadUsersSuccess,
  AccessRightsRemoveUsers,
  AccessRightsRemoveUsersFail,
  AccessRightsRemoveUsersSuccess,
} from '../actions/access-rights.action';
import { selectAllAccessRights } from '../selectors/acces-rights.selectors';
import {
  AccessRightsData,
  ConfigUsers,
  ContentUserRole,
} from '@models/access-rights/access-rights';
import { Config } from '@models/fabrication/config';
import { AccessRightsLoadUsers } from '@store/actions/access-rights.action';
import { AxiosError } from 'axios';
import {
  EntitlementActionTypes,
  EntitlementsLoadForCurrentUserFail,
  EntitlementsLoadForCurrentUserSuccess,
} from '../actions/entitlements.action';
import { EntitlementService } from '@services/entitlements/entitlement.service';
import { Entitlement } from '@models/entitlements/entitlement';
import { LoggingService } from '@services/logging.service';
import { LoadData, LoadDataFail, LoadDataSuccess } from '../actions/base/data-loading.action';
import { UserSettingsService } from '@services/user-settings.service';
import { DataElementEffectService } from '@services/data-element-effect.service';

@Injectable()
export class ApplicationEffects {
  configRoleDataIsLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessRightsActionTypes.AccessRightsLoadRole),
      exhaustMap((action: AccessRightsLoadRole) => {
        const selectedConfig = action.payload.config;
        const actions = [new SetCurrentConfigExternalId(selectedConfig.externalId)];
        return this.accessRightsService.getCurrentRole(selectedConfig).pipe(
          take(1),
          map((role: ContentUserRole) => {
            const accessRightsData: AccessRightsData = {
              id: selectedConfig.id,
              role,
            };

            return [new AccessRightsLoadRoleSuccess({ data: accessRightsData }), ...actions];
          })
        );
      }),
      switchMap((dispatchActions) => dispatchActions)
    )
  );

  loadConfigUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessRightsActionTypes.AccessRightsLoadUsers),
      switchMap((action: AccessRightsLoadUsers) => {
        const selectedConfig = action.payload.config;
        return this.accessRightsService.getConfigUsers(selectedConfig).pipe(
          map((users: ConfigUsers) => {
            if (users) {
              return new AccessRightsLoadUsersSuccess({ id: selectedConfig.id, users });
            } else {
              return new AccessRightsLoadUsersFail({ id: selectedConfig.id });
            }
          })
        );
      })
    )
  );

  addConfigUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessRightsActionTypes.AccessRightsAddUser),
      switchMap((action: AccessRightsAddUser) => {
        const selectedConfig = action.payload.config;
        return this.accessRightsService
          .addConfigUser(
            selectedConfig,
            action.payload.emailId,
            action.payload.role,
            action.payload.extraPermissions
          )
          .pipe(
            map((users: ConfigUsers) => {
              if (users) {
                return new AccessRightsAddUserSuccess({ id: selectedConfig.id, users });
              } else {
                return new AccessRightsAddUserFail({ id: selectedConfig.id });
              }
            }),
            catchError((error: AxiosError) =>
              of(
                new AccessRightsAddUserFail({
                  id: selectedConfig.id,
                  statusCode: error.response.status,
                })
              )
            )
          );
      })
    )
  );

  removeConfigUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessRightsActionTypes.AccessRightsRemoveUsers),
      switchMap((action: AccessRightsRemoveUsers) => {
        const selectedConfig = action.payload.config;
        return this.accessRightsService
          .removeConfigUsers(selectedConfig, action.payload.userIds)
          .pipe(
            map((users: ConfigUsers) => {
              if (users) {
                return new AccessRightsRemoveUsersSuccess({ id: selectedConfig.id, users });
              } else {
                return new AccessRightsRemoveUsersFail({ id: selectedConfig.id });
              }
            })
          );
      })
    )
  );

  // change selected config, need to fire ui action to re-create the navigation options

  setCurrentConfigId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActionTypes.SetCurrentConfigExternalId),
        switchMap((action: SetCurrentConfigExternalId) => {
          return combineLatest([
            this.store$.select(selectAllConfigs),
            this.store$.select(selectAllAccessRights),
          ]).pipe(
            take(1),
            tap((setupData: [Config[], AccessRightsData[]]) => {
              const [configs, accessRightsData] = setupData;
              // if payload is null attempt to select the first config
              // if no configs exist then set to null (no data navigation will be created)
              const selectedConfig = configs.find((x) => x.externalId === action.payload) || null;
              const role: ContentUserRole = (selectedConfig &&
                accessRightsData?.find((x) => x.id === selectedConfig.id)?.role) as ContentUserRole;
              //save last access config user settings
              this.userSettingsService.currentUserSettings.lastAccessedConfigId = action.payload;
              this.userSettingsService.saveUserSettings();
              this.navigationService.createNavigationDataForConfig(configs, selectedConfig, role);
            })
          );
        })
      ),
    { dispatch: false }
  );

  loadEntitlementsForCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntitlementActionTypes.EntitlementsLoadForCurrentUser),
      tap(() => this.store$.dispatch(new LoadData())),
      switchMap(() => {
        const failActions = [
          new EntitlementsLoadForCurrentUserFail(),
          new SetEntitlementCheckComplete({ complete: true }),
          new LoadDataFail(),
          new SetApplicationIsDisabled({ disabled: true }),
          new SetCurrentConfigExternalId(null), // prompt creation of empty nav menu
        ];
        return this.entitlementsService.getCurrentUserEntitlement().pipe(
          map((entitlement: Entitlement) => {
            if (entitlement) {
              const successActions: Action[] = [
                new EntitlementsLoadForCurrentUserSuccess({ entitlement }),
                new SetEntitlementCheckComplete({ complete: true }),
                new LoadDataSuccess(),
              ];

              if (!entitlement.auth) {
                successActions.push(
                  new SetCurrentConfigExternalId(null),
                  new SetApplicationIsDisabled({ disabled: true })
                );
              }

              return successActions;
            } else {
              return failActions;
            }
          }),
          catchError((error: AxiosError) => {
            this.loggingService.logError(error);
            return of(failActions);
          })
        );
      }),
      switchMap((dispatchActions) => dispatchActions)
    )
  );

  skipBinaryTaskData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ApplicationActionTypes.SkipBinaryTaskData),
        map((action: SkipBinaryTaskData) =>
          this.dataElementEffectService.postSuccessNotification(
            action.payload.completeOperation,
            action.payload.dataElementType
          )
        )
      );
    },
    { dispatch: false }
  );

  setBinaryTaskData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActionTypes.SetBinaryTaskData),
        map((action: SetBinaryTaskData) => action.payload),
        withLatestFrom(this.store$.select(selectCurrentConfig)),
        switchMap(([binaryTaskData, config]) => {
          return this.binaryService.submitBinaryTask(
            config,
            binaryTaskData.entityId,
            binaryTaskData.entityType,
            binaryTaskData.action,
            binaryTaskData.externalIds,
            binaryTaskData.schemaId,
            binaryTaskData.jobType,
            binaryTaskData.oldPath,
            binaryTaskData.updatedReferenceContentIds || []
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<FDMState>,
    private binaryService: BinaryTaskManagerService,
    private navigationService: NavigationService,
    private accessRightsService: AccessRightsService,
    private entitlementsService: EntitlementService,
    private loggingService: LoggingService,
    private userSettingsService: UserSettingsService,
    private dataElementEffectService: DataElementEffectService
  ) {}
}
