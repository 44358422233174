import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { InstallationRate } from '@models/fabrication/labour-rate';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { selectCurrentInstallationRates } from '@store/selectors/configs.selectors';
import { selectInstallationRateById } from '@store/selectors/installation-rate.selectors';
import {
  LoadInstallationRates,
  LoadInstallationRatesSuccess,
} from '@store/actions/installation-rate.action';
import { UpdateConfigInstallationRateIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { Observable, map, of } from 'rxjs';

@Injectable()
// todo: implement table and form setup
export class DynamicInstallationRateSetup extends DynamicDataElementTypeSetup<InstallationRate> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<InstallationRate>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return '';
  }

  setupOptions() {
    this.options = {
      dataType: DataElementType.InstallationRate,
      dependentDataTypes: [],
      createNewInstance: () => new InstallationRate(),
      sortFields: ['value', 'name'],
      supportsDynamicUpdates: false,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentInstallationRates(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectInstallationRateById(id)),
      },
      actions: {
        loadAllAction: (config: Config) =>
          this.store$.dispatch(new LoadInstallationRates({ config })),
        loadSuccessAction: () => new LoadInstallationRatesSuccess(),
        deleteDataSuccessAction: () => null,
        addDataSuccessAction: () => null,
        updateDataSuccessAction: () => null,
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigInstallationRateIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: null,
        editModelAction: null,
        copyModelAction: null,
        deleteModelsAction: () => null,
        fixModelAction: null,
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_INSTALLATION_RATES,
        schemas: [
          {
            dataType: DataElementType.InstallationRate,
            schema: {
              namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
              version: EnvironmentConstants.FSS_SCHEMA_INSTALLATION_RATE_VERSION,
              type: EnvironmentConstants.FSS_SCHEMA_INSTALLATION_RATE,
            },
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<InstallationRate>> {
    const columns: ColumnDefinition[] = [];
    return of(this.createDynamicTableOptions(columns));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<InstallationRate>> {
    const uniqueFieldRestrictions = ['name'];

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: InstallationRate) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          formStyle: DynamicFormStyle.SIMPLE,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'value'],
      isReplaceable: true,
      isRemovable: false,
      isEditable: false,
      upstreamReferenceDataTypes: () => [DataElementType.Connector],
      isDownstreamRefBlocked: () => true,
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return null;
  }

  isFixable(): boolean {
    return false;
  }

  getInvalidDataErrors(): string {
    return null;
  }

  requiresBinaryUpgrade(/*dataElement: InstallationRate*/): boolean {
    return false;
  }
}
