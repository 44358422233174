import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomRouteReuseStategy implements RouteReuseStrategy {
  private savedHandles = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuse;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = this.getRouteKey(route);
    this.savedHandles.set(key, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return route.queryParams.reuse && this.savedHandles.has(this.getRouteKey(route));
  }

  retrieve = (route: ActivatedRouteSnapshot): DetachedRouteHandle | null =>
    this.savedHandles.get(this.getRouteKey(route)) || null;

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private getRouteKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter((u) => u.url)
      .map((u) => u.url)
      .join('/');
  }

  public clearSavedHandle(key: string): void {
    const handle = this.savedHandles.get(key);
    if (handle) {
      (handle as any).componentRef.destroy();
    }

    this.savedHandles.delete(key);
  }
}
