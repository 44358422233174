import * as fromDBFiles from '../actions/db-file.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DBFile } from '@models/fabrication/db-file';

export interface DBFileState extends EntityState<DBFile> {} // eslint-disable-line

export const dbFileAdapter: EntityAdapter<DBFile> = createEntityAdapter<DBFile>({
  selectId: (insulationSpec: DBFile) => insulationSpec.id,
});

export const initialState: DBFileState = dbFileAdapter.getInitialState();

export function reducer(state = initialState, action: fromDBFiles.DBFileActions): DBFileState {
  switch (action.type) {
    // specs
    case fromDBFiles.DBFileActionTypes.LoadDBFiles: {
      return state;
    }
    case fromDBFiles.DBFileActionTypes.LoadDBFilesSuccess: {
      return dbFileAdapter.upsertMany(action.data, state);
    }
    case fromDBFiles.DBFileActionTypes.LoadDBFilesFail: {
      return state;
    }
    case fromDBFiles.DBFileActionTypes.UpdateDBFile: {
      return state;
    }
    case fromDBFiles.DBFileActionTypes.UpdateDBFileSuccess: {
      return dbFileAdapter.updateOne(
        { id: action.dataElement.id, changes: action.dataElement },
        state
      );
    }
    case fromDBFiles.DBFileActionTypes.UpdateDBFileFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectDBFileIds,
  selectEntities: selectDBFileEntities,
  selectAll: selectAllDBFiles,
  selectTotal: selectDBFilesTotal,
} = dbFileAdapter.getSelectors();
