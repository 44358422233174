import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config, ConfigUnitSystem } from '@models/fabrication/config';
import { StiffenerInfo, StiffenerType } from '@models/fabrication/stiffener-info';
import { EnvironmentConstants } from '@constants/environment-constants';
import { map, take } from 'rxjs/operators';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfigStiffenerInfos,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import { selectStiffenerInfoById } from '@store/selectors/stiffener-info.selectors';
import {
  LoadStiffenerInfos,
  LoadStiffenerInfosSuccess,
  AddStiffenerInfo,
  UpdateStiffenerInfo,
  CopyStiffenerInfo,
  DeleteStiffenerInfos,
  DeleteStiffenerInfosSuccess,
  AddStiffenerInfoSuccess,
  UpdateStiffenerInfoSuccess,
} from '@store/actions/stiffener-info.action';
import { UpdateConfigStiffenerInfoIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { UnitSchemaType } from '@models/forge-units/forge-unit-schema-types';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import helpLinks from '@assets/help/help-links.json';
import { Observable, of } from 'rxjs';

@Injectable()
export class DynamicStiffenerInfoSetup extends DynamicDataElementTypeSetup<StiffenerInfo> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<StiffenerInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.stiffeners;
  }

  setupOptions() {
    const stiffenerSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_STIFFENER_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_STIFFENER,
    };

    this.options = {
      dataType: DataElementType.Stiffener,
      dependentDataTypes: [],
      createNewInstance: () => {
        return new StiffenerInfo();
      },
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigStiffenerInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectStiffenerInfoById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadStiffenerInfos({ config })),
        loadSuccessAction: () => new LoadStiffenerInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteStiffenerInfosSuccess(),
        addDataSuccessAction: () => new AddStiffenerInfoSuccess(),
        updateDataSuccessAction: () => new UpdateStiffenerInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigStiffenerInfoIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: StiffenerInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddStiffenerInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        editModelAction: (model: StiffenerInfo) =>
          this.store$.dispatch(new UpdateStiffenerInfo({ dataElement: model })),
        copyModelAction: (model: StiffenerInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyStiffenerInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        deleteModelsAction: (models: StiffenerInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteStiffenerInfos({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: StiffenerInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.Stiffener,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_STIFFENER,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_STIFFENER_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS,
                  addSuccessAction: new AddStiffenerInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_STIFFENERINFOS,
        schemas: [
          {
            dataType: DataElementType.Stiffener,
            schema: stiffenerSchema,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(
    configUnitSystem: ConfigUnitSystem
  ): Observable<DynamicTableOptions<StiffenerInfo>> {
    const lengthUnits = ForgeUnitsService.getStandardLengthUnits(configUnitSystem);

    const stiffenerInfoTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.STIFFENERS.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
        formatter: (value: string) =>
          value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
        visible: true,
      },
      {
        field: 'centralType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.STIFFENERS.CENTRAL_TYPE),
        visible: true,
      },
      {
        field: 'endType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.STIFFENERS.END_TYPE),
        visible: true,
      },
      {
        field: 'longAdjust',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.STIFFENERS.LONG_ADJUST),
        visible: false,
        units: lengthUnits,
      },
      {
        field: 'omitShortSideIfLessThan',
        header: this.translate.instant(
          LC.DATATYPES.DEFINITIONS.STIFFENERS.OMIT_SHORT_SIDE_IF_LESS_THAN
        ),
        visible: false,
        units: lengthUnits,
      },
      {
        field: 'shortAdjust',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.STIFFENERS.SHORT_ADJUST),
        visible: false,
        units: lengthUnits,
      },
    ];
    return of(this.createDynamicTableOptions(stiffenerInfoTableColumns));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<StiffenerInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: StiffenerInfo) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
              includeFields: [
                'category',
                'longAdjust',
                'shortAdjust',
                'omitShortSideIfLessThan',
                'endType',
                'centralType',
              ],
              expanded: true,
              orderByIncludeFields: true,
              options: {
                selectFields: [
                  {
                    key: 'centralType',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      StiffenerType,
                      'StiffenerType'
                    ),
                  },
                  {
                    key: 'endType',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      StiffenerType,
                      'StiffenerType'
                    ),
                  },
                ],
                unitsFields: [
                  {
                    key: 'longAdjust',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                  {
                    key: 'shortAdjust',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                  {
                    key: 'omitShortSideIfLessThan',
                    units: {
                      imperial: UnitSchemaType.Inches,
                      metric: UnitSchemaType.Millimeters,
                    },
                  },
                ],
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
          ],
          formStyle: DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category'],
      isReplaceable: true,
      isRemovable: true,
      isEditable: true,
      upstreamReferenceDataTypes: () => [DataElementType.Part],
      clusterIcon: 'file-assembly16',
      isDownstreamRefBlocked: () => true,
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return 'file-assembly';
  }

  isFixable(): boolean {
    return true;
  }

  getInvalidDataErrors(model: StiffenerInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.Stiffener);
    return this.getStandardInvalidDataError(DataElementType.Stiffener, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: StiffenerInfo*/): boolean {
    return false;
  }
}
