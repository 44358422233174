import { ComponentPortal, TemplatePortal, Portal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class DynamicGraphExternalRender {
  private dynamicComponents: DynamicGraphComponent[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  /**
   * Mount a template ref in specific DOM element using cdk portals
   *
   * @param {TemplateRef<any>} templateRef - ng template reference
   * @param {ViewContainerRef} viewContainerRef - parent view/component reference
   * @param {*} portalHostRef - DOM element reference
   * @param {*} context - context to pass to template
   * @memberof DynamicGraphComponentsService
   */
  mountDynamicTemplate(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    portalHostRef: any,
    context: any
  ) {
    const { portal, portalHost } = this.createDynamicTemplate(
      templateRef,
      viewContainerRef,
      portalHostRef,
      context
    );
    portalHost.attach(portal);
  }

  /**
   * Mount a component to specific DOM element using cdk portals
   *
   * @param {*} component - Component class
   * @param {*} portalHostRef - DOM element
   * @memberof DynamicGraphComponentsService
   */
  mountComponent(component, portalHostRef) {
    const { portal, portalHost } = this.createDynamicComponent(component, portalHostRef);
    portalHost.attach(portal);
  }

  private createDynamicTemplate(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    portalHostRef: any,
    context: any
  ) {
    const portal = new TemplatePortal(templateRef, viewContainerRef, context);

    const portalHost = new DomPortalOutlet(
      portalHostRef,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    const dynamicComponent = {
      id: uuidv4(),
      portal,
      portalHost,
    };

    this.dynamicComponents.push(dynamicComponent);
    return dynamicComponent;
  }

  private createDynamicComponent(component, portalHostRef) {
    const portal = new ComponentPortal(component);

    const portalHost = new DomPortalOutlet(
      portalHostRef,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
    const dynamicComponent = {
      id: uuidv4(),
      portal,
      portalHost,
    };

    this.dynamicComponents.push(dynamicComponent);
    return dynamicComponent;
  }
}

interface DynamicGraphComponent {
  id: string;
  portal: Portal<any>;
  portalHost: DomPortalOutlet;
}
