import { Config } from '@models/fabrication/config';
import {
  LoadDataElementsAction,
  LoadDataElementsFailAction,
  LoadDataElementsSuccessAction,
  UpdateDataElementAction,
  UpdateDataElementFailAction,
  UpdateDataElementSuccessAction,
  CopyDataElementAction,
  CopyDataElementFailAction,
  CopyDataElementSuccessAction,
  AddDataElementSuccessAction,
  StartUpgradeDataElementAction,
  StartUpgradeDataElementFailAction,
  StartUpgradeDataElementSuccessAction,
  FinaliseUpgradeDataElementAction,
  FinaliseUpgradeDataElementFailAction,
  FinaliseUpgradeDataElementSuccessAction,
  DeleteDataElementsAction,
  DeleteDataElementsFailAction,
  DeleteDataElementsSuccessAction,
} from './base/data-element.action';
import { DataElementType } from '@constants/data-element-types';
import { Part } from '@models/fabrication/part';
import { ContentNode } from '@models/fabrication/content-node';
import { DataLoadingState } from './base/data-loading.action';
import { Action } from '@ngrx/store';
import { StorageFileType } from '@models/fabrication/files';
import { EnvironmentConstants } from '@constants/environment-constants';

export enum PartActionTypes {
  LoadSinglePart = '[Parts API] Load Single Part',
  LoadParts = '[Parts API] Load Parts',
  LoadPartsFail = '[Parts API] Load Parts Fail',
  LoadPartsSuccess = '[Parts API] Load Parts Success',
  UpdatePart = '[Part API] Update Part',
  UpdatePartFail = '[Part API] Update Part Fail',
  UpdatePartSuccess = '[Part API] Update Part Success',
  CopyPart = '[Part API] Copy Part',
  CopyPartSuccess = '[Part API] Copy Part Success',
  CopyPartFail = '[Part API] Copy Part Fail',
  AddPartSuccess = '[Part API] Add Part Success',
  StartUpgradePart = '[Part API] Start Upgrade Part',
  StartUpgradePartFail = '[Part API] Start Upgrade Part Fail',
  StartUpgradePartSuccess = '[Part API] Start Upgrade Part Success',
  FinaliseUpgradePart = '[Part API] Finalise Upgrade Part',
  FinaliseUpgradePartFail = '[Part API] Finalise Upgrade Part Fail',
  FinaliseUpgradePartSuccess = '[Part API] Finalise Upgrade Part Success',
  RemoveAllParts = '[Part API] Remove All Parts',
  DeleteParts = '[Part API] Delete Parts',
  DeletePartsSuccess = '[Part API] Delete Parts Success',
  DeletePartsFail = '[Part API] Delete Parts Fail',
}

export class LoadSinglePart implements Action {
  config: Config;
  dbId: string;
  readonly type = PartActionTypes.LoadSinglePart;

  constructor(
    public payload: {
      config: Config;
      dbId: string;
    }
  ) {
    this.config = payload.config;
    this.dbId = payload.dbId;
  }
}

export class LoadParts implements LoadDataElementsAction<Part> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  successAction = new LoadPartsSuccess();
  failAction = new LoadPartsFail();
  readonly type = PartActionTypes.LoadParts;
  dataElementType = DataElementType.Part;
  parentNode: ContentNode;
  nodeId: string;
  isExternalNodeId = false;
  bypassCache = false;

  constructor(public payload: { config: Config; parentNode: ContentNode; bypassCache?: boolean }) {
    this.config = payload.config;
    this.parentNode = payload.parentNode;
    this.nodeId = payload.parentNode.id;
    this.bypassCache = payload.bypassCache;
  }
}

export class LoadPartsFail implements LoadDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = PartActionTypes.LoadPartsFail;
}

export class LoadPartsSuccess implements LoadDataElementsSuccessAction<Part> {
  dataLoadingState = DataLoadingState.Complete;
  data: Part[];
  readonly type = PartActionTypes.LoadPartsSuccess;
}

export class UpdatePart implements UpdateDataElementAction<Part> {
  dataLoadingState = DataLoadingState.Loading;
  dataElement: Part;
  successAction = new UpdatePartSuccess();
  failAction = new UpdatePartFail();
  readonly type = PartActionTypes.UpdatePart;
  dataElementType = DataElementType.Part;
  nodeId: string;
  isExternalNodeId: boolean;
  oldPath: string;
  updateThumbnails: boolean;
  ignoreActivities = true;
  ignoreCache = true;

  constructor(
    public payload: {
      dataElement: Part;
      nodeId: string;
      isExternalNodeId: boolean;
      oldPath: string;
      updateThumbnails: boolean;
    }
  ) {
    this.dataElement = payload.dataElement;
    this.nodeId = payload.nodeId;
    this.isExternalNodeId = payload.isExternalNodeId;
    this.oldPath = payload.oldPath;
    this.updateThumbnails = payload.updateThumbnails;
  }
}

export class UpdatePartFail implements UpdateDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = PartActionTypes.UpdatePartFail;
}

export class UpdatePartSuccess implements UpdateDataElementSuccessAction<Part> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: Part;
  readonly type = PartActionTypes.UpdatePartSuccess;
}

export class CopyPart implements CopyDataElementAction<Part> {
  dataLoadingState = DataLoadingState.Loading;
  config: Config;
  dataElement: Part;
  successAction = new CopyPartSuccess();
  failAction = new CopyPartFail();
  dataElementType = DataElementType.Part;
  skipBinaryTask = false;
  readonly type = PartActionTypes.CopyPart;
  nodeId = EnvironmentConstants.FCS_NODE_ID_FDM_ITEM_FOLDER;
  isExternalNodeId = true;
  ignoreActivities = true;
  ignoreCache = true;
  usesSearch = true;

  constructor(payload: { config: Config; dataElement: Part }) {
    this.dataElement = payload.dataElement;
    this.config = payload.config;
  }
}

export class CopyPartFail implements CopyDataElementFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = PartActionTypes.CopyPartFail;
}

export class CopyPartSuccess implements CopyDataElementSuccessAction<Part> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: Part;
  readonly type = PartActionTypes.CopyPartSuccess;
}

export class AddPartSuccess implements AddDataElementSuccessAction<Part> {
  dataLoadingState = DataLoadingState.Complete;
  dataElement: Part;
  readonly type = PartActionTypes.AddPartSuccess;
}

export class StartUpgradePart implements StartUpgradeDataElementAction<Part> {
  config: Config;
  successAction = new StartUpgradePartSuccess();
  failAction = new StartUpgradePartFail();
  dataElement: Part;
  dataElementType = DataElementType.Part;
  nodeId: string;
  isExternalNodeId: boolean;
  readonly type = PartActionTypes.StartUpgradePart;
  dataLoadingState = DataLoadingState.Ignore;
  updateCache = false;

  constructor(payload: { config: Config; part: Part; nodeId: string; isExternalNodeId: boolean }) {
    this.config = payload.config;
    this.dataElement = payload.part;
    this.nodeId = payload.nodeId;
    this.isExternalNodeId = payload.isExternalNodeId;
  }
}

export class StartUpgradePartFail implements StartUpgradeDataElementFailAction {
  readonly type = PartActionTypes.StartUpgradePartFail;
  dataLoadingState = DataLoadingState.Ignore;
}

export class StartUpgradePartSuccess implements StartUpgradeDataElementSuccessAction<Part> {
  dataElement: Part;

  readonly type = PartActionTypes.StartUpgradePartSuccess;
  dataLoadingState = DataLoadingState.Ignore;
}

export class FinaliseUpgradePart implements FinaliseUpgradeDataElementAction<Part> {
  config: Config;
  successAction = new FinaliseUpgradePartSuccess();
  failAction = new FinaliseUpgradePartFail();
  dataElementType = DataElementType.Part;
  dataElementId: string;
  nodeId: string;
  isExternalNodeId = false;
  readonly type = PartActionTypes.FinaliseUpgradePart;
  dataLoadingState = DataLoadingState.Ignore;
  storageFileTypes = [StorageFileType.PartFile, StorageFileType.ImageFile];
  updateCache = false;

  constructor(payload: { config: Config; partId: string; nodeId: string }) {
    this.config = payload.config;
    this.nodeId = payload.nodeId;
    this.dataElementId = payload.partId;
  }
}

export class FinaliseUpgradePartFail implements FinaliseUpgradeDataElementFailAction {
  readonly type = PartActionTypes.FinaliseUpgradePartFail;
  dataLoadingState = DataLoadingState.Ignore;
}

export class FinaliseUpgradePartSuccess implements FinaliseUpgradeDataElementSuccessAction<Part> {
  dataElement: Part;

  readonly type = PartActionTypes.FinaliseUpgradePartSuccess;
  dataLoadingState = DataLoadingState.Ignore;
}

export class RemoveAllParts implements Action {
  readonly type = PartActionTypes.RemoveAllParts;
}

export class DeleteParts implements DeleteDataElementsAction<Part> {
  config: Config;
  dataLoadingState = DataLoadingState.Loading;
  dataElements: Part[];
  successAction = new DeletePartsSuccess();
  failAction = new DeletePartsFail();
  dataElementType = DataElementType.Part;
  skipBinaryTask = false;
  nodeId = null;
  isExternalNodeId = false;
  ignoreActivities = true;
  ignoreCache = true;
  oldPath: string;
  readonly type = PartActionTypes.DeleteParts;

  constructor(public payload: { config: Config; dataElements: Part[]; oldPath: string }) {
    this.dataElements = payload.dataElements;
    this.config = payload.config;
    this.oldPath = payload.oldPath;
  }
}

export class DeletePartsFail implements DeleteDataElementsFailAction {
  dataLoadingState = DataLoadingState.Failed;
  readonly type = PartActionTypes.DeletePartsFail;
}

export class DeletePartsSuccess implements DeleteDataElementsSuccessAction {
  dataLoadingState = DataLoadingState.Complete;
  removedDataElementIds: string[];
  readonly type = PartActionTypes.DeletePartsSuccess;
}

export type PartActions =
  | LoadSinglePart
  | LoadParts
  | LoadPartsFail
  | LoadPartsSuccess
  | UpdatePart
  | UpdatePartFail
  | UpdatePartSuccess
  | CopyPart
  | CopyPartSuccess
  | CopyPartFail
  | AddPartSuccess
  | StartUpgradePart
  | StartUpgradePartSuccess
  | StartUpgradePartFail
  | FinaliseUpgradePart
  | FinaliseUpgradePartFail
  | FinaliseUpgradePartSuccess
  | RemoveAllParts
  | DeleteParts
  | DeletePartsSuccess
  | DeletePartsFail;
