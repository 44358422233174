import { DataElementType } from '@constants/data-element-types';
import { Operation } from '@constants/operations-types';
import { BinaryJobType } from '@models/binary-task/binary-job-type';
import { Action } from '@ngrx/store';
import { BinaryTaskAction } from '@services/binary-task-manager.service';

export enum ApplicationActionTypes {
  SetConfigsLoading = '[Application] Set Configs Loading',
  SetConfigsLoadingFailed = '[Application] Set Configs Loading Failed',
  SetConfigsLoaded = '[Application] Set Configs Loaded',
  SetCurrentConfigExternalId = '[Application] Set Current Config External Id',
  SetCurrentItemFileIds = '[Application] Set Current Item File Ids',
  SetCurrentItemFileIdsToEmpty = '[Application] Set Current Item File Ids to Empty',
  SkipBinaryTaskData = '[Application] Skip Web Hook Data',
  SetBinaryTaskData = '[Application] Set Web Hook Data',
  SetLoadedItemFolder = '[Application] Set Loaded Item Folder',
  SetRequestedRefreshPath = '[Application] Set Requested Refresh Path',
  SetConfigRefreshDataStatus = '[Application] Set Config Refresh Data Status',
  SetInitialCacheValidityCheckComplete = '[Application] Set Initial Cache Validity Check Complete',
  SetEntitlementCheckComplete = '[Application] Set Entitlement Check Complete',
  SetApplicationIsDisabled = '[Application] Set Application is Disabled',
}

export class SetConfigsLoading implements Action {
  readonly type = ApplicationActionTypes.SetConfigsLoading;

  constructor(public payload: boolean) {}
}

export class SetConfigsLoadingFailed implements Action {
  readonly type = ApplicationActionTypes.SetConfigsLoadingFailed;

  constructor(public payload: boolean) {}
}

export class SetConfigsLoaded implements Action {
  readonly type = ApplicationActionTypes.SetConfigsLoaded;

  constructor(public payload: boolean) {}
}

export class SetCurrentConfigExternalId implements Action {
  readonly type = ApplicationActionTypes.SetCurrentConfigExternalId;

  constructor(public payload: string) {}
}

export class SetCurrentItemFileIds implements Action {
  readonly type = ApplicationActionTypes.SetCurrentItemFileIds;

  constructor(public payload: { allowMultiple: boolean; id: string | string[] }) {}
}

export class SetCurrentItemFileIdsToEmpty implements Action {
  readonly type = ApplicationActionTypes.SetCurrentItemFileIdsToEmpty;
}

export class SkipBinaryTaskData implements Action {
  readonly type = ApplicationActionTypes.SkipBinaryTaskData;

  constructor(
    public payload: {
      dataElementType: DataElementType;
      completeOperation: Operation;
    }
  ) {}
}

export class SetBinaryTaskData implements Action {
  readonly type = ApplicationActionTypes.SetBinaryTaskData;

  constructor(
    public payload: {
      entityId: string;
      entityType: string;
      action: BinaryTaskAction;
      externalIds: string[];
      schemaId: string;
      jobType: BinaryJobType;
      oldPath?: string;
      updatedReferenceContentIds?: string[];
    }
  ) {}
}

export class SetLoadedItemFolder implements Action {
  readonly type = ApplicationActionTypes.SetLoadedItemFolder;

  constructor(public payload: { itemFolderId: string }) {}
}

export class SetRequestedRefreshpath implements Action {
  readonly type = ApplicationActionTypes.SetRequestedRefreshPath;

  constructor(public payload: { path: string }) {}
}

export class SetInitialCacheValidityCheckComplete implements Action {
  readonly type = ApplicationActionTypes.SetInitialCacheValidityCheckComplete;

  constructor(public payload: { complete: boolean }) {}
}

export class SetEntitlementCheckComplete implements Action {
  readonly type = ApplicationActionTypes.SetEntitlementCheckComplete;

  constructor(public payload: { complete: boolean }) {}
}

export class SetApplicationIsDisabled implements Action {
  readonly type = ApplicationActionTypes.SetApplicationIsDisabled;

  constructor(public payload: { disabled: boolean }) {}
}

export type ApplicationActions =
  | SetConfigsLoading
  | SetConfigsLoadingFailed
  | SetConfigsLoaded
  | SetCurrentConfigExternalId
  | SetCurrentItemFileIds
  | SetCurrentItemFileIdsToEmpty
  | SkipBinaryTaskData
  | SetBinaryTaskData
  | SetLoadedItemFolder
  | SetRequestedRefreshpath
  | SetInitialCacheValidityCheckComplete
  | SetEntitlementCheckComplete
  | SetApplicationIsDisabled;
