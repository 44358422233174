import { FabricationReference } from './references';

export class ForgeContentDataElement {
  // the urn of the content, node or subLibrary
  id = '';
  name = '';
  description = '';
  externalId = '';
  etag = '';
  extensionDataType = '';
  extensionDataVersion = '';
  extensionDataNamespace = '';
  lastModifiedTime = '';
  parentId = '';

  fabricationReferences: FabricationReference[];
  files: string[] = [];
  thumbnails: string[] = [];
  tags: string[] = [];
  isTemporaryData = false;
  isUpgrading = false;
  thumbnailUrl = '';
  isInvalid = false;
  schemaType?: string;
}
