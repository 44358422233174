import { Injectable, Injector } from '@angular/core';
import { LoggingService } from '@services/logging.service';
import { ResilientHttpClient, IResilientHttpOptions } from '@adsk/resilient-axios-client';
import { lastValueFrom, from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AxiosRequestConfig, Method, AxiosError } from 'axios';
import { ResiliencyConstants as rc } from '@constants/resiliency-constants';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ResilientHttpService {
  private httpClient: ResilientHttpClient = null;
  private authService: AuthService = null;
  public httpClientNoAuth: ResilientHttpClient = null;

  constructor(private loggingService: LoggingService, private injector: Injector) {
    this.authService = this.injector.get(AuthService);
    this.setupClient();
  }

  public get<T>(url: string, requestConfig?: AxiosRequestConfig): Observable<T> {
    return this.makeHttpCall(url, 'GET', requestConfig);
  }
  public post<T>(url: string, requestConfig?: AxiosRequestConfig): Observable<T> {
    return this.makeHttpCall(url, 'POST', requestConfig);
  }

  public put<T>(url: string, requestConfig?: AxiosRequestConfig): Observable<T> {
    return this.makeHttpCall(url, 'PUT', requestConfig);
  }

  public patch<T>(url: string, requestConfig?: AxiosRequestConfig): Observable<T> {
    return this.makeHttpCall(url, 'PATCH', requestConfig);
  }

  public delete<T>(url: string, requestConfig?: AxiosRequestConfig): Observable<T> {
    return this.makeHttpCall(url, 'DELETE', requestConfig);
  }

  private makeHttpCall<T>(
    url: string,
    method: Method,
    requestConfig?: AxiosRequestConfig
  ): Observable<T> {
    const additionalRequestConfig: AxiosRequestConfig = requestConfig || {};
    const config: AxiosRequestConfig = {
      ...additionalRequestConfig,
      method,
      url,
    };

    return from(this.httpClient.request<T>(config)).pipe(
      map((response) => response.data),
      catchError((error: AxiosError) => {
        // logged by resilient client
        // re-throw so consumer can react to http errors
        return throwError(() => error);
      })
    );
  }

  private setupClient(): void {
    const resilientHttpOptions: IResilientHttpOptions = {
      timeout: 60000,
      baseURL: '',
      oAuth2Func: () => lastValueFrom(this.authService.getAccessToken()),
      logError: (error: AxiosError) => this.loggingService.logError(error),
      resilienceOptions: {
        retries: rc.DEFAULT_RETRY_COUNT,
      },
    };

    const resilientHttpOptionsNoAuth: IResilientHttpOptions = {
      timeout: 60000,
      baseURL: '',
      logError: (error: AxiosError) => this.loggingService.logError(error),
      resilienceOptions: {
        retries: rc.DEFAULT_RETRY_COUNT,
      },
    };

    this.httpClient = new ResilientHttpClient(resilientHttpOptions);
    this.httpClientNoAuth = new ResilientHttpClient(resilientHttpOptionsNoAuth);
  }
}
