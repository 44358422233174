import { resolvedRoles } from '@weave-design/theme-data/build/esm/darkGrayMediumDensityTheme';

const themeData = {
  metadata: {
    colorSchemeName: 'FDM Dark Gray',
    colorSchemeId: 'fdm-dark-gray-theme',
    className: 'fdm--dark--gray--theme',
    densityId: 'medium-density',
    densityName: 'Medium density',
    name: 'FDM Dark Gray, Medium density',
    fileName: 'FdmDarkGrayTheme',
    id: 'fdm-dark-gray-medium-density',
  },
  resolvedRoles: {
    ...resolvedRoles,
  },
};

export default themeData;
