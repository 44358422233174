import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { createRoot } from 'react-dom/client';
import { FC } from 'react';
import { ThemeService } from '@services/theme.service';
import { ReactFactory } from './react-factory.react';
import 'reflect-metadata';

const PROPS_METADATAKEY = Symbol('props');
enum PROPS_META_TYPE {
  'INPUT' = 'INPUT',
  'OUTPUT' = 'OUTPUT',
}
export function ReactProp(type: keyof typeof PROPS_META_TYPE) {
  return function (target: any, propertyKey: string) {
    Reflect.defineMetadata(PROPS_METADATAKEY, type, target, propertyKey);
  };
}

@Component({ template: '' })
export abstract class ReactFactoryComponent<P> implements AfterViewInit, OnChanges, OnDestroy {
  static readonly template = '<div #container style="display: block; position: relative;"></div>';

  @ViewChild('container') container: ElementRef<HTMLDivElement>;

  root;
  propsChangeSubject: BehaviorSubject<P>;
  private themeService: ThemeService;

  abstract getComponent(): FC<P>;

  constructor(private injector: Injector) {
    this.propsChangeSubject = new BehaviorSubject(null);
    this.themeService = this.injector.get(ThemeService);
  }

  ngAfterViewInit(): void {
    this.renderReactDOM();
  }

  ngOnChanges(): void {
    this.propsChangeSubject.next(this.getProps());
  }

  ngOnDestroy(): void {
    this.root?.unmount();
  }

  private getProps() {
    const props: any = {};
    for (const variable of Object.keys(this)) {
      const propsType = Reflect.getMetadata(PROPS_METADATAKEY, this, variable);
      if (propsType === PROPS_META_TYPE.INPUT) {
        props[variable] = this[variable];
      } else if (propsType === PROPS_META_TYPE.OUTPUT) {
        props[variable] = (data) => this[variable].emit(data);
      }
    }
    return props;
  }

  renderReactDOM() {
    if (this.container?.nativeElement) {
      if (!this.root) {
        this.root = createRoot(this.container.nativeElement);
        this.root.render(
          <ReactFactory
            propsChangeSubject={this.propsChangeSubject}
            themeChangeSubject={this.themeService.listenToMuiThemeChanges()}
            component={this.getComponent()}
            injector={this.injector}
          />
        );
      }
    }
  }
}
