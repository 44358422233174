import * as fromContentNode from '../actions/content-node.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContentNode } from '@models/fabrication/content-node';

export interface ContentNodeState extends EntityState<ContentNode> {} // eslint-disable-line

export const contentNodeAdapter: EntityAdapter<ContentNode> = createEntityAdapter<ContentNode>({
  selectId: (contentNode: ContentNode) => contentNode.id,
});

export const initialState: ContentNodeState = contentNodeAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromContentNode.ContentNodeActions
): ContentNodeState {
  switch (action.type) {
    case fromContentNode.ContentNodeActionTypes.LoadContentNode: {
      return state;
    }
    case fromContentNode.ContentNodeActionTypes.LoadContentNodeSuccess: {
      return contentNodeAdapter.upsertMany(action.data, state);
    }
    case fromContentNode.ContentNodeActionTypes.LoadContentNodeFail: {
      return state;
    }
    case fromContentNode.ContentNodeActionTypes.UpdateContentNodeReferences: {
      return contentNodeAdapter.updateOne(action.payload, state);
    }
    default:
      return state;
  }
}

// entity selectors
export const {
  selectIds: selectContentNodeIds,
  selectEntities: selectContentNodeEntities,
  selectAll: selectAllContentNode,
  selectTotal: selectContentNodeTotal,
} = contentNodeAdapter.getSelectors();
