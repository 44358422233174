import { DynamicFormSelectType } from '../dynamic-form/dynamic-form-properties';
import { ColumnDefinition } from '../dynamic-table/dynamic-table-options';

export enum ComponentMode {
  'view' = 'view',
  'edit' = 'edit',
}

export enum InlineEditType {
  'text' = 'text',
  'number' = 'number',
  'select' = 'select',
  'checkbox' = 'checkbox',
  'toggle' = 'toggle',
  'units' = 'units',
  'selectEditable' = 'selectEditable',
}

export interface InlineEditOptions {
  disabled: boolean;
  fieldType: InlineEditType;
  selectFieldOptions?: (row: unknown, field: string) => DynamicFormSelectType[];
  uniqueColumnData?: UniqueReferenceList[];
  // function that returns a function so that data sources for validation are always up to date
  validator?: () => (
    inlineEditData: InlineEditData,
    value: any
  ) => { error: boolean; message: string };
  /**
   * Other model field that this value is dependant on to filter it's
   * value/displayed options that are visible in an editable cell
   * @type {string[]}
   * @memberof InlineEditOptions
   */
  dependantField?: string;
  /**
   * Filter function to run when a dependent field value changes
   * currently only supports returning a filtered set of select options
   * @type {boolean}
   * @memberof InlineEditOptions
   */
  filterOnDependantFieldChange?: (value: string) => DynamicFormSelectType[];
  valueAffectsWholeColumn?: boolean;
}

export interface UniqueReferenceList {
  id: string;
  name: string;
}

export interface InlineEditError {
  reference: string;
  errorMessage: string;
}

export interface InlineEditClearErrorState {
  clearReference: string;
  clearAllErrors: boolean;
}

/**
 * Inline data passed to editable dynamic table cells
 * @export
 * @interface InlineEditData
 */
export interface InlineEditData {
  cellId: string;
  tableId: string;
  field: string;
  columnIndex: number;
  rowIndex: number;
  row: any;
  column: ColumnDefinition;
  value: any;
  error?: InlineEditError;
  forceFocus: boolean;
  rowSelectionId: string;
}

export interface InlineEditDataCellChangeEvent {
  inlineData: InlineEditData;
  oldValue: any;
}
