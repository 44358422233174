import * as fromRates from '../actions/installation-rate.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { InstallationRate } from '@models/fabrication/labour-rate';

export interface InstallationRateState extends EntityState<InstallationRate> {} // eslint-disable-line

export const installationRateAdapter: EntityAdapter<InstallationRate> =
  createEntityAdapter<InstallationRate>({
    selectId: (rate: InstallationRate) => rate.id,
  });

export const initialState: InstallationRateState = installationRateAdapter.getInitialState();

export function reducer(
  state = initialState,
  action: fromRates.InstallationRateActions
): InstallationRateState {
  switch (action.type) {
    case fromRates.InstallationRateActionTypes.LoadInstallationRates: {
      return state;
    }
    case fromRates.InstallationRateActionTypes.LoadInstallationRatesSuccess: {
      return installationRateAdapter.upsertMany(action.data, state);
    }
    case fromRates.InstallationRateActionTypes.LoadInstallationRatesFail: {
      return state;
    }

    default:
      return state;
  }
}

// entity selectors
export const {
  // select the array of installation rate ids
  selectIds: selectInstallationRateIds,

  // select the dictionary of installation rate entities
  selectEntities: selectInstallationRateEntities,

  // select the array of installation rates
  selectAll: selectAllInstallationRates,

  // select the total installation rate count
  selectTotal: selectInstallationRatesTotal,
} = installationRateAdapter.getSelectors();
